import React from "react";
import AppFooter from "web/react/components/app-footer/app-footer";
import { useInStockProductPageContext } from "web/react/pages/product/in-stock/in-stock-product-page/in-stock-product-page.context";
import { ProductLayoutSerializer } from "web/types/serializers";

export function Footer({
    productLayout,
    helpCentreURLs,
}: {
    productLayout: ProductLayoutSerializer;
    helpCentreURLs: string[];
}): JSX.Element {
    const { footer } = productLayout;
    const { country } = useInStockProductPageContext();

    return <AppFooter data={footer} helpCentreURLs={helpCentreURLs} country={country} />;
}

import React from "react";
import HomepageBreakoutBrands from "web/react/components/__LEGACY__/homepage-breakout-brands/homepage-breakout-brands";
import HomepageMostWantedRail from "web/react/components/__LEGACY__/homepage-most-wanted-rail/homepage-most-wanted-rail";
import BrandValue from "web/react/components/brand-value/brand-value";
import CategoryLinks from "web/react/components/category-links/category-links";
import { ProductRail } from "web/react/components/product-rail/product-rail";
import PromoSection from "web/react/components/promo-section/promo-section";
import TopLysts from "web/react/components/top-lysts/top-lysts";
import { gettext } from "web/script/modules/django-i18n";
import { HomepageLayoutSerializer } from "web/types/serializers";
import styles from "./homepage-layout.module.css";

interface HomepageLayoutProps {
    layoutData: HomepageLayoutSerializer;
}

export function HomepageLayout({
    layoutData: {
        homepage_brand_values: brandValues,
        homepage_featured_banners: featuredBanners,
        homepage_breakout_brands: breakoutBrands,
        homepage_trends: trends,
        homepage_generic_types: genericTypes,
        homepage_promo_banner: promoBanner,
        new_arrivals: newArrivals,
    },
}: HomepageLayoutProps): JSX.Element {
    return (
        <div className={styles.homepageLayout}>
            {promoBanner && <PromoSection data={promoBanner} />}
            {featuredBanners && <TopLysts {...featuredBanners} />}
            {breakoutBrands && <HomepageBreakoutBrands {...breakoutBrands} />}
            {brandValues && <BrandValue className={styles.homepageBrandValue} {...brandValues} />}
            {trends && <HomepageMostWantedRail {...trends} />}
            {newArrivals && (
                <ProductRail
                    id="product-rail"
                    label="homepage-new-arrivals"
                    title={gettext("general.new_on_lyst")}
                    products={newArrivals.products}
                />
            )}
            {genericTypes && (
                <CategoryLinks genericTypes={genericTypes} id="homepage-category-links-rail" />
            )}
        </div>
    );
}

export default HomepageLayout;

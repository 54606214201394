import React from "react";
import styles from "./hidden-visually.module.css";

interface HiddenVisuallyProps {
    children: React.ReactNode;
}

export function HiddenVisually({ children }: HiddenVisuallyProps): JSX.Element {
    return <div className={styles.root}>{children}</div>;
}

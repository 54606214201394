import _isFunction from "lodash/isFunction";
import environment from "web/script/modules/environment";
import globals from "web/script/modules/globals";
import browser from "web/script/utils/browser";
import globalEvents from "web/script/utils/global-events";
import history from "web/script/utils/history";
import navigate from "web/script/utils/navigate";
import url, { redirectInNewTab } from "web/script/utils/url";

interface UseProductClickArgs {
    id?: string;
    productUrl?: string;
    productImageUrl?: string;
    inStock?: boolean;
    shouldRedirect?: boolean;
    overlayEnabled?: boolean;
    productUid?: string;
    productSlug?: string;
    reason?: string;
    linkId?: string;
    sendAnalytics?: () => void;
    href?: string;
    isLeadLink?: boolean;
    openInNewTab?: boolean;
    leadURL?: string;
    contextType?: string | null;
    feedType?: string | null;
    redirectWithLinkId?: boolean;
    retailerExcludedFromApp?: boolean;
    appDeeplinkPath?: string;
    checkoutEnabled?: boolean;
    hashURL?: string | null;
}

interface UseProductClickResponse {
    onClick: (event) => any;
}

/**
 * Returns true if a new tab should be used for the event
 */
function _isNewTab(event): boolean {
    return (browser.mac && event.metaKey) || (!browser.mac && event.ctrlKey) || event.which === 2;
}

const DEFAULT_PRODUCT_CLICK_ARGS = {
    inStock: false,
    overlayEnabled: false,
};
// useProductClick is a hook counterpart for the legacy product-click-mixin typically used in Baustein components
// use with caution as code may diverge between the two versions as long as both exist
export function useProductClick(props: UseProductClickArgs): UseProductClickResponse {
    const args = { ...DEFAULT_PRODUCT_CLICK_ARGS, ...props };
    const {
        shouldRedirect,
        overlayEnabled,
        productSlug,
        reason,
        linkId,
        sendAnalytics,
        href,
        isLeadLink,
        openInNewTab,
        hashURL,
    } = args;

    function _redirectWithReason(event, newTab = false): void {
        let parsed = url.parse(href as string);

        event.preventDefault();

        if (reason) {
            parsed.searchParams.set("reason", reason);
        }
        if (linkId) {
            parsed.searchParams.set("link_id", linkId);
        }

        if (newTab) {
            globals.window.open(url.unparse(parsed), "_blank");
            return;
        }

        navigate(url.unparse(parsed));
    }

    /**
     * Product click event handler that will either redirect or open a new tab to product page.
     */
    function _onClickRedirect(event): void {
        if (event.target.classList.contains("show-more-button")) {
            return;
        }

        let newTab = _isNewTab(event) as any;

        globalEvents.trigger("product-card-selected", event);
        _redirectWithReason(event, newTab);
    }

    /**
     * Product click event handler that will either open an overlay, new tab or redirect to product page.
     */
    function _onClick(event): void {
        if (event.target.classList.contains("show-more-button")) {
            return;
        }

        event.preventDefault();

        if (_isFunction(sendAnalytics)) {
            sendAnalytics();
        }

        const isOverlayEnabled =
            overlayEnabled && productSlug && browser.supportsHistory && !event.metaKey;

        globalEvents.trigger("product-card-selected", event);

        // if the overlay isn't enabled, and the target is to open a new tab, then bail out here
        if (!isOverlayEnabled && openInNewTab) {
            // the lead-link click handler also calls `preventDefault` and calls window.open. we don't
            // want to to it twice.
            if (!isLeadLink) {
                redirectInNewTab(href, "product-link");
            }
            return;
        }

        let newTab = _isNewTab(event) as any;

        if (!isOverlayEnabled || newTab || !reason) {
            _redirectWithReason(event, newTab);
        } else {
            let currentSearchParams = new URLSearchParams(window.location.search);
            let queryParams = {
                reason: reason,
                link_id: linkId,
                previous_page_type: environment.get("pageType"),
                previous_page_sub_type: environment.get("pageSubType"),
                is_track_overlay: isOverlayEnabled && openInNewTab,
            };

            let url = new URL(href as string, window.location.href);

            // ORG-3465: More details in https://confluence.lystit.com/display/OA/FY23+Q2+-+Hash+URL+Crawl+Optimisation
            const pageType = environment.get("pageType");

            if (pageType === "feed") {
                queryParams["origin_feed"] =
                    currentSearchParams.get("origin_feed") || window.location.pathname;
            }

            if (hashURL) {
                url = new URL(hashURL as string, window.location.href);
            }

            for (let [k, v] of Object.entries(queryParams)) {
                url.searchParams.set(k, v as string);
            }

            history.pushOverlay("product", { newURL: url });
        }
    }

    return { onClick: shouldRedirect ? _onClickRedirect : _onClick };
}

import clsx from "clsx";
import _throttle from "lodash/throttle";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { HeaderBar } from "web/react/components/navigation/header-bar/header-bar";
import { NavigationMenu } from "web/react/components/navigation/navigation-menu";
import { SetPaidSessionIdOnLinks } from "web/react/components/set-paid-session-id-on-links/set-paid-session-id-on-links";
import { SkipNavigationButton } from "web/react/components/skip-navigation-button/skip-navigation-button";
import { useDomViewport } from "web/react/hooks/use-dom-viewport/use-dom-viewport";
import useIsomorphicLayoutEffect from "web/react/hooks/use-isomorphic-layout-effect/use-isomorphic-layout-effect";
import { MainNavigation as MainNavigationReduxInterface } from "web/redux/ducks/main-navigation";
import environment from "web/script/modules/environment";
import {
    AppHeaderBarSerializer,
    AppPreHeaderBarSerializer,
    HeaderGenderedMenuSerializer,
    MyListMenuSerializer,
} from "web/types/serializers";
import { DesktopMenu } from "./desktop-menu";
import { NavigationContextProvider, useNavigationContext } from "./navigation.context";
import * as styles from "./navigation.css";

function extractNavigation(state): MainNavigationReduxInterface {
    return state.mainNavigationReducer;
}

export interface NavigationProps {
    headerBar: AppHeaderBarSerializer;
    myListMenu: MyListMenuSerializer;
    preHeaderBar: AppPreHeaderBarSerializer;
    headerMenu: HeaderGenderedMenuSerializer;
    featuredDesignersUrl: string;
    blockBotsNavigation: boolean;
}

function Overlay(): React.ReactElement {
    const [isOverlayOpen, setIsOverlayOpen] = useState(false);

    const { showMenu, showSearch } = useNavigationContext();

    const stickyBarFlag = environment.getFeature("act_sticky_navbar");

    useIsomorphicLayoutEffect(() => {
        setIsOverlayOpen(Boolean(showMenu || showSearch));
    }, [showMenu, showSearch]);

    return (
        <div
            className={clsx(styles.overlay, {
                [styles.showOverlay]: isOverlayOpen,
                [styles.showOverlayWithStickBar]: stickyBarFlag,
            })}
        />
    );
}

function NavigationWrapper({
    headerBar,
    myListMenu,
    preHeaderBar,
    headerMenu,
    featuredDesignersUrl,
    blockBotsNavigation,
}: NavigationProps): React.ReactElement {
    const { selectedGender } = useSelector(extractNavigation);
    const { showMenu, showSearch } = useNavigationContext();

    const genders = {
        women: "F",
        men: "M",
    };

    // Gender can be set from the request (userGender) or
    // from redux (selectedGender) by interacting with the navigation
    const currentGender = selectedGender || headerBar.selected_gender?.toLowerCase() || "women";

    const isRebrand = !!environment.getFeature("beam_nav_redesign_v0");

    // ENRICH-3346
    // Stick the navigation at the top of the viewport
    // and show it on scroll up.
    // Will be applied only on feed pages
    const [scroll, setScroll] = useState("");
    const [prevScrollTop, setPrevScrollTop] = useState(0);
    const navbarRef = useRef<HTMLDivElement>(null);
    const { isMobileViewport, isTabletViewport } = useDomViewport();
    const stickyBarFlag = environment.getFeature("act_sticky_navbar");

    useEffect(() => {
        if (!stickyBarFlag) {
            return;
        }

        function onWindowScroll(): void {
            const scrollTop = window.scrollY;

            if (scrollTop <= prevScrollTop || scrollTop <= 0) {
                setScroll("up");
            } else {
                setScroll("down");
            }

            setPrevScrollTop(scrollTop);
        }

        const throttledOnWindowScroll = _throttle(onWindowScroll, 100);

        window.addEventListener("scroll", throttledOnWindowScroll);

        return () => {
            window.removeEventListener("scroll", throttledOnWindowScroll);
        };
    }, [stickyBarFlag, prevScrollTop]);

    const hideNavbarSticky =
        (isMobileViewport || isTabletViewport) &&
        stickyBarFlag &&
        scroll === "down" &&
        !showMenu &&
        !showSearch;

    return (
        <div
            ref={navbarRef}
            className={clsx({
                [styles.navbarSticky]: (isMobileViewport || isTabletViewport) && stickyBarFlag,
                [styles.navbarStickyHidden]: hideNavbarSticky,
            })}
        >
            <div className={isRebrand ? styles.headerContentRebrand : styles.headerContent}>
                <SetPaidSessionIdOnLinks />
                <SkipNavigationButton />
                <HeaderBar
                    {...headerBar}
                    registerUrl={myListMenu.register_url}
                    userDropdown={{
                        accountDropdown: preHeaderBar.account_dropdown,
                        accountUrl: preHeaderBar.account_url,
                        registerUrl: preHeaderBar.register_url,
                    }}
                    showCountryPicker={preHeaderBar.show_country_picker}
                    languageSelector={preHeaderBar.language_selector}
                />
                {/** empty div used for autocomplete React portal @see search-bar.tsx*/}
                <div id="app-header-autocomplete" />
                <DesktopMenu
                    menu={headerMenu[genders[currentGender]]}
                    featuredDesignersUrl={featuredDesignersUrl}
                />
                <NavigationMenu
                    data={headerMenu}
                    myListMenu={myListMenu}
                    isAuthenticated={headerBar.is_authenticated}
                    currentGender={currentGender}
                    showCountryPicker={preHeaderBar.show_country_picker}
                    currentCountry={preHeaderBar.country_info.country}
                    currentCurrency={preHeaderBar.country_info.currency_indicator}
                    languageSelector={preHeaderBar.language_selector}
                    blockBotsNavigation={blockBotsNavigation}
                />
            </div>
            <Overlay />

            {/** empty div used for header-bar-search React portal @see header-bar.tsx*/}
            <div
                id="app-header-search-bar-mobile"
                className={isRebrand ? styles.mobileSearchBarRebrand : styles.mobileSearchBar}
            />
        </div>
    );
}

export function Navigation(props: NavigationProps): React.ReactElement {
    const isRebrand = !!environment.getFeature("beam_nav_redesign_v0");

    return (
        <NavigationContextProvider isRebrand={isRebrand}>
            <NavigationWrapper {...props} />
        </NavigationContextProvider>
    );
}

import React from "react";
import { useFooterContext } from "./footer";
import FooterBlock from "./footer-block";
import styles from "./footer-extra-links.module.css";

export const WOMEN = "women";
export const MEN = "men";

function FooterExtraLinks(): JSX.Element | null {
    const {
        data: {
            extra_links_enabled: extraLinksEnabled,
            extra_links: extraLinks,
            men_links_enabled: menLinksEnabled,
        },
        openBlockId,
    } = useFooterContext();

    if (!extraLinksEnabled) {
        return null;
    }

    const enabledGenderBlockId = menLinksEnabled ? MEN : WOMEN;

    return (
        <FooterBlock isOpen={openBlockId === enabledGenderBlockId} isInteractive={false}>
            <div>
                <ul>
                    {extraLinks.map((link) => (
                        <li key={link.url}>
                            <a className={styles.link} href={link.url}>
                                {link.name}
                            </a>
                        </li>
                    ))}
                </ul>
            </div>
        </FooterBlock>
    );
}

export default FooterExtraLinks;

import clsx from "clsx";
import React, { Fragment } from "react";
import { HStack } from "web/react/emo/h-stack";
import { Heading } from "web/react/emo/heading";
import { Text } from "web/react/emo/text";
import { VStack } from "web/react/emo/v-stack";
import { View } from "web/react/emo/view";
import { useDomViewport } from "web/react/hooks/use-dom-viewport/use-dom-viewport";
import analytics from "web/script/analytics/analytics";
import { gettext } from "web/script/modules/django-i18n";
import { CuratedProductsLinkSerializer } from "web/types/serializers";
import * as styles from "./curated-products-card.css";

interface CuratedProductsCardProps {
    links: CuratedProductsLinkSerializer[];
}

export default function CuratedProductsCard({ links = [] }: CuratedProductsCardProps): JSX.Element {
    const { isMobileViewport } = useDomViewport();

    return (
        <View
            background="placeholder"
            className={clsx(styles.wrapper, styles.wrapperBorder)}
            padding={{ sm: "sm", lg: "lg" }}
        >
            <VStack spacing={isMobileViewport ? "md" : "lg"}>
                <VStack spacing="xxs">
                    <Text color="secondaryBrand" textStyle="caption-2">
                        {gettext("feeds.curated_products.related_category")}
                    </Text>
                    <Heading as="h2" textStyle={{ sm: "title-3", lg: "title-2" }}>
                        {gettext("feed.curated_products.looking_for_something.title")}
                    </Heading>
                </VStack>
                <HStack spacing="xs">
                    {links.map(({ image, url, title }) => (
                        <Fragment key={url}>
                            <a
                                href={url}
                                onClick={(): void => {
                                    analytics.event("uf_infeed_cta", "click", title);
                                }}
                                data-testid={`image-${title}`}
                            >
                                <View
                                    background="placeholder"
                                    padding="xxs"
                                    className={styles.imageContainer}
                                >
                                    <img
                                        className={styles.itemImage}
                                        src={image}
                                        alt={title}
                                        height={275}
                                        width={300}
                                    />
                                </View>
                                <View
                                    paddingX="sm"
                                    paddingY="xs"
                                    className={clsx(styles.wrapperBorder, styles.textWrapper)}
                                >
                                    <Text textStyle="body-2">{title}</Text>
                                </View>
                            </a>
                        </Fragment>
                    ))}
                </HStack>
            </VStack>
        </View>
    );
}

import React from "react";
import * as styles from "./status-indicator.css";

interface StatusIndicatorProps {
    active: boolean;
}

/**
 * Design System
 * @name StatusIndicator
 * @version 1.0
 * @design https://www.figma.com/file/VhOHx4tv9uYXZHXAAKPVYf/Design-System?type=design&node-id=8056-80596&t=sJmKH2VN0OACQRby-4
 */
export function StatusIndicator({ active }: StatusIndicatorProps): React.ReactElement {
    return <div className={active ? styles.active : undefined} />;
}

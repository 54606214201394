import React, { useState } from "react";
import { useNavigationContext } from "web/react/components/navigation/navigation.context";
import { MenuSerializer, SubMenuSerializer } from "web/types/serializers";
import { CategoryMenu } from "./category-menu";
import { StickyCategories } from "./sticky-categories";

export interface DesktopMenuProps {
    menu: MenuSerializer;
    featuredDesignersUrl: string;
}

export function DesktopMenu({ menu, featuredDesignersUrl }: DesktopMenuProps): React.ReactElement {
    const [selectedCategory, setSelectedCategory] = useState<SubMenuSerializer | null>(null);

    const { setShowMenu } = useNavigationContext();

    function onLeave(): void {
        setSelectedCategory(null);
        setShowMenu(null);
    }

    return (
        <>
            <div data-testid="desktop-menu" onMouseLeave={onLeave}>
                <StickyCategories
                    menus={menu.menus}
                    links={menu.links}
                    selectedCategory={selectedCategory}
                    setSelectedCategory={setSelectedCategory}
                />
                <CategoryMenu
                    menuData={menu}
                    featuredDesignersUrl={featuredDesignersUrl}
                    selectedCategory={selectedCategory}
                />
            </div>
        </>
    );
}

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import storage from "web/script/utils/storage";
import { AllSelectedFilterInfo } from "web/types/feed-filters";
import { InputFilterOption } from "web/types/universal-filters";

export interface FeedSidebarSlice {
    count: number;
    detailedCount: AllSelectedFilterInfo;
    genderOptions: InputFilterOption[];
    loading: boolean;
    open: boolean;
    selectedGender: InputFilterOption | null;
}

export const initialState: FeedSidebarSlice = {
    count: 0,
    genderOptions: [],
    detailedCount: {},
    loading: false,
    open: false,
    selectedGender: storage.get("selectedGender", "women", false),
};

const feedSideBar = createSlice({
    name: "feedSidebar",
    initialState,
    reducers: {
        openSidebar(state): void {
            state.open = true;
        },
        closeSidebar(state): void {
            state.open = false;
        },
        setFilterCount(state, action: PayloadAction<number>): void {
            state.count = action.payload;
        },
        setDetailedFilterCount(state, action: PayloadAction<AllSelectedFilterInfo>): void {
            state.detailedCount = action.payload;
        },
        setLoading(state, action: PayloadAction<boolean>): void {
            state.loading = action.payload;
        },
        setGenderOptions(state, action: PayloadAction<InputFilterOption[]>): void {
            state.genderOptions = action.payload;
        },
        setSelectedGender(state, action: PayloadAction<InputFilterOption>): void {
            storage.set("selectedGender", action.payload, false);
            state.selectedGender = action.payload;
        },
    },
});

export const {
    closeSidebar,
    openSidebar,
    setFilterCount,
    setDetailedFilterCount,
    setGenderOptions,
    setLoading,
    setSelectedGender,
} = feedSideBar.actions;
export default feedSideBar.reducer;

import React from "react";
import { Heading } from "web/react/emo/heading";
import { ngettext } from "web/script/modules/django-i18n";
import { numberFormat } from "web/script/modules/formats";
import { getHtmlProps } from "web/script/modules/html-tag-props";
import * as styles from "./feed-count.css";

export function formatCountString(count: number): string {
    const MAX_COUNT = 500;
    return count > MAX_COUNT ? `${MAX_COUNT}+` : numberFormat(count);
}

export interface FeedCountProps {
    productCount: number;
    retailerCount: number;
}

export function FeedCount({ productCount, retailerCount }: FeedCountProps): JSX.Element {
    const countSpanProps = {
        class: styles.count,
    };

    const productCountLabelHtml = {
        __html: ngettext(
            // "We've found {count} product"
            // "We've found {count} products"
            "feed.product_count.label_v2.in_spans",
            "feed.product_count.label_v2.in_spans_pl",
            productCount,
            {
                count: formatCountString(productCount),
                count_span_props: getHtmlProps(countSpanProps),
            }
        ),
    };

    const retailersCountLabelHtml = {
        __html: ngettext(
            // "from {count} of our partner stores"
            // "from {count} of our partner stores"
            "feed.retailers_count.label_v2.in_spans",
            "feed.retailers_count.label_v2.in_spans_pl",
            retailerCount,
            {
                count: formatCountString(retailerCount),
                count_span_props: getHtmlProps(countSpanProps),
            }
        ),
    };

    return (
        <div className={styles.container}>
            <Heading as="h2" textStyle={"title-2"} color="secondary">
                <div
                    dangerouslySetInnerHTML={productCountLabelHtml}
                    className={styles.feedCountsText}
                />
                {retailerCount > 0 && (
                    <div
                        dangerouslySetInnerHTML={retailersCountLabelHtml}
                        className={styles.feedCountsText}
                    />
                )}
            </Heading>
        </div>
    );
}

export default FeedCount;

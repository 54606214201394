const EVENTS = "__events";

export interface EventObject {
    type: string;
    eventName: string;
}

type EventCallback = (event: EventObject, ...args: any) => void;
type EventsCallbackList = {
    [event: string]: EventCallback[];
};

export default {
    /**
     * Register a callback for an event
     */
    on(event: string, callback: EventCallback): () => void {
        let events: EventsCallbackList | undefined = this[EVENTS];
        if (!events) {
            events = {};
            this[EVENTS] = events;
        }

        if (!events[event]) {
            events[event] = [];
        }

        events[event].push(callback);

        return (): void => this.off(event, callback);
    },

    /**
     * Fire an event and pass all additional parameters into the callback.
     */
    trigger(event: string, ...args: any): void {
        let events: EventsCallbackList | undefined = this[EVENTS];
        if (!events || !events[event]) {
            return;
        }

        // Add an event object to the callback parameters
        args.unshift({
            type: event,
            eventName: event,
        });

        events[event].forEach((fn) => fn.apply(this, args));
    },

    /**
     * Removes a callback
     * @param event The name of the event to remove the callback from
     * @param callback Exact reference to the callback to be removed
     */
    off(event: string, callback: EventCallback): void {
        let events: EventsCallbackList | undefined = this[EVENTS];
        if (!events || !events[event]) {
            return;
        }

        events[event] = events[event].filter((fn) => fn != callback);
    },
};

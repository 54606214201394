import clsx from "clsx";
import React from "react";
import styles from "./product-section-header.module.css";

interface ProductSectionHeaderProps {
    title: string;
    usesFeedLayout: boolean;
}

function ProductSectionHeader({ title, usesFeedLayout }: ProductSectionHeaderProps): JSX.Element {
    return (
        <div
            className={clsx(styles.titleWrapper, {
                [styles.titleWrapperInFeed]: usesFeedLayout,
            })}
        >
            <h5 className={styles.title} data-content={title}>
                {title}
            </h5>
        </div>
    );
}

export default ProductSectionHeader;

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { BannerSize } from "web/script/utils/app-banner-campaign";

export interface TopBannerSlice {
    isVisible: boolean;
    bannerSize: BannerSize;
    desktopBannerVisible: boolean;
    bannerType: "email_banner" | "app_banner";
}

const topBanner = createSlice({
    name: "topBanner",
    initialState: {
        isVisible: Boolean(false),
        bannerSize: BannerSize.None,
        desktopBannerVisible: false,
        bannerType: "app_banner",
    } as TopBannerSlice,
    reducers: {
        bannerHidden(state): void {
            state.isVisible = false;
            state.bannerSize = BannerSize.None;
        },
        bannerVisible(state): void {
            state.isVisible = true;
        },
        setBannerSize(state, action: PayloadAction<BannerSize>): void {
            state.bannerSize = action.payload;
        },
        setDesktopBannerVisible(state, action: PayloadAction<boolean>): void {
            state.desktopBannerVisible = action.payload;
        },
        setBannerType(state, action: PayloadAction<"email_banner" | "app_banner">): void {
            state.bannerType = action.payload;
        },
    },
});

export const {
    bannerHidden,
    bannerVisible,
    setBannerSize,
    setDesktopBannerVisible,
    setBannerType,
} = topBanner.actions;
export default topBanner.reducer;

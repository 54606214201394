import { RefObject, useCallback, useRef, useState } from "react";
import { useAboveFold } from "web/react/hooks/use-above-fold/use-above-fold";
import useIsomorphicLayoutEffect from "web/react/hooks/use-isomorphic-layout-effect/use-isomorphic-layout-effect";
import analytics from "web/script/analytics/analytics";

interface UseStoryAnalyticsProps {
    label: string;
    componentId?: number;
    componentLabel?: string;
    position: number;
    isSponsored: boolean;
    railPosition?: number;
    productId?: number;
    buyOptionId?: number | null;
}

interface UseStoryAnalyticsResults {
    containerRef: RefObject<HTMLDivElement>;
    sendClickEvent: (action: string) => void;
}

export function useStoryAnalytics(params: UseStoryAnalyticsProps): UseStoryAnalyticsResults {
    const {
        label,
        componentId,
        componentLabel,
        position,
        isSponsored,
        railPosition,
        productId,
        buyOptionId,
    } = params;

    const containerRef = useRef<HTMLDivElement>(null);
    const { isAboveFold, isVisible } = useAboveFold(containerRef, 0.5);
    const [hasImpression, setHasImpression] = useState(false);

    const sendAnalyticsEvent = useCallback(
        (action: string, area?: string) => {
            analytics.event(
                "home_story",
                action,
                label,
                action !== "clicked", // nonInteraction
                {}, // customData
                "", // subType
                {}, // checkoutData
                [
                    // itemData
                    {
                        item_type: "home_story",
                        above_fold: isAboveFold,
                        ...(componentId && { component_id: componentId }),
                        ...(componentLabel && { component_label: componentLabel }),
                        component_type: label,
                        position: position,
                        sponsored: isSponsored,
                        area: area,
                        ...(typeof railPosition === "number" && { rail_position: railPosition }),
                        ...(productId && { product_id: productId }),
                        ...(buyOptionId && { buy_option_id: buyOptionId }),
                    },
                ]
            );
        },
        [
            label,
            isAboveFold,
            componentId,
            componentLabel,
            position,
            isSponsored,
            railPosition,
            productId,
            buyOptionId,
        ]
    );

    function sendClickEvent(area: string): void {
        sendAnalyticsEvent("clicked", area);
    }

    useIsomorphicLayoutEffect(() => {
        if (isVisible && !hasImpression) {
            sendAnalyticsEvent("viewed");
            setHasImpression(true);
        }
    }, [isVisible, hasImpression, sendAnalyticsEvent, setHasImpression]);

    return { containerRef, sendClickEvent };
}

import WarningIcon from "img/checkout/icons/warning.svg?inline";
import React from "react";
import GenericStickyModal from "web/react/components/generic-sticky-modal/generic-sticky-modal";
import { Button } from "web/react/emo/button";
import styles from "./error-modal.module.css";

interface ErrorModalProps {
    title: string;
    description: string;
    isOpen: boolean;
    onClose: () => void;
    buttonCTAText: string;
    buttonCTA?: () => void;
}

function ErrorModal({
    title,
    description,
    isOpen,
    onClose,
    buttonCTAText,
    buttonCTA,
}: ErrorModalProps): React.ReactElement {
    return (
        <GenericStickyModal isOpen={isOpen} onClose={onClose} noScroll className={styles.modal}>
            <div className={styles.overlayContainer}>
                <div className={styles.inline}>
                    <span className={styles.title}>{title}</span>
                    <WarningIcon />
                </div>
                <div className={styles.description}>
                    <p>{description}</p>
                </div>
                <Button title={buttonCTAText} width="full" onClick={buttonCTA}></Button>
            </div>
        </GenericStickyModal>
    );
}

export default ErrorModal;

import clsx from "clsx";
import React from "react";
import { Tooltip } from "react-tooltip";
import style from "./hint-bubble.module.css";

interface HintBubbleProps {
    tooltipText: React.ReactNode;
    className?: string;
}

export function HintBubble({ className, tooltipText }: HintBubbleProps): JSX.Element {
    return (
        <>
            <span data-tooltip-id="my-tooltip" className={style.hintBubble} />
            <Tooltip
                id="my-tooltip"
                place="top"
                className={clsx(style.hintBubbleWrapper, className)}
                classNameArrow={style.arrow}
                clickable
            >
                <div className={style.hintBubbleTooltipWrapper}>{tooltipText}</div>
            </Tooltip>
        </>
    );
}

import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { useDispatch, useSelector } from "react-redux";
import { useNavigationContext } from "web/react/components/navigation/navigation.context";
import { AutoSuggest } from "web/react/components/navigation/search-bar/auto-suggest";
import {
    getSearchLimits,
    useAutoSuggestContext,
} from "web/react/components/navigation/search-bar/auto-suggest/auto-suggest.context";
import SVGIcon from "web/react/components/svg-icon/svg-icon";
import { HStack } from "web/react/emo/h-stack";
import { useDomViewport } from "web/react/hooks/use-dom-viewport/use-dom-viewport";
import { hideMainNavigation } from "web/redux/ducks/main-navigation";
import { ReduxStoreState } from "web/redux/store";
import analytics from "web/script/analytics/analytics";
import { gettext } from "web/script/modules/django-i18n";
import strings from "web/script/utils/strings";
import { AppHeaderBarSearchInputSerializer } from "web/types/serializers";
import * as styles from "./search-bar.css";

const EVENT_CATEGORY = "top_nav";

function extractSelectedGenderState(state: ReduxStoreState): string {
    return strings.capitalize(state.mainNavigationReducer.selectedGender);
}

interface SearchBarProps {
    searchInput: AppHeaderBarSearchInputSerializer;
    isMobile: boolean;
}
export function SearchBar({ searchInput, isMobile }: SearchBarProps): React.ReactElement {
    const [initialInteracted, setInitialInteracted] = useState<boolean>(false);

    const {
        query,
        setRecentSearches,
        setSearchInputFocused,
        updateAutoSuggestion,
        searchInputRef,
        onKeyDown,
        onSubmit,
        onInput,
        recentSearchesCleared,
    } = useAutoSuggestContext();

    const stateGender = useSelector(extractSelectedGenderState);
    const dispatch = useDispatch();

    const { setShowMenu, setShowSearch, isRebrand } = useNavigationContext();
    const { isDomLoaded, isDesktopViewport } = useDomViewport();

    const { recentSearchesLimit } = getSearchLimits(isDesktopViewport);

    useEffect(() => {
        if (!recentSearchesCleared) {
            setRecentSearches(searchInput.recent_searches?.slice(0, recentSearchesLimit));
        } else {
            setRecentSearches([]);
        }
    }, [
        setRecentSearches,
        searchInput.recent_searches,
        recentSearchesLimit,
        recentSearchesCleared,
    ]);

    const selectedGender = stateGender || searchInput.gender;

    /* initialize the search value on initial load; query doesn't change */

    useEffect(() => {
        updateAutoSuggestion({
            gender: selectedGender,
        });
    }, [updateAutoSuggestion, selectedGender]);

    function onBlurSearchInput(): void {
        setSearchInputFocused(false);
        setShowSearch(null);
    }

    function onFocusSearchInput(): void {
        setSearchInputFocused(true);
        setShowMenu(null);
        setShowSearch(isMobile ? "mobile" : "desktop");
        if (!initialInteracted) {
            setInitialInteracted(true);
        }

        analytics.event(EVENT_CATEGORY, "search_focused", "search_bar");
        dispatch(hideMainNavigation());
    }

    function onClickCancelButton(): void {
        setShowSearch(null);
        analytics.event("header", "clicked", "burger_menu_search_button_close");
    }

    const autoCompleteElement = isDomLoaded && document.getElementById("app-header-autocomplete");

    return (
        <>
            <span className={styles.searchWrapper}>
                <form action={searchInput.action_url} onSubmit={onSubmit}>
                    <HStack>
                        <div
                            className={isRebrand ? styles.inputWrapperRebrand : styles.inputWrapper}
                        >
                            {!isMobile && (
                                <button type="submit" className={styles.searchButton}>
                                    <SVGIcon className={styles.searchIcon} name="search" />
                                </button>
                            )}
                            <input
                                type="text"
                                className="hidden"
                                name="term"
                                defaultValue={query}
                            />
                            <input
                                type="text"
                                className="hidden"
                                name="gender"
                                defaultValue={selectedGender}
                            />
                            {/* set autoComplete="off" autoCorrect="off" to disable QuickType on iOS */}
                            <input
                                className={styles.input}
                                aria-label={gettext("header.product_search.label")}
                                type="search"
                                autoComplete="off"
                                autoCorrect="off"
                                name="q"
                                placeholder={
                                    selectedGender === "Men"
                                        ? gettext("header.search.placeholder_for_men")
                                        : gettext("header.search.placeholder_for_women")
                                }
                                onBlur={onBlurSearchInput}
                                onFocus={onFocusSearchInput}
                                onInput={onInput}
                                onChange={onInput}
                                onKeyDown={onKeyDown}
                                ref={searchInputRef}
                                value={query}
                                autoFocus={isMobile}
                            ></input>
                            {isMobile && (
                                <button type="submit" className={styles.searchButton}>
                                    <SVGIcon className={styles.searchIcon} name="search" />
                                </button>
                            )}
                        </div>
                        {isMobile && (
                            <button
                                type="button"
                                className={styles.closeButton}
                                onClick={onClickCancelButton}
                            >
                                <SVGIcon className={styles.closeIcon} name="cross-thin" />
                            </button>
                        )}

                        {/* Native keyboard `search` button wouldn't work w/o this w/o displaying a [type=submit] element (can't have display: none) */}
                        {/* <input type="submit" /> */}
                    </HStack>
                </form>
            </span>

            {autoCompleteElement &&
                ReactDOM.createPortal(<AutoSuggest />, autoCompleteElement as HTMLDivElement)}
        </>
    );
}

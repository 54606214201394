import React from "react";
import { useProductCardContext } from "./product-card";
import styles from "./product-card-badges.module.css";

function ProductCardBadges(): React.ReactElement | null {
    const {
        exclusive_badge: exclusiveBadge,
        sale_badge: saleBadge,
        free_shipping_badge: freeShippingBadge,
    } = useProductCardContext();

    if (!exclusiveBadge && !saleBadge && !freeShippingBadge) {
        return null;
    }

    return (
        <>
            {!!exclusiveBadge && <span className={styles.badge}>{exclusiveBadge}</span>}

            {!!saleBadge && <span className={styles.badge}>{saleBadge}</span>}

            {!!freeShippingBadge && <span className={styles.badge}>{freeShippingBadge}</span>}
        </>
    );
}

export default ProductCardBadges;

import clsx from "clsx";
import React, { lazy, Suspense } from "react";
import styles from "./product-gallery-image.module.css";

const ZoomableImage = lazy(
    () =>
        import(
            "web/react/components/product-gallery/product-gallery-zoomable-image/product-gallery-zoomable-image"
        )
);

interface NormalImageProps {
    image: ProductImage;
    onClick: () => void;
    isSingle: boolean;
    hasRelatedProductsSidebar: boolean;
    className?: string;
    width?: number;
    height?: number;
}

interface FullScreenImageProps {
    image: ProductImage;
}

interface ImgProps {
    image: ProductImage;
    className?: string;
    width?: number;
    height?: number;
}

function Img({ image, className, width, height }: ImgProps): React.ReactElement {
    return (
        <img
            alt={image.alt_text}
            src={image.large_url}
            className={className}
            width={width}
            height={height}
        />
    );
}

function NormalImage({
    image,
    onClick,
    isSingle,
    hasRelatedProductsSidebar,
    className,
    width,
    height,
}: NormalImageProps): React.ReactElement {
    return (
        <div
            className={clsx(
                styles.normalImageWrapper,
                {
                    [styles.normalImageWrapperSingle]: isSingle,
                    [styles.normalImageWrapperWithSidebar]: hasRelatedProductsSidebar,
                },
                className
            )}
            onClick={onClick}
        >
            <Img image={image} className={styles.normalImage} width={width} height={height} />
        </div>
    );
}

function FullScreenImage({ image }: FullScreenImageProps): React.ReactElement {
    return (
        <div className={styles.fullscreenImageWrapper}>
            <Suspense fallback={<Img image={image} />}>
                <ZoomableImage image={image} />
            </Suspense>
        </div>
    );
}

interface ImageProps {
    isFullscreen: boolean;
    image: ProductImage;
    onClick: () => void;
    isSingle: boolean;
    hasRelatedProductsSidebar: boolean;
    className?: string;
    width?: number;
    height?: number;
}

function Image({
    isFullscreen = false,
    onClick,
    image,
    isSingle,
    hasRelatedProductsSidebar,
    className,
    width = 520,
    height = 650,
}: ImageProps): React.ReactElement {
    if (isFullscreen) {
        return <FullScreenImage image={image} />;
    }

    return (
        <NormalImage
            className={className}
            image={image}
            onClick={onClick}
            isSingle={isSingle}
            hasRelatedProductsSidebar={hasRelatedProductsSidebar}
            width={width}
            height={height}
        />
    );
}

export default Image;

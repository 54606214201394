import React, { useState } from "react";
import { HintBubble } from "web/react/components/hint-bubble/hint-bubble";
import SVGIcon from "web/react/components/svg-icon/svg-icon";
import { Text } from "web/react/emo/text";
import { View } from "web/react/emo/view";
import useCopyToClipboard from "web/react/hooks/use-copy-to-clip-board";
import { BuyOptionPromotionSerializer } from "web/react/pages/product/in-stock/in-stock-product-page/in-stock-product-page.context";
import analytics from "web/script/analytics/analytics";
import { gettext } from "web/script/modules/django-i18n";
import * as styles from "./pdp-promo.css";

interface PromoProps {
    promotion: BuyOptionPromotionSerializer | null;
    variant?: keyof typeof styles.promoWrapper;
}

export function Promo({ promotion, variant = "card" }: PromoProps): JSX.Element | null {
    const [isCodeCopied, setIsPromoCodeCopied] = useState(false);
    const copy = useCopyToClipboard();

    if (!promotion) return null;

    const { message, promoCode, tcs } = promotion;

    const tcsOptions = tcs.map(({ message, url }) => (
        <a
            href={url}
            key={url}
            className={styles.promoTCsLink}
            target="_blank"
            rel="noopener noreferrer"
        >
            {message}
        </a>
    ));

    function onClick(): void {
        analytics.event("promo-code", "code_clicked", "pdp");
        if (promoCode) {
            copy(promoCode, "pdp");
            setIsPromoCodeCopied(true);
        }
    }

    return (
        <View background="grayscale50" className={styles.promoWrapper[variant]}>
            <Text textStyle={"body-2"} as="span">
                <span
                    data-testid="promo-code"
                    onClick={onClick}
                    dangerouslySetInnerHTML={{ __html: message }}
                />
                <HintBubble
                    tooltipText={<div className={styles.promoTCs}>{tcsOptions}</div>}
                    className={styles.promoTCsIcon}
                />
            </Text>
            {isCodeCopied && (
                <Text textStyle={"body-2"} color="success" as="span" className={styles.promoCopied}>
                    <SVGIcon name={"tick"} className={styles.tickIconPromo} overrideStyle />
                    <Text textStyle={"body-2"} as="span">
                        {gettext("product.buy_area.code_copied.label")}
                    </Text>
                </Text>
            )}
        </View>
    );
}

import React, { useCallback, useEffect, useRef, useState } from "react";
import { ProductDetails } from "web/react/components/product-card/react/emo/product-details/product-details";
import ProductCard from "web/react/components/product-card/react/product-card";
import { ProductCardLinkCuratedAd } from "web/react/components/product-card/react/product-card-link-curated-ad";
import { Rail } from "web/react/components/rail/rail";
import { ProductCardImage } from "web/react/emo/product-card";
import { VStack } from "web/react/emo/v-stack";
import { useAboveFold } from "web/react/hooks/use-above-fold/use-above-fold";
import analytics from "web/script/analytics/analytics";
import { CuratedAdsSerializer } from "web/types/serializers";
import * as styles from "./curated-ads.css";

interface CuratedAdsProps extends CuratedAdsSerializer {
    feedType?: string;
    onOverlay?: boolean;
}

export function CuratedAds({
    title,
    products,
    retailer_network: retailerNetwork,
    context_type: contextType,
    collection_name: collectionName,
    collection_id: collectionId,
    collection_gender: collectionGender,
    collection_country: collectionCountry,
    feedType,
}: CuratedAdsProps): JSX.Element | null {
    const visibleRef = useRef<HTMLDivElement | null>(null);
    const { isAboveFold, isVisible } = useAboveFold(visibleRef);
    const [isLoaded, setIsLoaded] = useState(false);

    const analyticsEventLabel = "curated_ads";

    const sendAnalyticsEvent = useCallback(
        (action) => {
            analytics.event(
                analyticsEventLabel, // category
                action,
                "", // label
                action !== "click", // nonInteraction
                {}, // customData
                "", // subType
                {}, // checkoutData
                [
                    // itemData
                    {
                        item_type: "ad_curated",
                        retailer_network: retailerNetwork,
                        label: collectionName,
                        variant: contextType,
                        above_fold: isAboveFold,
                        collection_name: collectionName,
                        collection_id: collectionId,
                        collection_gender: collectionGender,
                        collection_country: collectionCountry,
                    },
                ]
            );
        },
        [
            isAboveFold,
            contextType,
            collectionName,
            retailerNetwork,
            collectionGender,
            collectionCountry,
            collectionId,
        ]
    );

    useEffect(() => {
        if (!isLoaded && products.length) {
            sendAnalyticsEvent("loaded");
            setIsLoaded(true);
        }
    }, [isLoaded, products.length, sendAnalyticsEvent]);

    useEffect(() => {
        if (products.length && isVisible) {
            sendAnalyticsEvent("impression");
        }
    }, [products.length, isVisible, sendAnalyticsEvent]);

    if (!products.length) {
        return null;
    }

    function handleImageClick(): void {
        analytics.event("product_card", "image", analyticsEventLabel);
    }

    return (
        <div ref={visibleRef}>
            <Rail
                title={title}
                analyticsLabel={analyticsEventLabel}
                listItemClassName={styles.item}
            >
                {products?.map((product) => {
                    return (
                        <ProductCard key={product.id} product={product} dataTestId="product-card">
                            <ProductCardLinkCuratedAd
                                collectionId={collectionId}
                                onClick={() => sendAnalyticsEvent("click")}
                            >
                                <VStack spacing="xs" key={product.uid}>
                                    <div onClick={handleImageClick}>
                                        <ProductCardImage
                                            src={product.image_url}
                                            alt={product.image_alt_text}
                                            lazy={false}
                                        />
                                    </div>

                                    <ProductDetails
                                        reason={`curated_ad-${collectionId}`}
                                        feedType={feedType}
                                        analyticsEventLabel={analyticsEventLabel}
                                        shouldRenderLink={false}
                                        showPromo={false}
                                        showSponsoredMessaging={true}
                                    />
                                </VStack>
                            </ProductCardLinkCuratedAd>
                        </ProductCard>
                    );
                })}
            </Rail>
        </div>
    );
}

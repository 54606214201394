import environment from "web/script/modules/environment";

export function getActiveBlock(flag: string): any {
    const activeWebToAppBlocks = environment.get("activeWebToAppBlocks") || {};
    return activeWebToAppBlocks[flag] || null;
}

export function isActiveBanner(flag: string): boolean {
    const activeWebToAppBanners = environment.get("activeWebToAppBanners") || [];
    return activeWebToAppBanners.includes(flag);
}

import clsx from "clsx";
import AppStoreBlack from "img/ui/app-store-black.svg?inline";
import AppStoreWhite from "img/ui/app-store-white.svg?inline";
import PlayStoreBlack from "img/ui/play-store-black.svg?inline";
import React from "react";
import Button from "web/react/components/__LEGACY__/LEGACY_button/button";
import BranchLink from "web/react/components/branch-link/branch-link";
import { DesktopCampaignRawBranchLink, RawBranchLink } from "web/script/utils/branch-io";
import styles from "./app-download-badge.module.css";

type AppButtonType = "white" | "black";
type AppDownloadLink = "footer" | "appPage" | "appTeaser" | "desktopAppBannerFeeds";

const APP_DOWNLOAD_LINKS: {
    readonly [lnk in AppDownloadLink]: RawBranchLink | DesktopCampaignRawBranchLink;
} = {
    footer: RawBranchLink.Footer,
    appPage: RawBranchLink.AppPage,
    appTeaser: RawBranchLink.AppTeaser,
    desktopAppBannerFeeds: DesktopCampaignRawBranchLink.Feed,
};

interface AppDownloadButtonProps {
    color: AppButtonType;
    appDownloadLink?: AppDownloadLink;
    eventLabel: string;
    isBadge?: boolean;
    small?: boolean;
    isButton?: boolean;
    buttonText?: string;
    badgeType?: "android" | "ios";
    branchLink?: string;
    callback?: null | ((event) => void);
}

export function AppDownloadBadge({
    color,
    small,
    appDownloadLink = "appTeaser",
    eventLabel,
    isButton,
    buttonText = "",
    badgeType = "ios",
    branchLink: _branchLink,
    callback,
}: AppDownloadButtonProps): JSX.Element {
    const branchLink = _branchLink || APP_DOWNLOAD_LINKS[appDownloadLink];
    const svgProps = { height: "80%", width: "100%", viewBox: "0 0 120 40" };

    function appBadge(): JSX.Element {
        if (badgeType === "android") {
            return <PlayStoreBlack {...svgProps} viewBox={"0 0 135 40"} />;
        } else {
            return color === "black" ? (
                <AppStoreBlack {...svgProps} />
            ) : (
                <AppStoreWhite {...svgProps} />
            );
        }
    }

    const smallCSS = badgeType === "android" ? styles.smallAndroid : styles.smallIos;

    if (callback) {
        return (
            <span
                className={clsx(styles.badge, {
                    [smallCSS]: small,
                    [styles.buttonSize]: isButton,
                })}
                onClick={callback}
            >
                <span>
                    {isButton && (
                        <Button styleType="cta" className={styles.downloadButton}>
                            {buttonText}
                        </Button>
                    )}
                    {!isButton && appBadge()}
                </span>
            </span>
        );
    }

    return (
        <BranchLink
            className={clsx(styles.badge, {
                [smallCSS]: small,
                [styles.buttonSize]: isButton,
            })}
            branchLink={branchLink}
            eventLabel={eventLabel}
            title={buttonText}
            primaryButton={isButton}
        >
            <span>{appBadge()}</span>
        </BranchLink>
    );
}

export default AppDownloadBadge;

import PropTypes from "prop-types";
import React from "react";
import BurgerMenuItem from "web/react/components/burger-menu/burger-menu-item/burger-menu-item";
import BurgerMenuSecondaryHeader from "web/react/components/burger-menu/burger-menu-secondary-header/burger-menu-secondary-header";
import analytics from "web/script/analytics/analytics";
import { gettext } from "web/script/modules/django-i18n";
import requester from "web/script/modules/requester";
import style from "./burger-menu-country-selector-menu.module.css";

function BurgerMenuCountrySelectorMenu({
    allCountries,
    currentCountry,
    currentCurrency,
    onBackToMainClick,
}) {
    function onCountryClick(countryCode, newCountry) {
        analytics.event("SETTINGS", "CHANGE_COUNTRY", currentCountry + "-to-" + newCountry);
        requester("/account/set_country/", {
            method: "POST",
            body: { country: countryCode },
            headers: {
                Accept: "text/html",
            },
        }).then(function () {
            window.location.reload(true);
        });
    }

    let countrySelection = allCountries.map((countryTuple) => {
        return (
            <BurgerMenuItem
                key={countryTuple[0]}
                renderText={countryTuple[1]}
                onClickEvent={() => onCountryClick(countryTuple[0], countryTuple[1])}
            />
        );
    });

    return (
        <div>
            <BurgerMenuSecondaryHeader
                renderText={gettext("burger_menu.country_selector.title")}
                onClickEvent={onBackToMainClick}
            />
            <div className={style.selectedCountry}>
                {gettext("burger_menu.country_selector.currently_selected", {
                    country: currentCountry,
                    currency_indicator: currentCurrency,
                })}
            </div>
            <ul id="countryMenuLinks">{countrySelection}</ul>
        </div>
    );
}

export default BurgerMenuCountrySelectorMenu;

BurgerMenuCountrySelectorMenu.propTypes = {
    allCountries: PropTypes.array,
    currentCountry: PropTypes.string,
    currentCurrency: PropTypes.string,
    onBackToMainClick: PropTypes.func.isRequired,
};

BurgerMenuCountrySelectorMenu.defaultProps = {
    menuData: [],
    currentCountry: "",
    currentCurrency: "",
};

import storage from "web/script/utils/storage";

export const STORAGE_KEY = "blockModalCampaigns";
const EXPIRY_DAYS = 1;

interface InteractionDetails {
    hasBeenDismissed: boolean;
    dismissedCount: number;
}

export function getCampaignHasBeenDismissed(campaignName): InteractionDetails {
    const blockModalCampaigns = storage.get(STORAGE_KEY, {});
    const blockModalCampaign = blockModalCampaigns[campaignName];

    // check if the block dismissal has expired
    const blockExpiryTimestamp = blockModalCampaign?.blockExpiry;
    const currentDate = new Date();
    const hasBeenDismissed = blockExpiryTimestamp
        ? currentDate.getTime() <= blockExpiryTimestamp
        : false;

    // if the block dismissal has expired then set the dismissedCount to 0
    const dismissedCount =
        hasBeenDismissed && blockModalCampaign ? blockModalCampaign.dismissedCount : 0;
    return { hasBeenDismissed, dismissedCount };
}

export function setCampaignHasBeenDismissed(campaignName): void {
    const blockModalCampaign = getCampaignHasBeenDismissed(campaignName);
    const newDismissedCount = blockModalCampaign.dismissedCount + 1;

    // set the block dismissal to expire in `EXPIRY_DAYS` days from now
    const currentDate = new Date();
    const expiryDateTimestamp = currentDate.setDate(currentDate.getDate() + EXPIRY_DAYS);
    const blockModalCampaigns = storage.get(STORAGE_KEY, {});
    blockModalCampaigns[campaignName] = {
        blockExpiry: expiryDateTimestamp,
        dismissedCount: newDismissedCount,
    };

    storage.set(STORAGE_KEY, blockModalCampaigns);
}

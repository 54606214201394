import clsx from "clsx";
import React from "react";
import styles from "./icon-button.module.css";

export interface IconButtonProps {
    ["aria-label"]: string;
    className?: string;
    icon: JSX.Element;
    onClick?(): void;
    dataTestId?: string;
}

export function IconButton({
    icon,
    onClick,
    className,
    dataTestId,
    ...props
}: IconButtonProps): JSX.Element {
    const onKeyDown = (event): void => {
        if (onClick && ["Space", "Enter"].includes(event.key)) {
            onClick();
        }
    };

    return (
        <button
            aria-label={props["aria-label"]}
            onClick={onClick}
            onKeyDown={onKeyDown}
            className={clsx(styles.button, className)}
            type="button"
            data-testid={dataTestId}
        >
            {icon}
        </button>
    );
}

import baustein from "baustein";
import GenericOverlay from "web/script/components/overlays/generic-overlay";

export default baustein.register("generic-overlay-launcher", {
    defaultOptions: {
        contentUrl: null,
        eventCategory: null,
        eventAction: null,
        eventLabel: null,
        overlaySize: "large",
    },

    setupEvents(add) {
        add("click", this.launchOverlay);
    },

    launchOverlay(event) {
        event.preventDefault();

        const existing = baustein.getInstancesOf("generic-overlay");

        // close any existing overlays
        Promise.all(existing.map((overlay) => overlay.close())).then(() => {
            // now destroy them
            existing.forEach((overlay) => overlay.destroy());

            const contentUrl = this.options.contentUrl || this.el.href;

            const overlay = new GenericOverlay({
                contentUrl,
                eventCategory: this.options.eventCategory,
                eventAction: this.options.eventAction,
                eventLabel: this.options.eventLabel,
                overlaySize: this.options.overlaySize,
            });

            // dirty but appending the overlay into an `<a>` does wierd things...
            overlay.appendTo(document.body);

            overlay.open();
        });
    },
});

import clsx from "clsx";
import React from "react";
import Rail from "web/react/components/LEGACY_rail/rail";
import ProductCard from "web/react/components/product-card/react/product-card";
import { ProductCardSerializer } from "web/types/serializers";
import styles from "./product-rail.module.css";

interface ProductRailProps {
    label: string;
    products: ProductCardSerializer[];
    title: string;
    id?: string;
    viewMoreUrl?: string;
}

export function ProductRail({
    label,
    products,
    title,
    viewMoreUrl,
    id,
}: ProductRailProps): JSX.Element {
    const leftButtonStyle = clsx(styles.scrollButton, styles.left);
    const rightButtonStyle = clsx(styles.scrollButton, styles.right);

    return (
        <Rail
            analyticsLabel={label}
            className={styles.rail}
            railClassName={styles.railList}
            seeAllLink={viewMoreUrl}
            title={title}
            titlePosition="center"
            leftButtonStyle={leftButtonStyle}
            rightButtonStyle={rightButtonStyle}
            id={id}
        >
            {products.map((product) => {
                return (
                    <ProductCard key={product.id} product={product} className={styles.productCard}>
                        <ProductCard.Image width={200} height={250} analyticsEventLabel={label}>
                            <ProductCard.Badges />
                        </ProductCard.Image>

                        <ProductCard.SaveForLaterButton isAffiliate={product.is_affiliate} />

                        <ProductCard.Details analyticsEventLabel={label} />
                    </ProductCard>
                );
            })}
        </Rail>
    );
}

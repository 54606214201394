/**
 * This is the standard Facebook Tag Manager code modified so that it loads when the main page is
 ready.
 * Ensure you export the function it creates (it usually attaches itself to the window object).
 * If ever Facebook change this, it should just be a matter of copy and pasting the script into
 * here with some modification.
 *
 * Current version Retrieved from:
 * https://business.facebook.com/ads/manager/pixel/facebook_pixel/?act=104048399696450&pid=p1&business_id=813506668696503
 */
import loader from "./loader";

// Facebook code: Moved outside from the loader
var n = (window.fbq = function () {
    n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments);
});
if (!window._fbq) {
    window._fbq = n;
}
n.push = n;
n.loaded = true;
n.version = "2.0";
n.queue = [];

const load = loader(() => {
    // Facebook code
    !(function (f, b, e, v, n, t, s) {
        t = b.createElement(e);
        t.async = !0;
        t.src = v;
        s = b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t, s);
    })(window, document, "script", "https://connect.facebook.net/en_US/fbevents.js");
});

export default function () {
    load();
    return window.fbq(...arguments);
}

import { CheckoutPaymentInformationSerializer } from "web/types/serializers";

export interface PaymentInformation {
    paymentFormLast4: string | null;
    paymentFormCardType: PaymentCardProvider | null;
}

// To support both Stripe Token [0] and Stripe PaymentMethod [1] we need to map the card brands
// from both APIs which differ on `amex` and `diners` interpretations.
// [0] https://stripe.com/docs/api/tokens/object#token_object-card-brand
// [1] https://stripe.com/docs/api/payment_methods/object#payment_method_object-card-brand
export const STRIPE_CARD_BRAND_MAP = {
    "american express": "amex",
    amex: "amex",
    diners: "diners_club",
    "diners club": "diners_club",
    discover: "discover",
    jcb: "jcb",
    mastercard: "mastercard",
    unionpay: "unionpay",
    visa: "visa",
    unknown: "",
} as const;

// Human readable
export type StripePaymentCardProvider = keyof typeof STRIPE_CARD_BRAND_MAP;

// Not perfect, but should hopefully highlight where type is used on FE but not understood on BE
export type PaymentCardProvider = (typeof STRIPE_CARD_BRAND_MAP)[StripePaymentCardProvider] &
    NonNullable<CheckoutPaymentInformationSerializer["card_provider"]>;

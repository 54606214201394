import React, { Dispatch, SetStateAction, createContext, useContext, useState } from "react";

interface FeedContextProps {
    feedFetched: boolean;
    setFeedFetched: Dispatch<SetStateAction<boolean>>;
}
const FeedContext = createContext<FeedContextProps>({
    feedFetched: false,
    setFeedFetched: () => {},
});

interface FeedContextProviderProps {
    children: React.ReactNode;
}

export function FeedContextProvider({ children }: FeedContextProviderProps): React.ReactElement {
    const [feedFetched, setFeedFetched] = useState(true);
    const value = { feedFetched, setFeedFetched };
    return <FeedContext.Provider value={value}>{children}</FeedContext.Provider>;
}

export function useFeedContext(): FeedContextProps {
    return useContext(FeedContext);
}

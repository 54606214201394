/**
 * @file suggestion-result.tsx
 * @description Single result found in AutoSuggestion component
 * @author Harry Rhodes
 */
import clsx from "clsx";
import React, { useState } from "react";
import { useAutoSuggestContext } from "web/react/components/navigation/search-bar/auto-suggest/auto-suggest.context";
import { ListItem } from "web/react/emo/list-item/list-item";
import { Text } from "web/react/emo/text";
import { useDomViewport } from "web/react/hooks/use-dom-viewport/use-dom-viewport";
import { ProfileSuggestion, QuerySuggestion, RecentSearchSerializer } from "web/types/serializers";
import * as styles from "./suggestion-result.css";

/**
 * @typedef ConditionalSuggestionResultProps
 * @description shared type used to infer props depending on the variant
 */
type ConditionalSuggestionResultProps =
    | {
          variant: "profile";
          profileSuggestion: ProfileSuggestion;
          querySuggestion?: never;
          recentSearch?: never;
      }
    | {
          variant: "query";
          profileSuggestion?: never;
          querySuggestion: QuerySuggestion;
          recentSearch?: never;
      }
    | {
          variant: "recent";
          profileSuggestion?: never;
          querySuggestion?: never;
          recentSearch: RecentSearchSerializer;
      };

type CommonSuggestionResultProps = {
    index: number;
};

type SuggestionResultProps = CommonSuggestionResultProps & ConditionalSuggestionResultProps;

export function SuggestionResult({
    index,
    variant,
    profileSuggestion,
    querySuggestion,
    recentSearch,
}: SuggestionResultProps): React.ReactElement {
    const { activeSuggestionIndex, makeSuggestionActive, onSuggestionClick } =
        useAutoSuggestContext();
    const [touchMoved, setTouchMoved] = useState<boolean>(false);
    const { isMobileViewport, isTabletViewport } = useDomViewport();
    /**
     * Used by touch device
     */
    function onTouchMove(): void {
        setTouchMoved(true);
    }
    /**
     *Used by touch device
     */
    function onTouchStart(): void {
        setTouchMoved(false);
    }

    /**
     * Used by touch device, calls onSuggestionClick only when it's not scrolling
     */
    function onTouchEnd(event): void {
        if (!touchMoved) {
            onSuggestionClick(event);
        }
    }

    function onMouseEnter(event): void {
        makeSuggestionActive(parseInt(event.target.dataset.suggestionIndex, 0));
    }

    function onMouseLeave(event): void {
        makeSuggestionActive(parseInt(event.target.dataset.suggestionIndex, 0));
    }

    const notDesktop = isMobileViewport || isTabletViewport;
    const size =
        variant === "query" || (variant === "recent" && recentSearch.subtitle === "" && notDesktop)
            ? "xs"
            : "sm";

    return (
        <div className={styles.suggestion}>
            <ListItem
                size={size}
                className={clsx(
                    styles.listItem,
                    activeSuggestionIndex === index && styles.activeSuggestion
                )}
                data-suggestion-type={variant}
                data-suggestion-index={index}
                onTouchStart={onTouchStart}
                onTouchMove={onTouchMove}
                onTouchEnd={onTouchEnd}
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
                onMouseDown={onSuggestionClick}
                showChevron
                data-testid="suggestion-result"
            >
                {variant === "profile" && profileSuggestion && (
                    <div className={styles.imageWrapper}>
                        <img
                            className={styles.image}
                            src={profileSuggestion.image}
                            alt={profileSuggestion.name}
                        />
                    </div>
                )}
                {(variant === "profile" && profileSuggestion) ||
                (variant === "recent" && recentSearch) ? (
                    <div className={styles.profileInfo}>
                        <Text textStyle={"body-3-small"}>
                            {variant === "profile" && profileSuggestion
                                ? profileSuggestion.name
                                : recentSearch.title}
                        </Text>
                        <Text textStyle={"footnote"} className={styles.footnote}>
                            {variant === "profile" && profileSuggestion
                                ? profileSuggestion.profile_type
                                : recentSearch.subtitle}
                        </Text>
                    </div>
                ) : (
                    querySuggestion.query
                )}
            </ListItem>
            <div className={styles.divider} />
        </div>
    );
}

import React from "react";
import LazyImage from "web/react/components/lazy-load-image/lazy-load-image";
import { useProductCardContext } from "./product-card";
// eslint-disable-next-line css-modules/no-unused-class
import styles from "./product-card-image.module.css";

// Curated Ads products lack the features and analytics that most product cards have.
type ProductCardImageCuratedAdProps = Record<string, never>;

export function ProductCardImageCuratedAd({}: ProductCardImageCuratedAdProps): React.ReactElement | null {
    const width = 125;
    const height = 156;
    const { image_url: imageURL, image_alt_text: imageAltText } = useProductCardContext();

    if (!imageURL) {
        return <div style={{ position: "relative", width, height }}></div>;
    }

    return (
        <div className={styles.imageWrapper}>
            <LazyImage
                className={styles.image}
                width={width}
                height={height}
                src={imageURL}
                alt={imageAltText}
                data-pin-no-hover="true"
                placeholder={`data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 ${width} ${height}"3E%3C/svg%3E`}
            />
        </div>
    );
}

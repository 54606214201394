import PropTypes from "prop-types";

export const ProductImagePropType = PropTypes.shape({
    // Desktop thumbnail
    thumbnail_url: PropTypes.string.isRequired,
    // Mobile cover image
    large_url: PropTypes.string.isRequired,
    large_url_2x: PropTypes.string,
    // Used by e.g. product-gallery-image
    full_size_url: PropTypes.string,
    alt_text: PropTypes.string,
});

export const ProductPropType = PropTypes.shape({
    product_id: PropTypes.string.isRequired,
    link_ids: PropTypes.arrayOf(PropTypes.string).isRequired,

    canonical_slug: PropTypes.string.isRequired,
    canonical_url: PropTypes.string.isRequired,

    short_description: PropTypes.string.isRequired,
    long_description: PropTypes.string.isRequired,

    color: PropTypes.string.isRequired,
    designer: PropTypes.string.isRequired,

    images: PropTypes.arrayOf(ProductImagePropType).isRequired,

    more_descriptions: PropTypes.shape({
        descriptions: PropTypes.arrayOf(
            PropTypes.shape({
                from_retailer_name: PropTypes.string,
                short_description: PropTypes.string,
                description: PropTypes.string,
            })
        ),
    }),
});

export const ProductRetailerPropType = PropTypes.shape({
    image: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
});

import _chunk from "lodash/chunk";
import _each from "lodash/each";
import _escape from "lodash/escape";
import _first from "lodash/first";
import _last from "lodash/last";
import _merge from "lodash/merge";
import _size from "lodash/size";

const OBJECT_TYPE_REGEX = /\[object (.*?)]/;

const runtime = {
    type(o: Record<string, any>): string {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        return Object.prototype.toString.call(o).match(OBJECT_TYPE_REGEX)![1];
    },

    boolean(o: any): boolean {
        if (!o) {
            return false;
        }
        if (o === true) {
            return o;
        }
        if (Array.isArray(o)) {
            return o.length > 0;
        }
        if (runtime.type(o) === "Object") {
            return Object.keys(o).length > 0;
        }
        return !!o;
    },

    // TODO: This can almost certainly be replaced with lodash/isEqual, but we
    //       need to add a test to make sure the URLSearchParams equality bug doesn't
    //       crop up (also test other objects like URL?)
    isEqual(objA: any, objB: any): boolean {
        if (objA === objB) {
            return true;
        }

        let type = runtime.type(objA);

        if (type != runtime.type(objB)) {
            return false;
        } else if (type != "Array" && type != "Object") {
            return false;
        }

        if (type == "Array") {
            if (objA.length != objB.length) {
                return false;
            }

            for (let i = 0; i <= objA.length; i++) {
                if (objA[i] !== objB[i]) {
                    return false;
                }
            }
            return true;
        }

        let keys = Object.keys(objA);
        if (!runtime.isEqual(keys, Object.keys(objB))) {
            return false;
        }

        return keys.every((key) => runtime.isEqual(objA[key], objB[key]));
    },

    escape(str: string): string {
        return (
            _escape(str)
                // Jinja uses different escape sequences to underscore for some reason
                .replace(/&quot;/g, "&#34;")
                .replace(/&#x27;/g, "&#39;")
        );
    },

    each: _each,
};

export interface JinjaFilters {
    [name: string]: (...args: any[]) => any;
}
export interface JinjaGlobals {
    [name: string]: any;
}

const filters: JinjaFilters = {
    capitalize(s: string): string {
        return s ? s[0].toUpperCase() + s.substring(1).toLowerCase() : s;
    },

    batch<T>(arr: T[], size: number, fillWith?: T): T[][] {
        let batched = _chunk<T>(arr, size);
        let last = batched[batched.length - 1];
        if (last && last.length < size && fillWith !== undefined) {
            for (let i = 0; i < size - last.length; i++) {
                last.push(fillWith);
            }
        }

        return batched;
    },

    default(obj: any, defaultValue = "", boolean = false): any {
        let test: boolean;

        if (boolean) {
            test = runtime.boolean(obj);
        } else {
            test = obj !== undefined;
        }

        return test ? obj : defaultValue;
    },

    int(value: string, defaultValue = 0): number {
        let result = parseInt(value, 10);
        return isNaN(result) ? defaultValue : result;
    },

    slice<T>(value: T[], slices: number, fillWith?: T): T[][] {
        let length = value.length;
        let itemsPerSlice = Math.floor(length / slices);
        let slicesWithExtra = length % slices;
        let offset = 0;
        let result: T[][] = [];

        for (let i = 0; i < slices; i++) {
            let start = offset + i * itemsPerSlice;

            if (i < slicesWithExtra) {
                offset += 1;
            }

            let end = offset + (i + 1) * itemsPerSlice;
            let tmp = value.slice(start, end);

            if (fillWith != null && i >= slicesWithExtra) {
                tmp.push(fillWith);
            }

            result.push(tmp);
        }

        return result;
    },

    title(s: string): string {
        s = s + "";
        return s.replace(/\S+/g, filters.capitalize);
    },

    truncate(s: string, length = 255, killwords = false, end = "...", leeway = 5): string {
        s = s + "";

        let endLength = end.length;

        if (s.length <= length + leeway) {
            return s;
        } else if (killwords) {
            return s.substring(0, length - endLength) + end;
        }

        let s2 = s.substring(0, length - endLength).split(" ");
        if (s2.length > 1) {
            s2.pop();
        }
        s = s2.join(" ");
        return s + end;
    },

    first: _first,
    last: _last,
    size: _size,
};

const globals: JinjaGlobals = {};

const jinjaToJS = {
    filters,
    runtime,
    // You may override this to provide custom methods within the templates
    globals,
    createContext(context: JinjaGlobals): JinjaGlobals {
        return _merge({}, jinjaToJS.globals, context);
    },
};

export type JinjaToJS = typeof jinjaToJS;

export default jinjaToJS;

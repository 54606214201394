import React, { useCallback, useEffect, useReducer } from "react";
import { useSelector } from "react-redux";
import { BrandAdContainer } from "web/react/components/brand-ad-container/brand-ad-container";
import { useRothkoFetcher } from "web/react/hooks/use-rothko-fetcher";
import { ReduxStoreState } from "web/redux/store";
import logging from "web/script/utils/logging";
import { BrandAdSerializer } from "web/types/serializers";
import { InputFilterOption, UniversalFiltersData } from "web/types/universal-filters";

// Common utility types
// universal filters may not exist yet, or at all.
type MaybeUniversalFiltersData = undefined | UniversalFiltersData;
// gender filter may not exist, null means it's there but has no useful value.
type MaybeKeyword = undefined | null | string;
// params for rothko call to kevel.
type RequestKeywords = Record<string, string>;
// which may or may not respond with an ad.
type MaybeBrandAdSerializer = null | BrandAdSerializer;

const ENDPOINT = "modules/brand_ads";

// Helper to transform request keywords from a list to an object.
function unpackRequestKeywords(keywords: string[]): RequestKeywords {
    return keywords.reduce((xs, x) => {
        const [key, ...values] = x.split(".");
        xs[key] = values.length ? values[0] : "";
        return xs;
    }, {});
}

// Helper to extract a list of options from deep within universal filters.
export function extractOptions(
    filters: MaybeUniversalFiltersData,
    sectionId: string
): InputFilterOption[] {
    if (typeof filters === "undefined") {
        return [];
    }
    const section = filters?.filter_section_list.find((section) => section.id === sectionId);

    return (section?.option_groups[0].options as InputFilterOption[]) || [];
}

// Helper to extract the active gender, or null if no single gender is selected.
export function extractActiveGenderFilter(options: InputFilterOption[]): MaybeKeyword {
    const checkedOptions = options.filter((x) => x.checked);
    if (checkedOptions.length === 1) {
        switch (checkedOptions[0].value) {
            case "Men":
                return "M";
            case "Women":
                return "F";
        }
    }
    return null;
}

// Helper to get the filter gender, or undefined if there isn't a gender filter.
function getActiveGenderFilter(filters: MaybeUniversalFiltersData): MaybeKeyword {
    const options = extractOptions(filters, "section-gender");
    if (options.length) {
        return extractActiveGenderFilter(options);
    }
    return undefined;
}

// State and Reducer
interface BrandAdState {
    requestKeywords: RequestKeywords;
    currentAd: MaybeBrandAdSerializer;
    knownAds: Record<string, MaybeBrandAdSerializer>;
    newAdRequired: boolean;
}

function brandAdPropsReducer(state: BrandAdState, action): BrandAdState {
    // No immutability helper, use assignment w/o declaration.
    let requestKeywords;
    let currentAd;
    let knownAds;
    let newAdRequired;
    switch (action.type) {
        case "swapGender":
            // Update the gender request keyword.
            // Either switch to known ad or set a flag requesting a new one.
            ({ requestKeywords, currentAd, knownAds, newAdRequired } = state);
            const { activeFilterGender } = action.payload;
            requestKeywords.gender = activeFilterGender;
            if (activeFilterGender in knownAds) {
                currentAd = knownAds[activeFilterGender];
            } else {
                newAdRequired = true;
            }
            return { requestKeywords, currentAd, knownAds, newAdRequired };

        case "newAdRequested":
            // Wait for an async response.
            ({ requestKeywords, currentAd, knownAds } = state);
            return { requestKeywords, currentAd, knownAds, newAdRequired: false };

        case "newAdReady":
            //  Update the current ad, record in known ads.
            ({ requestKeywords, knownAds, newAdRequired } = state);
            currentAd = action.payload.currentAd;
            if (requestKeywords?.gender) {
                knownAds[requestKeywords.gender] = currentAd;
            }
            return { requestKeywords, currentAd, knownAds, newAdRequired };

        case "newAdNotFound":
            // Set current and known ad to null.
            ({ requestKeywords, knownAds, newAdRequired } = state);
            if (requestKeywords?.gender) {
                knownAds[requestKeywords.gender] = null;
            }
            return { requestKeywords, currentAd: null, knownAds, newAdRequired };

        default:
            throw new Error("incorrect action");
    }
}

// Prepare the props for the reducer.
function getInitialKeywordsAndState(props): {
    initialKeywords: RequestKeywords;
    initialState: BrandAdState;
} {
    const initialKeywords = unpackRequestKeywords(props.request_keywords);
    if (!["M", "F"].includes(initialKeywords.gender)) {
        initialKeywords.gender = "F";
    }
    const initialState = {
        currentAd: props,
        requestKeywords: initialKeywords,
        knownAds: { [initialKeywords.gender]: props },
        newAdRequired: false,
    };
    return { initialKeywords, initialState };
}

// Component
function BrandAdLoader(props: BrandAdSerializer): null | JSX.Element {
    const [rothkoFetch, rothkoisFetching] = useRothkoFetcher<MaybeBrandAdSerializer>(ENDPOINT);
    const { initialKeywords, initialState } = getInitialKeywordsAndState(props);
    const [brandAdState, dispatch] = useReducer(brandAdPropsReducer, initialState);
    const activeFilters = useSelector(
        (state: ReduxStoreState): MaybeUniversalFiltersData => state.feedFiltersReducer.active
    );

    // If no single gender is selected, count this as whatever the initial gender was.
    let activeFilterGender: MaybeKeyword = getActiveGenderFilter(activeFilters);
    if (activeFilterGender === null) {
        activeFilterGender = initialKeywords.gender;
    }
    // Async callback for rothko request actions.
    const onFetchRequired = useCallback(
        async (requestKeywords) => {
            dispatch({
                type: "newAdRequested",
            });
            try {
                const brandAdData = await rothkoFetch(requestKeywords);
                if (brandAdData) {
                    dispatch({
                        type: "newAdReady",
                        payload: { currentAd: brandAdData },
                    });
                } else {
                    dispatch({
                        type: "newAdNotFound",
                    });
                }
            } catch (error: any) {
                logging.error("Failed to fetch brand ad", error);
            }
        },
        [rothkoFetch]
    );

    // Make rothko requests when required to do so.
    useEffect(() => {
        if (brandAdState.newAdRequired) {
            onFetchRequired(brandAdState.requestKeywords);
        }
    }, [onFetchRequired, brandAdState]);

    // Swap the gender if it no longer matches the current state.
    useEffect(() => {
        if (
            !rothkoisFetching &&
            typeof activeFilterGender === "string" &&
            activeFilterGender !== brandAdState.requestKeywords.gender
        ) {
            dispatch({ type: "swapGender", payload: { activeFilterGender } });
        }
    }, [rothkoisFetching, activeFilterGender, brandAdState.requestKeywords.gender]);

    // Show current ad if there is one.
    if (brandAdState.currentAd) {
        return <BrandAdContainer {...brandAdState.currentAd} />;
    }

    return null;
}

export default BrandAdLoader;

import clsx from "clsx";
import React from "react";
import SVGIcon from "web/react/components/svg-icon/svg-icon";
import { gettext } from "web/script/modules/django-i18n";
import { BrandValueItemSerializer, BrandValueSerializer } from "web/types/serializers";
import styles from "./brand-value.module.css";

interface JoinItemProps {
    paragraph: string;
    joinUrl: string;
}

export function JoinItem({ paragraph, joinUrl }: JoinItemProps): JSX.Element {
    return (
        <a href={joinUrl}>
            <div className={styles.joinItem}>
                <p className={styles.joinParagraph}>{paragraph}</p>
                <span className={styles.joinUrl}>
                    {
                        gettext("homepage.module_value_props_join_button") // Join
                    }
                    <SVGIcon className={styles.arrowIcon} name={"arrow-icon"} />
                </span>
            </div>
        </a>
    );
}

interface StartShoppingItemProps {
    url: string;
    ctaText: string;
}

export function StartShoppingItem({ url, ctaText }: StartShoppingItemProps): JSX.Element {
    return (
        <a href={url}>
            <div className={styles.joinItem}>
                <div className={styles.startShoppingUrl}>
                    {ctaText}
                    <SVGIcon className={styles.arrowIcon} name={"arrow-icon"} />
                </div>
            </div>
        </a>
    );
}

interface BrandValueItemProps {
    value: BrandValueItemSerializer;
    numeral: number;
}

export function BrandValueItem({ value, numeral }: BrandValueItemProps): JSX.Element {
    return (
        <li className={styles.homepageBrandValueItem}>
            <h2 className={styles.numeral}> {numeral} </h2>
            <h4 className={styles.title}> {value.title} </h4>
            {value.paragraphs.map((paragraph) => (
                <p key={paragraph} className={styles.paragraph}>
                    {paragraph}
                </p>
            ))}
        </li>
    );
}

interface BrandValueProps extends BrandValueSerializer, ReactComponentProps {}

function BrandValue({
    className,
    items,
    join_paragraph: joinParagraph,
    join_url: joinUrl,
    start_shopping_cta_text: startShoppingCtaText = "",
    start_shopping_url: startShoppingUrl = "",
}: BrandValueProps): JSX.Element {
    return (
        <div data-testid="brand-value-module" className={clsx(styles.wrapper, className)}>
            <div className={styles.valuesContainer}>
                <ul className={styles.homepageBrandValuesList}>
                    {items.map((value, index) => (
                        <BrandValueItem key={value.title} value={value} numeral={index + 1} />
                    ))}
                </ul>
            </div>
            <div className={styles.actionContainer}>
                {startShoppingUrl && startShoppingCtaText ? (
                    <StartShoppingItem ctaText={startShoppingCtaText} url={startShoppingUrl} />
                ) : (
                    <JoinItem paragraph={joinParagraph} joinUrl={joinUrl} />
                )}
            </div>
        </div>
    );
}

export default BrandValue;

import React from "react";
import { PromoBanner } from "web/react/components/promo-section/promo-section";
import desktop from "./image-assets/DESKTOP.jpg";
import mobile from "./image-assets/MOBILE.jpg";
import tablet from "./image-assets/TABLET.jpg";
import desktopXl from "./image-assets/XL_DESKTOP.jpg";

interface AppDownloadPromoProps {
    branchLink: string;
}

export default function AppDownloadPromo({ branchLink }: AppDownloadPromoProps): JSX.Element {
    return (
        <PromoBanner
            url={branchLink}
            mobile={mobile}
            tablet={tablet}
            desktop={desktop}
            desktop_xl={desktopXl}
            alt_text="Membership banner"
            is_branch_link={true}
        />
    );
}

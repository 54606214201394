import React, { useEffect, useState } from "react";
import { Button } from "web/react/emo/button";
import { useClickTracker } from "web/react/hooks/use-click-tracker/use-click-tracker";
import analytics from "web/script/analytics/analytics";
import { BranchIOLinkData, getBranchCampaignName, getLinkData } from "web/script/utils/branch-io";
import { NotQuiteAURL } from "web/script/utils/url";

interface BranchLinkProps {
    branchLink: string;
    title: string;
    className?: string;
    children?: JSX.Element;
    eventLabel?: string;
    primaryButton?: boolean;
    appDeepLinkURL?: URL | NotQuiteAURL;
    showAppIcon?: boolean;
    financialIncentiveWebviewToken?: string;
    beforeOnClick?: React.EventHandler<React.MouseEvent>;
}

function BranchLink({
    title,
    className,
    eventLabel,
    branchLink,
    primaryButton,
    appDeepLinkURL,
    showAppIcon = false,
    financialIncentiveWebviewToken,
    beforeOnClick,
    children,
}: BranchLinkProps): JSX.Element {
    let trackedClick = useClickTracker("web_to_app", "click", eventLabel, true, {
        campaign_name: getBranchCampaignName(branchLink),
    });

    function onClick(event): void {
        beforeOnClick?.(event);
        trackedClick(event);
    }

    const [branchHref, setBranchHref] = useState<string>(branchLink);

    useEffect(() => {
        function updateHref(): void {
            const branchReferralLink = new URL(branchLink);
            const branchIOLinkData: BranchIOLinkData = getLinkData(
                appDeepLinkURL,
                financialIncentiveWebviewToken
            );
            for (const branchDataProperty in branchIOLinkData) {
                branchReferralLink.searchParams.set(
                    branchDataProperty,
                    branchIOLinkData[branchDataProperty]
                );
            }

            setBranchHref(branchReferralLink.toString());
        }

        if (!branchLink) return;

        updateHref();
        analytics.on("pageViewIdChanged", updateHref);
        return function cleanup(): void {
            analytics.off("pageViewIdChanged", updateHref);
        };
    }, [branchLink, appDeepLinkURL, financialIncentiveWebviewToken]);

    if (primaryButton) {
        return (
            <Button
                onClick={onClick}
                title={title}
                width="full"
                href={branchHref}
                icon={showAppIcon ? "lyst-app-icon" : undefined}
            />
        );
    }

    return (
        <a
            onClick={onClick}
            className={className}
            href={branchHref}
            title={title}
            aria-label={title}
        >
            {children}
        </a>
    );
}

export default BranchLink;

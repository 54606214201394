import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector as useReduxSelector } from "react-redux";
import { useDomViewport } from "web/react/hooks/use-dom-viewport/use-dom-viewport";
import { useOverlayWatcher } from "web/react/hooks/use-overlay-watcher/use-overlay-watcher";
import { closeSidebar, openSidebar } from "web/redux/ducks/feed-sidebar";
import { ReduxStoreState } from "web/redux/store";
import history from "web/script/utils/history";

function extractSidebarState(state: ReduxStoreState): boolean {
    return state.feedSidebarReducer.open;
}

export const FILTERS_OVERLAY_NAME = "feeds-filters";

export function openFeedsFiltersOverlay(): void {
    if (history.getCurrentState().overlay !== FILTERS_OVERLAY_NAME) {
        // Disable `saveScrollPosition` because on filters changes
        // we tend to scroll the user to the top of the Feed, in order to see the new products.
        history.pushOverlay(FILTERS_OVERLAY_NAME, { saveScrollPosition: false });
    }
}

/**
 * Makes sure we don't accidentally show the overlay on desktop
 * TODO: Do we actually still need this?
 */
function useDesktopProtection(overlayIsOpen: boolean): void {
    const { isDesktopViewport } = useDomViewport();

    useEffect(() => {
        if (overlayIsOpen && isDesktopViewport) {
            history.popOverlay(FILTERS_OVERLAY_NAME);
        }
        // We only want to run this hook when the width changes
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isDesktopViewport]);
}

enum CloseType {
    None,
    Cancel,
    Apply,
}

/**
 * Handles the mobile overlay
 */
export function useOverlayHandler(
    applyState: () => void,
    cancelState: () => void
): {
    applyAndClose: () => void;
    cancelAndClose: () => void;
    overlayIsOpen: boolean;
} {
    const reduxOpenState = useReduxSelector(extractSidebarState);
    const dispatch = useDispatch();
    const overlayIsOpen = !!useOverlayWatcher(FILTERS_OVERLAY_NAME);

    useDesktopProtection(overlayIsOpen);

    // Force redux to keep in sync with the overlay state
    useEffect(() => {
        if (!reduxOpenState && overlayIsOpen) {
            dispatch(openSidebar());
        } else if (reduxOpenState && !overlayIsOpen) {
            dispatch(closeSidebar());
        }
    }, [dispatch, reduxOpenState, overlayIsOpen]);

    const [closeType, setCloseType] = useState(CloseType.None);

    const cancelAndClose = useCallback(
        function cancelAndClose(): void {
            if (!overlayIsOpen) {
                return;
            }
            setCloseType(CloseType.Cancel);
            history.popOverlay(FILTERS_OVERLAY_NAME);
        },
        [overlayIsOpen]
    );

    const applyAndClose = useCallback(
        function applyAndClose(): void {
            if (!overlayIsOpen) {
                return;
            }
            setCloseType(CloseType.Apply);
            history.popOverlay(FILTERS_OVERLAY_NAME);
        },
        [overlayIsOpen]
    );

    useEffect(() => {
        if (!overlayIsOpen) {
            if (closeType === CloseType.Apply) {
                applyState();
            } else {
                // closeType may be CloseType.None here, which means the user
                // hit the back button on their phone.
                cancelState();
            }
        }
        // Reset the close type on both open and close to avoid suprises
        setCloseType(CloseType.None);
        // We only want this effect hook to run when the overlay flips from one state to another
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [overlayIsOpen]);

    return {
        overlayIsOpen: closeType !== CloseType.None ? false : overlayIsOpen,
        applyAndClose,
        cancelAndClose,
    };
}

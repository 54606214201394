import React, { useEffect, useState } from "react";
import { Button } from "web/react/emo/button";
import { View } from "web/react/emo/view";
import { useInStockProductPageContext } from "web/react/pages/product/in-stock/in-stock-product-page/in-stock-product-page.context";
import { CheckoutModal } from "web/react/pages/product/in-stock/in-stock-product-page/product-price-comparison/checkout-modal";
import { getBuyOptionStickerType } from "web/react/pages/product/in-stock/in-stock-product-page/utils";
import analytics from "web/script/analytics/analytics";
import { gettext } from "web/script/modules/django-i18n";
import { ProductBuyOptionsSerializer, ProductSerializer } from "web/types/serializers";
import { Header } from "./header";
import * as styles from "./price-comparison-table.css";
import { Row } from "./row";

export const sharedStyles = styles;
export function PriceComparisonTable({
    product,
    buyOptions,
    cheapestBuyOptionId,
    termsUrl,
}: {
    product: ProductSerializer;
    buyOptions: ProductBuyOptionsSerializer[];
    cheapestBuyOptionId: number;
    termsUrl: string;
}): JSX.Element {
    const {
        sizePicker: { setSelectedSizeOption },
        checkoutModal: { modalBuyOption, setIsModalOpen, isModalOpen },
    } = useInStockProductPageContext();

    // If more then 5 buyOptions we only show the first 5
    const displayedBuyOptionsLimit = 5;
    const [displayedBuyOptions, setDisplayedBuyOptions] = useState(
        buyOptions.slice(0, displayedBuyOptionsLimit)
    );

    // count of the not displayed buyOptions
    const [notDisplayedBuyOptionsCount, setNotDisplayedBuyOptionsCount] = useState(
        buyOptions.length - displayedBuyOptionsLimit
    );

    useEffect(() => {
        // Ignore if the buy options did not change
        if (
            JSON.stringify(buyOptions.map((buyOption) => buyOption.link_id)) ===
            JSON.stringify(displayedBuyOptions.map((buyOption) => buyOption.link_id))
        ) {
            return;
        }

        setDisplayedBuyOptions(buyOptions.slice(0, displayedBuyOptionsLimit));
        setNotDisplayedBuyOptionsCount(buyOptions.length - displayedBuyOptionsLimit);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [buyOptions]);

    function onClick(): void {
        setDisplayedBuyOptions(buyOptions);
        analytics.event("see-more-prices", "clicked", "pdp");
    }

    const closeCheckoutModal = (): void => {
        setIsModalOpen(false);
        setSelectedSizeOption(null);
    };

    return (
        <div data-testid="comparison-table">
            <View className={styles.comparisonWrapper}>
                <Header />
                {displayedBuyOptions.map((buyOption, index) => {
                    const stickerType = getBuyOptionStickerType(
                        product,
                        buyOption,
                        cheapestBuyOptionId
                    );

                    return (
                        <Row
                            index={index}
                            buyOption={buyOption}
                            key={buyOption.link_id}
                            stickerType={stickerType}
                            termsUrl={termsUrl}
                        />
                    );
                })}
            </View>
            {displayedBuyOptions.length < buyOptions.length && (
                <div data-testid="see-more-prices" className={styles.seeMoreButton}>
                    <Button
                        title={
                            // "See more {count} prices"
                            gettext("in_stock_product.compare_prices.see_more_prices_cta", {
                                count: notDisplayedBuyOptionsCount,
                            })
                        }
                        onClick={onClick}
                        variant="secondary"
                    />
                </div>
            )}
            {/* if we have no size picker we need a CheckoutModal to capture size of checkout BO */}
            <CheckoutModal
                buyOption={modalBuyOption}
                isOpen={isModalOpen}
                setIsOpen={closeCheckoutModal}
            />
        </div>
    );
}

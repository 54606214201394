import React, { useEffect, useRef } from "react";
import analytics from "web/script/analytics/analytics";
import environment from "web/script/modules/environment";
import userProfiler from "web/script/modules/userprofiler";

const googleClientScriptURL = "https://accounts.google.com/gsi/client";

interface GoogleOneTapProps {
    googleAccountConfigs: {
        clientId: string;
        loginUri: string;
    };
}
declare global {
    interface Window {
        googleOneTapAnalytics?: (notification: any) => void;
    }
}

function googleOneTapAnalytics(notification: any): void {
    if (notification.getMomentType() !== "skipped") {
        analytics.event("google_one_tap", "shown");
    }
}

export default function GoogleOneTap({ googleAccountConfigs }: GoogleOneTapProps): JSX.Element {
    const containerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (containerRef.current) {
            containerRef.current.setAttribute("data-moment_callback", "googleOneTapAnalytics");
        }

        window.googleOneTapAnalytics = googleOneTapAnalytics;

        return () => {
            delete window.googleOneTapAnalytics;
        };
    }, []);

    return (
        <>
            {!userProfiler.isLoggedIn() && (
                <>
                    <script
                        data-testid="google-one-tap-script"
                        src={googleClientScriptURL}
                        async
                    ></script>
                    <div
                        ref={containerRef}
                        id="g_id_onload"
                        data-cancel_on_tap_outside="false"
                        data-client_id={googleAccountConfigs.clientId}
                        data-context="signin"
                        data-itp_support="true"
                        data-login_uri={googleAccountConfigs.loginUri}
                        data-sign_up_language={environment.get("language")}
                        data-testid="google-one-tap-container"
                    ></div>
                </>
            )}
        </>
    );
}

import { JinjaToJS } from "jinja-to-js";
import { gettext } from "web/script/modules/django-i18n";
import environment from "web/script/modules/environment";
import { languageIs, languageIsEnglish, languageIsIn } from "web/script/modules/language";

/**
 * Utility function to install new global methods into the Jinja-to-JS runtime.
 *
 * @params {Object} jinjaToJs
 */
export function installJinjaGlobals(jinjaToJs: JinjaToJS): void {
    jinjaToJs.globals._ = gettext;
    // placeholder to avoid breaking rothko with SSR react
    jinjaToJs.globals.include_jsx = (): void => {};
    jinjaToJs.globals.language_is_english = languageIsEnglish;
    jinjaToJs.globals.language_is = languageIs;
    jinjaToJs.globals.language_is_in = languageIsIn;

    Object.defineProperty(jinjaToJs.globals, "features", {
        // We want every invocation of a template to get the latest version of
        //  the features object, in case a component has updated them
        // (eg with data from an ajax call)
        enumerable: true,
        get() {
            return environment.get("features", {});
        },
        // Storybook will re-run this function many many times
        configurable: true,
    });
}

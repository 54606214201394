import noop from "lodash/noop";
import { useEffect, useState } from "react";
import { useMatch, useNavigate, useSearchParams } from "react-router-dom";
import { useDomViewport } from "web/react/hooks/use-dom-viewport/use-dom-viewport";
import {
    PurchaseIntentCheckoutType,
    PurchaseIntentPurchaseType,
} from "web/react/pages/checkout/checkout.context";
import analytics from "web/script/analytics/analytics";
import environment from "web/script/modules/environment";
import history, { BrowserHistoryEvent } from "web/script/utils/history";
import { CheckoutProductSerializer } from "web/types/serializers";

export enum CheckoutView {
    ROOT = "root",
    DELIVERY = "delivery",
    SHIPPING = "shipping",
    PAYMENT = "payment",
    REVIEW = "review",
    PURCHASE_SUCCESSFUL = "purchase-successful",
    PURCHASE_PENDING = "purchase-pending",
    PURCHASE_FAILED = "purchase-failed",
}

export const VIEW_TITLES = {
    [CheckoutView.ROOT]: "Checkout",
    [CheckoutView.DELIVERY]: "Delivery method",
    [CheckoutView.SHIPPING]: "Shipping address",
    [CheckoutView.PAYMENT]: "Payment method",
    [CheckoutView.REVIEW]: "Checkout",
    [CheckoutView.PURCHASE_SUCCESSFUL]: "Checkout",
    [CheckoutView.PURCHASE_PENDING]: "Checkout",
    [CheckoutView.PURCHASE_FAILED]: "Checkout",
};

const ANALYTICS_SUB_TYPES = {
    [CheckoutView.ROOT]: "review_order",
    [CheckoutView.DELIVERY]: "delivery_method",
    [CheckoutView.SHIPPING]: "shipping_form",
    [CheckoutView.PAYMENT]: "payment_method",
    [CheckoutView.REVIEW]: "review_order",
    [CheckoutView.PURCHASE_SUCCESSFUL]: "order_success",
    [CheckoutView.PURCHASE_PENDING]: "order_pending",
    [CheckoutView.PURCHASE_FAILED]: "order_failed",
};

export interface CheckoutContextViewsNavigation {
    activeView: CheckoutView;
    goToView: (view: CheckoutView, title?: string) => void;
    pageTitle: string;
    onClickBackButton: () => void;
    exitCheckoutPage: (event: any, data?: BrowserHistoryEvent) => void;
    triggerCheckoutExit: (newURL: URL) => void;
    stayInCheckout: () => void;
    showCheckoutExitPrompt: boolean;
}

interface UseViewsNavigation extends CheckoutContextViewsNavigation {
    goToLatestStep: () => void;
    isBuyNowEnabled: boolean;
    isDeliveryFormDisabled: boolean;
    isPaymentFormDisabled: boolean;
    isShippingFormDisabled: boolean;
    onToggleAccordion: (accordion: CheckoutView) => void;
    setFullPageLoadingSpinner: (loading: boolean) => void;
    showFullPageLoadingSpinner: boolean;
}

export const CHECKOUT_CONTEXT_VIEWS_NAVIGATION_DEFAULT_DATA: CheckoutContextViewsNavigation = {
    activeView: CheckoutView.ROOT,
    goToView: noop,
    pageTitle: VIEW_TITLES[CheckoutView.ROOT],
    onClickBackButton: noop,
    exitCheckoutPage: noop,
    stayInCheckout: noop,
    triggerCheckoutExit: noop,
    showCheckoutExitPrompt: false,
};

interface UseViewsNavigationProps {
    isDeliveryFormComplete: boolean;
    isPaymentFormComplete: boolean;
    isShippingFormComplete: boolean;
    isErrorModalOpen: boolean;
    products: CheckoutProductSerializer[];
    showDeliveryLoadingSpinner: boolean;
    showPaymentLoadingSpinner: boolean;
    showShippingLoadingSpinner: boolean;
    checkoutOrderId: string | null;
    checkoutOrderState: string;
    purchaseIntentSessionId: string;
    hasShippingFormErrored: boolean;
    hasDeliveryFormErrored: boolean;
    hasPaymentFormErrored: boolean;
}

function useViewsNavigation({
    isErrorModalOpen,
    isDeliveryFormComplete,
    isPaymentFormComplete,
    isShippingFormComplete,
    products,
    showDeliveryLoadingSpinner,
    showPaymentLoadingSpinner,
    showShippingLoadingSpinner,
    checkoutOrderId,
    checkoutOrderState,
    purchaseIntentSessionId,
    hasShippingFormErrored,
    hasDeliveryFormErrored,
    hasPaymentFormErrored,
}: UseViewsNavigationProps): UseViewsNavigation {
    const navigate = useNavigate();
    const isReviewView = useMatch(CheckoutView.REVIEW);
    const [searchParams] = useSearchParams();

    const [showFullPageLoadingSpinner, setFullPageLoadingSpinner] = useState<boolean>(false);
    const [isNavigationDisabled, setNavigationDisabled] = useState<boolean>(false);
    const [isBuyNowEnabled, setBuyNowEnabled] = useState<boolean>(true);
    const [showCheckoutExitPrompt, setCheckoutExitPrompt] = useState<boolean>(false);

    const [isPurchaseOutcomePage, setPurchaseOutcomePage] = useState<boolean>(false);
    const [activeView, setActiveView] = useState<CheckoutView>(CheckoutView.REVIEW);
    const [pageTitle, setPageTitle] = useState<string>(VIEW_TITLES[CheckoutView.REVIEW]);
    const [destinationUrl, setDestinationUrl] = useState<string>("");

    const [isShippingFormDisabled] = useState<boolean>(false);
    const [isDeliveryFormDisabled, setDeliveryFormDisabled] = useState<boolean>(true);
    const [isPaymentFormDisabled, setPaymentFormDisabled] = useState<boolean>(true);

    const { isDomLoaded, isMobileViewport, isTabletViewport, isDesktopViewport } = useDomViewport();

    function onClickBackButton(): void {
        if (isReviewView) {
            const newURL = new URL(products[0].lyst_product_url, window.location.href);
            triggerCheckoutExit(newURL);
        } else {
            goToView(CheckoutView.REVIEW);
        }
    }

    function triggerCheckoutExit(newURL: URL): void {
        history.trigger("checkoutExit", {
            eventType: "popstate",
            newURL,
        });
    }

    function checkPurchaseOutcomePage(view: CheckoutView): boolean {
        return (
            view === CheckoutView.PURCHASE_FAILED ||
            view === CheckoutView.PURCHASE_PENDING ||
            view === CheckoutView.PURCHASE_SUCCESSFUL
        );
    }

    function goToView(view: CheckoutView, title?: string): void {
        setPageTitle(title || VIEW_TITLES[view]);
        setActiveView(view);
        if (checkPurchaseOutcomePage(view)) setPurchaseOutcomePage(true);
        isDomLoaded &&
            navigate(
                {
                    pathname: view,
                    search: searchParams.toString(),
                },
                { replace: isDesktopViewport }
            );
        window.scrollTo(0, 0);

        sendPageViewAnalytics(view);
    }

    function sendPageViewAnalytics(view: CheckoutView): void {
        if (view === activeView) return;

        environment.set("pageSubType", ANALYTICS_SUB_TYPES[view]);

        // Generate a new pageViewId for each checkout pageView
        analytics.generatePageViewId();

        const checkoutOrderItemType = {
            item_type: "checkout_order",
            order_status: checkoutOrderState,
        };

        if (checkoutOrderId !== null) {
            checkoutOrderItemType["order_id"] = checkoutOrderId;
        }

        analytics.pageView([
            {
                item_type: "purchase_intent_session",
                purchase_intent_session_id: purchaseIntentSessionId,
                purchase_type: PurchaseIntentPurchaseType.CHECKOUT,
                checkout_type: PurchaseIntentCheckoutType.NATIVE,
            },
            checkoutOrderItemType,
        ]);
    }

    function onToggleAccordion(accordion: CheckoutView): void {
        if (isNavigationDisabled) return;

        if (accordion !== activeView) {
            goToView(accordion);
            return;
        }

        if (
            (activeView === CheckoutView.SHIPPING && !isShippingFormComplete) ||
            (activeView === CheckoutView.DELIVERY && !isDeliveryFormComplete) ||
            (activeView === CheckoutView.PAYMENT && !isPaymentFormComplete) ||
            isPaymentFormComplete
        ) {
            goToView(CheckoutView.REVIEW);
        } else if (isDeliveryFormComplete) {
            goToView(CheckoutView.PAYMENT);
        } else if (isShippingFormComplete) {
            goToView(CheckoutView.DELIVERY);
        } else {
            goToView(CheckoutView.SHIPPING);
        }
    }

    function handleBrowserHistoryChange(event: any, data: BrowserHistoryEvent): void {
        if (history._currentState?.idx === 0 && data.eventType === "popstate") {
            setCheckoutExitPrompt(true);
        }
    }

    function handleUICheckoutExit(event: any, data: BrowserHistoryEvent): void {
        setCheckoutExitPrompt(true);
        setDestinationUrl(data.newURL.href);
    }

    function stayInCheckout(): void {
        const currentOverlay = history.getCurrentState().overlay || "checkout";
        history.pushOverlay(currentOverlay);
        goToView(activeView);
        setCheckoutExitPrompt(false);
        setDestinationUrl("");
    }

    function exitCheckoutPage(event: any, data?: BrowserHistoryEvent): void {
        const url = data?.newURL.href || destinationUrl;
        if (url) {
            window.location.href = url;
        } else {
            history.back();
        }
        setCheckoutExitPrompt(false);
    }

    function goToLatestStep(): void {
        if (isNavigationDisabled) return;

        setBuyNowEnabled(
            isShippingFormComplete &&
                isDeliveryFormComplete &&
                isPaymentFormComplete &&
                !hasShippingFormErrored &&
                !hasDeliveryFormErrored &&
                !hasPaymentFormErrored
        );

        if (hasShippingFormErrored) {
            setPaymentFormDisabled(true);
            setDeliveryFormDisabled(true);
            goToView(CheckoutView.SHIPPING);
            return;
        } else if (hasDeliveryFormErrored) {
            setPaymentFormDisabled(true);
            goToView(CheckoutView.DELIVERY);
            return;
        } else if (hasPaymentFormErrored) {
            goToView(CheckoutView.PAYMENT);
            return;
        }

        if (isPaymentFormComplete) {
            setPaymentFormDisabled(false);
            setDeliveryFormDisabled(false);
        } else if (isDeliveryFormComplete) {
            setPaymentFormDisabled(false);
            setDeliveryFormDisabled(false);
        } else if (isShippingFormComplete) {
            setDeliveryFormDisabled(false);
        } else {
            setDeliveryFormDisabled(true);
            setPaymentFormDisabled(true);
        }

        // Always return to review page on mobile
        if (isMobileViewport || isTabletViewport) {
            if (!isErrorModalOpen) goToView(CheckoutView.REVIEW);
            return;
        }

        // Go to next accordion on desktop
        if (isPaymentFormComplete) {
            goToView(CheckoutView.REVIEW);
        } else if (isDeliveryFormComplete) {
            goToView(CheckoutView.PAYMENT);
        } else if (isShippingFormComplete) {
            goToView(CheckoutView.DELIVERY);
        } else {
            goToView(CheckoutView.SHIPPING);
        }
    }

    useEffect(() => {
        setNavigationDisabled(
            showShippingLoadingSpinner || showDeliveryLoadingSpinner || showPaymentLoadingSpinner
        );

        if (isMobileViewport || isTabletViewport)
            setFullPageLoadingSpinner(
                showShippingLoadingSpinner ||
                    showDeliveryLoadingSpinner ||
                    showPaymentLoadingSpinner
            );
    }, [
        isMobileViewport,
        isTabletViewport,
        showDeliveryLoadingSpinner,
        showPaymentLoadingSpinner,
        showShippingLoadingSpinner,
    ]);

    useEffect(() => {
        goToLatestStep();
    }, [
        isDomLoaded,
        hasPaymentFormErrored,
        hasDeliveryFormErrored,
        hasShippingFormErrored,
        isPaymentFormComplete,
        isDeliveryFormComplete,
        isShippingFormComplete,
        isNavigationDisabled,
    ]);

    useEffect(() => {
        if (isShippingFormComplete && !isPurchaseOutcomePage) {
            history.on("change", handleBrowserHistoryChange);
            history.on("checkoutExit", handleUICheckoutExit);
            return () => {
                history.off("change", handleBrowserHistoryChange);
                history.off("checkoutExit", handleUICheckoutExit);
            };
        } else {
            history.on("checkoutExit", exitCheckoutPage);
            return () => history.off("checkoutExit", exitCheckoutPage);
        }
    }, [isShippingFormComplete, isPurchaseOutcomePage]);

    return {
        activeView,
        goToLatestStep,
        goToView,
        isBuyNowEnabled,
        isDeliveryFormDisabled,
        isPaymentFormDisabled,
        isShippingFormDisabled,
        pageTitle,
        onClickBackButton,
        onToggleAccordion,
        setFullPageLoadingSpinner,
        showFullPageLoadingSpinner,
        exitCheckoutPage,
        stayInCheckout,
        triggerCheckoutExit,
        showCheckoutExitPrompt,
    };
}

export default useViewsNavigation;

import environment from "web/script/modules/environment";
import globals from "web/script/modules/globals";
import { setPaidSessionIdOnUrl } from "web/script/utils/paid-session-id";

export default function navigate(url: string): void {
    let newUrl = url;
    if (environment.has("paidSessionId")) {
        newUrl = setPaidSessionIdOnUrl(url, environment.get("paidSessionId"));
    }
    globals.window.location.assign(newUrl);
}

import clsx from "clsx";
import React, { useState } from "react";
import { Settings as SlickSettings } from "react-slick";
import { BREAKPOINTS } from "web/react/constants";
import withReduxProvider from "web/react/redux-provider";
import analytics from "web/script/analytics/analytics";
import environment from "web/script/modules/environment";
import { ProductImageGalleryCarouselEmo } from "./product-image-carousel-emo";
import FullscreenGalleryEmo from "./product-image-gallery-fullscreen-emo";
import * as styles from "./product-image-gallery.css";

export interface Image {
    id: string | number;
    altText?: string | null;
    url: string;
    src?: string;
}

function buildUrl(url: string, width: number, height: number): string {
    return url.replace("/photos/", `/${width}/${height}/n/photos/`);
}

export enum LazyLoadTypes {
    EAGER = "eager",
    LAZY = "lazy",
}

type ImageProps = {
    src: string;
    alt?: string;
    onClick: (event) => void;
    width?: number;
    height?: number;
    isFullscreen?: boolean;
    lazy?: boolean;
};

export const Image = ({
    src,
    alt,
    lazy = false,
    onClick,
    width = 520,
    height = 650,
    isFullscreen = false,
}: ImageProps): JSX.Element => {
    // CW-2172 increase dimension of images in IS PDP
    const showHigherResolutionImages = environment.getFeature(
        "seo_higher_resolution_pdp_images_uk"
    );
    const mobileRatio = { width: 520, height: 650 };
    const desktopRatio = { width: 1040, height: 1300 };

    return (
        <div
            className={clsx({
                [styles.item]: !isFullscreen,
                [styles.fullScreenItem]: isFullscreen,
            })}
        >
            {showHigherResolutionImages ? (
                <picture>
                    <source
                        media={styles.mobileMedia}
                        srcSet={buildUrl(src, mobileRatio.width, mobileRatio.height)}
                    />
                    <source
                        media={styles.desktopMedia}
                        srcSet={buildUrl(src, desktopRatio.width, desktopRatio.height)}
                    />
                    <img
                        className={clsx({
                            [styles.image]: !isFullscreen,
                            [styles.fullscreenImage]: isFullscreen,
                        })}
                        decoding="async"
                        alt={alt}
                        loading={lazy ? LazyLoadTypes.LAZY : LazyLoadTypes.EAGER}
                        onClick={onClick}
                        width={width}
                        height={height}
                        src={buildUrl(src, desktopRatio.width, desktopRatio.height)}
                    />
                </picture>
            ) : (
                <img
                    className={clsx({
                        [styles.image]: !isFullscreen,
                        [styles.fullscreenImage]: isFullscreen,
                    })}
                    decoding="async"
                    src={buildUrl(src, width, height)}
                    alt={alt}
                    loading={lazy ? LazyLoadTypes.LAZY : LazyLoadTypes.EAGER}
                    width={width}
                    onClick={onClick}
                />
            )}
        </div>
    );
};

type ProductImageGalleryProps = {
    images: Image[];
    currentSlideIndex?: number;
    productId: string;
};

const _ProductImageGallery = ({ images, productId }: ProductImageGalleryProps): JSX.Element => {
    const [fullscreen, setFullscreen] = useState(false);
    const [initialSlideIndex, setInitialSlideIndex] = useState<number>(0);
    const [currentSlideIndex, setCurrentSlideIndex] = useState<number>(0);
    const [seenAll, setSeenAll] = useState(false);

    function handleImageClick(event, index): void {
        setInitialSlideIndex(index);
        setCurrentSlideIndex(index);
        setFullscreen(true);
    }

    function onSlideChange(curSlide, nextSlide): void {
        setCurrentSlideIndex(nextSlide);
        analytics.event("product_gallery", "engagement", "changed_image", false, {
            product_id: productId,
        });

        if (!seenAll) {
            const lastSlideIndex = images.length - 1;

            if (nextSlide === lastSlideIndex) {
                setSeenAll(true);
                analytics.event("product_gallery", "engagement", "seen_all", false, {
                    product_id: productId,
                });
            }
        }
    }

    const afterSlideChange = (currentSlide: number): void => {
        onSlideChange(undefined, currentSlide);
    };

    // Slick settings and props for the fullscreen gallery
    const fullscreenGalleryEmoSettings: SlickSettings = {
        accessibility: true,
        speed: 500,
        slidesToShow: 1,
        dots: images.length > 1,
        initialSlide: initialSlideIndex,
        beforeChange: onSlideChange,
        centerMode: false,
        touchThreshold: 5,
        infinite: false,
        swipeToSlide: true,
        arrows: false,
        swipe: true,
        touchMove: false,
        draggable: false,
        lazyLoad: undefined,
        responsive: [
            {
                breakpoint: BREAKPOINTS.Medium,
                settings: {
                    speed: 200,
                    touchMove: true,
                    draggable: true,
                },
            },
        ],
    };

    const InPageViewSettings: SlickSettings = {
        accessibility: true,
        speed: 500,
        slidesToShow: 1,
        dots: images.length > 1,
        initialSlide: currentSlideIndex,
        afterChange: afterSlideChange,
        centerMode: false,
        touchThreshold: 5,
        infinite: false,
        swipeToSlide: true,
        arrows: false,
        swipe: true,
        touchMove: false,
        draggable: false,
        lazyLoad: undefined,
        responsive: [
            {
                breakpoint: BREAKPOINTS.Medium,
                settings: {
                    slidesToShow: 1,
                    speed: 200, // we need this for smooth swipe
                    touchMove: true,
                    draggable: false,
                },
            },
            {
                breakpoint: BREAKPOINTS.Large,
                settings: {
                    slidesToShow: 1,
                    speed: 200,
                    touchMove: true,
                    draggable: true,
                },
            },
        ],
    };

    const fullscreenGalleryEmoProps = {
        isOpen: fullscreen,
        onClose: (): void => {
            setFullscreen(false);
            analytics.event("product_gallery", "engagement", "close_fullscreen", false, {
                product_id: productId,
            });
        },
        images: images,
        settings: fullscreenGalleryEmoSettings,
    };

    return (
        <>
            {fullscreen ? (
                <FullscreenGalleryEmo
                    currentSlideIndex={currentSlideIndex}
                    productId={productId}
                    {...fullscreenGalleryEmoProps}
                />
            ) : (
                <ProductImageGalleryCarouselEmo
                    images={images}
                    settings={InPageViewSettings}
                    isFullscreen={fullscreen}
                    handleImageClick={(event, index): void => handleImageClick(event, index)}
                    onClose={(): void => setFullscreen(false)}
                    currentSlideIndex={currentSlideIndex}
                    productId={productId}
                />
            )}
        </>
    );
};

export const ProductImageGallery = withReduxProvider(_ProductImageGallery);

import React, { useEffect, useRef, useState } from "react";

import RelatedProductsSection from "web/react/components/LEGACY_related-products-section/related-products-section";
import ProductCard from "web/react/components/product-card/react";
import TabSwitcher from "web/react/components/tab-switcher/tab-switcher";
import { useDomViewport } from "web/react/hooks/use-dom-viewport/use-dom-viewport";
import analytics from "web/script/analytics/analytics";
import { gettext } from "web/script/modules/django-i18n";
import { RelatedProductsAreaSectionSerializer } from "web/types/serializers";
import styles from "./related-products-area.module.css";

interface RelatedProductsAreaProps {
    sections: RelatedProductsAreaSectionSerializer[];
    onOOS: boolean;
    hideShortDescription?: boolean | string;
    isAffiliate: boolean;
    retailerSlug?: string | null;
    noAdsense?: boolean;
    noBrandAd?: boolean;
    analyticsEventLabel?: string;
}

export function RelatedProductsArea({
    sections,
    onOOS,
    isAffiliate,
    noAdsense,
    noBrandAd,
    retailerSlug,
}: RelatedProductsAreaProps): React.ReactElement {
    function handleTabClickAnalytics(label): void {
        if (label) {
            analytics.event("NAVIGATION", "TAB_CLICK", label);
        }
    }

    // Check if first row of Similar Products/New Arrivals section is visible in the view port
    // Compare the top and bottom coordinates with the current viewport height to determine if row
    // is fully visible
    const ref = useRef<HTMLInputElement>(null);
    const [analyticsSent, setAnalyticsSent] = useState(false);
    const { isDesktopViewport, isTabletViewport } = useDomViewport();

    useEffect(() => {
        const target = document.getElementsByClassName("base-overlay");

        function unmountEventListener(target): void {
            if (target[0]) {
                target[0].removeEventListener("scroll", handleScroll);
            } else {
                window.removeEventListener("scroll", handleScroll);
            }
        }
        function handleScroll(): void {
            if (ref && ref.current) {
                const { top, bottom } = ref.current.getBoundingClientRect();
                const isFirstProductCardVisible = top >= 0 && bottom <= window.innerHeight;
                if (isFirstProductCardVisible && !analyticsSent) {
                    analytics.event("more_like_this", "impression", "pdp_related_feed");
                    setAnalyticsSent(true);
                    unmountEventListener(target);
                }
                // Remove scroll event listener after the event is sent
            }
        }

        if (target[0]) {
            target[0].addEventListener("scroll", handleScroll);
        } else {
            window.addEventListener("scroll", handleScroll);
        }
        return () => {
            unmountEventListener(target);
        };
    }, [analyticsSent]);
    const productRef = isDesktopViewport || isTabletViewport ? 3 : 0;
    return (
        <div id="relatedproducts" className={styles.relatedProductsArea}>
            <TabSwitcher>
                <TabSwitcher.Header>
                    {sections.map(({ title, label }) => (
                        <TabSwitcher.Tab
                            key={title as string}
                            onClick={(): void => handleTabClickAnalytics(label)}
                        >
                            {title as string}
                        </TabSwitcher.Tab>
                    ))}
                </TabSwitcher.Header>

                {sections.map((section, sectionIndex) => (
                    <RelatedProductsSection
                        key={section.label as string}
                        viewMoreUrl={section.view_more_url}
                        hiddenOnDesktop={section.hidden_products_count}
                        isInStock={onOOS ? !onOOS : true}
                        isAffiliate={isAffiliate}
                        retailerSlug={retailerSlug || null}
                    >
                        {section.related_products.length ? (
                            section.related_products.map((product, index) => (
                                <ProductCard
                                    key={product.id}
                                    product={product}
                                    className={styles.productCard}
                                    productCardRef={
                                        sectionIndex === 0 && productRef === index ? ref : null
                                    }
                                >
                                    <ProductCard.Image
                                        width={125}
                                        height={156}
                                        lazyLoad={!(onOOS && noAdsense && noBrandAd && index < 2)}
                                        analyticsCategory="more_like_this"
                                        analyticsEventLabel="pdp_related_feed"
                                    >
                                        <ProductCard.Badges />
                                    </ProductCard.Image>

                                    <ProductCard.SaveForLaterButton
                                        isAffiliate={product.is_affiliate}
                                    />

                                    <ProductCard.Details
                                        analyticsCategory="more_like_this"
                                        analyticsEventLabel="pdp_related_feed"
                                    />
                                </ProductCard>
                            ))
                        ) : (
                            <p className={styles.emptyFeed}>
                                <em>{gettext("feeds.empty_message")}</em>
                            </p>
                        )}
                    </RelatedProductsSection>
                ))}
            </TabSwitcher>
        </div>
    );
}

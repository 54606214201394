import React from "react";
import { SubCategory } from "web/react/components/navigation/navigation-menu/subcategory";
import { useNavigationContext } from "web/react/components/navigation/navigation.context";
import { Button } from "web/react/emo/button";
import { ListItem } from "web/react/emo/list-item/list-item";
import { Text } from "web/react/emo/text";
import { View } from "web/react/emo/view";
import { gettext } from "web/script/modules/django-i18n";
import { canUseMembership } from "web/script/modules/utils";
import { getUrl } from "web/script/routing/getUrl";
import { LanguageChoicePropInterface } from "web/types/language";
import { MenuSerializer, MyListMenuSerializer } from "web/types/serializers";
import * as styles from "./gender-menu.css";

interface GenderMenuProps {
    menu: MenuSerializer;
    isAuthenticated: boolean;
    myListMenu: MyListMenuSerializer;
    currentCountry?: string;
    currentCurrency?: string;
    currentLanguage?: LanguageChoicePropInterface;
    onCategoryClick: (submenu: any) => void;
    onCountrySelectorClick: () => void;
    onLanguageSelectorClick: () => void;
    showCountryPicker?: boolean;
    showLanguageSelector?: boolean;
    fullRender?: boolean;
}

export function GenderMenu({
    currentCountry = "",
    currentCurrency = "",
    currentLanguage,
    menu,
    myListMenu,
    onCategoryClick,
    onCountrySelectorClick,
    onLanguageSelectorClick,
    showCountryPicker = false,
    showLanguageSelector = false,
    fullRender = false,
    isAuthenticated = false,
}: GenderMenuProps): React.ReactElement {
    const { handleCategoryAnalytics, handleNavigationAnalytics } = useNavigationContext();

    return (
        <>
            <div data-testid={`gender-menu-${menu.display_name}`} className={styles.genderMenu}>
                <View marginBottom="sm">
                    {menu.links &&
                        menu.links.map((link) => {
                            const isSaleLink = link.is_sale;
                            return (
                                <ListItem as="a" key={link.text} href={link.url}>
                                    <Text
                                        textStyle="body-3-small"
                                        color={isSaleLink ? "error" : "primary"}
                                    >
                                        {link.text}
                                    </Text>
                                </ListItem>
                            );
                        })}

                    {menu.menus &&
                        menu.menus.map((submenu) => {
                            return (
                                <ListItem
                                    key={submenu.display_name}
                                    as="button"
                                    onClick={() => {
                                        onCategoryClick(submenu);
                                        handleCategoryAnalytics("product_type");
                                    }}
                                    showChevron
                                    label={submenu.display_name}
                                    textStyle="body-3-small"
                                />
                            );
                        })}
                </View>

                <View className={styles.divider} marginX="sm" />
                <Text as={"p"} textStyle={"body-2"} className={styles.myListSectionTitle}>
                    {
                        // "My List"
                        gettext("burger_menu.my_list_section_title")
                    }
                </Text>

                {myListMenu.menu_items &&
                    myListMenu.menu_items.map((myListItem, i) => {
                        return (
                            <ListItem
                                as="a"
                                href={myListItem.url}
                                key={i}
                                onClick={() => {
                                    handleNavigationAnalytics(myListItem.text);
                                }}
                                showChevron
                                label={myListItem.text}
                                textStyle="body-3-small"
                            />
                        );
                    })}
                <ListItem
                    as="a"
                    href={getUrl("helpCentreHomepage") || ""}
                    key={"help-centre"}
                    onClick={() => {
                        handleNavigationAnalytics("help-centre");
                    }}
                    showChevron
                    label={gettext("footer.help_info.title_variant_b")}
                    textStyle="body-3-small"
                />
                {showCountryPicker && (
                    <ListItem
                        as="button"
                        key={`country_selector_${currentCountry}`}
                        onClick={() => {
                            handleNavigationAnalytics("country_selector");
                            onCountrySelectorClick();
                        }}
                        showChevron
                        // Country { Country symbol - currency symbol }
                        label={gettext("burger_menu.country_selector", {
                            country: currentCountry,
                            currency_indicator: currentCurrency,
                        })}
                        textStyle="body-3-small"
                    />
                )}

                {showLanguageSelector && (
                    <ListItem
                        as="button"
                        key={`country_selector_${currentLanguage?.language_name}`}
                        onClick={() => {
                            handleNavigationAnalytics("language_selector");
                            onLanguageSelectorClick();
                        }}
                        label={gettext("burger_menu.language_selector", {
                            language: currentLanguage?.language_name,
                        })}
                        showChevron
                        textStyle="body-3-small"
                    />
                )}

                {isAuthenticated && canUseMembership() && (
                    <div className={styles.signOutButtonContainer}>
                        <Button
                            href={myListMenu.sign_out_url}
                            size={"lg"}
                            onClick={() => {
                                handleNavigationAnalytics("sign_out");
                            }}
                            variant={"secondary"}
                            title={
                                /* "Sign out" */
                                gettext("header.user_dropdown.sign_out")
                            }
                            width="full"
                        />
                    </div>
                )}
            </div>

            {menu.menus &&
                menu.menus.map((submenu) => (
                    <SubCategory
                        key={submenu.display_name}
                        title={submenu.display_name}
                        submenu={submenu}
                        fullRender={fullRender}
                    />
                ))}
        </>
    );
}

import clsx from "clsx";
import React from "react";
import LazyLoadImage from "web/react/components/lazy-load-image/lazy-load-image";
import { LinkWithImageSerializer } from "web/types/serializers";
import styles from "./rail-link-item.module.css";

interface RailLinkItemProps {
    className?: string;
    titleClassName?: string;
    imageClassName?: string;
    link: LinkWithImageSerializer;
}

export default function RailLinkItem({
    link,
    className,
    titleClassName,
    imageClassName,
}: RailLinkItemProps): JSX.Element {
    return (
        <a className={clsx(className, styles.link)} href={link.url}>
            <h4 className={clsx(titleClassName, styles.linkTitle)}> {link.title} </h4>
            <LazyLoadImage
                className={clsx(imageClassName, styles.linkImage)}
                src={link.image_url}
                alt={link.image_alt}
            />
        </a>
    );
}

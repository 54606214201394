import clsx from "clsx";
import React, { RefObject } from "react";
import { useSelector } from "react-redux";
import { Breadcrumbs } from "web/react/components/breadcrumbs/breadcrumbs";
import { FeedCount } from "web/react/components/feed-count";
import { isProfileHeader } from "web/react/components/feed-header/feed-header";
import FollowDesignerButton from "web/react/components/follow-designer-button/follow-designer-button";
import { ExpandableText } from "web/react/emo/expandable-text/expandable-text";
import { HStack } from "web/react/emo/h-stack";
import { Link } from "web/react/emo/link";
import { Sticker } from "web/react/emo/sticker";
import { VStack } from "web/react/emo/v-stack";
import { View } from "web/react/emo/view";
import { useDomViewport } from "web/react/hooks/use-dom-viewport/use-dom-viewport";
import { FeedPage, FeedPageType } from "web/react/pages/feed/utils";
import { Image } from "web/react/pages/product/in-stock/product-image-gallery/product-image-gallery";
import { ReduxStoreState } from "web/redux/store";
import { gettext } from "web/script/modules/django-i18n";
import { canUseMembership } from "web/script/modules/utils";
import {
    BreadcrumbsSerializer,
    FeedHeaderSerializer,
    ProfileHeaderSerializer,
    RetailPromotionSerializer,
} from "web/types/serializers";
import { FeedHeaderImage } from "./feed-header-image";
import { FeedHeaderTitle } from "./feed-header-title";
import * as styles from "./feed-header-with-logo.css";

export interface FeedHeaderWithLogoProps {
    title: string;
    subtitle?: string | JSX.Element;
    profile?: ProfileHeaderSerializer | FeedHeaderSerializer;
    shortDescription?: string | null;
    description?: string | null;
    followCTARef?: RefObject<HTMLDivElement>;
    actions?: JSX.Element;
    headerImage?: Image;
    type?: FeedPageType;
    productCount: number;
    retailerCount: number;
    breadcrumbs?: BreadcrumbsSerializer | null;
}

const getHeaderImageSrc = ({
    headerImage,
    type = FeedPage.GENERIC_SHOP,
    profile,
}: {
    headerImage?: Image;
    profile?: ProfileHeaderSerializer | FeedHeaderSerializer;
    type?: FeedPageType;
}): string | undefined => {
    if (!(profile as FeedHeaderSerializer)?.photo_url) return undefined;

    return (
        (headerImage?.src ||
            ((isProfileHeader(profile) || type === FeedPage.RELATED_PRODUCTS) &&
                (profile as FeedHeaderSerializer)?.photo_url) ||
            profile?.small_photo_url) ??
        undefined
    );
};

export function FeedHeaderWithLogo({
    profile,
    title,
    subtitle,
    shortDescription,
    description,
    followCTARef,
    actions,
    headerImage,
    type = FeedPage.GENERIC_SHOP,
    productCount,
    retailerCount,
    breadcrumbs,
}: FeedHeaderWithLogoProps): JSX.Element {
    const { isMobileViewport, isTabletViewport, isDesktopViewport } = useDomViewport();
    const headerImageSrc = getHeaderImageSrc({ headerImage, profile, type });
    const headerImageAltText = (headerImage?.altText || profile?.photo_alt_text) ?? undefined;
    const designerName = profile?.follow_button?.designer_name ?? undefined;

    const feedBreadcrumbsState = useSelector((state: ReduxStoreState) => state.feedBreadcrumbs);
    const feedBreadcrumbs = feedBreadcrumbsState?.breadcrumbs
        ? feedBreadcrumbsState.breadcrumbs
        : breadcrumbs?.breadcrumbs;

    const fullDescription = description || shortDescription;

    const promotionDescription = profile &&
        (profile?.promotion as RetailPromotionSerializer) &&
        profile?.promotion?.promo_is_valid && (
            <Sticker
                name="promotionCode"
                label={
                    <span
                        dangerouslySetInnerHTML={{
                            __html: profile.promotion.description,
                        }}
                    />
                }
            />
        );

    return (
        <View>
            <div
                className={clsx(styles.breadcrumbs, {
                    [styles.noBreadcrumbs]: !feedBreadcrumbs,
                })}
            >
                {feedBreadcrumbs && <Breadcrumbs type="feed" breadcrumbs={feedBreadcrumbs} />}
            </div>
            <div className={styles.wrapper} ref={followCTARef}>
                <View className={styles.title}>
                    <HStack spacing={isMobileViewport ? "sm" : "md"}>
                        <FeedHeaderImage
                            src={headerImageSrc}
                            designerName={designerName}
                            altText={headerImageAltText}
                        />
                        <VStack spacing="xxs">
                            <FeedHeaderTitle title={title} subtitle={subtitle} type={type} />
                            {actions && <div className={styles.followButtonWrapper}>{actions}</div>}
                            {profile && isProfileHeader(profile) && (
                                <div>
                                    {canUseMembership() && (
                                        <div className={styles.followButtonWrapper}>
                                            <HStack spacing={"xs"} align="center">
                                                {profile.follow_button && (
                                                    <FollowDesignerButton
                                                        notFollowedText={
                                                            type === FeedPage.DESIGNER_PROFILE
                                                                ? // "Follow brand"
                                                                  gettext(
                                                                      "feed.follow_brand.button"
                                                                  )
                                                                : type === FeedPage.RETAILER_PROFILE
                                                                ? // "Follow store"
                                                                  gettext(
                                                                      "feed.follow_store.button"
                                                                  )
                                                                : // "Follow"
                                                                  gettext("feed.store_updates.cta")
                                                        }
                                                        followedText={
                                                            // "Following"
                                                            gettext(
                                                                "feed.following_store_or_brand.label"
                                                            )
                                                        }
                                                        analyticsParameters={[
                                                            "du_feed_cta",
                                                            "click_top_cta",
                                                            "du_cta",
                                                        ]}
                                                        lystId={profile.follow_button.lyst_id}
                                                        isAuthenticated={
                                                            profile.follow_button.is_authenticated
                                                        }
                                                        isFollowing={
                                                            profile.follow_button
                                                                .is_following as string
                                                        }
                                                        designerName={title}
                                                    />
                                                )}
                                                {profile.retailer_hub_link && (
                                                    <Link
                                                        icon="tag-outline"
                                                        href={profile.retailer_hub_link}
                                                        title={
                                                            // "Promo’s"
                                                            gettext(
                                                                "promotion_message.designer.promo_btn_text"
                                                            )
                                                        }
                                                    />
                                                )}
                                                {isDesktopViewport && promotionDescription && (
                                                    <View>{promotionDescription}</View>
                                                )}
                                            </HStack>
                                        </div>
                                    )}
                                </div>
                            )}
                        </VStack>
                    </HStack>
                    {(isMobileViewport || isTabletViewport) && promotionDescription && (
                        <View paddingY={"xs"}>{promotionDescription}</View>
                    )}
                </View>
                {fullDescription && (
                    <div className={clsx("hidden-mobile", styles.description)}>
                        <ExpandableText lineLimit={3} dangerouslySetInnerHtml={true}>
                            {fullDescription}
                        </ExpandableText>
                    </div>
                )}
                {(isTabletViewport || isDesktopViewport) && type !== FeedPage.WISH_LIST && (
                    <div className={styles.feedCount}>
                        <FeedCount productCount={productCount} retailerCount={retailerCount} />
                    </div>
                )}
            </div>
        </View>
    );
}

import React from "react";
import { Stack, StackProps } from "web/react/emo/shared/components/stack";

export type HStackProps = Pick<
    StackProps,
    "children" | "spacing" | "align" | "justify" | "wrap" | "dataTestId" | "id"
>;

export function HStack({ children, ...props }: HStackProps): JSX.Element {
    return (
        <Stack direction="row" {...props}>
            {children}
        </Stack>
    );
}

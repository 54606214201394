// Adapted from https://github.com/broofa/node-uuid

var _byteToHex = [];
var _hexToByte = {};

for (var i = 0; i < 256; i++) {
    _byteToHex[i] = (i + 0x100).toString(16).substring(1);
    _hexToByte[_byteToHex[i]] = i;
}

function unparse(buf) {
    var i = 0;
    var bth = _byteToHex;
    return (
        bth[buf[i++]] +
        bth[buf[i++]] +
        bth[buf[i++]] +
        bth[buf[i++]] +
        "-" +
        bth[buf[i++]] +
        bth[buf[i++]] +
        "-" +
        bth[buf[i++]] +
        bth[buf[i++]] +
        "-" +
        bth[buf[i++]] +
        bth[buf[i++]] +
        "-" +
        bth[buf[i++]] +
        bth[buf[i++]] +
        bth[buf[i++]] +
        bth[buf[i++]] +
        bth[buf[i++]] +
        bth[buf[i++]]
    );
}

function makeRandomNumbers() {
    var numbers = [];

    for (var i = 0, r; i < 16; i++) {
        if ((i & 0x03) === 0) {
            r = Math.random() * 0x100000000;
        }
        numbers[i] = (r >>> ((i & 0x03) << 3)) & 0xff;
    }

    return numbers;
}

/**
 * Returns a valid uuid-4.
 * @returns {string}
 */
function uuid4() {
    var randomNumbers = makeRandomNumbers();

    // Per 4.4, set bits for version and `clock_seq_hi_and_reserved`
    randomNumbers[6] = (randomNumbers[6] & 0x0f) | 0x40;
    randomNumbers[8] = (randomNumbers[8] & 0x3f) | 0x80;

    return unparse(randomNumbers);
}

export default {
    uuid4: uuid4,
};

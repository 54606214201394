/* eslint-disable max-depth */
import requester from "web/script/modules/requester";

export const _maxAttempts = 30;
const _pollInterval = 1000;

const _defaultErrorMessage = (
    errorMessage
): {
    error: { message: string; code: string };
} => ({
    error: { message: errorMessage, code: "generic_error" },
});

/**
 * Sends an API request to the backend and polls if status is 202
 *
 * @param {string} endpoint The endpoint to send the request to.
 * @param {object} options The options to send with the request.
 * @param {function} onSuccess The callback to call when the request completes.
 * @param {function} onError The callback to call when the request fails.
 * @param {number} maxAttempts The maximum number of attempts to make. Defaults to 15.
 * @param {number} pollInterval The interval to wait between attempts. Defaults to 1000ms.
 *
 * @returns {Promise<any>} A promise that resolves when the request completes.
 */
export const callPollEndpoint = (
    endpoint: string,
    options: any,
    onSuccess: (response: Record<string, any>) => any,
    onError: (error?: any) => any,
    maxAttempts: number = _maxAttempts,
    pollInterval: number = _pollInterval
): Promise<Response> => {
    let attempts = 0;

    options["query"] = options["query"] ? options["query"] : {};

    const executePoll = async (resolve: any): Promise<void> => {
        attempts++;
        let finalAttemptEndpoint = endpoint;

        options["query"].is_final_attempt = attempts === maxAttempts;

        try {
            const response = await requester(finalAttemptEndpoint, options);

            if (response.status === 200) {
                resolve(response.body);
                const data = await response.json();

                if (!data.success) {
                    let error = new Error() as any;
                    error.response = _defaultErrorMessage(data.error || "Generic polling error");
                    throw error;
                } else {
                    return onSuccess(data);
                }
            } else if (response.status === 202) {
                if (attempts >= maxAttempts) {
                    let error = new Error() as any;
                    error.response = _defaultErrorMessage("Max polling attempts reached");
                    throw error;
                }

                setTimeout(executePoll, pollInterval, resolve);
            } else {
                let error = new Error() as any;
                error.response = _defaultErrorMessage(
                    `Error status ${response.status} occurred while polling`
                );
                throw error;
            }
        } catch (error: any) {
            return onError(
                error.response?.error || _defaultErrorMessage("Generic call error").error
            );
        }
    };

    return new Promise(executePoll);
};

/**
 * Sends an API request to the backend.
 *
 * @param {string} endpoint The endpoint to send the request to.
 * @param {object} options The options to send with the request.
 * @param {function} onSuccess The callback to call when the request completes.
 * @param {function} onError The callback to call when the request fails.
 *
 * @returns {Promise<any>} A promise that resolves when the request completes.
 */
export async function callEndpoint(
    endpoint: string,
    options: any,
    onSuccess: (response: Record<string, any>) => any,
    onError: (error?: any) => any
): Promise<Response> {
    try {
        const response = await requester(endpoint, options);

        if (response.status === 200) {
            const data = await response.json();

            if (!data.success) {
                let error = new Error() as any;
                error.response = _defaultErrorMessage(data.error || "Generic call error");
                throw error;
            } else {
                return onSuccess(data);
            }
        } else {
            let error = new Error() as any;
            error.response = _defaultErrorMessage(
                `Error status ${response.status} occurred calling endpoint`
            );
            throw error;
        }
    } catch (error: any) {
        return onError(error.response?.error || _defaultErrorMessage("Generic call error").error);
    }
}

import clsx from "clsx";
import React from "react";
import SVGIcon from "web/react/components/svg-icon/svg-icon";
import { Text } from "web/react/emo/text/text";
import { gettext } from "web/script/modules/django-i18n";
import styles from "./back-button.module.css";

interface BackButtonProps {
    onBack?(): void;
}

export function BackButton({ onBack }: BackButtonProps): JSX.Element {
    return (
        <button onClick={onBack} className={clsx(styles.button)}>
            <SVGIcon className={styles.icon} name="chevron-thin" />
            <Text textStyle={"caption-1"} as={"span"}>
                {gettext("general.back_btn_text") /* Back */}
            </Text>
        </button>
    );
}

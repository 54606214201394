import clsx from "clsx";
import React from "react";
import SVGIcon from "web/react/components/svg-icon/svg-icon";
import { HStack } from "web/react/emo/h-stack/h-stack";
import styles from "./inline-error-message.module.css";

interface InlineErrorMessageProps {
    children: React.ReactNode;
    className?: string;
    id?: string;
}

export function InlineErrorMessage({
    children,
    className,
    id,
}: InlineErrorMessageProps): React.ReactElement {
    return (
        <div className={clsx(styles.root, className)} id={id}>
            <HStack spacing="xxxs" align="start">
                <div>
                    <SVGIcon name="error" className={styles.icon} />
                </div>
                <div>{children}</div>
            </HStack>
        </div>
    );
}

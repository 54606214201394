/*
Regions where we apply Google and Microsoft Consent Mode for our Google and Microsoft Analytics.
*/
export default {
    // EEA includes EU countries and Switzerland (CH)
    EEA: [
        "AT",
        "BE",
        "BG",
        "HR",
        "CY",
        "CZ",
        "DK",
        "EE",
        "FI",
        "FR",
        "DE",
        "GR",
        "HU",
        "IS",
        "IE",
        "IT",
        "LV",
        "LI",
        "LT",
        "LU",
        "MT",
        "NL",
        "NO",
        "PL",
        "PT",
        "RO",
        "SK",
        "SI",
        "ES",
        "SE",
        "CH",
    ],

    UK: ["GB"],

    USA: ["US"],
};

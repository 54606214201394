import React from "react";
import { CurvedPill } from "web/react/components/filters-emo/curved-pill";
import { Rail } from "web/react/components/rail/rail";
import SVGIcon from "web/react/components/svg-icon/svg-icon";
import analytics from "web/script/analytics/analytics";
import {
    RelatedLinkSerializer,
    RelatedProductCard,
    RelatedSearchRailSerializer,
} from "web/types/serializers";
import styles from "./related-searches-rail.module.css";

interface RelatedSearchesRailProps {
    relatedSearches?:
        | RelatedLinkSerializer[]
        | RelatedSearchRailSerializer[]
        | RelatedProductCard[];
    title?: string;
}

export function RelatedSearchesRail({
    relatedSearches,
    title,
}: RelatedSearchesRailProps): JSX.Element {
    const analyticsLabel = "related_searches";

    function handleClick(): void {
        analytics.event("related_searches", "click", "pdp_is");
    }

    return (
        <Rail title={title} analyticsLabel={analyticsLabel} shouldWrap alwaysWrap>
            {relatedSearches?.map((relatedSearch, index) => {
                return (
                    <a
                        href={relatedSearch.url}
                        key={`related-searches-pill-${index}`}
                        className={styles.relatedSearchPill}
                        itemProp={relatedSearch?.is_breadcrumb ? "item" : undefined}
                    >
                        <CurvedPill
                            label={relatedSearch.text || relatedSearch.name}
                            onClick={handleClick}
                            icon={<SVGIcon name="search" />}
                            active={false}
                            activeHighlighted={false}
                        />
                    </a>
                );
            })}
        </Rail>
    );
}

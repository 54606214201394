import baustein from "baustein";
import template from "templates/modules/save_for_later/save_for_later_button.jinja";
import analytics from "web/script/analytics/analytics";
import { gettext } from "web/script/modules/django-i18n";
import environment from "web/script/modules/environment";
import requester from "web/script/modules/requester";
import userProfiler from "web/script/modules/userprofiler";

/**
 * This module 'save-for-later' a product to a user's profile
 *
 * @constructor
 * @alias module:components/save-for-later-button
 * @extends module:baustein
 */
export default baustein.register("save-for-later-button", {
    template: template,

    tagName: "button",

    defaultOptions: {
        productId: "",
        isSaved: false,
        isLoveIcon: false,
        modifier: "",
    },

    init() {
        const isPdp = environment.get("pageType") === "product";
        this.S4LIsFullHeart = false;
        if (!isPdp) {
            this.S4LIsFullHeart = true;
        }
    },

    onInsert() {
        const isUserLoggedIn = userProfiler.isLoggedIn();

        // If the user is logged in, emit the event that'll
        // trigger the async save for later pin fetching.
        if (isUserLoggedIn) {
            this.emit("saveForLaterButtonInserted", { productId: this.options.productId });
        }
    },

    getInitialRenderContext() {
        return {
            save_for_later_button: {
                is_saved_for_later: this.options.isSaved,
                is_love_icon: this.options.isLoveIcon,
                is_discount_test_icon: this.discountIconTest,
                product_id: this.options.productId,
                button_text: this._getCTACopy(this.options.isSaved),
                is_full_heart: this.S4LIsFullHeart,
            },
            modifier: this.options.modifier,
        };
    },

    setupEvents(add) {
        add("click", this._onClick);
    },

    _onClick(event) {
        event.preventDefault();
        if (userProfiler.isLoggedIn()) {
            this.postSaveForLater();
        }
    },

    postSaveForLater() {
        // disable the component whilst sending POST
        this.el.disabled = true;

        const method = "POST";
        const body = {
            product_id: this.options.productId,
        };

        // get the context type
        const type = this.getType();

        return requester("/services/pins/", { method, body })
            .then((response) => {
                if (!response.ok) {
                    console.error(
                        `Could not save/un-save product with id: ${this.options.productId}`
                    );
                    return;
                }

                if (response.status === 204) {
                    // product removed from save-for-later
                    analytics.event("save_for_later", "remove_item", type);
                    this._render(false);
                } else if (response.status === 201) {
                    // product added to save-for-later
                    this.emit("showInstantFeedback", { area: "heart" });
                    analytics.event("save_for_later", "add_item", type);
                    this._render(true);
                }

                this.el.disabled = false;
            })
            .catch((e) => {
                this.el.disabled = false;

                // Still want to log the intent, but can't work out from response if it was an add or remove
                // - get this data from the template serializer instead (this won't be accurate if the page
                // has not been refreshed since last add/remove, but is the best we can do here).
                if (this.options.isSaved) {
                    analytics.event("save_for_later", "remove_item", type);
                } else {
                    analytics.event("save_for_later", "add_item", type);
                }

                console.error(e);
            });
    },

    getType() {
        if (this.options.isLoveIcon) {
            return "product_card";
        } else {
            return "product_page";
        }
    },

    _render(isSaved) {
        // only show save-for-later callout for logged-in users who have yet to see it
        if (isSaved && !userProfiler.getSaveForLaterCalloutShown() && userProfiler.isLoggedIn()) {
            this.emit("savedForLater");
            userProfiler.setSaveForLaterCalloutShown();
        }

        this.setRenderContext({
            save_for_later_button: {
                is_saved_for_later: isSaved,
                product_id: this.options.productId,
                is_love_icon: this.options.isLoveIcon,
                button_text: this._getCTACopy(isSaved),
                is_full_heart: this.S4LIsFullHeart,
            },
            modifier: this.options.modifier,
        });
    },

    _getCTACopy(isSaved) {
        let buttonText;

        if (isSaved) {
            buttonText = gettext("in_stock_product.saved");
        } else {
            buttonText = gettext("save_for_later.cta.variant_b");
        }

        return buttonText;
    },
});

/* eslint-disable @typescript-eslint/no-non-null-assertion */
import _defer from "lodash/defer";
import _find from "lodash/find";
import _isEqual from "lodash/isEqual";
import _isNumber from "lodash/isNumber";
import _isString from "lodash/isString";
import analytics from "web/script/analytics/analytics";
import eventEmitter from "web/script/mixins/event-emitter";
import environment from "web/script/modules/environment";
import globals from "web/script/modules/globals";
import navigate from "web/script/utils/navigate";
import { setPaidSessionIdOnUrl } from "web/script/utils/paid-session-id";
import browser from "./browser";
import diff, { DiffResult } from "./diff";
import url from "./url";
import uuid from "./uuid";

export interface BrowserHistoryState {
    uuid: string;
    pageViewId: string;
    overlay?: string;
    [key: string]: any;
}

export interface BrowserHistoryEvent {
    eventType: "popstate" | "pushstate" | "replacestate";
    newURL: URL;
    oldState: BrowserHistoryState | null;
    oldURL: URL | null;
    queryChanges?: DiffResult[];
    state: BrowserHistoryState;
}

interface StateOptions {
    title?: string;
    data?: Partial<BrowserHistoryState>;
    bypassRouting?: boolean;
    saveScrollPosition?: boolean | number;
    forcePageView?: boolean;
    wipeState?: boolean;
}

/**
 * A browser history object which wraps the History API.
 */
export class BrowserHistory {
    _currentURL: URL | null = null;
    _currentState: BrowserHistoryState | null = null;

    /**
     * Keeps a history of scroll positions when a new state is pushed. This is restored
     * when the state has been popped (i.e. gone back).
     */
    _scrollPositionHistoryQueue: number[] | null = null;

    constructor() {
        this._onLocationChange = this._onLocationChange.bind(this);
        this._onHashChange = this._onHashChange.bind(this);
    }
    on = eventEmitter.on;
    off = eventEmitter.off;
    trigger = eventEmitter.trigger;

    private started = false;

    /**
     * Starts listening for history changes, and fires any history being listened to.
     */
    start(): void {
        if (this.started) {
            throw new Error("History has already been started.");
        }
        this.started = true;

        this._scrollPositionHistoryQueue = [];

        globals.window.addEventListener("popstate", this._onLocationChange);
        globals.window.addEventListener("hashchange", this._onHashChange);

        // replace the initial state with one that contains a uuid
        if (browser.supportsHistory) {
            this.replaceState(new URL(globals.window.location.href), {
                title: globals.document.title,
                data: {
                    uuid: uuid.uuid4(),
                    pageViewId: analytics.getPageViewId(),
                },
                bypassRouting: true,
            });
            // Since we're bypassing routing we need to set these manually
            this._currentURL = new URL(globals.window.location.href);
            this._currentState = globals.window.history.state;
        }
    }

    /**
     * Unbinds all event listeners and resets all state.
     */
    stop(): void {
        if (!this.started) {
            return;
        }
        this.started = false;

        this._scrollPositionHistoryQueue = null;
        globals.window.removeEventListener("popstate", this._onLocationChange);
        globals.window.removeEventListener("hashchange", this._onHashChange);
    }

    /**
     * Replaces a new state into history.
     */
    replaceState(newURL: URL, options?: StateOptions): void {
        this._updateHistory("replaceState", newURL, options);
    }

    /**
     * Pushes the current state in the history. The argument order of this method is
     * different from the browser API to reflect the most common use cases.
     */
    pushState(newURL: URL, options?: StateOptions): void {
        this._updateHistory("pushState", newURL, options);
    }

    /**
     * Shortcut for pushing a overlay state
     * @param name The name of the overlay (eg "FeedFilters")
     * @param options Extra options to pass to pushState
     */
    pushOverlay(name: string, options: StateOptions & { newURL?: URL } = {}): void {
        let { newURL } = options;
        if (!newURL) {
            newURL = new URL(globals.window.location.href);
        }

        if (!options.data) {
            options.data = {};
        }
        options.data.overlay = name;
        this.pushState(newURL, options);
    }

    /**
     * *Asynchronously* pops the history stack until the named overlay is no longer active
     * @param name The name of the overlay (eg "FeedFilters")
     * @returns A promise to know when the popping is complete
     */
    popOverlay(name: string): Promise<void> {
        if (!name || this._currentState?.overlay != name) {
            return Promise.resolve();
        }

        return new Promise((resolve) => {
            let handleChange = (_, data: BrowserHistoryEvent): void => {
                if (data.state.overlay == name) {
                    this.back();
                    return;
                }
                this.off("change", handleChange);
                resolve();
            };

            this.on("change", handleChange);
            this.back();
        });
    }

    watchOverlay(name: string, callback: (state: false | BrowserHistoryState) => void): () => void {
        let lastState: false | BrowserHistoryState | null = null;
        function stateWatcher(state: BrowserHistoryState): void {
            if (state?.overlay != name) {
                if (lastState !== false) {
                    lastState = false;
                    callback(lastState);
                }
                return;
            }

            if (_isEqual(state, lastState)) {
                return;
            }
            lastState = state;
            callback(state);
        }
        if (this._currentState) {
            stateWatcher(this._currentState);
        }

        return this.on("change", (event, data: BrowserHistoryEvent) => {
            stateWatcher(data.state);
        });
    }

    /**
     * Causes the history to go back one step. This may cause a new page load or a
     * 'popstate' event depending on how the current state was triggered.
     */
    back(): void {
        globals.window.history.back();
    }

    /**
     * Returns the current pathname.
     * @returns {string}
     */
    getCurrentPath(): string {
        return globals.window.location.pathname;
    }

    /**
     * Returns an object containing the current query parameters.
     */
    getCurrentQueryParams(): URLSearchParams {
        return new URLSearchParams(globals.window.location.search);
    }

    /**
     * Returns the current history state
     */
    getCurrentState(): BrowserHistoryState {
        if (!this._currentState) {
            throw new Error("History hasn't started!");
        }
        return this._currentState;
    }

    /**
     * Updates the query string of the url without changing the path.
     * @param query The new query parameters to add or update
     * @param replace If replaceState should be used instead of pushState
     */
    updateQuery(query: URLSearchParams, replace?: boolean): void;
    /**
     * Updates part of the query string of the url without changing the path.
     * @param key The key of the query string to set
     * @param value The new value to set it to
     * @param replace If replaceState should be used instead of pushState
     */
    updateQuery(key: string, value: string | null, replace?: boolean): void;
    updateQuery(
        keyOrQuery: URLSearchParams | string,
        valueOrReplace: string | boolean | null | undefined,
        replace = false
    ): void {
        let newURL = new URL(globals.window.location.href);
        if (_isString(keyOrQuery)) {
            if (valueOrReplace) {
                newURL.searchParams.set(keyOrQuery, valueOrReplace.toString());
            } else {
                newURL.searchParams.delete(keyOrQuery);
            }
        } else {
            newURL.search = url.toQueryString(keyOrQuery);
            replace = !!valueOrReplace;
        }

        this._updateHistory(replace ? "replaceState" : "pushState", newURL);
    }

    /**
     * Convenience method for subscribing to a particular query parameter.
     * The main different with this method in comparison to 'on' is that the
     * callback will be called straight away if the value of the parameter is
     * truth-y at the time of call.
     * @param {string} key The key to subscribe to.
     * @param {function} callback The callback to call when it changes.
     * @returns {function} A function that if called unbinds the handler.
     */
    bindQuery(
        key: string,
        callback: (
            action: "added" | "deleted" | "changed",
            values: string[] | undefined,
            oldValues: string[] | undefined
        ) => void
    ): () => void {
        let value: string[];

        if (this.started) {
            value = this.getCurrentQueryParams().getAll(key);

            // if value is already present and the history module has
            // already been started then call the callback.
            if (value.length) {
                callback("added", value, undefined);
            }
        }

        return this.on("change", (event, data: BrowserHistoryEvent) => {
            let record: DiffResult | undefined = _find(data.queryChanges, { key });

            if (!record || _isEqual(record.value, value)) {
                return;
            }

            value = record.value;
            callback(record.type, record.value, record.oldValue);
        });
    }

    /**
     * Updates the history state using the given method.
     */
    private _updateHistory(
        method: "pushState" | "replaceState",
        newURL: URL,
        options: StateOptions = {}
    ): void {
        // Safety check for non typechecked files since I'm changing the API
        if (typeof newURL == "string") {
            throw new Error("The API for this function has changed, please use `new URL`.");
        }

        if (!browser.supportsHistory) {
            navigate(newURL.href);
            return;
        }

        // fix scroll position bugs in certain browsers, only for pushState
        if (method === "pushState" && options.saveScrollPosition !== false) {
            // fix scroll position when popping to a previous state on the feeds pagination
            let scrollPosition = globals.window.pageYOffset;
            if (typeof options.saveScrollPosition == "number") {
                scrollPosition = options.saveScrollPosition;
            }
            this._scrollPositionHistoryQueue!.push(scrollPosition);
        }

        let state = options.data || {};

        if (!state.uuid) {
            state.uuid = uuid.uuid4();
        }

        let isNewPageView =
            options.forcePageView ||
            this._currentURL == null ||
            newURL.pathname != this._currentURL.pathname ||
            this._getChanges(this._currentURL.searchParams, newURL.searchParams).length > 0;

        // Set the pageViewID associated with this page state
        if (!state.pageViewId) {
            if (isNewPageView) {
                analytics.generatePageViewId();
            }
            state.pageViewId = analytics.getPageViewId();
        }

        // Include previous state so it stacks
        if (this._currentState && !options.wipeState) {
            state = {
                ...this._currentState,
                ...state,
            };
        }

        // Prevent any accidental modification
        state = Object.freeze(state);

        let url = newURL.href;

        if (environment.has("paidSessionId")) {
            url = setPaidSessionIdOnUrl(url, environment.get("paidSessionId"));
        }

        globals.window.history[method](state, options.title ?? globals.document.title, url);
        // needed so that the feeds pagination does not reset when loading new pages
        if (!options.bypassRouting) {
            this._onLocationChange({
                state: state,
                type: method.toLowerCase(),
            });
        }
    }

    /**
     *
     * @param [event] If event is passed then it is being called from 'popstate' event,
     *                otherwise it is being called manually following a pushState or
     *                replaceState.
     */
    private _onLocationChange({ type: eventType, state }: { type: string; state: any }): void {
        let oldURL = this._currentURL;
        let oldState = this._currentState;

        // A copy because URL objects are mutable
        this._currentURL = new URL(globals.window.location.href);
        this._currentState = state;

        let newURL = new URL(window.location.href);
        let queryChanges = this._getChanges(oldURL?.searchParams, newURL?.searchParams);

        eventType = eventType.toLowerCase();

        this.trigger("change", {
            eventType,
            newURL,
            oldState,
            oldURL,
            queryChanges,
            state,
        });

        // On popstate events
        if (eventType === "popstate") {
            // Restore the pageViewId
            const state = globals.window.history.state;
            if (state) {
                analytics.setPageViewId(state.pageViewId);
                analytics.setReferrer(globals.window.location.href, state.pageViewId);
            }
            // Restore the scroll position for browsers that are
            // buggy in restoring it on changing the URL on back.
            let scrollPosition = this._scrollPositionHistoryQueue!.pop();
            if (_isNumber(scrollPosition)) {
                _defer((scrollPosition) => {
                    globals.window.scrollTo(0, scrollPosition);
                }, scrollPosition);
            }
        }
    }

    /**
     * Handler for the hashchange event.
     * @param event
     */
    private _onHashChange(event): void {
        this.trigger("hashchange", event);
    }

    /**
     * Calculates the changes between two sets of query params (ie. added, changed, removed.)
     */
    _getChanges(
        queryA: URLSearchParams | null | undefined,
        queryB: URLSearchParams | null | undefined
    ): DiffResult[] {
        let queryAJSON = url.toURLSearchParams(queryA).toJSON();
        let queryBJSON = url.toURLSearchParams(queryB).toJSON();

        return diff(queryAJSON, queryBJSON);
    }
}

// create the instance exported by this module
export default new BrowserHistory();

import { clsx } from "clsx";
import React, { useEffect, useRef } from "react";
import SizeGuide from "web/react/components/buybuybuy-area/size-guide/size-guide";
import { Option } from "web/react/components/select/types";
import { ErrorText } from "web/react/components/size-picker/error-text";
import { SizePicker } from "web/react/components/size-picker/size-picker";
import {
    ProductPurchaseType,
    ProductPurchaseTypes,
    useInStockProductPageContext,
} from "web/react/pages/product/in-stock/in-stock-product-page/in-stock-product-page.context";
import analytics from "web/script/analytics/analytics";
import { gettext } from "web/script/modules/django-i18n";
import { ProductScreenSize } from "web/types/serializers";
import * as styles from "./size-area.css";

interface SizeAreaProps {
    sizes: ProductScreenSize[];
    isSizePickerExpanded: boolean;
    handleSizeLinkClick: (expanded: boolean, shouldSendAnalytic?: boolean) => void;
    isComparison?: boolean;
    isModal?: boolean;
}

export function SizeArea({
    sizes,
    isSizePickerExpanded,
    handleSizeLinkClick,
    isComparison,
    isModal,
}: SizeAreaProps): React.ReactElement | null {
    const sizeAreaRef = useRef<HTMLDivElement>(null);

    const {
        sizePicker: {
            showSizePicker,
            schemaLabels,
            userDefaultSchema,
            sizeGuideLink,
            setSelectedSizeOption,
            setError,
            setComparisonError,
            error,
            comparisonError,
        },
        activeProduct: { selectedSizeOption },
        productPurchaseType,
    } = useInStockProductPageContext();

    const isExpressCheckout = productPurchaseType === ProductPurchaseType.EXPRESS_CHECKOUT;

    // scroll to the size picker error
    useEffect(() => {
        if (isComparison && comparisonError && sizeAreaRef.current) {
            sizeAreaRef.current.scrollIntoView({ behavior: "smooth" });
        }
    }, [isComparison, comparisonError]);

    function handleSizeSelect(
        productPurchaseType: ProductPurchaseTypes,
        option: Option,
        isComparison: boolean
    ): void {
        const action = option.disabled ? "select_oos_alert" : "select_in_stock_size";
        analytics.event(
            isComparison ? "compare_prices_size_picker" : "size_picker",
            action,
            option.selectedText,
            false,
            null,
            `${productPurchaseType}.size_picker.${action}`,
            {},
            [],
            true
        );
    }

    const onSizePickerSelect = (option: Option, isComparison: boolean): void => {
        if (isExpressCheckout) {
            analytics.event("express_checkout", "changed_size");
            return;
        }
        handleSizeSelect(productPurchaseType, option, isComparison);
        setSelectedSizeOption(option.value);
    };

    const shouldShow = showSizePicker || (!showSizePicker && !!isModal);
    if (shouldShow)
        return (
            <div
                ref={sizeAreaRef}
                className={clsx(styles.sizePicker, {
                    ["hidden-tablet"]: isComparison,
                    [styles.sizePickerComparison]: isComparison,
                })}
            >
                {!isComparison && !isExpressCheckout && !isModal && (
                    <SizeGuide sizeGuideLink={sizeGuideLink} />
                )}
                <SizePicker
                    error={isComparison ? comparisonError : error}
                    onSelect={(option: Option, isComparison: boolean) => {
                        setError(false);
                        setComparisonError(false);
                        onSizePickerSelect(option, isComparison);
                    }}
                    isExpanded={isSizePickerExpanded}
                    schemas={schemaLabels}
                    defaultSelectText={gettext("product.buy_area.select_size.label")} // "Select size to see lowest price"
                    userDefaultSchema={userDefaultSchema}
                    setExpanded={handleSizeLinkClick}
                    sizes={sizes}
                    productType={productPurchaseType}
                    selectedOption={selectedSizeOption || undefined}
                    isExpressCheckout={isExpressCheckout}
                    isComparison={!!isComparison}
                    showDisabledOptions
                />
                {isComparison ? comparisonError && <ErrorText /> : error && <ErrorText />}
            </div>
        );

    return null;
}

import baustein from "baustein";
import { openCustomerCaptureOverlay } from "web/redux/ducks/customer-capture-overlay";
import store from "web/redux/store";
import analytics from "web/script/analytics/analytics";
import requester from "web/script/modules/requester";

const plusSvgName = "plus";
const tickSvgName = "tick";

export default baustein.register("follow-button", {
    defaultOptions: {
        registrationReason: "",
        nextPath: "",
        followedText: "",
        notfollowedText: "",
        lystId: "",
        category: "",
        isAuthenticated: false,
        isFollowing: false,
        isInHeader: false,
    },

    init() {
        this.following = this.options.isFollowing;
    },

    setupEvents: function (add) {
        add("click", this.onClick);
    },

    onClick() {
        const label = this.following ? "Unfollow" : "Follow";
        analytics.event("Follow Button", "Clicked", label);
        if (this.options.isInHeader) {
            analytics.event("du_feed_cta", "click_top_cta", "du_cta");
        }
        if (this.options.isAuthenticated) {
            this.followToggle(this.options.lystId);
        } else {
            this.triggerSignup();
        }
    },

    triggerSignup() {
        const emailCaptureOptions = {
            captureType: "signup_du",
            designerId: this.options.lystId,
            next: this.options.nextPath,
        };
        store.dispatch(openCustomerCaptureOverlay(emailCaptureOptions));
    },

    followToggle(lystId) {
        if (this.following) {
            this.unfollow(lystId);
        } else {
            this.follow(lystId);
        }
    },

    follow() {
        this.followButtonState();
        this.following = true;
        this.request("/api/users/add/", { lyst_id: this.options.lystId })
            .then(() => {
                analytics.event("Follow Button", "Follow - Success", null, true);
            })
            .catch((err) => {
                analytics.event("Follow Button", "Follow - Error", null, true);
                console.error("There was a problem following.", err);
                this.unfollowButtonState();
                this.following = false;
            });
        this.emit("userFollow");
    },

    unfollow() {
        this.unfollowButtonState();
        this.following = false;
        this.request("/api/users/remove/", { lyst_id: this.options.lystId })
            .then(() => {
                analytics.event("Follow Button", "Unfollow - Success", null, true);
            })
            .catch((err) => {
                analytics.event("Follow Button", "Unfollow - Error", null, true);
                console.error("There was a problem unfollowing.", err);
                this.followButtonState();
                this.following = true;
            });
        this.emit("userUnfollow");
    },

    unfollowButtonState() {
        const textEl = this.el.querySelector("span");
        const svgEl = this.el.querySelector("svg");
        textEl.textContent = this.options.notfollowedText;
        this.toggleSvgOnElemenet(svgEl, plusSvgName, tickSvgName);
    },

    followButtonState() {
        const textEl = this.el.querySelector(".follow-button__copy");
        const svgEl = this.el.querySelector("svg");
        textEl.textContent = this.options.followedText;
        this.toggleSvgOnElemenet(svgEl, tickSvgName, plusSvgName);
    },

    toggleSvgOnElemenet(el, addSvg, removeSvg) {
        el.classList.remove(`svg-icon-${removeSvg}`);
        el.classList.add(`svg-icon-${addSvg}`);
        el.querySelector("use").setAttributeNS(
            "http://www.w3.org/1999/xlink",
            "xlink:href",
            `#${addSvg}`
        );
    },

    request(url, data) {
        const options = {
            body: data,
            method: "POST",
        };
        return requester(url, options).then(requester.toJSON);
    },
});

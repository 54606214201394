import loader from "./loader";

const load = loader(() => {
    var s = document.createElement("script");
    s.type = "text/javascript";
    s.async = true;
    s.src = "https://s.kk-resources.com/ks.js";
    var x = document.getElementsByTagName("script")[0];
    x.parentNode.insertBefore(s, x);
});

export default function (data) {
    window._kkstrack = data;
    load();
}

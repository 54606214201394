import clsx from "clsx";
import isEqual from "lodash/isEqual";
import React from "react";
import { Option, OptionPropsUnion } from "web/react/components/select/types";
import styles from "./options.module.css";

export interface OptionsProps {
    className?: string;
    isExpanded: boolean;
    onClick: (option: Option) => (event: React.MouseEvent<HTMLDivElement>) => void;
    OptionComponent: (optionComponentProps: OptionPropsUnion) => JSX.Element;
    optionHeadingElement?: JSX.Element;
    options: Option[];
    optionsClassName?: string;
    optionsWrapperClassName?: string;
    selectedOption: Option;
    optionFooterElement?: JSX.Element;
    showDisabledOptions?: boolean;
}

export default function Options({
    className,
    isExpanded,
    onClick,
    OptionComponent,
    optionHeadingElement,
    options,
    optionsClassName,
    optionsWrapperClassName,
    selectedOption,
    optionFooterElement,
    showDisabledOptions,
}: OptionsProps): JSX.Element {
    const numColumns = options.filter((obj) => obj.text.length > 1).length > 0 ? 2 : 1;
    return (
        <div
            className={clsx(
                styles.optionsWrapper,
                { [styles.expanded]: isExpanded },
                optionsWrapperClassName,
                className
            )}
        >
            {optionHeadingElement}
            <div className={clsx(styles.options, optionsClassName)}>
                {options.map((option) => {
                    const hideItem = !showDisabledOptions && option.disabled;
                    const isSelected = isEqual(option.value, selectedOption.value);
                    return hideItem ? null : (
                        <OptionComponent
                            isSelected={isSelected}
                            onClick={onClick(option)}
                            option={option}
                            numColumns={numColumns}
                            key={option.text.toString()}
                        />
                    );
                })}
                {optionFooterElement}
            </div>
        </div>
    );
}

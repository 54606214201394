import format from "string-format";
import { gettext } from "web/script/modules/django-i18n";
import environment from "web/script/modules/environment";
import { numberFormat } from "web/script/modules/formats";

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
//
//   ____
//  / ___|   _ _ __ _ __ ___ _ __   ___ _   _
// | |  | | | | '__| '__/ _ \ '_ \ / __| | | |
// | |__| |_| | |  | | |  __/ | | | (__| |_| |
//  \____\__,_|_|  |_|  \___|_| |_|\___|\__, |
//                                      |___/

/**
 * How many decimal places for prices in this currency?
 *
 * @param {String} currency - string currency code
 * @return {Number}
 */
function getCurrencyDecimalPlaces() {
    return environment.get("currencyProps.currencyDecimalPlaces", 2);
}

/**
 * Quantize a price to decimal places appropriate for the currency.
 *
 * @param {Number} price
 * @param {String} currency - string currency code
 * @return {Number}
 */
function quantizePriceForCurrency(price) {
    const decimalPlaces = getCurrencyDecimalPlaces();
    const quantizedPrice = price.toFixed(decimalPlaces);
    return quantizedPrice;
}

/**
 * Provide text representation of the currency symbol.
 *
 * @return {String}
 */
function _getCurrencySymbol() {
    const currencySymbol = environment.get("currencyProps.currencySymbol", "$");
    return currencySymbol;
}

/**
 * Provide text representation of a `price`, including currency symbol.
 *
 * @param {String} priceText
 * @return {String}
 */
function _getPriceWithCurrencySymbol(priceText) {
    const priceWithCurrencySymbolFormat = environment.get(
        "currencyProps.priceWithCurrencySymbolFormat",
        "{currency_symbol}{price}"
    );
    return format(priceWithCurrencySymbolFormat, {
        currency_symbol: _getCurrencySymbol(),
        price: priceText,
    });
}

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
//
//  ____       _
// |  _ \ _ __(_) ___ ___
// | |_) | '__| |/ __/ _ \
// |  __/| |  | | (_|  __/
// |_|   |_|  |_|\___\___|

/**
 * All functions take Numbers as parameters.
 *
 * @param {Mixed}
 * @return {Number}
 */
function _ensurePriceIsNumeric(price) {
    if (typeof price == "number") {
        return price;
    } else if (price || price != null) {
        try {
            return parseFloat(price);
        } catch (e) {
            return 0;
        }
    }
    return 0;
}

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
//  _   _
// | | | |_   _ _ __ ___   __ _ _ __
// | |_| | | | | '_ ` _ \ / _` | '_ \
// |  _  | |_| | | | | | | (_| | | | |
// |_| |_|\__,_|_| |_| |_|\__,_|_| |_|
//
// ____                _       _     _
// |  _ \ ___  __ _  __| | __ _| |__ | | ___
// | |_) / _ \/ _` |/ _` |/ _` | '_ \| |/ _ \
// |  _ <  __/ (_| | (_| | (_| | |_) | |  __/
// |_| \_\___|\__,_|\__,_|\__,_|_.__/|_|\___|
//
// ____       _
// |  _ \ _ __(_) ___ ___
// | |_) | '__| |/ __/ _ \
// |  __/| |  | | (_|  __/
// |_|   |_|  |_|\___\___|

/**
 * Provide a round number text representation of the Number `price`
 * in the local format for the user"s language.
 * E.g. Uses space or comma for thousands separators
 *
 *   _geRoundPrice(29.99)   >> "30"
 *   _getRoundPrice(1030.00) >> "1,030"
 *
 * @param {String|Number} price - the price to format
 * @return {String}
 */
function _getRoundPrice(price) {
    return numberFormat(parseInt(price, 10));
}

/**
 * Provide an exact text representation of the Number `price`
 * expressed with decimal places appropriate for the currency
 * in the local number format for the user"s language.
 * E.g. Uses dot or comma for decimal point,
 * and space or comma for thousands separators
 *
 *   _getExactPrice(29.99, "USD")   >> "29.99"
 *   _getExactPrice(1030.00, "USD") >> "1,030.00"
 *
 * @param {String|Number} price - the price to format
 * @param {String} currency - the currency, so we know how many decimal places
 * @return {String}
 */
function _getExactPrice(price, currency) {
    const quantizedPrice = quantizePriceForCurrency(price, currency);
    return numberFormat(quantizedPrice);
}

/**
 * Provide compact text representation of the Number `price`
 * "Compact" means showing the decimal place only if the price is not a whole integer value.
 *
 *   _getCompactPrice(29.99, "USD")   >> "29.99"
 *   _getCompactPrice(1030.00, "USD") >> "1,030"
 *
 * @param {String|Number} price - the price to format
 * @param {String} currency - the currency, so we know how many decimal places
 * @return {String}
 */
function _getCompactPrice(price, currency) {
    const quantizedPrice = quantizePriceForCurrency(price, currency);
    const isNotWholeNumber = quantizedPrice % 1 != 0;
    if (isNotWholeNumber) {
        return _getExactPrice(price, currency);
    } else {
        return _getRoundPrice(price);
    }
}

/**
 * Get a string representation of a price in the local format,
 * maybe with a currency symbol.
 *
 * Options for formatting the price number:
 *     - round
 *     - exact
 *     - compact
 *
 * Options for currency symbol:
 *     - no currency symbol
 *     - with currency symbol
 *
 * Select options by chaining method calls together.
 * The first method call selects the price number format.
 * The second method call selects the currency symbol option.
 *
 * Examples with currency symbol:
 *
 *   formatPrice(1299.99).compact().withCurrency() >> '$1,229.99'
 *   formatPrice(1300.0).compact().withCurrency() >> '$1,300'
 *
 * Examples with no currency symbol:
 *
 *   formatPrice(29.99).round().withoutCurrency() >> '30'
 *   formatPrice(29.99).exact().withoutCurrency() >> '29.99'
 *
 * @param {String|Number} price - Number ideally, but other datatypes accepted
 * @return {any}
 */
export function formatPrice(price) {
    function PriceWithCurrencyFormatter(price, priceNumberFormatter) {
        price = _ensurePriceIsNumeric(price);
        const currency = environment.get("currencyProps.currencyCode");
        return {
            _getPrice() {
                return priceNumberFormatter(price, currency);
            },
            // e.g. formatPrice(price).exact().withoutCurrency()
            withoutCurrency() {
                return this._getPrice();
            },
            // e.g. formatPrice(price).compact().withCurrency()
            withCurrency() {
                return _getPriceWithCurrencySymbol(this._getPrice());
            },
        };
    }

    function PriceNumberFormatter(price) {
        return {
            round() {
                return PriceWithCurrencyFormatter(price, _getRoundPrice);
            },
            exact() {
                return PriceWithCurrencyFormatter(price, _getExactPrice);
            },
            compact() {
                return PriceWithCurrencyFormatter(price, _getCompactPrice);
            },
        };
    }

    return PriceNumberFormatter(price);
}

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
//  ____  _                           _
// |  _ \(_)___  ___ ___  _   _ _ __ | |_ ___
// | | | | / __|/ __/ _ \| | | | '_ \| __/ __|
// | |_| | \__ \ (_| (_) | |_| | | | | |_\__ \
// |____/|_|___/\___\___/ \__,_|_| |_|\__|___/

/**
 * Returns a localized text representation of a discount percentage.
 *
 * See: src/ssaw/utils/price.py
 *
 * @param {Number} discount_percent - Price discount percentage as a Decimal value in the range [0,100]
 * @return {String}
 */
export function getRoundNumberWithPercentSymbol(discountPercent = 0) {
    discountPercent = parseInt(_ensurePriceIsNumeric(discountPercent), 10);
    return gettext("general.integer_with_percent_symbol", {
        integer: discountPercent,
    });
}

import { Form, Formik, FormikErrors, FormikHelpers } from "formik";
import React, { useState } from "react";
import Button from "web/react/components/__LEGACY__/LEGACY_button/button";
import { FormikPasswordField } from "web/react/components/forms/formik";
import { Heading } from "web/react/emo/heading";
import { Text } from "web/react/emo/text/text";
import { VStack } from "web/react/emo/v-stack";
import { ValidationRequirements } from "web/react/emo/validation-requirement";
import { View } from "web/react/emo/view";
import styles from "web/react/pages/account/reset-password-pages/reset-password-pages.module.css";
import analytics from "web/script/analytics/analytics";
import { gettext } from "web/script/modules/django-i18n";
import globals from "web/script/modules/globals";
import requester from "web/script/modules/requester";
import { DEFAULT_PASSWORD_REQUIREMENTS, validatePassword } from "web/script/utils/validate";

type ResetPasswordScreenValues = {
    new_password1: string;
    new_password2: string;
};

export default function ResetPasswordPage(): JSX.Element {
    const [isValidationRequirementsVisible, setIsValidationRequirementsVisible] = useState(false);

    const initialValues: ResetPasswordScreenValues = {
        new_password1: "",
        new_password2: "",
    };

    async function onSubmit(
        values: ResetPasswordScreenValues,
        actions: FormikHelpers<ResetPasswordScreenValues>
    ): Promise<void> {
        actions.setSubmitting(true);

        try {
            const response = await requester.post(window.location.pathname, values as any);

            if (response.success) {
                globals.window.location.href = globals.window.location.origin;
                return;
            }
            throw new Error(response.data?.errors?.join?.("\n") ?? "Unknown error");
        } catch (e) {
            analytics.event("reset_password_from_email", "password_fields");
        }
        actions.setSubmitting(false);
    }

    function validate(values: ResetPasswordScreenValues): FormikErrors<ResetPasswordScreenValues> {
        const errors: FormikErrors<ResetPasswordScreenValues> = {};

        setIsValidationRequirementsVisible(false);

        if (!values.new_password1) {
            errors.new_password1 = gettext("account.invalid_password_format.label");
        }
        if (!values.new_password2) {
            errors.new_password2 = gettext("account.invalid_password_format.label");
        }

        if (values.new_password1 && values.new_password2) {
            if (
                values.new_password1 === values.new_password2 &&
                !validatePassword(values.new_password2)
            ) {
                errors.new_password2 = gettext("account.invalid_password_format.label");
                setIsValidationRequirementsVisible(true);
            } else if (values.new_password1 !== values.new_password2) {
                errors.new_password2 = gettext("account.two_passwords_no_match.label");
            }
        }

        return errors;
    }

    return (
        <>
            <View
                className={styles.wrapper}
                paddingTop={{ sm: "lg", xl: "xxl" }}
                paddingBottom={{ sm: "lg", xl: "xxl" }}
                paddingRight={{ sm: "sm" }}
                paddingLeft={{ sm: "sm" }}
            >
                <VStack spacing="md">
                    <Heading textStyle={{ lg: "large-title", sm: "large-title-2" }} as="h1">
                        {gettext("account.reset_password.title")}
                    </Heading>
                    <Text textStyle={"body-1"}>{gettext("account.reset_password.desc")}</Text>
                    <Formik
                        initialValues={initialValues}
                        onSubmit={onSubmit}
                        validate={validate}
                        validateOnChange={false}
                        validateOnBlur={false}
                    >
                        {({ isSubmitting, values }): JSX.Element => (
                            <Form noValidate>
                                <VStack spacing="sm">
                                    <FormikPasswordField
                                        label={gettext("account.create_password.title")}
                                        id="new_password1"
                                        name="new_password1"
                                        onFocus={() => {
                                            analytics.event("reset_password", "field_password_1");
                                        }}
                                        placeholder={gettext("account.create_password.title")}
                                        autoComplete="new-password"
                                        minLength={8}
                                        maxLength={125}
                                    />
                                    <FormikPasswordField
                                        label={gettext("account.confirm_your_password.label")}
                                        id="new_password2"
                                        name="new_password2"
                                        onFocus={() => {
                                            analytics.event("reset_password", "field_password_2");
                                        }}
                                        placeholder={gettext("account.confirm_your_password.label")}
                                        autoComplete="new-password"
                                        minLength={8}
                                        maxLength={125}
                                    />
                                    {isValidationRequirementsVisible && (
                                        <ValidationRequirements
                                            requirements={DEFAULT_PASSWORD_REQUIREMENTS}
                                            title={gettext("account.set_password_contain.title")}
                                            value={values.new_password2}
                                        />
                                    )}
                                    <Button styleType="cta" disabled={isSubmitting} type="submit">
                                        {gettext("account.save_new_password.cta")}
                                    </Button>
                                </VStack>
                            </Form>
                        )}
                    </Formik>
                </VStack>
            </View>
        </>
    );
}

import _each from "lodash/each";
import _isArray from "lodash/isArray";
import _isObject from "lodash/isObject";
import _isString from "lodash/isString";
import environment from "web/script/modules/environment";
import globals from "web/script/modules/globals";
import { getLanguage } from "web/script/modules/language";

export const BASE_URL = "https://www.lyst.com/";

type URLSearchParamsLike = {
    [key: string]: string | string[] | null | undefined;
};

export interface NotQuiteAURL {
    protocol: string;
    host: string;
    searchParams: URLSearchParams;
    path: string;
    hash: string;
    pathname: string;
    search: string;
    href: string;
    hostname: string;
}

export type URLQuery = URLSearchParams | URLSearchParamsLike | string;

declare global {
    interface URLSearchParams {
        /**
         * Note: This method is a deviation from the spec
         * @returns an object representing the current state of the URLSearchParams instance.
         */
        toJSON(): { [key: string]: string[] };
        /**
         * Utility function to remove one value from a parameter list
         */
        deleteValue(name: string, valueToRemove: string): void;
    }
}

URLSearchParams.prototype.toJSON = function (): { [key: string]: string[] } {
    let result = {};
    for (let key of this.keys()) {
        result[key] = this.getAll(key);
    }
    return result;
};

URLSearchParams.prototype.deleteValue = function deleteValue(
    name: string,
    valueToRemove: string
): void {
    if (!this.has(name)) {
        return;
    }

    let existing = this.getAll(name);
    this.delete(name);
    for (let value of existing) {
        if (value != valueToRemove) {
            this.append(name, value);
        }
    }
};

/**
 * Joins all arguments together ensuring there is a leading and trailing slash.
 * Any arguments that not non-empty strings are ignored.
 */
export function join(...args: string[]): string {
    let result = `/${args.filter((s) => !!s).join("/")}/`.replace(/\/+/g, "/");
    if (result == "/") {
        return "";
    }
    return result;
}

/**
 * Turns a URLSearchParams instance or a plain object into an encoded query string.
 */
export function toQueryString(params: URLSearchParams | URLSearchParamsLike): string {
    if (params instanceof URLSearchParams) {
        return params.toString();
    }

    let searchParams = new URLSearchParams();

    _each(params, function (value, key) {
        if (_isArray(value)) {
            value.forEach(searchParams.append.bind(searchParams, key));
        } else if (value != null) {
            searchParams.append(key, value);
        }
    });

    return searchParams.toString();
}

/**
 * Unparses an object with the same structure as returned from `parse` and returns a string.
 * For flexibility the `searchParams` property can be a URLSearchParams instance or just a plain object.
 */
export function unparse(parsed: NotQuiteAURL): string {
    let url = "";
    let queryString = toQueryString(parsed.searchParams);

    url += parsed.protocol ? parsed.protocol + "//" : "";
    url += parsed.host || "";
    url += parsed.path || "";
    url += queryString ? "?" + queryString : "";
    url += parsed.hash || "";

    return url;
}

/**
 * Parses a URL into an object. The returns object can be mutated to change the URL and
 * then passed to `unparse` to get a URL string again.
 */
export function parse(url: string): NotQuiteAURL {
    let parsedURL;
    try {
        parsedURL = new URL(url, globals.window.location.href);
    } catch {
        if (globals.window.location) {
            console.error(`${url} is not a valid URL`);
        }
    }

    let parsed = {
        protocol: parsedURL?.protocol,
        host: parsedURL?.host,
        searchParams: parsedURL?.searchParams,
        path: parsedURL?.pathname,
        hash: parsedURL?.hash,
        pathname: parsedURL?.pathname,
        search: parsedURL?.search,
        href: parsedURL?.href,
        hostname: parsedURL?.hostname,
    };

    parsed.toString = unparse.bind(null, parsed);

    return parsed;
}

/**
 * Creates a URLSearchParams instance from `obj`.
 */
export function toURLSearchParams(obj: URLQuery | null | undefined): URLSearchParams {
    if (obj instanceof URLSearchParams) {
        return obj;
    }

    if (_isString(obj)) {
        return new URLSearchParams(obj.replace(/^\?/, ""));
    }

    if (_isObject(obj) && !_isArray(obj)) {
        return new URLSearchParams(toQueryString(obj));
    }

    return new URLSearchParams();
}

export function isAbsoluteUrl(url: string): boolean {
    return url.indexOf("://") > 0 || url.indexOf("//") === 0;
}

const IS_LYST_DOMAIN_REGEX =
    /^https?:\/\/[^\/]*?\.?(srv.lystit.com|127.0.0.1|localhost|xip.io|lyst.com|lyst.co.uk|lyst.com.au|lyst.fr|lyst.es|lyst.it|lyst.de|lyst.at|lyst.ca|lyst.com.nl|lyst.jp.net|lyst.com.ru|lyst.ch|lystapp.be)/;
export function isLystUrl(url: string): boolean {
    // relative URLs are always Lyst URLs
    if (!isAbsoluteUrl(url)) {
        return true;
    }
    return IS_LYST_DOMAIN_REGEX.test(url);
}

/**
 *
 * @param url URL to open
 * @param reason Unique identifier used when reporting errors
 * @returns void
 */
export function redirectInNewTab(url: string | undefined, reason: string): void {
    if (!url) return;

    // wrapped in a setTimeout to avoid issues with
    // window.open in an async function in iOS
    setTimeout(() => {
        const newTab = globals.window.open(url, "_blank");
        if (!newTab) {
            console.error(`Failed to open new tab - ${reason}.`);
        }
    }, 0);
}

/**
 * Prepends the language code to the url path if the language is not en-us or en-gb. en-us and en-gb are the default languages of lyst.com and lyst.co.uk respectively. The back end resolving logic will not work if they are prepended.
 *
 * This function always assumes that the relativeReference is relative, even if specified with a leading slash.
 *
 * @param relativeReference a URL path to be prepended with the language code
 * @returns url path prepended with language if language is not en-us or en-gb
 */
export function buildLanguageAwareUrlPath(relativeReference: string): string {
    const baseUrl = environment.get("publicUrl", BASE_URL);

    if (!relativeReference.startsWith("/")) {
        relativeReference = `/${relativeReference}`;
    }

    const urlSansLanguage = new URL(relativeReference, baseUrl);

    // Strip leading slash from urlPath to treat it as a relative path. This is necessary for the URL constructor to work correctly.
    // See https://developer.mozilla.org/en-US/docs/Web/API/URL_API/Resolving_relative_references
    let urlPath = urlSansLanguage.pathname.substring(1);

    // Append trailing slash to urlPath if it doesn't exist to avoid an unnecessary redirect
    if (!urlPath.endsWith("/")) {
        urlPath = `${urlPath}/`;
    }

    // Rebuild relative reference. Ignore anchors.
    relativeReference = `${urlPath}${urlSansLanguage.search}`;

    // If language is en-us or en-gb, treat the language path as the root path (/)
    const language = getLanguage();

    let languagePath = `/${language}/`;
    if (language === "en-us" || language === "en-gb") {
        languagePath = "/";
    }
    // Do not prepend the language code if it is already in the path
    if (relativeReference.startsWith(`${language}/`)) {
        languagePath = "/";
    }

    // Construct the URL by replacing the current path with the language code path then append the supplied urlPath.
    // Using publicUrl set on environment here to avoid problems with server-side rendering.
    let url = new URL(languagePath, baseUrl);
    url = new URL(relativeReference, url.href);

    return `${url.pathname}${url.search}`;
}

export default {
    URLSearchParams,
    join,
    parse,
    unparse,
    toQueryString,
    toURLSearchParams,
};

import clsx from "clsx";
import React from "react";
import { useTabSwitcherContext } from "./tab-switcher";
import styles from "./tab-switcher-button.module.css";

export interface TabSwitcherButtonInterface {
    children: React.ReactNode;
    index?: number;
    onClick?: () => any;
}

function InnerTab({ name }: { name: string }): JSX.Element {
    return (
        <span>
            {name}
            <span className={styles.innerTabUnderline}></span>
        </span>
    );
}

function TabSwitcherButton({
    children,
    index,
    onClick,
}: TabSwitcherButtonInterface): React.ReactElement {
    const { currentTabIndex, setCurrentTabIndex } = useTabSwitcherContext();

    function handleClick(): void {
        onClick?.();
        setCurrentTabIndex(index as number);
    }

    return (
        <li
            role="tab"
            tabIndex={index === currentTabIndex ? 0 : -1}
            aria-selected={index === currentTabIndex}
            aria-controls={`tabpanel-${index}`}
            className={clsx(styles.tab, {
                [styles.activeTab]: index === currentTabIndex,
            })}
            onClick={handleClick}
        >
            <h5 className={styles.tabTitle} data-content={children}>
                {children}
            </h5>
        </li>
    );
}

TabSwitcherButton.InnerTab = InnerTab;

export default TabSwitcherButton;

import clsx from "clsx";
import React, { useRef } from "react";
import Slider from "react-slick";
import SecondaryButton from "web/react/components/buttons/secondary-button/secondary-button";
import FollowDesignerButton from "web/react/components/follow-designer-button/LEGACY/follow-designer-button";
import LoadingSpinner from "web/react/components/loading-spinner/loading-spinner";
import styles from "web/react/components/product-area-rail/product-area-rail.module.css";
import ProductCard from "web/react/components/product-card/react";
import ProductSectionHeader from "web/react/components/product-section-header/product-section-header";
import SVGIcon from "web/react/components/svg-icon/svg-icon";
import analytics from "web/script/analytics/analytics";
import { gettext } from "web/script/modules/django-i18n";

interface SliderArrowProps {
    className?: string;
    onClick?: () => void;
}

export function PrevArrow({ onClick, className }: SliderArrowProps): React.ReactElement {
    function handlePrev(): void {
        analytics.event("product-cards", "paginated", "previous_button");
        onClick?.();
    }

    return (
        <SecondaryButton
            className={clsx(className, styles.navButton, styles.prevNavButton)}
            small
            onClick={handlePrev}
        >
            <SVGIcon name="chevron" />
            {/* Previous */}
            {gettext("paginated_carousel.prev_btn_txt")}
        </SecondaryButton>
    );
}

export function NextArrow({ onClick, className }: SliderArrowProps): React.ReactElement {
    function handleNext(): void {
        analytics.event("product-cards", "paginated", "next_button");
        onClick?.();
    }

    return (
        <SecondaryButton
            className={clsx(className, styles.navButton, styles.nextNavButton)}
            small
            onClick={handleNext}
        >
            {/* Next */}
            {gettext("paginated_carousel.next_btn_txt")}
            <SVGIcon name="chevron" />
        </SecondaryButton>
    );
}

interface Designer {
    lystId: number;
    name: string;
}

interface ProductAreaRailProps {
    analyticsEventLabel?: string;
    title: string;
    products: any;
    isLoading?: boolean;
    showFollowCTA?: boolean;
    designer?: Designer;
    isAuthenticated?: boolean;
    isFollowing?: boolean;
}

function ProductAreaRail({
    analyticsEventLabel = undefined,
    title,
    products,
    isLoading = false,
    showFollowCTA = false,
    designer,
    isAuthenticated = false,
    isFollowing = false,
}: ProductAreaRailProps): React.ReactElement | null {
    const sliderRef = useRef<any>(null);

    const slickSettings = {
        arrows: true,
        draggable: false,
        infinite: false,
        slidesToShow: 5,
        slidesToScroll: 5,
        swipe: false,
        touchMove: false,
        prevArrow: <PrevArrow />,
        nextArrow: <NextArrow />,
        responsive: [
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    swipe: true,
                    touchMove: true,
                    draggable: true,
                    arrows: false,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    swipe: true,
                    touchMove: true,
                    draggable: true,
                    arrows: false,
                },
            },
        ],
    };

    return (
        <div className={styles.wrapper}>
            <div className={styles.container}>
                <div className={styles.headerContainer}>
                    <ProductSectionHeader title={title} usesFeedLayout={true} />
                    {showFollowCTA && designer?.lystId && (
                        <FollowDesignerButton
                            lystId={designer?.lystId}
                            designerName={designer?.name}
                            className={
                                isFollowing ? styles.followButtonFollowed : styles.followButton
                            }
                            downloadApp={true}
                            isFollowing={isFollowing ? "true" : "false"}
                            analyticsParameters={[
                                "follow_new_arrivals",
                                "clicked",
                                "follow_button",
                            ]}
                            notFollowedText={gettext("follow_button.label.follow")}
                            followedText={gettext("follow_button.label.following")}
                            isAuthenticated={isAuthenticated ? "true" : "false"}
                        />
                    )}
                </div>
                {isLoading ? (
                    <div className={styles.loader}>
                        <LoadingSpinner type="highlight" />
                    </div>
                ) : (
                    <div data-testid="slider">
                        <Slider ref={sliderRef} className={styles.products} {...slickSettings}>
                            {products?.map((product) => (
                                <div key={product.uid} className={styles.productCard}>
                                    <ProductCard product={product}>
                                        <ProductCard.Image
                                            width={200}
                                            height={250}
                                            analyticsEventLabel={analyticsEventLabel}
                                        />

                                        <ProductCard.SaveForLaterButton
                                            isAffiliate={product.is_affiliate}
                                        />

                                        <ProductCard.Details
                                            analyticsEventLabel={analyticsEventLabel}
                                        />
                                    </ProductCard>
                                </div>
                            ))}
                        </Slider>
                    </div>
                )}
            </div>
        </div>
    );
}

export default ProductAreaRail;

/* eslint-disable max-depth */
import loadable from "@loadable/component";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import GenericStickyModal from "web/react/components/generic-sticky-modal/generic-sticky-modal";
import withReduxProvider from "web/react/redux-provider";
import { closeCustomerCaptureOverlay } from "web/redux/ducks/customer-capture-overlay";
import { ReduxStoreState } from "web/redux/store";
import analytics from "web/script/analytics/analytics";
import { gettext } from "web/script/modules/django-i18n";
import { BranchIOLinkData, getLinkData } from "web/script/utils/branch-io";
import mobileImage from "./images/get-app-phone.png";
import styles from "./qrcode-overlay.module.css";

const QRCode = loadable(() => import(/* webpackChunkName: "qrcode.react" */ "qrcode.react"));

export function _QRCodeOverlay(): JSX.Element {
    const {
        captureType = "qr_code",
        open: overlayOpen,
        branchLink = "",
    } = useSelector((state: ReduxStoreState) => state.customerCaptureOverlay);

    // Scan the code on your phone
    const title = gettext("app_landing.scan_code.title");
    // Use your phones camera to scan and download the free Lyst app
    const desc = gettext("app_landing.scan_code.desc");
    // Available on iOS
    const label = gettext("app_landing.available_ios.label");
    const hidden = "hidden";
    const visibilityChange = "visibilitychange";
    const dispatch = useDispatch();

    const [branchHref, setBranchHref] = useState<string>(branchLink);

    useEffect(() => {
        const branchReferralLink = new URL(branchLink);
        const branchIOLinkData: BranchIOLinkData = getLinkData();
        for (const branchDataProperty in branchIOLinkData) {
            branchReferralLink.searchParams.set(
                branchDataProperty,
                branchIOLinkData[branchDataProperty]
            );
        }

        setBranchHref(branchReferralLink.toString());
    }, [branchLink]);

    function handleVisibilityChange(): void {
        if (!document[hidden]) {
            analytics.event(captureType, "view_form", "back_overlay");
            document.removeEventListener(visibilityChange, handleVisibilityChange);
        }
    }

    function triggerAfterOpen(): void {
        analytics.event(captureType, "open_qrcode_overlay", "open_overlay", true);
        document.addEventListener(visibilityChange, handleVisibilityChange, false);
    }

    function triggerClose(): void {
        analytics.event(captureType, "close_qrcode_overlay", "close_overlay");
        document.removeEventListener(visibilityChange, handleVisibilityChange);
        dispatch(closeCustomerCaptureOverlay());
    }

    return (
        <GenericStickyModal
            isOpen={overlayOpen}
            onAfterOpen={triggerAfterOpen}
            styleType={"fullscreen"}
            className={styles.modal}
            onClose={triggerClose}
        >
            <>
                <QRCode value={branchHref} className={styles.qrCodeImage} alt={"QR code"} />
                <h1 className={styles.title}>{title}</h1>
                <span className={styles.desc}>{desc}</span>
                <span className={styles.label}>{label}</span>
                <img src={mobileImage} className={styles.phoneImage} alt={"Lyst app"} />
            </>
        </GenericStickyModal>
    );
}

export const QRCodeOverlay = withReduxProvider(_QRCodeOverlay);

import { useState } from "react";

export interface UseThreeDsChallenge {
    isThreeDsChallenged: boolean;
    threeDsUrl: string;
    setThreeDsChallenged: (threeDsChallenged: boolean) => void;
    setThreeDsUrl: (url: string) => void;
    resetThreeDsChallenged: () => void;
}

function useThreeDsChallenge(): UseThreeDsChallenge {
    const [isThreeDsChallenged, setThreeDsChallenged] = useState<boolean>(false);
    const [threeDsUrl, setThreeDsUrl] = useState<string>("");

    function resetThreeDsChallenged(): void {
        setThreeDsChallenged(false);
        setThreeDsUrl("");
    }

    return {
        isThreeDsChallenged,
        threeDsUrl,
        setThreeDsChallenged,
        setThreeDsUrl,
        resetThreeDsChallenged,
    };
}

export default useThreeDsChallenge;

import browser from "web/script/utils/browser";
import style from "./style";

export default {
    /**
     * Attaches an event listener that will destroy itself after its first run.
     * @param {object} element - The element to attach an event to
     * @param {object} event - The event to listen for
     * @param {function} function - The handler function
     */
    doOnce(element, event, func) {
        function handler(...args) {
            element.removeEventListener(event, handler);
            func.apply(this, args);
        }

        element.addEventListener(event, handler);
    },

    /**
     * Helper for transitioning an element and doing something on the
     * transitionEndEvent. Has a max-timeout for extra safety and handlers
     * lack of support for transitions by resolving instantly.
     *
     * @param {object} element - The element to attach an event to
     * @param {function} transitionTrigger - A function that will trigger a transition event.
     *                                       Called with the element as it's only argument.
     * @param {number} [timeout=350] - A max-timeout after which to call the function anyways in ms
     *                             Defaults to 350ms.
     * @param {string} [property] - The transition property to listen for. It will be
     *                                       normalised to the correct property for the browser.
     * @returns {Promise} A promise that will be resolved with the animated element.
     */
    onTransitionEnd(element, transitionTrigger, timeout, property) {
        return new Promise(function (resolve) {
            var timer;
            var transitionEndEvent = browser.transitionEndEvent;

            property = style.getCSSProperty(property);

            // If a browser doesn't support css transitions, there is
            // no reason to wait for anything.
            if (!browser.supportsCSSTransitions) {
                transitionTrigger(element);
                return resolve(element);
            }

            function handler(event) {
                if (event.target !== element) {
                    console.warn(transitionEndEvent, " for different element", event.target);
                    return;
                }

                if (property === null || event.propertyName === property) {
                    clearTimeout(timer);
                    element.removeEventListener(transitionEndEvent, handler);
                    resolve(element);
                }
            }

            element.addEventListener(transitionEndEvent, handler);

            requestAnimationFrame(function () {
                transitionTrigger(element);
            });

            timer = setTimeout(function () {
                element.removeEventListener(transitionEndEvent, handler);
                resolve(element);
            }, timeout || 350);
        });
    },

    onAnimationEnd(element, transitionTrigger, animationName, timeout) {
        return new Promise((resolve) => {
            var timer;
            var animationEndEvent = browser.animationEndEvent;

            function handler(event) {
                if (event.target !== element) {
                    console.warn(animationEndEvent, " for different element", event.target);
                    return;
                }

                if (event.animationName === animationName) {
                    clearTimeout(timer);
                    element.removeEventListener(animationEndEvent, handler);
                    resolve(element);
                } else {
                }
            }

            requestAnimationFrame(function () {
                transitionTrigger(element);
            });

            timer = setTimeout(() => {
                resolve(element);
            }, timeout || 2000);

            element.addEventListener(animationEndEvent, handler);
        });
    },
};

import _debounce from "lodash/debounce";
import { useCallback, useEffect, useState } from "react";
import { useFeedFetch } from "web/react/hooks/use-feed-fetch/use-feed-fetch";
import { useFeedContext } from "web/react/pages/feed/feed.context";
import analytics from "web/script/analytics/analytics";
import environment from "web/script/modules/environment";
import requester from "web/script/modules/requester";
import userProfiler from "web/script/modules/userprofiler";
import logging from "web/script/utils/logging";
import url from "web/script/utils/url";

/** @typedef {{promise: Promise<boolean>, resolve?: (ret: boolean) => void, reject?: (err: Error) => void, saving: bool}} FetchCacheItem */
type CacheItem = {
    promise: Promise<boolean>;
    resolve?: (value: boolean | PromiseLike<boolean>) => void;
    reject?: (err?: Error) => void;
    saving: boolean;
};

/**
 * @type {Map<string, boolean>}
 */
let statusCache = new Map<string, boolean>();

/**
 * @type {Map<string, CacheItem>}
 */
let fetchCache = new Map<string, CacheItem>();
/**
 * @type {Set<string>}
 */
let fetchQueue = new Set<string>();

/**
 * @param {string} productID
 * @returns {[FetchCacheItem, boolean]}
 */
function createFetchCacheItem(productID): CacheItem {
    // Shouldn't happen, but just in case
    const data = fetchCache.get(productID);
    if (data) {
        return data;
    } else {
        let cachedFetch: CacheItem = {
            promise: Promise.resolve(false),
            resolve: undefined,
            reject: undefined,
            saving: false,
        };
        // This cannot happen in the initialiser for `cachedFetch` because object
        //  properties are evaluated before the object is created which results in
        //  the `new Promise` callback being invoked before the reference it needs to
        //  modify is available.
        // This is pretty confusing behaviour and took a while to debug :(
        cachedFetch.promise = new Promise((resolve, reject) => {
            cachedFetch.resolve = resolve;
            cachedFetch.reject = reject;
        });

        fetchCache.set(productID, cachedFetch);

        return cachedFetch;
    }
}

export {
    createFetchCacheItem as __createFetchCacheItem,
    fetchCache as __fetchCache,
    fetchQueue as __fetchQueue,
    statusCache as __statusCache,
};

/**
 * @param {string[]} productIDs
 * @returns {Promise<void>}
 */
async function fetchStatuses(productIDs: string[]): Promise<void> {
    /* eslint-disable max-depth */
    let data;
    try {
        const query = url.toURLSearchParams({
            // TODO: There's a limit to the size this request can be, we should
            //        split it up into multiple requests if it's too long
            product_id: productIDs,
        });
        data = await requester.get("/services/pins/", query);
    } catch (err: any) {
        for (let productID of productIDs) {
            let cachedFetch = fetchCache.get(productID);
            if (!cachedFetch || cachedFetch.saving) {
                continue;
            }
            if (cachedFetch.reject) {
                cachedFetch.reject(err);
            }
            fetchCache.delete(productID);
        }
        throw err;
    }

    for (let productID of Object.keys(data.pin_mapping)) {
        let cachedFetch = fetchCache.get(productID);
        if (cachedFetch && cachedFetch.saving) {
            continue;
        }
        let isSaved = data.pin_mapping[productID];
        statusCache.set(productID, isSaved);
        if (cachedFetch && cachedFetch.resolve) {
            cachedFetch.resolve(isSaved);
        }
        fetchCache.delete(productID);
    }

    for (let productID of productIDs) {
        let cachedFetch = fetchCache.get(productID);
        if (!cachedFetch || cachedFetch.saving) {
            continue;
        }
        if (cachedFetch.reject) {
            cachedFetch.reject(new Error(`Server response did not include product "${productID}!`));
        }
        fetchCache.delete(productID);
    }
}

let runQueue = _debounce(() => {
    if (!fetchQueue.size) {
        return;
    }
    fetchStatuses(Array.from(fetchQueue)).catch((err) =>
        logging.error(err, "Failed to run the fetch queue")
    );
    fetchQueue.clear();
}, 50);

/**
 * Asks the save for later system to prefetch a number of product IDs and cache
 *  the results.
 * Useful for situations where you know you're going to render a bunch of
 *  buttons and want to start fetching them all immediately.
 * As with all hooks, you should not dynamically generate the productIDs
 *  parameter or it'll run the effect every single render.
 * @param {string[]} productIDs The products in question
 * @returns {void}
 */
export function useSFLPrefetch(productIDs: string[]): void {
    useEffect(() => {
        if (!userProfiler.isLoggedIn()) {
            return;
        }

        for (let productID of productIDs) {
            if (statusCache.has(productID) || fetchCache.has(productID)) {
                continue;
            }

            fetchQueue.add(productID);
            let { promise } = createFetchCacheItem(productID);
            // This function doesn't care if any of these promises fail but the
            //  runtime gets upset if you don't catch rejected promises.
            // In some cases, `useSaveForLater` will _also_ catch this error and
            //  report, which could be annoying - but generally the logging
            //  module should catch this and only report it once.
            promise.catch(logging.error);
        }

        runQueue();
    }, [productIDs]);
}

async function sendToggleRequest(productID): Promise<boolean> {
    let response = await requester("/services/pins/", {
        method: "POST",
        body: { product_id: productID } as any, // Terrible hack to stop requester complaining
    });

    fetchCache.delete(productID);

    if (response.status === 204) {
        statusCache.set(productID, false);
        return false;
    } else if (response.status === 201) {
        statusCache.set(productID, true);
        return true;
    }

    throw new Error(`Could not save/un-save product with id: ${productID}`);
}

/**
 * @param {string} productID
 * @returns {Promise<boolean>}
 */
async function toggleSave(productID): Promise<boolean> {
    let cachedFetch = fetchCache.get(productID);
    if (!cachedFetch) {
        cachedFetch = createFetchCacheItem(productID);
    }
    // If we're already saving this pin, don't do it again
    // Otherwise, we want to take over this fetch mid-flight and change the
    //  result to be the result of the user's action.
    if (cachedFetch.saving) {
        return await cachedFetch.promise;
    }

    cachedFetch.saving = true;
    cachedFetch.promise = sendToggleRequest(productID);

    let cachedFetchResolve = cachedFetch.resolve;
    let cachedFetchReject = cachedFetch.reject;
    delete cachedFetch.resolve;
    delete cachedFetch.reject;

    fetchCache.set(productID, cachedFetch);

    return await cachedFetch.promise
        .then((result) => {
            if (cachedFetchResolve) {
                cachedFetchResolve(result);
            }
            return result;
        })
        .catch((err) => {
            if (cachedFetchReject) {
                cachedFetchReject(err);
            }
            throw err;
        });
}

async function getStatus(productID, initialState = false): Promise<boolean> {
    const data = fetchCache.get(productID);

    if (data) {
        return await data.promise;
    } else {
        let cachedStatus = statusCache.get(productID);
        if (cachedStatus !== undefined) {
            return cachedStatus;
        } else if (initialState !== false) {
            statusCache.set(productID, initialState);
            return initialState;
        }

        let cachedFetch = createFetchCacheItem(productID);
        fetchQueue.add(productID);

        runQueue();

        return await cachedFetch.promise;
    }
}

export enum EventLabel {
    FEED = "product_card",
    PDP = "product_page",
    OOS = "stock_alert",
}

/**
 * A hook for creating a save for later button.
 * @param {string} productID
 * @param {keyof eventLabels} pageSource
 * @param {boolean} initialState
 * @returns {[boolean, boolean, () => Promise<boolean>, boolean]}
 */
export function useSaveForLater(
    productID: string,
    pageSource?: EventLabel,
    initialState = false
): {
    isSaved: boolean;
    loading: boolean;
    toggleSaveForLater: () => void;
    isSaving: boolean;
} {
    //  todo: initial state should be set to wishlisted state
    const [isSaved, setIsSaved] = useState(initialState);
    const [loading, setLoading] = useState(true);
    const [isSaving, setIsSaving] = useState(false);
    const { fetchFeed } = useFeedFetch();
    const { feedFetched } = useFeedContext();
    // Enforce productId is always a string.
    productID = productID ? productID.toString() : productID;

    const feedType = environment.get("feedType");
    useEffect(() => {
        setLoading(true);
        if (!userProfiler.isLoggedIn() || !productID) {
            return;
        }
        getStatus(productID, initialState)
            .then((res) => {
                setIsSaved(res);
                setLoading(false);
            })
            .catch((err) => {
                logging.error(err, "Failed to get initial save state for product %s:", productID);
                setLoading(false);
            });
    }, [productID, initialState, feedFetched]);

    let toggleSaveForLater = useCallback(async () => {
        setLoading(true);
        if (!userProfiler.isLoggedIn() || !productID) {
            throw new Error("Cannot toggle save state!");
        }

        let newState;

        try {
            // Transitioning from unsaved to saved.
            setIsSaving(!isSaved);
            newState = await toggleSave(productID);
            setIsSaved(newState);
            setIsSaving(false);
            // re-fetch product feed when state changes. Only if the user is on a wishlist feed
            if (feedType === "WISH_LIST") {
                fetchFeed();
            }
        } catch (err: any) {
            // Still want to log the intent, but can't work out from response if
            //  it was an add or remove - get this data from the cache instead
            // (this won't be accurate if the page has not been refreshed since
            //  last add/remove, but is the best we can do here).
            newState = !statusCache.get(productID);
            // TODO: Tell the user? Trigger another fetch to see if it actually
            //        went through or not? Does this happen enough to care?
            logging.error(err, "Could not toggle save state for product %s:");
        }
        setLoading(false);

        if (newState) {
            analytics.event("save_for_later", "add_item", pageSource?.toString(), false, {
                currency: environment.get("currencyProps.currencyCode"),
                product_id: productID,
            });
        } else {
            analytics.event("save_for_later", "remove_item", pageSource?.toString(), false, {
                product_id: productID,
            });
        }

        return undefined;
    }, [productID, pageSource, isSaved, fetchFeed, feedType]);

    return { isSaved, loading, toggleSaveForLater, isSaving };
}

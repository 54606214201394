import { ReactComponentManifest } from "web/entrypoints/common/setup";
import HomepageLayout from "web/react/components/__LEGACY__/homepage-layout/homepage-layout";
import AdsenseShopping from "web/react/components/adsense-shopping/adsense-shopping";
import GoogleOneTap from "web/react/components/google-one-tap/google-one-tap";
import { Stories } from "web/react/pages/home/stories";
import withRedux from "web/react/redux-provider";

const components: ReactComponentManifest = {
    AdsenseShopping: withRedux(AdsenseShopping),
    HomepageLayout,
    GoogleOneTap: withRedux(GoogleOneTap),
    Stories: withRedux(Stories),
};

export default components;

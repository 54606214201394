import clsx from "clsx";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigationContext } from "web/react/components/navigation/navigation.context";
import { HStack } from "web/react/emo/h-stack";
import { Text } from "web/react/emo/text";
import { View } from "web/react/emo/view";
import { setSelectedGender } from "web/redux/ducks/main-navigation";
import { ReduxStoreState } from "web/redux/store";
import { gettext, gettextNoop } from "web/script/modules/django-i18n";
import storage from "web/script/utils/storage";
import { AppHeaderBarGenderSerializer } from "web/types/serializers";
import * as styles from "./header-genders.css";

gettextNoop("header.gender.men");
gettextNoop("header.gender.women");

function extractSelectedGender(state: ReduxStoreState): string {
    return state.mainNavigationReducer.selectedGender;
}

interface HeaderGendersProps {
    genders: AppHeaderBarGenderSerializer[];
}

export function HeaderGenders({ genders }: HeaderGendersProps): React.ReactElement {
    const activeSelected = useSelector(extractSelectedGender);
    const dispatch = useDispatch();

    const { handleMenuOpenedAnalytics, isRebrand } = useNavigationContext();

    // Update the redux state with the navbar gender if it's not set
    useEffect(() => {
        const setGender = storage.get("selectedGender", "women", false);
        dispatch(setSelectedGender(setGender));
    }, [activeSelected, genders, dispatch]);

    function onGenderClick(event: React.SyntheticEvent<HTMLAnchorElement>, gender = "women"): void {
        event.preventDefault();
        const switchGender = gender !== activeSelected;
        if (!switchGender) {
            handleMenuOpenedAnalytics("opened", gender);
        } else {
            dispatch(setSelectedGender(gender));
            handleMenuOpenedAnalytics("closed", gender);
        }
    }

    function isGenderActive(gender): boolean {
        return activeSelected ? activeSelected === gender.value : gender.selected;
    }

    function getGenderName(gender): string {
        const text = gettext(`header.gender.${gender.value}`);
        return isRebrand ? text.toUpperCase() : text;
    }

    return (
        <div data-testid="header-gender-selector">
            <View className={styles.container}>
                <HStack spacing="md">
                    {genders.map((gender) => (
                        <a
                            key={gender.value}
                            data-testid={`gender-selector-${gender.value}`}
                            href={`${gender.gender_home_page}#category-navigation`}
                            onClick={(event) => onGenderClick(event, gender.value)}
                        >
                            <Text
                                textStyle={isRebrand ? "callout-v2" : "body-3-small"}
                                className={clsx({
                                    [styles.gender]: !isRebrand && styles.gender,
                                    [styles.genderRebrand]: isRebrand && styles.genderRebrand,
                                    [styles.active]: !isRebrand && isGenderActive(gender),
                                    [styles.activeRebrand]: isRebrand && isGenderActive(gender),
                                })}
                            >
                                {getGenderName(gender)}
                            </Text>
                        </a>
                    ))}
                </HStack>
            </View>
        </div>
    );
}

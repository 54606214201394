import React, { useState } from "react";
import { useDispatch } from "react-redux";
import Button from "web/react/components/__LEGACY__/LEGACY_button/button";
import MultiplyOnHoverButton from "web/react/components/__LEGACY__/buttons/multiply-on-hover-button/multiply-on-hover-button";
import {
    follow,
    getCaptureType,
    unFollow,
} from "web/react/components/follow-designer-button/utils";
import { openCustomerCaptureOverlay } from "web/redux/ducks/customer-capture-overlay";
import analytics from "web/script/analytics/analytics";

export interface FollowDesignerButtonProps {
    lystId: number;
    designerName?: string;
    followedText: string;
    notFollowedText: string;
    isAuthenticated: string;
    isFollowing: string;
    analyticsParameters: [string, string, string];
    className: string;
    downloadApp?: boolean;
}

export function FollowDesignerButton({
    lystId,
    designerName,
    followedText,
    notFollowedText,
    isAuthenticated,
    isFollowing,
    analyticsParameters,
    className,
}: FollowDesignerButtonProps): JSX.Element {
    const [following, setFollowing] = useState<boolean>(isFollowing !== "false");
    const dispatch = useDispatch();

    const buttonLabel = following ? followedText : notFollowedText;

    const [category, action, label] = analyticsParameters;

    function onClick(): void {
        analytics.event(category, action, label);
        if (isAuthenticated === "false") {
            dispatch(
                openCustomerCaptureOverlay({
                    designerId: lystId.toString(),
                    captureType: getCaptureType(analyticsParameters),
                    designerName,
                    analyticsEventLabel: "follow_designer_button",
                })
            );
        } else if (following) {
            unFollow(lystId, setFollowing);
        } else {
            follow(lystId, setFollowing);
        }
    }

    if (following) {
        return (
            <Button styleType={"primary"} onClick={onClick} className={className}>
                {buttonLabel}
            </Button>
        );
    }
    return (
        <MultiplyOnHoverButton
            className={className}
            onClick={onClick}
            disabled={false}
            fullWidth={false}
            fullHeight={false}
            openInNewTab={false}
        >
            {buttonLabel}
        </MultiplyOnHoverButton>
    );
}

export default FollowDesignerButton;

import clsx from "clsx";
import React from "react";
import Accordion from "web/react/components/__LEGACY__/accordion/accordion";
import MultiplyOnHoverButton from "web/react/components/__LEGACY__/buttons/multiply-on-hover-button/multiply-on-hover-button";
import BuyOnStoreButton from "web/react/components/__LEGACY__/buy-on-store-button/buy-on-store-button";
import { normalizeShippingCost } from "web/react/components/buybuybuy-area/buy-options/buy-options";
import { HintBubble } from "web/react/components/hint-bubble/hint-bubble";
import SVGIcon from "web/react/components/svg-icon/svg-icon";
import {
    PurchaseIntentCheckoutType,
    PurchaseIntentPurchaseType,
} from "web/react/pages/checkout/checkout.context";
import { openShippingAndReturnsOverlay } from "web/redux/ducks/shipping-and-returns-overlay";
import store from "web/redux/store";
import analytics from "web/script/analytics/analytics";
import environment from "web/script/modules/environment";
import uuid4 from "web/script/utils/uuid";
import { BuyBuyBuyOptionSerializer } from "web/types/serializers";
// Conditionally applying a class triggers a fake 'unused' linter error
// eslint-disable-next-line css-modules/no-unused-class
import styles from "./buy-option.module.css";

export interface BuyOptionProps {
    buyBuyBuyOption: BuyBuyBuyOptionSerializer;
    onNativeCheckoutClick: (
        options: Partial<BuyBuyBuyOptionSerializer>,
        purchaseIntentSessionId: string
    ) => Promise<Response>;
    buyOptionPosition: number;
    hasOtherBuyOptions: boolean;
    initialExpanded?: boolean;
    isLastBuyOption: boolean;
    onOldCheckoutClick: (
        options: Partial<BuyBuyBuyOptionSerializer>,
        purchaseIntentSessionId: string
    ) => Promise<Response>;
}

function BuyOption({
    buyBuyBuyOption,
    onNativeCheckoutClick,
    buyOptionPosition,
    hasOtherBuyOptions,
    initialExpanded,
    isLastBuyOption,
    onOldCheckoutClick,
}: BuyOptionProps): React.ReactElement {
    const {
        free_shipping,
        has_free_shipping,
        human_size,
        is_checkout,
        is_final_sale,
        is_native_checkout: isNativeCheckout,
        is_on_sale,
        lead_url,
        link_id,
        price,
        price_with_currency_symbol,
        product_category,
        product_id,
        product_slug,
        promotions,
        raw_size,
        retailer_name,
        retailer_slug,
        retailer_integration_type,
        retailer_tc_link,
        sale_discount,
        sale_price,
        sale_price_with_currency_symbol,
        shipping_cost_label,
        terms_and_conditions_link,
    } = buyBuyBuyOption;

    const [buyDisabled, setBuyDisabled] = React.useState<boolean>(false);

    function onClickShippingInfo(): void {
        store.dispatch(openShippingAndReturnsOverlay(link_id));
    }

    function onAccordionOpened({ accordionName, nonInteraction }): void {
        analytics.event("buy_area", "open_option", accordionName, nonInteraction);
    }

    function onAccordionClosed({ accordionName, nonInteraction }): void {
        analytics.event("buy_area", "close_option", accordionName, nonInteraction);
    }

    function sendCtaAnalytic({ cta }): void {
        const category = "buy_area";
        const action = "buy_options";
        const subType = `checkout.${category}.${action}`;

        analytics.event(category, action, "", false, null, subType, {
            buy_area: {
                buy_cta: {
                    cta,
                    option: {
                        retailer: retailer_slug,
                        price: sale_price,
                        shipping: normalizeShippingCost(has_free_shipping, shipping_cost_label),
                        is_checkout,
                        has_promotion: promotions.length > 0 || sale_discount > 0,
                        link_id: link_id,
                        position: buyOptionPosition, // position at which the buy option is displayed to the user
                    },
                },
            },
        });
    }

    function sendCheckoutAnalytic(
        purchaseIntentSessionId: string,
        checkout_type: PurchaseIntentCheckoutType
    ): void {
        const category = "purchase_pdp";
        const action = "clicked";
        const subType = `${category}.${action}`;

        analytics.event(category, action, "", false, null, subType, {}, [
            {
                item_type: "buy_option",
                id: link_id,
                sizes: [
                    {
                        size: raw_size,
                        on_sale: is_on_sale,
                        price: price,
                        current_price: sale_price,
                        currency: environment.get("currencyProps.currencyCode"),
                    },
                ],
                retailer: {
                    slug: retailer_slug,
                    retailer_integration_type: retailer_integration_type,
                },
                product: {
                    id: product_id,
                    slug: product_slug,
                    category: product_category,
                },
            },
            {
                item_type: "purchase_intent_session",
                purchase_intent_session_id: purchaseIntentSessionId,
                purchase_type: PurchaseIntentPurchaseType.CHECKOUT,
                checkout_type: checkout_type,
            },
        ]);
    }

    async function initiateCheckout(): Promise<void> {
        setBuyDisabled(true);

        const options: Partial<BuyBuyBuyOptionSerializer> = {
            link_id,
            raw_size,
            price,
            sale_discount,
            retailer_slug,
            is_final_sale,
            has_free_shipping,
        };

        // Generate a checkout purchase intent session uuid
        // This is used to track checkout analytics
        const purchaseIntentSessionId = uuid4.uuid4();

        sendCtaAnalytic({ cta: "buy now" });

        if (isNativeCheckout) {
            sendCheckoutAnalytic(purchaseIntentSessionId, PurchaseIntentCheckoutType.NATIVE);
            await onNativeCheckoutClick(options, purchaseIntentSessionId);
        } else {
            sendCheckoutAnalytic(purchaseIntentSessionId, PurchaseIntentCheckoutType.OLD_CHECKOUT);
            await onOldCheckoutClick(options, purchaseIntentSessionId);
        }

        setBuyDisabled(false);
    }

    function showFreeShippingCondition(): boolean {
        if (isNativeCheckout) return false;
        const conditionMinSpendAmount = free_shipping?.condition?.min_spend.amount;
        return conditionMinSpendAmount ? +conditionMinSpendAmount > +price : false;
    }

    return (
        <Accordion
            initialExpanded={initialExpanded}
            name={retailer_slug}
            onAccordionOpened={onAccordionOpened}
            onAccordionClosed={onAccordionClosed}
            className={!hasOtherBuyOptions && isLastBuyOption ? styles.removeLastBorder : ""}
        >
            <Accordion.Title activeClassName={styles.titleActive}>
                <div>
                    <div>
                        <span
                            className={clsx(styles.price, {
                                [styles.salePrice]: is_on_sale,
                            })}
                        >
                            {sale_price_with_currency_symbol}
                        </span>
                        {is_on_sale && (
                            <del className={styles.priceDeleted}>{price_with_currency_symbol}</del>
                        )}
                    </div>
                    <div className={styles.shipping}>
                        <span className={styles.shippingCost}>
                            {has_free_shipping ? "Free shipping" : shipping_cost_label}
                        </span>
                        {" from "}
                        <span className={styles.title}>{retailer_name}</span>
                    </div>
                </div>
            </Accordion.Title>
            <Accordion.Content>
                <div className={!isLastBuyOption || hasOtherBuyOptions ? styles.content : ""}>
                    <div className={styles.size} data-testid="buy-option-size">
                        <span>
                            {human_size
                                ? human_size === raw_size
                                    ? human_size
                                    : `${human_size} / ${raw_size}`
                                : raw_size}
                        </span>
                    </div>
                    {(free_shipping || promotions) && (
                        <div className={styles.promos}>
                            {promotions.map((promotion, index) => (
                                <div key={index} className={styles.promo}>
                                    <SVGIcon name="tag" className={styles.promoIcon} />
                                    {promotion.url ? (
                                        <a
                                            href={promotion.url}
                                            className={styles.promoLink}
                                            dangerouslySetInnerHTML={{
                                                __html: promotion.message,
                                            }}
                                        />
                                    ) : (
                                        <p
                                            className={styles.promoText}
                                            dangerouslySetInnerHTML={{
                                                __html: promotion.message,
                                            }}
                                        />
                                    )}
                                    {terms_and_conditions_link && retailer_tc_link && (
                                        <HintBubble
                                            tooltipText={
                                                <div className={styles.tooltipContent}>
                                                    <a
                                                        href={terms_and_conditions_link}
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                    >
                                                        {"Lyst promotion terms apply"}
                                                    </a>
                                                    <a
                                                        className={styles.tooltipRetailerLink}
                                                        href={retailer_tc_link}
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                    >{`${retailer_name} promotion terms apply`}</a>
                                                </div>
                                            }
                                        />
                                    )}
                                </div>
                            ))}
                            {free_shipping && showFreeShippingCondition() && (
                                <div
                                    className={styles.promo}
                                    data-testid="buy-option-free-shipping-condition"
                                >
                                    <SVGIcon name="truck" className={styles.truckPromoIcon} />
                                    {free_shipping.url ? (
                                        <a
                                            href={free_shipping.url}
                                            className={styles.promoLink}
                                            dangerouslySetInnerHTML={{
                                                __html: free_shipping.message,
                                            }}
                                        />
                                    ) : (
                                        <p
                                            className={styles.promoText}
                                            dangerouslySetInnerHTML={{
                                                __html: free_shipping.message,
                                            }}
                                        />
                                    )}
                                    {free_shipping.has_promo_code && retailer_tc_link && (
                                        <HintBubble
                                            tooltipText={
                                                <div className={styles.tooltipContent}>
                                                    <a
                                                        href={terms_and_conditions_link}
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                    >
                                                        {"Lyst promotion terms apply"}
                                                    </a>
                                                    {!free_shipping.is_lyst_shipping && (
                                                        <a
                                                            className={styles.tooltipRetailerLink}
                                                            href={retailer_tc_link}
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                        >{`${retailer_name} promotion terms apply`}</a>
                                                    )}
                                                </div>
                                            }
                                        />
                                    )}
                                </div>
                            )}
                        </div>
                    )}
                    {is_checkout ? (
                        <>
                            <MultiplyOnHoverButton
                                className={clsx(
                                    styles.addToBagButton,
                                    "buybuybuy-option__buy-button" // TODO: Refactor classname when migrating sticky buy button
                                )}
                                onClick={initiateCheckout}
                                disabled={buyDisabled}
                                openInNewTab={false}
                                fullHeight
                                fullWidth
                            >
                                <span>{"Buy now"}</span>
                            </MultiplyOnHoverButton>
                            <div
                                className={styles.shippingAndReturnsLink}
                                onClick={onClickShippingInfo}
                            >
                                {"View shipping & returns info"}
                            </div>
                        </>
                    ) : (
                        <BuyOnStoreButton
                            productId={link_id}
                            leadUrl={lead_url}
                            leadLabel={"Shop now"}
                            analyticsEventLabel={"buy-option"}
                            contextType={"shop_now"}
                            isAffiliate={true}
                            retailerSlug={retailer_slug}
                        />
                    )}
                </div>
            </Accordion.Content>
        </Accordion>
    );
}

export default BuyOption;

/* eslint-disable max-depth */
import _each from "lodash/each";
import tiktokEvents from "web/script/analytics/tiktok-events";
import ttq from "web/script/embedded/tiktok";
import environment from "web/script/modules/environment";

export default {
    pixelId: "CKJAGGBC77U1O76TIQ20",

    init: function () {
        ttq("load", { id: this.pixelId });
    },

    send: function (data) {
        _each(data, (event) => {
            let events = [];
            switch (event.type) {
                case "page_view":
                    // Send Page View pixel events for Feed pages
                    if (event.data.page_type === "feed") {
                        events.push({
                            eventName: tiktokEvents.page_view.name,
                        });
                    }

                    // Send View Content pixel events for Product (PDP) pages
                    if (event.data.product_id) {
                        events.push({
                            eventName: tiktokEvents.view_content.name,
                            eventData: {
                                content_id: event.data.product_id,
                                content_type: "product",
                            },
                        });
                    }
                    break;

                case "event":
                    events = this.getEventProps(event.data);
                    break;

                default:
                    break;
            }

            events.forEach((event) => {
                ttq("track", { name: event.eventName, data: event.eventData || {} });
            });

            return;
        });
        return Promise.resolve();
    },

    getEventProps(data) {
        let events = [];
        const category = data.category;
        const action = data.action;
        const label = data.label;
        const tiktokCategory = tiktokEvents[category];
        switch (category) {
            case "cart":
                // Send Add to Cart pixel events for Checkout
                if (action === tiktokCategory.action && label === tiktokCategory.label) {
                    events.push({
                        eventName: tiktokCategory.name,
                        eventData: {
                            contents: [
                                {
                                    content_id: data.customData.product_id,
                                    price: data.customData.price,
                                },
                            ],
                            content_type: "product",
                            currency: environment.get("currencyProps.currencyCode") || "USD",
                            value: data.customData.price,
                        },
                    });
                }
                break;

            case "affiliate":
                // Send Add to Cart pixel events for Affiliate
                if (action === tiktokCategory.action) {
                    events.push({
                        eventName: tiktokCategory.name,
                        eventData: {
                            contents: [
                                {
                                    content_id: label,
                                    price: data.customData.sale_price,
                                },
                            ],
                            content_type: "product",
                            currency: environment.get("currencyProps.currencyCode") || "USD",
                            value: data.customData.sale_price,
                        },
                    });
                }
                break;

            case "signup":
                // Send email signups pixel events
                if (action === tiktokCategory.action && label === tiktokCategory.label) {
                    events.push({
                        eventName: tiktokCategory.name,
                        eventData: { description: "email" },
                    });
                }
                break;

            case "signup_header":
                // Send social signups pixel events
                if (action === tiktokCategory.action) {
                    events.push({
                        eventName: tiktokCategory.name,
                        eventData: { description: label.split("_")[1] },
                    });
                }
                break;

            default:
                break;
        }

        return events;
    },
};

import environment from "web/script/modules/environment";
import requester from "web/script/modules/requester";

const EXCLUDED_BRANDS = {
    "alexander-mcqueen": "Alexander McQueen",
    balenciaga: "Balenciaga",
    "bottega-veneta": "Bottega Veneta",
    celine: "Celine",
    fendi: "Fendi",
    gucci: "Gucci",
    "louis-vuitton": "Louis Vuitton",
    moncler: "Moncler",
    moynat: "Moynat",
    "patou-designer": "Patou",
    rimowa: "Rimowa",
    "saint-laurent": "Saint Laurent",
    valentino: "Valentino",
};

export function excludedBrandNames(): string[] {
    const brandNames = Object.keys(EXCLUDED_BRANDS).map(function (key) {
        return EXCLUDED_BRANDS[key];
    });
    return brandNames;
}

export function isExcludedDesignerOnPage(): boolean {
    const designerSlug = environment.get("analyticsProduct.designer_slug");
    return designerSlug in EXCLUDED_BRANDS;
}

export async function getRewardDetails(webToken: string | undefined): Promise<any> {
    // TODO: Make error state more user friendly
    const defaultReward = { reward: "%", date_range: "-" };
    if (webToken == null) {
        return defaultReward;
    }
    let jsonData = { ...defaultReward };
    try {
        jsonData = await requester.get("/financial-incentive/", { token: webToken });
    } catch (e) {
        console.error(e);
        // Couldn't get reward data - return default
        return defaultReward;
    }
    return jsonData;
}

export default getRewardDetails;

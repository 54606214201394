import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FeedHeaderWithLogo } from "web/react/components/feed-header-with-logo/feed-header-with-logo";
import { useInitialReduxState } from "web/react/hooks/use-inital-redux-state/use-initial-redux-state";
import { FeedPageType } from "web/react/pages/feed/utils";
import { Image } from "web/react/pages/product/in-stock/product-image-gallery/product-image-gallery";
import { setDesignerProfile, triggerBanner } from "web/redux/ducks/follow-designer-banner";
import { ReduxStoreState } from "web/redux/store";
import {
    BreadcrumbsSerializer,
    FeedHeaderSerializer,
    ProfileHeaderSerializer,
} from "web/types/serializers";

export function isProfileHeader(
    profile?: ProfileHeaderSerializer | FeedHeaderSerializer
): profile is ProfileHeaderSerializer {
    return !!(
        (profile as ProfileHeaderSerializer)?.follow_button ||
        (profile as ProfileHeaderSerializer)?.retailer_hub_link
    );
}

export function isShopHeader(
    profile?: ProfileHeaderSerializer | FeedHeaderSerializer
): profile is ProfileHeaderSerializer {
    return !!(profile as FeedHeaderSerializer)?.title;
}

export interface FeedHeaderProps {
    title: string | JSX.Element;
    subtitle?: string | JSX.Element;
    shortDescription?: string | null;
    description?: string | null;
    actions?: JSX.Element;
    profile?: FeedHeaderSerializer;
    shopHeader?: boolean;
    productCount?: number;
    retailerCount?: number;
    type?: FeedPageType;
    headerImage?: Image;
    breadcrumbs?: BreadcrumbsSerializer | null;
}

function getInView(bounding: DOMRect): boolean {
    return (
        bounding.top >= -bounding.height &&
        bounding.left >= -bounding.width &&
        bounding.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
        bounding.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
}

function getTitle(title?, profile?: FeedHeaderProps["profile"]): string | null {
    if (title) {
        return title;
    }
    if (isProfileHeader(profile) && profile?.name) {
        return profile.name;
    }
    if (isShopHeader(profile) && profile?.title) {
        return profile.title;
    }
    return null;
}

export function FeedHeader({
    profile,
    subtitle,
    actions,
    productCount,
    retailerCount,
    shortDescription,
    description,
    type,
    headerImage,
    breadcrumbs,
    ...props
}: FeedHeaderProps): JSX.Element | null {
    const followCTARef = useRef<HTMLDivElement>(null);
    const dispatch = useDispatch();
    const filterStateTitle = useSelector(
        (state: ReduxStoreState) => state.feedFiltersReducer.title
    );

    useInitialReduxState([[setDesignerProfile, profile]]);

    useEffect(() => {
        function onScroll(): void {
            if (followCTARef.current) {
                let bounding = followCTARef.current.getBoundingClientRect();
                dispatch(triggerBanner(!getInView(bounding)));
            }
        }

        window.addEventListener("scroll", onScroll);
        return (): void => {
            window.removeEventListener("scroll", onScroll);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (followCTARef.current) {
            let bounding = followCTARef.current.getBoundingClientRect();
            dispatch(triggerBanner(!getInView(bounding)));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [followCTARef.current]);

    const title = getTitle(filterStateTitle || props.title, profile);

    if (!title) {
        return null;
    }

    return (
        <FeedHeaderWithLogo
            title={title}
            subtitle={subtitle}
            shortDescription={shortDescription}
            description={description}
            profile={profile}
            followCTARef={followCTARef}
            actions={actions}
            productCount={productCount || 0}
            retailerCount={retailerCount || 0}
            headerImage={headerImage}
            type={type}
            breadcrumbs={breadcrumbs}
        />
    );
}

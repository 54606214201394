import baustein from "baustein";
import template from "templates/modules/overlays/shipping_and_returns_overlay.jinja";
import { closeShippingAndReturnsOverlay } from "web/redux/ducks/shipping-and-returns-overlay";
import store from "web/redux/store";
import overlayMixin from "web/script/components/mixins/overlay-mixin";
import rothko from "web/script/modules/rothko";
import logging from "web/script/utils/logging";

export default baustein.register("shipping-and-returns-overlay", {
    mixins: [overlayMixin],

    defaultOptions: {
        // overlay-mixin options
        overlaySize: "medium",
        noContentPadding: false,

        // shipping & returns overlay options
        linkId: null,
    },

    onOverlayCloseIntent() {
        this.close();
        store.dispatch(closeShippingAndReturnsOverlay());
    },

    getOverlayContent() {
        const linkId = this.options.linkId;
        const options = {
            link_id: linkId,
        };

        return rothko
            .fetch("modules/overlays/shipping_and_returns_overlay", options)
            .then((overlayContext) => {
                return template({
                    shipping_and_returns_overlay: overlayContext,
                });
            })
            .catch((err) => {
                logging.error(err);
            });
    },
});

import { CaptureType } from "web/redux/ducks/customer-capture-overlay";
import analytics from "web/script/analytics/analytics";
import environment from "web/script/modules/environment";
import requester from "web/script/modules/requester";
import { FollowDesignerButtonProps } from "./LEGACY/follow-designer-button";

export function follow(
    lystId: number,
    setFollowing: React.Dispatch<React.SetStateAction<boolean>>
): Promise<void> {
    return requester
        .post("/api/users/add/", `lyst_id=${lystId}`)
        .then(() => {
            setFollowing(true);
            analytics.event("Follow Button", "Follow - Success", undefined, true);
        })
        .catch(() => {
            analytics.event("Follow Button", "Follow - Error", undefined, true);
        });
}

export function unFollow(
    lystId: number,
    setFollowing: React.Dispatch<React.SetStateAction<boolean>>
): Promise<void> {
    return requester
        .post("/api/users/remove/", `lyst_id=${lystId}`)
        .then(() => {
            setFollowing(false);
            analytics.event("Follow Button", "Unfollow - Success", undefined, true);
        })
        .catch(() => {
            analytics.event("Follow Button", "Unfollow - Error", undefined, true);
        });
}

export function getCaptureType(
    analyticsParameters: FollowDesignerButtonProps["analyticsParameters"]
): CaptureType {
    const pageSubType = environment.get("pageSubType");
    if (["designer", "designer_category", "designer_sub_category"].includes(pageSubType)) {
        return analyticsParameters[0] === "du_feed_banner" ? "signup_du_banner" : "signup_du";
    }
    return "signup_su";
}

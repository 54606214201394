import clsx from "clsx";
import React, { ReactNode, useEffect, useRef, useState } from "react";
import { useDragScroll } from "web/react/hooks/use-drag-scroll/use-drag-scroll";
import styles from "./drag-rail.module.css";

interface DragRailProps {
    className?: string;
    id?: string;
    onDragStart?: () => void;
    children: ReactNode;
    dragSpeed?: number;
}

export const PREVENT_CLICK_TOLERANCE = 10;

function DragRail({
    id,
    onDragStart,
    className,
    dragSpeed = 2,
    children,
}: DragRailProps): JSX.Element {
    const [isMouseDown, setIsMouseDown] = useState(false);
    const scrollingDiv = useRef<HTMLDivElement>(null);
    const [startDrag, endDrag] = useDragScroll(scrollingDiv, dragSpeed);

    function handleMouseDown(e): void {
        e.preventDefault();
        setIsMouseDown(true);
    }

    function handleMouseUp(): void {
        setIsMouseDown(false);
    }

    useEffect(() => {
        function handleClick(e): void {
            // If the user drags more than this amount of pixels then cancel click events
            if (Math.abs(startDrag - endDrag) > PREVENT_CLICK_TOLERANCE) {
                e.preventDefault();
                e.stopPropagation();
                onDragStart?.();
            }
        }

        if (!scrollingDiv.current) {
            return;
        }

        const domNode = scrollingDiv.current;

        if (domNode) {
            // N.b Mouse events for baustein components are not captured by react
            // Native handler is required here capture mouse events on e.g. ProductCard components
            domNode.addEventListener("click", handleClick, true);
        }

        return (): void => {
            if (domNode) {
                domNode.removeEventListener("click", handleClick, true);
            }
        };
    }, [startDrag, endDrag, onDragStart]);

    return (
        <div className={clsx(styles.hideScrollBar, className)}>
            <div
                id={id}
                className={styles.dragRailOuterContainer}
                ref={scrollingDiv}
                onMouseDown={handleMouseDown}
                onMouseUp={handleMouseUp}
                onMouseLeave={handleMouseUp}
            >
                <div
                    className={clsx(styles.dragRailInnerContainer, {
                        [styles.grabbing]: isMouseDown,
                    })}
                >
                    {children}
                </div>
            </div>
        </div>
    );
}

export default DragRail;

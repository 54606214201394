import { assignInlineVars } from "@vanilla-extract/dynamic";
import clsx from "clsx";
import React, { useEffect, useRef, useState } from "react";
import SVGIcon from "web/react/components/svg-icon/svg-icon";
import { HStack } from "web/react/emo/h-stack";
import { Stack, alignToFlex } from "web/react/emo/shared/components/stack";
import { Sprinkles } from "web/react/emo/sprinkles.css";
import { Text } from "web/react/emo/text";
import { spacing } from "web/react/emo/theme.css";
import { VStack } from "web/react/emo/v-stack";
import analytics from "web/script/analytics/analytics";
import { gettext } from "web/script/modules/django-i18n";
import strings from "web/script/utils/strings";
import * as styles from "./expandable-text.css";

const ANALYTICS_CATEGORIES = ["product_area"];

function ExpandArrow({ isExpanded }): JSX.Element {
    return (
        <HStack spacing="xxxs" align={"center"}>
            <SVGIcon
                name="chevron-emo"
                className={clsx({
                    [styles.expandableIconOpenIcon]: isExpanded,
                })}
            />
        </HStack>
    );
}

function DefaultExpandButton({ isExpanded, controlButtonText }): JSX.Element {
    return (
        <HStack align={"center"}>
            <Text as={"p"} textStyle={"body-3-small"} className={styles.controlButtonText}>
                {controlButtonText}
            </Text>
            <SVGIcon
                name="chevron-emo"
                className={clsx(styles.smallIcon, {
                    [styles.expandableIconOpenIcon]: isExpanded,
                })}
            />
        </HStack>
    );
}

function DividerExpandButton({ isExpanded }): JSX.Element {
    return (
        <div className={styles.expandableDividerContainer}>
            <span className={styles.expandableDividerLine}></span>
            <ExpandArrow isExpanded={isExpanded} />
        </div>
    );
}

function BoxExpandButton({ isExpanded }): JSX.Element {
    return (
        <div className={styles.buttonOpaque}>
            <ExpandArrow isExpanded={isExpanded} />
        </div>
    );
}

export interface ExpandableTextProps {
    children: string;
    analyticsCategory?: (typeof ANALYTICS_CATEGORIES)[number];
    variant?: "default" | "divider" | "box";
    lineLimit?: number;
    productId?: string;
    title?: string;
    dangerouslySetInnerHtml?: boolean;
}

export function ExpandableText({
    children,
    analyticsCategory,
    variant = "default",
    lineLimit = 2,
    productId,
    dangerouslySetInnerHtml = false,
    title,
}: ExpandableTextProps): JSX.Element {
    const [expandableTextHeight, setExpandableTextHeight] = useState<number>(0);
    const [isExpanded, setIsExpanded] = useState<boolean>(false);
    const [isClamped, setIsClamped] = useState<boolean>(strings.countWords(children) > 60); // arbitrary number but it's good enough for now
    const textElementRef = useRef<HTMLParagraphElement>(null);
    const textContainerRef = useRef<HTMLDivElement>(null);

    let controlButtonText = gettext("general.more");
    const stackProps = {
        direction: "column" as Sprinkles["flexDirection"],
        align: "start" as keyof typeof alignToFlex,
        spacing: "xxxs" as keyof typeof spacing,
    };

    if (isExpanded) {
        lineLimit = 999;
        controlButtonText = gettext("general.less");
    }

    useEffect(() => {
        if (textElementRef.current === null) {
            return;
        }

        setIsClamped(textElementRef.current.scrollHeight > textElementRef.current.clientHeight);
    }, [children]);

    useEffect(() => {
        setExpandableTextHeight(textContainerRef?.current?.scrollHeight || 0);
    }, [lineLimit, isClamped]);

    const buttonTestId = `expand-button-${variant}-${isExpanded ? "open" : "closed"}`;

    return (
        <div
            onClick={(e) => {
                const target = e.target as HTMLElement;
                if (target.tagName.toLowerCase() === "a") {
                    return;
                }

                if (!isExpanded && analyticsCategory) {
                    analytics.event(
                        analyticsCategory,
                        "click",
                        "open-details",
                        false,
                        {
                            product_id: productId,
                        },
                        "",
                        {},
                        [],
                        false,
                        false
                    );
                }
                setIsExpanded(isClamped && !isExpanded);
            }}
            data-testid="expandable-text"
        >
            <div
                className={clsx(styles.wrapper, { [styles.boxContainer]: variant === "box" })}
                style={assignInlineVars({
                    [styles.textHeight]: expandableTextHeight
                        ? `${expandableTextHeight}px`
                        : "auto",
                })}
            >
                <div
                    ref={textContainerRef}
                    className={clsx(styles.expandableTextContainer, {
                        [styles.boxTextContainer]: variant === "box",
                    })}
                >
                    <Stack {...stackProps}>
                        <VStack spacing="xxxs">
                            {title && (
                                <Text textStyle="callout" data-testid="title">
                                    {title}
                                </Text>
                            )}

                            <Text
                                ref={textElementRef}
                                as={"div"}
                                textStyle={"body-3-small"}
                                lineLimit={lineLimit}
                            >
                                {dangerouslySetInnerHtml ? (
                                    <div
                                        ref={textElementRef}
                                        dangerouslySetInnerHTML={{ __html: children }}
                                    />
                                ) : (
                                    children
                                )}
                            </Text>
                        </VStack>
                        {isClamped && variant !== "box" && (
                            <button className={styles.expandableButton} data-testid={buttonTestId}>
                                {variant === "default" ? (
                                    <DefaultExpandButton
                                        isExpanded={isExpanded}
                                        controlButtonText={controlButtonText}
                                    />
                                ) : (
                                    <DividerExpandButton isExpanded={isExpanded} />
                                )}
                            </button>
                        )}
                    </Stack>
                </div>
            </div>
            {isClamped && variant === "box" && (
                <button
                    className={clsx(styles.expandableButton, styles.expandableBoxButton)}
                    data-testid={buttonTestId}
                >
                    <BoxExpandButton isExpanded={isExpanded} />
                </button>
            )}
        </div>
    );
}

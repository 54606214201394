import { gettext } from "web/script/modules/django-i18n";

export function getTranslatedButtonText(buttonText: string): string {
    let translatedButtonText = "";
    switch (buttonText) {
        case "exploreNow":
            translatedButtonText = gettext("general.explore_now");
            break;
        case "readMore":
            translatedButtonText = gettext("general.read_more");
            break;
        case "readShop":
            translatedButtonText = gettext("general.read_and_shop");
            break;
        case "shopNow":
            translatedButtonText = gettext("general.shop_now");
            break;
        default:
            translatedButtonText = gettext("general.read_more");
    }
    return translatedButtonText;
}

import { ProductFeedSerializer } from "web/types/serializers";
/**
 * Extracting the initial X product IDs of feed page array of products,
 * with X being defined by the `numberOfProducts` parameter, which
 * defaults to 3.
 *
 * Returns a new array of only IDs.
 *
 * WARNING: increasing the amount of product IDs being sent in
 * analytics events can drastically slow down / prevent other
 * frontend events from being sent - see https://lyst.atlassian.net/wiki/spaces/EN/pages/136675719/2024-05-31+-+Front-end+events+appear+to+be+missing
 *
 * e.g. numberOfProducts = 5 & items = [
 *          {
 *              product_card :	id=899002191
 *              ...
 *              promo_label :	null
 *          },
 *          ...
 *      ]
 * becomes:
 *      ids = [ 899002191, 996042123, 123098765, 982736490, 092384756 ]
 *
 * @param {ProductFeedSerializer} items
 * @param {number} numberOfProducts
 *
 * @returns {Array}
 */
export function getInitialFeedProductIds(
    items: ProductFeedSerializer["feed_items"],
    numberOfProducts = 3
): number[] {
    // Only keep X amount of products, and extract the ID(s) from the product card
    return items.slice(0, numberOfProducts).map(({ product_card }) => product_card?.id);
}

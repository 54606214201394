import loader from "./loader";

const t = "ttq";
var pixelId;
window.TiktokAnalyticsObject = t;
var ttq = (window[t] = window[t] || []);

ttq.methods = [
    "page",
    "track",
    "identify",
    "instances",
    "debug",
    "on",
    "off",
    "once",
    "ready",
    "alias",
    "group",
    "enableCookie",
    "disableCookie",
];

ttq.setAndDefer = function (t, e) {
    t[e] = function () {
        t.push([e].concat(Array.prototype.slice.call(arguments, 0)));
    };
};

for (var i = 0; i < ttq.methods.length; i++) {
    ttq.setAndDefer(ttq, ttq.methods[i]);
}

ttq.instance = function (t) {
    for (var e = ttq._i[t] || [], n = 0; n < ttq.methods.length; n++) {
        ttq.setAndDefer(e, ttq.methods[n]);
    }
    return e;
};

const load = loader(() => {
    // TikTok code
    // load Tiktok pixel script
    !(function (w, d, t) {
        ttq.load = function (e, n) {
            var i = "https://analytics.tiktok.com/i18n/pixel/events.js";
            ttq._i = ttq._i || {};
            ttq._i[e] = [];
            ttq._i[e]._u = i;
            ttq._t = ttq._t || {};
            ttq._t[e] = +new Date();
            ttq._o = ttq._o || {};
            ttq._o[e] = n || {};
            var o = document.createElement("script");
            o.type = "text/javascript";
            o.async = !0;
            o.src = i + "?sdkid=" + e + "&lib=" + t;
            var a = document.getElementsByTagName("script")[0];
            a.parentNode.insertBefore(o, a);
        };
    })(window, document, "ttq");
    // Initialise Tiktok pixel
    ttq.load(pixelId);
});

export default function (method, eventData) {
    load();
    if (method === "load") {
        pixelId = eventData.id;
    } else {
        ttq[method](eventData.name, eventData.data);
    }
}

export const EU_COUNTRIES = new Set([
    "AT",
    "BE",
    "BG",
    "HR",
    "CY",
    "CZ",
    "DK",
    "EE",
    "FI",
    "FR",
    "DE",
    "GR",
    "HU",
    "IE",
    "IT",
    "LV",
    "LT",
    "LU",
    "MT",
    "NL",
    "PL",
    "PT",
    "RO",
    "SK",
    "SI",
    "ES",
    "SE",
    "GB",
]);

export const COOKIE_COUNTRIES = new Set([...EU_COUNTRIES, "US"]);

// See https://jira.lystit.com/browse/LAW-2173
export const EXPLICIT_EMAIL_CONSENT_COUNTRIES = new Set([
    ...EU_COUNTRIES,
    "AU",
    "BR",
    "CA",
    "HK",
    "IL",
    "IN",
    "KR",
    "MX",
    "SA",
    "ZA",
]);

export enum BREAKPOINTS {
    ExtraExtraSmall = 320,
    ExtraSmall = 375,
    Small = 576,
    Medium = 768,
    Large = 992,
    ExtraLarge = 1200,
}

// Rescued from browser.js
export enum VIEWPORTS {
    Mobile = 1,
    Tablet = 2,
    Desktop = 3,
}

/* eslint-disable max-depth */
import isPromise from "is-promise";
import _cloneDeep from "lodash/cloneDeep";
import _invokeMap from "lodash/invokeMap";
import _isArrayLike from "lodash/isArrayLike";
import _isElement from "lodash/isElement";
import _isEqual from "lodash/isEqual";
import _isFunction from "lodash/isFunction";
import _isPlainObject from "lodash/isPlainObject";
import _isString from "lodash/isString";
import _noop from "lodash/noop";
import _result from "lodash/result";

// keys used as private variables on Component instance
var privateSuffix = Math.random();
var stateKey = "_state" + privateSuffix;
var eventsKey = "_events" + privateSuffix;
var idKey = "_key" + privateSuffix;
var contextKey = "_context" + privateSuffix;

var observer;

var STATE_DETACHED = 1;
var STATE_ATTACHED = 2;
var STATE_DESTROYING = 3;
var STATE_DESTROYED = 4;

/**
 * The current function to use to query elements in the DOM. Can be overridden when calling `init`.
 * @type {function}
 */
var domQuery = defaultDOMQuery;

/**
 * The current function to use as a DOM wrapper. Can be overridden when calling `init`.
 * @type {function}
 */
var domWrapper = defaultDOMWrapper;

/**
 * Map of component name -> component Class
 * @type {Object}
 */
var componentClasses = {};

/**
 * Map of component id -> component instance
 * @type {Object}
 */
var componentInstances = {};

/**
 * Map of event name -> handlers for that event
 * @type {Object}
 */
var globalHandlers = {};

/**
 * Incrementing number used to give each component a unique id.
 * @type {Number}
 */
var nextComponentId = 1;

var dataComponentIdAttribute = "data-component-id";

var tmpEl;

/**
 * Map of event name -> flag indicating whether or not to use useCapture
 * @type {Object}
 */
var allEvents = {
    click: false,
    dblclick: false,
    mousedown: false,
    mouseup: false,
    mousemove: false,
    mouseleave: true,
    mouseenter: true,
    touchstart: false,
    touchmove: false,
    touchend: false,
    keyup: false,
    keydown: false,
    error: true,
    blur: true,
    focus: true,
    scroll: true,
    submit: true,
    change: true,
    resize: true,
    load: true,
    orientationchange: true,
    input: false,
    drag: false,
    dragstart: false,
    dragend: false,
    dragenter: false,
    dragleave: false,
    drop: false,
};

/**
 * Returns a camel-cased version of `str`.
 * @param {String} str
 * @returns {String}
 */
function toCamelCase(str) {
    var parts = str.split("-");
    var i = 0;
    var j = parts.length;

    while (++i < j) {
        parts[0] += parts[i].substring(0, 1).toUpperCase() + parts[i].substring(1);
    }

    return parts[0];
}

/**
 * The default function to perform DOM queries.
 * @param {HTMLElement} el
 * @param {string} selector
 */
function defaultDOMQuery(el, selector) {
    return el ? el.querySelectorAll(selector) : [];
}

/**
 * The default function to wrap the results of DOM queries.
 * @param {array|NodeList} arr
 * @returns {Array}
 */
function defaultDOMWrapper(arr) {
    return arr && arr.length ? Array.from(arr) : [];
}

/**
 * Returns the closest element to el that matches the given selector.
 * @param {HTMLElement} el
 * @param {String} selector
 * @returns {HTMLElement|Null}
 */
function closestElement(el, selector) {
    while (_isElement(el)) {
        if (el.matches(selector)) {
            return el;
        }

        el = el.parentElement;
    }

    return null;
}

/**
 * Returns the nearest Component instance for the passed element.
 * @param {HTMLElement|Component} element
 * @returns {Component[]}
 */
function parentComponents(element) {
    if (isComponent(element)) {
        element = element.el;
    }

    var id;
    var result = [];

    // Quick return for window or document
    if (element === window || element === document) {
        return [];
    }

    while (_isElement(element)) {
        id = element.getAttribute(dataComponentIdAttribute);

        if (id && componentInstances[id]) {
            result.push(componentInstances[id]);
        }

        element = element.parentElement;
    }

    return result;
}

/**
 * Returns the Component instance for the passed element or null.
 * If a component instance has already been created for this element
 * then it is returned, if not a new instance of the correct Component is created.
 * @param {HTMLElement} el
 */
export function fromElement(el) {
    var name;
    var id;

    if (!_isElement(el)) {
        return null;
    }

    name = el.getAttribute("is");
    id = el.getAttribute(dataComponentIdAttribute);

    // if no name then it is not a component
    if (!name) {
        return null;
    }

    // if there is an id we must already have a component instance
    if (id) {
        return componentInstances[id];
    }

    if (!componentClasses[name]) {
        throw Error("No component has been registered with name " + name);
    }

    // create a new Component instance
    return new componentClasses[name](el);
}

/**
 * Given an array of Component instances invokes 'method' on each one.
 * Any additional arguments are passed to the method.
 * @param {Component[]|Component} components
 * @param {String} method
 */
function invoke(components, method, ...args) {
    if (!components) {
        return;
    }

    if (!_isArrayLike(components)) {
        components = [components];
    }

    _invokeMap(components, method, ...args);
}

/**
 * Given an element returns an object containing all the attributes parsed as JSON.
 *
 * Runs all values through JSON.parse() so it is possible to pass
 * structured data to component instances through data-* attributes.
 * @param {HTMLElement} el
 * @returns {Object}
 */
function parseAttributes(el) {
    let result = {};

    for (let attr of el.attributes) {
        let name = toCamelCase(attr.name);
        let value = tryJSON(attr.value);

        result[name] = value;
    }

    return result;
}

/**
 * Try to JSON decode a string. Possibly Base64 encoded.
 *
 * Will try to decode this string to an object. Will return the original string
 * if JSON.parse fails.
 * @param {String} value
 * @returns {*}
 */
const b64startChars = new Set(Array.from("bedIMONWZ"));
const jsonStartChars = new Set(Array.from('{[0123456789tfn"'));

function tryJSON(value) {
    if (value.length % 4 === 0 && b64startChars.has(value[0])) {
        try {
            var decode = window.atob(value);
            return JSON.parse(decode);
        } catch (er) {}
    }

    try {
        if (jsonStartChars.has(value[0])) {
            return JSON.parse(value);
        }
    } catch (er) {}

    return value;
}

/**
 * Updates objA with objB and returns true if this resulted in any actual changes to objA.
 * @param objA
 * @param objB
 * @returns {boolean}
 */
function updateObject(objA, objB) {
    var changed = false;

    for (let key of Object.keys(objB)) {
        if (!_isEqual(objA[key], objB[key])) {
            changed = true;
            objA[key] = objB[key];
        }
    }

    return changed;
}

/**
 * Returns true if component is an instance of Component.
 * @param component
 * @returns {boolean}
 */
export function isComponent(component) {
    return component instanceof Component;
}

function ensureEventHasMethod(event, methodName, propertyName) {
    if (propertyName in event || methodName in event) {
        return; // don't override existing methods
    }

    event[propertyName] = false;

    event[methodName] = function () {
        event[propertyName] = true;
    };
}

/**
 * When `handleEvent` receives an event it adds a "job" to this queue. A job is an array with 3
 * elements, which map to the arguments expected by `processEventJob`.
 * @type {Array}
 */
var handleEventQueue = [];

var EVENT_JOB_METHOD_INDEX = 0;
var EVENT_JOB_COMPONENT_INDEX = 1;
var EVENT_JOB_ARGS_INDEX = 2;

/**
 * Handles all events - both standard DOM events and custom Component events.
 *
 * Finds all component instances that contain the 'target' and adds a job to the `handleEventsQueue` for each one.
 *
 * If the event is a DOM event then the event target is the 'target' property of the event.
 * If the event is a custom Component event then the target is the component that emitted the event.
 *
 * @param {Event} event
 */
export function handleEvent(event) {
    ensureEventHasMethod(event, "stopPropagation", "propagationStopped");
    ensureEventHasMethod(event, "preventDefault", "defaultPrevented");

    // this adds a "job" to the queue for each handler that should be called on each component
    for (let c of parentComponents(event.target)) {
        pushEventJobsForComponent(event, c);
    }

    // this adds a "job" to the queue for each global handler that should be called
    pushEventJobsForGlobalHandlers(event);

    while (handleEventQueue.length) {
        // get the next job in the queue
        var job = handleEventQueue.shift();

        if (job[EVENT_JOB_ARGS_INDEX][0].propagationStopped) {
            // if this component stopped propagation then remove all queued actions for this event
            while (
                handleEventQueue.length &&
                handleEventQueue[0][EVENT_JOB_ARGS_INDEX][0] === job[EVENT_JOB_ARGS_INDEX][0]
            ) {
                handleEventQueue.shift();
            }
        } else {
            // else we can can the handler
            job[EVENT_JOB_METHOD_INDEX].apply(
                job[EVENT_JOB_COMPONENT_INDEX],
                job[EVENT_JOB_ARGS_INDEX]
            );
        }
    }
}

/**
 * Pushes a "job" to the queue for each event handler `component` has for `event`.
 * @param {Event} event A DOM event or a custom component event.
 * @param {Component} component
 */
function pushEventJobsForComponent(event, component) {
    let target = event.target;

    // We definitely don't want to handle events for destroyed elements.
    if (component[stateKey] === STATE_DESTROYED) {
        return;
    }

    for (let componentEvent of component[eventsKey]) {
        let [eventType, selector, method] = componentEvent;

        // if event doesn't match then go to next component
        if (eventType !== event.type) {
            continue;
        }

        // if there is no selector just invoke the handler and move on
        if (!selector) {
            handleEventQueue.push([method, component, [event]]);
            continue;
        }

        // if this is a component event then the
        // selector just needs to match the component name
        if (isComponent(target)) {
            // if component name matches call the handler
            if (selector === target.name) {
                handleEventQueue.push([method, component, [event]]);
            }
        } else {
            // see if the selector matches the event target
            let closest = closestElement(target, selector);

            // if it does then call the handler passing the matched element
            if (closest) {
                handleEventQueue.push([method, component, [event, closest]]);
            }
        }
    }
}

/**
 * Pushes a "job" to the queue for each global event handler registered for `event`.
 * This is supported for components that need to listen to events on the body/document/window.
 * @param {Event} event A DOM event or a custom component event.
 */
function pushEventJobsForGlobalHandlers(event) {
    let handlers = globalHandlers[event.type];
    if (!handlers) {
        return;
    }

    for (let handler of handlers) {
        handleEventQueue.push([handler.fn, handler.ctx, [event, document.body]]);
    }
}

/**
 * Parses the given element or the root element and creates Component instances.
 * @param {HTMLElement} [node] If not provided then the <body> will be parsed.
 * @param {boolean} [ignoreRootNode=false] If `true` then the root not will not be parsed or returned.
 * @returns {Component[]}
 */
export function parse(node = document.body, ignoreRootNode = false) {
    if (!_isElement(node)) {
        throw Error("node must be an HTMLElement");
    }

    var result = [];

    var componentNodes = node.querySelectorAll("[is]");

    for (var i = componentNodes.length - 1; i >= 0; i--) {
        var subNode = componentNodes[i];
        var subComponent = fromElement(subNode);
        if (subComponent && !subComponent.isDestroyed()) {
            result.push(subComponent);
        }
    }

    if (!ignoreRootNode) {
        var component = fromElement(node);
        if (component && !component.isDestroyed()) {
            result.push(component);
        }
    }

    return result;
}

/**
 * Registers a new Component.
 * @param {String} name
 * @param {Object} [impl] The implementation methods / properties.
 * @returns {Function}
 */
export function register(name, impl) {
    if (!_isString(name) || !name) {
        throw Error('"' + name + '" is not a valid component name');
    }

    if (componentClasses[name]) {
        throw Error("A component called " + name + " already exists");
    }

    impl = impl || {};

    function F() {
        Component.apply(this, arguments); // eslint-disable-line prefer-rest-params
    }

    F.prototype = Object.create(Component.prototype);
    F.prototype.name = name;

    var impls = Array.from(impl.mixins || []);
    impls.push(impl);

    impls.forEach(function (impl) {
        Object.keys(impl).forEach(function (key) {
            var descriptor = Object.getOwnPropertyDescriptor(impl, key);
            var existing = Object.getOwnPropertyDescriptor(F.prototype, key);

            if (_isFunction(descriptor.value) && existing && _isFunction(existing.value)) {
                // save the original method
                var method = descriptor.value;

                // override the value of the descriptor to call
                // both the original function and the new one
                descriptor.value = function () {
                    /* eslint-disable prefer-rest-params */
                    existing.value.apply(this, arguments);
                    return method.apply(this, arguments);
                };
            }

            // define the new property
            Object.defineProperty(F.prototype, key, descriptor);
        });
    });

    componentClasses[name] = F;
    return F;
}

/**
 * Un-registers a Component class and destroys any existing instances.
 * @param {string} name
 */
export function unregister(name) {
    destroy(name);
    componentClasses[name] = null;
}

/**
 *
 * @param {string} method
 */
function eventManager(method) {
    var key;
    var el;

    for (key in allEvents) {
        // special case for resize and scroll event to listen on window
        el = ["resize", "scroll", "orientationchange"].indexOf(key) !== -1 ? window : document.body;

        el[method](key, handleEvent, !!allEvents[key]);
    }
}

/**
 * Handler for mutation events. Only used when MutationObserver is not supported.
 * @param event
 */
function mutationEventHandler(event) {
    switch (event.type) {
        case "DOMNodeInserted":
            nodeInserted(event.target);
            break;
        case "DOMNodeRemoved":
            nodeRemoved(event.target);
            break;
    }
}

/**
 * Binds all events.
 */
function bindEvents() {
    eventManager("addEventListener");

    if (window.MutationObserver) {
        // use MutationObserver if available
        observer = new MutationObserver(function (records) {
            for (let record of records) {
                for (let node of record.removedNodes) {
                    nodeRemoved(node);
                }
                for (let node of record.addedNodes) {
                    nodeInserted(node);
                }
            }
        });

        observer.observe(document.body, {
            childList: true,
            subtree: true,
        });
    } else {
        // fallback to mutation events
        document.body.addEventListener("DOMNodeInserted", mutationEventHandler, true);
        document.body.addEventListener("DOMNodeRemoved", mutationEventHandler, true);
    }
}

/**
 * Unbinds all events.
 */
function unbindEvents() {
    eventManager("removeEventListener");

    if (observer) {
        observer.disconnect();
    } else {
        document.body.removeEventListener("DOMNodeInserted", mutationEventHandler, true);
        document.body.removeEventListener("DOMNodeRemoved", mutationEventHandler, true);
    }
}

/**
 * Handler for a node being inserted. Parses the node finding all components and
 * calls `onInsert` on each.
 * @param node
 */
function nodeInserted(node) {
    if (_isElement(node)) {
        // We only want components that think they are detached as IE10 can get a bit trigger
        // happer with firing DOMNodeInserted events.
        var components = parse(node).filter(function (c) {
            if (c[stateKey] === STATE_DETACHED) {
                c[stateKey] = STATE_ATTACHED;
                return true;
            }
            return false;
        });

        invoke(components, "onInsert");
    }
}

/**
 * Handler for a node being removed. Parses the node finding all components and
 * calls `onRemove` on each.
 * @param node
 */
function nodeRemoved(node) {
    if (_isElement(node)) {
        // We only want components that think they are attached as IE10 can get a bit trigger
        // happer with firing DOMNodeRemoved events.
        var components = parse(node).filter(function (c) {
            if (c[stateKey] === STATE_ATTACHED) {
                c[stateKey] = STATE_DETACHED;
                return true;
            }
            return false;
        });

        invoke(components, "onRemove");
    }
}

/**
 * Initialises the components library by parsing the DOM and binding events.
 * @param {object} [options]
 * @param {function} [options.domQuery] A custom function to use to make DOM queries.
 * @param {function} [options.domWrapper] A custom function to use to wrap the results
 *                                        of DOM queries.
 */
export function init(options) {
    tmpEl = document.createElement("div");

    options = options || {};

    if (options.domQuery) {
        domQuery = options.domQuery;
    }

    if (options.domWrapper) {
        domWrapper = options.domWrapper;
    }

    bindEvents();

    // by calling `nodeInserted` not only will all the components present at page load be parsed
    // but `onInsert` will be called and the "inserted" event will be emitted on each
    nodeInserted(document.body);
}

/**
 * Opposite of `init`. Destroys all component instances and un-registers all components.
 * Resets the `domQuery` and `domWrapper` functions to their defaults.
 */
export function reset() {
    // destroy any component instances
    for (var key in componentInstances) {
        if (componentInstances[key]) {
            componentInstances[key].destroy();
        }
    }

    // reset state
    domQuery = defaultDOMQuery;
    domWrapper = defaultDOMWrapper;
    componentClasses = {};
    componentInstances = {};

    // unbind all event handlers
    unbindEvents();
}

/**
 * @param {string} name
 * @returns {Object}
 */
export function getInstanceOf(name) {
    return getInstancesOf(name)[0];
}

/**
 * @param {string} name
 * @returns {Array}
 */
export function getInstancesOf(name) {
    return Object.values(componentInstances).filter(
        (component) => component && component.name == name
    );
}

/**
 * @param {string} name
 */
export function destroy(name) {
    invoke(getInstancesOf(name), "destroy");
}

/**
 * Copy all attributes from `source` to `target` and remove any attributes from `target` that are
 * not present on `source`. The data-component-id attribute is ignored.
 * @param {HTMLElement} target
 * @param {HTMLElement} source
 */
function copyAttributes(target, source) {
    for (let attr of target.attributes) {
        let key = attr.name;
        if (key != dataComponentIdAttribute && !source.hasAttribute(key)) {
            target.removeAttribute(key);
        }
    }

    for (let attr of source.attributes) {
        target.setAttribute(attr.name, attr.value);
    }
}

/**
 * Creates a new Component
 * @param element
 * @param options
 * @constructor
 */
export function Component(element, options) {
    var shouldRender = false;

    if (arguments.length === 1 && _isPlainObject(element)) {
        options = element;
        element = this.createRootElement();
        shouldRender = true;
    }

    if (!arguments.length) {
        element = this.createRootElement();
        shouldRender = true;
    }

    // internals
    this[idKey] = nextComponentId++;
    this[eventsKey] = [];
    this[stateKey] = STATE_DETACHED;
    this[contextKey] = {};

    this.el = element;

    // Convenience for accessing this components root element wrapped
    // in whatever `domWrapper` returns. Not used internally.
    this.$el = domWrapper([this.el]);

    // Options are built from optional default options - this can
    // be a property or a function that returns an object, the
    // element attributes, and finally any options passed to the constructor
    this.options = Object.assign(
        {},
        _result(this, "defaultOptions", {}),
        parseAttributes(this.el),
        options
    );

    if (this.options.template) {
        this.template = this.options.template;
    }

    element.setAttribute("is", this.name);
    element.setAttribute(dataComponentIdAttribute, this[idKey]);

    // store this instance
    componentInstances[this[idKey]] = this;

    this.init();
    this.setupEvents(this.registerEvent.bind(this));
    this[contextKey] = _cloneDeep(this.getInitialRenderContext());

    if (this.options.onTemplateFail) {
        this.onTemplateFail = this.options.onTemplateFail;
    }

    // Only render if we created the root element in the constructor function. Otherwise we assume
    // that the element was already on the page and was already rendered.
    if (shouldRender) {
        this.render();
    }
}

Component.prototype = {
    name: "",

    tagName: "div",

    /**
     * If provided this will be used to render the component when `render()` is called. It should
     * be a function that accepts a single argument, which will be the return value of `getRenderContext()`.
     * It must return a valid HTML string and represent the entire component, including the root node.
     * @type {function}
     */
    template: null,

    /**
     * The init function will be called when the Component is created.
     * This maybe be through the parsing of DOM or through directly creating the component.
     */
    init: function () {},

    /**
     * Sets up any events required on the component, called during component initialisation.
     * @example
     *  setupEvents: function(add) {
     *      add('click', '.image-thumbnail', this._onImageThumbnailClick);
     *      add('mouseover', '.image', this._onImageMouseOverClick);
     *  }
     * @param {Function} add - use this function to add any events to the component
     */
    setupEvents: _noop,

    /**
     * Renders the component using `template`. This method performs a destructive render e.g. all
     * child components will first be destroyed.
     */
    render: function () {
        var template = this.template;
        var html;
        var newElement;

        if (!_isFunction(template)) {
            return;
        }

        const templateReturnValue = template.call(this, this.getRenderContext());

        if (isPromise(templateReturnValue)) {
            templateReturnValue
                .then(({ default: templ }) => {
                    this.template = templ;
                    this.render();
                })
                .catch(() => {
                    this.onTemplateFail();
                });
            this.templatePromise = templateReturnValue;
            return;
        }

        html = templateReturnValue;

        // Check the render produced usable HTML

        tmpEl.innerHTML = html;
        let firstChild = tmpEl.firstElementChild;

        if ((firstChild && firstChild.nextElementSibling) || !firstChild) {
            throw Error("A component template must produce a single DOM node.");
        }

        newElement = tmpEl.removeChild(firstChild);
        tmpEl.innerHTML = "";

        if (newElement.tagName !== this.el.tagName) {
            throw Error("Cannot change the tagName of an element.");
        }

        // Replace the old DOM with the newly rendered DOM

        // destroy all children of the target as they are about to be re-rendered
        invoke(parse(this.el, true), "destroy");

        this.el.innerHTML = "";

        while (newElement.firstChild) {
            this.el.appendChild(newElement.firstChild);
        }

        copyAttributes(this.el, newElement);
    },

    /**
     * Sets all the values in `context` into the components render context. If this results in any
     * changes to the context `render()` will be called.
     * @param {object} context
     */
    setRenderContext: function (context) {
        if (this.isDestroyed() || this.isDestroying()) {
            return;
        }

        // we want our own copy of the context so nothing outside can mutate it
        context = _cloneDeep(context);

        if (updateObject(this[contextKey], context)) {
            this.render();
        }
    },

    /**
     * Replaces the current render context with `context`. If this results in a different render
     * context then `render()` will be called.
     * @param {object} context
     */
    replaceRenderContext: function (context) {
        if (this.isDestroyed() || this.isDestroying()) {
            return;
        }

        // we want our own copy of the context so nothing outside can mutate it
        context = _cloneDeep(context);

        // if it is different to the current context then set it and call render()
        if (!_isEqual(this[contextKey], context)) {
            this[contextKey] = context;
            this.render();
        }
    },

    /**
     * Returns a clone of the current render context.
     * @returns {object}
     */
    getRenderContext: function () {
        return _cloneDeep(this[contextKey]);
    },

    /**
     * Called by the constructor to get the initial render context.
     * @returns {object}
     */
    getInitialRenderContext: function () {
        return {};
    },

    /**
     * Updates this components options. If calling this method results in the options changing then
     * `onOptionsChanged` will be called with the previous options.
     * @param options
     */
    updateOptions: function (options) {
        var optionsClone = _cloneDeep(this.options);

        if (updateObject(this.options, options)) {
            return Promise.resolve(this.onOptionsChange(optionsClone));
        } else {
            return Promise.reject().catch(() => {});
        }
    },

    /**
     * Called when options are changed via a call to `updateOptions`.
     */
    onOptionsChange: _noop,

    /**
     * Emits an event that parent Components can listen to.
     * @param name The name of the event to emit
     * @param [data] Event data
     */
    emit: function (name, data) {
        data = data || {};
        data.target = data.target || this;
        data.type = name;
        data.customEvent = true;

        handleEvent(data);
        return data;
    },

    /**
     * Inserts this component before another element.
     * @param {HTMLElement} el the element to go before
     */
    insertBefore: function (el) {
        el = _isElement(el) ? el : isComponent(el) ? el.el : null;

        if (!el) {
            return;
        }

        var parent = el.parentElement;
        if (parent) {
            parent.insertBefore(this.el, el);
        }
    },

    /**
     * Inserts this component after another element.
     * @param {HTMLElement} el the element to go after
     */
    insertAfter: function (el) {
        el = _isElement(el) ? el : isComponent(el) ? el.el : null;

        if (!el) {
            return;
        }

        // no insertAfter, so insert before the next sibling
        // null case automatically handled
        var parent = el.parentNode;
        if (parent) {
            parent.insertBefore(this.el, el.nextSibling);
        }
    },

    /**
     * Appends this Component to an element.
     * @param {HTMLElement} el
     */
    appendTo: function (el) {
        el = _isElement(el) ? el : isComponent(el) ? el.el : null;

        if (!el) {
            return;
        }

        el.appendChild(this.el);
    },

    /**
     * Called after the Component is inserted into the DOM.
     */
    onInsert: _noop,

    /**
     * Removes this component from the DOM.
     */
    remove: function () {
        // Cannot be removed if no element or no parent element
        if (!this.el || !this.el.parentElement) {
            return;
        }

        this.el.parentElement.removeChild(this.el);

        // If the component is currently destroying itself it is better to call onRemove() manually
        // here rather than wait for the mutation event to pick it up. This is because there is a
        // race condition where the state is set to destroyed before the mutation event fires.
        if (this.isDestroying()) {
            this.onRemove();
        }
    },

    /**
     * Called after this Component is removed from the DOM.
     */
    onRemove: _noop,

    /**
     * Removes this Component from the DOM and deletes the instance from the instances pool.
     */
    destroy: function () {
        // Check that this component has not already been destroyed or is currently being destroyed.
        if (!componentInstances[this[idKey]] || this.isDestroying()) {
            return;
        }

        this[stateKey] = STATE_DESTROYING;
        this[contextKey] = null;

        // invoke destroy on all child Components
        invoke(parse(this.el, true), "destroy");

        // Make sure this component is removed
        this.remove();

        this.releaseAllGlobalHandlers();

        // We are now destroyed!
        this[stateKey] = STATE_DESTROYED;

        // Remove the reference to the element and the dom wrapper
        this.el = null;
        this.$el = null;

        // Remove the reference to this component instance. Using a null assignment instead of
        // delete as delete has performance implications
        componentInstances[this[idKey]] = null;
    },

    /**
     * In the case that this Component is created directly by invoking the constructor with
     * no element this method will be called to create the root element.
     * @returns {HTMLElement}
     */
    createRootElement: function () {
        return document.createElement(this.tagName);
    },

    /**
     * Convenience method for performing querySelector within
     * the context of this Component.
     * @param {String} selector
     * @returns {Array}
     */
    find: function (selector) {
        return domWrapper(domQuery(this.el, selector));
    },

    /**
     * Returns the first component with 'name' within this Component or null.
     * @param {String} name
     * @returns {Component|Null}
     */
    findComponent: function (name) {
        return fromElement(this.find("[is=" + name + "]")[0]);
    },

    /**
     * Returns all components with 'name' within this component.
     * If no components exist with this name an empty array will be returned.
     * @param name
     * @returns {Component[]}
     */
    findComponents: function (name) {
        return [].map.call(this.find("[is=" + name + "]"), fromElement);
    },

    invoke: invoke,

    /**
     * Registers an event that this component would like to listen to.
     * @param {string} event
     * @param {string|function} selector
     * @param {function} [handler]
     */
    registerEvent: function (event, selector, handler) {
        if (arguments.length === 2) {
            handler = selector;
            selector = null;
        }

        this[eventsKey].push([event, selector, handler]);
    },

    /**
     * Release an event or all events from this component.
     * @example
     *  releaseEvent('click', '.image-thumbnail, this._onImageThumbnailClick);
     *  // releases the specific click event handler on an object
     *
     * @example
     *  releaseEvent('click', '.image-thumbnail');
     *  // release all click events on the object
     *
     * @example
     *  releaseEvent('click'); // releases all click events on the component
     *
     * @param {String} event - the event to release
     * @param {String} [selector] - the selector of the object to release the event
     * @param {Function} [handler] - the handler to release off the object
     */
    releaseEvent: function (event, selector, handler) {
        if (_isFunction(selector) && !handler) {
            handler = selector;
            selector = null;
        }

        if (!_isFunction(handler)) {
            handler = null;
        }

        if (typeof selector === "undefined") {
            selector = null;
        }

        this[eventsKey] = this[eventsKey].filter(function (ev) {
            var eventName = ev[0];
            var eventSelector = ev[1];
            var eventHandler = ev[2];

            if (!handler) {
                // we don't care what handler, just get rid of it
                return !(eventName === event && eventSelector === selector);
            } else {
                return !(
                    eventName === event &&
                    eventSelector === selector &&
                    eventHandler === handler
                );
            }
        });
    },

    /**
     * Set a global event handler. This is useful when you
     * need to listen to events that happen outside this component.
     * @param {String} event
     * @param {Function} fn
     */
    setGlobalHandler: function (event, fn) {
        // Each component should only be able to set 1 global handler for a given event with
        // the same function.
        this.releaseGlobalHandler(event, fn);

        globalHandlers[event] = globalHandlers[event] || [];

        globalHandlers[event].push({
            fn: fn,
            ctx: this,
        });
    },

    /**
     * Release a global event handler that was previously set with setGlobalHandler().
     * @param {String} event
     * @param {Function} fn
     */
    releaseGlobalHandler: function (event, fn) {
        var handlers = globalHandlers[event];

        if (!handlers) {
            return;
        }

        // filter out entries with the same function and context
        globalHandlers[event] = handlers.filter((handler) => {
            return handler.fn !== fn || handler.ctx !== this;
        });
    },

    /**
     * Releases all global handles that this component has registered using `setGlobalHandler`.
     */
    releaseAllGlobalHandlers: function () {
        for (let [event, handlers] of Object.entries(globalHandlers)) {
            globalHandlers[event] = handlers.filter((h) => h.ctx != this);
        }
    },

    /**
     * @returns {boolean} true if the component is currently destroying itself.
     */
    isDestroying: function () {
        return this[stateKey] === STATE_DESTROYING;
    },

    /**
     * @returns {boolean} true if the component has been destroyed.
     */
    isDestroyed: function () {
        return this[stateKey] == STATE_DESTROYED;
    },

    /**
     * @returns {boolean} true if the component is attached to the DOM.
     */
    isAttached: function () {
        return this[stateKey] == STATE_ATTACHED;
    },

    /**
     * @returns {boolean} true if the component is detached from the DOM.
     */
    isDetached: function () {
        return this[stateKey] == STATE_DETACHED;
    },

    /**
     * Can be used to determine if a dynamically imported template has been fetched
     */
    templatePromise: Promise.resolve(),

    /**
     * Will be called if the dynamic import is rejected
     */
    onTemplateFail: _noop,
};

export default {
    fromElement: fromElement,
    isComponent: isComponent,
    handleEvent: handleEvent,
    parse: parse,
    register: register,
    unregister: unregister,
    init: init,
    reset: reset,
    getInstanceOf: getInstanceOf,
    getInstancesOf: getInstancesOf,
    destroy: destroy,
    Component: Component,
};

import requester from "web/script/modules/requester";
import { buildLanguageAwareUrlPath } from "web/script/utils/url";
import { AuthType } from "./redux/ducks/customer-capture-overlay";

type DjangoJSONResponse<T> = {
    success: boolean;
    data?: T;
    errors: Record<string, string>;
};

export async function resetPassword(email: string): Promise<Response> {
    const response: any = await requester(`/rest_api/account/password-reset/`, {
        method: "PUT",
        body: { email } as any,
    });

    if (response.status !== 204) {
        throw new Error(response.data?.errors?.join?.("\n") ?? "Unknown error");
    }

    return response;
}

export type LoginResponse = DjangoJSONResponse<{
    redirect_url?: string;
}>;

type LoginBody = {
    designer_id?: string;
    invisible_pids?: string;
    product_ids?: string | number | null;
    search_query: string | null;
    search_gender: string | null;
    next: string;
};

export async function passwordLogin(
    email: string,
    password: string,
    body?: LoginBody,
    type?: AuthType
): Promise<LoginResponse> {
    const path = buildLanguageAwareUrlPath(`/account/login/?magic-link=false&type=${type}`);
    return await requester.post<LoginResponse>(path, {
        username: email,
        password: password,
        ...body,
    } as any);
}

export async function magicLinkLogin(email: string): Promise<LoginResponse> {
    return await requester.post<LoginResponse>(`/account/login/?magic-link=true`, {
        email,
    } as any);
}

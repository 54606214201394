import PropTypes from "prop-types";
import React from "react";

function Template({ tag, template, args, ...props }) {
    return React.createElement(tag, {
        ...props,
        dangerouslySetInnerHTML: { __html: template(args) },
    });
}

Template.propTypes = {
    tag: PropTypes.string.isRequired,
    template: PropTypes.func.isRequired,
    args: PropTypes.object.isRequired,
};

Template.defaultProps = {
    tag: "div",
};

export default Template;

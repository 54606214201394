import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { FeedRelatedProducts } from "web/types/serializers";

const initialState: FeedRelatedProducts = {
    feed_cards: [],
};

const moreLikeThis = createSlice({
    name: "moreLikeThis",
    initialState,
    reducers: {
        setMoreLikeThis(state, action: PayloadAction<FeedRelatedProducts>): void {
            state.feed_cards = action.payload.feed_cards;
        },
    },
});

export const { setMoreLikeThis } = moreLikeThis.actions;
export default moreLikeThis.reducer;

import clsx from "clsx";
import noop from "lodash/noop";
import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import SVGIcon from "web/react/components/svg-icon/svg-icon";
import useIsomorphicLayoutEffect from "web/react/hooks/use-isomorphic-layout-effect/use-isomorphic-layout-effect";
import styles from "./accordion.module.css";

export type AccordionHook = {
    accordionName: string | undefined;
    nonInteraction: boolean;
};

interface AccordionContextProps {
    expanded: boolean;
    onToggle: (any) => void;
}

export const AccordionContext = React.createContext<AccordionContextProps>({
    expanded: false,
    onToggle: () => {},
});

interface AccordionTitleProps {
    children: React.ReactNode;
    className?: string;
    activeClassName?: string;
}

function AccordionTitle({
    children,
    className,
    activeClassName = "",
}: AccordionTitleProps): React.ReactElement {
    const { onToggle, expanded } = useContext(AccordionContext);

    return (
        <div
            className={clsx(styles.title, className, { [activeClassName]: expanded })}
            onClick={onToggle}
        >
            {children}
            <SVGIcon
                name="chevron-medium"
                className={clsx(styles.chevronIcon, {
                    [styles.chevronIconActive]: expanded,
                })}
            />
        </div>
    );
}

interface AccordionContentProps {
    children: React.ReactNode;
    className?: string;
}

function AccordionContent({ children, className }: AccordionContentProps): React.ReactElement {
    const { expanded } = useContext(AccordionContext);
    const [maxHeight, setMaxHeight] = useState<number | string>(0);
    const [isTransitioning, setIsTransitioning] = useState<boolean>(false);
    const [localExpanded, setLocalExpanded] = useState<boolean>(expanded);
    const contentRef = useRef<HTMLDivElement>(null);
    const transitionRef = useRef<ReturnType<typeof setTimeout>>(setTimeout(noop, 0));

    /**
     * Behaviour summary:
     * 1. on expand, trigger expand transition, maxHeight = 0 -> maxHeight = contentScrollHeight
     * 2. after expand, on transition end: maxHeight = "initial"
     * 3. before collapse, maxHeight = "initial" -> maxHeight = contentScrollHeight
     * 4. trigger collapse transition, maxHeight = contentScrollHeight -> maxHeight = 0
     *
     * footnote: the `transitionend` event fires inconsistently across browsers, hence we're making use of setTimeout
     */

    useEffect(() => {
        return clearTimeout(transitionRef.current);
    }, []);

    useIsomorphicLayoutEffect(() => {
        if (expanded === localExpanded) {
            return;
        }
        if (contentRef.current) {
            setMaxHeight(contentRef.current.scrollHeight);
        }
        if (!isTransitioning) {
            setIsTransitioning(true);
        } else {
            setLocalExpanded(expanded);
            clearTimeout(transitionRef.current);
        }
        transitionRef.current = setTimeout(() => {
            setIsTransitioning(false);
        }, 150);
    }, [expanded]);

    useEffect(() => {
        if (isTransitioning) {
            setLocalExpanded(expanded);
        }
    }, [isTransitioning]);

    return (
        <div
            ref={contentRef}
            className={clsx(styles.content, { [styles.contentExpanded]: localExpanded }, className)}
            style={
                isTransitioning
                    ? {
                          maxHeight: localExpanded ? maxHeight : 0,
                      }
                    : undefined
            }
        >
            {children}
        </div>
    );
}

interface ControlledAccordionProps extends ReactComponentProps {
    expanded: boolean;
    onToggle: () => void;
}

function ControlledAccordion({
    children,
    expanded,
    onToggle,
    className,
}: ControlledAccordionProps): React.ReactElement {
    return (
        <AccordionContext.Provider value={{ expanded, onToggle }}>
            <div className={clsx(styles.accordion, className)}>{children}</div>
        </AccordionContext.Provider>
    );
}

interface AccordionProps extends ReactComponentProps {
    initialExpanded?: boolean;
    name?: string;
    onAccordionOpened?: (param: AccordionHook) => void;
    onAccordionClosed?: (param: AccordionHook) => void;
}

function Accordion({
    initialExpanded,
    children,
    name: accordionName,
    onAccordionOpened,
    onAccordionClosed,
    className,
}: AccordionProps): React.ReactElement {
    const [expanded, setExpanded] = useState<boolean>(initialExpanded || false);

    useEffect(() => {
        const nonInteraction = true;
        if (initialExpanded && onAccordionOpened) {
            onAccordionOpened({ accordionName, nonInteraction });
        } else if (!initialExpanded && onAccordionClosed) {
            onAccordionClosed({ accordionName, nonInteraction });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onToggle = useCallback(() => {
        const nonInteraction = false;
        if (expanded && onAccordionClosed) {
            onAccordionClosed({ accordionName, nonInteraction });
        } else if (!expanded && onAccordionOpened) {
            onAccordionOpened({ accordionName, nonInteraction });
        }

        setExpanded(!expanded);
    }, [expanded, accordionName, onAccordionClosed, onAccordionOpened]);

    return (
        <ControlledAccordion className={className} expanded={expanded} onToggle={onToggle}>
            {children}
        </ControlledAccordion>
    );
}

Accordion.Controlled = ControlledAccordion;
Accordion.Content = AccordionContent;
Accordion.Title = AccordionTitle;

export default Accordion;

import React, { useEffect, useState } from "react";
import BranchLink from "web/react/components/branch-link/branch-link";
import { gettext } from "web/script/modules/django-i18n";
import globals from "web/script/modules/globals";
import { RawBranchLink } from "web/script/utils/branch-io";
import styles from "./password-reset-complete.module.css";

interface PasswordResetCompleteProps {
    loginUrl: string;
}

function PasswordResetComplete({ loginUrl }: PasswordResetCompleteProps): JSX.Element {
    const [appDeepLinkURL, setAppDeepLinkURL] = useState<URL | undefined>(undefined);
    useEffect(() => {
        setAppDeepLinkURL(new URL(loginUrl, globals.window.location.href));
    }, [loginUrl]);

    return (
        <div className={styles.container}>
            <h1 className={styles.header}>{gettext("password_reset_complete.title")}</h1>
            <div className={styles.linkContainer}>
                <BranchLink
                    className={styles.appCta}
                    branchLink={RawBranchLink.PasswordResetComplete}
                    eventLabel={"password_reset_complete"}
                    title={gettext("password_reset_complete.app_link_cta.text")}
                    appDeepLinkURL={appDeepLinkURL}
                    primaryButton
                />
                <a className={styles.webLink} href={loginUrl}>
                    {gettext("password_reset_complete.website_link.text")}
                </a>
            </div>
        </div>
    );
}

export default PasswordResetComplete;

import React from "react";
import {
    ProductMoreDescriptionsSerializer,
    ProductMoreDescriptionsSingleDescriptionSerializer,
} from "web/types/serializers";
import styles from "./product-more-descriptions.module.css";

function ProductMoreDescriptions({
    descriptions,
}: ProductMoreDescriptionsSerializer): React.ReactElement {
    return (
        <>
            {descriptions.map((item, index) => (
                <ProductMoreDescription key={item.description || index} {...item} />
            ))}
        </>
    );
}

function ProductMoreDescription({
    description,
    short_description: shortDescription,
    from_retailer_name: fromRetailerName,
}: ProductMoreDescriptionsSingleDescriptionSerializer): React.ReactElement {
    return (
        <div className={styles.productMoreDescription}>
            {!!fromRetailerName && <p>{fromRetailerName}</p>}

            {!!shortDescription && <p>{shortDescription}</p>}

            {!!description && <p>{description}</p>}
        </div>
    );
}

export default ProductMoreDescriptions;

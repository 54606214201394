import clsx from "clsx";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import CloseButton from "web/react/components/buttons/close-button/close-button";
import FollowDesignerButton from "web/react/components/follow-designer-button/LEGACY/follow-designer-button";
import { useDomViewport } from "web/react/hooks/use-dom-viewport/use-dom-viewport";
import { useKevelCampaignControl } from "web/react/hooks/use-kevel-campaign-control/use-kevel-campaign-control";
import { bannerDisabledByUser } from "web/redux/ducks/follow-designer-banner";
import analytics from "web/script/analytics/analytics";
import { gettext } from "web/script/modules/django-i18n";
import { getHtmlProps } from "web/script/modules/html-tag-props";
import storage from "web/script/utils/storage";
import styles from "./follow-sticky-banner.module.css";

const ONE_HOUR = 3600000;

interface FollowStickyBannerProps {
    lystId: number;
    followedText: string;
    notFollowedText: string;
    isAuthenticated: string;
    isFollowing: string | null;
    description?: string;
    profileImage: string | null;
    forceDisplay: boolean;
    designerName: string | null;
    productTaxonomy: string | null;
    feedTitle: string | null;
}

function FollowStickyBanner({
    lystId,
    followedText,
    notFollowedText,
    isAuthenticated,
    isFollowing,
    description,
    profileImage,
    forceDisplay,
    designerName,
    productTaxonomy,
    feedTitle,
}: FollowStickyBannerProps): React.ReactElement {
    const [show, setShow] = useState(false);
    let [closed, setClosed] = useState(false);
    const [isEnabled, setIsEnabled] = useState(true); // TODO until the campaign is live, we will show the banner
    const wrapperRef = useRef(null);
    const dispatch = useDispatch();

    // This is to check if to hide the banner when Follow button
    // in the top is visible
    const { isTabletViewport, isDesktopViewport } = useDomViewport();
    const hideDUBanner = isTabletViewport || isDesktopViewport;

    function displayBanner(): void {
        setShow(true);
        analytics.event("du_feed_banner", "banner_shown", "du_banner", true); // non interactive
        storage.remove("hideStickyBannerTimestamp");
    }

    const triggerBanner = useCallback(
        (delay) => {
            let hideStickyBannerTimestamp = storage.get("hideStickyBannerTimestamp");
            let timer;
            if (
                !hideStickyBannerTimestamp ||
                hideStickyBannerTimestamp < new Date().getTime() - ONE_HOUR
            ) {
                timer = setTimeout(displayBanner, delay);
            } else {
                dispatch(bannerDisabledByUser(true));
            }

            return () => {
                clearTimeout(timer);
            };
        },
        [dispatch]
    );

    const { campaignDetails } = useKevelCampaignControl("top_banner");
    useEffect(() => {
        if (campaignDetails) {
            setIsEnabled(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (closed || hideDUBanner || !isEnabled) {
            return;
        }
        triggerBanner(5000);
    });

    useEffect(() => {
        if (closed || !hideDUBanner || !isEnabled) {
            return;
        }

        if (forceDisplay) {
            triggerBanner(500);
        } else {
            setShow(false);
        }
    }, [forceDisplay, closed, triggerBanner, hideDUBanner, isEnabled]);

    function triggerClose(): void {
        setShow(false);
        setClosed(true);
        // update reducer state banner is disabled
        dispatch(bannerDisabledByUser(true));
        storage.set("hideStickyBannerTimestamp", new Date().getTime());
        analytics.event("du_feed_banner", "banner_hidden", "du_banner", true); // non interactive
    }

    let renderedProfileImage: JSX.Element | null = null;
    if (profileImage) {
        renderedProfileImage = <img src={profileImage} className={styles.imageProfile} />;
    }

    if (!description) {
        const designerNameProps = {
            class: styles.designerName,
        };
        if (productTaxonomy) {
            description = gettext("designer_updates.get_updates_designer_category.in_spans", {
                designer_name_span_props: getHtmlProps(designerNameProps),
                designer_name: designerName,
                product_taxonomy: productTaxonomy,
            });
        } else {
            description = gettext("designer_updates.get_updates_designer.in_spans", {
                designer_name_span_props: getHtmlProps(designerNameProps),
                designer_name: designerName,
            });
        }
    }

    return (
        <div ref={wrapperRef}>
            <div
                className={clsx(styles.stickyBanner, {
                    [styles.stickyBannerShow]: show && !closed,
                })}
            >
                <div className={styles.wrapper}>
                    <div className={styles.topRow}>
                        <div className={styles.contentWrapper}>
                            {renderedProfileImage}
                            <div
                                className={styles.content}
                                dangerouslySetInnerHTML={{ __html: description }}
                            />
                        </div>
                    </div>
                    <div>
                        <FollowDesignerButton
                            className={styles.button}
                            lystId={lystId}
                            followedText={followedText}
                            notFollowedText={notFollowedText}
                            isAuthenticated={isAuthenticated}
                            isFollowing={isFollowing || ""}
                            analyticsParameters={[
                                "du_feed_banner",
                                "click_banner_cta",
                                "du_banner",
                            ]}
                            designerName={feedTitle || ""}
                        />
                        <CloseButton className={styles.closeButton} onClick={triggerClose} />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default FollowStickyBanner;

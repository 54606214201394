import React, { useRef } from "react";
import { DisplayBannerContainer } from "web/react/components/home/stories/display-banner-container";
import { ProductRailContainer } from "web/react/components/home/stories/product-rail-container";
import { SecondaryStoryRailContainer } from "web/react/components/home/stories/secondary-story-rail-container";
import { StoryContainer } from "web/react/components/home/stories/story-container";
import { ProductOverlay } from "web/react/components/product-overlay";
import { StoriesSerializer } from "web/types/serializers";
import * as styles from "./stories.css";

interface StoriesProps {
    stories: StoriesSerializer;
}

export function Stories({ stories }: StoriesProps): React.ReactElement {
    const {
        hero_story,
        primary_story,
        display_banner,
        secondary_story_rail,
        product_rail,
    }: StoriesSerializer = stories;

    const overlayRef = useRef(null);

    let position = 0;

    return (
        <div>
            <div ref={overlayRef}>
                <ProductOverlay parentRef={overlayRef} pageType="home" />
            </div>

            <section className={styles.wrapper}>
                {hero_story && (
                    <StoryContainer
                        story={hero_story}
                        storyType="hero"
                        className={styles.hero}
                        position={(position += 1)}
                    />
                )}
                {primary_story && (
                    <StoryContainer
                        story={primary_story}
                        storyType="primary"
                        className={styles.container}
                        position={(position += 1)}
                    />
                )}
                {display_banner && (
                    <DisplayBannerContainer
                        {...display_banner}
                        className={styles.container}
                        label="display_banner"
                        position={(position += 1)}
                        lazy
                        variant="lg"
                    />
                )}
                {product_rail && (
                    <ProductRailContainer
                        productRail={product_rail}
                        className={styles.container}
                        position={(position += 1)}
                    />
                )}
                {secondary_story_rail && (
                    <SecondaryStoryRailContainer
                        secondaryStoryRail={secondary_story_rail}
                        className={styles.container}
                        position={(position += 1)}
                        lazy
                    />
                )}
            </section>
        </div>
    );
}

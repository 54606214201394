import React, { useEffect, useState } from "react";
import { MemoryRouter, Route, Routes, useLocation } from "react-router-dom";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { Conditional } from "web/react/components/conditional";
import { ValuePropositionAnimation } from "web/react/components/value-proposition-animation/";
import { View } from "web/react/emo/view";
import { useDomViewport } from "web/react/hooks/use-dom-viewport/use-dom-viewport";
import { AuthType } from "web/redux/ducks/customer-capture-overlay";
import { AuthFormModuleSerializer } from "web/types/serializers";
import { ForgotPasswordEmailSentScreen } from "./forgot-password-email-sent-screen";
import { ForgotPasswordScreen } from "./forgot-password-screen";
import { InitialScreen } from "./initial-screen";
import { LoginEmailSentScreen } from "./login-email-sent-screen";
import { LoginScreen } from "./login-screen";
import styles from "./signup-or-login-page.module.css";
import { SignupScreen } from "./signup-screen";

function SignupOrLoginRoutes({
    setShowProposition,
    ...props
}: SignupOrLoginPageProps & {
    setShowProposition(value: boolean): void;
}): JSX.Element {
    const [email, setEmail] = useState("");
    const location = useLocation();
    const { isMobileViewport, isTabletViewport } = useDomViewport();

    useEffect(() => {
        if (isMobileViewport || isTabletViewport) {
            setShowProposition(location.pathname === "/");
        }
    }, [location, setShowProposition, isMobileViewport, isTabletViewport]);

    return (
        <TransitionGroup className={styles.flexRow}>
            <CSSTransition
                key={location.key}
                classNames={{
                    enter: styles.enter,
                    enterActive: styles.enterActive,
                    exit: styles.exit,
                    exitActive: styles.exitActive,
                }}
                timeout={{ enter: 1000, exit: 500 }}
                mountOnEnter
                unmountOnExit
            >
                <View
                    className={styles.screen}
                    marginY={{ sm: "sm", lg: "xxxl" }}
                    paddingX={{ sm: "xs", md: "sm", lg: "lg" }}
                    paddingY={{ sm: "md", lg: "xxl" }}
                >
                    <Routes location={location}>
                        <Route
                            index
                            element={
                                <InitialScreen {...props} onSuccess={setEmail} email={email} />
                            }
                        />
                        <Route path="/signup" element={<SignupScreen email={email} />} />
                        <Route path="/login" element={<LoginScreen email={email} />} />
                        <Route
                            path="/login/email-sent"
                            element={<LoginEmailSentScreen email={email} />}
                        />
                        <Route
                            path="/forgot-password"
                            element={<ForgotPasswordScreen onSuccess={setEmail} email={email} />}
                        />
                        <Route
                            path="/forgot-password/email-sent"
                            element={<ForgotPasswordEmailSentScreen email={email} />}
                        />
                    </Routes>
                </View>
            </CSSTransition>
        </TransitionGroup>
    );
}

export interface SignupOrLoginPageProps {
    formData: AuthFormModuleSerializer;
    authType: AuthType;
    nextUrl: string;
    reason: string;
    productId: string;
    designerId: string;
    searchQuery: string;
    searchGender: string;
}

export function SignupOrLoginPage({ ...props }: SignupOrLoginPageProps): JSX.Element {
    const [showProposition, setShowProposition] = useState(true);
    return (
        <View paddingX={{ xl: "lg" }} className={styles.contentWrapper}>
            <View className={styles.flex}>
                <View className={styles.screenWrapper}>
                    <MemoryRouter>
                        <SignupOrLoginRoutes setShowProposition={setShowProposition} {...props} />
                    </MemoryRouter>
                </View>
                <View className={styles.proposition}>
                    <Conditional check={showProposition}>
                        <ValuePropositionAnimation />
                    </Conditional>
                </View>
            </View>
        </View>
    );
}

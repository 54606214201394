import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setDetailedFilterCount, setFilterCount } from "web/redux/ducks/feed-sidebar";
import { AllSelectedFilterInfo } from "web/types/feed-filters";

/**
 * Keeps the number in the filter button up to date
 */
export function useFilterCountHandlerLegacy(selectedFilters: number): void {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(setFilterCount(selectedFilters));
    }, [dispatch, selectedFilters]);
}

export function useDetailedFilterCountHandler(selectedFilterInfo: AllSelectedFilterInfo): void {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(setDetailedFilterCount(selectedFilterInfo));
    }, [dispatch, selectedFilterInfo]);
}

import clsx from "clsx";
import React from "react";
import {
    mapResponsiveValue,
    ResponsiveValue,
    sprinkles,
    Sprinkles,
} from "web/react/emo/sprinkles.css";
import { spacing } from "web/react/emo/theme.css";
import * as styles from "./stack.css";

export const alignToFlex = {
    start: "flex-start",
    center: "center",
    end: "flex-end",
    stretch: "stretch",
} as const;

export const justifyToFlex = {
    start: "flex-start",
    center: "center",
    end: "flex-end",
    "space-between": "space-between",
    "space-around": "space-around",
    "space-evenly": "space-evenly",
    stretch: "stretch",
} as const;

export interface StackProps {
    direction: Sprinkles["flexDirection"];
    spacing?: keyof typeof spacing;
    align?: ResponsiveValue<keyof typeof alignToFlex>;
    justify?: ResponsiveValue<keyof typeof justifyToFlex>;
    wrap?: boolean;
    children?: React.ReactNode;
    dataTestId?: string;
    id?: string;
}

export function Stack({
    children,
    direction,
    align,
    justify,
    spacing,
    wrap = false,
    dataTestId,
    id,
}: StackProps): JSX.Element {
    return (
        <div data-testid={dataTestId}>
            <div
                id={id}
                className={clsx(
                    styles.stack,
                    spacing && styles.gap[spacing],
                    sprinkles({
                        justifyContent:
                            justify && mapResponsiveValue(justify, (value) => justifyToFlex[value]),
                        flexDirection: direction,
                        flexWrap: wrap ? "wrap" : "nowrap",
                        alignItems:
                            align && mapResponsiveValue(align, (value) => alignToFlex[value]),
                    })
                )}
            >
                {children}
            </div>
        </div>
    );
}

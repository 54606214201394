import { UniversalFilterOptionSerializer } from "web/types/serializers";
import { BaseFilterOption, UniversalFiltersData } from "web/types/universal-filters";

export class PendingFilters extends Map<string, boolean> {
    public getKey(name: string, value: string): string {
        return name + "=" + value;
    }

    public getChecked(filter: BaseFilterOption | UniversalFilterOptionSerializer): boolean {
        let value = this.get(this.getKey(filter.name, filter.value.toString()));

        if (value == null) {
            return filter.checked;
        }

        return value;
    }

    public setChecked(name: string, value: string, state: boolean): void {
        let key = this.getKey(name, value);
        let existing = this.get(key);
        if (existing == null) {
            this.set(key, state);
            return;
        } else if (state == existing) {
            return;
        }
        // If the user selects something and then unselects it, we don't want to
        // keep it in the pending object to reduce the complexity of updating
        // our query to the server.
        this.delete(key);
    }

    public hasPending(): boolean {
        return this.size > 0;
    }

    public toQuery(displayedFilters: UniversalFiltersData, useCustomQuery = true): URLSearchParams {
        // This is the query for the current menu state of filters that we send to
        //  Rothko for an updated filter set and for updating feeds.
        let filterQuery = new URLSearchParams(displayedFilters.query_string);
        let fullFilterQuery;
        if (useCustomQuery) {
            // Use the query string that doesn't contain feed pre filters if the page is a custom shop
            fullFilterQuery = filterQuery;
            filterQuery = new URLSearchParams(displayedFilters.custom_query_string);
        }
        for (let [pendingKey, pendingChecked] of this.entries()) {
            let [pendingName, pendingValue] = pendingKey.split("=");
            if (
                !pendingChecked &&
                fullFilterQuery?.get(pendingName) &&
                !filterQuery.get(pendingName)
            ) {
                // Make sure that if you remove a pre_filter from the
                // query string of a custom shop you land on an explore page
                fullFilterQuery.set("feed_url", "/explore/");
                filterQuery = fullFilterQuery;
            }
            if (pendingChecked) {
                filterQuery.append(pendingName, pendingValue);
            } else {
                filterQuery.deleteValue(pendingName, pendingValue);
            }
        }
        return filterQuery;
    }
}

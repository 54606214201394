import { assignInlineVars } from "@vanilla-extract/dynamic";
import clsx from "clsx";
import React, { forwardRef } from "react";
import {
    ResponsiveHeadingStyle,
    ResponsiveTextStyle,
    sprinkles,
    Sprinkles,
} from "web/react/emo/sprinkles.css";
import * as styles from "web/react/emo/typography.css";

export interface BaseTextProps {
    className?: string;
    color?: Sprinkles["color"];
    align?: Sprinkles["textAlign"];
    backgroundColor?: Sprinkles["backgroundColor"];
    upperCase?: boolean;
    strikeThrough?: boolean;
    underline?: boolean;
    textStyle: ResponsiveTextStyle | ResponsiveHeadingStyle;
    truncate?: boolean;
    lineLimit?: number;
    as?: "p" | "span" | "div" | "h1" | "h2" | "h3" | "h4" | "h5" | "h6";
    children: React.ReactNode;
    linkReset?: boolean;
    wordBreak?: boolean;
}

const BaseText = forwardRef(
    (
        {
            className,
            textStyle,
            align,
            upperCase,
            strikeThrough,
            underline,
            color,
            backgroundColor,
            as: Component = "div",
            children,
            truncate,
            lineLimit,
            linkReset = true,
            wordBreak = false,
        }: BaseTextProps,
        ref
    ): JSX.Element => {
        return (
            <Component
                ref={ref as React.RefObject<any>}
                className={clsx(
                    className,
                    sprinkles({
                        color,
                        textAlign: align,
                        textStyle,
                        backgroundColor,
                    }),
                    {
                        [styles.noSpacing]:
                            typeof Component === "string" && /^(p|h[1-6]$)/.test(Component),
                        [styles.truncate]: truncate,
                        [styles.lineClamp]: lineLimit,
                        [styles.upperCase]: upperCase,
                        [styles.strikeThrough]: strikeThrough,
                        [styles.linkReset]: linkReset,
                        [styles.underline]: underline,
                        [styles.wordBreak]: wordBreak,
                    }
                )}
                style={assignInlineVars({
                    ...(lineLimit && { [styles.lineClampVar]: String(lineLimit) }),
                })}
            >
                {children}
            </Component>
        );
    }
);

BaseText.displayName = "BaseText";
export { BaseText };

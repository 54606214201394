import yahooGemini from "web/script/embedded/yahoo-gemini";

export default {
    projectId: "10000",
    pixelId: "10008060",

    send(data) {
        // this will contain any promises we generate
        let promises = [];

        data.forEach((event) => {
            if (event.type === "page_view") {
                let yahooPayload = {
                    projectId: this.projectId,
                    properties: {
                        pixelId: this.pixelId,
                    },
                };
                if (event.data.product_id) {
                    yahooPayload.properties = {
                        pixelId: this.pixelId,
                        qstrings: {
                            et: "custom",
                            ea: "ViewProduct",
                            product_id: event.data.product_id,
                        },
                    };
                }
                yahooGemini(yahooPayload);
            }
        });

        return Promise.all(promises);
    },
};

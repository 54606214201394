import { useEffect } from "react";
import { useSelector as useReduxSelector } from "react-redux";
import { ReduxStoreState } from "web/redux/store";
import history from "web/script/utils/history";

function extractSortState(state: ReduxStoreState): string | undefined {
    return state.feedFiltersReducer.view;
}

/**
 * The current sorting of the feeds is handled by a baustein component that
 *  pushes updates into Redux.
 * Currently the universal filters then looks after that value - however I'm
 *  _not_ going to look after it because it's not a filter and it makes my life
 *  a lot easier if it's just an extra URL query that I have to remember to add
 *  when updating the feeds URL for the current filter selection.
 * That does mean that something needs to keep the URL up to date for changes,
 *  but since sorting is only ever a query parameter and won't be part of a
 *  SEO-friendly URL I can just add a useEffect hook here to update things for
 *  me when it changes.
 */
export function useSortingHandler(): string | undefined {
    const feedSortingState = useReduxSelector(extractSortState);

    useEffect(() => {
        // TODO: Currently the redux store is one-way but if the user opens a
        // page with a sorting parameter in the URL they would expect the
        // sorting dropdown to have that option selected...
        if (feedSortingState != null) {
            history.updateQuery("view", feedSortingState);
        }
    }, [feedSortingState]);

    return feedSortingState;
}

import _isEmpty from "lodash/isEmpty";
import environment from "web/script/modules/environment";

/**
 * Generate Event data
 */
export function generateEventData(
    category,
    action,
    label = "",
    nonInteraction = false,
    customData = {},
    subType = "",
    checkoutData = {},
    itemData = [],
    truncate
) {
    // make sure category, action, subType are all lowercase
    category = category.toLowerCase();
    action = action.toLowerCase();
    subType = subType.toLowerCase();

    // TODO remove this truncation as it is legacy
    if (category && truncate) {
        category = category.substring(0, 16);
    }

    // TODO remove this truncation as it is legacy
    if (action && truncate) {
        action = action.substring(0, 64);
    }

    // TODO remove this truncation as it is legacy
    if (label && truncate) {
        label = (label + "").substring(0, 64);
    } else if (label == null || label === false) {
        label = "";
    }

    // validate input subType to be dot separated without repeating dots
    // this.is.valid, soisthis, this..is.not, nor.is.this.
    subType =
        subType && /^(?!.*[.*]{2})[a-z0-9*_:-]+(?:\.?[a-z0-9*_:-]+)+$/gi.test(subType)
            ? subType
            : "";

    let eventData = {
        // metadata
        _version: "0.15.0",
        _type: "events",
        _subtype: subType || `${category}.${action}`,

        // events
        category: category,
        action: action,
        label: label,
        // RedShift doesn't like uppercase but GA wants it, unify when removing GA
        nonInteraction: nonInteraction,
        noninteraction: nonInteraction,

        page_type: environment.get("pageType"),
        page_sub_type: environment.get("pageSubType"),

        customData: customData,
    };

    if (!_isEmpty(checkoutData)) {
        eventData["checkout_data"] = checkoutData;
    }

    if (!_isEmpty(itemData)) {
        eventData["item_data"] = itemData;
    }

    return eventData;
}

import PropTypes from "prop-types";

export const LanguageChoicePropType = PropTypes.shape({
    is_selected: PropTypes.bool.isRequired,
    language_name: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
});

export interface LanguageChoicePropInterface {
    is_selected: boolean;
    language_name: string;
    url: string;
}

import clsx from "clsx";
import React, { Children } from "react";
import TabSwitcherButton from "./tab-switcher-button";
import styles from "./tab-switcher-header.module.css";

interface TabSwitcherHeaderProps {
    children: React.ReactElement | React.ReactElement[];
    className?: string;
}

function TabSwitcherHeader({ children, className }: TabSwitcherHeaderProps): React.ReactElement {
    return (
        <div className={clsx(styles.tabSwitcher, className)}>
            <div className={styles.overflowContainer}>
                <ul className={styles.tabs} role="tablist" aria-orientation="horizontal">
                    {Children.map(children, (child, index) => {
                        if (!child || child.type !== TabSwitcherButton) {
                            throw "You must only render <TabSwitcher.Tab> in <TabSwitcher.Header>";
                        }

                        return React.cloneElement(child, { index });
                    })}
                </ul>
            </div>
        </div>
    );
}

export default TabSwitcherHeader;

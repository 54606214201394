import clsx from "clsx";
import React, { useState } from "react";
import { useDispatch, useSelector as useReduxSelector } from "react-redux";
import { useNavigationContext } from "web/react/components/navigation/navigation.context";
import withReduxProvider from "web/react/redux-provider";
import { closeSearch } from "web/redux/ducks/app-header-bar";
import { triggerBanner } from "web/redux/ducks/follow-designer-banner";
import { MainNavigation } from "web/redux/ducks/main-navigation";
import { ReduxStoreState } from "web/redux/store";
import styles from "./main-navigation-toggle-mobile.module.css";

const MENU_OPENED = "opened";
const MENU_CLOSED = "closed";

function extractNavigation(state: ReduxStoreState): MainNavigation {
    return state.mainNavigationReducer;
}

function selectIsBannerVisible(state: ReduxStoreState): boolean {
    return state.followDesignerBannerReducer.show;
}

export function MainNavigationToggleMobile(): React.ReactElement {
    const [shouldShowBanner, setShouldShowBanner] = useState(false);
    const { selectedGender } = useReduxSelector(extractNavigation);
    const bannerVisible = useReduxSelector(selectIsBannerVisible);

    const dispatch = useDispatch();

    const { showMenu, setShowMenu, showSearch, setShowSearch, handleMenuOpenedAnalytics } =
        useNavigationContext();

    function sendAnalytics(menuVisibility): void {
        const gender = selectedGender || "women";
        handleMenuOpenedAnalytics(menuVisibility, gender);
    }

    function toggleVisibility(): void {
        if (showMenu) {
            if (shouldShowBanner && !bannerVisible) {
                dispatch(triggerBanner(true));
            }
            setShowMenu(null);
            document.body.classList.remove("no-scroll");
            sendAnalytics(MENU_CLOSED);
        } else {
            if (bannerVisible) {
                setShouldShowBanner(true);
                dispatch(triggerBanner(false));
            }
            setShowMenu("mobile");
            setShowSearch(null);
            dispatch(closeSearch());
            document.body.classList.add("no-scroll");
            sendAnalytics(MENU_OPENED);
        }
    }

    return (
        <button
            data-testid="navigation-toggle-mobile"
            className={clsx(showMenu ? styles.burgerCross : styles.burgerContainer, {
                [styles.iconGrey]: showSearch,
            })}
            onClick={toggleVisibility}
        >
            <div className={styles.burgerBar1} />
            <div className={styles.burgerBar2} />
            <div className={styles.burgerBar3} />
        </button>
    );
}

export default withReduxProvider(MainNavigationToggleMobile);

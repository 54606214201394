import environment from "web/script/modules/environment";

function defaultEnabledCheck() {
    return environment.get("disable_third_party_js") !== true;
}

export default function (fn, isEnabled = defaultEnabledCheck) {
    let loaded = false;

    return function loader() {
        window.removeEventListener("load", loader, false);

        if (loaded || !isEnabled()) {
            return;
        }
        if (document.readyState === "complete") {
            loaded = true;
            fn();
        } else {
            window.addEventListener("load", loader, false);
        }
    };
}

import { configureStore } from "@reduxjs/toolkit";
import filters from "web/redux/ducks/filters";
import appHeaderBarReducer from "./ducks/app-header-bar";
import cookieConsentReducer from "./ducks/cookie-consent";
import customerCaptureOverlay from "./ducks/customer-capture-overlay";
import feed from "./ducks/feed";
import feedBreadcrumbs from "./ducks/feed-breadcrumbs";
import feedCount from "./ducks/feed-count";
import feedFiltersReducer from "./ducks/feed-filters";
import feedHeader from "./ducks/feed-header";
import feedMoreLikeThis from "./ducks/feed-more-like-this";
import feedShowMore from "./ducks/feed-show-more";
import feedSidebarReducer from "./ducks/feed-sidebar";
import followDesignerBannerReducer from "./ducks/follow-designer-banner";
import mainNavigationReducer from "./ducks/main-navigation";
import sendToAppModalReducer from "./ducks/send-to-app-modal";
import shippingAndReturnsOverlayReducer from "./ducks/shipping-and-returns-overlay";
import signupOverlayReducer from "./ducks/signup-overlay";
import toasts from "./ducks/toasts";
import topBannerReducer from "./ducks/top-banner";

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export function configureLystStore(preloadedState?: any) {
    return configureStore({
        reducer: {
            appHeaderBarReducer,
            cookieConsentReducer,
            customerCaptureOverlay,
            feed,
            feedBreadcrumbs,
            feedCount,
            feedFiltersReducer,
            feedHeader,
            feedMoreLikeThis,
            feedShowMore,
            feedSidebarReducer,
            filters,
            followDesignerBannerReducer,
            mainNavigationReducer,
            sendToAppModalReducer,
            shippingAndReturnsOverlayReducer,
            signupOverlayReducer,
            topBannerReducer,
            toasts,
        },
        preloadedState,
    });
}

const store = configureLystStore();

export type ReduxStoreState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;

import React from "react";
import ProductCard from "web/react/components/product-card/react/product-card";
import { HStack } from "web/react/emo/h-stack";
import {
    ProductCardDetails,
    ProductCardPrice,
    ProductCardWishlist,
    ProductImage,
} from "web/react/emo/product-card";
import { VStack } from "web/react/emo/v-stack";
import { useStoryAnalytics } from "web/react/hooks/use-story-analytics";
import { ProductCardSerializer } from "web/types/serializers";
import * as styles from "./story-product-card.css";

interface StoryProductCardProps {
    product: ProductCardSerializer;
    position: number;
    railPosition: number;
}
export function StoryProductCard({
    product,
    position,
    railPosition,
}: StoryProductCardProps): JSX.Element {
    const { containerRef, sendClickEvent } = useStoryAnalytics({
        label: "product",
        position,
        isSponsored: false,
        railPosition,
        productId: product.id,
        buyOptionId: product.link_id,
    });

    return (
        <div ref={containerRef} className={styles.productCard} data-testid={"story-product-card"}>
            <ProductCard product={product}>
                <VStack spacing="sm">
                    <div data-testid="story-product-image" onClick={() => sendClickEvent("image")}>
                        <ProductImage width={300} height={379} shouldRenderLeadLinkInImage />
                    </div>
                    <VStack spacing="xxs">
                        <HStack justify="space-between">
                            <ProductCardPrice size={"lg"} style={"secondary"} />
                            <ProductCardWishlist
                                beforeOnClick={() => sendClickEvent("link")}
                                isSavedForLater={product.is_saved_for_later}
                            />
                        </HStack>
                        <ProductCardDetails beforeOnClick={() => sendClickEvent("link")} />
                    </VStack>
                </VStack>
            </ProductCard>
        </div>
    );
}

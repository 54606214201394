import React from "react";
import { FeedHeaderWithLogoProps } from "web/react/components/feed-header-with-logo/feed-header-with-logo";
import { Heading } from "web/react/emo/heading";
import { Text } from "web/react/emo/text";
import { VStack } from "web/react/emo/v-stack";
import { useDomViewport } from "web/react/hooks/use-dom-viewport/use-dom-viewport";

export function FeedHeaderTitle({
    type,
    title,
    subtitle,
}: Pick<FeedHeaderWithLogoProps, "type" | "title" | "subtitle">): JSX.Element {
    const { isDesktopViewport } = useDomViewport();

    const subtitleElement = subtitle && (
        <Text as={"span"} textStyle={"caption-1"}>
            {subtitle}
        </Text>
    );

    if (type === "RELATED_PRODUCTS") {
        return (
            <div data-testid="feed-header-title">
                <VStack spacing="xxs">
                    {subtitleElement}
                    <Heading textStyle={{ sm: "title-2", lg: "large-title-2" }} linkReset={false}>
                        {title}
                    </Heading>
                </VStack>
            </div>
        );
    }

    return (
        <VStack spacing="xxs">
            <div data-testid="feed-header-title">
                <Heading
                    textStyle={{
                        sm: "title-2",
                        lg: isDesktopViewport ? "large-title" : "large-title-2",
                    }}
                >
                    {title}
                </Heading>
            </div>
            {subtitleElement}
        </VStack>
    );
}

import { Form, Formik, FormikErrors, FormikHelpers } from "formik";
import React from "react";
import { useNavigate } from "react-router-dom";
import { SocialAuthButtons } from "web/react/components/buttons/social-auth-button";
import { Divider } from "web/react/components/divider";
import { FormikTextField } from "web/react/components/forms/formik";
import { LegalText } from "web/react/components/soft-signup-dialog/soft-signup-form";
import { Button } from "web/react/emo/button";
import { Heading } from "web/react/emo/heading";
import { VStack } from "web/react/emo/v-stack";
import { AuthType } from "web/redux/ducks/customer-capture-overlay";
import analytics from "web/script/analytics/analytics";
import { gettext } from "web/script/modules/django-i18n";
import environment from "web/script/modules/environment";
import requester from "web/script/modules/requester";
import { validateEmail } from "web/script/utils/validate";
import { AuthFormModuleSerializer } from "web/types/serializers";

type InitialScreenFormValues = {
    email: string;
};

interface InitialScreenFormProps {
    email?: string;
    onSuccess: (value) => void;
}

function InitialScreenForm({ email, onSuccess }: InitialScreenFormProps): JSX.Element {
    const navigate = useNavigate();

    const initialValues: InitialScreenFormValues = {
        email: email ?? "",
    };

    async function onSubmit(
        values: InitialScreenFormValues,
        actions: FormikHelpers<InitialScreenFormValues>
    ): Promise<void> {
        actions.setSubmitting(true);

        try {
            const response = await requester.post(`/account/register/verify-email/`, values as any);

            if (response && response.email_check) {
                onSuccess(values.email);
                return navigate("/login");
            }

            if (response && !response.email_check) {
                onSuccess(values.email);
                return navigate("/signup");
            }

            throw new Error(response.data?.errors?.join?.("\n") ?? "Unknown error");
        } catch (e) {
            analytics.event("signup_or_login", "submit", "server_error");
        }

        actions.setSubmitting(false);
    }

    function onClick(): void {
        analytics.event("signup_or_login", "click", "continue_button");
    }

    function validate(values: InitialScreenFormValues): FormikErrors<InitialScreenFormValues> {
        const errors: FormikErrors<InitialScreenFormValues> = {};

        if (!values.email) {
            analytics.event("signup_or_login", "missing", "field_email");
            errors.email = gettext("account.register.incorrect_email.label");
        } else if (!validateEmail(values.email)) {
            analytics.event("signup_or_login", "invalid", "field_email");
            errors.email = gettext("account.register.incorrect_email.label");
        }

        return errors;
    }

    return (
        <Formik
            initialValues={initialValues}
            onSubmit={onSubmit}
            validateOnChange={false}
            validateOnBlur={false}
            validate={validate}
        >
            {({ isSubmitting }): JSX.Element => (
                <Form noValidate>
                    <VStack spacing="md">
                        <FormikTextField
                            // "Email"
                            label={gettext("settings.account.form.email_label")}
                            name="email"
                            type="email"
                            // "Enter your email address to login or register"
                            placeholder={gettext("account.register.form.enter_email.placeholder")}
                            onFocus={() => {
                                analytics.event("signup_or_login", "fill_field", "field_email");
                            }}
                            autoCapitalize="off"
                            autoFocus={false}
                            required={true}
                        />
                        <div data-testid="continue-button">
                            <Button
                                disabled={isSubmitting}
                                onClick={onClick}
                                type="submit"
                                width="full"
                                title={
                                    /* Continue */
                                    gettext("general.continue")
                                }
                            />
                        </div>
                    </VStack>
                </Form>
            )}
        </Formik>
    );
}

interface InitialScreenProps {
    formData: AuthFormModuleSerializer;
    authType: AuthType;
    nextUrl: string;
    reason: string;
    productId: string;
    designerId: string;
    searchQuery: string;
    searchGender: string;
    email: string;
    onSuccess: (value) => void;
}

export function InitialScreen({
    formData,
    onSuccess,
    email,
    ...props
}: InitialScreenProps): JSX.Element {
    const socialAuthProps = {
        eventCategory: formData.event_category,
        isLogin: formData.is_login,
        isRegistration: formData.is_registration,
        ...props,
    };

    const requiresOptIn = environment.get("country") !== "US";

    return (
        <VStack spacing={"md"}>
            <Heading textStyle={{ lg: "large-title", sm: "large-title-2" }} as="h1">
                {/* "The only fashion website you'll need" */}
                {gettext("account.register.only_fashion_website.title")}
            </Heading>
            <InitialScreenForm onSuccess={onSuccess} email={email} />
            <VStack spacing="sm">
                <Divider>
                    <>
                        {/* or */}
                        {gettext("general.or")}
                    </>
                </Divider>
                <SocialAuthButtons {...socialAuthProps} />
                <LegalText requiresOptIn={requiresOptIn} />
            </VStack>
        </VStack>
    );
}

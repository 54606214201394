import clsx from "clsx";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Button } from "web/react/emo/button";
import { Heading } from "web/react/emo/heading";
import { Text } from "web/react/emo/text";
import { VStack } from "web/react/emo/v-stack";
import { View } from "web/react/emo/view";
import { useAboveFold } from "web/react/hooks/use-above-fold/use-above-fold";
import analytics from "web/script/analytics/analytics";
import { gettext } from "web/script/modules/django-i18n";
import rothko from "web/script/modules/rothko";
import { useSendImpression } from "web/script/utils/ads";
import { BrandAdSerializer } from "web/types/serializers";
import * as styles from "./brand-ad-container.css";

const ENDPOINT = "modules/brand_ads";

export type BrandAdEvent = {
    action: "loaded" | "click" | "impression";
    area: string;
    placement: Placement | null;
    retailerNetwork: BrandAdSerializer["retailer_network"];
    campaignId: BrandAdSerializer["campaign_id"];
    flightId: BrandAdSerializer["flight_id"];
    adId: BrandAdSerializer["ad_id"];
    creativeId: BrandAdSerializer["creative_id"];
    isAboveFold: boolean;
    isInternalAd: BrandAdSerializer["is_internal"];
};

type Placement = {
    name: BrandAdSerializer["placement_name"] | null;
    "co-ordinates": {
        "x-axis": number | null;
        "y-axis": number | null;
    };
    dimension: {
        width: number | null;
        height: number | null;
    };
    viewport: {
        width: number | null;
        height: number | null;
    };
};

export function getPlacement(
    element: HTMLDivElement,
    placementName: BrandAdSerializer["placement_name"]
): Placement {
    const coordinates: Placement["co-ordinates"] = {
        "x-axis": null,
        "y-axis": null,
    };

    const dimension: Placement["dimension"] = {
        width: null,
        height: null,
    };

    const viewport: Placement["viewport"] = {
        width: null,
        height: null,
    };

    if (window) {
        const domReact = element.getBoundingClientRect();
        const documentEl = document.documentElement.getBoundingClientRect();

        coordinates["y-axis"] = domReact.top - documentEl.top;
        coordinates["x-axis"] = domReact.x;

        dimension["height"] = domReact.height;
        dimension["width"] = domReact.width;

        viewport["height"] = window.innerHeight;
        viewport["width"] = window.innerWidth;
    }

    return {
        name: placementName || null,
        "co-ordinates": coordinates,
        dimension,
        viewport,
    };
}

export function sendEvent({
    action,
    area,
    retailerNetwork,
    campaignId,
    flightId,
    creativeId,
    isAboveFold,
    isInternalAd,
    adId,
    placement = null,
}: BrandAdEvent): void {
    analytics.event(
        "brand_ads", // category
        action,
        "",
        action !== "click", // nonInteraction
        {}, // customData
        "", // subType
        {}, // checkoutData
        [
            // itemData
            {
                item_type: "ad_brand",
                retailer_network: retailerNetwork,
                campaign_id: String(campaignId),
                flight_id: String(flightId),
                creative_id: String(creativeId),
                ad_id: String(adId),
                area,
                above_fold: isAboveFold,
                internal: isInternalAd,
                placement,
            },
        ]
    );
}

export interface BrandAdContainerProps extends BrandAdSerializer {
    onOverlay?: boolean;
}

export function BrandAdContainer({
    desktop_asset_url: desktopAssetUrl,
    mobile_asset_url: mobileAssetUrl,
    link,
    click_url: clickUrl,
    impression_url: impressionUrl,
    partner,
    retailer_network: retailerNetwork,
    campaign_id: campaignId,
    flight_id: flightId,
    creative_id: creativeId,
    ad_id: adId,
    is_internal: isInternalAd,
    placement_name: placementName,
    onOverlay = false,
    title,
    caption,
    button_cta: buttonCta,
}: BrandAdContainerProps): null | JSX.Element {
    const containerRef = useRef<HTMLDivElement>(null);

    const { isAboveFold, isVisible } = useAboveFold(containerRef);
    const [isLoaded, setIsLoaded] = useState(false);

    const { shouldSendImpression, setImpressionSent } = useSendImpression(isVisible, onOverlay);

    const sendKevelTracking = useCallback(
        (isClick: boolean): void => {
            const type = "pba";
            const url = isClick ? clickUrl : impressionUrl;
            if (url) {
                const clicked = isClick ? "true" : "false";
                rothko.fetch(ENDPOINT, { url, clicked, type }, { cache: false });
            }
        },
        [clickUrl, impressionUrl]
    );

    const sendAnalyticsEvent = useCallback(
        (action, area, placementName?) => {
            const placement =
                containerRef.current && getPlacement(containerRef.current, placementName);

            sendEvent({
                action,
                area,
                retailerNetwork,
                campaignId,
                flightId,
                creativeId,
                isAboveFold,
                isInternalAd,
                adId,
                placement,
            });
        },
        [retailerNetwork, campaignId, flightId, creativeId, adId, isAboveFold, isInternalAd]
    );

    function onMainButtonClick(): void {
        sendKevelTracking(true);
        sendAnalyticsEvent("click", "banner", placementName);
    }

    function onTextAreaClick(): void {
        sendKevelTracking(true);
        sendAnalyticsEvent("click", "title & caption");
    }

    function onShopNowBtnClick(): void {
        sendKevelTracking(true);
        sendAnalyticsEvent("click", "call to action");
    }

    // "Curated by Lyst" : "Sponsored by {partner}"
    const heading = isInternalAd
        ? gettext("curated_ads.curated_by_lyst")
        : gettext("in_stock_product.sponsored_by_retailer", { retailer_name: partner });

    useEffect(() => {
        if (!isLoaded) {
            sendAnalyticsEvent("loaded", "no interaction", placementName);
            setIsLoaded(true);
        }
    }, [isLoaded, sendAnalyticsEvent, placementName]);

    useEffect(() => {
        if (shouldSendImpression) {
            sendKevelTracking(false);
            setImpressionSent(true);
            sendAnalyticsEvent("impression", "no interaction", placementName);
        }
    }, [
        placementName,
        sendKevelTracking,
        sendAnalyticsEvent,
        setImpressionSent,
        shouldSendImpression,
    ]);

    return (
        <div ref={containerRef} className={clsx(styles.container)}>
            <div className={styles.partnerAdsLeftArea}>
                <a
                    href={link}
                    target="_blank"
                    onClick={onTextAreaClick}
                    rel="noreferrer"
                    className={styles.partnerAds}
                >
                    <VStack spacing="xs">
                        <Text textStyle="caption-2" color="secondaryBrand" upperCase>
                            {heading}
                        </Text>
                        <Heading textStyle="title-2" as="h3">
                            {title}
                        </Heading>
                        <Text textStyle="footnote" color="secondary">
                            {caption}
                        </Text>
                    </VStack>
                </a>
                <a
                    href={link}
                    target="_blank"
                    onClick={onShopNowBtnClick}
                    rel="noreferrer"
                    className={styles.partnerAdsButtonContainer}
                >
                    <Button title={buttonCta} variant="secondary" />
                </a>
            </div>
            <View className={styles.bannerContainer}>
                <a
                    href={link}
                    className="main-tracked-button"
                    target="_blank"
                    onClick={onMainButtonClick}
                    rel="noreferrer"
                >
                    {/*
                        Note for future changes: If something regarding the media queries
                        in images load or the picture sources change please update `base_page.jinja`
                        as well since we are preloading them conditionally.
                    */}
                    <picture>
                        <source media="(max-width: 575px)" srcSet={mobileAssetUrl} />
                        <source media="(min-width: 576px)" srcSet={desktopAssetUrl} />
                        <img
                            className={clsx(styles.containerImage)}
                            src={desktopAssetUrl}
                            alt={`${partner} banner`}
                        />
                    </picture>
                </a>
            </View>
        </div>
    );
}

import clsx from "clsx";
import React from "react";
import Button, { ButtonProps } from "web/react/components/__LEGACY__/LEGACY_button/button";
import styles from "./multiply-on-hover-button.module.css";

type MultiplyOnHoverButtonProps = Pick<
    ButtonProps,
    | "className"
    | "href"
    | "onClick"
    | "disabled"
    | "fullWidth"
    | "fullHeight"
    | "openInNewTab"
    | "children"
>;

export default function MultiplyOnHoverButton({
    className,
    href,
    onClick,
    disabled,
    fullWidth,
    fullHeight,
    openInNewTab,
    children,
}: MultiplyOnHoverButtonProps): JSX.Element {
    return (
        <Button
            href={href}
            styleType="cta"
            className={clsx(className, styles.buttonMultiply, {
                [styles.disabled]: disabled,
            })}
            onClick={onClick}
            disabled={disabled}
            fullWidth={fullWidth}
            fullHeight={fullHeight}
            openInNewTab={openInNewTab}
        >
            <div className={clsx(styles.multiplyLabel, styles.topLabel)}>{children}</div>
            <div className={clsx(styles.multiplyLabel, styles.bottomLabel)}>{children}</div>
            <div className={clsx(styles.multiplyLabel, styles.centerLabel)}>{children}</div>
        </Button>
    );
}

import React from "react";
import SVGIcon, { SVGIconName } from "web/react/components/svg-icon/svg-icon";
import { HStack } from "web/react/emo/h-stack";
import { Text } from "web/react/emo/text";
import { View } from "web/react/emo/view";
import { gettext } from "web/script/modules/django-i18n";
import * as styles from "./sticker.css";

export interface BaseStickerProps {
    variant: keyof typeof styles.sticker;
    label: string | JSX.Element;
    iconName?: Extract<
        SVGIconName,
        "emo-basket" | "emo-tick" | "emo-star" | "promo-tag" | "tag-outline"
    >;
}

export function BaseSticker({ variant, label, iconName }: BaseStickerProps): JSX.Element {
    return (
        <View paddingX="xxs" paddingY="xxxs" borderRadius="md" className={styles.sticker[variant]}>
            <HStack spacing="xxxs" align="center">
                {iconName && <SVGIcon name={iconName} />}
                <Text as="span" textStyle="footnote-small" className={styles.stickerCaption}>
                    {label}
                </Text>
            </HStack>
        </View>
    );
}

export function PromoTag({
    onStickerClick,
    hide = true,
}: {
    onStickerClick?: () => void;
    hide?: boolean;
}): JSX.Element {
    const onClick = (): void => {
        onStickerClick?.();
    };
    return (
        <button onClick={onClick}>
            <BaseSticker
                variant="promo"
                label={
                    hide
                        ? gettext("product.buy_area.hide_offer.link") // "Hide offer"
                        : gettext("product.buy_area.offer_available.link") // "Offer available"
                }
                iconName="promo-tag"
            />
        </button>
    );
}

export function LowestPrice(): JSX.Element {
    return (
        <BaseSticker
            variant="secondary"
            label={
                gettext("product.buy_area.lowest_price.label") // "Lowest Price"
            }
            iconName="emo-star"
        />
    );
}

export function PromotionCode({ label }: StickerProps): JSX.Element | null {
    if (!label) {
        return null;
    }
    return <BaseSticker variant="secondary" label={label} iconName="tag-outline" />;
}

export function OfficialStore(): JSX.Element {
    return (
        <BaseSticker
            variant="tertiary"
            label={
                gettext("product.buy_area.official_store.label") // "Official store"
            }
            iconName="emo-tick"
        />
    );
}

export function TrustedPartner(): JSX.Element {
    return (
        <BaseSticker
            variant="tertiary"
            label={
                gettext("product.buy_area.trusted_partner.label") // "Trusted partner"
            }
            iconName="emo-tick"
        />
    );
}

export function CheckoutOnLyst(): JSX.Element {
    return (
        <BaseSticker
            variant="tertiary"
            label={
                gettext("product.buy_area.checkout_lyst.label") // "Checkout on Lyst"
            }
            iconName="emo-basket"
        />
    );
}

const Stickers = {
    lowestPrice: LowestPrice,
    promotionCode: ({ label, name }) => <PromotionCode label={label} name={name} />,
    officialStore: OfficialStore,
    trustedPartner: TrustedPartner,
    checkoutOnLyst: CheckoutOnLyst,
};

export type StickerType = keyof typeof Stickers;

export interface StickerProps {
    name?: StickerType;
    label?: string | JSX.Element;
}

/**
 * Design System
 * @name Sticker
 * @version 1.0
 * @design https://www.figma.com/file/VhOHx4tv9uYXZHXAAKPVYf/Design-System?node-id=6368%3A66245
 */
export function Sticker({ name, label }: StickerProps): JSX.Element {
    const Component = name && Stickers[name];
    return (
        <>
            {Component ? (
                <Component label={label} name={name} />
            ) : (
                <BaseSticker variant="secondary" label={label || ""} />
            )}
        </>
    );
}

import React from "react";
import { connect } from "react-redux";
import { KevelCampaignControl } from "web/react/components/kevel-campaign-control/kevel-campaign-control";
import { Button } from "web/react/emo/button";
import { useLeadLinkClick } from "web/react/hooks/use-lead-link-click/use-lead-link-click";
import withReduxProvider from "web/react/redux-provider";
import { openCustomerCaptureOverlay } from "web/redux/ducks/customer-capture-overlay";
import analytics from "web/script/analytics/analytics";
import { gettext } from "web/script/modules/django-i18n";
import userProfiler from "web/script/modules/userprofiler";
import { canUseMembership } from "web/script/modules/utils";

type ContextType = "express_checkout" | "shop_now" | "comparison_option";

interface ButtonStyles {
    buttonMultiply: string;
    buttonBuyNow: string;
}

interface BuyOnStoreButtonProps {
    leadLabel?: string;
    leadUrl: string;
    openCustomerCaptureOverlay: (captureOptions) => void;
    productId: string;
    reason?: string;
    beforeOnClick?: (callback, event) => void;
    bingTraffic?: boolean;
    analyticsEventLabel: string;
    analyticsCategoryLabel?: string;
    analyticsFunctionEventLabel?: string;
    productImageUrl?: string;
    contextType: ContextType;
    hasPromotion?: boolean;
    overrideStyles?: ButtonStyles;
    isAffiliate?: boolean;
    retailerSlug: string;
}

export function BuyOnStoreButton({
    leadUrl,
    leadLabel = gettext("product.buy_area.shop_now"),
    openCustomerCaptureOverlay,
    productId,
    reason = "buy-on-store-button",
    beforeOnClick,
    bingTraffic,
    analyticsEventLabel,
    analyticsCategoryLabel = "buy_area",
    analyticsFunctionEventLabel = "buy-on-store-button",
    productImageUrl,
    contextType,
    hasPromotion,
    isAffiliate,
    retailerSlug,
}: BuyOnStoreButtonProps): JSX.Element {
    function fireAnalyticsEvent(): void {
        analytics.event(analyticsCategoryLabel, "clicked", analyticsFunctionEventLabel);
    }

    function afterOnClick(extraContent): void {
        if (!userProfiler.isLoggedIn() && canUseMembership()) {
            openCustomerCaptureOverlay({
                productId: productId,
                captureType: "signup_lead_save",
                analyticsEventLabel: analyticsEventLabel || "buy_on_store_button",
                ...extraContent,
            });
        }
    }

    const { onClick: onLeadLinkClick, leadHref } = useLeadLinkClick({
        href: leadUrl,
        beforeOnClick: beforeOnClick,
        productId: productId,
        reason: reason,
        openInNewTab: true,
    });

    const button = (
        <Button
            variant={bingTraffic === false ? "secondary" : "primary"}
            title={leadLabel || gettext("product.buy_area.shop_now")}
            width="full"
            height="full"
        />
    );

    return (
        <KevelCampaignControl
            contextType={contextType}
            options={{
                action: "shop_now_button_click",
                hasPromotion,
                isAffiliate,
                retailerSlug,
            }}
            defaultAction={(event) => onLeadLinkClick(event)}
            analyticsEventLabel={analyticsEventLabel || "buy_on_store_button"}
            beforeOnClick={() => {
                fireAnalyticsEvent();
            }}
            afterOnClick={afterOnClick}
            productImageUrl={productImageUrl}
            continueOptionHref={leadHref}
        >
            <a href={leadHref} target={"_blank"} rel="noreferrer">
                {button}
            </a>
        </KevelCampaignControl>
    );
}

export default withReduxProvider(connect(null, { openCustomerCaptureOverlay })(BuyOnStoreButton));

import React from "react";
import * as styles from "./pagination-progress-bar.css";

interface PaginationProgressBar {
    currentNumber: number;
    maximumNumber: number;
}

export function PaginationProgressBar({
    currentNumber = 0,
    maximumNumber = 1,
}: PaginationProgressBar): JSX.Element {
    const progress = (currentNumber / maximumNumber) * 100;

    return (
        <div className={styles.progressBar} role="presentation">
            <span
                data-testid="progress-bar"
                className={styles.progressBarInner}
                style={{
                    width: progress + "%",
                }}
            />
        </div>
    );
}

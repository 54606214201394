import { createSlice } from "@reduxjs/toolkit";

interface AppHeaderBarSlice {
    isSearchOpen: boolean;
    isCalloutOpen: boolean;
}

const appHeaderBar = createSlice({
    name: "appHeaderSearch",
    initialState: {
        isSearchOpen: false,
        isCalloutOpen: false,
        fetchWishlist: true,
        isWishlistAnimating: false,
    } as AppHeaderBarSlice,
    reducers: {
        openSearch(state): void {
            state.isSearchOpen = true;
        },
        closeSearch(state): void {
            state.isSearchOpen = false;
        },
        openCallout(state): void {
            state.isCalloutOpen = true;
        },
        closeCallout(state): void {
            state.isCalloutOpen = false;
        },
    },
});

// Extract the action creators object and the reducer
const { actions, reducer } = appHeaderBar;
// Extract and export each action creator by name
export const { openSearch, closeSearch, openCallout, closeCallout } = actions;
// Export the reducer, either as a default or named export
export default reducer;

import React from "react";
import { Conditional } from "web/react/components/conditional";
import { useProductCardContext } from "web/react/components/product-card/react";
import { ProductCardLink } from "web/react/components/product-card/react/product-card-link";
import { Retailer, SponsoredByRetailer } from "web/react/emo/retailer/retailer";
import { BaseText } from "web/react/emo/shared/components/base-text";
import { Text } from "web/react/emo/text";
import { VStack } from "web/react/emo/v-stack";
import { View } from "web/react/emo/view";
import analytics from "web/script/analytics/analytics";
import { gettext } from "web/script/modules/django-i18n";
import * as styles from "./product-card-details.css";

interface ProductInfoProps {
    brandName: string;
    shortDescription: string | null;
    handleShortDescriptionClick: () => void;
}

function ProductInfo({
    brandName,
    shortDescription,
    handleShortDescriptionClick,
}: ProductInfoProps): JSX.Element {
    return (
        <VStack spacing="xxs">
            <BaseText as="span" textStyle="title-3-v2" color={"secondary"} lineLimit={1} upperCase>
                {brandName}
            </BaseText>
            <Conditional check={!!shortDescription}>
                <div onClick={handleShortDescriptionClick} className={styles.cursorPointer}>
                    <Text
                        as="span"
                        textStyle="footnote"
                        color={"secondary"}
                        lineLimit={2}
                        aria-description={gettext("product_card.product_description.label")}
                    >
                        {shortDescription}
                    </Text>
                </div>
            </Conditional>
        </VStack>
    );
}

interface ProductCardDetailsProps {
    showRetailer?: boolean;
    showPromo?: boolean;
    feedType?: string;
    analyticsEventLabel?: string;
    reason?: string;
    shouldRenderLink?: boolean;
    showSponsoredMessaging?: boolean;
    shouldRenderLeadLink?: boolean;
    beforeOnClick?: (any?) => void;
}

export function ProductCardDetails({
    showRetailer = true,
    showPromo = true,
    feedType,
    analyticsEventLabel,
    reason,
    shouldRenderLink = true,
    showSponsoredMessaging = false,
    shouldRenderLeadLink = false,
    beforeOnClick = () => {},
}: ProductCardDetailsProps): JSX.Element {
    const {
        designer_name: brandName,
        short_description: shortDescription,
        retailer_name: retailerName,
        sale_discount: saleDiscount,
        in_stock: inStock,
    } = useProductCardContext();

    const promo = getPromoLabel();

    // TODO CW-318 move this logic of getting the promo on the BE same as mobile_api
    function getPromoLabel(): string | undefined {
        if (!inStock) return gettext("in_stock_product.compare_prices.out_of_stock_option");
        if (saleDiscount) return gettext("product_card.promo_label.sale");
    }

    function handleProductLinkClick(): void {
        beforeOnClick?.();
        analytics.event("product_card", "product", analyticsEventLabel, false, {
            designer_name: brandName,
            retailer_name: retailerName,
            in_stock: inStock,
        });
    }

    function handleShortDescriptionClick(): void {
        analytics.event("product_card", "short description", analyticsEventLabel, false, {
            designer_name: brandName,
            retailer_name: retailerName,
            in_stock: inStock,
        });
    }

    function handleRetailerClick(): void {
        beforeOnClick?.();
        analytics.event("product_card", "retailer", analyticsEventLabel);
    }

    return (
        <View>
            <VStack spacing="xxs">
                <VStack spacing="xxs">
                    {shouldRenderLink ? (
                        <ProductCardLink
                            reason={reason || "text"}
                            onClick={handleProductLinkClick}
                            feedType={feedType}
                            predicate={shouldRenderLeadLink}
                        >
                            <ProductInfo
                                brandName={brandName}
                                shortDescription={shortDescription}
                                handleShortDescriptionClick={handleShortDescriptionClick}
                            />
                        </ProductCardLink>
                    ) : (
                        <ProductInfo
                            brandName={brandName}
                            shortDescription={shortDescription}
                            handleShortDescriptionClick={() => {
                                beforeOnClick?.();
                                handleShortDescriptionClick();
                            }}
                        />
                    )}
                    <Conditional check={Boolean(showRetailer && retailerName)}>
                        <div onClick={handleRetailerClick} data-testid="retailer">
                            <Text as="span" textStyle="caption-3" color={"secondary"}>
                                {showSponsoredMessaging ? (
                                    <SponsoredByRetailer retailerName={retailerName} />
                                ) : (
                                    <Retailer
                                        retailerName={retailerName as string}
                                        fromSpanStyle={"upperCase"}
                                        enableBoldRetailerName={false}
                                    />
                                )}
                            </Text>
                        </div>
                    </Conditional>
                </VStack>
                <Conditional check={Boolean(showPromo && promo)}>
                    <BaseText as="span" textStyle={"caption-2"} color={"secondaryBrand"} upperCase>
                        {promo}
                    </BaseText>
                </Conditional>
            </VStack>
        </View>
    );
}

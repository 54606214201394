import { useEffect, useState } from "react";
import history, { BrowserHistoryState } from "web/script/utils/history";

export function useOverlayWatcher<S extends BrowserHistoryState = BrowserHistoryState>(
    name: string
): S | false {
    let [overlayData, setOverlayData] = useState<BrowserHistoryState | false>(false);

    useEffect(() => history.watchOverlay(name, setOverlayData), [name]);

    return overlayData as S | false;
}

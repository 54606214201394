import React from "react";
import { useThirdPartyScript } from "web/react/hooks/use-third-party-script/use-third-party-script";

interface TrustpilotWidgetProps {
    isFooter?: boolean;
}

export function TrustpilotWidget({ isFooter }: TrustpilotWidgetProps): JSX.Element {
    useThirdPartyScript({
        cookieType: "analytics",
        sourceUrl: "//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js",
    });

    return (
        <div
            className="trustpilot-widget"
            data-locale="en-GB"
            data-template-id="53aa8807dec7e10d38f59f32"
            data-businessunit-id="4df6d77700006400050fea24"
            data-style-height={isFooter ? "150px" : "110px"}
            data-style-width="100%"
            data-theme="light"
        >
            <a
                href="https://uk.trustpilot.com/review/www.lyst.com"
                target="_blank"
                rel="noopener noreferrer"
            />
        </div>
    );
}

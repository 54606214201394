/* eslint-disable @typescript-eslint/no-non-null-assertion */

import { renderReact } from "@lyst/hypernova-react";
import * as Sentry from "@sentry/browser"; // eslint-disable-line import/no-namespace
import { Integrations } from "@sentry/tracing";
import baustein from "baustein";
import jinjaToJs from "jinja-to-js";
import pickBy from "lodash/pickBy";
import { Metric, onCLS, onFCP, onINP, onLCP, onTTFB } from "web-vitals";
import "web/entrypoints/common/polyfills";
import { withReactStrictMode } from "web/react/hoc/with-react-strict-mode";
import analytics from "web/script/analytics/analytics";
import environment from "web/script/modules/environment";
import globals from "web/script/modules/globals";
import { installJinjaFilters } from "web/script/modules/jinja-filters";
import { installJinjaGlobals } from "web/script/modules/jinja-globals";
import oneTrustCookie from "web/script/modules/one-trust-cookie";
import userProfiler from "web/script/modules/userprofiler";
import alerts from "web/script/utils/alerts";
import browser from "web/script/utils/browser";
import history from "web/script/utils/history";
import "./baustein-common";
import commonComponents from "./react-common";

export interface ReactComponentManifest {
    [name: string]: React.ComponentType<any>;
}

function setupSentry(): void {
    const { environment, data } = globals.window.Lyst!;
    const release = `web-frontend@${(environment?.release_sha || "").substring(0, 8)}`;

    Sentry.init({
        dsn: environment.sentryDsn,
        release,
        integrations: [new Integrations.BrowserTracing()],
        environment: environment.sentryEnvironment,
        tracesSampleRate: 0.0,
        // When the ad blocker is on, google conversion tag throws an error
        // when trying to send events. This will stop sending these errors
        // to Sentry.
        beforeSend: (event) => {
            try {
                const values = event.exception?.values || [];
                const frames = values[0]?.stacktrace?.frames || [];
                // just so as not to have to assert each link in the chain
                if (frames[0]?.filename?.includes("/pagead/viewthroughconversion/")) {
                    return null;
                }
            } catch (error) {
                console.error(error);
            }

            return event;
        },
    });

    Sentry.setTags({
        country: environment.country,
        language: environment.language,
        currency: environment.currencyProps?.currencyCode,
        page_sub_type: data?.pageSubType,
        page_type: data?.pageType,
        logged_in: environment.userLoggedIn ? "yes" : "no",
    });

    Sentry.setExtra("features", pickBy(data?.features));
}

export function sendAnalyticsMetricEvent(metric: Metric, timeoutMs = 2000): void {
    const subtype: string = "core_web_vitals.".concat(metric.name.toLowerCase());

    // we need to ensure the metric is serialized properly first.
    // It's possible some metrics contain circular references. In this case, just discard those keys.
    // See this issue: https://github.com/GoogleChrome/web-vitals/issues/77
    // Note: I may also want to update this function to handle e.g. LCP entries elements,
    // which are HTML elements that can't be stringified and will otherwise be completely
    // ommitted.
    let cache: any[] = [];
    let data = JSON.stringify({ ...metric, attribution: {} }, function (key, value) {
        if (typeof value === "object" && value !== null) {
            if (cache.indexOf(value) !== -1) {
                // Circular reference found, discard key
                return;
            }
            // Store value in our collection
            cache.push(value);
        }
        return value;
    });

    // Timeout promise that rejects after `timeoutMs` milliseconds
    const timeoutPromise = new Promise((_, reject) => {
        setTimeout(() => {
            reject(new Error("Analytics event timed out."));
        }, timeoutMs);
    });

    // We need a way to catch errors from analytics.event. But the promise in the agif-transport does not have
    // a 'reject' handler, which is why we make our own timeout here.
    // There is still a chance that the agif-transport is silently failing, but this is true of all analytics
    // events sent this way in lyst/lyst.
    // This will be addressed in a future ticket, to make agif calls with a POST instead of a GET request.
    const analyticsPromise = analytics.event(
        "page_load",
        "web_vitals",
        "",
        true,
        JSON.parse(data),
        subtype
    );

    Promise.race([analyticsPromise, timeoutPromise]).catch((error) => {
        console.error(error.message); // Handle timeout or other errors
    });
}

export function setup(components: ReactComponentManifest): void {
    setupSentry();

    installJinjaFilters(jinjaToJs);
    installJinjaGlobals(jinjaToJs);

    analytics.init();
    userProfiler.init();
    browser.init();
    alerts.init();
    baustein.init();
    history.start();

    analytics.pageView();
    oneTrustCookie.init();

    for (let [name, component] of Object.entries({
        ...commonComponents,
        ...components,
    })) {
        renderReact(name, withReactStrictMode(component));
    }
    // we expose this as a global so 3rd parties can interact with our analytics
    window["lystAnalytics"] = {
        event: analytics.event.bind(analytics),
    };

    if (environment.getFeature("web_enable_core_web_vitals_reporting")) {
        onCLS(sendAnalyticsMetricEvent);
        onINP(sendAnalyticsMetricEvent);
        onLCP(sendAnalyticsMetricEvent);
        onFCP(sendAnalyticsMetricEvent);
        onTTFB(sendAnalyticsMetricEvent);
    }
}

import { Unsubscribe } from "redux";
import store, { ReduxStoreState } from "./store";

/**
 *
 * @param {*} select - function has store as argument and returns its value
 * eg: `state => (state.some.deep.property)`
 */

export function watchState<T>(
    select: (state: ReduxStoreState) => T,
    callback: (result: T) => void
): Unsubscribe {
    let currentValue = select(store.getState());
    function handleChange(): void {
        let previousValue = currentValue;
        currentValue = select(store.getState());

        if (previousValue !== currentValue) {
            callback(currentValue);
        }
    }

    const unsubscribe = store.subscribe(handleChange);
    return unsubscribe;
}

import React from "react";
import { JsonLd } from "react-schemaorg";
import { Organization } from "schema-dts";

interface OrganizationProps {
    url: string;
}

export default function JSONLDOrganization({ url }: OrganizationProps): JSX.Element {
    return (
        <JsonLd<Organization>
            item={{
                "@context": "https://schema.org",
                "@type": "Organization",
                name: "Lyst",
                url,
                sameAs: [
                    "https://twitter.com/lyst",
                    "https://www.facebook.com/Lyst",
                    "https://instagram.com/lyst/",
                ],
            }}
        />
    );
}

import clsx from "clsx";
import React from "react";
import { IconButton } from "web/react/components/buttons/icon-button/icon-button";
import SVGIcon from "web/react/components/svg-icon/svg-icon";
import { gettext } from "web/script/modules/django-i18n";
import styles from "./close-button.module.css";

interface CloseButtonProps {
    className?: string;
    useDefaultStyle?: boolean;
    onClick: (event?: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>) => void;
}

export default function CloseButton({
    className,
    useDefaultStyle = true,
    onClick,
}: CloseButtonProps): JSX.Element {
    return (
        <IconButton
            aria-label={gettext("general.close")}
            onClick={onClick}
            icon={<SVGIcon name="cross-thin" />}
            dataTestId={"close-button"}
            className={clsx(className, { [styles.button]: useDefaultStyle })}
        />
    );
}

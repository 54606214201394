import "web/react/pages/product/in-stock/product-image-gallery/product-image-gallery.css.ts.vanilla.css!=!../../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"web/react/pages/product/in-stock/product-image-gallery/product-image-gallery.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA61SS3ObMBC++1fspTPNAQ8Q8EO+9K8ItJithURA1MQd//cMEjLg2k0OuUn7+h6767faoOwi+LsCENTWkr8zKCT2hxUAl3RUARmsWgY5KoPNEP7dtYaK9yDXyqAyU+q6Wrt58TfPe7XzSqRjaRjwzuihr+J9cCZhSgZRGP4YQnnXtLphEFy0rgJSi1ilLw/jZ8xOZKbUDTaxsAuIWy61uUtASmDPIB4m1rolQ1ox4FmrZWdwiEosDIPdzhI0umawj+270MoELV2QQZzU/Wz4xg6fxhXUozjMLc2kzk+He3aTR/5v8cKJhn02rsa+M22MrvyH56djozslglzLwZxzSU6Fd6vW5Pc2iQ9D267/YFNIfWZQkhA4t3J7p2hukOUYpXU/4+a/MwzLwrHKJHfqH1j4q0JBHNq8QVTAlYCfFSl/KOl2U/cvlsx6fvuLa0o8+nUqi/8t61SL5q4sGcvGkp3bwsNbnXWlY5fb0TYdm9yx+OrPpO338efSSJEhLt38mgtB6hg42CR0oqf4uAufeEr45pennHyRchS9pk84L7mlmyfcfOILWMmgY4F1t6x9+r9lXT8AXX97VSwFAAA=\"}!../../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var close = 'qptelu7';
export var desktopMedia = '(min-width: 576px)';
export var expand = 'qptelu5';
export var fullScreenItem = 'qptelu2';
export var fullscreenImage = 'qptelu4';
export var galleryOffset = 'var(--qptelu0)';
export var image = 'qptelu3';
export var item = 'qptelu1';
export var mobileMedia = '(max-width: 575px)';
export var overlay = 'qptelu6';
import React from "react";
import { Rail } from "web/react/components/rail/rail";
import { useStoryAnalytics } from "web/react/hooks/use-story-analytics";
import { ProductRailSerializer } from "web/types/serializers";
import * as styles from "./product-rail.css";
import { StoryProductCard } from "./story-product-card";

interface ProductRailProps extends ProductRailSerializer {
    position: number;
}
export function ProductRail({
    id: componentId,
    title,
    caption,
    url,
    product_cards: productCards,
    position,
}: ProductRailProps): React.ReactElement {
    const { containerRef, sendClickEvent } = useStoryAnalytics({
        label: "product_rail",
        componentId,
        position,
        isSponsored: false,
    });

    return (
        <div ref={containerRef} className={styles.productRail} data-testid={"product-rail"}>
            <Rail
                analyticsLabel="stories-product-rail"
                title={title}
                caption={caption}
                ctaUrl={url}
                variant="secondary"
                headerClassName={styles.productRailHeader}
                onCtaClick={() => sendClickEvent("cta")}
                listItemClassName={styles.productCard}
                wrapLimit={productCards.length}
            >
                {productCards.map((product, i) => (
                    <StoryProductCard
                        key={product.id}
                        product={product}
                        position={position}
                        railPosition={i + 1}
                    />
                ))}
            </Rail>
        </div>
    );
}

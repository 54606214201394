import { assignInlineVars } from "@vanilla-extract/dynamic";
import clsx from "clsx";
import React, { ReactNode, useEffect, useRef } from "react";
import { vars } from "web/react/emo/theme.css";
import * as styles from "./keyline-grid.css";

interface KeylineGridProps {
    className?: string;
    children?: ReactNode;
    maxColumns?: 4 | 5;
}

export function KeylineGrid({
    className,
    children,
    maxColumns = 4,
}: KeylineGridProps): JSX.Element {
    function getMaxColumns(n): string {
        return `repeat(auto-fill, minmax(calc(${100 / n}% - ${vars.spacing.sm}), 1fr))`;
    }

    return (
        <div className={clsx(styles.wrapper, className)}>
            <div
                className={styles.grid}
                style={assignInlineVars({ [styles.maxColumns]: getMaxColumns(maxColumns) })}
            >
                {children}
            </div>
        </div>
    );
}

interface KeylineGridItemProps {
    children: ReactNode;
    scrollIntoView?: boolean;
    className?: string;
    colspan?: keyof typeof styles.colSpan;
}

export function KeylineGridItem({
    children,
    scrollIntoView = false,
    className,
    colspan = 1,
}: KeylineGridItemProps): JSX.Element {
    const ref = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (scrollIntoView) {
            ref.current?.scrollIntoView();
        }
    }, [scrollIntoView]);

    return (
        <div ref={ref} className={clsx(styles.colSpan[colspan], className)}>
            {children}
        </div>
    );
}

import criteo from "web/script/embedded/criteo";
import criteo_fr from "web/script/embedded/criteo_fr";
import environment from "web/script/modules/environment";
import browser from "web/script/utils/browser";

/**
 * This handles all events from our analytics pipeline sent to Criteo.
 *
 * The following types of event (including whether the user is logged in or not) are being sent:
 *
 * - Product event (backend name = viewItem):
 *   * fired on all 3 types of feed product pages: feed grid, wishlist feed & discovery edit feed
 *   * sends the ID of the first "CRITEO_PRODUCT_ID_LIMIT" amount of products
 *
 * - Add to Cart event (backend name = “addToCart”):
 *   * happens for both affiliate & checkout
 *   * fired on clicking the "Buy now" button
 *
 * - Basket event (backend name = “viewBasket”):
 *   * checkout only
 *   * the actual basket functionality having been discarded on Lyst, this is fired on landing on the
 * checkout shipping page
 *
 * - Sales event (backend name = “trackTransaction”):
 *   * checkout only
 *   * fired on completing the checkout process (placing an order)
 */

const COUNTRY_ACCOUNTS_MAP = {
    US: "US",
    GB: "GB",
    AL: "EU",
    AD: "EU",
    AM: "EU",
    AZ: "EU",
    BY: "EU",
    BE: "BE",
    BA: "EU",
    BG: "EU",
    HR: "EU",
    CY: "EU",
    CZ: "EU",
    DK: "EU",
    EE: "EU",
    FI: "EU",
    FR: "FR",
    FX: "EU",
    GE: "EU",
    DE: "DE",
    GR: "EU",
    HU: "EU",
    IS: "EU",
    IE: "EU",
    IT: "IT",
    LV: "EU",
    LI: "EU",
    LT: "EU",
    LU: "EU",
    MK: "EU",
    MT: "EU",
    MD: "EU",
    MC: "EU",
    ME: "EU",
    NL: "NL",
    AN: "EU",
    NO: "EU",
    PL: "EU",
    PT: "EU",
    RO: "EU",
    RU: "EU",
    SM: "EU",
    RS: "EU",
    SK: "EU",
    SI: "EU",
    ES: "ES",
    SE: "EU",
    CH: "CH",
    TR: "EU",
    UA: "EU",
    VA: "EU",
    AT: "AT",
    AU: "AU",
    CA: "CA",
};

const ACCOUNTS = {
    US: 55622,
    GB: 58186,
    EU: 30089,
    DE: 70989,
    IT: 70923,
    ES: 70927,
    AU: 70819,
    CA: 70818,
    NL: 70817,
    FR: 36542,
    AT: 74428,
    BE: 74430,
    CH: 74429,
    ROW: 36541,
};

const USER_SEGMENTS = {
    GB: {
        "Google-PLA-CSS": "1",
        "Bing-PLA": "2",
    },
    US: {
        "Bing-PLA": "1",
    },
};

const CRITEO_PRODUCT_ID_LIMIT = 3;

/**
 * Get the custom Criteo viewport types.
 */
function getCriteoViewport() {
    switch (browser.getViewport()) {
        case browser.VIEWPORTS.MOBILE:
            return "m";
        case browser.VIEWPORTS.TABLET:
            return "t";
        case browser.VIEWPORTS.DESKTOP:
            return "d";
        default:
            return "";
    }
}

function getItem(event) {
    return {
        id: event.data.label,
        price: event.data.customData.sale_price,
        quantity: "1",
    };
}

export default {
    send(data) {
        // filter out only the events for page views in the US or lead events
        const events = data.reduce((prev, curr) => {
            if (
                curr.type === "page_view" ||
                curr.data.action === "lead" ||
                curr.data.action === "lead_sold_out" ||
                curr.type === "event"
            ) {
                prev.push(curr);
            }
            return prev;
        }, []);

        const userId = environment.get("userId");
        const isUserLoggedIn = !!userId;
        const country = environment.get("country");
        const language = environment.get("language");

        const region = COUNTRY_ACCOUNTS_MAP[country] || "ROW";
        const account = ACCOUNTS[region];

        // https://jira.lystit.com/browse/PAID-2237, temporary solution for BE
        if (country === "BE" && (language === null || !language.startsWith("nl"))) {
            return Promise.resolve();
        }

        // Iterate over the events and send them to Criteo
        events.forEach((event) => {
            let criteoEvent = {};
            // CSS affiliate product page event:
            if (event.data.customData && event.data.customData.isCssLead) {
                criteoEvent["event"] = "viewBasket";
                criteoEvent["item"] = [getItem(event)];
                const userSegments = USER_SEGMENTS[country] || {};
                const atcGrouping = event.data.customData.atc_grouping;
                if (atcGrouping in userSegments) {
                    criteoEvent["user_segment"] = userSegments[atcGrouping];
                }
            }
            // Affiliate product page event:
            else if (event.data.action === "lead" || event.data.action === "lead_sold_out") {
                const transactionID = event.data.session_id + (region === "GB" ? "-aff" : "");
                // https://jira.lystit.com/browse/AT-997 - more info on what to send to criteo
                if (environment.get("trafficSource") !== "criteo") {
                    criteoEvent["event"] = "viewBasket";
                    criteoEvent["item"] = [getItem(event)];
                } else {
                    criteoEvent["event"] = "trackTransaction";
                    criteoEvent["id"] = transactionID;
                    criteoEvent["item"] = [getItem(event)];
                }
            }
            // View single product page event:
            else if (
                event.type === "page_view" &&
                (event.data.page_type === "product" || event.data.page_type === "product_overlay")
            ) {
                if (userId && event.data.product_is_icon) {
                    return Promise.resolve();
                }
                criteoEvent["event"] = "viewItem";
                criteoEvent["item"] = event.data.product_id;
            }
            // View feed page event: Normal grid feed, Wishlist feed & Discovery edit feed
            else if (event.data.label === "feed_product_ids") {
                // Criteo will accept less than 3 product IDs should the feed be really low
                // but they expect no more than 3 so keeping this slicing as a safeguard.
                let firstXProductIds = event.data.customData.ids.slice(0, CRITEO_PRODUCT_ID_LIMIT);
                criteoEvent["event"] = "viewList";
                criteoEvent["item"] = firstXProductIds;
            }
            // View home page event:
            else if (event.data.page_type === "homepage") {
                criteoEvent["event"] = "viewHome";
            }
            // Add to cart checkout event (clicking the "Buy now" button)
            else if (
                event.data.category === "cart" &&
                event.data.action === "add_item" &&
                event.data.label === "add_to_cart_button"
            ) {
                criteoEvent["event"] = "addToCart";
                criteoEvent["item"] = [event.data.customData.product_id];
            }
            // View basket checkout event (landing on checkout)
            else if (event.data.label === "checkout_landing_page") {
                criteoEvent["event"] = "viewBasket";
                criteoEvent["item"] = [
                    {
                        id: event.data.customData.product_info[0].product_id,
                        price: event.data.customData.product_info[0].regular_price.amount,
                        quantity: "1",
                    },
                ];
            }
            // Sales: completing the checkout process (placing an order)
            else if (event.data.label === "purchase_successful") {
                criteoEvent["event"] = "trackTransaction";
                criteoEvent["item"] = [
                    {
                        id: event.data.customData.product_info.product_id,
                        price: event.data.customData.product_info.regular_price.amount,
                        quantity: "1",
                    },
                ];
            }

            if (Object.keys(criteoEvent).length) {
                // Include the user's logged-in status:
                criteoEvent["is_user_logged_in"] = isUserLoggedIn;
                // Triggering the Criteo tag:
                // Temporarily testing a new tag on the French market
                if (window.Lyst.environment.country === "FR") {
                    criteo_fr(
                        { event: "setAccount", account },
                        { event: "setSiteType", type: getCriteoViewport() },
                        { event: "setEmail", email: "" },
                        criteoEvent
                        // Add manualFlush and flushEvents events in case we want to send
                        // multiple events to different pixels.
                    );
                } else {
                    criteo(
                        { event: "setAccount", account },
                        { event: "setSiteType", type: getCriteoViewport() },
                        { event: "setEmail", email: "" },
                        criteoEvent
                        // Add manualFlush and flushEvents events in case we want to send
                        // multiple events to different pixels.
                    );
                }
            }
        });
        // no way of waiting for this to be done
        return Promise.resolve();
    },
};

import { useEffect, useState } from "react";
import history, { BrowserHistoryEvent } from "web/script/utils/history";

export function useURLHash(): {
    hash: string | null;
    getHashValue: (key: string) => string | null;
} {
    const [urlData, setUrlData] = useState<URL | null>(null);
    const [hash, setHash] = useState<string | null>(null);

    useEffect(() => {
        setUrlData(new URL(window.location.href));
        setHash(window.location.hash);

        history.on("change", (event: any, data: BrowserHistoryEvent) => {
            setUrlData(data.newURL);
            setHash(data.newURL.hash);
        });
    }, []);

    function getHashValue(key: string): string | null {
        // This function is used specifically for handling the
        // hash experiment logic where you have #slug=something
        const matches = urlData?.hash.match(new RegExp(key + "=([^&]*)"));
        return matches ? matches[1] : null;
    }

    return { hash, getHashValue };
}

export function useSendImpression(
    isVisible: boolean,
    onOverlay: boolean
): {
    impressionSent: boolean;
    shouldSendImpression: boolean;
    setImpressionSent: React.Dispatch<React.SetStateAction<boolean>>;
} {
    const { getHashValue } = useURLHash();
    const hasProductSlug = getHashValue("slug");
    const [impressionSent, setImpressionSent] = useState(false);

    const shouldSendImpression =
        isVisible && (onOverlay ? !impressionSent : !impressionSent && !hasProductSlug);

    return { impressionSent, shouldSendImpression, setImpressionSent };
}

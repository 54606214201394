/**
 * Requester
 *
 * The requester is a wrapper around the window.fetch low level API to make it more straight-
 * forward to use for our requirements.
 *
 * The simplest way to run it is by calling .get()...
 *
 *   requester.get('/collections/').then((json) => { .. do stuff .. });
 *
 * All the functions here return promises, which means you must follow them up with a then to
 * handle the response.
 *
 * If you want a more advanced call, just call requester directly...
 *
 *   requester('/collections/').then(requester.json).then((json) => {});
 *
 * See the documentation of each method below to see the different parameters you can use. For
 * the more advanced methods, see https://developer.mozilla.org/en/docs/Web/API/Fetch_API
 *
 * @module modules/requester
 */
import _clone from "lodash/clone";
import _includes from "lodash/includes";
import _isString from "lodash/isString";
import analytics from "web/script/analytics/analytics";
import { RequesterError } from "web/script/modules/errors";
import alerts from "web/script/utils/alerts";
import cookie from "web/script/utils/cookie";
import url, { URLQuery } from "web/script/utils/url";
import globals from "./globals";

/* global fetch, Headers, Request */
/* eslint-disable @typescript-eslint/no-use-before-define */

const hostnameRegExp = /https*:\/\/(.*?)[:\/].*/;
const CSRF_COOKIE_NAME = "csrftoken";

const CSRF_REQUEST_HEADER = "X-CSRFToken";
const REQUESTED_WITH_HEADER = "X-Requested-With";
const PAGE_VIEW_ID_REQUEST_HEADER = "X-Page-View-ID";

const METHOD_GET = "GET";
const METHOD_PUT = "PUT";
const METHOD_DELETE = "DELETE";
const METHOD_POST = "POST";
const METHOD_HEAD = "HEAD";

const ALLOWED_METHODS = [METHOD_GET, METHOD_PUT, METHOD_DELETE, METHOD_POST, METHOD_HEAD];

const ALLOWED_QUERY_METHODS = [METHOD_GET, METHOD_HEAD];

const ALLOWED_BODY_METHODS = [METHOD_DELETE, METHOD_POST, METHOD_PUT];

/**
 * Checks if `o` is a plain object.
 */
function isObject(o: any): boolean {
    return Object.prototype.toString.call(o) === "[object Object]";
}

export interface CustomInit extends RequestInit {
    query?: URLQuery;
    multiPart?: boolean;
}

/**
 * A helper method for window.fetch.
 * @param  input - the URL or Request object you want to fetch
 * @param init - optional parameters for the request
 * @param init.method - a valid HTTP method, by default is GET, unless
 *                      init.body is set, when it will be POST by default
 * @param init.headers - a JSON object of headers to send with the request
 * @param init.body - a JSON object, string, or other form of content to send
 *                    if set, and no method is set, it is set to 'POST' if a JSON object is set,
 *                    it will be form url encoded
 * @param init.query - a JSON object to send as part of a GET request, that will
 *                     be appended as params to the end of the URL
 * @param init.mode - the mode to send in, 'cors', 'no-cors' or 'same-origin'
 * @param init.cache - one of 'default', 'no-store', 'reload', 'no-cache',
 *                     'force-cache', or 'only-if-cached'
 */
function requester(input: RequestInfo, init?: CustomInit): Promise<Response> {
    if (arguments.length > 1 && !isObject(init)) {
        throw new TypeError("If provided init must be an object.");
    }

    let realInit = init ? _clone(init) : {};

    if (_isString(realInit.method)) {
        realInit.method = realInit.method.toUpperCase();
    }

    if (realInit.method && !_isString(realInit.method)) {
        throw new TypeError("If init.method is provided it must be string.");
    }

    if (realInit.method && !_includes(ALLOWED_METHODS, realInit.method)) {
        throw new TypeError(
            `If init.method is provided it must be one of: ${ALLOWED_METHODS.join()}`
        );
    }

    input = processQuery(input, realInit);
    input = processBody(input, realInit);
    input = processHeaders(input, realInit);

    // credentials must always be set to include for cookies
    realInit.credentials = "include";

    return fetch(input, realInit)
        .then(requester._accessor.checkStatus.bind(null))
        .catch((response: Error | Response) => {
            // The Fetch API intentionally hides any useful information in case
            //  it could be used to breach security somehow, so the error we get
            //  is basically useless.
            // Replace that with our own one that
            // a) Groups in NewRelic
            // b) Can be introspected by our own code
            if (response instanceof Error) {
                return Promise.reject(response);
            }

            return Promise.reject(new RequesterError(response, input, realInit));
        });
}

/**
 * Handles a request that contains a query string. The query string can be in the input, defined in init.query, or both.
 */
function processQuery(input: RequestInfo, init: CustomInit): RequestInfo {
    if (!_isString(input)) {
        return input;
    }

    let parsed = new URL(input, globals.window.location.href);

    if (init.query) {
        if (init.method && !_includes(ALLOWED_QUERY_METHODS, init.method)) {
            throw new TypeError(
                `If init.query is provided then init.method must be one of: ${ALLOWED_QUERY_METHODS.join()}`
            );
        }

        let query = url.toURLSearchParams(init.query);
        for (let [k, v] of query.entries()) {
            parsed.searchParams.append(k, v);
        }
    }

    return parsed.href;
}

/**
 * Handles a request that contains a body.
 */
function processBody(input: RequestInfo, init: CustomInit): RequestInfo {
    if (!init.body) {
        return input;
    }

    // if you set a body, it must be a post at least
    init.method = init.method || METHOD_POST;

    if (!_includes(ALLOWED_BODY_METHODS, init.method)) {
        throw new TypeError(
            `If init.body is provided then method must be one of: ${ALLOWED_BODY_METHODS.join()}`
        );
    }

    // return the input as it is if the body is a FormData object
    if (init.body instanceof FormData) {
        return input;
    }

    // convert objects or URLSearchParam instances to url encoded strings
    const headers = init.headers || {};
    if (
        (init.body instanceof url.URLSearchParams || isObject(init.body)) &&
        headers["Content-Type"] !== "application/json"
    ) {
        init.body = url.toQueryString(init.body as any);
    }

    return input;
}

/**
 * Handles the headers for a request.
 * @param {string|Request} input
 * @param {object} init
 * @returns {string|Request}
 */
function processHeaders(input: RequestInfo, init: CustomInit): RequestInfo {
    let headers = init.headers || {};

    if (!headers.hasOwnProperty("Accept")) {
        headers["Accept"] = "application/json";
    }

    // we need to set some headers that must always be set
    headers[REQUESTED_WITH_HEADER] = "XMLHttpRequest";

    // a csrf token must be provided if sent to a lyst url and the page view ID too
    if (requireCsrfToken(input)) {
        let csrftoken = cookie(CSRF_COOKIE_NAME);
        let pageViewId = analytics.getPageViewId();

        if (csrftoken) {
            headers[CSRF_REQUEST_HEADER] = csrftoken;
        }

        if (pageViewId) {
            headers[PAGE_VIEW_ID_REQUEST_HEADER] = pageViewId;
        }
    }

    if (init.body && init.multiPart !== true && !headers["Content-Type"]) {
        headers["Content-Type"] = "application/x-www-form-urlencoded; charset=UTF-8";
    }

    init.headers = new Headers(headers);
    return input;
}

/**
 * Returns true if the request requires the CSRF token to be added, otherwise false.
 * @param {string|Request} input
 * @returns {boolean}
 */
function requireCsrfToken(input: RequestInfo): boolean {
    let hostname = (input instanceof Request ? input.url : input).match(hostnameRegExp);
    let location = globals.window.location;

    // if the url is relative or it is to the same domain then add the CSRF token
    return !hostname || hostname[1] === location.hostname;
}

/**
 * This is a generic status checker. It will check if a response was returned in the 200
 * range, and return the response. Otherwise a rejected promise is returned.
 *
 * Typically, if you make a request with requester, you shouldn't worry about this, since
 * it's called for you. However, if you're creating your own fetch chain, this may be useful.
 * @param {Object} response - the response from a fetch
 * @returns {Object|Promise} the original response object, or a rejected promise if errored
 */
requester.checkStatus = function (response: Response): Response | Promise<never> {
    if (response.status >= 200 && response.status < 300) {
        return response;
    }

    return Promise.reject(response);
};

/**
 * A utility function to handle the authentication state. Use this as a catch handler,
 * it will always return a rejected promise.
 *
 * @example
 *  requester('/collections/')
 *      .then(collectionSuccess, requester.checkAuthentication)
 *      .catch(collectionFailure);
 *
 * @param response - the response object returned from fetch
 * @returns {Promise} a rejected promise, ensure to catch it
 */
requester.checkAuthentication = function (response): Promise<never> {
    if (response.status === 403) {
        alerts.loginRequiredThenRedirect();
    }

    return Promise.reject(response);
};

/**
 * A utility function to get the body of the response parsed as JSON.
 *
 * @example
 *  requester('/collections/').then(requester.toJSON).then(function (data) {
 *      // do something with data
 *  });
 *
 * @param {Response} response - the response object returned from fetch
 * @returns {Promise}
 */
requester.toJSON = function (response: Response): Promise<any> {
    return response.json();
};

/**
 * A utility function to get the body of the response as text.
 *
 * @example
 *  requester('/collections/').then(requester.toText).then(function (text) {
 *      // do something with text
 *  });
 *
 * @param {Response} response - the response object returned from fetch
 * @returns {Promise}
 */
requester.toText = function (response: Response): Promise<string> {
    return response.text();
};

/**
 * A utility function to get back the most appropriate response format, according to the
 * mime-type of the response. If the mime-type is 'application/json', then it will return
 * a JSON object, otherwise it will return text.
 * @param {Response} response - the response object returned from fetch
 * @returns {String|Object} depending on the mime-type
 */
requester.getResponseBody = function (response: Response): Promise<any> {
    if (response.headers.get("content-type") === "application/json") {
        return response.json();
    }
    return response.text();
};

/**
 * A utility function that does what 90% of what you'll use requester for - getting data
 * with certain query parameters.
 *
 * Providing a URL and object representing the query you want to send, it will return a
 * promise that will give you back either JSON or Text (depending on the data returned). It will
 * also handle the authentication required case for you (however, you can turn this off).
 * @param path - string of the URL you want to get
 * @param query - object containing key/value pairs of the query you want to send
 * @param options.checkAuthentication - by default, if a 403 is returned, then the request is
 *  redirected to allow the user to sign in. You can disable this by setting it to false.
 * @returns {Promise} a promise chain with the response data
 */
requester.get = function <TResponse = any>(
    path: string,
    query?: URLQuery,
    options: { checkAuthentication?: boolean } = {}
): Promise<TResponse> {
    const catchHandler = options.checkAuthentication
        ? requester._accessor.checkAuthentication
        : null;

    return requester
        ._accessor(path, { query })
        .then(requester._accessor.getResponseBody, catchHandler);
};

/**
 * A utility function that does what 8% of what you'll use requester for - sending data with a
 * certain body object.
 *
 * Providing a URL and object representing the body you want to send, it will return a promise
 * that will give you back either the JSON or Text response (depending on the data returned). It
 * will also handle the authenticated required.
 * @param path - string of the URL you want to get
 * @param body - object containing key/value pairs of the body of the POST request
 * @returns {Promise} a promise chain with response data
 */
requester.post = function <TResponse = any>(
    path: string,
    body: CustomInit["body"]
): Promise<TResponse> {
    return requester
        ._accessor(path, { body })
        .then(requester._accessor.getResponseBody, requester._accessor.checkAuthentication);
};

// this provides a private reference, that can be overridden for mocks for testing.
requester._accessor = requester;

export default requester;

import analytics from "web/script/analytics/analytics";
import loader from "./loader";

window["_googCsa"] =
    window["_googCsa"] ||
    function () {
        (window["_googCsa"]["q"] = window["_googCsa"]["q"] || []).push(arguments);
    };

if (window.hasOwnProperty("_googCsa")) {
    window["_googCsa"]["t"] = 1 * new Date();
}

const load = loader(() => {
    let d = document;
    let g = d.createElement("script");
    let s = d.getElementsByTagName("script")[0];
    g.async = true;
    g.src = "https://www.google.com/adsense/search/ads.js";
    s.parentNode.insertBefore(g, s);

    g.onload = () => analytics.event("adsense-shopping", "script_loaded", "", true);
    g.onerror = () => analytics.event("adsense-shopping", "script_not_loaded", "", true);
});

export default function () {
    load();
    if (arguments) {
        window._googCsa.apply(null, arguments);
    }
}

import React, { useMemo } from "react";
import { HStack } from "web/react/emo/h-stack";
import { BaseText } from "web/react/emo/shared/components/base-text/base-text";
import { VStack } from "web/react/emo/v-stack";
import { useDomViewport } from "web/react/hooks/use-dom-viewport/use-dom-viewport";
import environment from "web/script/modules/environment";
import * as styles from "./price.css";

const FontsMap = {
    sm: "callout",
    md: "subhead",
    xm: "title-3",
    lg: "title-2",
} as const;

const StyleOptions = {
    primary: {
        color: "highlightBrand",
        backgroundColor: "transparent",
    },
    secondary: {
        color: "grayscale0",
        backgroundColor: "highlight2Brand50",
    },
} as const;

export interface CommonProps {
    inStock: boolean;
    dataTestId?: string;
    fullPrice?: string | number;
    minPrice?: string | number;
    size?: keyof typeof FontsMap;
    style?: keyof typeof StyleOptions;
}

type ConditionalProps =
    | {
          minPrice: string | number;
          maxPrice: string | number;
          inStock: true;
          salePrice?: never;
          fullPrice?: never;
          saleDiscount?: never;
      }
    | {
          fullPrice: string | number;
          saleDiscount?: number;
          salePrice?: string | number | null;
          maxPrice?: never;
          minPrice?: never;
      };

export type PriceProps = CommonProps & ConditionalProps;

export function Price({
    fullPrice,
    minPrice,
    maxPrice,
    salePrice,
    inStock,
    dataTestId,
    saleDiscount,
    size = "sm",
    style = "primary",
}: PriceProps): JSX.Element {
    const textStyle = FontsMap[size];
    const { backgroundColor, color } = StyleOptions[style];

    const hideOriginalPrice = !inStock && salePrice;
    const { isMobileViewport } = useDomViewport();
    const isPDP = environment.get("pageType") === "product";
    const showSaleDiscount = environment.getFeature("act_show_sale_discount");
    const discount = useMemo((): number | null => {
        if (!showSaleDiscount || !inStock) {
            return null;
        }

        if (saleDiscount) {
            return saleDiscount;
        }

        const salePriceNum =
            typeof salePrice === "string" ? parseFloat(salePrice.replace(/\D/g, "")) : salePrice;
        const fullPriceNum = fullPrice
            ? typeof fullPrice === "string"
                ? parseFloat(fullPrice.replace(/\D/g, ""))
                : fullPrice
            : typeof minPrice === "string"
            ? parseFloat(minPrice.replace(/\D/g, ""))
            : minPrice;

        if (!salePriceNum || !fullPriceNum) {
            return null;
        }

        return Math.round(((fullPriceNum - salePriceNum) / fullPriceNum) * 100);
    }, [showSaleDiscount, inStock, saleDiscount, salePrice, fullPrice, minPrice]);

    function priceContent(): JSX.Element {
        return (
            <div data-testid="product-price" className={styles.priceWrapper}>
                {!hideOriginalPrice && (
                    <BaseText
                        textStyle={textStyle}
                        strikeThrough={salePrice || !inStock ? true : false}
                    >
                        {fullPrice || minPrice}
                    </BaseText>
                )}
                {salePrice && (
                    <>
                        {discount && (
                            <BaseText
                                textStyle={textStyle}
                                strikeThrough={!inStock ? true : false}
                                color={"secondary"}
                            >
                                {"-"}
                                {discount}
                                {"%"}
                            </BaseText>
                        )}

                        <BaseText
                            textStyle={textStyle}
                            strikeThrough={!inStock ? true : false}
                            backgroundColor={backgroundColor}
                            color={color}
                        >
                            {salePrice}
                        </BaseText>
                    </>
                )}
                {minPrice && maxPrice && (
                    <BaseText textStyle={textStyle}>{` - ${maxPrice}`}</BaseText>
                )}
            </div>
        );
    }

    return isMobileViewport && !isPDP ? (
        <VStack align="start" spacing="xxxs" dataTestId={dataTestId}>
            {priceContent()}
        </VStack>
    ) : (
        <HStack align="start" spacing="xxxs" dataTestId={dataTestId}>
            {priceContent()}
        </HStack>
    );
}

import React from "react";
import DragRail from "web/react/components/drag-rail/drag-rail";
import analytics from "web/script/analytics/analytics";
import styles from "./homepage-most-wanted-rail.module.css";

interface Item {
    title: string;
    image_url: string;
    url: string;
    image_alt: string;
}

interface HomepageMostWantedItemProps {
    item: Item;
}

function HomepageMostWantedItem({ item }: HomepageMostWantedItemProps): JSX.Element {
    return (
        <div className={styles.homepageMostWantedItem}>
            <a className={styles.homepageMostWantedItemText} href={item.url}>
                {item.title}
            </a>
        </div>
    );
}

interface HomepageMostWantedRailProps {
    items: Item[];
}

export function HomepageMostWantedRail({ items }: HomepageMostWantedRailProps): JSX.Element {
    const id = "homepage-most-wanted-rail";

    function handleDrag(): void {
        analytics.event("rail", "drag", id);
    }
    return (
        <DragRail id={id} onDragStart={handleDrag} className={styles.homepageMostWantedRail}>
            <div className={styles.homepageMostWantedRailContent}>
                {items.map((item) => {
                    return <HomepageMostWantedItem key={item.url} item={item} />;
                })}
            </div>
        </DragRail>
    );
}

export interface HomepageMostWantedRailsProps {
    section_title: string;
    womens_links: Item[];
    mens_links: Item[];
}

function HomepageMostWantedRails({
    section_title: sectionTitle,
    womens_links: womensLinks,
    mens_links: mensLinks,
}: HomepageMostWantedRailsProps): JSX.Element {
    return (
        <div className={styles.homepageMostWantedBase}>
            <div>
                <h4 className={styles.homepageMostWantedTitle}>{sectionTitle}</h4>
            </div>
            <div className={styles.homepageMostWantedContent}>
                <HomepageMostWantedRail items={mensLinks} />
                <HomepageMostWantedRail items={womensLinks} />
            </div>
        </div>
    );
}

export default HomepageMostWantedRails;

import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { addToast, Toast } from "web/redux/ducks/toasts";

export function useToast(): UseToastResult {
    const dispatch = useDispatch();

    return useCallback(
        (props: Toast): void => {
            dispatch(addToast(props));
        },
        [dispatch]
    );
}

type UseToastResult = (props: Toast) => void;

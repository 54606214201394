import _isElement from "lodash/isElement";
import _isFunction from "lodash/isFunction";
import { BREAKPOINTS, VIEWPORTS } from "web/react/constants";
import style from "web/script/dom/style";
import environment from "web/script/modules/environment";
import globals from "web/script/modules/globals";
import storage from "./storage";

var win = globals.window;
var doc = document;
var isFunction = _isFunction;

export default {
    init: function () {
        /**
         * Boolean flag indicating whether the device is iOS or not.
         * @type {Boolean}
         */
        this.ios = /iPhone|iPad/.test(navigator.userAgent);

        /**
         * Boolean flag indicating whether the device is ios and if device version
         * is compatible with the app.
         * @type {Boolean}
         */
        this.isSupportedIos = this.ios && environment.get("allowAppCampaignBasedOnIosVersion");

        /**
         * Boolean flag indicating whether the device is Android or not.
         * @type {Boolean}
         */
        this.android = /Android/.test(navigator.userAgent);

        /**
         * Boolean flag indicating whether the device is android and if device version
         * is compatible with the app.
         * @type {Boolean}
         */
        this.isSupportedAndroid =
            this.android && environment.get("allowAppCampaignBasedOnAndroidVersion");

        /**
         * Boolean flag indicating whether the device is supported combining both android and
         * ios. This makes checking a little cleaner to avoid needing to check both devices each time
         * @type {Boolean}
         */
        this.isSupportedMobileDevice = this.isSupportedAndroid || this.isSupportedIos;
        /**
         * Boolean flag indicating whether the device is Mac or not.
         * @type {Boolean}
         */
        this.mac = /Mac/.test(navigator.userAgent);

        /**
         * Boolean flag indicating whether the device supports touch.
         * @type {Boolean}
         */
        this.hasTouch = "ontouchstart" in win;

        this.chrome = (function () {
            let edge = win.navigator.userAgent.indexOf("Edge") > -1 ? true : false;
            return win.chrome !== "undefined" && win.navigator.vendor === "Google Inc." && !edge;
        })();

        this.ie = !!navigator.userAgent.match(/Trident/g) || !!navigator.userAgent.match(/MSIE/g);

        this.chromeiOS = win.navigator.userAgent.match("CriOS");

        this.firefox = win.navigator.userAgent.indexOf("Firefox") > -1;

        this.supportSticky = (function () {
            const el = document.createElement("a");
            const mStyle = el.style;
            mStyle.cssText = "position:sticky;position:-webkit-sticky;position:-ms-sticky;";
            return mStyle.position.indexOf("sticky") !== -1;
        })();

        /**
         * Is the screen a retina screen?
         * @type {Boolean}
         */
        this.retina =
            (window.matchMedia &&
                (window.matchMedia(
                    "only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx), only screen and (min-resolution: 75.6dpcm)"
                ).matches ||
                    window.matchMedia(
                        "only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2/1), only screen and (min--moz-device-pixel-ratio: 2), only screen and (min-device-pixel-ratio: 2)"
                    ).matches)) ||
            (window.devicePixelRatio && window.devicePixelRatio >= 2);

        /**
         * Are scrollbars styleable?
         * @type {Boolean}
         */
        this.supportsCSSScrollbars = "WebkitAppearance" in document.documentElement.style;

        this.supportsFlexbox = (function () {
            var doc = document.documentElement.style;
            return "flexBasis" in doc || "WebkitFlexBasis" in doc;
        })();

        /**
         * The correct transition end event to use for this browser or null if event is not supported.
         * @type {String|Null}
         */
        this.transitionEndEvent = (function () {
            var event = null;
            var style = document.createElement("div").style;

            // slightly awkward but best way to get the correct event name.
            var transitions = {
                WebkitTransition: "webkitTransitionEnd",
                MozTransition: "transitionend",
                OTransition: "otransitionend",
                transition: "transitionend",
            };

            for (var key in transitions) {
                if (key in style) {
                    event = transitions[key];
                    break;
                }
            }

            return event;
        })();

        /**
         * The correct animation end event to use for this browser or null if event is not supported.
         * @type {String|Null}
         */
        this.animationEndEvent = (function () {
            var event = null;
            var style = document.createElement("div").style;

            // slightly awkward but best way to get the correct event name.
            var animations = {
                webkitAnimation: "webkitAnimationEnd",
                MozAnimation: "transitionend",
                OAnimation: "oanimationend",
                animation: "animationend",
            };

            for (var key in animations) {
                if (key in style) {
                    event = animations[key];
                    break;
                }
            }

            return event;
        })();

        this.supportsCSSTransforms = style.getCSSProperty("perspective") !== null;

        this.supportsCSSTransitions = style.getCSSProperty("transition") !== null;

        this.supportsHistory = !!win.history && isFunction(win.history.pushState);

        this.supportsLocalStorage = storage.isSupported();

        this.supportsQSA = isFunction(doc.querySelectorAll);

        this.supportsClassList = !!doc.body.classList && isFunction(doc.body.classList.add);

        this.supportsPromises = isFunction(win.Promise);

        this.supportsWebSockets = isFunction(win.WebSocket);

        this.supportsFileApi = (function () {
            var input = doc.createElement("input");
            input.type = "file";
            return !!input.files && isFunction(win.FormData) && isFunction(win.FileReader);
        })();

        this.supportsCSSFilters = (function () {
            var element = document.createElement("div");
            element.style.cssText = "-webkit-filter: blur(2px)";
            var testOne = !!element.style.length;
            var testTwo = document.documentMode === undefined || document.documentMode > 9;
            return testOne && testTwo;
        })();
    },

    /**
     * If called with no arguments returns the current scrollTop value of the
     * window, otherwise animates the scroll position of the document to the value indicated
     * by the first argument. Optionally a second argument can be passed to control the
     * duration of the scroll animation, which defaults to 200ms.
     *
     * @param {Number} - scrollTop (defaults to 0)
     * @param {Number} - duration (defaults to 200)
     * @param {NodeElement} - element (defaults to null)
     *
     * @returns {Number|Promise}
     */
    scrollTop: function (scrollTop = 0, duration = 200, element = null) {
        // getter
        if (arguments.length === 0) {
            return globals.window.pageYOffset;
        }

        const isCustomElement = element && _isElement(element);

        // setter - this animates the scroll position so returns a Promise
        return new Promise(function (resolve) {
            const initialValue = isCustomElement ? element.scrollTop : globals.window.scrollY;
            const changeValue = -(initialValue - scrollTop);
            const start = Date.now();

            // From https://github.com/danro/jquery-easing/blob/a6f21ff/jquery.easing.js#L25-L27
            function easeOutQuad(t, b, c, d) {
                return -c * (t /= d) * (t - 2) + b;
            }

            function step() {
                const elapsed = Math.min(Date.now() - start, duration);
                const y = easeOutQuad(elapsed, initialValue, changeValue, duration);

                if (isCustomElement) {
                    element.scrollTop = y;
                } else {
                    globals.window.scrollTo(0, y);
                }

                if (elapsed === duration) {
                    resolve();
                } else {
                    requestAnimationFrame(step);
                }
            }

            requestAnimationFrame(step);
        });
    },

    /**
     * Boolean flag indicating whether CSS transforms / transitions are supported.
     * @type {Boolean}
     */

    /**
     * Get the accurate viewport width.
     * @returns {Number}
     */
    getWindowWidth: function () {
        return globals.window.innerWidth;
    },

    /**
     * Get the accurate viewport width (minus any scrollbar etc.)
     * @returns {Number}
     */
    getClientWidth: function () {
        return document.body.clientWidth;
    },

    /**
     * Get the accurate viewport height.
     * @returns {Number}
     */
    getWindowHeight: function () {
        return globals.window.innerHeight;
    },

    /**
     * Get the height of the document.
     * @returns {number}
     */
    getDocumentHeight: function () {
        const body = document.body;
        const html = document.documentElement;

        return Math.max(
            body.scrollHeight,
            body.offsetHeight,
            html.clientHeight,
            html.scrollHeight,
            html.offsetHeight
        );
    },

    /**
     * Returns what type of viewport the page is currently contained in. It will return a
     * numbered constant, from the VIEWPORTS enum.
     *
     * @example
     *  // checks that the viewport is at least the size of a desktop
     *  browser.getViewport() === VIEWPORTS.DESKTOP;
     *
     * @example
     *  // checks that the viewport is the size of a tablet, or less
     *  browser.getViewport() <= VIEWPORTS.TABLET;
     *
     * @returns {number} {@see module:utils/browser.VIEWPORTS} for the constants
     */
    getViewport: function () {
        var windowWidth = this.getWindowWidth();
        if (windowWidth >= BREAKPOINTS.Large) {
            return VIEWPORTS.Desktop;
        } else if (windowWidth >= BREAKPOINTS.Medium) {
            return VIEWPORTS.Tablet;
        }
        return VIEWPORTS.Mobile;
    },

    VIEWPORTS: {
        MOBILE: VIEWPORTS.Mobile,
        TABLET: VIEWPORTS.Tablet,
        DESKTOP: VIEWPORTS.Desktop,
    },

    /*
     * Adds visibility change event listener to the document using the Page Visibility API
     * https://developer.mozilla.org/en-US/docs/Web/API/Page_Visibility_API
     *
     * @param {function} onChange - function called when the page
     * visiblity changes with boolean value indicating whether the
     * document is visible
     */
    addVisibilityListener(onChange) {
        var hidden;
        var visibilityChange;

        if (typeof globals.document.hidden !== "undefined") {
            hidden = "hidden";
            visibilityChange = "visibilitychange";
        } else if (typeof globals.document.msHidden !== "undefined") {
            hidden = "msHidden";
            visibilityChange = "msvisibilitychange";
        } else if (typeof globals.document.webkitHidden !== "undefined") {
            hidden = "webkitHidden";
            visibilityChange = "webkitvisibilitychange";
        }

        function handleVisibilityChange() {
            onChange(globals.document[hidden]);
        }

        globals.document.addEventListener(visibilityChange, handleVisibilityChange, false);
    },

    /**
     * Simple function to animate a given property for a specified duration
     */
    animate(element, property, amount, duration) {
        function easeInOutQuad(t) {
            return t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t;
        }

        const initialValue = element[property];
        const start = globals.window.performance.now();
        return new Promise((resolve) => {
            function animate(time) {
                let timeFraction = (time - start) / duration;

                if (timeFraction > 1) {
                    timeFraction = 1;
                    resolve();
                }

                let progress = easeInOutQuad(timeFraction);

                element[property] = initialValue + progress * amount;

                if (timeFraction < 1) {
                    requestAnimationFrame(animate);
                }
            }

            requestAnimationFrame(animate);
        });
    },
};

import _each from "lodash/each";
import _isEqual from "lodash/isEqual";

export type DiffResult =
    // | {
    //       key: string;
    //       type: "deleted";
    //       oldValue: any;
    //   }
    // | {
    //       key: string;
    //       type: "changed";
    //       oldValue: any;
    //       value: any;
    //   }
    // | {
    //       key: string;
    //       type: "added";
    //       value: any;
    //   };
    {
        key: string;
        type: "added" | "deleted" | "changed";
        oldValue?: any;
        value?: any;
    };

/**
 * Calculates the changes between two objects ie. added, changed, removed.
 */
export default function diff<A extends Record<string, any>, B extends Record<string, any>>(
    objectA: A,
    objectB: B
): DiffResult[] {
    let changes: DiffResult[] = [];

    _each(objectA, function (value, key) {
        if (!objectB.hasOwnProperty(key)) {
            changes.push({
                key: key,
                type: "deleted",
                oldValue: objectA[key],
            });
        } else if (!_isEqual(objectA[key], objectB[key])) {
            changes.push({
                key: key,
                type: "changed",
                oldValue: objectA[key],
                value: objectB[key],
            });
        }
    });

    _each(objectB, function (value, key) {
        if (!objectA.hasOwnProperty(key)) {
            changes.push({
                key: key,
                type: "added",
                value: objectB[key],
            });
        }
    });

    return changes;
}

import _isString from "lodash/isString";
import analytics from "web/script/analytics/analytics";
import globals from "web/script/modules/globals";
import browser from "web/script/utils/browser";

// Migration Info: the equivalent hook for this is `use-click-tracker`
function onClick(event) {
    let href = this.el.href;
    let target = this.el.target;

    let category = this.options.eventCategory;
    let action = this.options.eventAction;
    let label = this.options.eventLabel;

    if (_isString(category) && _isString(action) && href) {
        let newTab =
            (browser.mac && event.metaKey) || (!browser.mac && event.ctrlKey) || event.which === 2;

        if (target === "_blank" || newTab) {
            analytics.event(category, action, label);
        } else {
            event.preventDefault();
            analytics.event(category, action, label).then(() => {
                globals.window.location = href;
            });
        }
    }
}

export default {
    defaultOptions: {
        eventCategory: null,
        eventAction: null,
        eventLabel: null,
    },

    setupEvents(add) {
        add("click", onClick);
    },
};

import { RefObject, useEffect, useState } from "react";
import { useIntersectionObserver } from "web/react/hooks/use-intersection-observer/use-intersection-observer";

export function useAboveFold(
    ref: RefObject<HTMLDivElement | null>,
    threshold = 1
): {
    isAboveFold: boolean;
    isVisible: boolean;
} {
    const [isAboveFold, setIsAboveFold] = useState(false);
    const [isLoaded, setIsLoaded] = useState(false);

    const [isVisible, , isReady] = useIntersectionObserver(
        {
            threshold: threshold,
            rootMargin: "0px",
            once: true,
        },
        ref as any
    );

    useEffect(() => {
        if (!isLoaded && isReady) {
            if (isVisible) {
                setIsAboveFold(true);
            }
            setIsLoaded(true);
        }
    }, [isLoaded, isReady, isVisible]);

    if (isLoaded) {
        return { isAboveFold, isVisible: !!isVisible };
    } else {
        return { isAboveFold: false, isVisible: false };
    }
}

import PropTypes from "prop-types";
import React from "react";
import { HintBubble } from "web/react/components/hint-bubble/hint-bubble";
import SVGIcon from "web/react/components/svg-icon/svg-icon";
import styles from "./product-area-promos.module.css";

function ProductAreaPromo({ promotion }) {
    if (!promotion) {
        return null;
    }
    let { message, url, tcs } = promotion;

    message = <span className={styles.promoText} dangerouslySetInnerHTML={{ __html: message }} />;

    let promoText;
    if (url) {
        promoText = (
            <a href={url} target="_blank" rel="noopener noreferrer">
                {message}
            </a>
        );
    } else {
        promoText = message;
    }

    tcs = tcs.map(({ message, url }) => (
        <a href={url} key={url} target="_blank" rel="noopener noreferrer">
            {message}
        </a>
    ));
    let tcsPopup = <div className={styles.promoTCs}>{tcs}</div>;

    return (
        <div className={styles.promoArea}>
            <SVGIcon name="tag" className={styles.promoIcon} />
            {promoText}
            <HintBubble tooltipText={tcsPopup} className={styles.promoTCsIcon} />
        </div>
    );
}

const PromoPropType = PropTypes.shape({
    message: PropTypes.string.isRequired,
    url: PropTypes.string,
    tcs: PropTypes.arrayOf(
        PropTypes.shape({
            message: PropTypes.string.isRequired,
            url: PropTypes.string.isRequired,
        })
    ).isRequired,
});

ProductAreaPromo.propTypes = {
    promotion: PromoPropType,
};

export default ProductAreaPromo;

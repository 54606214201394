import React from "react";
import { ArchiveDeviceDivider } from "web/react/emo/archive-device-divider";
import { DisplayBanner } from "web/react/emo/display-banner";
import { useStoryAnalytics } from "web/react/hooks/use-story-analytics";
import { DisplayBannerSerializer } from "web/types/serializers";

export interface DisplayBannerContainerProps extends DisplayBannerSerializer {
    position: number;
    label: string;
    variant?: "sm" | "lg";
    className?: string;
    lazy?: boolean;
}

export function DisplayBannerContainer({
    id: componentId,
    desktop_image_url: desktopImage,
    mobile_image_url: mobileImage,
    url,
    position,
    label,
    component_label: componentLabel,
    variant,
    className,
    lazy = false,
}: DisplayBannerContainerProps): React.ReactElement {
    const { containerRef, sendClickEvent } = useStoryAnalytics({
        label,
        componentId,
        componentLabel,
        position,
        isSponsored: true,
    });

    return (
        <>
            <div ref={containerRef} className={className}>
                <DisplayBanner
                    desktopImage={desktopImage}
                    mobileImage={mobileImage}
                    url={url}
                    onClick={() => sendClickEvent("image")}
                    alt={label}
                    variant={variant}
                    lazy={lazy}
                />
            </div>
            {variant === "lg" && <ArchiveDeviceDivider />}
        </>
    );
}

import React from "react";
import Footer, { FooterProps } from "web/react/components/footer/footer";
import withReduxProvider from "web/react/redux-provider";

export function AppFooter(footerData: FooterProps): JSX.Element | null {
    return (
        <Footer {...footerData}>
            <Footer.LeadContent />

            <Footer.InternationalLinks />

            <Footer.ExtraLinks />

            <Footer.HelpInfo />
        </Footer>
    );
}

export default withReduxProvider(AppFooter);

import PropTypes from "prop-types";
import React from "react";
import Expandable from "web/react/components/expandable/expandable";
import Shipping from "web/react/components/shipping/shipping";
import analytics from "web/script/analytics/analytics";
import { gettext } from "web/script/modules/django-i18n";
import { ShippingOptionPropType } from "web/types/buy-option";

function ProductDescriptionShipping({
    className,
    freeShipping,
    leadUrl,
    retailerName,
    shouldShowExtraShipping,
    extraShipping,
    openExpandableArea = false,
}) {
    return (
        <div className={className}>
            <Expandable
                renderLabel={() => (
                    <h4>{gettext("in_stock_product.compare_prices.all_shipping_returns.link")}</h4>
                )}
                onClick={(isOpen) => {
                    if (!isOpen) {
                        analytics.event("product area", "click", "open-shipping");
                    }
                }}
                openByDefault={openExpandableArea}
            >
                <Shipping
                    freeShippingUrl={"" /* this is always "" in monoprice_buy_option.py */}
                    leadUrl={leadUrl}
                    retailerName={retailerName}
                    freeShipping={freeShipping}
                    shouldShowExtraShipping={shouldShowExtraShipping}
                    extraShipping={extraShipping}
                />
            </Expandable>
        </div>
    );
}

ProductDescriptionShipping.propTypes = {
    className: PropTypes.string,
    freeShipping: ShippingOptionPropType,
    leadUrl: PropTypes.string,
    retailerName: PropTypes.string,
    shouldShowExtraShipping: PropTypes.bool,
    extraShipping: PropTypes.object,
};

export default ProductDescriptionShipping;

import consentModeRegions from "web/script/analytics/consent-mode-regions";
import googleConversion from "web/script/embedded/google-conversion";

export default {
    setDefaultConsentMode: function () {
        // Set GCM default state
        // EEA + UK Region
        googleConversion("consent", "default", {
            ad_storage: "denied",
            analytics_storage: "denied",
            // gcm v2 parameters
            ad_user_data: "denied",
            ad_personalization: "denied",
            region: consentModeRegions.EEA.concat(consentModeRegions.UK),
        });
        // USA Region
        googleConversion("consent", "default", {
            ad_storage: "granted",
            analytics_storage: "granted",
            // gcm v2 parameters
            ad_user_data: "granted",
            ad_personalization: "granted",
            region: consentModeRegions.USA,
        });
    },

    updateConsentMode: function (cookiesState) {
        // Update Consent Mode
        var adStorageCookie =
            cookiesState.acceptsAds && cookiesState.acceptsPersonalizedAds ? "granted" : "denied";
        var analyticsStorageCookie = cookiesState.acceptsAnalytics ? "granted" : "denied";

        googleConversion("consent", "update", {
            ad_storage: adStorageCookie,
            analytics_storage: analyticsStorageCookie,
            // gcm v2 parameters
            ad_user_data: adStorageCookie,
            ad_personalization: adStorageCookie,
        });
    },
};

import { assignInlineVars } from "@vanilla-extract/dynamic";
import { clsx } from "clsx";
import React, { useEffect, useState } from "react";
import GenericStickyModal from "web/react/components/generic-sticky-modal/generic-sticky-modal";
import SVGIcon from "web/react/components/svg-icon/svg-icon";
import { Heading } from "web/react/emo/heading";
import { Text } from "web/react/emo/text";
import { VStack } from "web/react/emo/v-stack";
import { View } from "web/react/emo/view";
import { useDomViewport } from "web/react/hooks/use-dom-viewport/use-dom-viewport";
import { useWindowHeight } from "web/react/hooks/use-window-height/use-window-height";
import { useInStockProductPageContext } from "web/react/pages/product/in-stock/in-stock-product-page/in-stock-product-page.context";
import { handleSizeExpanded } from "web/react/pages/product/in-stock/in-stock-product-page/product-area/product-info/product-info";
import { PriceComparisonTable } from "web/react/pages/product/in-stock/in-stock-product-page/product-price-comparison/price-comparison-table";
import { SizeArea } from "web/react/pages/product/in-stock/in-stock-product-page/size-area";
import { isSelectedSize } from "web/react/pages/product/in-stock/in-stock-product-page/utils";
import analytics from "web/script/analytics/analytics";
import { gettext } from "web/script/modules/django-i18n";
import * as styles from "./product-price-comparison.css";

export function ComparePriceLink(): JSX.Element {
    const { isTabletViewport, isDesktopViewport } = useDomViewport();
    const {
        comparison: { openCompareModal, displayedBuyOptions },
        comparePricesRef,
    } = useInStockProductPageContext();

    function scrollToComparePrice(): void {
        comparePricesRef?.current?.scrollIntoView({
            behavior: "smooth",
            block: "center",
            inline: "nearest",
        });
    }

    function onClick(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>): void {
        event.preventDefault();

        if (isTabletViewport || isDesktopViewport) {
            scrollToComparePrice();
        } else {
            openCompareModal();
        }

        analytics.event("compare-prices", "clicked", "pdp");
    }

    return (
        <button className={styles.comparePricesButton}>
            <a data-testid="compare-prices-button" className={styles.flex} onClick={onClick}>
                <Text as="span" textStyle={"callout"} className={styles.comparePriceCTA}>
                    {/* "Compare {count} prices" */}
                    {gettext("product.buy_area.compare_count_prices.cta", {
                        count: displayedBuyOptions.length,
                    })}
                </Text>
                <SVGIcon
                    name="arrow-down"
                    className={clsx("hidden-mobile", styles.compareSVGIcon)}
                />
            </a>
        </button>
    );
}

export function ProductPriceComparison(): JSX.Element | null {
    const {
        product,
        buyOptions,
        cheapestBuyOptionId,
        termsUrl,
        sizePicker: { sizes },
        comparison: {
            isComparePriceOpen,
            closeCompareModal,
            displayedBuyOptions,
            setDisplayedBuyOptions,
        },
        checkoutModal: { isModalOpen },
        activeProduct: { selectedSizeOption },
        comparePricesRef,
        productPurchaseType,
    } = useInStockProductPageContext();

    const [isSizePickerExpanded, setIsSizePickerExpanded] = useState(false);

    const pageHeight = useWindowHeight();

    // Filter the buy options to only show the ones that have the selected size option if is_icon is true
    // Always include the buy options that are not icons
    // If there is no selected size option, show all buy options
    useEffect(() => {
        // If checkout modal is open dont filter buy options in the background
        if (!isModalOpen) {
            setDisplayedBuyOptions(
                buyOptions.filter(
                    (buyOption) =>
                        !selectedSizeOption ||
                        !buyOption.is_icon ||
                        buyOption.sizes.some((size) => isSelectedSize(selectedSizeOption, size))
                )
            );
        }
    }, [selectedSizeOption, buyOptions, setDisplayedBuyOptions, isModalOpen]);

    if (buyOptions.length <= 1) {
        return null;
    }

    const priceCompareTitle = (
        <Heading as={"h2"} textStyle={"title-2"} className={styles.title}>
            {
                // "Compare all prices"
                gettext("in_stock_product.compare_prices.compare_prices_title")
            }
        </Heading>
    );

    const handleSizeLinkClick = (): void => {
        setIsSizePickerExpanded((isSizePickerExpanded) => !isSizePickerExpanded);
        handleSizeExpanded(productPurchaseType, isSizePickerExpanded, true);
    };

    const priceComparisonArea = (
        <VStack spacing="md">
            <Text as="span" textStyle={"body-1"} color={"secondary"}>
                {
                    // "The best prices we've found across {store_count} stores."
                    gettext("in_stock_product.compare_prices.compare_prices_desc")
                }
            </Text>
            {sizes && (
                <SizeArea
                    sizes={sizes}
                    isSizePickerExpanded={isSizePickerExpanded}
                    handleSizeLinkClick={handleSizeLinkClick}
                    isComparison
                />
            )}
            <PriceComparisonTable
                product={product}
                buyOptions={displayedBuyOptions}
                cheapestBuyOptionId={cheapestBuyOptionId}
                termsUrl={termsUrl}
            />
        </VStack>
    );

    return (
        <>
            <section ref={comparePricesRef} className="hidden-mobile">
                <View>
                    {priceCompareTitle}
                    {priceComparisonArea}
                </View>
            </section>
            <GenericStickyModal
                isOpen={isComparePriceOpen}
                onAfterOpen={() => {
                    analytics.event("compare-prices", "shown", "pdp");
                }}
                onClose={() => {
                    closeCompareModal();
                    analytics.event("compare-prices", "modal_dismissed", "pdp");
                }}
                className={clsx("hidden-desktop", "hidden-tablet-only", styles.comparisonModal)}
                closeClassName={styles.closeButton}
                noScroll
            >
                <div
                    style={assignInlineVars({
                        [styles.pageHeight]: pageHeight ? `${pageHeight}px` : "100vh",
                    })}
                    className={styles.mobileWrapper}
                >
                    <View paddingX="sm">{priceCompareTitle}</View>
                    <div className={styles.mobileTable}>{priceComparisonArea}</div>
                </div>
            </GenericStickyModal>
        </>
    );
}

import merge from "lodash/merge";
import { useCallback, useEffect, useRef, useState } from "react";
import { Rothko, RothkoOptions } from "web/script/modules/rothko";
import { URLQuery } from "web/script/utils/url";

enum UseRothkoLoadingStates {
    DEFAULT,
    PENDING,
    COMPLETE,
    ERROR,
}

export interface UseRothkoOptions<TData = unknown> {
    enabled?: boolean;
    rothko?: RothkoOptions;
    onSuccess?(data: TData): void;
}

const defaultOptions: UseRothkoOptions = {
    enabled: true,
};

interface UseRothkoResult<TData = unknown> {
    data: TData | undefined;
    isFetching: boolean;
    isError: boolean;
    isFetched: boolean;
    isSuccess: boolean;
    run<T>(query?: URLQuery): Promise<T> | undefined;
}

/*  Note - parameters needs to be a reference to an object between re-renders as useEffect does not do a deep object comparison when deciding
if it should execute. For example:
```
const rothkoOptions = useMemo(() =>({ slug: productSlug, uid: productUid }), [productSlug, productUid]);
const { data, loadingState } = useRothko(endpoint, rothkoOptions);
```
This will ensure parameters is always a reference to the same object
*/
export function useRothko<TData = unknown>(
    endpoint: string,
    initialQuery?: URLQuery,
    options?: UseRothkoOptions<TData>
): UseRothkoResult<TData> {
    const clientRef = useRef<Rothko | undefined>(undefined);
    const [data, setData] = useState<TData | undefined>(undefined);
    const [loadingState, setLoadingState] = useState(UseRothkoLoadingStates.DEFAULT);
    const { enabled, onSuccess } = merge({}, defaultOptions, options || {});

    const run = useCallback(
        (query?: URLQuery) => {
            setLoadingState(UseRothkoLoadingStates.PENDING);
            return clientRef.current
                ?.fetch(query || initialQuery)
                .catch(() => setLoadingState(UseRothkoLoadingStates.ERROR));
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [JSON.stringify(initialQuery)]
    );

    useEffect(() => {
        const client = new Rothko(endpoint, options?.rothko);
        client.on("data", (ev, response) => {
            setData(response);
            onSuccess?.(response);
            setLoadingState(UseRothkoLoadingStates.COMPLETE);
        });
        clientRef.current = client;
    }, [endpoint, onSuccess, options]);

    useEffect(() => {
        if (enabled) {
            run();
        }
    }, [enabled, run]);

    return {
        data,
        isFetching: loadingState === UseRothkoLoadingStates.PENDING,
        isError: loadingState === UseRothkoLoadingStates.ERROR,
        isFetched:
            loadingState === UseRothkoLoadingStates.COMPLETE ||
            loadingState === UseRothkoLoadingStates.ERROR,
        isSuccess: loadingState === UseRothkoLoadingStates.COMPLETE,
        run,
    };
}

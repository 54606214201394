import noop from "lodash/noop";
import React, { createContext, useContext, useEffect, useMemo, useRef, useState } from "react";
import environment from "web/script/modules/environment";
import userProfiler from "web/script/modules/userprofiler";
import { Schemas } from "web/types/examples/schemas";
import {
    DesignerSerializer,
    ProductBuyOptionsSerializer,
    ProductCardSerializer,
    ProductRelatedProductsSidebarSerializer,
    ProductScreenSize,
    ProductSerializer,
} from "web/types/serializers";
import { InStockAffiliateProductPageProps } from "./in-stock-product-page";
import {
    DEFAULT_PRODUCT,
    DEFAULT_PRODUCT_BUY_OPTION,
    getPromotion,
    getShippingInformation,
} from "./utils";

export const ProductPurchaseType = {
    AFFILIATE: "affiliate",
    CHECKOUT: "checkout",
    EXPRESS_CHECKOUT: "express_checkout",
} as const;

export type ProductPurchaseTypes = (typeof ProductPurchaseType)[keyof typeof ProductPurchaseType];

export interface ShippingInformation {
    info: string;
    isFree: boolean;
}

export interface BuyOptionPromotionSerializer {
    tcs: {
        message: string;
        url: string;
    }[];
    message: string;
    url: string;
    promoCode: string;
}

export interface InStockProductPageContextProps {
    country: string;
    isLoggedIn: boolean;
    termsUrl: string;
    designer: DesignerSerializer;
    product: ProductSerializer;
    buyOptions: ProductBuyOptionsSerializer[];
    cheapestBuyOptionId: number;
    activeBuyOption: {
        buyOption: ProductBuyOptionsSerializer;
        promotion: BuyOptionPromotionSerializer | null;
        index: number;
    };
    activeProduct: {
        selectedSizeOption: ProductScreenSize | null;
        productImageUrl: string;
        longDescription: string;
        price: {
            fullPrice: string;
            inStock: boolean;
            salePrice: string | null;
        };
        cardSizeList: string[];
        shippingInformation: ShippingInformation[];
    };
    sizePicker: {
        showSizePicker: boolean;
        sizes: ProductScreenSize[];
        schemaLabels: Schemas;
        userDefaultSchema: string;
        sizeGuideLink: string;
        setSelectedSizeOption: React.Dispatch<React.SetStateAction<ProductScreenSize | null>>;
        error: boolean;
        comparisonError: boolean;
        setError: React.Dispatch<React.SetStateAction<boolean>>;
        setComparisonError: React.Dispatch<React.SetStateAction<boolean>>;
    };
    comparison: {
        isComparePriceOpen: boolean;
        openCompareModal: () => void;
        closeCompareModal: () => void;
        displayedBuyOptions: ProductBuyOptionsSerializer[];
        setDisplayedBuyOptions: React.Dispatch<React.SetStateAction<ProductBuyOptionsSerializer[]>>;
    };
    checkoutModal: {
        modalBuyOption: ProductBuyOptionsSerializer;
        setModalBuyOption: React.Dispatch<React.SetStateAction<ProductBuyOptionsSerializer>>;
        isModalOpen: boolean;
        setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
    };
    relatedProducts: ProductCardSerializer[] | null;
    relatedProductsSidebar?: ProductRelatedProductsSidebarSerializer | null;
    productPurchaseType: ProductPurchaseTypes;
    comparePricesRef: React.RefObject<HTMLDivElement> | null;
}

export const InStockProductPageContext = createContext<InStockProductPageContextProps>({
    country: "",
    isLoggedIn: false,
    termsUrl: "",
    designer: {
        id: 0,
        active: false,
        name: "",
        slug: "",
        url: "",
    },
    product: DEFAULT_PRODUCT,
    buyOptions: [],
    cheapestBuyOptionId: 0,
    activeBuyOption: {
        buyOption: DEFAULT_PRODUCT_BUY_OPTION,
        promotion: null,
        index: 0,
    },
    activeProduct: {
        selectedSizeOption: null,
        productImageUrl: "",
        longDescription: "",
        price: {
            fullPrice: "",
            inStock: false,
            salePrice: "",
        },
        cardSizeList: [],
        shippingInformation: [],
    },
    sizePicker: {
        showSizePicker: false,
        sizes: [],
        schemaLabels: {},
        userDefaultSchema: "",
        sizeGuideLink: "",
        setSelectedSizeOption: noop,
        error: false,
        comparisonError: false,
        setError: () => {},
        setComparisonError: () => {},
    },
    comparison: {
        isComparePriceOpen: true,
        openCompareModal: noop,
        closeCompareModal: noop,
        displayedBuyOptions: [],
        setDisplayedBuyOptions: noop,
    },
    checkoutModal: {
        modalBuyOption: DEFAULT_PRODUCT_BUY_OPTION,
        setModalBuyOption: noop,
        isModalOpen: false,
        setIsModalOpen: noop,
    },
    relatedProducts: null,
    relatedProductsSidebar: null,
    productPurchaseType: "affiliate",
    comparePricesRef: null,
});

interface InStockProductPageContextProviderProps {
    children: React.ReactNode;
    initialData: Omit<InStockAffiliateProductPageProps, "helpCentreURLs">;
}

export const InStockProductPageContextProvider = ({
    children,
    initialData,
}: InStockProductPageContextProviderProps): React.ReactElement => {
    const comparePricesRef = useRef(null);

    const { productArea, productLayout } = initialData;

    const {
        designer,
        product,
        buy_options: buyOptions,
        cheapest_link_id: cheapestBuyOptionId,
        sizes,
        terms_url: termsUrl,
        product_purchase_type: productPurchaseType,
    } = productArea;

    const {
        related_products: _relatedProducts,
        related_products_sidebar: relatedProductsSidebar,
        product_analytics_data: productAnalyticsData,
    } = productLayout;

    const relatedProducts = _relatedProducts
        ? _relatedProducts?.sections[0]?.related_products
        : null;

    const country = environment.get("country");
    const isLoggedIn = useMemo(() => userProfiler.isLoggedIn(), []);

    useEffect(() => {
        userProfiler.saveSeenProduct(productAnalyticsData);
    }, [productAnalyticsData]);

    const [isComparePriceOpen, setIsComparePriceOpen] = useState(false);

    function openCompareModal(): void {
        setIsComparePriceOpen(true);
    }

    function closeCompareModal(): void {
        setIsComparePriceOpen(false);
    }

    const [sizePickerError, setSizePickerError] = useState(false);
    const [comparisonSizePickerError, setComparisonSizePickerError] = useState(false);
    const [selectedSizeOption, setSelectedSizeOption] = useState<ProductScreenSize | null>(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalBuyOption, setModalBuyOption] = useState<ProductBuyOptionsSerializer>(
        buyOptions[0]
    );
    const [activeBuyOption, setActiveBuyOption] = useState<ProductBuyOptionsSerializer>(
        buyOptions[0]
    );

    useEffect(() => {
        // If the checkout modal is open we dont want to change the active buy option in the background.
        if (!isModalOpen) {
            let activeLinkId = selectedSizeOption?.link_id || buyOptions[0].link_id;
            let option = buyOptions.filter((buyOption) => buyOption.link_id === activeLinkId)[0];
            setActiveBuyOption(option);
        }
    }, [selectedSizeOption, buyOptions, isModalOpen]);

    const activeBuyOptionIndex = buyOptions.findIndex(
        (buyOption) => buyOption.link_id === activeBuyOption.link_id
    );

    const { retailer_slug: retailerSlug } = activeBuyOption;

    const [displayedBuyOptions, setDisplayedBuyOptions] = useState(buyOptions);

    const longDescription =
        retailerSlug in product.descriptions
            ? product.descriptions[retailerSlug]
            : product.descriptions["default"];

    const productImageUrl = product.images[0].full_size_url;

    const promotion = getPromotion(product, activeBuyOption, termsUrl);
    const shippingInfo = getShippingInformation(activeBuyOption);
    const cardSizeList = sizes.all_sizes.map((size) =>
        size.localized_sizes && sizes.user_default_schema in size.localized_sizes
            ? size.localized_sizes[sizes.user_default_schema].display_size
            : size.display_size
    );

    const showSizePicker = !!(
        activeBuyOption.is_icon ||
        (!activeBuyOption.is_icon && selectedSizeOption !== null && !isModalOpen)
    );

    const value = {
        country,
        isLoggedIn,
        termsUrl,
        designer,
        product,
        buyOptions,
        cheapestBuyOptionId,
        activeBuyOption: {
            buyOption: activeBuyOption,
            promotion,
            index: activeBuyOptionIndex,
        },
        activeProduct: {
            selectedSizeOption,
            productImageUrl,
            longDescription,
            price: {
                fullPrice: activeBuyOption.price,
                inStock: Boolean(activeBuyOption.in_stock),
                salePrice: activeBuyOption.sale_price,
            },
            cardSizeList,
            shippingInformation: shippingInfo,
        },
        sizePicker: {
            showSizePicker: showSizePicker,
            sizes: sizes.all_sizes,
            schemaLabels: sizes.schema_labels,
            userDefaultSchema: sizes.user_default_schema,
            sizeGuideLink: sizes.size_guide_link,
            setSelectedSizeOption,
            error: sizePickerError,
            comparisonError: comparisonSizePickerError,
            setError: setSizePickerError,
            setComparisonError: setComparisonSizePickerError,
        },
        comparison: {
            isComparePriceOpen,
            openCompareModal,
            closeCompareModal,
            displayedBuyOptions,
            setDisplayedBuyOptions,
        },
        checkoutModal: {
            modalBuyOption,
            setModalBuyOption,
            isModalOpen,
            setIsModalOpen,
        },
        relatedProducts: relatedProducts,
        relatedProductsSidebar: relatedProductsSidebar || null,
        productPurchaseType,
        comparePricesRef,
    };

    return (
        <InStockProductPageContext.Provider value={value}>
            {children}
        </InStockProductPageContext.Provider>
    );
};

export const useInStockProductPageContext = (): InStockProductPageContextProps =>
    useContext(InStockProductPageContext);

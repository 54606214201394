import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface ProductCard {
    productUrl: string;
    productImageUrl: string;
    inStock: boolean;
    leadURL?: string;
    openCount?: number;
    retailerExcludedFromApp?: boolean;
    appDeeplinkPath: string;
    linkId?: string;
}

export interface ProductCardModal extends ProductCard {
    openModal: boolean;
}

interface GenericModalProps {
    modalType: string;
    product: ProductCard | null;
    next?: {
        type: string | null;
        href?: string | null;
    } | null;
}

export interface GenericModal extends GenericModalProps {
    openModal: boolean;
}

export interface SendToAppModalSlice {
    OOSModalIsOpen: boolean;
    productCard: ProductCardModal;
    genericModal: GenericModal;
}

function calculateOpenCount(currentCount: number): number {
    if (currentCount < 3) {
        return currentCount + 1;
    }
    return 0;
}

const sendToAppModal = createSlice({
    name: "sendToAppModal",
    initialState: {
        OOSModalIsOpen: false,
        productCard: {
            productUrl: "",
            openModal: false,
            leadURL: "",
            retailerExcludedFromApp: false,
            appDeeplinkPath: "",
        },
        genericModal: {
            openModal: false,
            modalType: "",
            product: null,
            next: null,
        },
    } as SendToAppModalSlice,
    reducers: {
        openOOSModal(state): void {
            state.OOSModalIsOpen = true;
        },
        closeOOSModal(state): void {
            state.OOSModalIsOpen = false;
        },
        openProductCardModal(state, action: PayloadAction<ProductCard>): void {
            state.productCard = {
                productUrl: action.payload.productUrl,
                productImageUrl: action.payload.productImageUrl,
                inStock: action.payload.inStock,
                openModal: true,
                leadURL: action.payload.leadURL,
                openCount: calculateOpenCount(
                    state.productCard.openCount ? state.productCard.openCount : 0
                ),
                retailerExcludedFromApp: action.payload.retailerExcludedFromApp,
                appDeeplinkPath: action.payload.appDeeplinkPath,
                linkId: action.payload.linkId,
            };
        },
        closeProductCardModal(state): void {
            state.productCard = {
                productUrl: "",
                productImageUrl: "",
                inStock: false,
                openModal: false,
                openCount: state.productCard.openCount,
                retailerExcludedFromApp: false,
                appDeeplinkPath: "",
            };
        },
        openGenericModal(state, action: PayloadAction<GenericModalProps>): void {
            state.genericModal = {
                openModal: true,
                modalType: action.payload.modalType,
                product: action.payload.product,
                next: action.payload.next,
            };
        },
        closeGenericModal(state): void {
            state.genericModal = {
                openModal: false,
                modalType: "",
                product: null,
                next: null,
            };
        },
    },
});

export const {
    openOOSModal,
    closeOOSModal,
    openProductCardModal,
    closeProductCardModal,
    openGenericModal,
    closeGenericModal,
} = sendToAppModal.actions;
export default sendToAppModal.reducer;

import React from "react";
import AdsenseShopping from "web/react/components/adsense-shopping/adsense-shopping";
import { BrandAdContainer } from "web/react/components/brand-ad-container/brand-ad-container";
import { CuratedAds } from "web/react/components/curated-ads/curated-ads";
import { MoreLikeThisGrid } from "web/react/components/more-like-this-grid/more-like-this-grid";
import { RecentlyViewed } from "web/react/components/recently-viewed/recently-viewed";
import { RelatedSearchesRail } from "web/react/components/related-searches-rail/related-searches-rail";
import { VStack } from "web/react/emo/v-stack";
import {
    ProductPurchaseType,
    useInStockProductPageContext,
} from "web/react/pages/product/in-stock/in-stock-product-page/in-stock-product-page.context";
import { gettext } from "web/script/modules/django-i18n";
import { ProductLayoutSerializer } from "web/types/serializers";

/**
 * Retrieves placement content for ads.
 *
 * This function returns JSX elements representing ad placements for a given page.
 * On a normal page, it includes two slots at the top and one slot at the bottom.
 * For an express checkout page, it includes zero slots at the top and three slots at the bottom.
 * The function follows a hierarchy for ad placement.
 *
 **/
function getPlacementContent(
    brandAd: ProductLayoutSerializer["brand_ad"] | undefined,
    curatedAds: ProductLayoutSerializer["curated_ads"] | undefined,
    adsenseForShopping: ProductLayoutSerializer["adsense_for_shopping"] | undefined,
    isExpressCheckout: boolean
): { abovePlacement: JSX.Element | null; belowPlacement: JSX.Element | null } {
    const isAdsenseAtTop = !(curatedAds && brandAd);

    const abovePlacement = !isExpressCheckout ? (
        <VStack spacing="xl" dataTestId="ad-products-above">
            {brandAd && (
                <div>
                    <BrandAdContainer {...brandAd} />
                </div>
            )}
            {curatedAds && <CuratedAds {...curatedAds} feedType={""} />}
            {adsenseForShopping && isAdsenseAtTop && <AdsenseShopping {...adsenseForShopping} />}
        </VStack>
    ) : null;

    const belowPlacement = (
        <VStack spacing="xl" dataTestId="ad-products-below">
            {isExpressCheckout && (
                <>
                    {brandAd && (
                        <div>
                            <BrandAdContainer {...brandAd} />
                        </div>
                    )}
                    {curatedAds && <CuratedAds {...curatedAds} feedType={""} />}
                </>
            )}
            {adsenseForShopping && !isAdsenseAtTop && <AdsenseShopping {...adsenseForShopping} />}
        </VStack>
    );

    return {
        abovePlacement,
        belowPlacement,
    };
}

export function RelatedArea({
    productLayout,
    isTrackOverlayTest = false,
}: {
    productLayout: Partial<ProductLayoutSerializer>;
    isTrackOverlayTest?: boolean;
}): JSX.Element | null {
    const {
        related_products: relatedProducts,
        curated_ads: curatedAds,
        brand_ad: brandAd,
        adsense_for_shopping: adsenseForShopping,
        related_designers_category_rail: relatedDesignersCategoryRail,
        recently_viewed_products: recentlyViewedProducts,
        related_search_rail: relatedSearchRail,
    } = productLayout;

    const { productPurchaseType } = useInStockProductPageContext();

    // For express checkout page we want to be able to prioritize the more like this section
    const isExpressCheckout = productPurchaseType === ProductPurchaseType.EXPRESS_CHECKOUT;

    const { abovePlacement, belowPlacement } = getPlacementContent(
        brandAd,
        curatedAds,
        adsenseForShopping,
        isExpressCheckout
    );

    return (
        <VStack spacing="xl">
            {!isTrackOverlayTest && abovePlacement}
            {relatedProducts && (
                <MoreLikeThisGrid section={relatedProducts.sections[0]} isInStock />
            )}
            {isTrackOverlayTest && abovePlacement}
            {belowPlacement}
            {relatedDesignersCategoryRail && !!relatedDesignersCategoryRail.designers?.length && (
                <RelatedSearchesRail
                    title={relatedDesignersCategoryRail.title || undefined}
                    relatedSearches={relatedDesignersCategoryRail.designers}
                />
            )}
            {relatedSearchRail && !!relatedSearchRail.related_searches.length && (
                <RelatedSearchesRail
                    title={gettext("product.related_products.related_searches")}
                    relatedSearches={relatedSearchRail.related_searches}
                />
            )}
            {!!recentlyViewedProducts && (
                <RecentlyViewed
                    title={recentlyViewedProducts.title}
                    products={recentlyViewedProducts.products}
                    is_loading={recentlyViewedProducts.is_loading}
                    current_product_id={recentlyViewedProducts.current_product_id}
                    in_overlay={recentlyViewedProducts.in_overlay}
                    isRedesign
                />
            )}
        </VStack>
    );
}

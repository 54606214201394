import React, { useState } from "react";
import GenericModal from "web/react/components/generic-modal/generic-modal";
import { Stack } from "web/react/emo/shared/components/stack";
import { View } from "web/react/emo/view";
import { useDomViewport } from "web/react/hooks/use-dom-viewport/use-dom-viewport";
import { useInStockProductPageContext } from "web/react/pages/product/in-stock/in-stock-product-page/in-stock-product-page.context";
import { ProductInfo } from "web/react/pages/product/in-stock/in-stock-product-page/product-area/product-info";
import analytics from "web/script/analytics/analytics";
import { ProductBuyOptionsSerializer } from "web/types/serializers";
import * as styles from "./checkout-modal.css";

interface CheckoutModalProps {
    buyOption: ProductBuyOptionsSerializer;
    isOpen: boolean;
    setIsOpen: () => void;
}

export function CheckoutModal({
    buyOption,
    isOpen,
    setIsOpen,
}: CheckoutModalProps): React.ReactElement {
    const {
        activeProduct: { productImageUrl },
    } = useInStockProductPageContext();

    const [analyticsSent, setAnalyticsSent] = useState(false);

    if (isOpen && !analyticsSent) {
        setAnalyticsSent(true);
        analytics.event("checkout_modal", "impression");
    }
    const { isDesktopViewport } = useDomViewport();

    return (
        <GenericModal className={styles.modal} isOpen={isOpen} onClose={() => setIsOpen()}>
            <div data-testid="checkout-modal">
                <Stack direction={isDesktopViewport ? "row" : "column-reverse"} wrap>
                    <div className={styles.productInfo}>
                        <ProductInfo isModal buyOption={buyOption} />
                    </div>

                    <View className={styles.imageGallery}>
                        <img className={styles.image} src={productImageUrl} />
                    </View>
                </Stack>
            </div>
        </GenericModal>
    );
}

import { useRothko } from "web/react/hooks/use-rothko/use-rothko";
import { CaptureType } from "web/redux/ducks/customer-capture-overlay";
import analytics from "web/script/analytics/analytics";
import environment from "web/script/modules/environment";
import { trackKevelEvent } from "web/script/utils/ad-analytics";
import { getCampaignHasBeenDismissed } from "web/script/utils/block-modal-campaigns";
import {
    CampaignRawBranchLink,
    getBranchCampaignName,
    RawBranchLink,
} from "web/script/utils/branch-io";
import storage from "web/script/utils/storage";
import { URLQuery } from "web/script/utils/url";
import { KevelCampaignControlSerializer } from "web/types/serializers";

type CampaignType = "top_banner" | "branch_link" | "block_modal";

export interface CampaignOptions {
    isIcon?: boolean;
    hasBeenBlocked?: boolean;
    hasBeenDismissed?: boolean;
    action?: string;
    isAffiliate?: boolean;
    hasPromotion?: boolean;
    retailerExcludedFromApp?: boolean;
    heimdallFlags?: string[];
    inStock?: boolean;
    areaClicked?: string;
    productId?: number;
    linkId?: number | null;
    currency?: string | null;
    retailerSlug?: string | null;
    newUserGenerated?: boolean;
    pageCount?: number;
}

export interface CampaignDetails {
    branchLink: string;
    name: string;
    blockType?: string | null;
    titleKey?: string | null;
    componentType: string;
    captureType?: CaptureType | null;
    cheapestPriceComparison?: {
        isCheapestPrice: boolean;
        percentagePriceDifference: number;
    };
    ctaKey?: string | null;
}

export interface KevelCampaignControl {
    isKevelEnabled: boolean;
    campaignDetails: CampaignDetails | undefined;
    onClick: () => void;
    onLoad: () => void;
    fetchCampaign: () => Promise<CampaignDetails | undefined>;
}

function getCampaignDetails(
    data: KevelCampaignControlSerializer,
    campaignType: CampaignType
): CampaignDetails | undefined {
    const validBranchLinks = campaignType === "top_banner" ? CampaignRawBranchLink : RawBranchLink;

    let branchLink = "";
    if (data.branch_link_name) {
        branchLink = validBranchLinks[data.branch_link_name];

        if (!branchLink) {
            console.error("Not a valid branch_link_name");
            return undefined;
        }
    }

    let cheapestPriceComparison;
    if (data.cheapest_price_comparison) {
        cheapestPriceComparison = {
            isCheapestPrice: data.cheapest_price_comparison.is_cheapest_price,
            percentagePriceDifference: data.cheapest_price_comparison.percentage_price_difference,
        };
    }

    return {
        branchLink,
        titleKey: data.title_key,
        blockType: data.block_type,
        componentType: data.component_type,
        name: getBranchCampaignName(branchLink),
        captureType: data.capture_type ? (data.capture_type as CaptureType) : null,
        cheapestPriceComparison: cheapestPriceComparison,
        ctaKey: data.cta_key,
    };
}

export function useKevelCampaignControl(
    campaignType: CampaignType,
    options: CampaignOptions = {},
    fetchImmediately = true,
    contextType = ""
): KevelCampaignControl {
    const endpoint = "modules/kevel_campaign_control";
    const isKevelEnabled =
        Boolean(environment.getFeature("web_membership_treatment")) ||
        Boolean(environment.getFeature("web_membership_treatment_desktop"));

    function _getQuery(): URLQuery {
        const query: URLQuery = {
            campaignType: campaignType,
            contextType: contextType,
            feedType: environment.get("feedType"),
            pageType: environment.get("pageType"),
            pageSubType: environment.get("pageSubType"),
            initialPageType: analytics.getInitialPageType(),
            initialPageSubType: analytics.getInitialSubPageType(),
            continueClicked: storage.get("continueClicked", false, true),
            pageCount: storage.get("valid_journey_page_count", 0, true),
            ...new URLSearchParams(Object.entries(options)).toJSON(),
        };

        if (contextType) {
            const { hasBeenDismissed, dismissedCount } = getCampaignHasBeenDismissed(contextType);
            query.hasBeenDismissed = hasBeenDismissed.toString();
            query.dismissedCount = dismissedCount.toString();
        }

        return query;
    }

    function convertDataToCampaignDetails(
        data: KevelCampaignControlSerializer | undefined
    ): CampaignDetails | undefined {
        if (data) {
            if (data.session_variable_key && data.session_variable_value) {
                storage.set(data.session_variable_key, data.session_variable_value, true);
            }

            return getCampaignDetails(data, campaignType);
        }
    }

    const initialQuery = _getQuery();
    const { data, run } = useRothko<KevelCampaignControlSerializer>(endpoint, initialQuery, {
        enabled: isKevelEnabled && fetchImmediately,
        rothko: { cache: true },
    });

    let campaignDetails = convertDataToCampaignDetails(data);

    function onClick(): void {
        if (data && data.click_url) {
            trackKevelEvent(data.click_url, "true");
        }
    }

    function onLoad(): void {
        if (data && data.impression_url) {
            trackKevelEvent(data.impression_url, "false");
        }
    }

    async function fetchCampaign(): Promise<CampaignDetails | undefined> {
        if (isKevelEnabled) {
            const query = _getQuery();
            const data: KevelCampaignControlSerializer | undefined = await run(query);
            return convertDataToCampaignDetails(data);
        }
    }

    return {
        isKevelEnabled,
        campaignDetails,
        onClick,
        onLoad,
        fetchCampaign,
    };
}

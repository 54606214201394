import _isDate from "lodash/isDate";
import _isNumber from "lodash/isNumber";
import _isString from "lodash/isString";
import _isUndefined from "lodash/isUndefined";

var plusesRegex = /\+/g;

function decode(s) {
    try {
        s = decodeURIComponent(s.replace(plusesRegex, " "));
    } catch (e) {
        return null;
    }

    // quoted string
    if (s[0] === '"' && s[s.length - 1] === '"') {
        s = s.substring(1, s.length - 1);
    }

    return s;
}

/**
 *
 * @param {String} key
 * @param {String|Number} [value]
 * @param {Object} [options]
 * @returns {String|Null|Undefined}
 */
function cookie(key, value, options) {
    if (!_isString(key)) {
        return;
    }

    // Write
    if (arguments.length > 1) {
        options = options || {};

        // only strings and numbers are valid values
        if (!_isString(value) && !_isNumber(value)) {
            return;
        }

        // allow expires to be passed as a number representing days
        if (_isNumber(options.expires)) {
            var days = options.expires;
            options.expires = new Date();
            options.expires.setDate(options.expires.getDate() + days);
        }

        var encode = _isUndefined(options.withEncoding) ? true : options.withEncoding;
        var encKey = encode ? encodeURIComponent(key) : key;
        var encValue = encode ? encodeURIComponent(value) : value;

        document.cookie = [
            encKey,
            "=",
            encValue,

            // use expires attribute, max-age is not supported by IE
            _isDate(options.expires) ? "; expires=" + options.expires.toUTCString() : "",

            options.path ? "; path=" + options.path : "",
            options.domain ? "; domain=" + options.domain : "",
            options.secure ? "; secure" : "",
        ].join("");

        return;
    }

    // Read

    // To prevent the for loop in the first place assign an empty array
    // in case there are no cookies at all. Also prevents odd result when
    // calling cookie().
    const cookies = document.cookie ? document.cookie.split("; ") : [];
    let result = null;

    for (var i = 0, l = cookies.length; i < l; i++) {
        let parts = cookies[i].split("=");
        let name = decode(parts.shift());
        let cookie = parts.join("=");

        if (key === name) {
            result = decode(cookie);
            break;
        }
    }

    return result;
}

export default cookie;

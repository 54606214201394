import React from "react";

interface ConditionalProps {
    check: boolean;
    children: React.ReactNode;
}

export function Conditional({ check, children }: ConditionalProps): JSX.Element | null {
    if (check) {
        return <>{children}</>;
    }
    return null;
}

export default Conditional;

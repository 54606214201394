import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { UniversalFiltersData } from "web/types/universal-filters";

export interface FeedFiltersSlice {
    active: UniversalFiltersData | undefined;
    displayed: UniversalFiltersData | undefined;
    showLandingContent: boolean;
    title: string | null;
    view: string | undefined;
}

// Split into pending and active filters.
const initialState: FeedFiltersSlice = {
    active: undefined,
    displayed: undefined,
    showLandingContent: true,
    title: null,
    view: undefined,
};
const feedFilters = createSlice({
    name: "feedFilters",
    initialState,
    reducers: {
        setActiveFilters(state, action: PayloadAction<UniversalFiltersData>): void {
            // State can be modified directly when inside createSlice
            state.active = action.payload;
        },
        setDisplayedFilters(state, action: PayloadAction<UniversalFiltersData>): void {
            state.displayed = action.payload;
        },
        setFeedOrder(state, action: PayloadAction<string>): void {
            state.view = action.payload;
        },
        hideLandingContent(state): void {
            state.showLandingContent = false;
        },
        setTitle(state, action: PayloadAction<string>): void {
            state.title = action.payload;
        },
    },
});

// Extract the action creators object and the reducer
const { actions, reducer } = feedFilters;
// Extract and export each action creator by name
export const { setActiveFilters, setDisplayedFilters, setFeedOrder, hideLandingContent, setTitle } =
    actions;
// Export the reducer, either as a default or named export
export default reducer;

import baustein from "baustein";
import template from "templates/modules/save_for_later/save_for_later_signup_prompt.jinja";
import { openCustomerCaptureOverlay } from "web/redux/ducks/customer-capture-overlay";
import store from "web/redux/store";
import analytics from "web/script/analytics/analytics";
import "web/script/components/react-tooltip";
import { gettext } from "web/script/modules/django-i18n";
import environment from "web/script/modules/environment";

/**
 * This module 'save-for-later-signup-prompt' displays the signup
 * overlay when clicking "save for later" buttons if you are a logged out user.
 *
 * @constructor
 * @alias module:components/save-for-later-signup-prompt
 * @extends module:baustein
 */
export default baustein.register("save-for-later-signup-prompt", {
    template,

    defaultOptions: {
        has_tooltip: false,
        productId: null,
        productSlug: null,
        productGender: null,
        pageSource: "feed",
    },
    init() {
        this.discountIconTest = environment.get("heartIconToDiscount");
        this.isFullHeart = true;
    },
    getInitialRenderContext() {
        // "We'll tell you when this goes on sale"
        const tooltipCopy = gettext("in_stock_product.save_for_later_btn.tooltip");
        // because this component is only rendered with `is_love_icon = true` we don't need
        // CTA copy - should be re-factored so the component can handle both cases
        return {
            save_for_later_signup_prompt: {
                is_love_icon: true,
                product_id: this.options.productId,
                product_slug: this.options.productSlug,
                product_gender: this.options.productGender,
                is_saved_for_later: false,
                next_url: window.location.pathname + window.location.search,
                form_action: "/account/register/",
                has_tooltip: this.options.has_tooltip,
                tooltip_copy: tooltipCopy,
                page_source: "feed",
                is_full_heart: this.isFullHeart,
            },
        };
    },

    setupEvents(add) {
        add("submit", ".save-for-later__signup-prompt", this.onSubmit);
    },

    onSubmit(event) {
        event.preventDefault();

        if (!this.options.isLoveIcon) {
            // if it is love icon it indicated it's a product card and we already trigger this
            // analytics event in product-card.js component
            analytics.event("save_for_later", "add_item", "product_page");
        }

        store.dispatch(
            openCustomerCaptureOverlay({
                productId: this.options.productId.toString(),
                captureType: `signup_${this.options.pageSource}_save`, // signup_pdp_save or signup_feed_save
            })
        );
    },
});

import microsoftClarity from "web/script/embedded/microsoft-clarity";
import environment from "web/script/modules/environment";

export default {
    init: function () {
        if (environment.get("geoip_country") !== "US") {
            microsoftClarity();
        }
    },

    send: function () {},
};

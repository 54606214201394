import { useEffect, useState } from "react";

function getWindowHeight(): number | undefined {
    // Note that Hypernova will ensure `window` is always `{}`, so we can
    //  procede assuming it exists but none of its properties will.
    return window.innerHeight;
}

/**
 * Returns how tall the user's screen is.
 * On Hypernova, it will always return a mobile screensize
 */
export function useWindowHeight(): number | undefined {
    let [windowHeight, setWindowHeight] = useState(getWindowHeight());

    useEffect(() => {
        function onResize(): void {
            setWindowHeight(getWindowHeight());
        }

        window.addEventListener("resize", onResize);
        return (): void => {
            window.removeEventListener("resize", onResize);
        };
    }, []);

    return windowHeight;
}

import _cloneDeep from "lodash/cloneDeep";

import baustein from "baustein";
import RelatedProductsLoader from "web/react/components/LEGACY_related-products-loader/related-products-loader";
import CuratedAds from "web/react/components/__LEGACY__/curated-ads/curated-ads";
import { ProductArea } from "web/react/components/__LEGACY__/product-area/product-area";
import AdsenseShopping from "web/react/components/adsense-shopping/adsense-shopping";
import { BrandAdContainer } from "web/react/components/brand-ad-container";
import PDPGallery from "web/react/components/pdp-gallery/pdp-gallery";
import { RecentlyViewed } from "web/react/components/recently-viewed/recently-viewed";
import analytics from "web/script/analytics/analytics";
import "web/script/components/email-capture-prompt";
import "web/script/components/follow-button";
import overlayMixin from "web/script/components/mixins/overlay-mixin";
import "web/script/components/overlays/generic-overlay-launcher";
import "web/script/components/product-overview";
import "web/script/components/read-more-text";
import "web/script/components/save-for-later-button";
import "web/script/components/save-for-later-signup-prompt";
import environment from "web/script/modules/environment";
import rothko from "web/script/modules/rothko";
import userProfiler from "web/script/modules/userprofiler";
import browser from "web/script/utils/browser";
import globalEvents from "web/script/utils/global-events";
import history from "web/script/utils/history";
import { base64Decode } from "web/script/utils/json";
import logging from "web/script/utils/logging";
import renderReactComponentInDocument from "web/script/utils/render-react";
import storage from "web/script/utils/storage";

export default baustein.register("product-overlay", {
    mixins: [overlayMixin],

    defaultOptions: {
        // overlay-mixin options
        overlaySize: "large",
        noContentPadding: true,
        extraClassnames: "new-mobile-overlay",
        enableBackButton: browser.getViewport() === browser.VIEWPORTS.MOBILE,

        // product-overlay options
        originFeed: null,
        withHashURL: false,
        productSlug: null,
        productUid: null,
        linkId: null,
        previousPageType: null,
        previousPageSubType: null,
    },

    setupEvents(add) {
        add("selected", "product-card", this._onProductCardSelect);

        this._boundProductCardSelect = this._onProductCardSelect.bind(this);
        this._boundAddedToBag = this._onAddedToBag.bind(this);

        globalEvents.on("product-card-selected", this._boundProductCardSelect);
        globalEvents.on("added-to-bag", this._boundAddedToBag);
    },

    onRemove() {
        globalEvents.off("product-card-selected", this._boundProductCardSelect);
        globalEvents.off("added-to-bag", this._boundAddedToBag);
    },

    renderProductArea() {
        if (document.querySelector(".product-in-stock-overlay__details")) {
            if (this.overlayContext.in_stock) {
                const inStock = this.overlayContext.in_stock;
                const productArea = inStock["product_area"];
                const productEditButton = inStock["product_edit_button"];
                renderReactComponentInDocument(
                    ProductArea,
                    {
                        productArea: productArea,
                        editButton: productEditButton,
                        features: productArea.features,
                    },
                    document.querySelector(".product-in-stock-overlay__details")
                );
            }
        }
    },

    renderImageGallery() {
        if (
            document.querySelector(".product-in-stock-overlay__gallery__slot") &&
            this.overlayContext.in_stock
        ) {
            const inStock = this.overlayContext.in_stock;
            const productImageGallery = inStock["product_image_gallery"];

            renderReactComponentInDocument(
                PDPGallery,
                {
                    product: productImageGallery,
                },
                document.querySelector(".product-in-stock-overlay__gallery__slot")
            );
        }

        if (
            document.querySelector(".product-overview__gallery__slot") &&
            this.overlayContext.in_stock
        ) {
            const inStockOverview = this.overlayContext.in_stock.product_overview;
            const productImageGallery = inStockOverview["product_image_gallery"];

            renderReactComponentInDocument(
                PDPGallery,
                {
                    product: productImageGallery,
                    features: {},
                },
                document.querySelector(".product-overview__gallery__slot")
            );
        }

        if (
            document.querySelector(".product-oos-overlay__info__gallery__slot") &&
            this.overlayContext.out_of_stock
        ) {
            const outOfStock = this.overlayContext.out_of_stock;
            const productImageGallery = outOfStock["product_image_gallery"];

            renderReactComponentInDocument(
                PDPGallery,
                {
                    product: productImageGallery,
                    features: {},
                    variant: "oos-carousel",
                },
                document.querySelector(".product-oos-overlay__info__gallery__slot")
            );
        }
    },

    renderRelatedProductsLoader() {
        if (
            document.querySelector(".product-in-stock-overlay__related-products__slot") &&
            this.overlayContext.in_stock
        ) {
            const inStock = this.overlayContext.in_stock;
            const relatedProductsLoader = inStock["related_products_loader"];

            renderReactComponentInDocument(
                RelatedProductsLoader,
                { ...relatedProductsLoader, origin_feed: this.options.originFeed },
                document.querySelector(".product-in-stock-overlay__related-products__slot")
            );
        }

        if (
            document.querySelector(".product-oos-overlay__info__related-products__slot") &&
            this.overlayContext.out_of_stock
        ) {
            const outOfStock = this.overlayContext.out_of_stock;
            const relatedProductsLoader = outOfStock["related_products_loader"];

            renderReactComponentInDocument(
                RelatedProductsLoader,
                { ...relatedProductsLoader, origin_feed: this.options.originFeed },
                document.querySelector(".product-oos-overlay__info__related-products__slot")
            );
        }
    },

    renderRecentlyViewed() {
        if (
            document.querySelector(".product-in-stock-overlay__recently-viewed__slot") &&
            this.overlayContext.in_stock
        ) {
            const inStock = this.overlayContext.in_stock;
            const recentlyViewedProducts = inStock["recently_viewed_products"];

            renderReactComponentInDocument(
                RecentlyViewed,
                {
                    ...recentlyViewedProducts,
                    origin_feed: this.options.originFeed,
                    isRedesign: false,
                },
                document.querySelector(".product-in-stock-overlay__recently-viewed__slot")
            );
        }

        if (
            document.querySelector(".product-oos-overlay__recently-viewed__slot") &&
            this.overlayContext.out_of_stock
        ) {
            const outOfStock = this.overlayContext.out_of_stock;
            const recentlyViewedProducts = outOfStock["recently_viewed_products"];

            renderReactComponentInDocument(
                RecentlyViewed,
                {
                    ...recentlyViewedProducts,
                    isRedesign: false,
                },
                document.querySelector(".product-oos-overlay__recently-viewed__slot")
            );
        }
    },

    renderAdsModule(component, slotName, contextModuleName) {
        const context = this.overlayContext?.[contextModuleName];
        const inStockSlot = document.querySelector(`.product-in-stock-overlay__${slotName}__slot`);
        const oosSlot = document.querySelector(`.product-oos-overlay__${slotName}__slot`);

        if (context) {
            inStockSlot &&
                renderReactComponentInDocument(
                    component,
                    { ...context, onOverlay: true },
                    inStockSlot
                );
            oosSlot &&
                renderReactComponentInDocument(component, { ...context, onOverlay: true }, oosSlot);
        }
    },

    async onOptionsChange() {
        if (!this.el) {
            return;
        }

        this.el.scrollTop = 0;

        const content = await this.getOverlayContent();

        this.setContent(content);
        this.renderProductArea();
        this.renderImageGallery();
        this.renderRelatedProductsLoader();
        this.renderRecentlyViewed();
        this.renderAdsModule(AdsenseShopping, "adsense-shopping", "adsense_for_shopping_module");
        this.renderAdsModule(BrandAdContainer, "brand-ads", "brand_ads_module");
        this.renderAdsModule(CuratedAds, "curated-ads", "curated_ads_module");
        this.trackPageView();
    },

    onOverlayOpen() {
        // ORG-3513: Preserve the current environment
        // when the initial page is feed URL + hash
        // and it opens product overlay
        const overlayOnInitialPageLoad = storage.get("overlayOnInitialPageLoad", false, true);
        const initialPageURL = storage.get("initialPageURL", "", true);

        if (
            this.options.withHashURL &&
            overlayOnInitialPageLoad &&
            initialPageURL === window.location.href
        ) {
            this.feedEnvironment = _cloneDeep(environment._currState);
        }

        this.renderProductArea();
        this.renderImageGallery();
        this.renderRelatedProductsLoader();
        this.renderRecentlyViewed();
        this.renderAdsModule(AdsenseShopping, "adsense-shopping", "adsense_for_shopping_module");
        this.renderAdsModule(BrandAdContainer, "brand-ads", "brand_ads_module");
        this.renderAdsModule(CuratedAds, "curated-ads", "curated_ads_module");
        this.trackPageView();
        // Fix page on mobile to avoid background scrolling on ios
        if (browser.getViewport() === browser.VIEWPORTS.MOBILE) {
            this.emit("fixPageContent");
        }
    },

    onOverlayCloseIntent() {
        const overlayOnInitialPageLoad = storage.get("overlayOnInitialPageLoad", false, true);
        const initialPageURL = storage.get("initialPageURL", "", true);

        // ORG-3465: Make sure that the product overlay
        // can be closed if it is opened on initial page load
        // e.g. when copy-pasting a hash URL in new tab and load it
        // However when opened in new tab `history.back()` will navigate
        // back to the empty tab, so this ensures that it won't go back
        // that further
        if (
            !(
                this.options.withHashURL &&
                overlayOnInitialPageLoad &&
                initialPageURL === window.location.href
            )
        ) {
            history.back();
        }

        const previousPageType = this.options.previousPageType;
        const previousPageSubType = this.options.previousPageSubType;
        environment.set({
            pageType: previousPageType,
            pageSubType: previousPageSubType,
        });

        if (browser.getViewport() === browser.VIEWPORTS.MOBILE) {
            this.emit("unFixPageContent");
        }

        // ORG-3465: Make sure that the product overlay
        // can be closed if it is opened on initial page load
        // e.g. when copy-pasting a hash URL in new tab and load it
        if (
            this.options.withHashURL &&
            overlayOnInitialPageLoad &&
            initialPageURL === window.location.href
        ) {
            // Actually the feed / PDP URL since we are removing the hash
            const nextURL = new URL(window.location.href.replace(window.location.hash, ""));

            // ORG-3513: Put back the feed environment
            // so we can send the correct analytics
            // for feed page when going back from product overlay
            // initial page load
            if (this.feedEnvironment) {
                environment._currState = this.feedEnvironment;
            }

            history.pushState(nextURL);
        }
    },

    trackPageView() {
        const overlayOnInitialPageLoad = storage.get("overlayOnInitialPageLoad", false, true);
        const initialPageURL = storage.get("initialPageURL", "", true);

        const container = this.el.querySelector(".product-overlay");
        if (!container) {
            console.warn("Cannot track page view for product overlay!");
            return;
        }

        const analyticsProductData = base64Decode(container.getAttribute("data-analytics-product"));

        // ORG-3513: Correctly set up analytics data
        // if the initial page load opens product overlay
        // see https://confluence.lystit.com/display/OA/FY23+Q2+-+Hash+URL+Crawl+Optimisation for more details
        if (overlayOnInitialPageLoad && initialPageURL === window.location.href) {
            analytics.setInitialPageType("product_overlay");
            analytics.setInitialSubPageType(
                analyticsProductData.soldout ? "out_of_stock" : "in_stock"
            );
        }

        // Save the seen product in the
        // user localstorage profile
        userProfiler.saveSeenProduct(analyticsProductData);

        // update environment variables
        environment.set({
            gaVariables: base64Decode(container.getAttribute("data-ga-dimensions")),
            analyticsProduct: analyticsProductData,
            pageType: "product_overlay",
            pageSubType: analyticsProductData.soldout ? "out_of_stock" : "in_stock",
            pageViewAnalytics: base64Decode(container.getAttribute("data-page-view")),
            // ORG-3513: Get the PDP URL so we can use in analytics
            productURL: container.getAttribute("data-product-url"),
        });

        // we add adroll via tag manager so don't know if it's there yet and we only want to use
        // them temporary so we are doing this the ugly way. will be better with doubleclick (haha, lol)
        try {
            window.__adroll.record_user({
                product_id: analyticsProductData.product_slug,
            });
        } catch (err) {
            // do nothing
        }
    },

    getOverlayContent() {
        const slug = this.options.productSlug;
        const uid = this.options.productUid;
        const linkId = this.options.linkId;

        return Promise.all([
            import(
                /* webpackChunkName: "template-product-overlay" */
                "templates/modules/product/product_overlay.jinja"
            ),
            rothko.fetch("modules/product/product_overlay", {
                slug,
                uid,
                link_id: linkId,
            }),
        ])
            .then(([{ default: template }, productOverlayContext]) => {
                this.overlayContext = productOverlayContext;
                const features = environment.get("features");
                environment.set("features", {
                    ...features,
                    mg_show_tax_included_label: this.overlayContext.mg_show_tax_included_label,
                });

                return template({
                    product_overlay: this.overlayContext,
                    csrf_token: rothko.getCSRF(),
                });
            })
            .catch((err) => {
                logging.error(err);
            });
    },

    getOverlayContext() {
        return this.overlayContext;
    },

    /**
     * Handles the 'selected' event from product-card components. Stops propagation so the event
     * does not bubble up any further.
     * @private
     */
    _onProductCardSelect(event) {
        event.stopPropagation && event.stopPropagation();
    },

    _onAddedToBag() {
        history.back();
    },
});

import environment from "web/script/modules/environment";

/**
 * Utility function to return the current language, including the locale.
 * I.e. 'en-gb' -> 'en-gb'
 */
export function getLanguage(): string {
    const language = environment.get("language", null);

    if (language === null) {
        return "en-us";
    }

    return language;
}

/**
 * Utility function to return the current language without the country.
 * I.e. 'en-gb' -> 'en'
 */
export function getLanguageWithoutCountry(): string {
    return getLanguage().split("-")[0];
}

/**
 * Utility function to determine if the application is in the English language.
 *
 * Always return true if force show English-only features is switched on
 */
export function languageIsEnglish(): boolean {
    if (environment.get("forceShowEnglishOnlyFeatures", false)) {
        return true;
    }

    const language = environment.get("language", null);

    return language === null || language.startsWith("en");
}

/**
 * DO NOT USE THIS FOR FEATURES THAT ARE NOT YET TRANSLATED!
 * Please use language_is_english() for this instead.
 * This is for features that are permanently only allowed in certain languages.
 * E.g. a PR project that's only translated into certain languages,
 * or the "impressum" section of legal docs that's only relevant to Germany.
 */
export function languageIs(allowedLanguage): boolean {
    if (allowedLanguage.split("-").length !== 1) {
        // Including country in allowed language would effectively restrict the domain
        // not just the language, because the country in a language code is always
        // the SEO country for a domain. This method is not intended to restrict the domain.
        throw new Error(
            `allowedLanguage arg cannot include country, so ${allowedLanguage} is invalid.`
        );
    }
    return getLanguageWithoutCountry() === allowedLanguage;
}

/**
 * DO NOT USE THIS FOR FEATURES THAT ARE NOT YET TRANSLATED!
 * Please use language_is_english() for this instead.
 * This is for features that are permanently only allowed in certain languages.
 * E.g. a PR project that's only translated into certain languages,
 * or the "impressum" section of legal docs that's only relevant to Germany.
 */
export function languageIsIn(allowedLanguages): boolean {
    for (let allowedLanguage of allowedLanguages) {
        if (languageIs(allowedLanguage)) {
            return true;
        }
    }
    return false;
}

import environment from "web/script/modules/environment";
import loader from "./loader";

/* global YAHOO */

window.dotq = window.dotq || [];

const load = loader(
    () => {
        (function (w, d, t, r, u) {
            let s = d.createElement(t);
            s.type = "application/javascript";
            s.src = r;
            s.async = true;
            s.onload = s.onreadystatechange = function () {
                let y;
                let rs = this.readyState;
                let c = w[u];
                if (rs && rs != "complete" && rs != "loaded") {
                    return;
                }
                try {
                    y = YAHOO.ywa.I13N.fireBeacon;
                    w[u] = [];
                    w[u].push = function (p) {
                        y([p]);
                    };
                    y(c);
                } catch (e) {}
            };
            let scr = d.getElementsByTagName(t)[0];
            let par = scr.parentNode;
            par.insertBefore(s, scr);
        })(window, document, "script", "https://s.yimg.com/wi/ytc.js", "dotq");
    },
    () =>
        environment.get("eu_geoip_country") !== true &&
        environment.get("country") !== "GB" &&
        environment.get("disable_third_party_js") !== true
);

export default function () {
    load();
    return window.dotq.push(...arguments);
}

import _identity from "lodash/identity";
import _reduce from "lodash/reduce";
import environment from "web/script/modules/environment";

export default {
    objectTypes: {
        data_survey_response: 1,
        survey_response: 1,
        page_view: 1,
        ux_score: 1,
        event: 1,
        search_term: 1,
        product: 1,
        link: 1,
        feature: 1,
    },

    nonPluralizedObjectTypes: ["ux_score"],

    send: function (data) {
        var agifTransportUrl = environment.get("agifAnalyticsUrl");

        if (!agifTransportUrl) {
            return Promise.resolve();
        }

        var hasDataToSend = false;

        data = _reduce(
            data,
            function (result, obj) {
                var type;

                // RedShift wants plural everything... except
                // for types in `nonPluralizedObjectTypes`, because it was
                // originally made without the final 's' (in webnext).
                if (
                    obj.type.slice(-1) === "s" ||
                    this.nonPluralizedObjectTypes.includes(obj.type)
                ) {
                    type = obj.type;
                } else {
                    type = obj.type + "s";
                }

                if (this.objectTypes[obj.type]) {
                    hasDataToSend = true;
                    result[type] = result[type] || [];
                    result[type].push(obj.data);
                }

                return result;
            }.bind(this),
            {}
        );

        if (!hasDataToSend) {
            return Promise.resolve();
        }

        return new Promise((resolve) => {
            var timeoutId = null;
            var img = document.createElement("img");

            function done() {
                clearTimeout(timeoutId);
                img.src = "";
                img.onerror = img.onload = _identity;
                resolve();
            }

            // done will be called when the image loads, errors, or times out.
            timeoutId = setTimeout(done, 2000);

            img.onerror = img.onload = done;
            img.src = agifTransportUrl + "?d=" + encodeURIComponent(JSON.stringify(data));
        });
    },
};

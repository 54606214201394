import React from "react";
import styles from "./price-range.module.css";

export interface PriceRangeProps {
    hasMultipleStoresPerSize: boolean;
    hasMultiplePricesPerSize: boolean;
    regularPriceWithCurrencySymbol: string | null;
    minimumPriceWithCurrencySymbol: string | null;
}

function PriceRange({
    hasMultipleStoresPerSize,
    hasMultiplePricesPerSize,
    regularPriceWithCurrencySymbol,
    minimumPriceWithCurrencySymbol,
}: PriceRangeProps): React.ReactElement {
    const isDiscounted =
        regularPriceWithCurrencySymbol !== null &&
        regularPriceWithCurrencySymbol !== minimumPriceWithCurrencySymbol;
    const hasPriceRange = hasMultipleStoresPerSize || hasMultiplePricesPerSize;

    function renderSinglePrice(): React.ReactElement {
        return (
            <>
                <span className={isDiscounted ? styles.priceSale : ""}>
                    {minimumPriceWithCurrencySymbol}
                </span>
                {isDiscounted && (
                    <span className={styles.deletedPrice}>
                        {" "}
                        <del>{regularPriceWithCurrencySymbol}</del>
                    </span>
                )}
            </>
        );
    }

    function renderFromToRange(): React.ReactElement {
        return (
            <>
                {hasMultiplePricesPerSize && "From "}
                <span>{minimumPriceWithCurrencySymbol}</span>
            </>
        );
    }

    return (
        <div className={styles.priceRange}>
            {!hasPriceRange ? renderSinglePrice() : renderFromToRange()}
        </div>
    );
}

export default PriceRange;

import clsx from "clsx";
import React, { useState } from "react";
import DragRail from "web/react/components/drag-rail/drag-rail";
import SVGIcon from "web/react/components/svg-icon/svg-icon";
import analytics from "web/script/analytics/analytics";
import { gettext } from "web/script/modules/django-i18n";
import styles from "./category-links.module.css";

interface Link {
    title: string;
    url: string;
}

export interface GenericType {
    title: string;
    image_url: string;
    url: string;
    links: Link[];
}

interface CategoryLinkProps {
    type: GenericType;
    isOpen: boolean;
    onClick: () => void;
}

export const LINK_LIMIT = 9;

function CategoryLink({ type, isOpen, onClick }: CategoryLinkProps): JSX.Element {
    const [isExpanded, setIsExpanded] = useState(false);
    const hideLinks = type.links.length > LINK_LIMIT;
    return (
        <div className={styles.link}>
            <button
                className={styles.linkTitleContainer}
                type="button"
                onClick={(): void => onClick()}
            >
                <h4 className={styles.linkTitle}>{type.title}</h4>
                {isOpen ? (
                    <SVGIcon name={"minus"} className={styles.plusMinusIcon} />
                ) : (
                    <SVGIcon name={"plus"} className={styles.plusMinusIcon} />
                )}
            </button>
            <div
                className={clsx(styles.linkTrends, {
                    [styles.open]: isOpen,
                })}
            >
                <ul className={clsx({ [styles.hideLinks]: hideLinks && !isExpanded })}>
                    {type.links.map((link) => {
                        return (
                            <li key={link.url} className={styles.linkTrendList}>
                                <a href={link.url} className={styles.linkTrendText}>
                                    {link.title}
                                </a>
                            </li>
                        );
                    })}
                </ul>
                {hideLinks && !isExpanded && (
                    <button
                        className={styles.moreButton}
                        type="button"
                        onClick={(): void => setIsExpanded(true)}
                    >
                        {"+"}
                        {
                            gettext("burger_menu.more_category_link") // More
                        }
                    </button>
                )}
            </div>
        </div>
    );
}

export interface CategoryLinksProps extends ReactComponentProps {
    genericTypes: GenericType[];
    id: string;
}

function CategoryLinks({ genericTypes, id, className }: CategoryLinksProps): JSX.Element {
    const [openBlockId, setOpenBlockId] = useState<string | null>(null);

    function setOpenBlock(blockId: string): void {
        if (blockId == openBlockId) {
            // close everything
            setOpenBlockId(null);
        } else {
            // Set the new active block
            setOpenBlockId(blockId);
        }
    }

    function handleDrag(): void {
        analytics.event("rail", "drag", id);
    }
    return (
        <div className={clsx(styles.linksBase, className)}>
            <DragRail id={id} onDragStart={handleDrag}>
                <div className={styles.linksDragRailContainer}>
                    {genericTypes.map((item) => {
                        return (
                            <CategoryLink
                                key={item.title}
                                type={item}
                                isOpen={openBlockId == item.title}
                                onClick={(): void => setOpenBlock(item.title)}
                            />
                        );
                    })}
                </div>
            </DragRail>
        </div>
    );
}

export default CategoryLinks;

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { FeedFiltersSerializer, FilterOptionSerializer } from "web/types/serializers";

interface FiltersState {
    ui: {
        isFetching: boolean;
        baseUrl: string | null;
        isStale: boolean;
        url: string | null;
        activeFilters: FilterOptionSerializer[];
        sortBy: string;
        onApplyUrl?: string | null;
        onApplySortBy?: string | null;
    };
    data?: FeedFiltersSerializer;
}

const initialState = {
    ui: {
        isFetching: false,
        baseUrl: null,
        isStale: false,
        url: null,
        activeFilters: [],
        sortBy: "recommended",
        onApplySortBy: "recommended",
        onApplyUrl: null,
    },
} as FiltersState;

const filtersSlice = createSlice({
    name: "filters",
    initialState,
    reducers: {
        setFiltersData(state, action: PayloadAction<FiltersState["data"]>) {
            state.data = action.payload;
        },
        setFiltersBaseUrl(state, action: PayloadAction<FiltersState["ui"]["baseUrl"]>) {
            state.ui.baseUrl = action.payload;
        },
        setFiltersFeedUrl(state, action: PayloadAction<FiltersState["ui"]["url"]>) {
            state.ui.url = action.payload;
        },
        setFiltersActiveFilters(state, action: PayloadAction<FiltersState["ui"]["activeFilters"]>) {
            state.ui.activeFilters = action.payload;
        },
        setFiltersFeedSortBy(state, action: PayloadAction<FiltersState["ui"]["sortBy"]>) {
            state.ui.sortBy = action.payload;
        },
        setFiltersIsFetching(state, action: PayloadAction<FiltersState["ui"]["isFetching"]>) {
            state.ui.isFetching = action.payload;
        },
        setFiltersOnApplyUrl(state, action: PayloadAction<FiltersState["ui"]["onApplyUrl"]>) {
            state.ui.onApplyUrl = action.payload;
        },
        setFiltersOnApplySortBy(state, action: PayloadAction<FiltersState["ui"]["onApplyUrl"]>) {
            state.ui.onApplySortBy = action.payload;
        },
        setFiltersIsStale(state, action: PayloadAction<FiltersState["ui"]["isStale"]>) {
            state.ui.isStale = action.payload;
        },
    },
});

export const {
    setFiltersData,
    setFiltersIsFetching,
    setFiltersFeedUrl,
    setFiltersFeedSortBy,
    setFiltersOnApplyUrl,
    setFiltersOnApplySortBy,
    setFiltersBaseUrl,
    setFiltersIsStale,
    setFiltersActiveFilters,
} = filtersSlice.actions;
export default filtersSlice.reducer;

import React from "react";
import { ArchiveDeviceDivider } from "web/react/emo/archive-device-divider";
import { SecondaryStoryRail } from "web/react/emo/secondary-story-rail/secondary-story-rail";
import { SecondaryStoryRailSerializer } from "web/types/serializers";

interface SecondaryStoryRailContainerProps {
    secondaryStoryRail: SecondaryStoryRailSerializer;
    className?: string;
    position: number;
    lazy?: boolean;
}

export function SecondaryStoryRailContainer({
    secondaryStoryRail,
    className,
    position,
    lazy = false,
}: SecondaryStoryRailContainerProps): React.ReactElement {
    return (
        <>
            <div className={className}>
                <SecondaryStoryRail {...secondaryStoryRail} position={position} lazy={lazy} />
            </div>
            <ArchiveDeviceDivider />
        </>
    );
}

import React, { useEffect, useRef, useState } from "react";
import { KeylineGrid, KeylineGridItem } from "web/react/components/keyline-grid";
import { ProductDetails } from "web/react/components/product-card/react/emo";
import ProductCard from "web/react/components/product-card/react/product-card";
import { RelatedProductsShowMore } from "web/react/components/related-products-show-more/related-products-show-more";
import { HStack } from "web/react/emo/h-stack";
import { Heading } from "web/react/emo/heading";
import { ProductCardPrice, ProductCardWishlist, ProductImage } from "web/react/emo/product-card";
import { VStack } from "web/react/emo/v-stack";
import { useDomViewport } from "web/react/hooks/use-dom-viewport/use-dom-viewport";
import { useIntersectionObserver } from "web/react/hooks/use-intersection-observer/use-intersection-observer";
import analytics from "web/script/analytics/analytics";
import { gettext } from "web/script/modules/django-i18n";
import { canUseMembership } from "web/script/modules/utils";
import { RelatedProductsAreaSectionSerializer } from "web/types/serializers";
import * as styles from "./more-like-this-grid.css";

interface MoreLikeThisGridProps {
    section: RelatedProductsAreaSectionSerializer;
    isInStock: boolean;
    retailerSlug?: string | null;
}

export function MoreLikeThisGrid({
    section,
    isInStock,
    retailerSlug = null,
}: MoreLikeThisGridProps): React.ReactElement {
    const [sentAnalytics, setSentAnalytics] = useState(false);

    const moreLikeThisRef = useRef<HTMLDivElement | null>(null);

    const { isMobileViewport } = useDomViewport();
    const [isVisible] = useIntersectionObserver(
        {
            rootMargin: "0px",
            threshold: 0,
            once: true,
        },
        moreLikeThisRef as any
    );

    useEffect(() => {
        if (isVisible && !sentAnalytics) {
            setSentAnalytics(true);
            analytics.event("more_like_this", "impression", "pdp_related_feed");
        }
    }, [isVisible, isMobileViewport, sentAnalytics]);

    return (
        <div id="relatedproducts">
            <VStack spacing="lg">
                <Heading as="h2" textStyle={{ sm: "title-3", md: "title-2" }}>
                    {gettext("product.buy_area.more_like_this.label")}
                </Heading>
                {section.related_products && (
                    <div ref={moreLikeThisRef}>
                        <KeylineGrid maxColumns={5}>
                            {section.related_products.map((product, i) => {
                                return (
                                    <KeylineGridItem key={`${product.uid}-${i}`}>
                                        <ProductCard product={product} dataTestId="product-card">
                                            <VStack spacing="xxs">
                                                <ProductImage
                                                    analyticsCategory="more_like_this"
                                                    analyticsEventLabel={"pdp_related_feed"}
                                                    shouldRenderLeadLinkInImage
                                                />
                                                <VStack spacing="xxxs">
                                                    <HStack spacing="none" justify="space-between">
                                                        <ProductCardPrice size="sm" />
                                                        {canUseMembership() && (
                                                            <ProductCardWishlist
                                                                isSavedForLater={
                                                                    product.is_saved_for_later
                                                                }
                                                            />
                                                        )}
                                                    </HStack>
                                                    <ProductDetails
                                                        analyticsCategory="more_like_this"
                                                        analyticsEventLabel={"pdp_related_feed"}
                                                    />
                                                </VStack>
                                            </VStack>
                                        </ProductCard>
                                    </KeylineGridItem>
                                );
                            })}
                        </KeylineGrid>
                    </div>
                )}
                {section.view_more_url && (
                    <div className={styles.showMoreContainer}>
                        <RelatedProductsShowMore
                            view_more_url={section.view_more_url}
                            product_is_instock={isInStock}
                            retailerSlug={retailerSlug}
                            isRedesign
                        />
                    </div>
                )}
            </VStack>
        </div>
    );
}

import PropTypes from "prop-types";
import React from "react";
import BurgerMenuItem from "web/react/components/burger-menu/burger-menu-item/burger-menu-item";
import BurgerMenuSecondaryHeader from "web/react/components/burger-menu/burger-menu-secondary-header/burger-menu-secondary-header";
import { gettext } from "web/script/modules/django-i18n";
import { LanguageChoicePropType } from "web/types/language";
import style from "./burger-menu-language-selector.module.css";

function BurgerMenuLanguageSelector({ allLanguages, onBackToMainClick }) {
    let currentLanguage = allLanguages.find((language) => language.is_selected === true);

    return (
        <div>
            <BurgerMenuSecondaryHeader
                renderText={gettext("burger_menu.language_selector.title")}
                onClickEvent={onBackToMainClick}
            />
            <div className={style.selectedLanguage}>
                {gettext("burger_menu.language_selector", {
                    language: currentLanguage.language_name,
                })}
            </div>
            <ul id="languageMenuLinks">
                {allLanguages.map((language) => {
                    return (
                        <BurgerMenuItem
                            key={language.language_name}
                            renderText={language.language_name}
                            href={language.url}
                        />
                    );
                })}
            </ul>
        </div>
    );
}

export default BurgerMenuLanguageSelector;

BurgerMenuLanguageSelector.propTypes = {
    allLanguages: PropTypes.arrayOf(LanguageChoicePropType),
    onBackToMainClick: PropTypes.func.isRequired,
};

BurgerMenuLanguageSelector.defaultProps = {
    allLanguages: [],
};

import baustein from "baustein";
import _throttle from "lodash/throttle";
import LazyLoadImage from "web/react/components/lazy-load-image/lazy-load-image";
import PaginatedCarousel from "web/script/components/paginated-carousel";
import "web/script/components/tracked-link";
import browser from "web/script/utils/browser";
import renderReactComponentInDocument from "web/script/utils/render-react";

const MOBILE_SET = 1.3;
const TABLET_SET = 2.5;
const DESKTOP_SET = 3;
const GUTTER_WIDTH = 20;

const FEATURED_BANNER_SELECTOR = ".content-card-portrait";
const CONTAINER_SELECTOR = ".featured-banners__container";
const FEATURED_BANNERS_CAROUSEL_ENABLED_CLASS = "featured-banners--carousel-enabled";

export default baustein.register("featured-banners-carousel", {
    setupEvents() {
        this.setGlobalHandler("resize", this._resize);
    },

    onInsert() {
        this._updateCarousel();
    },

    /**
     * Recalculate the carousel options when the browser is resized
     */
    _resize: _throttle(function () {
        this._updateCarousel();
    }, 250),

    /**
     * If it doesn't exist create a carousel else, update the carousel options
     */
    _updateCarousel() {
        const container = this.el.querySelector(CONTAINER_SELECTOR);
        if (!container) {
            return;
        }
        const [gutterWidth, itemsPerPage] = this._getItemLayout();
        const options = {
            items: Array.from(this.el.querySelectorAll(FEATURED_BANNER_SELECTOR)),
            gutterWidth,
            itemsPerPage,
            eventCategory: "featured banner",
        };

        let paginatedCarousel = this.findComponent("paginated-carousel");
        if (paginatedCarousel) {
            paginatedCarousel.updateOptions(options);
        } else {
            paginatedCarousel = new PaginatedCarousel(options);
            paginatedCarousel.appendTo(container);
        }

        this.el.classList.add(FEATURED_BANNERS_CAROUSEL_ENABLED_CLASS);
        this._lazyLoadContentCardsImages();
    },

    /**
     * Lazy load content cards images using the React component LazyLoadImage
     */
    _lazyLoadContentCardsImages() {
        const cards = document.querySelectorAll(".content-card-portrait__image-wrapper");
        for (const card of cards) {
            const { src } = card.dataset;
            renderReactComponentInDocument(
                LazyLoadImage,
                {
                    src,
                    className: "content-card-portrait__image",
                    customTag: "div",
                },
                card
            );
        }
    },

    /**
     * Identify how many items we want to show (based on viewport width) and the left
     * and right padding each item should have
     */
    _getItemLayout() {
        if (browser.getViewport() <= browser.VIEWPORTS.MOBILE) {
            return [GUTTER_WIDTH, MOBILE_SET];
        } else if (browser.getViewport() <= browser.VIEWPORTS.TABLET) {
            return [GUTTER_WIDTH, TABLET_SET];
        } else {
            return [GUTTER_WIDTH, DESKTOP_SET];
        }
    },
});

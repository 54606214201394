import { useEffect, useState } from "react";
import { BREAKPOINTS } from "web/react/constants";

function getWindowWidth(): number {
    // Note that Hypernova will ensure `window` is always `{}`, so we can
    //  proceed assuming it exists but none of its properties will.
    return window.innerWidth || BREAKPOINTS.ExtraSmall;
}

/**
 * Returns how wide the user's screen is.
 * On Hypernova, it will always return a mobile screen size
 */
export function useWindowWidth(): number {
    let [windowWidth, setWindowWidth] = useState(getWindowWidth());

    useEffect(() => {
        function onResize(): void {
            setWindowWidth(getWindowWidth());
        }

        window.addEventListener("resize", onResize);
        return (): void => {
            window.removeEventListener("resize", onResize);
        };
    }, []);

    return windowWidth;
}

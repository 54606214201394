import { useEffect, useState } from "react";
import history, { BrowserHistoryEvent } from "web/script/utils/history";

export function useHistoryWatcher(): BrowserHistoryEvent | null {
    let [urlData, setUrlData] = useState<BrowserHistoryEvent | null>(null);

    useEffect(
        () =>
            history.on("change", (event: any, data: BrowserHistoryEvent) => {
                setUrlData(data);
            }),
        []
    );

    return urlData;
}

import React, { PropsWithChildren } from "react";

export function withReactStrictMode<P extends Record<string, unknown>>(
    WrappedComponent: React.ComponentType<P>
): React.FC<PropsWithChildren<P>> {
    return function withReactStrictMode(props): JSX.Element {
        return (
            <React.StrictMode>
                <WrappedComponent {...props} />
            </React.StrictMode>
        );
    };
}

import React from "react";
import BuyOptions from "web/react/components/buybuybuy-area/buy-options/buy-options";
import PriceRange from "web/react/components/buybuybuy-area/price-range/price-range";
import ProductDescription from "web/react/components/buybuybuy-area/product-description/product-description";
import ProductHeader from "web/react/components/buybuybuy-area/product-header/product-header";
import {
    BuyBuyBuyAreaSerializer,
    ProductDescriptionSerializer,
    ProductHeaderSerializer,
    ProductImageSerializer,
} from "web/types/serializers";

interface BuyAreaProps {
    csrfToken: string;
    header: ProductHeaderSerializer;
    buyAreaOptions: BuyBuyBuyAreaSerializer;
    productDescription: ProductDescriptionSerializer | null;
    isOverlay?: boolean;
    productImages: ProductImageSerializer[];
}

function BuyArea({
    header,
    csrfToken,
    buyAreaOptions,
    productDescription,
    isOverlay,
    productImages,
}: BuyAreaProps): React.ReactElement | null {
    const {
        product_id,
        min_and_max_price_are_the_same,
        multiple_stores,
        regular_price_with_currency_symbol,
        minimum_price_with_currency_symbol,
        size_guide_link,
        form_action,
        return_url,
        available_sizes,
        sizes,
        country,
        is_saved_for_later,
        buy_options,
        user_default_schema,
        schema_labels,
    } = buyAreaOptions;

    const [regPriceWithCurSymbol, setRegPriceWithCurSymbol] = React.useState<string | null>(
        regular_price_with_currency_symbol
    );
    const [minPriceWithCurSymbol, setMinPriceWithCurSymbol] = React.useState<string | null>(
        minimum_price_with_currency_symbol
    );
    const [hasMultiplePricesPerSize, setHasMultiplePricesPerSize] = React.useState<boolean>(
        !min_and_max_price_are_the_same
    );
    const [hasMultipleStoresPerSize, setHasMultipleStoresPerSize] =
        React.useState<boolean>(multiple_stores);

    return (
        <div className="buybuybuy-area">
            {header && (
                <ProductHeader
                    variant={"checkout"}
                    designerUrl={header.designer_url}
                    isOwnerActive={header.is_owner_active}
                    isOwnerFeedLinkable={header.is_owner_feed_linkable}
                    ownerName={header.owner_name}
                    shortDescription={header.short_description}
                />
            )}
            <PriceRange
                hasMultipleStoresPerSize={hasMultipleStoresPerSize}
                hasMultiplePricesPerSize={hasMultiplePricesPerSize}
                regularPriceWithCurrencySymbol={regPriceWithCurSymbol}
                minimumPriceWithCurrencySymbol={minPriceWithCurSymbol}
            />
            <BuyOptions
                isOverlay={isOverlay}
                buyOptions={buy_options}
                productId={product_id}
                isSavedForLater={is_saved_for_later}
                setRegPriceWithCurSymbol={setRegPriceWithCurSymbol}
                setMinPriceWithCurSymbol={setMinPriceWithCurSymbol}
                setHasMultiplePricesPerSize={setHasMultiplePricesPerSize}
                setHasMultipleStoresPerSize={setHasMultipleStoresPerSize}
                sizeOptionsForm={{
                    csrfToken: csrfToken,
                    action: form_action,
                    country: country,
                    returnUrl: return_url,
                    availableSizes: available_sizes,
                    sizes: sizes,
                    sizeGuideLink: size_guide_link,
                    userDefaultSchema: user_default_schema,
                    schemas: schema_labels,
                }}
                productImages={productImages}
            />
            {productDescription && <ProductDescription {...productDescription} />}
        </div>
    );
}

export default BuyArea;

import React from "react";
import SizeGuide from "web/react/components/buybuybuy-area/size-guide/size-guide";
import { gettext } from "web/script/modules/django-i18n";
import { languageIsEnglish } from "web/script/modules/language";
import { ProductSizesSerializer } from "web/types/serializers";
import styles from "./product-sizes.module.css";

function ProductSizes({
    sizes,
    size_guide_link: link,
}: ProductSizesSerializer): React.ReactElement {
    return (
        <div className={styles.productSizes}>
            <span className={styles.heading}>{gettext("in_stock_product.sizes_heading")}</span>

            {languageIsEnglish() && (
                <em>
                    <SizeGuide sizeGuideLink={link} />
                </em>
            )}

            {sizes.map((size) => (
                <span key={size} className={styles.size}>
                    {size}
                </span>
            ))}
        </div>
    );
}

export default ProductSizes;

import clsx from "clsx";
import React, { useEffect, useRef, useState } from "react";
import Options from "web/react/components/select/options";
import {
    Option,
    OptionHeadingPropsUnion,
    OptionPropsUnion,
} from "web/react/components/select/types";
import SVGIcon from "web/react/components/svg-icon/svg-icon";
import { Text } from "web/react/emo/text";
import {
    ProductPurchaseType,
    useInStockProductPageContext,
} from "web/react/pages/product/in-stock/in-stock-product-page/in-stock-product-page.context";
import { gettext } from "web/script/modules/django-i18n";
import styles from "./select.module.css";

export interface SelectProps {
    className?: string;
    defaultSelectText: string;
    dataTestId?: string;
    error?: boolean;
    isExpanded: boolean;
    onFirstExpand?: () => void;
    onSelect: (option: Option) => void;
    onDisabledSelect?: (option: Option) => void;
    OptionComponent: (optionProps: OptionPropsUnion) => JSX.Element;
    OptionFooterComponent?: JSX.Element;
    OptionHeadingComponent?: (optionHeadingProps: OptionHeadingPropsUnion) => JSX.Element;
    options: Option[];
    setExpanded: (expanded: boolean, shouldSendAnalytic?: boolean) => void;
    showOnlyOptionByDefault?: boolean;
    showDisabledOptions?: boolean;
    pickedOption?: Option;
}

export default function Select({
    className,
    defaultSelectText,
    dataTestId,
    isExpanded,
    error = false,
    onFirstExpand,
    onSelect,
    onDisabledSelect = () => {},
    OptionComponent,
    OptionHeadingComponent,
    OptionFooterComponent,
    options,
    setExpanded,
    showOnlyOptionByDefault = false,
    showDisabledOptions = true,
    pickedOption,
}: SelectProps): JSX.Element {
    const {
        sizePicker: { setSelectedSizeOption },
        activeProduct,
        productPurchaseType,
    } = useInStockProductPageContext();
    const ref = useRef<HTMLDivElement>(null);
    let defaultOption: Option = {
        selectedText: "",
        text: [],
        value: null,
    };
    if (options.length === 1 && showOnlyOptionByDefault) {
        defaultOption = options[0];
    }

    const [previouslyExpanded, updatePreviouslyExpanded] = useState(false);
    const [selectedOptionValue, updateSelectedOptionValue] = useState(null);
    const [selectedOption, updateSelectedOption] = useState(defaultOption);

    useEffect(() => {
        if (activeProduct.selectedSizeOption === null) {
            setSelectedSizeOption(defaultOption.value);
        }
    }, [defaultOption.value, setSelectedSizeOption, activeProduct.selectedSizeOption]);

    useEffect(() => {
        // Set window click to close expansion if click is outside of element and
        // click is not PDP Pricing Card Text link
        function closeExpansionOnOutsideClick(e): void {
            const targetText = gettext("in_stock_product.out_of_stock_size");
            if (e.target.textContent !== targetText && !ref.current?.contains(e.target)) {
                setExpanded(false);
            }
        }

        if (isExpanded) {
            window.addEventListener("click", closeExpansionOnOutsideClick);
        }

        return () => {
            window.removeEventListener("click", closeExpansionOnOutsideClick);
        };
    }, [isExpanded, setExpanded]);

    useEffect(() => {
        // Perform onFirstExpand function if given and first time select has been expanded
        if (isExpanded && !previouslyExpanded && onFirstExpand) {
            onFirstExpand();
            updatePreviouslyExpanded(true);
        }
    }, [isExpanded, onFirstExpand, previouslyExpanded]);

    useEffect(() => {
        const newSelectedOption =
            options.find((option): boolean => {
                return option.value === selectedOptionValue;
            }) || defaultOption;

        updateSelectedOption(newSelectedOption);
    }, [options, selectedOptionValue]);

    useEffect(() => {
        if (pickedOption) {
            updateSelectedOption(pickedOption);
        }
    }, [pickedOption, updateSelectedOption]);

    function handleSelectBoxOnClick(event): void {
        event.stopPropagation();
        setExpanded(!isExpanded);
    }

    function handleOptionOnClick(option: Option): () => void {
        return () => {
            if (!option.disabled) {
                updateSelectedOptionValue(option.value);
                setExpanded(false, !(productPurchaseType === ProductPurchaseType.EXPRESS_CHECKOUT));
                onSelect(option);
            } else {
                onDisabledSelect(option);
            }
        };
    }

    const optionHeadingElement = OptionHeadingComponent ? (
        <OptionHeadingComponent onCloseButtonClick={handleSelectBoxOnClick} />
    ) : undefined;

    return (
        <div ref={ref} className={clsx(styles.selectWrapper, className)}>
            <div
                aria-description={defaultSelectText}
                className={clsx(styles.selectBox, {
                    [styles.error]: error,
                })}
                data-testid={dataTestId}
                onClick={handleSelectBoxOnClick}
            >
                <Text
                    textStyle={"body-2"}
                    color={selectedOption.selectedText ? "primary" : "secondary"}
                    as={"span"}
                    className={styles.selectBoxText}
                >
                    {selectedOption.selectedText ? selectedOption.selectedText : defaultSelectText}
                </Text>
                <SVGIcon
                    name="chevron-medium"
                    className={clsx(styles.chevronIcon, {
                        [styles.chevronIconExpanded]: isExpanded,
                    })}
                />
            </div>
            <Options
                className={className}
                isExpanded={isExpanded}
                onClick={handleOptionOnClick}
                options={options}
                optionHeadingElement={optionHeadingElement}
                OptionComponent={OptionComponent}
                selectedOption={selectedOption}
                optionFooterElement={OptionFooterComponent}
                showDisabledOptions={showDisabledOptions}
            />
        </div>
    );
}

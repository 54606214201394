import { useEffect } from "react";
import { useSelector } from "react-redux";
import { ReduxStoreState } from "web/redux/store";
import { BannerSize } from "web/script/utils/app-banner-campaign";

// This hooks is going to add a `className` to a specific DOM Element (`getNode`),
// in the case the below Top Banner conditions are met.
export function useTopBannerClass(getNode: () => Element, className: string): void {
    const topBanner = useSelector((state: ReduxStoreState) => state.topBannerReducer);
    const topBannerIsInView = useSelector(
        (state: ReduxStoreState) => state.followDesignerBannerReducer.show
    );

    const hasTopPadding = topBanner.isVisible && !topBannerIsInView;

    useEffect(() => {
        const node = getNode();
        const isVisible =
            hasTopPadding &&
            (topBanner.bannerSize === BannerSize.Small || topBanner.bannerType === "email_banner");

        if (isVisible) {
            node.classList.add(className);
        } else {
            node.classList.remove(className);
        }
    }, [topBanner, getNode, className, topBannerIsInView, hasTopPadding]);
}

import noop from "lodash/noop";
import { useState } from "react";
import {
    PaymentCardProvider,
    PaymentInformation,
    STRIPE_CARD_BRAND_MAP,
    StripePaymentCardProvider,
} from "web/types/payment-card";
import {
    CheckoutLayoutSerializer,
    CheckoutPaymentInformationSerializer,
} from "web/types/serializers";

interface UsePaymentFormOptions {
    initialData: CheckoutLayoutSerializer;
}

interface BasePaymentFormDetails {
    isPaymentFormComplete: boolean;
    hasPaymentFormErrored: boolean;
    onPaymentInformationChange: (paymentInformation: PaymentInformation) => void;
    paymentFormName: string;
    showPaymentLoadingSpinner: boolean;
}

interface UsePaymentForm extends BasePaymentFormDetails {
    paymentFormData: PaymentInformation;
    setPaymentFormComplete: (complete: boolean) => void;
    setPaymentFormErrored: (errored: boolean) => void;
    setPaymentFormName: (name: string) => void;
    setPaymentLoadingSpinner: (loading: boolean) => void;
}

export interface CheckoutContextPaymentForm extends BasePaymentFormDetails, PaymentInformation {
    isPaymentFormDisabled: boolean;
    onPaymentFormSubmit: (paymentInfo: CheckoutPaymentInformationSerializer) => void;
}

export const CHECKOUT_CONTEXT_PAYMENT_FORM_DEFAULT_DATA: CheckoutContextPaymentForm = {
    isPaymentFormComplete: false,
    isPaymentFormDisabled: true,
    hasPaymentFormErrored: false,
    onPaymentFormSubmit: noop,
    onPaymentInformationChange: noop,
    paymentFormCardType: null,
    paymentFormLast4: null,
    paymentFormName: "",
    showPaymentLoadingSpinner: false,
};

export function convertPaymentInformation(
    serializedPaymentInformation: CheckoutPaymentInformationSerializer
): PaymentInformation {
    return {
        paymentFormLast4: serializedPaymentInformation.card_last_4 || "",
        paymentFormCardType:
            (serializedPaymentInformation.card_provider as PaymentCardProvider) || "",
    };
}

export function normalizeCardBrand(cardBrand: string): PaymentCardProvider | "" {
    const brandLowerCase = cardBrand.toLowerCase();
    return STRIPE_CARD_BRAND_MAP[brandLowerCase as StripePaymentCardProvider];
}

function usePaymentForm({ initialData }: UsePaymentFormOptions): UsePaymentForm {
    const [isPaymentFormComplete, setPaymentFormComplete] = useState<boolean>(
        initialData.is_payment_complete
    );
    const [hasPaymentFormErrored, setPaymentFormErrored] = useState<boolean>(false);
    const [showPaymentLoadingSpinner, setPaymentLoadingSpinner] = useState<boolean>(false);
    const [paymentFormData, setPaymentFormData] = useState<PaymentInformation>(
        convertPaymentInformation(initialData.payment_information)
    );
    const [paymentFormName, setPaymentFormName] = useState<string>(
        `${initialData.billing_information.first_name} ${initialData.billing_information.last_name}`.trim()
    );

    function onPaymentInformationChange(paymentInformation: PaymentInformation): void {
        setPaymentFormData(paymentInformation);
    }

    return {
        isPaymentFormComplete,
        hasPaymentFormErrored,
        onPaymentInformationChange,
        paymentFormData,
        paymentFormName,
        setPaymentFormComplete,
        setPaymentFormErrored,
        setPaymentFormName,
        setPaymentLoadingSpinner,
        showPaymentLoadingSpinner,
    };
}

export default usePaymentForm;

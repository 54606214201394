import clsx from "clsx";
import React, { DetailedHTMLProps, ImgHTMLAttributes } from "react";
import { BaseText, BaseTextProps } from "web/react/emo/shared/components/base-text";
import * as styles from "./designer-or-store-logo.css";

export interface DesignerOrStoreLogoProps
    extends DetailedHTMLProps<ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement> {
    name: string;
    alt: string;
    variant?: keyof typeof styles.noImage;
    as?: BaseTextProps["as"];
}

const textStyles = {
    primary: "large-title",
    secondary: "xxl-title",
} as const;

export function DesignerOrStoreLogo({
    src,
    alt,
    name,
    variant = "primary",
    as = "h1",
    ...props
}: DesignerOrStoreLogoProps): JSX.Element {
    const isDefaultImage = src?.includes("/default-user.jpg");
    return (
        <div
            className={clsx({
                [styles.image]: !!src,
                [styles.noImage[variant]]: !src || isDefaultImage,
            })}
        >
            <div className={styles.content}>
                {src && !isDefaultImage ? (
                    <img src={src} alt={alt} {...props} className={styles.fullWithAndHeight} />
                ) : (
                    <BaseText
                        textStyle={textStyles[variant]}
                        color={variant}
                        aria-label={alt}
                        upperCase
                        as={as}
                    >
                        {name[0]}
                    </BaseText>
                )}
            </div>
        </div>
    );
}

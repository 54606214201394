import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import storage from "web/script/utils/storage";

export interface MainNavigation {
    visible: boolean;
    selectedGender: string;
}

const initialState: MainNavigation = {
    visible: false,
    selectedGender: "",
};

const mainNavigation = createSlice({
    name: "feedsShowSeoContent",
    initialState,
    reducers: {
        showMainNavigation(state): void {
            state.visible = true;
        },
        hideMainNavigation(state): void {
            state.visible = false;
        },
        setSelectedGender(state, action: PayloadAction<string>): void {
            storage.set("selectedGender", action.payload, false);
            state.selectedGender = action.payload;
        },
    },
});

const { actions, reducer } = mainNavigation;
export const { showMainNavigation, hideMainNavigation, setSelectedGender } = actions;
export default reducer;

import { useEffect, useLayoutEffect } from "react";
import globals from "web/script/modules/globals";

const canUseDOM = !!(
    typeof globals.window !== "undefined" &&
    globals.window.document &&
    globals.window.document.createElement
);
/**
 * Warning: useLayoutEffect might cause a mismatch between the initial, non-hydrated UI and the intended UI.
 * To avoid this, it is recommended to use this function on components that render exclusively on the client
 * or to react to events after the first render.
 */
export default canUseDOM ? useLayoutEffect : useEffect;

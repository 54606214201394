import { useEffect } from "react";
import environment from "web/script/modules/environment";
import { setPaidSessionIdOnUrl } from "web/script/utils/paid-session-id";

export function SetPaidSessionIdOnLinks(): null {
    useEffect(() => {
        if (!environment.get("paidSessionId")) {
            return;
        }

        function onEvent(event: MouseEvent | TouchEvent): void {
            const target = (event.target as HTMLElement).closest("a");
            if (!target) {
                return;
            }

            if (target.hasAttribute("href")) {
                const href = target.getAttribute("href") ?? "";
                target.setAttribute(
                    "href",
                    setPaidSessionIdOnUrl(href, environment.get("paidSessionId"))
                );
            }
        }

        window.addEventListener("pointerdown", onEvent, true);
        window.addEventListener("contextmenu", onEvent, true);
        return () => {
            window.removeEventListener("pointerdown", onEvent, true);
            window.removeEventListener("contextmenu", onEvent, true);
        };
    }, []);

    return null;
}

import React from "react";
import { Breadcrumbs as InitialBreadcrumbs } from "web/react/components/breadcrumbs/breadcrumbs";
import { ProductLayoutSerializer } from "web/types/serializers";

export function Breadcrumbs({
    productLayout,
}: {
    productLayout: ProductLayoutSerializer;
}): JSX.Element | null {
    const { product_breadcrumbs: productBreadcrumbs } = productLayout;

    return (
        <div>
            {productBreadcrumbs && (
                <InitialBreadcrumbs breadcrumbs={productBreadcrumbs.breadcrumbs} type="pdp" />
            )}
        </div>
    );
}

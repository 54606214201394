import map from "lodash/map";
import React from "react";
import CloseButton from "web/react/components/buttons/close-button/close-button";
import { Option } from "web/react/components/select/types";
import SVGIcon from "web/react/components/svg-icon/svg-icon";
import { gettext } from "web/script/modules/django-i18n";
import { Schemas } from "web/types/examples/schemas";
import styles from "./option-heading.module.css";

export interface SizePickerOptionHeadingProps {
    onCloseButtonClick: (
        event?: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>
    ) => void;
}

export function SizePickerOptionHeading({
    onCloseButtonClick,
}: SizePickerOptionHeadingProps): JSX.Element {
    // "Select a size"
    const sizeSelectLabel = gettext("product.buy_area.select_size_menu.title");
    return (
        <div className={styles.optionHeadingMobile}>
            <h4 className={styles.header}>{sizeSelectLabel}</h4>
            <CloseButton className={styles.closeButton} onClick={onCloseButtonClick} />
        </div>
    );
}

export interface SelectableSchemaSizePickerOptionHeadingProps extends SizePickerOptionHeadingProps {
    onSchemaSelect: (event) => void;
    schemas: Schemas;
    selectedSchema: string;
}

export function SelectableSchemaSizePickerOptionHeading({
    onCloseButtonClick,
    onSchemaSelect,
    schemas,
    selectedSchema,
}: SelectableSchemaSizePickerOptionHeadingProps): JSX.Element {
    // "Store size"
    const storeSizeLabel = gettext("product.buy_area.store_size.label");

    // "{schema_size} size"
    const selectedSchemaLabel = gettext("product.buy_area.schema_size.label", {
        schema_size: schemas[selectedSchema],
    });

    function handleSelectOnClick(event): void {
        event.stopPropagation();
    }

    const schemaOptions: Option[] = map(schemas, (schemaDisplayText, schemaValue): Option => {
        return {
            selectedText: schemaDisplayText,
            text: [schemaDisplayText],
            value: schemaValue,
        };
    });

    function handleChange(event): void {
        onSchemaSelect(event);
    }

    return (
        <>
            <SizePickerOptionHeading onCloseButtonClick={onCloseButtonClick} />
            <div className={styles.optionHeading}>
                <div
                    className={styles.selectWrapper}
                    onClick={handleSelectOnClick}
                    data-testid="select-wrapper"
                >
                    <div className={styles.schemaSelectLabel}>{selectedSchemaLabel}</div>
                    <SVGIcon name="chevron-medium" className={styles.chevronIcon} />
                    <select
                        data-testid="schema-select-box"
                        className={styles.selectDropdown}
                        onChange={handleChange}
                        value={selectedSchema}
                    >
                        {schemaOptions.map((schemaOption) => {
                            return (
                                <option key={schemaOption.value} value={schemaOption.value}>
                                    {schemaOption.text[0]}
                                </option>
                            );
                        })}
                    </select>
                </div>
                <div className={styles.storeSizeLabel}>{storeSizeLabel}</div>
            </div>
        </>
    );
}

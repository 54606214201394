import React from "react";
import { Stack, StackProps } from "web/react/emo/shared/components/stack/stack";

export type VStackProps = Pick<
    StackProps,
    "children" | "spacing" | "align" | "justify" | "dataTestId"
>;

export function VStack({ children, ...props }: VStackProps): JSX.Element {
    return (
        <Stack direction="column" {...props}>
            {children}
        </Stack>
    );
}

import { MutableRefObject, useEffect, useState } from "react";

export function useDragScroll(
    targetRef: MutableRefObject<HTMLDivElement | null>,
    speed = 1
): number[] {
    const [startDrag, setStartDrag] = useState(0);
    const [endDrag, setEndDrag] = useState(0);

    useEffect(() => {
        if (targetRef.current == null) return;
        const slider = targetRef.current;
        let isDown = false;
        let startX;
        let scrollLeft;

        function startDragging(e): void {
            isDown = true;
            startX = e.pageX - slider.offsetLeft;
            scrollLeft = slider.scrollLeft;
            setStartDrag(scrollLeft);
        }

        function endDrag(): void {
            isDown = false;
            setEndDrag(slider.scrollLeft);
        }

        function dragMove(e): void {
            if (!isDown) return;
            const x = e.pageX - slider.offsetLeft;
            const step = (x - startX) * speed;
            slider.scrollLeft = scrollLeft - step;
        }

        slider.addEventListener("mousedown", startDragging);
        slider.addEventListener("mouseleave", endDrag);
        slider.addEventListener("mouseup", endDrag);
        slider.addEventListener("mousemove", dragMove);

        return (): void => {
            slider.removeEventListener("mousedown", startDragging);
            slider.removeEventListener("mouseleave", endDrag);
            slider.removeEventListener("mouseup", endDrag);
            slider.removeEventListener("mousemove", dragMove);
        };
    }, [targetRef, speed]);

    return [startDrag, endDrag];
}

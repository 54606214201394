import kelkoo from "web/script/embedded/kelkoo";
import environment from "web/script/modules/environment";

const ACCOUNTS = {
    UK: 100506873,
    DE: 100501708,
    US: 100501707,
};

export default {
    send: function (data) {
        const predictedRPL = environment.get("predictedTrackValue");
        let country = environment.get("country");
        if (country === "GB") {
            country = "UK";
        }
        const merchantId = ACCOUNTS[country];

        if (predictedRPL && environment.get("trafficSource") == "kelkoo" && merchantId) {
            data.forEach((event) => {
                if (event.data.action === "lead") {
                    let data = {
                        merchantInfo: [
                            {
                                country: country,
                                merchantId: merchantId,
                            },
                        ],
                        orderValue: predictedRPL,
                        orderId: event.data.label,
                    };
                    kelkoo(data);
                    return Promise.resolve();
                }
            });
        }
    },
};

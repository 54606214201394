import _find from "lodash/find";
import { useState } from "react";
import { PendingFilters } from "web/react/components/feeds-filters/pending-filters";
import analytics from "web/script/analytics/analytics";
import logging from "web/script/utils/logging";
import { UniversalFilterOptionSerializer } from "web/types/serializers";
import {
    SelectFilterOptionGroup,
    UniversalFilterOption,
    UniversalFiltersData,
} from "web/types/universal-filters";

export function purgeAllValues(
    query: URLSearchParams,
    pending: PendingFilters,
    name: string
): void {
    for (let value of query.getAll(name)) {
        pending.setChecked(name, value, false);
    }
}

function getAnalyticsName(
    name: UniversalFilterOption["name"],
    value: UniversalFilterOption["value"]
): string {
    let analyticsLabel = name;
    // Ported from universal-filters.js to keep the analytics labels in sync.
    switch (analyticsLabel) {
        // get the analytics label from the gender
        case "gender":
            // Converts the value 'Men' to '_men'.
            // Note 'Men' is the input value, not the label.
            // The label shown to the user will be translated.
            analyticsLabel += `_${value.toString().toLowerCase()}`;
            break;
        // convert final price to / from to a general price range event
        case "final_price_to":
        case "final_price_from":
            analyticsLabel = "price_range";
            break;
    }

    return analyticsLabel;
}

/**
 * Adds a prefix to the Analytics label.
 *
 * We have different components which are triggering the same Analytics events.
 * For example, clicking on `FeedsFilterBar` (feeds header) or `BreadcrumbPills` (overlay aside filter)
 * will result in the same Analytics event.
 * As we are performing A/B Testing, we want to distinguish where a certain event comes from.
 * Because of this - we are making the event's label unique, as adding a prefix in front of it.
 */
export function getPrefixedAnalyticsLabel(
    name: UniversalFilterOption["name"],
    event_prefix: UniversalFilterOption["event_prefix"] = "filter_nav"
): string {
    return `${event_prefix}_${name}`;
}

/**
 * Attempts to find the default value for a selectbox
 * TODO: Can we send the default value in the option group??
 */
export function getDefaultSelectOption(
    selected: UniversalFilterOption | UniversalFilterOptionSerializer,
    allFilters: UniversalFiltersData
): UniversalFilterOption | UniversalFilterOptionSerializer {
    let { name } = selected;

    let section = _find(allFilters.filter_section_list, (section) =>
        section.all_option_names.includes(name)
    );

    if (!section) {
        // Ooops?
        logging.error(`Could not find selectbox for ${name}?!`);
        return selected;
    }

    let optionGroup = _find(
        section.option_groups as SelectFilterOptionGroup[],
        (opt) => opt.options[0].name == name
    );

    if (!optionGroup) {
        // humm
        logging.error(`Could not find selectbox for ${name}?!`);
        return selected;
    }

    // Special case
    if (name == "final_price_to") {
        return optionGroup.options[optionGroup.options.length - 1];
    }
    return optionGroup.options[0];
}

function handleFilterClick(
    filter: UniversalFilterOption | UniversalFilterOptionSerializer,
    pendingFilterInfo: PendingFilters,
    displayedFilters: UniversalFiltersData
): PendingFilters {
    let { name, value: newValue, type, event_prefix } = filter;

    newValue = newValue.toString();

    let newPendingFilters = new PendingFilters(pendingFilterInfo);

    let isCurrentlyChecked = newPendingFilters.getChecked(filter);

    let filterQuery = pendingFilterInfo.toQuery(displayedFilters, false);
    let oldValue = filterQuery.get(name);

    let analyticsLabel = getAnalyticsName(name, newValue);
    analyticsLabel = getPrefixedAnalyticsLabel(analyticsLabel, event_prefix);

    // Special cases
    if (newValue != oldValue || isCurrentlyChecked) {
        if (name == "product_type") {
            purgeAllValues(filterQuery, newPendingFilters, "category");
        }

        if (name == "product_type" || name == "category") {
            purgeAllValues(filterQuery, newPendingFilters, "subcategory");
        }
    }

    if (type == "checkbox") {
        if (isCurrentlyChecked) {
            newPendingFilters.setChecked(name, newValue, false);
            analytics.event("filter", "remove_filter", analyticsLabel);
        } else {
            newPendingFilters.setChecked(name, newValue, true);
            analytics.event("filter", "add_filter", analyticsLabel);
        }
    } else if (type == "option") {
        // The default option for prices is sometimes not in the query
        if (oldValue == null) {
            oldValue = getDefaultSelectOption(filter, displayedFilters).value.toString();
            // Update the query since it's used to set values to pending=false
            filterQuery.set(name, oldValue);
        }
        // You can never unselect an option, only select a different one
        if (newValue != oldValue) {
            purgeAllValues(filterQuery, newPendingFilters, name);
            newPendingFilters.setChecked(name, newValue, true);
            // As per old filter behaviour don't send a remove event when it is removed via selecting another option
            analytics.event("filter", "add_filter", analyticsLabel);
        }
    } else {
        // radio or category (same behaviour)
        purgeAllValues(filterQuery, newPendingFilters, name);
        if (oldValue != null) {
            let analyticsLabel = getAnalyticsName(name, oldValue);
            analyticsLabel = getPrefixedAnalyticsLabel(analyticsLabel, event_prefix);

            analytics.event("filter", "remove_filter", analyticsLabel);
        }
        if (!isCurrentlyChecked) {
            newPendingFilters.setChecked(name, newValue, true);
            analytics.event("filter", "add_filter", analyticsLabel);
        }
    }

    return newPendingFilters;
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export function useFilterClickHandler(displayedFilters: UniversalFiltersData) {
    const [pendingFilterInfo, setPendingFilterInfo] = useState(new PendingFilters());

    /**
     * Universal handler for filtery things being clicked on
     * @param filter The filter item the user clicked on
     */
    function toggleFilter(
        filter: UniversalFilterOption | UniversalFilterOptionSerializer
    ): PendingFilters {
        let newPendingFilters = handleFilterClick(filter, pendingFilterInfo, displayedFilters);
        setPendingFilterInfo(newPendingFilters);
        return newPendingFilters;
    }

    function toggleMultipleFilters(
        filters: UniversalFilterOption[] | UniversalFilterOptionSerializer[]
    ): PendingFilters {
        let _pendingFilterInfo = pendingFilterInfo;

        for (const filter of filters) {
            _pendingFilterInfo = handleFilterClick(filter, _pendingFilterInfo, displayedFilters);
        }

        setPendingFilterInfo(_pendingFilterInfo);
        return _pendingFilterInfo;
    }

    function removeAllSelectedFilters(
        selectedFilters: UniversalFilterOption[] | UniversalFilterOptionSerializer[]
    ): PendingFilters {
        let newPendingFilters = pendingFilterInfo;

        for (let filter of selectedFilters) {
            // These special cases are all purged when filter.name === "product_type" is removed.
            // This assumes the parent filter "product_type" is in selectedFilters, which should be
            // the case if one of these child filters have been selected.
            if (filter.name === "category" || filter.name === "subcategory") {
                continue;
            }

            if (filter.type === "option") {
                filter = getDefaultSelectOption(filter, displayedFilters);
            }

            newPendingFilters = handleFilterClick(filter, newPendingFilters, displayedFilters);
        }

        setPendingFilterInfo(newPendingFilters);
        return newPendingFilters;
    }

    function resetPendingFilters(): void {
        setPendingFilterInfo(new PendingFilters());
    }

    return {
        toggleFilter,
        toggleMultipleFilters,
        pendingFilterInfo,
        resetPendingFilters,
        removeAllSelectedFilters,
    };
}

import baustein from "baustein";
import analytics from "web/script/analytics/analytics";
import overlayMixin from "web/script/components/mixins/overlay-mixin";
import requester from "web/script/modules/requester";

export default baustein.register("generic-overlay", {
    mixins: [overlayMixin],

    defaultOptions: {
        contentUrl: null,
        overlaySize: "large",
        eventCategory: null,
        eventAction: null,
        eventLabel: null,
    },

    getOverlayContent() {
        const options = {
            headers: {
                Accept: "text/html",
            },
        };
        return requester(this.options.contentUrl, options).then(requester.toText);
    },

    onOverlayOpen() {
        if (this.options.eventCategory && this.options.eventAction) {
            const { eventCategory, eventAction, eventLabel } = this.options;
            analytics.event(eventCategory, eventAction, eventLabel);
        }
    },

    onOverlayCloseIntent() {
        this.close();
    },
});

import { useEffect } from "react";
import { useDispatch, useSelector as useReduxSelector } from "react-redux";
import { setGenderOptions } from "web/redux/ducks/feed-sidebar";
import { ReduxStoreState } from "web/redux/store";
import { InputFilterOption, UniversalFiltersData } from "web/types/universal-filters";

export function extractGenderFromFilters(
    filters: UniversalFiltersData
): InputFilterOption[] | undefined {
    const genderSection = filters.filter_section_list.find(
        (section) => section.id === "section-gender"
    );

    if (!genderSection) {
        return;
    }

    return genderSection.option_groups[0].options as any;
}

/**
 * Makes sure the external gender switcher has the right info
 */
export function useExternalGender(activeFilters: UniversalFiltersData): void {
    const dispatch = useDispatch();

    // Find out if we've been sent any genders by the server
    const genders = extractGenderFromFilters(activeFilters);

    useEffect(() => {
        if (genders) {
            // Store avaiable genders in redux so the user can switch more easily
            dispatch(setGenderOptions(genders));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [genders]);
}

function extractSelectedGender(state: ReduxStoreState): InputFilterOption | null {
    return state.feedSidebarReducer.selectedGender;
}

export function useSelectedGender(): InputFilterOption | null {
    return useReduxSelector(extractSelectedGender);
}

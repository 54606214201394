import React from "react";
import GenericModal from "web/react/components/generic-modal/generic-modal";
import LoadingSpinner from "web/react/components/loading-spinner/loading-spinner";
import analytics from "web/script/analytics/analytics";
import { gettext } from "web/script/modules/django-i18n";
import requester from "web/script/modules/requester";
import styles from "./size-guide.module.css";

function SizeGuide({ sizeGuideLink }: { sizeGuideLink: string }): React.ReactElement {
    const [isOpen, setIsOpen] = React.useState(false);
    const [content, setContent] = React.useState<string>("");

    async function trigger(event: React.SyntheticEvent): Promise<void> {
        event.preventDefault();

        const options = {
            headers: {
                Accept: "text/html",
            },
        };
        setIsOpen(true);
        const response = await requester(sizeGuideLink, options).then(requester.toText);
        setContent(response);
    }

    function sendAnalytics(): void {
        analytics.event("buy_area", "show_size_guide");
    }

    return (
        <div className={styles.link}>
            <a data-testid="size-guide" href={sizeGuideLink} onClick={trigger}>
                {/* Size guide */}
                {gettext("in_stock_product.size_guide")}
            </a>
            <GenericModal
                isOpen={isOpen}
                onAfterOpen={sendAnalytics}
                onClose={(): void => setIsOpen(false)}
                className={content ? styles.modal : styles.loadingModal}
            >
                {content ? (
                    <div
                        className={styles.modalContent}
                        dangerouslySetInnerHTML={{ __html: content }}
                    />
                ) : (
                    <LoadingSpinner />
                )}
            </GenericModal>
        </div>
    );
}

export default SizeGuide;

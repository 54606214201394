import loader from "./loader";

window.criteo_q = window.criteo_q || [];

const load = loader(() => {
    let d = document;
    let g = d.createElement("script");
    let s = d.getElementsByTagName("script")[0];
    g.type = "text/javascript";
    g.async = true;
    g.src = "//static.criteo.net/js/ld/ld.js";
    s.parentNode.insertBefore(g, s);
});

export default function (...args) {
    load();
    return window.criteo_q.push(...args);
}

import { isExcludedDesignerOnPage } from "web/react/components/webviews/financial-incentive-webviews/financial-incentive-utils";
import analytics from "web/script/analytics/analytics";
import environment from "web/script/modules/environment";
import globals from "web/script/modules/globals";
import url, { NotQuiteAURL } from "web/script/utils/url";

const ANALYTICS_CATEGORY_WHITELIST = new Set([
    "filter",
    "feeds_show_more",
    "feed",
    "sort_by",
    "nav_search",
    "product_card",
    "save_for_later",
    // The EmailCapture overlay is opened...
    "signup_pdp_save",
    "signup_lead_save",
    "signup_feed_save",
    "oos_notify_me",
    "signup_du",
    "signup_search",
    "stock_alert",
    "stock_alert_lead_return",
    "signup_oos_save",
    // Signed up
    "signup_header",
    // Tracking
    "affiliate",
    // icon
    "cart",
    "buy_area",
    "get_updates_designer",
]);

const DEEPLINK_PARAMS_WHITELIST = new Set(["q", "term", "gender"]);

export enum RawBranchLink {
    DesignerUpdates = "https://lyst.app.link/KpHF8KyBZ3",
    SaveForLater = "https://lyst.app.link/4fHObAlxZ3",
    Footer = "https://lyst.app.link/9Q09TGy4U5",
    AppPage = "https://lyst.app.link/84mTCgEAd6",
    AppTeaser = "https://lyst.app.link/kBbgC3bBd6",
    ShowMore = "https://lyst.app.link/KJLyM3c0Q6",
    ComparePrices = "https://lyst.app.link/bK2Ohz8WX6",
    OutOfStockShowMore = "https://lyst.app.link/IQxqsiBh96",
    InStockShowMore = "https://lyst.app.link/T68wuPvh96",
    Homepage = "https://lyst.app.link/rcdNHcFLL7",
    InStockShopNow = "https://lyst.app.link/TlNFPzNPO7",
    OOSGoToRetailer = "https://lyst.app.link/UXDONsGuubb ",
    AppSignupS4L = "https://lyst.app.link/9y2ZaHeprdb",
    AppSignupBIS = "https://lyst.app.link/DPnaBnaprdb",
    AppSignupDU = "https://lyst.app.link/1zhPVs7ordb",
    AppSignupTimedBlock = "https://lyst.app.link/oIejTjm46gb",
    OOSNotifyMe = "https://lyst.app.link/F8J1fQuc9hb",
    ProductCardGeneralFeed = "https://lyst.app.link/SlNFPzNPO7",
    ProductCardGeneralFeedClick2 = "https://lyst.app.link/lJmoADpr0lb",
    ProductCardGeneralFeedClick3 = "https://lyst.app.link/7l0ueRur0lb",
    ProductCardGeneralFeedClick4 = "https://lyst.app.link/pRGgvjzr0lb",
    ProductCardISPDPRelatedFeed = "https://lyst.app.link/VlNFPzNPO7",
    HardBlockInStockShopNow = "https://lyst.app.link/pP2lfP5c4kb",
    PasswordResetComplete = "https://lyst.app.link/account/login",
    PageNotFound = "https://lyst.app.link/qs2qWaNaSlb",
    SizeOOSAlertMe = "https://lyst.app.link/TKAyDzsbqob",
    FollowNewArrivals = "https://lyst.app.link/UF9IZ6nShob",
    GalleryModule = "https://lyst.app.link/T8Y0CxTPxpb",
    NavigationMenu = "https://lyst.app.link/4iMEbxfQprb",
    MySizesOnboarding = "https://lyst.app.link/vghFFbkNmHb",
}

export enum CampaignRawBranchLink {
    LeadReturn = "https://lyst.app.link/1xX3NV4Xp7",
    ISPDP = "https://lyst.app.link/2xX3NV4Xp7",
    OOSPDP = "https://lyst.app.link/4xX3NV4Xp7",
    Feed = "https://lyst.app.link/3xX3NV4Xp7",
    SignedInUsers = "https://lyst.app.link/5xX3NV4Xp7",
    Homepage = "https://lyst.app.link/6xX3NV4Xp7",
    TrackEmailInterstitial = "https://lyst.app.link/pR32yrZorab",
}

export enum FinancialIncentivesCampaignRawBranchLink {
    Homepage = "https://lyst.app.link/6vdRy1MoS9",
    Feed = "https://lyst.app.link/4vdRy1MoS9",
    ISPDP = "https://lyst.app.link/5vdRy1MoS9",
    InStockModal = "https://lyst.app.link/TWlVyJJRKbb",
}

export enum DesktopCampaignRawBranchLink {
    Feed = "https://lyst.app.link/oR32yrZorab",
}

export const BranchLinkCampaignName = {
    [RawBranchLink.DesignerUpdates]: "Web to App - Designer Updates",
    [RawBranchLink.SaveForLater]: "Web to App - Save for Later",
    [RawBranchLink.OOSNotifyMe]: "Web to App - Out of Stock PDP - BIS Button",
    [RawBranchLink.Footer]: "Web to App - Footer",
    [RawBranchLink.AppPage]: "Web to App - App landing page Button",
    [RawBranchLink.AppTeaser]: "Web to App - App landing page Image",
    [RawBranchLink.ShowMore]: "Web to App - Feed - Show More Button",
    [RawBranchLink.ComparePrices]: "Web to App - Compare prices link",
    [RawBranchLink.OutOfStockShowMore]: "Web to App - Out of Stock PDP - Show More Button",
    [RawBranchLink.InStockShowMore]: "Web to App - In Stock PDP - Show More Button",
    [RawBranchLink.Homepage]: "Web to App - New Homepage - App module link",
    [RawBranchLink.InStockShopNow]: "Web to App - In Stock PDP - Shop Now button",
    [RawBranchLink.OOSGoToRetailer]: "Web to App - Out of Stock PDP - Go Retailer Button",
    [RawBranchLink.AppSignupS4L]: "Web to App - Signup touchpoint - S4L",
    [RawBranchLink.AppSignupBIS]: "Web to App - Signup touchpoint - BIS",
    [RawBranchLink.AppSignupDU]: "Web to App - Signup touchpoint - DU",
    [RawBranchLink.AppSignupTimedBlock]: "Web to App - Signup touchpoint - Timed",
    [RawBranchLink.ProductCardGeneralFeed]: "Web to App - Feed - Product card click",
    [RawBranchLink.ProductCardGeneralFeedClick2]: "Web to App - Feed - Product card click 2",
    [RawBranchLink.ProductCardGeneralFeedClick3]: "Web to App - Feed - Product card click 3",
    [RawBranchLink.ProductCardGeneralFeedClick4]: "Web to App - Feed - Product card click 4",
    [RawBranchLink.ProductCardISPDPRelatedFeed]:
        "Web to App - In Stock PDP - Related Products click",
    [RawBranchLink.HardBlockInStockShopNow]:
        "Web to App - In Stock PDP - Hard block Shop Now button",
    [RawBranchLink.PasswordResetComplete]: "Web to App - Reset Password Complete - Log in Click",
    [RawBranchLink.PageNotFound]: "Web to App - 404",
    [RawBranchLink.SizeOOSAlertMe]: "Web to App - In Stock PDP - OOS size",
    [RawBranchLink.FollowNewArrivals]: "Web to App - In Stock PDP - New Arrivals Module",
    [RawBranchLink.GalleryModule]: "Web to App - In Stock PDP - Gallery module",
    [RawBranchLink.NavigationMenu]: "Web to App - Navigation",
    [CampaignRawBranchLink.LeadReturn]: "Web to App - CSS Lead Return - General",
    [CampaignRawBranchLink.ISPDP]: "Web to App - In Stock PDP - General",
    [CampaignRawBranchLink.OOSPDP]: "Web to App - Out of Stock PDP - General",
    [CampaignRawBranchLink.Feed]: "Web to App - Feed - General",
    [CampaignRawBranchLink.SignedInUsers]: "Web to App - Signed In Users - General",
    [CampaignRawBranchLink.Homepage]: "Web to App - Homepage - General",
    [CampaignRawBranchLink.TrackEmailInterstitial]: "Web to App - Email - Intermediary screen",
    [FinancialIncentivesCampaignRawBranchLink.Homepage]:
        "Web to App - Homepage - Financial Incentive",
    [FinancialIncentivesCampaignRawBranchLink.Feed]: "Web to App - Feed - Financial Incentive",
    [FinancialIncentivesCampaignRawBranchLink.ISPDP]:
        "Web to App - In Stock PDP - Financial Incentive",
    [FinancialIncentivesCampaignRawBranchLink.InStockModal]:
        "Web to App - In Stock PDP - Shop Now - Financial Incentive",
    [DesktopCampaignRawBranchLink.Feed]: "Web to App - Desktop - Feed - General banner",
    [RawBranchLink.MySizesOnboarding]: "Web to App - My Sizes Onboarding",
};

export interface BranchIOLinkData {
    web_session_id: string;
    page_type: string; // one of ?
    page_sub_type: string;
    web_device_id: string;
    web_pageview_id: string;
    user_id?: string;
    $deeplink_path?: string;
    utm_medium?: string;
    utm_campaign?: string;
    utm_source?: string;
    utm_content?: string;
    utm_term?: string;
    utm_country?: string;
    utm_grouping?: string;
    utm_keywords?: string;
    atc_medium?: string;
    atc_campaign?: string;
    atc_country?: string;
    atc_grouping?: string;
    atc_keywords?: string;
    atc_source?: string;
    atc_content?: string;
    atc_term?: string;
    atc_remarketing?: string;
    webview_path?: "webview" | undefined;
    webview_token?: string;
}

export interface BranchIOMetaData {
    trafficSource: string;
    lifeCycleStage: string;
    isReturningUser: boolean;
    country: string;
    domain: string;
    page_sub_type: string;
    page_type: string;
    initial_page_sub_type: string;
    initial_page_type: string;
    user_id?: string;
}

export interface SimpleAnalyticsEvent {
    type: string;
    data: {
        category: string;
        action: string;
        label?: string;
        life_cycle_stage: string;
        returning_user: boolean;
        country: string;
        domain: string;
        page_sub_type: string;
        page_type: string;
        initial_page_sub_type: string;
        initial_page_type: string;
        user_id: number | null;
    };
}

function getTrafficSource(): "paid" | "other" {
    let params = url.parse(globals.window.location.href).searchParams;

    return params.get("atc_medium") === "cpc" || params.get("utm_medium") === "cpc"
        ? "paid"
        : "other";
}

function addAttributionData(linkData: BranchIOLinkData): BranchIOLinkData {
    let trafficAttribution = analytics.getTrafficAttribution();
    if (trafficAttribution) {
        Object.keys(trafficAttribution).forEach((key) => {
            if (trafficAttribution[key] === null) {
                delete trafficAttribution[key];
            }
        });
    }
    return { ...linkData, ...trafficAttribution };
}

function addWebviewParameters(
    linkData: BranchIOLinkData,
    pageType: string,
    pageSubType: string,
    financialIncentiveWebviewToken?: string
): BranchIOLinkData {
    type WebViewParameters = { webview_path: "webview"; webview_token: string };
    let webviewToken;
    if (financialIncentiveWebviewToken) {
        webviewToken = financialIncentiveWebviewToken;
    }
    if (webviewToken) {
        let webviewParameters: WebViewParameters = {
            webview_path: "webview",
            webview_token: webviewToken,
        };
        return { ...linkData, ...webviewParameters };
    } else {
        return linkData;
    }
}

export function getBranchCampaignName(branchLink: string): string {
    return BranchLinkCampaignName[branchLink] || "";
}

function validateSearchParams(search: string): string {
    const deeplinkSearchParams = new URLSearchParams();
    const searchParams = new URLSearchParams(search);

    searchParams.forEach((value, key) => {
        if (DEEPLINK_PARAMS_WHITELIST.has(key)) {
            deeplinkSearchParams.append(key, value);
        }
    });

    return deeplinkSearchParams.toString();
}

export function getLinkData(
    appDeepLinkURL?: URL | NotQuiteAURL,
    financialIncentiveWebviewToken?: string
): BranchIOLinkData {
    const pageType = environment.get("pageType");
    const pageSubType = environment.get("pageSubType");
    let branchIOLinkData: BranchIOLinkData = {
        web_session_id: analytics.getSessionId(),
        page_type: pageType,
        page_sub_type: pageSubType,
        web_device_id: analytics.getDeviceUid(),
        web_pageview_id: analytics.getPageViewId(),
    };
    branchIOLinkData = addAttributionData(branchIOLinkData);
    if (!isExcludedDesignerOnPage()) {
        branchIOLinkData = addWebviewParameters(
            branchIOLinkData,
            pageType,
            pageSubType,
            financialIncentiveWebviewToken
        );
    }
    const userId = environment.get("userId");
    if (userId) {
        branchIOLinkData.user_id = userId;
    }

    let validDeepLinkURL;
    if (appDeepLinkURL) {
        validDeepLinkURL = new URL(`https://lyst.com${appDeepLinkURL.pathname}`);
        validDeepLinkURL.search = validateSearchParams(appDeepLinkURL.search);
    } else {
        validDeepLinkURL = new URL(`https://lyst.com${globals.window.location.pathname}`);
        validDeepLinkURL.search = validateSearchParams(globals.window.location.search);
    }

    branchIOLinkData["$deeplink_path"] = validDeepLinkURL.toString();

    return branchIOLinkData;
}

export function getBranchMetadata(event: SimpleAnalyticsEvent): BranchIOMetaData {
    let branchMetaData: BranchIOMetaData = {
        trafficSource: getTrafficSource(),
        lifeCycleStage: event.data.life_cycle_stage,
        isReturningUser: event.data.returning_user,
        country: event.data.country,
        domain: event.data.domain,
        page_sub_type: event.data.page_sub_type,
        page_type: event.data.page_type,
        initial_page_sub_type: event.data.initial_page_sub_type,
        initial_page_type: event.data.initial_page_type,
    };

    if (event.data.user_id) {
        branchMetaData.user_id = event.data.user_id.toString();
    }

    return branchMetaData;
}

export function getBranchAnalyticsName(event: SimpleAnalyticsEvent): string {
    // Do not append label as it makes targeting too granular in branch.
    let branchIOEventName = `${event.data.category}_${event.data.action}`;
    return branchIOEventName;
}

export function shouldSendAnalytics(event: SimpleAnalyticsEvent): boolean {
    return event.type === "event" && ANALYTICS_CATEGORY_WHITELIST.has(event.data.category);
}

import React, { useEffect } from "react";
import { DesignerOrStoreLogo } from "web/react/components/designer-or-store-logo/";
import { FeedHeaderWithLogo } from "web/react/components/feed-header-with-logo/LEGACY/feed-header-with-logo";
import TabSwitcher from "web/react/components/tab-switcher/tab-switcher";
import analytics from "web/script/analytics/analytics";
import { gettext } from "web/script/modules/django-i18n";
import { FollowedLystsItemDataSerializer, FollowedLystsSerializer } from "web/types/serializers";
import styles from "./followed-lysts.module.css";

function getTypeTranslation(type: string): string {
    // 'Brand' : 'Store'
    return type === "designer" ? gettext("lyst_type.designer") : gettext("lyst_type.retailer");
}

interface FollowedSectionTileProps {
    lystUrl: string;
    name: string;
    type: string;
    imageUrl?: string;
}

function FollowedSectionTile({
    lystUrl,
    name,
    type,
    imageUrl,
}: FollowedSectionTileProps): JSX.Element {
    return (
        <li className={styles.tabSectionItem}>
            <figure>
                <a
                    href={lystUrl}
                    onClick={() => analytics.event("followed_lysts", `${type}_card_click`, name)}
                    className={styles.imageWrapper}
                >
                    <DesignerOrStoreLogo
                        src={imageUrl}
                        alt={
                            // "{designer_name} logotype"
                            gettext("feed.designer_logo.image_alt_text", {
                                designer_name: name,
                            })
                        }
                        name={name}
                        variant={"secondary"}
                    />
                </a>
                <figcaption>
                    <h5 className={styles.itemDescriptionTitle}>{name}</h5>
                    <p className={styles.itemDescriptionSubTitle}>{type}</p>
                </figcaption>
            </figure>
        </li>
    );
}

function FollowedTabSection({
    elements,
}: {
    elements: FollowedLystsItemDataSerializer[];
}): JSX.Element {
    return (
        <ul className={styles.tabSectionWrapper}>
            {elements.map(({ lyst_url, name, image_url, type, id }) => (
                <FollowedSectionTile
                    key={id}
                    lystUrl={lyst_url}
                    name={name}
                    imageUrl={image_url}
                    type={getTypeTranslation(type)}
                />
            ))}
        </ul>
    );
}

export interface FollowedLystsProps {
    followedLysts: FollowedLystsSerializer;
    pageTitle: string;
}

function FollowedLysts({
    followedLysts: { followed_brands, followed_stores },
    pageTitle,
}: FollowedLystsProps): JSX.Element {
    const sections = [followed_brands, followed_stores];

    useEffect(() => {
        analytics.event("followed_lysts", "page_view");
    }, []);

    return (
        <div className={styles.contentWrapper}>
            <FeedHeaderWithLogo title={pageTitle} />
            <TabSwitcher className={styles.tabSwitcherWrapper}>
                <TabSwitcher.Header className={styles.tabSwitcherHeader}>
                    {sections.map(({ label }) => (
                        <TabSwitcher.Tab
                            key={label}
                            onClick={() => analytics.event("followed_lysts", "tab_click", label)}
                        >
                            <TabSwitcher.Tab.InnerTab name={label} />
                        </TabSwitcher.Tab>
                    ))}
                </TabSwitcher.Header>
                {sections.map(({ label, data }) => (
                    <FollowedTabSection key={label} elements={data} />
                ))}
            </TabSwitcher>
        </div>
    );
}

export default FollowedLysts;

import React, { useEffect, useRef } from "react";
// Components
import { RelatedProductsArea } from "web/react/components/__LEGACY__/LEGACY_related-products-area/related-products-area";
import CuratedAds from "web/react/components/__LEGACY__/curated-ads/curated-ads";
import AdsenseShopping from "web/react/components/adsense-shopping/adsense-shopping";
import { AppFooter } from "web/react/components/app-footer/app-footer";
import { BrandAdContainer } from "web/react/components/brand-ad-container";
import { Breadcrumbs } from "web/react/components/breadcrumbs/breadcrumbs";
import ProductDescription from "web/react/components/buybuybuy-area/product-description/product-description";
import { CustomerCaptureOverlay } from "web/react/components/customer-capture-overlay/customer-capture-overlay";
import { FooterProps } from "web/react/components/footer/footer";
import OOSProductHero from "web/react/components/oos-product-hero/oos-product-hero";
import PDPGallery from "web/react/components/pdp-gallery/pdp-gallery";
import { ProductOverlay } from "web/react/components/product-overlay";
import ProductRetailers from "web/react/components/product-retailers/product-retailers";
import { RecentlyViewed } from "web/react/components/recently-viewed/recently-viewed";
import { RelatedSearchesRail } from "web/react/components/related-searches-rail/related-searches-rail";
import { VStack } from "web/react/emo/v-stack";
// eslint-disable-next-line css-modules/no-unused-class
import styles from "web/react/pages/product/out-of-stock/out-of-stock-product-page/out-of-stock-product-page.module.css";
import { gettext } from "web/script/modules/django-i18n";
import userProfiler from "web/script/modules/userprofiler";
import { OOSProductLayoutSerializer } from "web/types/serializers";

interface OutOfStockProductPageProps {
    productLayout: OOSProductLayoutSerializer;
    productUrl: string;
    variant: "affiliate" | "icon";
    footerData?: FooterProps;
    isOverlay?: boolean;
}

export function OutOfStockProductPage({
    productLayout,
    productUrl,
    variant,
    footerData,
    isOverlay = false,
}: OutOfStockProductPageProps): React.ReactElement {
    const overlayRef = useRef(null);

    const {
        product_analytics_data: productAnalyticsData,
        brand_ad: brandAd,
        curated_ads: curatedAds,
        product_hero_card: productHeroCard,
        retailer_data: retailerData,
        adsense_for_shopping: adsenseForShopping,
        related_products: relatedProducts,
        related_search_rail: relatedSearchRail,
        product_breadcrumbs: productBreadcrumbs,
        product_description: productDescription,
        product_more_descriptions: productMoreDescriptions,
        product_image_gallery: productImageGallery,
        product_retailers: productRetailers,
        recently_viewed_products: recentlyViewedProducts,
        footer,
    } = productLayout;

    const { related_designers_category_rail: relatedDesignersCategoryRail } =
        productLayout as OOSProductLayoutSerializer;

    const isAffiliate = variant === "affiliate";

    useEffect(() => {
        userProfiler.saveSeenProduct(productAnalyticsData);
    }, [productAnalyticsData]);

    const isAdsenseAtTop = !(curatedAds && brandAd);

    return (
        <div className={styles.container}>
            <CustomerCaptureOverlay />

            {!isOverlay && (
                <div ref={overlayRef}>
                    <ProductOverlay parentRef={overlayRef} />
                </div>
            )}

            <div className={styles.content}>
                {productHeroCard && (
                    <OOSProductHero
                        variant={variant}
                        data={productHeroCard}
                        productUrl={productUrl}
                    />
                )}
                <VStack spacing="xl">
                    {brandAd && <BrandAdContainer {...brandAd} />}
                    {curatedAds && (
                        <div>
                            <CuratedAds {...curatedAds} usesFeedLayout={false} />
                        </div>
                    )}
                    {adsenseForShopping && isAdsenseAtTop && (
                        <div className="mb20">
                            <AdsenseShopping {...adsenseForShopping} />
                        </div>
                    )}
                </VStack>
                {relatedProducts && (
                    <div className="mb10 mt10">
                        <RelatedProductsArea
                            sections={relatedProducts.sections}
                            noBrandAd={!!brandAd}
                            noAdsense={!!adsenseForShopping}
                            onOOS={true}
                            isAffiliate={isAffiliate}
                            retailerSlug={retailerData?.slug}
                        />
                    </div>
                )}

                {adsenseForShopping && !isAdsenseAtTop && (
                    <div className="mb20">
                        <AdsenseShopping {...adsenseForShopping} />
                    </div>
                )}

                {isAffiliate && (
                    <>
                        {!!relatedDesignersCategoryRail?.designers?.length && (
                            <div className="page-block p30 pt10">
                                <RelatedSearchesRail
                                    title={relatedDesignersCategoryRail.title}
                                    relatedSearches={relatedDesignersCategoryRail.designers}
                                />
                            </div>
                        )}
                    </>
                )}

                {relatedSearchRail && !!relatedSearchRail.related_searches.length && (
                    <div className="page-block p30 pt10">
                        <RelatedSearchesRail
                            title={gettext("product.related_products.related_searches")}
                            relatedSearches={relatedSearchRail?.related_searches}
                        />
                    </div>
                )}

                {productBreadcrumbs && (
                    <div className="page-block p30 pt10">
                        <Breadcrumbs type="pdp" breadcrumbs={productBreadcrumbs?.breadcrumbs} />
                    </div>
                )}

                {productDescription && (
                    <div className={styles.productDescription}>
                        <div className="page-block p30 pt10">
                            <ProductDescription
                                title={productDescription.title}
                                description={productDescription.description}
                                read_more_limit={productDescription.read_more_limit}
                                product_sizes={productDescription.product_sizes}
                                more_descriptions={productMoreDescriptions}
                                without_scroll
                            />
                            {productImageGallery &&
                                productImageGallery.images &&
                                !!productImageGallery.images.length && (
                                    <>
                                        <h4 className="mt20">
                                            {gettext("oos_product.product_details_photos_title")}
                                        </h4>

                                        <div className="mb0">
                                            <PDPGallery
                                                variant="oos-carousel"
                                                hasRelatedProductsSidebar={false}
                                                product={productImageGallery}
                                            />
                                        </div>
                                    </>
                                )}
                        </div>
                    </div>
                )}

                <div className="m20 mt10 mb10">
                    <ProductRetailers
                        retailers={productRetailers.retailers}
                        previously_sold_at_retailers_html={
                            productRetailers.previously_sold_at_retailers_html
                        }
                    />
                </div>

                <div className="page-block p30 pt10">
                    <RecentlyViewed
                        title={recentlyViewedProducts.title}
                        products={recentlyViewedProducts.products}
                        is_loading={recentlyViewedProducts.is_loading}
                        current_product_id={recentlyViewedProducts.current_product_id}
                        in_overlay={recentlyViewedProducts.in_overlay}
                    />
                </div>
            </div>

            {footerData && (
                <AppFooter
                    data={footer}
                    helpCentreURLs={footerData.helpCentreURLs}
                    country={footerData.country}
                />
            )}
        </div>
    );
}

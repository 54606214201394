import React from "react";
import analytics from "web/script/analytics/analytics";
import { gettext } from "web/script/modules/django-i18n";
import styles from "./skip-navigation-button.module.css";

export function SkipNavigationButton(): React.ReactElement {
    const onClick = (): void => {
        analytics.event("accessibility", "click", "skip_navigation_button");
    };

    const onKeyDown = (event): void => {
        if (["Space", "Enter"].includes(event.key)) {
            onClick();
        }
    };
    return (
        <a
            className={styles.skipNavButton}
            href="#main"
            onClick={onClick}
            onKeyDown={onKeyDown}
            aria-label="skip-navigation"
        >
            {gettext("general.skip_navigation.label").toUpperCase()}
        </a>
    );
}

import { isAbsoluteUrl, isLystUrl } from "web/script/utils/url";

export function setPaidSessionIdOnUrl(pathOrUrl: string, paidSessionId: string | null): string {
    if (!paidSessionId || !isLystUrl(pathOrUrl)) {
        return pathOrUrl;
    }

    try {
        const url = new URL(pathOrUrl, window.location.href);
        url.searchParams.set("paid_session_id", paidSessionId);
        return isAbsoluteUrl(pathOrUrl) ? url.href : url.href.replace(url.origin, "");
    } catch (e) {
        return pathOrUrl;
    }
}

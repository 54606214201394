import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";

export interface Toast {
    id?: string;
    duration?: number;
    message: string;
}

// At the moment we only support a single toast. We would need to do a piece of with design and UX - to understand how this would actually work

const TOAST_LIMIT = 1;
const initialState = [] as Toast[];
let toastCount = 0;

const toastSlice = createSlice({
    name: "toast",
    initialState,
    reducers: {
        addToast(state, action: PayloadAction<Toast>): void {
            if (state.length === TOAST_LIMIT) {
                state.shift();
            }
            const id = toastCount++;
            state.push({ id: id.toString(), ...action.payload });
        },

        removeToast(state, action: PayloadAction<Toast["id"]>) {
            return state.filter((toast) => toast.id !== action.payload);
        },
    },
});

export const { addToast, removeToast } = toastSlice.actions;
export default toastSlice.reducer;

import React from "react";
import PrimaryButton from "web/react/components/buttons/primary-button/primary-button";
import { KevelCampaignControl } from "web/react/components/kevel-campaign-control/kevel-campaign-control";
import { Button } from "web/react/emo/button";
import analytics from "web/script/analytics/analytics";
import { gettext } from "web/script/modules/django-i18n";
import globals from "web/script/modules/globals";
import styles from "./related-products-show-more.module.css";

interface RelatedProductsShowMoreProps {
    view_more_url: string;
    product_is_instock?: boolean;
    isAffiliate?: boolean;
    buttonType?: string;
    showMoreCTAText?: string;
    retailerSlug: string | null;
    isRedesign?: boolean;
}

function ShowMoreButton({ href, type = "normal", text }): JSX.Element {
    if (type === "relatedProductsOOS") {
        return (
            <PrimaryButton href={href} className={styles.showMoreButtonLarge}>
                {text || gettext("oos_product.hero.btn")}
            </PrimaryButton>
        );
    }

    return (
        <PrimaryButton href={href} className={styles.showMoreButton}>
            {gettext("product.related_products.show_me_more")}
        </PrimaryButton>
    );
}

export function RelatedProductsShowMore({
    view_more_url,
    product_is_instock,
    isAffiliate = true,
    buttonType,
    showMoreCTAText,
    retailerSlug,
    isRedesign,
}: RelatedProductsShowMoreProps): JSX.Element {
    const productIsInstock = product_is_instock != null && product_is_instock;

    function beforeOnClick(): void {
        analytics.event("more_like_this", "see_more", "pdp_related_feed");
    }

    const analyticsEventLabel = productIsInstock ? "is_related_show_more" : "oos_related_show_more";
    const campaignAction = productIsInstock
        ? "is_related_show_more_click"
        : "oos_related_show_more_click";

    function onCampaignNotActive(): void {
        // go to the related products feed
        globals.window.location.href = view_more_url;
    }
    return (
        <KevelCampaignControl
            contextType={"related_show_more"}
            options={{
                action: campaignAction,
                isAffiliate: isAffiliate,
                retailerSlug,
            }}
            defaultAction={onCampaignNotActive}
            continueOptionHref={view_more_url}
            analyticsEventLabel={analyticsEventLabel}
            beforeOnClick={beforeOnClick}
        >
            {isRedesign ? (
                <Button
                    href={view_more_url}
                    type="button"
                    variant="secondary"
                    title={gettext("product.related_products.show_me_more")}
                />
            ) : (
                <ShowMoreButton href={view_more_url} type={buttonType} text={showMoreCTAText} />
            )}
        </KevelCampaignControl>
    );
}

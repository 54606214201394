import _escape from "lodash/escape";
import _isString from "lodash/isString";
import _unescape from "lodash/unescape";

/**
 * This is a very simple utility module to ensure that Django and Underscore
 * escaped strings use the same HTML entities when being escaped/unescaped.
 *
 * i.e.
 *      &#x27; === &#39; === single quote
 */
export default {
    /**
     * Escapes the provided string by replacing Underscore's
     * HTML entities with the ones used by Django.
     *
     * @params {String} string
     * @returns {String}
     */
    escape(string) {
        if (!_isString(string)) {
            console.error("The argument provided is not a string.");
            return;
        }

        let escapedString = string.replace(/&#x27;/g, "&#39;");
        return _escape(escapedString);
    },

    /**
     * Unescapes the provided string by replacing Django's
     * HTML entities with the ones used by Underscore.
     *
     * @params {String} string
     * @returns {String}
     */
    unescape(string) {
        if (!_isString(string)) {
            console.error("The argument provided is not a string.");
            return;
        }

        let unescapedString = string.replace(/&#39;/g, "&#x27;");
        return _unescape(unescapedString);
    },

    /**
     * Capitalizes the first letter for the provided string.
     *
     * @params {String} string
     * @returns {String}
     */
    capitalize(string) {
        if (!_isString(string)) {
            console.error("The argument provided is not a string.");
            return;
        }

        return string.charAt(0).toUpperCase() + string.slice(1);
    },

    /**
     * Strips HTML tags and converts HTML entities like "&amp;" into their equivalent characters.
     * See more @link https://stackoverflow.com/a/47140708/4312466
     *
     * @param {string} string
     * @returns {string}
     */
    stripHTML(string) {
        const doc = new DOMParser().parseFromString(string, "text/html");
        return doc.body.textContent || "";
    },

    /**
     * Count the number of words in the provided string
     *
     * @param {string} string
     * @returns {number}
     */
    countWords(input) {
        const trimmedInput = input.trim().replace(/<[^>]*>/g, "");
        if (trimmedInput.length === 0) {
            return 0;
        }

        const words = trimmedInput.split(/\s+/);
        return words.length;
    },
};

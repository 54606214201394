import { FieldValidator, useField } from "formik";
import React from "react";
import {
    GenericTextInput,
    GenericTextInputProps,
} from "web/react/components/inputs-base-components";

interface TextInputFieldProps extends GenericTextInputProps {
    name: string;
    validate?: FieldValidator;
}

export function FormikTextField({
    type,
    name,
    validate,
    value,
    ...props
}: TextInputFieldProps): JSX.Element {
    const [field, { error }] = useField({
        type,
        name,
        validate,
        value,
    });

    return (
        <GenericTextInput
            {...props}
            {...field}
            id={field.name}
            type={type}
            error={error}
            inputTheme={"primary"}
        />
    );
}

import analytics from "web/script/analytics/analytics";

type CopyFn = (text: string, analyticLabel: string) => Promise<boolean>; // Return success

function useCopyToClipboard(): CopyFn {
    const copy: CopyFn = async (text, analyticLabel) => {
        if (!navigator?.clipboard) {
            analytics.event("promo-code", "code_not_copied", analyticLabel);
            return false;
        }

        try {
            await navigator.clipboard.writeText(text);
            analytics.event("promo-code", "code_copied", analyticLabel);
            return true;
        } catch (error) {
            analytics.event("promo-code", "code_not_copied", analyticLabel);
            return false;
        }
    };

    return copy;
}

export default useCopyToClipboard;

import React from "react";
import { View } from "web/react/emo/view";
import { useDomViewport } from "web/react/hooks/use-dom-viewport/use-dom-viewport";
import { sharedStyles as styles } from "web/react/pages/product/in-stock/in-stock-product-page/product-price-comparison/price-comparison-table";
import { gettext } from "web/script/modules/django-i18n";

export function Header(): JSX.Element | null {
    const { isMobileViewport, isTabletViewport } = useDomViewport();

    if (isMobileViewport) return null;

    // Price and Retailer : "Price"
    const priceAreaText = isTabletViewport
        ? gettext("in_stock_product.compare_prices.price_and_retailer_tablet_title")
        : gettext("in_stock_product.compare_prices.price_title");

    // "Retailer"
    const retailerAreaText = isTabletViewport
        ? ""
        : gettext("in_stock_product.compare_prices.retailer_title");

    // "Shipping and size" : "Store information"
    const shippingInfoAreaText = isTabletViewport
        ? gettext("in_stock_product.compare_prices.shipping_and_size_tablet_title")
        : gettext("in_stock_product.compare_prices.store_title");

    // "Available sizes"
    const sizePillsAreaText = isTabletViewport
        ? ""
        : gettext("in_stock_product.compare_prices.sizes_title");

    return (
        <View className={styles.comparisonRow["header"]} padding={"md"}>
            <span className={styles.priceArea}>{priceAreaText}</span>
            <span className={styles.retailerArea}>{retailerAreaText}</span>
            <span className={styles.shippingInfoArea}>{shippingInfoAreaText}</span>
            <span className={styles.sizePillsArea}>{sizePillsAreaText}</span>
            <span className={styles.buttonArea}></span>
        </View>
    );
}

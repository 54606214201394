import React from "react";
import { useProductCardContext } from "web/react/components/product-card/react";
import { Price, PriceProps } from "web/react/emo/price";

type ProductCardPriceProps = Pick<PriceProps, "size" | "style">;

export function ProductCardPrice({ size, style }: ProductCardPriceProps): JSX.Element {
    const {
        full_price_with_currency_symbol: fullPriceWithCurrencySymbol,
        sale_price_with_currency_symbol: salePriceWithCurrencySymbol,
        sale_discount: saleDiscount,
        in_stock: inStock,
    } = useProductCardContext();

    if (!saleDiscount) {
        return (
            <div data-testid="product-price">
                <Price
                    size={size}
                    style={style}
                    fullPrice={fullPriceWithCurrencySymbol as string}
                    inStock={inStock}
                    dataTestId="not-on-sale"
                />
            </div>
        );
    }

    return (
        <div data-testid="product-price">
            <Price
                size={size}
                style={style}
                fullPrice={fullPriceWithCurrencySymbol as string}
                salePrice={salePriceWithCurrencySymbol as string}
                inStock={inStock}
                saleDiscount={saleDiscount}
                dataTestId="is-on-sale"
            />
        </div>
    );
}

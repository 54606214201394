import React from "react";
import { BaseText, BaseTextProps } from "web/react/emo/shared/components/base-text";
import { ResponsiveHeadingStyle } from "web/react/emo/sprinkles.css";

export interface HeadingProps extends BaseTextProps {
    textStyle: ResponsiveHeadingStyle;
    as?: "h1" | "h2" | "h3" | "h4" | "h5" | "h6";
}

export function Heading({ as = "h1", children, ...props }: HeadingProps): JSX.Element {
    return (
        <BaseText as={as} {...props}>
            {children}
        </BaseText>
    );
}

import baustein from "baustein";
import events from "web/script/dom/events";
import style from "web/script/dom/style";

const BANNER_HIDDEN_MODIFIER = "background-banner--hidden";

export default baustein.register("background-banner", {
    setupEvents(add) {
        add("bannerImageLoaded", this.onImageLoaded);
    },

    onInsert() {
        let coverImageUrl = this.options.coverImage;
        this.el.classList.add(BANNER_HIDDEN_MODIFIER);

        if (!coverImageUrl) {
            this.onImageLoaded();

            return;
        }

        requestAnimationFrame(() => {
            this.setCoverImage(coverImageUrl);
        });
    },

    /**
     * Adds the backgroundImage to the element, adds the
     * related class and cleans after itself by removing
     * the `cover-image` attribute.
     *
     * @param {String} coverImageUrl
     */
    setCoverImage(coverImageUrl) {
        this.setupImageLoading(coverImageUrl);

        style.setStyle(this.el, "background-image", `url('${coverImageUrl}')`);
        this.el.classList.add("background-banner--cover");
    },

    /**
     * Fades out the cover images and then removes the
     * related style attribute from the banner element.
     */
    removeCoverImage() {
        events
            .onTransitionEnd(this.el, () => {
                this.el.classList.add(BANNER_HIDDEN_MODIFIER);
            })
            .then(
                () => {
                    style.setStyle(this.el, "background-image", null);
                },
                300,
                "opacity"
            );
    },

    /**
     * Sets up the image loaded callback to make the cover
     * image fade-in smoothly.
     *
     * @param {String} coverImageUrl
     */
    setupImageLoading(coverImageUrl) {
        let image = new Image();

        image.onload = () => this.emit("bannerImageLoaded");

        image.src = coverImageUrl;
    },

    /**
     * Image loaded callback. Removes the loading class and
     * makes the image fade in (via CSS opacity transitions).
     */
    onImageLoaded() {
        this.el.classList.remove(BANNER_HIDDEN_MODIFIER);
    },
});

import React from "react";
import { gettext } from "web/script/modules/django-i18n";
import { useFooterContext } from "./footer";
import FooterBlock from "./footer-block";
import styles from "./footer-i18n-links.module.css";

interface LinkProps {
    url: string;
    name: string;
}

interface LinksBlockProps {
    links: LinkProps[];
}

export const INTERNATIONAL = "international";

function LinksBlock({ links }: LinksBlockProps): JSX.Element {
    return (
        <div>
            <ul>
                {links.map((link) => (
                    <li key={link.url}>
                        <a href={link.url} className={styles.link}>
                            {link.name}
                        </a>
                    </li>
                ))}
            </ul>
        </div>
    );
}

function FooterInternationalLinks(): JSX.Element | null {
    const {
        data: { i18n_links: internationalLinks },
        openBlockId,
        setOpenBlock,
    } = useFooterContext();

    if (!internationalLinks) {
        return null;
    }

    return (
        <FooterBlock
            isOpen={openBlockId === INTERNATIONAL}
            onClick={(): void => setOpenBlock(INTERNATIONAL)}
            title={gettext("footer.international.title")}
        >
            <LinksBlock links={internationalLinks} />
        </FooterBlock>
    );
}

export default FooterInternationalLinks;

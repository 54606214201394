import clsx from "clsx";
import PropTypes from "prop-types";
import React from "react";

const VALID_SPINNER_TYPES = ["secondary", "highlight"];

function LoadingSpinner({ type, inline }) {
    let loadingClassNames = clsx("loading-square", {
        [`loading-square--${type}`]: VALID_SPINNER_TYPES.includes(type),
        "loading-square--inline": inline,
    });

    return (
        <div
            className={loadingClassNames}
            role={"progressbar"}
            aria-valuemin={0}
            aria-valuemax={100}
            data-testid="loader"
        >
            <div className="loading-square__lr" />
            <div className="loading-square__tb" />
        </div>
    );
}

export default LoadingSpinner;

LoadingSpinner.propTypes = {
    type: PropTypes.oneOf(VALID_SPINNER_TYPES),
    inline: PropTypes.bool,
};

LoadingSpinner.defaultProps = {
    inline: false,
};

/* eslint-disable max-depth */
import _each from "lodash/each";
import snapchatEvents from "web/script/analytics/snapchat-events";
import snaptr from "web/script/embedded/snapchat";
import environment from "web/script/modules/environment";
import uuid from "web/script/utils/uuid";

export default {
    pixelId: "932e622e-76d4-48fc-a5c6-e0712b9cdac8",

    init: function (analytics) {
        let snapchatHash = analytics.getSnapchatHash();
        snaptr("init", this.pixelId, { user_hashed_email: snapchatHash });
    },

    send: function (data) {
        _each(data, (event) => {
            let events = [];
            switch (event.type) {
                case "page_view":
                    // Send Page View events on all pages apart from Lead page
                    if (event.data.page_type !== "lead") {
                        events.push({
                            eventName: snapchatEvents.page_view.name,
                            eventData: {
                                item_ids: [event.data.product_id],
                            },
                        });
                    }
                    // Send View Content Snap Pixel Event when product ID isn't null
                    if (event.data.product_id) {
                        events.push({
                            eventName: snapchatEvents.view_content.name,
                            eventData: {
                                item_ids: [event.data.product_id],
                                item_category: "product",
                            },
                        });
                    }
                    break;

                case "event":
                    events = this.getEventProps(event.data);
                    break;

                default:
                    break;
            }

            events.forEach((event) => {
                snaptr("track", event.eventName, event.eventData || {});
            });

            return;
        });
        return Promise.resolve();
    },

    getEventProps(data) {
        let events = [];
        const category = data.category;
        const action = data.action;
        const label = data.label;
        const snapchatCategory = snapchatEvents[category];
        const predictedRPL = environment.get("predictedTrackValue");
        switch (category) {
            case "cart":
                if (action === snapchatCategory.action && label === snapchatCategory.label) {
                    events.push({
                        eventName: snapchatCategory.name,
                        eventData: {
                            item_ids: [data.customData.product_id],
                            item_category: "product",
                            price: data.customData.price,
                            currency: "USD",
                        },
                    });
                }
                break;

            case "affiliate":
                if (action === snapchatCategory.action) {
                    if (predictedRPL) {
                        events.push({
                            eventName: snapchatCategory.name,
                            eventData: {
                                item_ids: [label],
                                item_category: "product",
                                price: predictedRPL && predictedRPL * 100,
                                currency: "USD",
                                // Snapchat suggests to send currency, price, and transaction ID
                                // for accurate ROAS reporting with PURCHASE events. We don't have
                                // track_id at this point so we generate a UUID to send with the event.
                                transaction_id: uuid.uuid4(),
                            },
                        });
                    }
                }
                break;

            default:
                break;
        }

        return events;
    },
};

import React, { useState } from "react";
import { FooterSerializer } from "web/types/serializers";
import FooterBlock from "./footer-block";
import FooterExtraLinks from "./footer-extra-links";
import FooterHelpInfo from "./footer-help-info";
import FooterInternationalLinks from "./footer-i18n-links";
import FooterLeadContent from "./footer-lead-content";
import styles from "./footer.module.css";

export interface FooterProps {
    data: FooterSerializer;
    helpCentreURLs: string[];
    country: string;
}

type FooterComponentProps = ReactComponentProps & FooterProps;

export interface FooterContextProps extends FooterProps {
    openBlockId: string | null;
    setOpenBlock: (blockId: string) => void;
}

const FooterContext = React.createContext<FooterContextProps | undefined>(undefined);

export function useFooterContext(): FooterContextProps {
    const context = React.useContext(FooterContext);

    if (!context) {
        throw new Error("useFooterContext must be used within a FooterContextProvider");
    }

    return context;
}

function Footer({ data, helpCentreURLs, country, children }: FooterComponentProps): JSX.Element {
    const [openBlockId, setOpenBlockId] = useState<string | null>(null);

    function setOpenBlock(blockId: string): void {
        if (blockId === openBlockId) {
            // close everything
            setOpenBlockId(null);
        } else {
            // Set the new active block
            setOpenBlockId(blockId);
        }
    }

    return (
        <FooterContext.Provider
            value={{
                data,
                helpCentreURLs,
                country,
                openBlockId,
                setOpenBlock,
            }}
        >
            <div className={styles.footerWrap}>
                <footer className={styles.footer}>{children}</footer>
            </div>
        </FooterContext.Provider>
    );
}

Footer.Block = FooterBlock;
Footer.LeadContent = FooterLeadContent;
Footer.InternationalLinks = FooterInternationalLinks;
Footer.ExtraLinks = FooterExtraLinks;
Footer.HelpInfo = FooterHelpInfo;

export default Footer;

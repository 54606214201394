import baustein from "baustein";
import template from "templates/modules/paginated_carousel.jinja";
import analytics from "web/script/analytics/analytics";
import { emptyElement } from "web/script/dom/manipulation";
import style from "web/script/dom/style";

const SWIPE_THRESHOLD = 40;
const COMPONENT_CLASS = ".paginated-carousel";
const PAGINATION_HIDDEN_MODIFIER = "paginated-carousel--no-pagination";

// exported for tests
export const WRAPPER_SELECTOR = `${COMPONENT_CLASS}__wrapper`;
export const NEXT_BUTTON_SELECTOR = `${COMPONENT_CLASS}__button--next`;
export const PREVIOUS_BUTTON_SELECTOR = `${COMPONENT_CLASS}__button--previous`;

export default baustein.register("paginated-carousel", {
    template,

    defaultOptions() {
        return {
            items: [],
            itemsPerPage: 200,
            eventCategory: "paginated-carousel",
        };
    },

    setupEvents(add) {
        add("click", PREVIOUS_BUTTON_SELECTOR, this._onPreviousClick);
        add("click", NEXT_BUTTON_SELECTOR, this._onNextClick);
        add("touchstart", WRAPPER_SELECTOR, this._onTouchStart);
    },

    /**
     * The component is created with an _index attribute of 0
     */
    init() {
        this._index = 0;
        this._deltaX = 0;
        this._isSwiping = false;
    },

    onInsert() {
        this.onOptionsChange();
    },

    onOptionsChange() {
        this.render();
    },

    /**
     * The component is rendered client side with the template. The content of the wrapper is set to ''.
     */
    render() {
        let wrapper = this._getWrapper();
        if (wrapper) {
            // If we have a wrapper element already just get rid of it's HTML which means any
            // child components will not be destroyed by the subsequent render.
            emptyElement(wrapper);
        }

        // call `super()`
        baustein.Component.prototype.render.apply(this, arguments); // eslint-disable-line prefer-rest-params

        // re-fetch the wrapper from the DOM as it will have changed after calling `render()`
        wrapper = this._getWrapper();

        this._calculateComponentWidth();
        this._calculateItemWidth();

        // reinstate all items
        this.options.items.forEach((item) => {
            wrapper.appendChild(
                this._wrapItem(item, this.options.itemsPerPage, this.options.gutterWidth)
            );
        });

        this._setWrapperWidth();
        this._setButtonsState(false, this.options.items.length > this.options.itemsPerPage);
    },

    /**
     * Sets the wrapper width based on the items'.
     */
    _setWrapperWidth() {
        let wrapper = this._getWrapper();

        wrapper.style.width = `${this._itemWidth * this.options.items.length}px`;
    },

    /**
     * Find the wrapper element in the DOM
     */
    _getWrapper() {
        return this.el.querySelector(WRAPPER_SELECTOR);
    },

    /**
     * Retrieves the width of the component
     */
    _calculateComponentWidth() {
        this._componentWidth = parseInt(getComputedStyle(this.el, null).width || 0, 10);
    },

    /**
     * Retrieves the width of a single product card
     */
    _calculateItemWidth() {
        this._itemWidth =
            (this._componentWidth + this.options.gutterWidth) / this.options.itemsPerPage;
    },

    /**
     * Translate the wrapper in a backward direction when "previous" button is clicked.
     */
    _onPreviousClick() {
        analytics.event(this.options.eventCategory, "paginated", "previous_button");
        this._setIndex(this._index - this.options.itemsPerPage);
    },

    /**
     * Translate the wrapper in a forward direction when "next" buttons is clicked.
     */
    _onNextClick() {
        analytics.event(this.options.eventCategory, "paginated", "next_button");
        this._setIndex(this._index + this.options.itemsPerPage);
    },

    /**
     * Translates the carousel to a given position
     * @param {number} index The index of the first card which should appear in the carousel
     * @private
     */
    _setIndex(index) {
        const maxIndex = Math.ceil(this.options.items.length - this.options.itemsPerPage);
        const newIndex = Math.max(0, Math.min(maxIndex, index));
        const translateX = this._getTranslationForIndex(newIndex);

        requestAnimationFrame(() => {
            style.setStyle(this._getWrapper(), "transform", `translateX(${translateX}px)`);
        });
        this._setButtonsState(newIndex !== 0, newIndex < maxIndex);
        this._index = newIndex;
    },

    /**
     * Converts an item index to a translation, clamped so you can't scroll to empty space  * when itemsPerPage is not an integer
     * @param {number} index The index of the first card which should appear in the carousel
     * @private
     */
    _getTranslationForIndex(index) {
        return -Math.min(
            index * this._itemWidth,
            this._itemWidth * this.options.items.length -
                this._componentWidth -
                this.options.gutterWidth
        );
    },

    /**
     * Enables/disabled/shows/hides the next/previous buttons.
     * @param {boolean} previousVisibility If the "previous" button should be visible
     * @param {boolean} nexyVisibility If the "next" button should be visible
     * @private
     */
    _setButtonsState(previousVisibility, nextVisibility) {
        const previousButton = this.el.querySelector(PREVIOUS_BUTTON_SELECTOR);
        const nextButton = this.el.querySelector(NEXT_BUTTON_SELECTOR);

        previousButton.disabled = !previousVisibility;
        nextButton.disabled = !nextVisibility;

        if (!previousVisibility && !nextVisibility) {
            this.el.classList.add(PAGINATION_HIDDEN_MODIFIER);
        }
    },

    /**
     * Wraps `item` with a <div> that has a hardcoded width and other styling
     * @param {HTMLElement} item
     * @param {number} itemsPerPage
     * @param {number} gutter
     * @returns {Element}
     * @private
     */
    _wrapItem(item, itemsPerPage, gutter) {
        const wrapper = document.createElement("div");
        wrapper.appendChild(item);
        wrapper.style.width = `${this._itemWidth}px`;
        wrapper.style.paddingRight = `${gutter}px`;
        return wrapper;
    },

    /**
     * Event handler for 'touchstart' events
     * @param {Event} event
     * @param {HTMLElement} wrapper
     * @private
     */
    _onTouchStart(event, wrapper) {
        this._touchStartCoords = this._getTouchCoords(event);

        this.registerEvent("touchmove", WRAPPER_SELECTOR, this._onTouchMove);
        this.registerEvent("touchend", WRAPPER_SELECTOR, this._onTouchEnd);

        style.setStyle(wrapper, "transition", "none");
    },

    /**
     * Event handler for 'touchmove' events.
     * @param {Event} event
     * @param {HTMLElement} wrapper
     * @private
     */
    _onTouchMove(event, wrapper) {
        const touchMoveCoords = this._getTouchCoords(event);
        const deltaX = touchMoveCoords.x - this._touchStartCoords.x;
        const deltaY = Math.abs(touchMoveCoords.y - this._touchStartCoords.y);

        if (!this._isSwiping && Math.abs(deltaY) > Math.abs(deltaX)) {
            this.releaseEvent("touchmove", WRAPPER_SELECTOR, this._onTouchMove);
            this.releaseEvent("touchend", WRAPPER_SELECTOR, this._onTouchEnd);

            style.setStyle(wrapper, "transition", null);

            this._isSwiping = false;
            return;
        }

        this._isSwiping = true;

        event.preventDefault();
        event.stopPropagation();

        let translateX = this._getTranslationForIndex(this._index) + deltaX;

        this._deltaX = deltaX;
        requestAnimationFrame(() => {
            style.setStyle(wrapper, "transform", `translateX(${translateX}px)`);
        });
    },

    /**
     * Event handler for 'touchend' events.
     * @param {Event} event
     * @param {HTMLElement} wrapper
     * @private
     */
    _onTouchEnd(event, wrapper) {
        if (this._isSwiping) {
            event.preventDefault();
        }

        this.releaseEvent("touchmove", WRAPPER_SELECTOR, this._onTouchMove);
        this.releaseEvent("touchend", WRAPPER_SELECTOR, this._onTouchEnd);

        style.setStyle(wrapper, "transition", null);

        this._isSwiping = false;

        const absDeltaX = Math.abs(this._deltaX);
        let indexChange;

        if (absDeltaX < SWIPE_THRESHOLD) {
            indexChange = 0;
        } else {
            let swipedItems = Math.ceil(absDeltaX / this._itemWidth);
            indexChange = swipedItems * (this._deltaX > 0 ? -1 : 1);
        }

        // Now the user has finished the touch action we want to reset the state of the component
        this._deltaX = 0;
        this._setIndex(this._index + indexChange);
    },

    /**
     * Helper for getting the coordinates of a touch event.
     * @param {Event} event
     * @returns {{x: number, y: number}}
     * @private
     */
    _getTouchCoords(event) {
        var touch = event.touches[0];
        return {
            x: touch.screenX,
            y: touch.screenY,
        };
    },
});

/**
 * Empties the given element of it's contents without mutating the contents.
 *
 * This function does not use `element.innerHTML = ''` as in IE the innerHTML of ALL children will
 * also be destroyed, which is almost definitely not what you would want.
 *
 * @param {HTMLElement} element
 * @returns {HTMLElement}
 */
export function emptyElement(element) {
    while (element.firstChild) {
        element.removeChild(element.firstChild);
    }

    return element;
}

export default { emptyElement };

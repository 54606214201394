import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import SVGIcon from "web/react/components/svg-icon/svg-icon";
import { Image } from "web/react/pages/product/in-stock/product-image-gallery/product-image-gallery";
import * as styles from "web/react/pages/product/in-stock/product-image-gallery/product-image-gallery.css";
import analytics from "web/script/analytics/analytics";
import { ProductCardSerializer } from "web/types/serializers";
import { ProductImageGalleryCarouselEmo } from "./product-image-carousel-emo";

interface FullscreenGalleryEmoProps {
    onClose: () => void;
    images: Image[];
    settings: Record<string, any>;
    isOpen?: boolean;
    moreLikeThisProducts?: ProductCardSerializer[];
    currentLinkId?: string;
    currentSlideIndex: number;
    productId: string;
}

interface ModalProps {
    children: React.ReactNode;
    onClose: () => void;
    in: boolean;
}

function ModalEmo({ children, onClose }: ModalProps): JSX.Element {
    return ReactDOM.createPortal(
        <div className={styles.overlay} data-testid="fullscreen-gallery">
            <div onClick={onClose} className={styles.close}>
                <SVGIcon name={"cross-thin"} />
            </div>
            {children}
        </div>,
        document.body
    );
}

function FullscreenGalleryEmo({
    isOpen = false,
    onClose,
    images,
    settings,
    currentSlideIndex,
    productId,
}: FullscreenGalleryEmoProps): React.ReactElement {
    useEffect(() => {
        if (isOpen) {
            analytics.event("product_gallery", "engagement", "open_fullscreen", false, {
                product_id: productId,
            });
        }
    }, [isOpen, productId]);

    return (
        <ModalEmo onClose={onClose} in={isOpen}>
            <ProductImageGalleryCarouselEmo
                images={images}
                isFullscreen={true}
                settings={settings}
                onClose={onClose}
                currentSlideIndex={currentSlideIndex}
                productId={productId}
            />
        </ModalEmo>
    );
}

export default FullscreenGalleryEmo;

import React, { useState } from "react";
import {
    GenericTextInput,
    GenericTextInputProps,
} from "web/react/components/inputs-base-components";
import SVGIcon from "web/react/components/svg-icon/svg-icon";
import styles from "./password-input.module.css";

export interface PasswordInputProps extends Omit<GenericTextInputProps, "type"> {
    value?: string;
}

export function PasswordInput({ error, value, ...props }: PasswordInputProps): JSX.Element {
    const [isShown, setIsShown] = useState(false);

    return (
        <div className={styles.contentWrapper}>
            <GenericTextInput
                type={isShown ? "text" : "password"}
                error={error}
                value={value}
                {...props}
                icon={
                    <div className={styles.showIcon} onClick={() => setIsShown(!isShown)}>
                        <SVGIcon name={isShown ? "show-on" : "show-off"} />
                    </div>
                }
            />
        </div>
    );
}

import clsx from "clsx";
import React, { InputHTMLAttributes } from "react";
import { Conditional } from "web/react/components/conditional";
import SVGIcon from "web/react/components/svg-icon/svg-icon";
import { InlineErrorMessage } from "web/react/emo/inline-error-message";
import { VStack } from "web/react/emo/v-stack";
import * as styles from "./generic-text-input.css";

export interface GenericTextInputProps extends InputHTMLAttributes<HTMLInputElement> {
    isValidated?: boolean;
    error?: string;
    type?: "email" | "number" | "password" | "search" | "tel" | "text" | "url";
    icon?: JSX.Element;
    label?: string;
    inputTheme?: Extract<keyof typeof styles, "base" | "secondary" | "primary">;
}

export function GenericTextInput({
    error,
    isValidated,
    className,
    type = "text",
    icon,
    label,
    inputTheme = "base",
    ...props
}: GenericTextInputProps): React.ReactElement {
    const id = props.id || props.name;
    const hasError = !!error;

    return (
        <VStack spacing="xxxs">
            <div className={styles.wrapper}>
                <VStack spacing={"xs"}>
                    {label && <label htmlFor={id}>{label}</label>}
                    <div>
                        <input
                            {...props}
                            type={type}
                            className={clsx(
                                styles.input,
                                styles[inputTheme],
                                {
                                    [styles.error]: error,
                                    [styles.completed]: isValidated,
                                },
                                className
                            )}
                            aria-invalid={hasError ? "true" : undefined}
                            aria-errormessage={hasError ? `${id}-error` : undefined}
                        />
                        {!error && isValidated && (
                            <SVGIcon className={styles.completedIcon} name="tick" />
                        )}
                        {icon}
                    </div>
                </VStack>
            </div>
            <Conditional check={!!error}>
                <InlineErrorMessage id={hasError ? `${id}-error` : undefined}>
                    {error}
                </InlineErrorMessage>
            </Conditional>
        </VStack>
    );
}

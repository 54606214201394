import { clsx } from "clsx";
import React, { useEffect, useRef, useState } from "react";
import { Tooltip } from "react-tooltip";
import styles from "./tooltip.module.css";

interface ReactTooltipProps {
    content: string;
    direction?: "top" | "bottom" | "left" | "right";
    children: string | React.ReactNode;
    autoShow?: number;
    autoHide?: number;
    manual?: boolean;
    showArrow?: boolean;
    withCloseButton?: boolean;
    theme?: "dark" | "light";
    onShow?: () => void;
    onHide?: () => void;
    onClose?: () => void;
}

export function ReactTooltip({
    content,
    direction,
    autoShow,
    autoHide,
    showArrow = true,
    children,
    manual = false,
    withCloseButton,
    theme = "dark",
    onShow,
    onHide,
    onClose,
}: ReactTooltipProps): JSX.Element {
    const [opened, setOpened] = useState<boolean>(false);
    const [isOpen, setIsOpen] = useState<boolean | undefined>(undefined);
    const autoShowTimeout = useRef<ReturnType<typeof setTimeout> | null>();
    const autoHideTimeout = useRef<ReturnType<typeof setTimeout> | null>();

    const themeStyles = {
        dark: {
            closeButton: styles.tooltipCloseButtonDark,
            contentInner: styles.tooltipContentInnerDark,
            content: styles.tooltipContentDark,
            contentColor: "#fff",
            contentBackground: "#333",
        },
        light: {
            closeButton: styles.tooltipCloseButtonLight,
            contentInner: styles.tooltipContentInnerLight,
            content: styles.tooltipContentLight,
            contentColor: "#000",
            contentBackground: "#fff",
        },
    };

    function handleHover(): void {
        if (manual) {
            return;
        }

        // Clear timeouts
        if (autoShowTimeout.current) {
            clearTimeout(autoShowTimeout.current);
        }

        if (autoHideTimeout.current) {
            clearTimeout(autoHideTimeout.current);
        }

        // Because of the way React Tooltip Lite is using isOpen prop
        if (isOpen !== undefined) {
            setIsOpen(undefined);
        }

        // Fire onShow or onHide callbacks
        if (!opened) {
            onShow && onShow();
            setOpened(true);
        } else {
            onHide && onHide();
            setOpened(false);
        }
    }

    function handleClose(): void {
        setIsOpen(false);
        setOpened(false);
        onClose && onClose();
        autoHideTimeout.current && clearTimeout(autoHideTimeout.current);
    }

    useEffect(() => {
        if (!!autoShow) {
            autoShowTimeout.current = setTimeout(() => {
                setIsOpen(true);
                setOpened(true);
                onShow && onShow();
            }, autoShow * 1000);
        }

        if (!!autoHide) {
            autoHideTimeout.current = setTimeout(() => {
                setIsOpen(false);
                setOpened(false);
                onHide && onHide();
            }, autoHide * 1000);
        }

        return (): void => {
            // Clear timeouts
            if (autoShowTimeout.current) {
                clearTimeout(autoShowTimeout.current);
            }

            if (autoHideTimeout.current) {
                clearTimeout(autoHideTimeout.current);
            }
        };
    }, [autoShow, autoHide, onShow, onHide]);

    return (
        <div>
            {typeof children !== "string" ? (
                <span
                    data-testid="inner-span"
                    data-tooltip-id="my-tooltip"
                    onMouseEnter={handleHover}
                    onMouseLeave={handleHover}
                >
                    {children}
                </span>
            ) : (
                <span
                    data-testid="inner-span"
                    data-tooltip-id="my-tooltip"
                    onMouseEnter={handleHover}
                    onMouseLeave={handleHover}
                    dangerouslySetInnerHTML={{ __html: children }}
                />
            )}
            <Tooltip
                id="my-tooltip"
                place={direction}
                className={clsx(
                    themeStyles[theme].content,
                    themeStyles[theme].contentBackground,
                    themeStyles[theme].contentColor,
                    {
                        [styles.tooltipContentWithCloseButton]: withCloseButton,
                        [styles.tooltipContentWithNoCloseButton]: !withCloseButton,
                    }
                )}
                classNameArrow={clsx({
                    [styles.arrow]: showArrow,
                    [styles.arrowDark]: theme === "dark",
                    [styles.arrowLight]: theme === "light",
                })}
                clickable
                isOpen={isOpen}
                wrapper="span"
                afterShow={onShow}
                afterHide={onHide}
            >
                {withCloseButton && (
                    // eslint-disable-next-line react/jsx-no-literals
                    <button className={themeStyles[theme].closeButton} onClick={handleClose}>
                        &times;
                    </button>
                )}
                <div
                    className={themeStyles[theme].contentInner}
                    dangerouslySetInnerHTML={{ __html: content }}
                />
            </Tooltip>
        </div>
    );
}

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { BreadcrumbsSerializer } from "web/types/serializers";

const feedBreadcrumbs = createSlice({
    name: "feedBreadcrumbs",
    initialState: {
        breadcrumbs: undefined,
    } as Partial<BreadcrumbsSerializer>,
    reducers: {
        setFeedBreadcrumbs(state, action: PayloadAction<BreadcrumbsSerializer | null>): void {
            state.breadcrumbs = action.payload?.breadcrumbs;
        },
    },
});

export const { setFeedBreadcrumbs } = feedBreadcrumbs.actions;
export default feedBreadcrumbs.reducer;

import clsx from "clsx";
import React from "react";
import SVGIcon from "web/react/components/svg-icon/svg-icon";
import { useIntersectionObserver } from "web/react/hooks/use-intersection-observer/use-intersection-observer";
import analytics from "web/script/analytics/analytics";
import styles from "./sticky-buy-button.module.css";

const DEFAULT_INTERSECTION_OBSERVER_OPTIONS = {
    threshold: 0,
    rootMargin: "0px",
    once: false,
};

interface StickyBuyButtonProps {
    targetRef: React.RefObject<HTMLDivElement>;
}

function StickyBuyButton({ targetRef }: StickyBuyButtonProps): React.ReactElement {
    const [initialHidden, setInitialHidden] = React.useState<boolean>(true);
    const [isOptionsVisible] = useIntersectionObserver(
        DEFAULT_INTERSECTION_OBSERVER_OPTIONS,
        targetRef as any
    );

    React.useEffect(() => {
        if (initialHidden && isOptionsVisible) {
            setInitialHidden(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOptionsVisible]);

    function handleClick(): void {
        let buyOptionsOffset = 0;

        if (targetRef?.current) {
            buyOptionsOffset = targetRef.current.getBoundingClientRect().top;
            targetRef.current.scrollIntoView({
                behavior: "smooth",
                block: "center",
            });
        }

        analytics.event(
            "buy_area",
            "sticky_button_click",
            buyOptionsOffset < 0 ? "below_buy_options" : "above_buy_options"
        );
    }

    return (
        <button
            className={clsx(styles.button, {
                [styles.fade]: initialHidden || isOptionsVisible,
            })}
            type="button"
            onClick={handleClick}
        >
            <SVGIcon className={styles.iconPlus} name="plus-bag" />
            <span className={styles.buttonText}>{"See buying option" /* TODO I18N-291 */}</span>
        </button>
    );
}

export default StickyBuyButton;

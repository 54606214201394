import React from "react";
import { RelatedProductsArea } from "web/react/components/__LEGACY__/LEGACY_related-products-area/related-products-area";
import LoadingSpinner from "web/react/components/loading-spinner/loading-spinner";
import { useRothko } from "web/react/hooks/use-rothko/use-rothko";
import { RelatedProductsLoaderSerializer } from "web/types/serializers";
import styles from "./related-products-loader.module.css";

const ENDPOINT = "modules/product/related_products_loader";

function RelatedProductsLoader({
    product_slug: productSlug,
    is_overlay: isOverlay,
    is_instock: isInStock,
    is_affiliate: isAffiliate,
    origin_feed: originFeed,
}: RelatedProductsLoaderSerializer): React.ReactElement {
    const { data, isFetching } = useRothko<RelatedProductsLoaderSerializer>(ENDPOINT, {
        isOverlay: isOverlay.toString(),
        isInstock: isInStock.toString(),
        isAffiliate: isAffiliate.toString(),
        slug: productSlug,
        originFeed,
    });

    const relatedProducts = data?.related_products;

    if (isFetching || !relatedProducts) {
        return (
            <div className={styles.loading}>
                <LoadingSpinner inline={true} type={"highlight"} />
            </div>
        );
    }

    return (
        <RelatedProductsArea
            sections={relatedProducts.sections}
            isAffiliate={isAffiliate}
            onOOS={!isInStock}
        />
    );
}

export default RelatedProductsLoader;

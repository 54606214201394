import React from "react";
import { SVGIconName } from "web/react/components/svg-icon/svg-icon";
import { Button, ButtonProps } from "web/react/emo/button";
import { useToast } from "web/react/emo/toast/useToast";
import { VStack } from "web/react/emo/v-stack";
import { useDomViewport } from "web/react/hooks/use-dom-viewport/use-dom-viewport";
import { AuthType } from "web/redux/ducks/customer-capture-overlay";
import analytics from "web/script/analytics/analytics";
import { gettext } from "web/script/modules/django-i18n";
import environment from "web/script/modules/environment";
import globals from "web/script/modules/globals";
import navigate from "web/script/utils/navigate";
import { SSO_PROVIDERS, SocialLogin } from "web/script/utils/social-auth-login";
import storage from "web/script/utils/storage";
import url from "web/script/utils/url";

export interface SocialAuth {
    eventCategory: string;
    isLogin?: boolean;
    isRegistration: boolean;
    authType: AuthType;
    nextUrl: string;
    reason?: string;
    productId?: string | number | null;
    designerId?: string | null;
    searchQuery?: string | null;
    searchGender?: string | null;
    type: keyof typeof SSO_PROVIDERS;
}

export type SocialAuthButtonProps = SocialAuth & Omit<ButtonProps, "type">;

export default function SocialAuthButton({
    type,
    eventCategory,
    isLogin,
    isRegistration,
    nextUrl,
    reason = "",
    title,
    ...props
}: SocialAuthButtonProps): JSX.Element {
    const action = isLogin ? "click_social_sign_in" : "click_social_sign_up";
    const label = isLogin ? `signin_${type}` : `signup_${type}`;

    const toast = useToast();

    async function onClick(): Promise<void> {
        analytics.event(eventCategory, action, label);
        if (storage.get("new_user_to_sign_up", null, true) === "active") {
            storage.set("new_user_to_sign_up", "complete", true);
        }
        const parsedURL = url.parse(globals.window.location.toString());
        const next = nextUrl || url.unparse(parsedURL);

        let searchParams = {};

        if (isRegistration) {
            searchParams["reason"] = reason;
            searchParams["isLogin"] = false;
        } else {
            searchParams["isLogin"] = true;
        }

        searchParams = {
            next,
            sign_up_language: environment.get("language"),
            ...props,
            ...searchParams,
        };

        parsedURL.searchParams = new URLSearchParams(searchParams);

        // CW-646 - Fix Lyst account takeover via Social Account linking functionality
        try {
            const redirectURL = await SocialLogin(
                parsedURL,
                SSO_PROVIDERS[type] as keyof typeof SSO_PROVIDERS
            );
            navigate(redirectURL);
        } catch (e: any) {
            toast({ message: `Failed to log in with ${SSO_PROVIDERS[type]}` });
        }
    }

    return (
        <Button
            title={title}
            width="full"
            variant={"secondary"}
            icon={`${type}-icon` as SVGIconName}
            onClick={onClick}
        />
    );
}

export function SocialAuthButtons(
    props: Omit<SocialAuthButtonProps, "type" | "title">
): JSX.Element {
    const { isDesktopViewport } = useDomViewport();
    return (
        <VStack spacing={isDesktopViewport ? "sm" : "xxxs"}>
            <div data-testid="continue-with-google">
                <SocialAuthButton
                    type={"google"}
                    title={
                        /* Continue with Google */
                        gettext("account.register.sign_up_with_provider", {
                            social_auth_provider: "Google",
                        })
                    }
                    {...props}
                />
            </div>
            <div data-testid="continue-with-apple">
                <SocialAuthButton
                    type="apple"
                    title={
                        /* Continue with Apple */
                        gettext("account.register.sign_up_with_provider", {
                            social_auth_provider: "Apple",
                        })
                    }
                    {...props}
                />
            </div>
            <div data-testid="continue-with-facebook">
                <SocialAuthButton
                    type={"facebook"}
                    title={
                        /* Continue with Facebook */
                        gettext("account.register.sign_up_with_provider", {
                            social_auth_provider: "Facebook",
                        })
                    }
                    {...props}
                />
            </div>
        </VStack>
    );
}

import { JinjaToJS } from "jinja-to-js";
import { base64Encode } from "web/script/utils/json";

/**
 * Utility function to return the mark up to use for icon
 * @param name Name of icon to use
 */
export function svgIcon(name: string): string {
    return `
        <svg class="svg-icon svg-icon-${name}">
            <use xlink:href="#${name}"></use>
        </svg>
    `;
}

/**
 * Utility function to install new filters into the Jinja-to-JS runtime.
 */
export function installJinjaFilters(jinjaToJs: JinjaToJS): void {
    jinjaToJs.filters.base64_json = base64Encode;
    jinjaToJs.filters.svg_icon = svgIcon;
}

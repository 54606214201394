import clsx from "clsx";
import React from "react";
import styles from "./button.module.css";

export type ButtonType =
    | "cta"
    | "primary"
    | "secondary"
    | "tertiary"
    | "temp-grey"
    | "sso-auth"
    | "temp-gold";

const STYLE_TYPE_CLASSES: { readonly [bt in ButtonType]: string } = {
    cta: styles.cta,
    primary: styles.primary,
    secondary: styles.secondary,
    tertiary: styles.tertiary,
    "temp-grey": styles.tempGrey,
    "sso-auth": styles.ssoAuth,
    "temp-gold": styles.tempGold,
};

function onDisabledClick(event: React.MouseEvent): void {
    event.preventDefault();
}

// NOTE: this is our old button component. The new button lives inside
// media/web/react/emo/button/button.tsx
export interface ButtonProps extends DefaultElementProps {
    /** Button className */
    className?: string;
    /** If this button should be a link, set this to where it should go */
    href?: string;
    /** Allows you to override what HTML element the button renders as
     * TODO: maybe remove this there are better ways to do this
     */
    element?: "div" | "span";
    /** Button grows horizontally to fit its container */
    fullHeight?: boolean;
    /** Button grows vertically to fit its container */
    fullWidth?: boolean;
    /** If this button is a link, open in a _blank window */
    openInNewTab?: boolean;
    /** Prevent button being clickable (& visual state) */
    disabled?: boolean;
    /** Show a thin border */
    thinBorder?: boolean;
    /** Render small version of the button **/
    small?: boolean;
    /** What style this button should show as */
    styleType: ButtonType;
    /** Event handler */
    onClick?: React.MouseEventHandler<HTMLElement>;
    children?: React.ReactNode;
}

// Pretty much everything's going to be a button or a link so let's not get bogged down
type DefaultElementProps = JSX.IntrinsicElements["a"] & JSX.IntrinsicElements["button"];

/** BUTTONS FOR THE BUTTON GOD */
export function Button({
    element,
    fullHeight,
    fullWidth,
    openInNewTab,
    small,
    styleType,
    thinBorder,
    ...props
}: ButtonProps): JSX.Element {
    let Component = props.href ? "a" : "button";
    if (element) {
        Component = element;
    }

    props.className = clsx(props.className, styles.button, STYLE_TYPE_CLASSES[styleType], {
        [styles.fullWidth]: fullWidth,
        [styles.fullHeight]: fullHeight,
        [styles.disabled]: props.disabled,
        [styles.buttonButton]: !props.href,
        [styles.small]: small,
        [styles.thinBorder]: thinBorder,
    });

    if (props.href) {
        props.target = openInNewTab ? "_blank" : "_self";
    }

    if (props.disabled) {
        props.onClick = onDisabledClick;
        props["aria-disabled"] = true;
    }

    return <Component {...props} />;
}

export default Button;

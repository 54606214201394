import _isString from "lodash/isString";
import _reduce from "lodash/reduce";

/**
 *
 * @type {string[]}
 */
var CSS_EXPECTS_UNIT = [
    "margin-top",
    "margin-bottom",
    "margin-left",
    "margin-right",
    "padding-top",
    "padding-bottom",
    "padding-left",
    "padding-right",
    "left",
    "right",
    "top",
    "bottom",
    "width",
    "height",
    "max-width",
    "max-height",
    "min-width",
    "min-height",
];

/**
 *
 * @type {object}
 */
var cssPropertyCache = {};

/**
 *
 * @param {string} property
 * @param {string|number|null} value
 * @returns {string|number|null}
 */
function checkPropertyUnit(property, value) {
    /* eslint eqeqeq: 0 */
    if (CSS_EXPECTS_UNIT.indexOf(property) < 0) {
        return value;
    }

    // try and parse a number out of the value
    var parsedValue = parseFloat(value);

    // if it's not a number, return the raw value
    if (isNaN(parsedValue)) {
        return value;
    }

    // if the parsed value roughly equals the raw value, then it's
    // more than likely to not have any unit, add a pixel
    if (value == parsedValue) {
        return value + "px";
    }

    // otherwise return the raw value
    return value;
}

/**
 * Given a non-prefixed CSS property returns one that will work in the current browser.
 * @param {string} prop
 * @returns {string|null}
 */
function getCSSProperty(prop) {
    if (!_isString(prop)) {
        return null;
    }

    if (cssPropertyCache.hasOwnProperty(prop)) {
        return cssPropertyCache[prop];
    }

    var styleRules = document.createElement("div").style;

    var vendors = ["Webkit", "Moz", "O", "ms"];
    var vendorProp;
    var i = 0;

    // convert from dasherized to camelized
    var camelized = prop.replace(/-(\w)/g, function (match, letter) {
        return letter.toUpperCase();
    });

    // check support for unprefixed property
    if (camelized in styleRules) {
        cssPropertyCache[prop] = prop;
        return prop;
    }

    // check vendor prefixed versions
    for (; i < vendors.length; i++) {
        vendorProp = vendors[0] + camelized[0].toUpperCase() + camelized.substring(1);
        if (vendorProp in styleRules) {
            cssPropertyCache[prop] = "-" + vendors[0].toLowerCase() + "-" + prop;
            return cssPropertyCache[prop];
        }
    }

    cssPropertyCache[prop] = null;
    return null;
}

/**
 *
 * @param element
 * @param property
 * @param value
 */
function setStyle(element, property, value) {
    property = getCSSProperty(property);

    if (property === null) {
        return;
    }

    if (value === null) {
        element.style.removeProperty(property);
    } else {
        element.style.setProperty(property, checkPropertyUnit(property, value));
    }
}

export default {
    getCSSProperty: getCSSProperty,

    setStyle: setStyle,

    /**
     * Sets many styles on an element.
     * @param element - a DOM element
     * @param styles - a literal object containing CSS properties and values
     */
    setStyles: function (element, styles) {
        for (var style in styles) {
            if (!styles.hasOwnProperty(style)) {
                continue;
            }
            setStyle(element, style, styles[style]);
        }
    },

    /**
     * With an object literal, create a transform string. The keys of the object should be the
     * transformation names, and the values should be an array of values for the transform.
     * Units are not automatically inferred.
     *
     * @example
     *  var def = {
     *      'translate': ['20px', '-20px'],
     *      'scale': ['1.3']
     *  };
     *  style.createTransformString(def);
     *  // returns: 'translate(20px, -20px) scale(1.3)'
     * @param {Object} transformDefinition - see the example for how this should be provided
     * @returns {string}
     */
    createTransformString: function (transformDefinition) {
        return _reduce(
            transformDefinition,
            function (memo, value, key) {
                memo.push(key + "(" + value.join(",") + ")");
                return memo;
            },
            []
        ).join(" ");
    },

    /**
     * Convenience method for forcing a reflow on an element.
     * @param {HTMLElement} element
     * @returns {Number}
     */
    forceReflow: function (element) {
        return element.offsetLeft;
    },

    /**
     * Returns an object containing the size and position of `element` relative to the viewport.
     * @param {HTMLElement} element
     * @returns {object}
     */
    getPosition(element) {
        // Need to use try/catch due to an IE bug where this method throws an error if the element
        // is not attached to the DOM - https://goo.gl/HhrpAM
        try {
            return element.getBoundingClientRect();
        } catch (error) {
            console.error("Error calling getBoundingClientRect() on", element, error);
            return {
                bottom: 0,
                height: 0,
                left: 0,
                right: 0,
                top: 0,
                width: 0,
            };
        }
    },
};

import React from "react";
import SVGIcon, { SVGIconName } from "web/react/components/svg-icon/svg-icon";
import { HStack } from "web/react/emo/h-stack";
import { BaseText } from "web/react/emo/shared/components/base-text";
import * as styles from "./link.css";

export interface LinkProps {
    href: string;
    title: string;
    icon?: SVGIconName;
}

export function Link({ href, title, icon }: LinkProps): React.ReactElement {
    return (
        <HStack spacing="xxxs" align="center">
            {icon && <SVGIcon className={styles.icon} name={icon} />}
            <a data-testid="link" href={href}>
                <BaseText
                    className={styles.link}
                    as="p"
                    textStyle="small-link"
                    color="secondaryBrand"
                >
                    {title}
                </BaseText>
            </a>
        </HStack>
    );
}

import React from "react";
import { getFeedsTitle } from "web/react/components/feeds-filters/hooks/use-filter-manager";
import { useClickTracker } from "web/react/hooks/use-click-tracker/use-click-tracker";
import { gettext } from "web/script/modules/django-i18n";
import { FeedHeaderSerializer } from "web/types/serializers";
import { UniversalFiltersData } from "web/types/universal-filters";

export enum FeedPage {
    RETAILER_PROFILE = "RETAILER_PROFILE",
    DESIGNER_PROFILE = "DESIGNER_PROFILE",
    GENERIC_SHOP = "GENERIC_SHOP",
    SEARCH = "SEARCH",
    RELATED_PRODUCTS = "RELATED_PRODUCTS",
    DYNAMIC_FEED = "DYNAMIC_FEED",
    DEFAULT = "DEFAULT",
    USER_PROFILE = "USER_PROFILE",
    DESIGNER_SHOP = "DESIGNER_SHOP",
    CUSTOM_SHOP = "CUSTOM_SHOP",
    CLP_FEED = "CLP_FEED",
    SALE_FEED = "SALE_FEED",
    COLLECTION_FEED = "COLLECTION_FEED",
    PRODUCT_EDIT = "PRODUCT_EDIT",
    SAVED_SEARCH_FEED = "SAVED_SEARCH_FEED",
    SALE_TAXONOMY_FEED = "SALE_TAXONOMY_FEED",
    DESIGNER_SALE_PROFILE = "DESIGNER_SALE_PROFILE",
    RETAILER_SALE_PROFILE = "RETAILER_SALE_PROFILE",
    NEW_ARRIVALS = "NEW_ARRIVALS",
    DISCOVERY_CONTENT = "DISCOVERY_CONTENT",
    WISH_LIST = "WISH_LIST",
}

export type FeedPageType = (typeof FeedPage)[keyof typeof FeedPage];

interface RelatedProductData {
    productLink: string | null;
    designerName: string | null;
    productDescription: string | null;
}

export function RelatedProductTitle({
    productLink,
    designerName,
    productDescription,
}: RelatedProductData): JSX.Element {
    const onClick = useClickTracker("navigation", "related_products_feed_return");
    const title = `${designerName} ${productDescription}`;

    return productLink ? (
        <a onClick={onClick} href={productLink}>
            {title}
        </a>
    ) : (
        <span>{title}</span>
    );
}

export function getFeedTitle(
    feedType: string,
    header: FeedHeaderSerializer,
    universalFilters?: UniversalFiltersData
): string | JSX.Element {
    if (feedType === FeedPage.SEARCH) {
        return header.title ? `"${header.title}"` : '""';
    }

    if (feedType === FeedPage.RELATED_PRODUCTS) {
        return (
            <RelatedProductTitle
                productLink={header.product_link}
                designerName={header.designer_name}
                productDescription={header.description}
            />
        );
    }

    if (feedType === FeedPage.DYNAMIC_FEED && universalFilters) {
        return getFeedsTitle(universalFilters);
    }

    return header.title ?? "";
}

export function getFeedSubTitle(feedType: string): string | undefined {
    if (feedType === FeedPage.RELATED_PRODUCTS) {
        return `${gettext("related_products.main_heading")}...`;
    }
}

import clsx from "clsx";
import PropTypes from "prop-types";
import React, { useState } from "react";
import SVGIcon from "web/react/components/svg-icon/svg-icon";
import styles from "./expandable.module.css";

function Expandable({
    chevronClass,
    children,
    className,
    onClick,
    renderLabel,
    openByDefault = false,
}) {
    const [open, setOpen] = useState(openByDefault);
    function toggleOpen() {
        setOpen(!open);
        // For owning components to fire analytics etc.
        if (onClick) {
            onClick(open);
        }
    }
    return (
        <div className={clsx(className, `${open ? styles.open : styles.closed}`)}>
            <div className={styles.button} onClick={toggleOpen}>
                {renderLabel(open)}
                <SVGIcon className={chevronClass} name="round-chevron" rotate={open ? -90 : 90} />
            </div>
            <div className={styles.content}>{children}</div>
        </div>
    );
}

export default Expandable;

Expandable.propTypes = {
    chevronClass: PropTypes.string,
    className: PropTypes.string,
    children: PropTypes.node.isRequired,
    onClick: PropTypes.func,
    renderLabel: PropTypes.func.isRequired,
};

import React, { PropsWithChildren } from "react";
import { Provider } from "react-redux";
import { Store } from "redux";
import globalStore from "web/redux/store";

/**
 * It's React-Redux's <Provider> component but it defaults to our store
 */
export const ReduxWrapper = function ReduxWrapper({ children, store = globalStore }) {
    return <Provider {...{ store, children }} />;
} as React.FC<PropsWithChildren<{ store?: Store }>>;

/**
 * Mainly designed for renderHook - wraps ReduxWrapper with a predefined store
 * (eg the one you made for the test)
 * @param store The store you want to use instead of the global one
 */
export function reduxTestWrapper(store?: Store): React.FC<PropsWithChildren> {
    /* eslint-disable react/display-name */
    return ({ children }) => <ReduxWrapper {...{ store, children }} />;
}

/**
 * HOC for connecting components to the Redux store.
 * In general, you should only use this on the root element rendered by
 *  Hypernova and not anything higher up the tree.
 * @param WrappedComponent The component to wrap
 * @param store Lets you override which store the component is connected to.
 */
export function withRedux<P extends Record<string, any>>(
    WrappedComponent: React.ComponentType<P>,
    store?: Store
): React.FC<PropsWithChildren<P>> {
    return function withRedux(props) {
        return (
            <ReduxWrapper {...{ store }}>
                <WrappedComponent {...props} />
            </ReduxWrapper>
        );
    };
}

export default withRedux;

import bingConversion from "web/script/embedded/bing-conversion";

export default {
    send: function (data) {
        data.forEach((event) => {
            if (event.type === "page_view") {
                bingConversion();
            }
        });
        // no way of waiting for this to be done
        return Promise.resolve();
    },
};

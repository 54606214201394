import React from "react";
import { DesignerOrStoreLogo } from "web/react/components/designer-or-store-logo";
import { gettext } from "web/script/modules/django-i18n";
import * as styles from "./feed-header-image.css";

export function FeedHeaderImage({
    altText,
    designerName,
    src,
}: {
    src?: string;
    designerName?: string;
    altText?: string;
}): JSX.Element | null {
    if (designerName) {
        return (
            <span className={styles.headerLogoWrapper}>
                <DesignerOrStoreLogo
                    src={src as string | undefined}
                    alt={
                        // "{designer_name} logotype"
                        gettext("feed.designer_logo.image_alt_text", {
                            designer_name: designerName,
                        })
                    }
                    name={designerName}
                    as={"div"}
                />
            </span>
        );
    }

    if (altText && src) {
        return (
            <div data-testid="feed-header-image">
                <span className={styles.headerImageWrapper}>
                    <img src={src} alt={altText} className={styles.headerImage} />
                </span>
            </div>
        );
    }

    return null;
}

import React from "react";
import { Text } from "web/react/emo/text";
import { gettext } from "web/script/modules/django-i18n";
import * as styles from "./error-text.css";

export function ErrorText(): React.ReactElement {
    return (
        <Text textStyle={"body-3-small"} as={"div"} className={styles.sizePickerErrorText}>
            {/* "Please select a size to checkout" */}
            {gettext("error.checkout.size_needed")}
        </Text>
    );
}

import React, { useRef, useState } from "react";
import ProductMoreDescriptions from "web/react/components/buybuybuy-area/product-more-descriptions/product-more-descriptions";
import ProductSizes from "web/react/components/buybuybuy-area/product-sizes/product-sizes";
import analytics from "web/script/analytics/analytics";
import { gettext } from "web/script/modules/django-i18n";
import strings from "web/script/utils/strings";
import {
    ProductDescriptionSerializer,
    ProductMoreDescriptionsSerializer,
} from "web/types/serializers";
import styles from "./product-description.module.css";

interface ProductDescriptionProps extends ProductDescriptionSerializer {
    more_descriptions?: ProductMoreDescriptionsSerializer | null;
    without_scroll?: boolean;
}

function ProductDescription({
    title,
    description,
    read_more_limit: readMoreLimit,
    product_sizes: productSizes,
    more_descriptions: moreDescriptions,
    without_scroll: withoutScroll,
}: ProductDescriptionProps): React.ReactElement {
    const analyticsSent = useRef<boolean>(false);

    const [hidden, setHidden] = useState<boolean>(true);

    const words = description?.trim().split(" ");
    const maxWordCount = readMoreLimit || 20;

    // don't display "Read more" for short descriptions
    const expandable = words
        ? words.length > maxWordCount ||
          !!moreDescriptions?.descriptions?.length ||
          !!productSizes?.sizes?.length
        : false;

    let partialText = "";

    if (words) {
        const showWords = 1 + Math.ceil(maxWordCount * 0.8);
        partialText = `${strings.escape(words.slice(0, showWords).join(" "))}&#133;`;
    }

    const content =
        hidden && expandable ? (
            <>
                <p dangerouslySetInnerHTML={{ __html: partialText }} />
            </>
        ) : (
            <>
                <p>{description}</p>

                {moreDescriptions && !!moreDescriptions.descriptions.length && (
                    <ProductMoreDescriptions descriptions={moreDescriptions.descriptions} />
                )}

                {!!productSizes && <ProductSizes {...productSizes} />}
            </>
        );

    function handleReadMoreLess(): void {
        if (!analyticsSent.current) {
            analytics.event("Read More Text", "Clicked");
            analytics.event("product_overview", "read_more_details");
            analyticsSent.current = true;
        }

        setHidden((isHidden) => {
            if (!isHidden && !withoutScroll) {
                // Scroll to the top of the overlay or window
                const overlay = document.querySelector(".base-overlay");

                if (overlay) {
                    overlay.scrollTo({
                        top: 0,
                        left: 0,
                    });
                } else {
                    window.scrollTo({
                        top: 0,
                        left: 0,
                    });
                }
            }

            return !isHidden;
        });
    }

    return (
        <div className={styles.wrapper}>
            <h4 className={styles.title}>{title}</h4>

            <div className={styles.details}>{content}</div>

            {expandable && (
                <div className={styles.readMoreBorder}>
                    <span className={styles.readMoreToggle} onClick={handleReadMoreLess}>
                        <em>
                            {hidden ? gettext("general.read_more") : gettext("general.read_less")}
                        </em>
                    </span>
                </div>
            )}
        </div>
    );
}

export default ProductDescription;

/* eslint-disable @typescript-eslint/no-non-null-assertion */
import clsx from "clsx";
import React from "react";
import { Sprinkles } from "web/react/emo/sprinkles.css";
import { Text } from "web/react/emo/text";
import { View } from "web/react/emo/view";
import * as styles from "./curved-pill.css";

export interface CurvedPillProps {
    label?: string;
    onClick: () => void;
    active: boolean;
    activeHighlighted: boolean;
    deselected?: boolean;
    disabled?: boolean;
    icon?: JSX.Element;
    className?: string;
    width?: Sprinkles["width"];
    height?: Sprinkles["height"];
    variant?: "primary" | "secondary" | "tertiary";
}

export function CurvedPill({
    label,
    onClick,
    active,
    activeHighlighted,
    deselected = false,
    disabled = false,
    icon,
    className,
    width = "fit-content",
    height = "full",
    variant = "primary",
}: CurvedPillProps): JSX.Element {
    return (
        <div onClick={onClick} className={clsx(className, { [styles.disabledDiv]: disabled })}>
            <View
                paddingLeft={label ? "xxs" : "xs"}
                paddingX="xs"
                paddingY="xxs"
                borderRadius="xm"
                width={width}
                height={height}
                className={clsx(styles.initial, {
                    [styles.active]: active,
                    [styles.activeGray]: variant === "tertiary",
                    [styles.inactive]: disabled || (deselected && !active),
                    [styles.activeHighlighted]:
                        variant === "primary" && activeHighlighted && active,
                    [styles.activeHighlightedDark]:
                        variant === "secondary" && activeHighlighted && active,
                })}
            >
                {label && (
                    <Text
                        textStyle={variant === "secondary" ? "footnote-small" : "footnote"}
                        className={styles.text}
                        aria-label={label}
                    >
                        {label}
                    </Text>
                )}
                {icon}
            </View>
        </div>
    );
}

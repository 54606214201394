import React from "react";
import { Text } from "web/react/emo/text";
import { View } from "web/react/emo/view";

export interface PillProps {
    children: React.ReactNode;
}

export const Pill = ({ children }: PillProps): JSX.Element => {
    return (
        <View
            paddingX="xxs"
            paddingY="xxxs"
            border="keyline"
            borderRadius="xxxs"
            borderColor="grayscale300"
        >
            <Text color="secondary" textStyle="footnote">
                {children}
            </Text>
        </View>
    );
};

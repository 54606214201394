import clsx from "clsx";
import React, { useMemo } from "react";
import SaveForLaterButton from "web/react/components/save-for-later-button/save-for-later-button";
import { Option } from "web/react/components/select/types";
import { gettext } from "web/script/modules/django-i18n";
import userProfiler from "web/script/modules/userprofiler";
import * as styles from "./size-option.css";

export interface SizeOptionProps extends React.ComponentPropsWithoutRef<"div"> {
    className?: string;
    isSelected: boolean;
    onClick: (event: React.MouseEvent<HTMLDivElement>) => void;
    option: Option;
    numColumns?: 1 | 2;
}

/**
 * As a way to make Select component work as intended, every component that composes it must also define an "option"
 * component to use in the select dropdown.
 */
export function SizeOption({
    className,
    isSelected,
    onClick,
    option,
    numColumns = 1,
}: SizeOptionProps): JSX.Element {
    const isLoggedIn = useMemo(() => userProfiler.isLoggedIn(), []);

    // testing explicitly for bool, not null, undefined, etc
    const outOfStock = option.value.in_stock === false;
    const productId = option.value.product_id;
    const showAlertMe = !isLoggedIn && productId && outOfStock;

    if (showAlertMe) {
        // "Alert me"
        const ctaText = gettext("product.buy_area.alert_me_size.label");

        // replacing the second field with "Alert me",
        // we do this to keep a consistent two-column
        // option for products regardless of if they're
        // in the old or new size taxonomies
        option.text = [option.text[0], ctaText];
    }

    const optionContent: JSX.Element = (
        <>
            {option.text.map((text, index) => {
                const indexIsNotASize = index === 1 && showAlertMe;

                const key = `${text}_${index}`;
                const style = clsx(styles.optionText, styles.column, {
                    [styles.disabled]: option.disabled && !indexIsNotASize,
                });

                return (
                    <div className={style} key={key}>
                        {showAlertMe && index === 1 ? (
                            <SaveForLaterButton
                                captureType="size_stock_alert"
                                analyticsEventLabel="s4l_alert_me_size_picker"
                                cta={text}
                                isAffiliate={true}
                                isLoggedIn={isLoggedIn}
                                pageSource="pdp"
                                productId={productId}
                                type="cta"
                            />
                        ) : (
                            text
                        )}
                    </div>
                );
            })}
        </>
    );

    return (
        <div
            aria-description={option.selectedText}
            aria-label={"size option"}
            className={clsx(
                styles.row,
                { [styles.centred]: numColumns === 1 },
                { [styles.selected]: isSelected && !option.disabled },
                className
            )}
            data-testid={"option"}
            onClick={onClick}
        >
            {optionContent}
        </div>
    );
}

import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface SROSlice {
    open: boolean;
    linkId: string | null;
}

const initialState: SROSlice = { open: false, linkId: null };

const shippingAndReturnsOverlay = createSlice({
    name: "shippingAndReturnsOverlay",
    initialState: initialState,
    reducers: {
        openShippingAndReturnsOverlay(state, action: PayloadAction<string>): void {
            state.open = true;
            state.linkId = action.payload;
        },

        closeShippingAndReturnsOverlay(state): void {
            state.open = false;
            state.linkId = null;
        },
    },
});

const { actions, reducer } = shippingAndReturnsOverlay;
export const { openShippingAndReturnsOverlay, closeShippingAndReturnsOverlay } = actions;
export default reducer;

import loadable from "@loadable/component";
import clsx from "clsx";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import BuyOnStoreButton from "web/react/components/__LEGACY__/buy-on-store-button/buy-on-store-button";
import ColorPicker from "web/react/components/color-picker/color-picker";
import { HintBubble } from "web/react/components/hint-bubble/hint-bubble";
import PriceInfo from "web/react/components/price-info/price-info";
import ProductDescriptionDetails from "web/react/components/product-description/product-description-details/product-description-details";
import ProductDescriptionHeader from "web/react/components/product-description/product-description-header/product-description-header";
import ProductDescriptionShipping from "web/react/components/product-description/product-description-shipping/product-description-shipping";
import SaveForLaterButton from "web/react/components/save-for-later-button/save-for-later-button";
import { SizePicker } from "web/react/components/size-picker/size-picker";
import { useDomViewport } from "web/react/hooks/use-dom-viewport/use-dom-viewport";
import analytics from "web/script/analytics/analytics";
import { gettext } from "web/script/modules/django-i18n";
import environment from "web/script/modules/environment";
import { languageIsEnglish } from "web/script/modules/language";
import userProfiler from "web/script/modules/userprofiler";
import { BuyOptionPropType } from "web/types/buy-option";
import { DesignerPropType } from "web/types/designer";
import { Schemas } from "web/types/examples/schemas";
import {
    ProductAreaSerializer,
    ProductSizePickerSerializer,
    SizeSerializer,
} from "web/types/serializers";
import Promos from "./product-area-promos/product-area-promos";
import styles from "./product-area.module.css";

const ProductDescriptionComparePrice = loadable(
    () =>
        import(
            /* webpackChunkName: "product-description-compare-prices" */
            "web/react/components/product-description/product-description-compare-prices/product-description-compare-prices"
        )
);
const ProductDescriptionExpressCheckout = loadable(
    () =>
        import(
            /* webpackChunkName: "product-description-express-checkout" */
            "web/react/components/product-description/product-description-express-checkout/product-description-express-checkout"
        )
);

interface ProductAreaProps {
    productArea: ProductAreaSerializer;
    productSizesData?: ProductSizePickerSerializer | null;
    handleProductChange: (id: any) => void;
    selectedVariant: string;
    openExpandableArea?: boolean;
}

function extractProductSizeData(productSizesData): {
    sizes: any[];
    userDefaultSchema: string;
    schemaLabels: Schemas;
} {
    const sizeData = {
        sizes: productSizesData?.sizes,
        userDefaultSchema: productSizesData?.user_default_schema,
        schemaLabels: productSizesData?.schema_labels,
    };
    return sizeData;
}

export function getSizePillData(sizes, userDefaultSchema): any[] {
    const sizePillData = sizes.map((size) => {
        return {
            stockStatus: size.in_stock,
            label: size.localized_sizes[userDefaultSchema].display_size,
        };
    });
    return sizePillData;
}

export function ProductArea({
    productArea: {
        buy_options: buyOptions,
        products,
        designer,
        sorted_buy_options: allSortedBuyOptions,
        sorted_variants: sortedVariants,
        lead_label: leadLabel,
        terms_url: termsUrl,
        show_express_checkout: showExpressCheckout,
        size_guide_link: sizeGuideLink,
        express_sizes: expressSizes,
        bing_traffic: bingTraffic,
        is_partner_checkout: isPartnerCheckout,
    },
    productSizesData,
    handleProductChange,
    selectedVariant,
    openExpandableArea = false,
}: ProductAreaProps): React.ReactElement {
    const [isSizePickerExpanded, setIsSizePickerExpanded] = useState(false);
    const { sizes, userDefaultSchema, schemaLabels } = extractProductSizeData(productSizesData);
    const [analyticsEventLabel, setAnalyticsEventLabel] = useState<string>("is-product-shop-now");
    const isAffiliate = !isPartnerCheckout && !showExpressCheckout;
    useEffect(() => {
        if (isSizePickerExpanded) {
            document.body.classList.add("no-scroll");
        }
        return (): void => {
            document.body.classList.remove("no-scroll");
        };
    }, [isSizePickerExpanded]);
    if (!selectedVariant && sortedVariants) {
        // If we're rendered by the old PDP code, we won't get this prop and need to improvise
        selectedVariant = sortedVariants[0];
    }
    const product = products[selectedVariant];

    const sortedBuyOptions = allSortedBuyOptions?.filter((linkId) =>
        product.link_ids.includes(linkId)
    );
    const buyOption = buyOptions[sortedBuyOptions?.[0]];
    const size: SizeSerializer = buyOption.sizes[buyOption.default_size];
    const isLoggedIn = React.useMemo(() => userProfiler.isLoggedIn(), []);

    const currentPriceHtml = size.formatted_sale_price;
    const priceWithCurrencySymbol = size.formatted_price;

    let selectedShipping;
    let freeShipping;
    if (size.shipping_info) {
        let { cheapest, cheapest_guaranteed: guaranteed } = size.shipping_info;
        if (cheapest && cheapest.is_free) {
            freeShipping = cheapest;
        }
        if (guaranteed) {
            selectedShipping = guaranteed;
        } else {
            selectedShipping = cheapest;
        }
    }
    let shouldRenderShipping = freeShipping;

    const promo = size.promotion;
    const retailerTerms = promo?.terms_url || size.lead_url;
    const promotion = promo && {
        tcs: [
            {
                // "Lyst promotion terms apply"
                message: gettext("in_stock_product.lyst_promotion_terms_link"),
                url: termsUrl,
            },
            {
                // "{retailer_name} promotion terms apply"
                message: gettext("in_stock_product.retailer_promotion_terms_link", {
                    retailer_name: buyOption.retailer_name,
                }),
                url: retailerTerms,
            },
        ],
        message: promo.html_message,
        url: promo.url,
    };
    // TODO: Move this to product-description-price when we kill the old serializer
    // "Price last checked {interval}"
    const humanLastChecked = gettext("in_stock_product.last_checked_at", {
        interval: buyOption.date_updated_human,
    });

    const productImageUrl = product.images[0].full_size_url;
    const showSizePicker =
        productSizesData?.sizes && environment.getFeature("show_size_picker_on_pdp");
    const { isDesktopViewport } = useDomViewport();

    function handleSizeSelect(option): void {
        const category = "size_picker";
        if (!option.disabled) {
            const action = "select_in_stock_size";
            analytics.event(
                category,
                action,
                option.selectedText,
                false,
                null,
                `affiliate.${category}.${action}`,
                {},
                [],
                true
            );
        } else {
            const action = "select_oos_alert";
            analytics.event(
                category,
                action,
                option.selectedText,
                false,
                null,
                `affiliate.${category}.${action}`,
                {},
                [],
                true
            );
            setAnalyticsEventLabel("size_oos_alert");
        }
    }

    return (
        <div>
            <ProductDescriptionHeader
                className={styles.headerSection}
                designerName={designer.name}
                designerUrl={designer.url}
                isDesignerActive={designer.active}
                shortDescription={product.short_description}
                productId={product.product_id}
                isAffiliate={isAffiliate}
            />

            <div className={styles.section}>
                <div className={styles.price}>
                    <PriceInfo
                        isOnSale={size.is_on_sale}
                        retailerName={buyOption.retailer_name}
                        leadUrl={size.lead_url}
                        formattedPrice={priceWithCurrencySymbol}
                        selectedShipping={selectedShipping}
                        currentPriceHtml={currentPriceHtml}
                    />
                    {sortedBuyOptions && sortedBuyOptions.length > 1 && (
                        <ProductDescriptionComparePrice
                            buyOptions={buyOptions}
                            sortedBuyOptions={sortedBuyOptions}
                            inlineVariant={true}
                            productImageUrl={productImageUrl}
                        />
                    )}
                </div>
                <Promos promotion={promotion} />
                {showSizePicker && (
                    <SizePicker
                        onSelect={handleSizeSelect}
                        isExpanded={isSizePickerExpanded}
                        schemas={schemaLabels}
                        // "Select a size to check for accurate prices"
                        defaultSelectText={gettext("product.buy_area.select_size.label")}
                        userDefaultSchema={userDefaultSchema}
                        setExpanded={() => {
                            if (!isSizePickerExpanded) {
                                analytics.event(
                                    "size_picker",
                                    "open_size_picker",
                                    undefined,
                                    false,
                                    null,
                                    "affiliate.size_picker.open_size_picker",
                                    null,
                                    undefined,
                                    true
                                );
                            } else {
                                analytics.event(
                                    "size_picker",
                                    "close_size_picker",
                                    undefined,
                                    false,
                                    null,
                                    "affiliate.size_picker.close_size_picker",
                                    null,
                                    undefined,
                                    true
                                );
                            }
                            setIsSizePickerExpanded(!isSizePickerExpanded);
                        }}
                        sizes={sizes}
                        productType="affiliate"
                    />
                )}
                {!showExpressCheckout && (
                    <div className={clsx(styles.leadButtons, styles.buttonsInRow)}>
                        {/* Affiliate PDP mobile */}
                        <BuyOnStoreButton
                            productId={product.product_id}
                            leadUrl={size.lead_url}
                            leadLabel={leadLabel}
                            analyticsEventLabel={analyticsEventLabel}
                            productImageUrl={productImageUrl}
                            contextType={"shop_now"}
                            hasPromotion={promotion != null}
                            isAffiliate={isAffiliate}
                            retailerSlug={buyOption.retailer_slug}
                        />
                        {languageIsEnglish() && isDesktopViewport && (
                            <SaveForLaterButton
                                isLoggedIn={isLoggedIn}
                                type="LEGACY_normal"
                                productId={product.product_id}
                                pageSource="pdp"
                                isAffiliate={isAffiliate}
                                // "Track deals"
                                cta={gettext("designer_updates.track_deals_btn_text")}
                            />
                        )}
                    </div>
                )}
                <div
                    className={clsx(styles.leadButtons, {
                        [styles.buttonsInColumn]: !showExpressCheckout,
                        [styles.expressCheckoutButtonsInRow]: showExpressCheckout,
                    })}
                >
                    {/* Affiliate PDP desktop & Express Checkout PDP */}
                    {!showExpressCheckout && (
                        <BuyOnStoreButton
                            productId={product.product_id}
                            leadUrl={size.lead_url}
                            leadLabel={leadLabel}
                            analyticsEventLabel={analyticsEventLabel}
                            productImageUrl={productImageUrl}
                            contextType={"shop_now"}
                            hasPromotion={promotion != null}
                            isAffiliate={isAffiliate}
                            retailerSlug={buyOption.retailer_slug}
                        />
                    )}

                    {/* PMT-978: Different button layout options */}
                    {languageIsEnglish() && isDesktopViewport && (
                        <SaveForLaterButton
                            isLoggedIn={isLoggedIn}
                            type="LEGACY_normal"
                            productId={product.product_id}
                            pageSource="pdp"
                            // "Track deals"
                            cta={
                                showExpressCheckout
                                    ? gettext("designer_updates.track_deals_btn_text")
                                    : undefined
                            }
                            showExpressCheckout={showExpressCheckout}
                            bingTraffic={bingTraffic}
                        />
                    )}
                    {showExpressCheckout && (
                        <BuyOnStoreButton
                            productId={product.product_id}
                            leadUrl={size.lead_url}
                            leadLabel={leadLabel}
                            bingTraffic={bingTraffic}
                            analyticsEventLabel={analyticsEventLabel}
                            productImageUrl={productImageUrl}
                            contextType={"express_checkout"}
                            hasPromotion={promotion != null}
                            isAffiliate={isAffiliate}
                            retailerSlug={buyOption.retailer_slug}
                        />
                    )}
                </div>
                {humanLastChecked && buyOption.is_amazon && (
                    <div className={styles.lastCheckedWrapper}>
                        <HintBubble
                            tooltipText={
                                gettext("in_stock_product.last_checked_at_tooltip") // "Product prices and availability are accurate as of the date/time indicated and are subject to change. Any price and availability information displayed on partners' sites at the time of purchase will apply to the purchase of this product."
                            }
                        />
                        <span title={buyOption.date_updated}>{humanLastChecked}</span>
                    </div>
                )}
            </div>
            {sortedVariants && sortedVariants.length > 1 && (
                <div className={styles.section}>
                    <h3 className={styles.title}>
                        {
                            gettext("in_stock_product.colors.title") // Colors
                        }
                        {":"}
                    </h3>
                    <ColorPicker
                        allSortedBuyOptions={allSortedBuyOptions as any[]}
                        buyOptions={buyOptions as any}
                        current={selectedVariant}
                        onClick={handleProductChange}
                        variants={sortedVariants}
                        products={products}
                    />
                </div>
            )}
            {/* PMT-978: Different button layout options */}
            {showExpressCheckout && (
                <ProductDescriptionExpressCheckout
                    className={styles.expressBuySection}
                    sizeGuideLink={sizeGuideLink}
                    sizes={expressSizes}
                    price={size.price}
                    leadUrl={size.lead_url}
                    leadLabel={buyOption.retailer_name}
                    productId={product.product_id}
                    productCode={buyOption.product_code}
                    bingTraffic={bingTraffic}
                    storeLeadUrl={size.lead_url}
                    isPartnerCheckout={isPartnerCheckout}
                    userDefaultSchema={userDefaultSchema}
                />
            )}
            {(shouldRenderShipping || showExpressCheckout) && (
                <ProductDescriptionShipping
                    leadUrl={size.lead_url}
                    retailerName={buyOption.retailer_name}
                    freeShipping={freeShipping}
                    className={styles.section}
                    shouldShowExtraShipping={showExpressCheckout}
                    extraShipping={buyOption.extra_shipping}
                    openExpandableArea={openExpandableArea}
                />
            )}
            <ProductDescriptionDetails className={clsx(styles.section)} product={product} />
        </div>
    );
}

export const ProductAreaPropType = PropTypes.shape({
    buy_options: PropTypes.objectOf(BuyOptionPropType).isRequired,
    products: PropTypes.objectOf(PropTypes.object).isRequired,
    designer: DesignerPropType.isRequired,
    absolute_url: PropTypes.string.isRequired,
    sorted_buy_options: PropTypes.arrayOf(PropTypes.string).isRequired,
    sorted_variants: PropTypes.arrayOf(PropTypes.string).isRequired,
    available_sizes: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.string)).isRequired,
    lead_label: PropTypes.string.isRequired,
    terms_url: PropTypes.string.isRequired,
    show_express_checkout: PropTypes.bool.isRequired,
    size_guide_link: PropTypes.string,
    express_sizes: PropTypes.arrayOf(
        PropTypes.shape({
            option_value: PropTypes.shape({
                link_id: PropTypes.string,
                raw_size: PropTypes.string,
                icon: PropTypes.bool,
            }),
            human_size: PropTypes.string,
            raw_size: PropTypes.string,
            min_price: PropTypes.string,
        })
    ),
    is_express_checkout_size_test: PropTypes.bool,
    express_checkout_disclaimer: PropTypes.bool,
    bing_traffic: PropTypes.bool,
});

/** @module components/read-more-text */
import baustein from "baustein";
import readMoreTextContent from "templates/modules/read_more_text_content.jinja";
import analytics from "web/script/analytics/analytics";
import factory from "web/script/dom/factory";
import { gettext } from "web/script/modules/django-i18n";
import browser from "web/script/utils/browser";
import strings from "web/script/utils/strings";

/**
 * A component for shortening a long piece of text to a certain number of
 * words with a 'Read More' link to show the rest.
 * @constructor
 * @alias module:components/read-more-text
 * @extends module:baustein
 */
export default baustein.register(
    "read-more-text",
    /** @lends module:components/read-more-text.prototype */
    {
        setupEvents: function (add) {
            add("click", "[data-read-more-text-toggle]", this._showFullText);
            add("click", "[data-read-less-text-toggle]", this._hideFullText);
        },

        defaultOptions: {
            maxWords: 20,
            mobileMaxWords: 0,
            readMoreText: gettext("general.read_more"),
            readLessText: gettext("general.read_less"),
            smallScreenOnly: false,
            // Whether to inline th 'Read More' text after the 'ellipsis'
            inlineReadMore: false,
        },

        /**
         * The full text content of the component.
         */
        _fullText: null,

        /**
         * Examines the text content of the component and if longer than
         * `options.maxWords` truncates it and adds a read more link.
         */
        init: function () {
            this.analyticsSent = false;

            const words = this.el.textContent.trim().split(" ");
            let maxWordCount = this.options.maxWords;

            if (this.options.mobileMaxWords && browser.getViewport() < browser.VIEWPORTS.TABLET) {
                maxWordCount = this.options.mobileMaxWords;
            }

            if (words.length <= maxWordCount) {
                return;
            }

            if (this.options.smallScreenOnly && browser.getViewport() > browser.VIEWPORTS.TABLET) {
                return;
            }

            let showWords = 0;
            this._partialText = "";
            if (maxWordCount > 0) {
                showWords = 1 + Math.ceil(maxWordCount * 0.8);
                this._partialText = `${strings.escape(words.slice(0, showWords).join(" "))}&#133; `;
            }

            this._fullText = this.el.innerHTML;

            this._hideFullText();
        },

        _toggleReadMoreLess() {
            const readMoreText = this.el.querySelector("[data-read-more-text-toggle]");
            if (readMoreText) {
                readMoreText.classList.toggle("hidden");
            }
            const readLessText = this.el.querySelector("[data-read-less-text-toggle]");
            readLessText.classList.toggle("hidden");
        },

        /**
         * Shows the full text after the user clicks the 'Read More' link.
         * @private
         */
        _showFullText: function () {
            this.el.querySelector("[data-content]").innerHTML = this._fullText;
            this._toggleReadMoreLess();

            // Just so we don't keep sending analytics
            if (!this.analyticsSent) {
                analytics.event("Read More Text", "Clicked");
                this.emit("readMoreText");
                this.analyticsSent = true;
            }
        },

        _hideFullText: function () {
            const renderData = {
                ...this.options,
                content: this._partialText,
            };
            const fragment = document.createDocumentFragment();
            const filterPreviews = factory.fromString(readMoreTextContent(renderData));
            fragment.appendChild(filterPreviews);
            while (this.el.firstChild) {
                this.el.removeChild(this.el.firstChild);
            }
            this.el.appendChild(fragment);
        },
    }
);

import React, { useRef } from "react";
import { useDispatch } from "react-redux";
import Slider from "react-slick";
import BranchLink from "web/react/components/branch-link/branch-link";
import { useDomViewport } from "web/react/hooks/use-dom-viewport/use-dom-viewport";
import withReduxProvider from "web/react/redux-provider";
import { openCustomerCaptureOverlay } from "web/redux/ducks/customer-capture-overlay";
import { RawBranchLink } from "web/script/utils/branch-io";
import { PromoBanner as PromoBannerSerializer } from "web/types/serializers";
import styles from "./promo-section.module.css";

interface PromoPicture {
    mobile: string | undefined | null;
    tablet: string | undefined | null;
    desktop: string | undefined | null;
    desktopXl: string | undefined | null;
    altText: string;
}

export interface PromoBannerProps extends PromoBannerSerializer {
    title?: string;
    inCarousel?: boolean;
}

function PromoSection({ data }: { data: PromoBannerProps[] }): JSX.Element {
    if (data.length > 1) {
        return <PromoCarousel data={data} />;
    } else {
        return <PromoBanner {...data[0]} />;
    }
}

export function PromoCarousel({ data }: { data: PromoBannerProps[] }): JSX.Element {
    const sliderRef = useRef<any>(null);

    const slides = data.map((image) => {
        return (
            /* hack to get slick-slider ssr to set slick-track width to NaN (ignored by the
             * browser) instead of 0, which causes the slides to start in the wrong place until
             * the client version is initialized*/
            <div key={image.url} style={{}}>
                <PromoBanner {...image} inCarousel />
            </div>
        );
    });

    const settings = {
        dots: false,
        autoplay: true,
        autoplaySpeed: data[0]?.display_time_for_promo_banner
            ? data[0]?.display_time_for_promo_banner * 1000
            : 5000,
        arrows: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
    };

    function handleOnSwipe(): void {
        if (sliderRef) {
            sliderRef.current.innerSlider.clickable = true;
        }
    }

    return (
        <Slider
            ref={sliderRef}
            {...settings}
            // Workaround for click bug
            // https://github.com/akiran/react-slick/issues/604#issuecomment-429166338
            onSwipe={handleOnSwipe}
        >
            {slides}
        </Slider>
    );
}

export function PromoBanner({
    url,
    mobile,
    tablet,
    desktop,
    desktop_xl: desktopXl,
    alt_text: altText,
    is_branch_link: isBranchLink,
    inCarousel,
}: PromoBannerProps): JSX.Element | null {
    const dispatch = useDispatch();
    const { isMobileViewport, isTabletViewport, isDesktopViewport } = useDomViewport();

    if (mobile == null && tablet == null && desktop == null && desktopXl == null) {
        return null;
    }

    function scanQrCode(e): void {
        e.preventDefault();
        dispatch(
            openCustomerCaptureOverlay({
                captureType: "qr_code",
                branchLink: RawBranchLink.AppTeaser,
            })
        );
    }

    const promoPicture = (
        <PromoPicture
            mobile={mobile}
            tablet={tablet}
            desktop={desktop}
            desktopXl={desktopXl}
            altText={altText}
        />
    );

    const carouselLinkAttributes = inCarousel
        ? {
              target: "_blank",
              rel: "noreferrer",
          }
        : {};

    const linkAttributes =
        isDesktopViewport && isBranchLink
            ? { href: "#", onClick: scanQrCode, ...carouselLinkAttributes }
            : { href: url, ...carouselLinkAttributes };

    return (
        <div className={styles.container}>
            {(isMobileViewport || isTabletViewport) && isBranchLink ? (
                <BranchLink title={""} branchLink={url} eventLabel={"homepage_promo"}>
                    {promoPicture}
                </BranchLink>
            ) : (
                <a {...linkAttributes}>{promoPicture}</a>
            )}
        </div>
    );
}

function PromoPicture({ mobile, tablet, desktop, desktopXl, altText }: PromoPicture): JSX.Element {
    return (
        <picture className={styles.picture}>
            {/* XL Desktop */}
            {desktopXl && <source srcSet={desktopXl} media="(min-width: 1135px)" />}

            {/* Desktop */}
            {desktop && <source srcSet={desktop} media="(min-width: 992px)" />}

            {/* Tablet */}
            {tablet && <source srcSet={tablet} media="(min-width: 576px)" />}

            {/* Mobile */}
            {mobile && <source srcSet={mobile} media="(min-width: 0px)" />}

            {/* Fallback if picture element not supported */}
            <img className={styles.image} src={desktop || ""} alt={altText} />
        </picture>
    );
}

export default withReduxProvider(PromoSection);

import React from "react";
import { HeroStory } from "web/react/components/home/stories/hero-story";
import { PrimaryStory } from "web/react/components/home/stories/primary-story";
import { ArchiveDeviceDivider } from "web/react/emo/archive-device-divider";
import { HeroStorySerializer, PrimaryStorySerializer } from "web/types/serializers";

interface StoryContainerProps {
    story: HeroStorySerializer | PrimaryStorySerializer;
    storyType: "hero" | "primary";
    className?: string;
    position: number;
}

export function StoryContainer({
    story,
    storyType,
    className,
    position,
}: StoryContainerProps): React.ReactElement {
    return (
        <ArchiveDeviceDivider overlap>
            <div className={className}>
                {storyType === "hero" ? (
                    <HeroStory {...story} position={position} />
                ) : (
                    <PrimaryStory {...story} position={position} />
                )}
            </div>
        </ArchiveDeviceDivider>
    );
}

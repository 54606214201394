import React from "react";

import clsx from "clsx";
import { Text } from "web/react/emo/text";
import { View } from "web/react/emo/view/view";
import * as styles from "./product-card-label.css";

interface ProductCardLabelProps {
    text: string;
    variant?: "primary" | "secondary";
    width?: "full" | "fit-content";
}

/**
 * Design System
 * @name ProductCardLabel
 * @version 1.0
 * @design https://www.figma.com/design/VhOHx4tv9uYXZHXAAKPVYf/Design-System?node-id=17324-3238&t=wt2IfpOfNQRmEIZY-0
 */
export const ProductCardLabel = React.forwardRef<HTMLDivElement, ProductCardLabelProps>(
    function ProductCardLabel({ text, variant = "primary", width = "full" }, ref): JSX.Element {
        return (
            <div ref={ref}>
                <View
                    background="placeholder"
                    paddingTop="xxs"
                    paddingRight="xs"
                    paddingBottom="xxs"
                    paddingLeft="xs"
                    className={clsx(styles.wrapper, {
                        [styles.secondaryWrapper]: variant === "secondary",
                        [styles.fullWidth]: width === "full",
                    })}
                >
                    <Text color="grayscale800" textStyle="caption-3-small" upperCase>
                        {text}
                    </Text>
                </View>
            </div>
        );
    }
);

import { FieldValidator, useField } from "formik";
import React from "react";
import { PasswordInput, PasswordInputProps } from "web/react/components/inputs-base-components";
import { VStack } from "web/react/emo/v-stack";
import {
    ValidationRequirements,
    ValidationRequirementsProps,
} from "web/react/emo/validation-requirement";
import { gettext } from "web/script/modules/django-i18n";

interface PasswordInputFieldProps extends PasswordInputProps {
    name: string;
    validate?: FieldValidator;
}

export function FormikPasswordField({
    name,
    validate,
    value,
    ...props
}: PasswordInputFieldProps): JSX.Element {
    const [field, { error }] = useField({ type: "password", name, validate, value });

    return (
        <PasswordInput {...props} {...field} id={field.name} error={error} inputTheme={"primary"} />
    );
}

export function FormikPasswordFieldWithValidation({
    name,
    validate,
    value,
    requirements,
    ...props
}: PasswordInputFieldProps & Omit<ValidationRequirementsProps, "title" | "value">): JSX.Element {
    const [field, { error }] = useField({ type: "password", name, validate, value });

    return (
        <VStack spacing={"xxxs"}>
            <PasswordInput
                {...props}
                {...field}
                id={field.name}
                error={error}
                inputTheme={"primary"}
            />
            {field.value && error && (
                <ValidationRequirements
                    requirements={requirements}
                    // "Your password should contain at least:"
                    title={gettext("account.set_password_contain.title")}
                    value={field.value}
                />
            )}
        </VStack>
    );
}

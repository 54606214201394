import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import withReduxProvider from "web/react/redux-provider";
import { openCustomerCaptureOverlay } from "web/redux/ducks/customer-capture-overlay";
import userProfiler from "web/script/modules/userprofiler";

function _FacebookCampaignControl(): JSX.Element {
    const dispatch = useDispatch();

    useEffect(() => {
        const queryParams = new URL(window.location.href).searchParams;
        if (!userProfiler.isLoggedIn() && queryParams.has("hb")) {
            dispatch(
                openCustomerCaptureOverlay({
                    analyticsEventCategory: "membership",
                    analyticsEventLabel: "facebook_landing_campaign",
                    blockType: "hard_block_after_action",
                    captureType: "facebook_landing_campaign",
                    componentType: "sign_up",
                    ctaKey: "general.continue",
                    titleKey: "member_signup.generic_member.title",
                })
            );
        }
    }, [dispatch]);

    return <div />;
}

export const FacebookCampaignControl = withReduxProvider(_FacebookCampaignControl);

/**
 * This is a simple utility module to remove all currency characters
 * from string and return float
 *
 * i.e.
 *      "£30.00" >>> 30.00
 *
 *
 * @params {String} string
 * @returns {Number}
 */
export function removeCurrencyChars(string: string): number {
    // The regex below will match all character not present in the list below:
    // \d matches a digit (equivalent to [0-9])
    // \. matches the character .
    // \s matches any whitespace character
    // /g permits replace() to replace each occurrence
    return parseFloat(string.replace(/[^\d\.\s]/g, ""));
}

import clsx from "clsx";
import React, { Children } from "react";
import { KeylineGrid, KeylineGridItem } from "web/react/components/__LEGACY__/keyline-grid";
import ProductCard from "web/react/components/product-card/react";
import { RelatedProductsShowMore } from "web/react/components/related-products-show-more/related-products-show-more";
import styles from "./related-products-section.module.css";

interface RelatedProductsSectionProps {
    viewMoreUrl: string | null;
    children: React.ReactElement | null | (React.ReactElement | null)[];
    hiddenOnDesktop?: number;
    isInStock: boolean;
    isAffiliate: boolean;
    retailerSlug: string | null;
}

function RelatedProductsSection({
    children,
    viewMoreUrl,
    hiddenOnDesktop,
    isInStock,
    isAffiliate,
    retailerSlug,
}: RelatedProductsSectionProps): React.ReactElement {
    let firstChild;

    if (children) {
        // We are expecting all children to be the same (when we pass product cards)
        firstChild = Children.count(children) > 1 ? children[0] : children;
    }

    function renderFeed(): React.ReactElement | null {
        if (!children) {
            return null;
        }

        return (
            <KeylineGrid fullWidth>
                {Children.map(children as React.ReactElement[], (child, index) => (
                    <KeylineGridItem
                        key={child.props.product.uid}
                        className={clsx({
                            [styles.hiddenOnDesktop]: hiddenOnDesktop && index < hiddenOnDesktop,
                        })}
                    >
                        {child}
                    </KeylineGridItem>
                ))}
            </KeylineGrid>
        );
    }

    return (
        <div className={styles.section}>
            {firstChild && firstChild.type === ProductCard ? renderFeed() : children}
            {viewMoreUrl && firstChild && firstChild.type === ProductCard && (
                <div className={styles.viewFeed}>
                    <RelatedProductsShowMore
                        view_more_url={viewMoreUrl}
                        product_is_instock={isInStock}
                        isAffiliate={isAffiliate}
                        retailerSlug={retailerSlug}
                    />
                </div>
            )}
        </div>
    );
}

export default RelatedProductsSection;

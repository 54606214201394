import _each from "lodash/each";
import googleConversion from "web/script/embedded/google-conversion";
import environment from "web/script/modules/environment";

export default {
    // Parent MCC Account
    conversionIdSearchAndPla: 11165785113, // Google Ads account name: "Lyst Ltd - Search and PLA"
    searchAndPlaGroupName: "Search and PLA MCC Account",

    // Search related conversion actions
    conversionIdSearch: 982977399, // Google Ads account name: "Lyst LTD MCC"
    rplConversionLabel: "p9nCCMudtNMBEPeW3NQD",
    appBannerConversionLabel: "qaESCNf5zdUBEPeW3NQD",

    // PLA related conversion actions
    conversionIdPla: 605072450, // Google Ads account name: "Lyst PLA MCC"

    // CSS related conversion actions
    conversionIdCssUK: 827715413, // Google Ads account name: "Lyst CSS - UK"
    conversionIdCssEU: 788956605, // Google Ads account name: "Lyst CSS - EUR"

    init: function () {
        googleConversion("config", "AW-" + this.conversionIdSearchAndPla, {
            send_page_view: false,
            groups: this.searchAndPlaGroupName,
        });
        googleConversion("config", "AW-" + this.conversionIdSearch, { send_page_view: false });
        googleConversion("config", "AW-" + this.conversionIdPla, { send_page_view: false });
        googleConversion("config", "AW-" + this.conversionIdCssUK, { send_page_view: false });
        googleConversion("config", "AW-" + this.conversionIdCssEU, { send_page_view: false });
    },

    send: function (data) {
        // this will contain any promises we generate
        var promises = [];

        _each(data, (event) => {
            const userId = event?.data?.device_id;
            // Send Page View events on all pages apart from Lead page
            if (event.data.page_type !== "lead" && event.type === "page_view") {
                let adwordsPayload = {
                    google_remarketing_only: true,
                };
                if (userId) {
                    adwordsPayload.user_id = userId;
                }
                if (event.data.product_id) {
                    adwordsPayload.ecomm_prodid = event.data.product_id;
                    adwordsPayload.ecomm_pagetype = "product";
                }
                if (event.data.paid_no_retarget) {
                    adwordsPayload.no_retarget = event.data.paid_no_retarget;
                }
                if (event.data.life_cycle_stage) {
                    adwordsPayload.life_cycle_stage = event.data.life_cycle_stage;
                }
                // Send to default group - all accounts
                googleConversion("event", "page_view", adwordsPayload);
            } else if (
                event.type === "event" &&
                event.data.category === "affiliate" &&
                (event.data.action === "lead" || event.data.action === "lead_sold_out")
            ) {
                // Remarketing pixel, send to default group - all accounts
                const defaultGroupData = { value: 0 };
                if (userId) {
                    defaultGroupData.user_id = userId;
                }
                promises.push(createGtagPromise("page_view", defaultGroupData));

                // Revenue-per-Lead predicted conversion value
                const predictedRPL = environment.get("predictedTrackValue");
                if (predictedRPL) {
                    promises.push(
                        createConversionGtagPromise(
                            this.conversionIdSearch,
                            this.rplConversionLabel,
                            predictedRPL,
                            "USD",
                            userId
                        )
                    );
                }
            } else if (
                event.type === "event" &&
                event.data.category === "web_to_app" &&
                event.data.action === "click"
            ) {
                const campaignName = event.data.customData["campaign_name"];
                const cpi = environment.get("webToAppCpi", {})[campaignName];
                promises.push(
                    createConversionGtagPromise(
                        this.conversionIdSearch,
                        this.appBannerConversionLabel,
                        ...(cpi === undefined ? [null, null] : [cpi, "USD"]),
                        userId
                    )
                );
            }
        });

        // wait for any promises what we were asked to wait for
        return Promise.all(promises);
    },
};

function createGtagPromise(event, data) {
    return new Promise((resolve) => {
        // create gtag
        data.event_callback = resolve;
        googleConversion("event", event, data);
    });
}

function createConversionGtagPromise(id, label, value, currency, userId) {
    let data = {
        send_to: "AW-" + id + "/" + label,
        label: label,
    };
    if (value || value === 0) {
        data.value = value;
    }
    if (currency) {
        data.currency = currency;
    }
    if (userId) {
        data.user_id = userId;
    }

    return createGtagPromise("conversion", data);
}

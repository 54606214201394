/* eslint-disable max-depth */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import _cloneDeep from "lodash/cloneDeep";
import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useExternalGender } from "web/react/components/feeds-filters/hooks/use-external-gender";
import { PendingFilters } from "web/react/components/feeds-filters/pending-filters";
import { useDomViewport } from "web/react/hooks/use-dom-viewport/use-dom-viewport";
import { useHistoryWatcher } from "web/react/hooks/use-history-watcher/use-history-watcher";
import { useRothkoFetcher } from "web/react/hooks/use-rothko-fetcher";
import {
    hideLandingContent,
    setActiveFilters,
    setDisplayedFilters,
    setTitle,
} from "web/redux/ducks/feed-filters";
import { ReduxStoreState } from "web/redux/store";
import analytics from "web/script/analytics/analytics";
import { gettext, gettextNoop } from "web/script/modules/django-i18n";
import environment from "web/script/modules/environment";
import { getColorLabel, getMaterialLabel } from "web/script/modules/labels";
import rothko from "web/script/modules/rothko";
import { getUrl } from "web/script/routing/getUrl";
import { getProductPageRegex } from "web/script/routing/routes";
import history from "web/script/utils/history";
import logging from "web/script/utils/logging";
import { AllSelectedFilterInfo } from "web/types/feed-filters";
import { UniversalFilterOptionSerializer } from "web/types/serializers";
import {
    BaseFilterOption,
    FilteredScrollAreaFilterOptionGroup,
    UniversalFilterOption,
    UniversalFiltersData,
} from "web/types/universal-filters";
import {
    getDefaultSelectOption,
    purgeAllValues,
    useFilterClickHandler,
} from "./use-filter-click-handler";
import {
    useDetailedFilterCountHandler,
    useFilterCountHandlerLegacy,
} from "./use-filter-count-handler";
import { FILTERS_OVERLAY_NAME } from "./use-overlay-handler";
import { useSelectedFilterInfo } from "./use-selected-filter-info";
import { useSortingHandler } from "./use-sorting-handler";

/**
 * Find the most deeply nested selected item with no selected siblings
 */
function findLeafSelection(filters: UniversalFilterOption[]): UniversalFilterOption | null {
    let selectedChildren = filters.filter((item) => item.checked);

    if (selectedChildren.length !== 1) {
        return null;
    }

    let filter = selectedChildren[0];

    if (filter.type === "category") {
        return findLeafSelection(filter.children) || filter;
    }

    return filter;
}

function getFeedsTypeParams(filters: UniversalFiltersData) {
    let selectedLeaves = {};
    for (let section of filters.filter_section_list) {
        if (section.type !== "pair_of_selects") {
            // ORG-3733: Add the color variant filters selection
            if (section.id === "section-color") {
                for (let group of section.option_groups) {
                    selectedLeaves[group.id.replace("option-group", "section")] = findLeafSelection(
                        group.options
                    );
                }
            } else {
                selectedLeaves[section.id] = findLeafSelection(section.option_groups[0].options);
            }
        }
    }

    return {
        selectedGender: selectedLeaves["section-gender"],
        selectedGenderValue:
            selectedLeaves["section-gender"] && selectedLeaves["section-gender"].value,
        selectedTaxonomy: selectedLeaves["section-category"],
        selectedDesigner: selectedLeaves["section-designer-slug"],
        selectedColor: selectedLeaves["section-color"],
        selectedColorVariant: selectedLeaves["section-color-variant"],
        selectedMaterial: selectedLeaves["section-material"],
        selectedDiscountFrom: selectedLeaves["section-discount-from"],
        selectedAllSale:
            selectedLeaves["section-discount-from"] &&
            selectedLeaves["section-discount-from"].value === "1",
        selectedSize: selectedLeaves["section-sizes"],
    };
}

function getFeedsPageSubType(filters: UniversalFiltersData): string {
    const {
        selectedGenderValue,
        selectedDesigner,
        selectedTaxonomy,
        selectedColor,
        selectedColorVariant,
    } = getFeedsTypeParams(filters);

    const selectedTaxonomyLevel =
        selectedTaxonomy?.name === "product_type" ? "type" : selectedTaxonomy?.name;
    const feedType = selectedDesigner ? "designer" : "generic";

    if (selectedTaxonomy) {
        return selectedColor || selectedColorVariant
            ? `${feedType}_${selectedTaxonomyLevel}_colour`
            : `${feedType}_${selectedTaxonomyLevel}`;
    } else if (selectedGenderValue) {
        return `${feedType}_gender`;
    } else {
        return feedType;
    }
}

export function getFeedsTitle(filters: UniversalFiltersData): string {
    const {
        selectedGenderValue,
        selectedTaxonomy,
        selectedDesigner,
        selectedColor,
        selectedColorVariant,
        selectedMaterial,
        selectedAllSale,
        selectedSize,
    } = getFeedsTypeParams(filters);

    // ORG-3733: Construct the color label for color variant filter selected
    let colorLabel = "";
    if (selectedColor) {
        colorLabel = selectedColorVariant?.label || getColorLabel(selectedColor.label);
    }

    const forGenderLabels = {
        Women: gettext("general.womens"),
        Men: gettext("general.mens"),
    };

    const forGenderTemplateLabels = {
        Women: "for_women",
        Men: "for_men",
    };

    let forGender = forGenderLabels[selectedGenderValue] || false;

    let forGenderTemplate = forGenderTemplateLabels[selectedGenderValue] || false;

    /* eslint-disable max-depth */
    if (!selectedTaxonomy) {
        if (selectedDesigner) {
            if (forGender) {
                if (selectedAllSale) {
                    // "{for_gender} {designer_name} on Sale"
                    return gettext("sale_feed.header.designer_for_gender", {
                        for_gender: forGender,
                        designer_name: selectedDesigner.label,
                    });
                }
                // "{for_gender} {designer_name}"
                return gettext("designer_shop.designer_gender_page_title", {
                    for_gender: forGender,
                    designer_name: selectedDesigner.label,
                });
            }
            if (selectedAllSale) {
                // "{designer_name} on Sale"
                return gettext("sale_feed.header.designer", {
                    designer_name: selectedDesigner.label,
                });
            }
            // "{designer_name}"
            return selectedDesigner.label;
        }
        return gettext("feed.browse_title");
    }
    if (selectedDesigner) {
        if (forGender) {
            if (selectedAllSale) {
                // "{for_gender} {designer_name} {product_taxonomy} on Sale"
                return gettext("sale_feed.title.product_taxonomy_for_designer_and_gender", {
                    for_gender: forGender,
                    designer_name: selectedDesigner.label,
                    product_taxonomy: selectedTaxonomy.label,
                });
            }
            if (selectedTaxonomy.name == "product_type") {
                if (selectedColor) {
                    // {for_gender} {designer_name} {product_type} {color}"
                    return gettext("feed.page_title.type_for_gender_color.designer", {
                        for_gender: forGender,
                        product_type: selectedTaxonomy.label,
                        designer_name: selectedDesigner.label,
                        color: colorLabel,
                    });
                }
                return gettext("product.breadcrumbs.type_by_designer_for_gender", {
                    for_gender: forGender,
                    product_type: selectedTaxonomy.label,
                    designer_name: selectedDesigner.label,
                });
            }
            if (selectedTaxonomy.name === "category") {
                if (selectedColor) {
                    // "{for_gender} {designer_name} {product_category} {color}"
                    return gettext("feed.page_title.subcategory_for_gender_color.designer", {
                        for_gender: forGender,
                        product_subcategory: selectedTaxonomy.label,
                        designer_name: selectedDesigner.label,
                        color: colorLabel,
                    });
                }
                // "{for_gender} {designer_name} {product_category}"
                return gettext("product.breadcrumbs.category_by_designer_for_gender", {
                    for_gender: forGender,
                    product_category: selectedTaxonomy.label,
                    designer_name: selectedDesigner.label,
                });
            }
            // "{for_gender} {designer_name} {product_category} {color}"
            if (selectedTaxonomy.name === "subcategory") {
                if (selectedColor) {
                    return gettext("feed.page_title.subcategory_for_gender_color.designer", {
                        for_gender: forGender,
                        product_subcategory: selectedTaxonomy.label,
                        designer_name: selectedDesigner.label,
                        color: colorLabel,
                    });
                }
                // "{for_gender} {designer_name} {product_subcategory}"
                return gettext("product.breadcrumbs.subcategory_by_designer_for_gender", {
                    for_gender: forGender,
                    product_subcategory: selectedTaxonomy.label,
                    designer_name: selectedDesigner.label,
                });
            }
        } else {
            if (selectedAllSale) {
                // "{designer_name} {product_taxonomy} on Sale"
                return gettext("sale_feed.title.product_taxonomy_for_designer", {
                    designer_name: selectedDesigner.label,
                    product_taxonomy: selectedTaxonomy.label,
                });
            }

            if (selectedTaxonomy.name === "product_type") {
                if (selectedColor) {
                    // {color} {designer_name} {product_type}
                    return gettext("feed.page_title.type_color.designer", {
                        product_type: selectedTaxonomy.label,
                        designer_name: selectedDesigner.label,
                        color: colorLabel,
                    });
                }
                // "{designer_name} {product_type}"
                return gettext("product.breadcrumbs.type_by_designer", {
                    product_type: selectedTaxonomy.label,
                    designer_name: selectedDesigner.label,
                });
            }
            if (selectedTaxonomy.name === "category") {
                if (selectedColor) {
                    // {color} {product_category} {designer_name}
                    return gettext("feed.page_title.template_color_no_discount_designer", {
                        color: colorLabel,
                        category_name: selectedTaxonomy.label,
                        designer_name: selectedDesigner.label,
                    });
                }
                // "{designer_name} {product_category}"
                return gettext("product.breadcrumbs.category_by_designer", {
                    product_category: selectedTaxonomy.label,
                    designer_name: selectedDesigner.label,
                });
            }
            if (selectedTaxonomy.name === "subcategory") {
                if (selectedColor) {
                    // {color} {designer_name} {product_subcategory}
                    return gettext("feed.page_title.subcategory_color.designer", {
                        product_subcategory: selectedTaxonomy.label,
                        designer_name: selectedDesigner.label,
                        color: colorLabel,
                    });
                }
                // "{designer_name} {product_subcategory}"
                return gettext("product.breadcrumbs.subcategory_by_designer", {
                    product_subcategory: selectedTaxonomy.label,
                    designer_name: selectedDesigner.label,
                });
            }
        }
    } else if (forGender) {
        if (selectedAllSale) {
            // "{for_gender} {product_taxonomy} On Sale"
            return gettext("sale_feed.title.product_taxonomy_for_gender", {
                for_gender: forGender,
                product_taxonomy: selectedTaxonomy.label,
            });
        }
        if (selectedColor && !selectedMaterial && !selectedSize) {
            // "{for_gender} {color} {category_name}"
            return gettext("feed.header.template_color", {
                for_gender: forGender,
                color: colorLabel,
                category_name: selectedTaxonomy.label,
            });
        }
        if (selectedMaterial && !selectedColor && !selectedSize) {
            // "{for_gender} {material} {category_name}"
            return gettext("feed.header.template_material", {
                for_gender: forGender,
                material: getMaterialLabel(selectedMaterial.label),
                category_name: selectedTaxonomy.label,
            });
        }

        // ORG-3697 Size headers only applicable to Shoes
        if (
            selectedSize &&
            !selectedColor &&
            !selectedMaterial &&
            selectedTaxonomy.id.includes("taxonomy-shoes") &&
            environment.getFeature("seo_category_size_feeds")
        ) {
            // "{country} {size_number} {category} for Men"
            // "{country} {size_number} {category} for Women"
            // US Size 8'5 Shoes for Men
            // US Size 8'5 Shoes for Women
            let sizeLabel = selectedSize.label.split(" "); // [8.5, US], [2, UK]

            let sizeNumber = sizeLabel[0].replace(".", "'");

            let country = environment.get("country", sizeLabel[1]);
            if (country === "GB") {
                country = "UK";
            }

            // The noop queries are to force the parser to add the keys to the list
            // of translation keys (parser can't guess the parameterised string).
            gettextNoop("feed.header.template_size_for_women_category");
            gettextNoop("feed.header.template_size_for_men_category");
            let i18nKey = `feed.header.template_size_${forGenderTemplate}_category`;
            return gettext(i18nKey, {
                country: country,
                size_number: sizeNumber,
                category: selectedTaxonomy.label,
            });
        }
        if (selectedTaxonomy.name === "product_type") {
            // "{for_gender} {product_type}"
            return gettext("product.breadcrumbs.type_for_gender", {
                product_type: selectedTaxonomy.label,
                for_gender: forGender,
            });
        }
        if (selectedTaxonomy.name === "category") {
            // "{for_gender} {product_category}"
            return gettext("product.breadcrumbs.category_for_gender", {
                product_category: selectedTaxonomy.label,
                for_gender: forGender,
            });
        }
        if (selectedTaxonomy.name === "subcategory") {
            // "{for_gender} {product_subcategory}"
            return gettext("product.breadcrumbs.subcategory_for_gender", {
                product_subcategory: selectedTaxonomy.label,
                for_gender: forGender,
            });
        }
    } else {
        if (selectedColor && !selectedMaterial) {
            // "{color} {category_name}"
            return gettext("feed.header.template_color_all", {
                color: colorLabel,
                category_name: selectedTaxonomy.label,
            });
        }
        if (selectedMaterial && !selectedColor) {
            // "{material} {category_name}"
            return gettext("feed.header.template_material_all", {
                material: getMaterialLabel(selectedMaterial.label),
                category_name: selectedTaxonomy.label,
            });
        }
        if (["product_type", "category", "subcategory"].includes(selectedTaxonomy.name)) {
            // "{category_name}"
            return selectedTaxonomy.label;
        }
    }
    /* eslint-enable max-depth */

    return gettext("feed.browse_title");
}

export function getSelectedOption(
    selectedFilterInfo: AllSelectedFilterInfo,
    section: string
): UniversalFilterOption[] {
    if (!selectedFilterInfo) {
        return [];
    }

    return selectedFilterInfo[section]?.breadcrumbs.filter(
        (option: BaseFilterOption) => !option.disabled && option.checked
    );
}
export function getSelectedOptions(
    selectedFilterInfo: AllSelectedFilterInfo
): UniversalFilterOption[] {
    if (!selectedFilterInfo) {
        return [];
    }

    return Object.values(selectedFilterInfo)
        .flatMap((section) => section.breadcrumbs)
        .filter((option: BaseFilterOption) => !option.disabled);
}
export interface FilterManager {
    applyState(): void;
    canApply: boolean;
    cancelState(): void;
    displayedFilters: UniversalFiltersData;
    isLoading: boolean;
    lazyFacetFetch(optionGroupId: string): void;
    lazyFacetIsLoading: boolean;
    onClick(
        filter: UniversalFilterOption | UniversalFilterOptionSerializer,
        immediateUnselect?: boolean,
        alwaysApply?: boolean
    ): void;
    pendingFilterInfo: PendingFilters;
    selectedFilterInfo: AllSelectedFilterInfo;
    suggestedFilters: UniversalFilterOptionSerializer[];
    updateFromServer(): void;
    clearAllFilters(): void;
    clearSelectedFilters(section: string | string[], shouldApply?: boolean): void;
    applyMultiple(filters: UniversalFilterOption[] | UniversalFilterOptionSerializer[]): void;
}

export function useFilterManager(initialState: UniversalFiltersData): FilterManager {
    const dispatch = useDispatch();

    const { active, displayed } = useSelector((state: ReduxStoreState) => state.feedFiltersReducer);

    const activeFilters = active || initialState;
    const displayedFilters = displayed || initialState;

    function applyDisplayedFilters(newFilters: UniversalFiltersData | undefined): void {
        // if we have just fetched the filters, we need to pass them in because the state
        // (displayedFilters) won't be updated until the next render
        dispatch(setActiveFilters(newFilters || displayedFilters));
    }

    function revertDisplayedFilters(): void {
        dispatch(setDisplayedFilters(activeFilters));
    }

    const {
        toggleFilter,
        toggleMultipleFilters,
        // This is an override object for `displayedFilters` so the user can click
        //  on things without having to wait for rothko after every tap
        pendingFilterInfo,
        resetPendingFilters,
        removeAllSelectedFilters,
    } = useFilterClickHandler(displayedFilters);

    // This is the current sorting system the user has chosen.
    // I'm not going to include it in any of our state to avoid having to deal
    //  with what happens when it updates - it can just stay in Redux and be
    //  pulled out whenever we update the URL
    const feedSortingState = useSortingHandler();
    // This is all the info required to display the selected filter state on the
    //  menu, with labels, selected subcounts & breadcrumb pills
    const [numSelectedFilters, selectedFilterInfo] = useSelectedFilterInfo(
        displayedFilters,
        pendingFilterInfo
    );

    // gets the suggested filters to display in the suggested filter pills within the feed filters
    const suggestedFilters = displayedFilters.suggested_filters;

    const onFiltersUpdated = useCallback(
        (newState: UniversalFiltersData) => {
            dispatch(setDisplayedFilters(newState));
            resetPendingFilters();
        },
        [dispatch, resetPendingFilters]
    );

    const [fetchFilters, isLoading] = useRothkoFetcher<UniversalFiltersData>(
        "modules/universal_filters",
        onFiltersUpdated
    );

    // fetch facets for designers or retailers
    const [lazyFiltersFetch, lazyFacetIsLoading] =
        useRothkoFetcher<FilteredScrollAreaFilterOptionGroup>(
            "modules/option_group_filtered_scroll_area"
        );

    useFilterCountHandlerLegacy(numSelectedFilters);

    useDetailedFilterCountHandler(selectedFilterInfo);

    const { isDesktopViewport } = useDomViewport();
    const urlData = useHistoryWatcher();

    /*
     * SERVER TALKIN'
     */
    function generateFeedsURL(newFilters: UniversalFiltersData) {
        return new URL(newFilters.canonical_url, window.location.href);
    }

    function updateFeedsQuery(url: URL) {
        if (feedSortingState) {
            if (feedSortingState == "recommended") {
                // Don't include the default sorting in the URL
                url.searchParams.delete("view");
            } else {
                url.searchParams.set("view", feedSortingState);
            }
        }

        // Disable `saveScrollPosition` because on filters changes
        // we tend to scroll the user to the top of the Feed, in order to see the new products.
        history.pushState(url, { saveScrollPosition: false });
    }

    function setFeedsTitle(filters: UniversalFiltersData) {
        let title = getFeedsTitle(filters);
        dispatch(setTitle(title));
    }

    function setFeedsPageSubType(filters: UniversalFiltersData) {
        let pageSubType = getFeedsPageSubType(filters);
        environment.set("pageSubType", pageSubType);
    }

    async function refreshFromServer(
        query: URLSearchParams
    ): Promise<UniversalFiltersData | undefined> {
        try {
            // The query order _shouldn't_ matter, but in case it does be consistent
            query.sort();
            return await fetchFilters(query);
        } catch (e: any) {
            // TODO: I don't know what to do in this case? Retry? Discard all pending filters?
            logging.error(e, "TODO");
        }
    }

    async function lazyFacetFetch(optionGroupId: string) {
        let params = new URLSearchParams(
            displayedFilters.custom_query_string || displayedFilters.query_string
        );
        params.set("option_group_id", optionGroupId);
        let data: any;
        try {
            data = await lazyFiltersFetch(params);
        } catch (err: any) {
            logging.error(err, `Failed to fetch options for ${optionGroupId}`);
            return;
        }
        // Find the right place in the displayedFilters to insert this. It
        // should replace the data for the option group we just fetched

        // Note that re-fetching the main set of facets (refreshFromServer)
        // resets the lazy loaded facets. This is what we want for now, since
        // it will force us to re-fetch them when next opened.

        // we clone the whole displayedFilters so we can mutate our copy in-place
        let newFilterSectionList = _cloneDeep(displayedFilters.filter_section_list);
        for (let section of newFilterSectionList) {
            // this only works for single-group sections
            if (section.option_groups.length != 1) {
                continue;
            }
            let group = section.option_groups[0];
            if (group.id != optionGroupId) {
                continue;
            }
            section.option_groups = [data];

            dispatch(
                setDisplayedFilters({
                    ...displayedFilters,
                    filter_section_list: newFilterSectionList,
                })
            );
            return;
        }
        logging.error(`${optionGroupId} does not exist in the displayed filters!`);
    }

    /*
     *  CALLBACKS
     */
    async function applyState(newFilters?: UniversalFiltersData): Promise<void> {
        if (!newFilters && pendingFilterInfo.hasPending()) {
            // TODO: This call may fail in which case we'll just apply all the
            //        non-pending filters
            newFilters = await refreshFromServer(pendingFilterInfo.toQuery(displayedFilters));
        }
        // TODO: Check if there are actually any changes to be applied
        applyDisplayedFilters(newFilters);

        // send the event as part of the current page view
        analytics.event("filter", "apply");
        updateFeedsQuery(generateFeedsURL(newFilters || displayedFilters));
        if (displayedFilters.feed_url == getUrl("exploreFeed")) {
            // if the feed_url isn't /explore/, we have pre-filters that we can't remove, which
            // are also what's powering e.g. the title and description
            setFeedsTitle(newFilters || displayedFilters);

            // Set the pageType if for some reason it is not set
            if (!environment.get("pageType")) {
                environment.set("pageType", "feed");
            }
            setFeedsPageSubType(newFilters || displayedFilters);
            dispatch(hideLandingContent());
        }
    }

    async function clearAllFilters(): Promise<void> {
        let selectedOptions = getSelectedOptions(selectedFilterInfo);
        let pendingFilterInfo = removeAllSelectedFilters(selectedOptions);

        if (pendingFilterInfo) {
            let filterQuery = pendingFilterInfo.toQuery(displayedFilters);
            // A special case for wishlist feed. Clear all will return to empty filters wishlist
            const wishlistUrl = new URL("/account/wishlist/", window.location.href);
            if (displayedFilters.feed_url === wishlistUrl.pathname) {
                filterQuery = new URLSearchParams();
                filterQuery.set("feed_url", wishlistUrl.pathname);
            }
            let newFilters = await refreshFromServer(filterQuery);
            // Make sure the menu reflects the new filters if we're on desktop
            applyState(newFilters);
        }
    }

    async function clearSelectedFilters(
        section: string | string[],
        shouldApply = true
    ): Promise<void> {
        const selectedOptions: UniversalFilterOption[] = Array.isArray(section)
            ? section
                  .flatMap((key) => getSelectedOption(selectedFilterInfo, key))
                  .filter((option) => option ?? option)
            : getSelectedOption(selectedFilterInfo, section);

        if (selectedOptions && selectedOptions.length < 1) return;

        const pendingFilterInfo = removeAllSelectedFilters(selectedOptions);

        if (pendingFilterInfo) {
            purgeAllValues(
                pendingFilterInfo.toQuery(displayedFilters),
                pendingFilterInfo,
                selectedOptions[0].name
            );
            let newFilters = await refreshFromServer(pendingFilterInfo.toQuery(displayedFilters));
            // Make sure the menu reflects the new filters if we're on desktop
            if (shouldApply) {
                applyState(newFilters);
            }
        }
    }

    /**
     * Universal handler for filtery things being clicked on
     * @param filter The filter item the user clicked on
     * @param immediateUnselect If they clicked it in the breadcrumb/pill thingy
     * @param alwaysApply If we want to override sidebar behaviour when the action is from gender switch
     */
    async function onClick(
        filter: UniversalFilterOption | UniversalFilterOptionSerializer,
        immediateUnselect = false,
        alwaysApply = false
    ): Promise<void> {
        if (isLoading) {
            return;
        }

        if (immediateUnselect && filter.type === "option") {
            // We need to change this to a request to select the default value
            //  for this option group because you can't "unselect" options.
            filter = getDefaultSelectOption(filter, displayedFilters);
        }

        let pendingFilterInfo = toggleFilter(filter);

        let shouldApply = alwaysApply || isDesktopViewport;

        if (immediateUnselect || shouldApply) {
            let newFilters = await refreshFromServer(pendingFilterInfo.toQuery(displayedFilters));
            // Make sure the menu reflects the new filters if we're on desktop
            if (shouldApply) {
                applyState(newFilters);
            }
        }
    }

    /**
     * Handler for applying multiple filters at once
     * @param filters The filters the user clicked on
     */
    function applyMultiple(
        filters: UniversalFilterOption[] | UniversalFilterOptionSerializer[]
    ): void {
        if (isLoading) {
            return;
        }

        toggleMultipleFilters(filters);
    }

    function cancelState(): void {
        revertDisplayedFilters();
        resetPendingFilters();
        // Nuke any in-flight update requests by creating a new one.
        // This should be resolved immediately from the cache, but even if it
        //  isn't the user won't see anything because the filters will be closed.
        refreshFromServer(new URLSearchParams(activeFilters.query_string));
    }

    function updateFromServer(): void {
        if (pendingFilterInfo.hasPending()) {
            refreshFromServer(pendingFilterInfo.toQuery(displayedFilters));
        }
    }

    let canApply =
        activeFilters.canonical_url !== displayedFilters.canonical_url ||
        pendingFilterInfo.hasPending();

    useEffect(() => {
        if (initialState.filter_section_list.length) {
            // Avoid re-fetching the initial state once we have it.
            rothko.addCacheEntry(
                "modules/universal_filters",
                initialState.query_string,
                initialState
            );
            return;
        }

        async function getInitialData() {
            try {
                let newFilters = await refreshFromServer(
                    new URLSearchParams(initialState.query_string)
                );
                applyDisplayedFilters(newFilters);
            } catch (err: any) {
                logging.error(err, "Failed to get universal_filters data");
            }
        }

        getInitialData();
        // We only ever need to run this hook once, if we are initialised with no filters from the
        // server (e.g. on /search/, where we want to fetch them with ajax after the page load)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (!urlData) {
            return;
        }

        // Filters don't change on pagination so lets not reload them
        if (
            urlData.queryChanges?.[0]?.key === "page" &&
            ["changed", "added"].includes(urlData.queryChanges[0].type)
        ) {
            return;
        }

        if (
            getProductPageRegex().test(urlData.newURL.pathname) ||
            urlData.newURL.hash?.startsWith("#slug=")
        ) {
            // overlay
            return;
        }

        async function refetchFilters(searchParams: URLSearchParams) {
            let newFilters = await refreshFromServer(searchParams);
            if (!newFilters) {
                // We don't know what should be displayed on the filters, so reload the page to make sure what the user interacts with is correct.
                // This is fairly reasonable behaviour for the back button so people shouldn't notice
                window.location.reload();
                return;
            }
            applyDisplayedFilters(newFilters);

            // this state update was made by the overlay being opened, so don't
            // unnecessarily update the page title
            if (!urlData?.oldState?.overlay && urlData?.state?.overlay === FILTERS_OVERLAY_NAME) {
                return;
            }

            if (newFilters.feed_url == getUrl("exploreFeed")) {
                // if the feed_url isn't /explore/, we have pre-filters that we can't remove, which
                // are also what's powering the title
                setFeedsTitle(newFilters);
            }
        }

        const currentPageUrl = new URL(window.location.href);
        const canonicalUrl = new URL(activeFilters.canonical_url, window.location.href);

        if (
            currentPageUrl.href !== canonicalUrl.href &&
            urlData.newURL.pathname !== "/account/wishlist/"
        ) {
            let searchParams = currentPageUrl.searchParams;
            searchParams.set("feed_url", currentPageUrl.pathname);
            refetchFilters(searchParams);
        }
        // This hook should only run when the page URL changes, so we don't care about the state of anything outside of that situation
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [urlData]);

    useExternalGender(activeFilters);

    return {
        applyState,
        canApply,
        cancelState,
        displayedFilters,
        isLoading,
        onClick,
        pendingFilterInfo,
        selectedFilterInfo,
        suggestedFilters,
        updateFromServer,
        lazyFacetFetch,
        lazyFacetIsLoading,
        clearAllFilters,
        clearSelectedFilters,
        applyMultiple,
    };
}

import { useCallback, useEffect, useRef, useState } from "react";
import { Rothko } from "web/script/modules/rothko";

/**
 * Provides a ref with a rothko instance for manual data fetching
 */
export function useRothkoFetcher<T>(
    path: string,
    onData?: (data: T) => void,
    cache = true
): [Rothko<T>["fetch"], boolean] {
    const rothko = useRef<Rothko<T>>();
    const [isLoading, setLoading] = useState(false);

    useEffect(() => {
        rothko.current = new Rothko(path, { cache });
        rothko.current.on("error", () => setLoading(false));
    }, [path, cache]);

    useEffect(
        () =>
            rothko.current?.on("data", (e, data) => {
                setLoading(false);
                if (onData) {
                    onData(data);
                }
            }),
        [onData]
    );

    type FetchFunc = Rothko<T>["fetch"];
    const fetch = useCallback(function fetch(
        ...args: Parameters<FetchFunc>
    ): ReturnType<FetchFunc> {
        setLoading(true);
        if (!rothko.current) {
            throw new Error("Rothko is missing!");
        }
        return rothko.current.fetch(...args);
    },
    []);

    return [fetch, isLoading];
}

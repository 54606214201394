import React, { forwardRef } from "react";
import { BaseText, BaseTextProps } from "web/react/emo/shared/components/base-text";
import { ResponsiveTextStyle } from "web/react/emo/sprinkles.css";

export interface TextProps extends BaseTextProps {
    textStyle: ResponsiveTextStyle;
    as?: "p" | "span" | "div";
}

const Text = forwardRef(({ as = "div", children, ...props }: TextProps, ref): JSX.Element => {
    return (
        <BaseText ref={ref} as={as} {...props}>
            {children}
        </BaseText>
    );
});

Text.displayName = "Text";
export { Text };

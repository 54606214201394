import React from "react";
import { Text } from "web/react/emo/text";

import clsx from "clsx";
import SVGIcon, { SVGIconName } from "web/react/components/svg-icon/svg-icon";
import { TextStyle } from "web/react/emo/sprinkles.css";
import { buttonRecipe, fullWidth, iconRecipe } from "./button.css";

export interface ButtonProps {
    className?: string;
    size?: "sm" | "lg";
    width?: "full" | "fit-content";
    height?: "full" | "fit-content";
    icon?: SVGIconName;
    variant?: "primary" | "secondary" | "tertiary";
    disabled?: boolean;
    href?: string;
    onClick?: React.MouseEventHandler<HTMLElement>;
    title: string;
    titleClassName?: string;
    type?: React.ButtonHTMLAttributes<HTMLButtonElement>["type"];
}

/**
 * Design System
 * @name Button
 * @version 1.0
 * @design https://www.figma.com/file/VhOHx4tv9uYXZHXAAKPVYf/Design-System?type=design&node-id=3481-30566&t=sJmKH2VN0OACQRby-4
 */
export function Button({
    className,
    size = "lg",
    width = "fit-content",
    height = "fit-content",
    icon,
    variant = "primary",
    disabled = false,
    href,
    onClick,
    title,
    titleClassName,
    type = "button",
}: ButtonProps): React.ReactElement {
    const textStyles: { [key: string]: TextStyle } = {
        lg: "callout-small",
        sm: "caption-1",
    };

    const showIcon = icon;
    const buttonClass = buttonRecipe({
        size,
        width,
        height,
        disabled,
        variant: showIcon && variant !== "tertiary" ? "secondary" : variant,
        icon: icon && size === "lg",
        useButtonAnimation: true,
    });
    const content = (
        <>
            {showIcon && (
                <SVGIcon
                    className={iconRecipe({
                        width: width,
                        background: icon === "lyst-app-icon",
                    })}
                    name={icon}
                    overrideStyle
                />
            )}
            <Text
                as="span"
                className={clsx(
                    titleClassName,
                    width === "full" && showIcon ? fullWidth : undefined
                )}
                textStyle={textStyles[size]}
                color={"inherit"}
            >
                {title}
            </Text>
        </>
    );

    return href ? (
        <a className={clsx(className, buttonClass)} onClick={onClick} href={href}>
            {content}
        </a>
    ) : (
        <button
            className={clsx(className, buttonClass)}
            onClick={onClick}
            disabled={disabled}
            type={type}
        >
            {content}
        </button>
    );
}

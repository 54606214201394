import React from "react";
import DragRail from "web/react/components/drag-rail/drag-rail";
import RailLinkItem from "web/react/components/rail-link-item/rail-link-item";
import { LinkWithImageSerializer } from "web/types/serializers";
import styles from "./homepage-breakout-brands.module.css";

interface TitleItemProps {
    title: string;
}

export function TitleItem({ title }: TitleItemProps): JSX.Element {
    return (
        <div className={styles.titleItem}>
            <p className={styles.title}>{title}</p>
        </div>
    );
}

export interface HomepageBreakoutBrandsProps {
    designer_title: string;
    designer_links: LinkWithImageSerializer[];
    top_dc_title: string;
    top_dc_links: LinkWithImageSerializer[];
    bottom_dc_title: string;
    bottom_dc_links: LinkWithImageSerializer[];
}

function HomepageBreakoutBrands({
    designer_title: designerTitle,
    designer_links: designerLinks,
    top_dc_title: topDesignerCategoryTitle,
    top_dc_links: topDesignerCategoryLinks,
    bottom_dc_title: bottomDesignerCategoryTitle,
    bottom_dc_links: bottomDesignerCategoryLinks,
}: HomepageBreakoutBrandsProps): JSX.Element {
    return (
        <div className={styles.homepageBreakoutBrandsWrapper}>
            <DragRail id="homepage-designer-rail" className={styles.dragRail}>
                <div className={styles.itemsContainer}>
                    <TitleItem title={designerTitle} />
                    {designerLinks.map((designerLink) => {
                        return <RailLinkItem key={designerLink.title} link={designerLink} />;
                    })}
                </div>
            </DragRail>

            <DragRail id="homepage-top-dc-rail" className={styles.dragRail}>
                <div className={styles.itemsContainer}>
                    {topDesignerCategoryLinks.slice(0, 2).map((topDesignerCategoryLink) => {
                        return (
                            <RailLinkItem
                                key={topDesignerCategoryLink.title}
                                link={topDesignerCategoryLink}
                            />
                        );
                    })}
                    <TitleItem title={topDesignerCategoryTitle} />
                    {topDesignerCategoryLinks.slice(2).map((topDesignerCategoryLink) => {
                        return (
                            <RailLinkItem
                                key={topDesignerCategoryLink.title}
                                link={topDesignerCategoryLink}
                            />
                        );
                    })}
                </div>
            </DragRail>

            <DragRail id="homepage-bottom-dc-rail" className={styles.dragRail}>
                <div className={styles.itemsContainer}>
                    {bottomDesignerCategoryLinks.slice(0, 1).map((bottomDesignerCategoryLink) => {
                        return (
                            <RailLinkItem
                                key={bottomDesignerCategoryLink.title}
                                link={bottomDesignerCategoryLink}
                            />
                        );
                    })}
                    <TitleItem title={bottomDesignerCategoryTitle} />
                    {bottomDesignerCategoryLinks.slice(1).map((bottomDesignerCategoryLink) => {
                        return (
                            <RailLinkItem
                                key={bottomDesignerCategoryLink.title}
                                link={bottomDesignerCategoryLink}
                            />
                        );
                    })}
                </div>
            </DragRail>
        </div>
    );
}

export default HomepageBreakoutBrands;

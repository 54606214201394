import React from "react";
import { RelatedProductImageCard } from "web/react/components/related-products-sidebar/related-products-sidebar";
import { HStack } from "web/react/emo/h-stack";
import { Text } from "web/react/emo/text";
import { View } from "web/react/emo/view";
import analytics from "web/script/analytics/analytics";
import { gettext } from "web/script/modules/django-i18n";
import { ProductRelatedProductsSidebarSerializer } from "web/types/serializers";
import * as styles from "./more-like-this.css";

const ID = "more-like-this-section";

interface MoreLikeThisProps {
    moreLikeThisProducts: ProductRelatedProductsSidebarSerializer | null;
}

export function MoreLikeThis({ moreLikeThisProducts }: MoreLikeThisProps): JSX.Element | null {
    if (!moreLikeThisProducts) {
        return null;
    }

    const MAX_SEE_MORE_ITEMS = 3;
    const handleThumbnailClick = (): void => {
        analytics.event("product-area", "click", "more-items-like-this");
    };

    return (
        <section id={ID} className="hidden-desktop">
            <View padding={{ sm: "xs" }} className={styles.title} background={"placeholder"}>
                <Text textStyle={"body-3-small"}>
                    {/* See more items like this */}
                    {gettext("product.buy_area.more_items_like_this.label")}
                </Text>
            </View>
            <View padding={{ sm: "xs" }} background={"placeholder"}>
                <HStack spacing={"xxs"}>
                    {moreLikeThisProducts.product_image_cards
                        .slice(0, MAX_SEE_MORE_ITEMS)
                        .map((product) => (
                            <View
                                key={`more-like-this-${product.id}`}
                                background={"placeholder"}
                                padding={"xxs"}
                                className={styles.wrapper}
                            >
                                <button onClick={handleThumbnailClick}>
                                    <RelatedProductImageCard
                                        key={product.id}
                                        product={product}
                                        isEmo
                                    />
                                </button>
                            </View>
                        ))}
                </HStack>
            </View>
        </section>
    );
}

import format from "string-format";

/**
 * This is a copy of django's "escape" method except it does not escape ampersands.
 * This means we can use it to escape text that may include a link url.
 *
 * See: i18n_utils.html_tag_props
 *
 * @return {String}
 */
function escapeButKeepAmpersands(text) {
    return (
        text
            .toString()
            // Not escaping ampersands is the only difference from the django method
            // .replace(/&/g, "&amp;")
            .replace(/</g, "&lt;")
            .replace(/>/g, "&gt;")
            .replace(/"/g, "&quot;")
            .replace(/'/g, "&#39;")
    );
}

/**
 * When an i18n string includes `{*_props}` placeholders, they must be filled using this method.
 * Property names and values will be HTML escaped, and a safe string will be returned.
 *
 * See: i18n_utils.html_tag_props
 *
 * @param {Object} props - tag property names and values
 * @return {String} safe string to be formatted into the i18n string that includes the html tag
 */
export function getHtmlProps(props) {
    let sanitisedHtmlTagPropsString = Object.keys(props).reduce(function (str, name) {
        let value = props[name];
        if (value === null || value === undefined) {
            return str + format("{name} ", { name });
        } else {
            value = escapeButKeepAmpersands(value);
            return str + format('{name}="{value}" ', { name, value });
        }
    }, "");
    return sanitisedHtmlTagPropsString.trim();
}

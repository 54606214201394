import clsx from "clsx";
import React from "react";
import { SpacingStyleProps, Sprinkles, sprinkles } from "web/react/emo/sprinkles.css";

export interface ViewProps extends SpacingStyleProps {
    background?: Sprinkles["backgroundColor"];
    children?: React.ReactNode;
    className?: string;
    border?: Sprinkles["border"];
    borderRadius?: Sprinkles["borderRadius"];
    borderColor?: Sprinkles["borderColor"];
    width?: Sprinkles["width"];
    height?: Sprinkles["height"];
}

export function View({
    marginLeft,
    marginRight,
    marginTop,
    marginBottom,
    marginX,
    marginY,
    margin,
    paddingLeft,
    paddingRight,
    paddingTop,
    paddingBottom,
    paddingX,
    paddingY,
    padding,
    children,
    className,
    background,
    border,
    borderRadius,
    borderColor,
    width,
    height,
}: ViewProps): JSX.Element {
    return (
        <div
            className={clsx(
                className,
                sprinkles({
                    backgroundColor: background,
                    marginLeft,
                    marginRight,
                    marginTop,
                    marginBottom,
                    marginX,
                    marginY,
                    margin,
                    paddingLeft,
                    paddingRight,
                    paddingTop,
                    paddingBottom,
                    paddingX,
                    paddingY,
                    padding,
                    border,
                    borderColor,
                    borderRadius,
                    width,
                    height,
                })
            )}
        >
            {children}
        </div>
    );
}

import clsx from "clsx";
import React from "react";
import SVGIcon from "web/react/components/svg-icon/svg-icon";
import { Text } from "web/react/emo/text/text";
import analytics from "web/script/analytics/analytics";
import { gettext } from "web/script/modules/django-i18n";
import { useProductCardContext } from "./product-card";
// eslint-disable-next-line css-modules/no-unused-class
import styles from "./product-card-details.module.css";
import { ProductCardLink } from "./product-card-link";

interface ProductCardDetailsProps {
    showVariant?: boolean;
    className?: string;
    feedType?: string;
    analyticsCategory?: string;
    analyticsEventLabel?: string;
    hideShortDescription?: boolean | string;
}

const defaultProps = {
    showVariant: false,
    analyticsCategory: "product_card",
};

function getPromoLabelText(promoLabel: string | null | undefined): string | undefined {
    if (promoLabel) {
        if (promoLabel === "out_of_stock") {
            return gettext("oos_product.oos.label");
        } else if (promoLabel === "low_stock") {
            return gettext("product_card.promo_label.low_stock");
        } else if (promoLabel === "sale") {
            return gettext("product_card.promo_label.sale");
        } else if (promoLabel === "trending") {
            return gettext("product_card.promo_label.trending");
        }
    }
    return undefined;
}

export function ProductCardDetails({
    showVariant,
    className,
    feedType,
    analyticsCategory = "product_card",
    analyticsEventLabel,
}: ProductCardDetailsProps = defaultProps): React.ReactElement {
    const {
        designer_name: designerName,
        short_description: shortDescription,
        full_price_with_currency_symbol: fullPriceWithCurrencySymbol,
        has_variant: hasVariant,
        in_stock: inStock,
        show_retailer_as_affiliate: showRetailerAsAffiliate,
        retailer_name: retailerName,
        context_type: contextType,
        promo_label: promoLabel,
        id: productId,
    } = useProductCardContext();

    const promoLabelText = getPromoLabelText(promoLabel);

    function handleShortDescriptionClick(): void {
        analytics.event(analyticsCategory, "short description", analyticsEventLabel, false, {
            product_id: productId,
        });
    }

    function handleRetailerClick(): void {
        analytics.event(analyticsCategory, "retailer", analyticsEventLabel, false, {
            product_id: productId,
        });
    }

    const productCardDescription = (
        <>
            <span className={styles.designer}>{designerName}</span>
            {!!shortDescription && (
                <span className={styles.shortDescription} onClick={handleShortDescriptionClick}>
                    {shortDescription}
                </span>
            )}
        </>
    );

    return (
        <div className={clsx(styles.details, className)}>
            <h3
                className={styles.detailsWrapper}
                aria-description={gettext("product_card.product_description.label")}
            >
                <ProductCardLink
                    reason="text"
                    className={styles.detailsLink}
                    feedType={feedType}
                    predicate={!!(contextType === "related-new-product")}
                >
                    {productCardDescription}
                </ProductCardLink>
            </h3>

            <div className={styles.priceSection}>
                {fullPriceWithCurrencySymbol ? (
                    <ProductCardPriceSection />
                ) : (
                    <ProductCardNotAvailable />
                )}
            </div>
            {hasVariant && inStock && showVariant && (
                <p className={styles.variants}>
                    <em>{gettext("product_card.more_colors_available")}</em>
                </p>
            )}
            <div className={styles.retailer} onClick={handleRetailerClick}>
                {showRetailerAsAffiliate && (
                    <>
                        <SVGIcon name="hyperlink" />{" "}
                    </>
                )}
                <span>{retailerName}</span>
            </div>
            {promoLabelText && (
                <Text textStyle={"caption-2"} upperCase={true} color={"secondaryBrand"}>
                    {promoLabelText}
                </Text>
            )}
        </div>
    );
}

export function ProductCardPriceSection(): React.ReactElement {
    const {
        full_price_with_currency_symbol: fullPriceWithCurrencySymbol,
        sale_price_with_currency_symbol: salePriceWithCurrencySymbol,
        in_stock: inStock,
        free_shipping: freeShipping,
        on_promotion: onPromotion,
        sale_discount: saleDiscount,
        discount_info: discountInfo,
    } = useProductCardContext();

    if (!inStock) {
        return (
            <>
                <del className={clsx(styles.price)}>{fullPriceWithCurrencySymbol}</del>

                <span className={clsx(styles.soldOutMessage)}>
                    {gettext("product_card.sold_out")}
                </span>
            </>
        );
    }
    return (
        <>
            {freeShipping && <SVGIcon className={styles.icon} name="truck" />}

            {onPromotion && <SVGIcon className={styles.icon} name="tag" />}

            {saleDiscount ? (
                <>
                    <del className={styles.price}>{fullPriceWithCurrencySymbol}</del>
                    <span className={clsx(styles.price, styles.salePrice)}>
                        {salePriceWithCurrencySymbol}
                    </span>
                </>
            ) : (
                <span className={styles.price}>{fullPriceWithCurrencySymbol}</span>
            )}

            {discountInfo && <span className={styles.discountInfo}>{discountInfo}</span>}
        </>
    );
}

export function ProductCardNotAvailable(): React.ReactElement {
    return (
        <span className={styles.soldOutMessage}>
            {gettext("product_card.not_available_in_your_country")}
        </span>
    );
}

import PropTypes from "prop-types";
import React from "react";
import SVGIcon from "web/react/components/svg-icon/svg-icon";
import style from "./burger-menu-secondary-header.module.css";

function BurgerMenuSecondaryHeader({ renderText, onClickEvent }) {
    return (
        <div onClick={onClickEvent} className={style.secondaryMenuHeader}>
            <SVGIcon className={style.menuItemBigChevron} name="arrow-icon" flip />
            {renderText}
        </div>
    );
}

export default BurgerMenuSecondaryHeader;

BurgerMenuSecondaryHeader.propTypes = {
    renderText: PropTypes.string.isRequired,
    onClickEvent: PropTypes.func.isRequired,
};

BurgerMenuSecondaryHeader.defaultProps = {
    renderText: "",
};

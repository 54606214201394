import baustein from "baustein";
import _isElement from "lodash/isElement";
import _isFunction from "lodash/isFunction";
import _isString from "lodash/isString";
import baseOverlayTemplate from "templates/modules/overlays/base_overlay.jinja";
import analytics from "web/script/analytics/analytics";
import events from "web/script/dom/events";
import { emptyElement } from "web/script/dom/manipulation";
import style from "web/script/dom/style";

const BASE_OVERLAY_SELECTOR = ".base-overlay__content";
const BASE_OVERLAY_VISIBLE_MATTE_MODIFIER = "base-overlay--visible-matte";
const BASE_OVERLAY_VISIBLE_CONTENT_MODIFIER = "base-overlay--visible-content";
/**
 * @return {Promise} A promise that will resolve once the matte is shown.
 */
function showMatte() {
    style.forceReflow(this.el);

    return events.onTransitionEnd(
        this.el,
        (el) => {
            el.classList.add(BASE_OVERLAY_VISIBLE_MATTE_MODIFIER);
        },
        250,
        "opacity"
    );
}

/**
 * @param  {content} content Can either be an html string, an html node or a component
 * @return {Promise} A promise that will resolve once the content is shown.
 */
function showContent(content) {
    // If the user closed the overlay before the content was done loading
    // we need to bail and not do anything.
    if (this.isDestroyed()) {
        return Promise.resolve();
    }

    this.setContent(content);

    return events.onTransitionEnd(
        this.el,
        (el) => {
            el.classList.add(BASE_OVERLAY_VISIBLE_CONTENT_MODIFIER);
        },
        250,
        "opacity"
    );
}

/**
 * Event handler for any clicks within the overlay. If the click target was either the root
 * component element (the matte) or the close button then `onOverlayCloseIntent` is called.
 * @param  {event} event The click event
 */
function onClick(event) {
    const isOverlayCloseIntent =
        event.target == this.el ||
        event.target == this.el.querySelector(".base-overlay__close-button");

    if (isOverlayCloseIntent) {
        this.emit("overlayCloseIntent");
        event.stopPropagation();
        this.onOverlayCloseIntent(event);
    }
    if (this.options.enableBackButton) {
        analytics.event("product_area", "click", "hash_back");
    }
}

/**
 * Event handler for keyup events. If the keyup event was for the ESC key then `onOverlayCloseIntent` is called.
 * @param event
 */
function onKeyup(event) {
    const emittedCloseIntentEvent = this.emit("overlayCloseIntent");

    // If the `overlayCloseIntent` event has been default prevented,
    // it means it's been already handled and we don't have to
    // close any other overlay.
    if (event.keyCode === 27 && !emittedCloseIntentEvent.defaultPrevented) {
        this.onOverlayCloseIntent(event);
    }
}

/**
 * Hides the overlay content and matte.
 * @return {Promise} A promise that resolves when the overlay is hidden.
 */
function hideOverlay() {
    return events
        .onTransitionEnd(
            this.el,
            (el) => {
                el.classList.remove(BASE_OVERLAY_VISIBLE_MATTE_MODIFIER);
            },
            250,
            "opacity"
        )
        .then((el) => {
            el.classList.remove(BASE_OVERLAY_VISIBLE_CONTENT_MODIFIER);
        });
}

export default {
    template: baseOverlayTemplate,

    /**
     * Setup event
     * @param  {add} add Add event helper
     */
    setupEvents(add) {
        add("click", onClick);
    },

    init() {
        ["getOverlayContent", "onOverlayCloseIntent"].forEach(
            function (methodName) {
                if (!_isFunction(this[methodName])) {
                    throw new Error(
                        "The component " +
                            this.name +
                            " has not implemented the method " +
                            methodName +
                            " which is required by the overlay-mixin."
                    );
                }
            }.bind(this)
        );
    },

    onInsert() {
        this.setGlobalHandler("keyup", onKeyup);
    },

    onRemove() {
        this.releaseGlobalHandler("keyup", onKeyup);
    },

    /**
     * Show the overlay
     * @return {Promise} A promise that will resolve once the content is
     *                   shown and the onOverlayOpen hook is called.
     */
    open() {
        const contentPromise = Promise.resolve(this.getOverlayContent());
        this.emit("overlayOpen");

        const mattePromise = showMatte.call(this);
        return Promise.all([contentPromise, mattePromise])
            .then((values) => {
                return showContent.call(this, values[0]).then(this.onOverlayOpen.bind(this));
            })
            .catch(() => {
                // Close the overlay if there was a mistake
                // TODO: Add a default error overlay?
                this.close();
            });
    },

    /**
     * Hides the overlay and then destroys it.
     * @returns {Promise} A promise that is resolved when the overlay is hidden and destroyed.
     */
    close() {
        // It's very possible that code might call this function after doing something async, in
        // which case this component may have been destroyed already.
        if (this.isDestroyed()) {
            console.warn("Trying to close an overlay that has already been destroyed.");
            return Promise.resolve();
        }

        this.emit("overlayClose");
        return hideOverlay.call(this).then(this.destroy.bind(this));
    },

    /**
     * Hook for when the overlay is opened. This method can be implemented by components that
     * use this mixin to be notified when content is visible.
     */
    onOverlayOpen() {},

    /**
     * Set the content of the overlay
     * @param {string|object|node} content Sets the content of the overlay. Can either be
     *                                     an html string, an object or a dom node.
     */
    setContent(content) {
        if (this.isDestroyed()) {
            return;
        }

        let wrapper = this.el.querySelector(BASE_OVERLAY_SELECTOR);

        // destroy all child components
        this.invoke(baustein.parse(wrapper), "destroy");

        // make sure the wrapper is empty
        wrapper = emptyElement(wrapper);

        if (baustein.isComponent(content)) {
            content.appendTo(wrapper);
        } else if (_isElement(content)) {
            wrapper.appendChild(content);
        } else if (_isString(content)) {
            wrapper.innerHTML = content;
        } else {
            throw new Error(
                "ZOMG wat are you doing. setContent was called with an invalid content type."
            );
        }
    },

    /**
     * Get render context can return some options for the
     * overlay such, as the size etc.
     */
    getInitialRenderContext() {
        return {
            overlay: {
                size: this.options.overlaySize || "small",
                component_name: this.name,
                no_content_padding: !!this.options.noContentPadding,
                extra_classnames: this.options.extraClassnames || "",
                enable_back_button: this.options.enableBackButton,
            },
        };
    },
};

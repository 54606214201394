// This module provides an easy interface
// to store and get Anonymous user behaviour,
// such as products seen and leads generated.
//
// The resulting "user profile" is an object
// structured like this:
//
// {
//     user: {
//         userId: null/id,
//         userGender: M/F,
//     },
//     clickedLeads: [
//         { ... },
//         { ... }
//     ],
//     seenProducts: [
//         { ... },
//         { ... }
//     ],
//     'searchTerms': [
//         '...',
//         '...'
//     ]
//     stockAlerts: [
//         1234, 65432
//     ],
//     designerUpdates: [
//         2542, 3234
//     ]
// }
//
//
// WARNING!
// Make sure you don't store any user sensitive information such as email address in local storage!

import _each from "lodash/each";
import _findIndex from "lodash/findIndex";
import analytics from "web/script/analytics/analytics";
import storage from "web/script/utils/storage";
import environment from "./environment";

export const STORAGE_PREFIX = "lystUser";

class UserProfiler {
    getDeviceId() {
        // We have been truncating 36 character device IDs to 35
        // characters previously in the analytics module Continuing to
        // do this here to maintain behaviour of existing storage gets
        let deviceId = analytics.getDeviceUid();
        deviceId = deviceId.substring(0, 35);
        return deviceId;
    }

    /**
     * Initialize the UserProfiler
     */
    init() {
        let userId = environment.get("userId");

        let deviceId = this.getDeviceId();

        this.userStorageKey = `${STORAGE_PREFIX}-${userId || deviceId}`;

        this.userProfile = this._getUserProfile(this.userStorageKey);

        if (userId) {
            this._mergeDeviceAndUserData();
        }

        this._saveUserProfile();
    }

    /**
     * @returns {Boolean} true if the User is logged in i.e a userId exists.
     */
    isLoggedIn() {
        return !!environment.get("userLoggedIn");
    }

    /**
     * @returns {Boolean} true if the logged in User is staff.
     */
    isStaffUser() {
        return !!environment.get("userIsStaff");
    }

    isFirstTimeLoggedIn() {
        return !!environment.get("firstTimeLogin");
    }

    _setBoolFlag(flag, value = true) {
        this.userProfile[flag] = value;
        this._saveUserProfile();
    }

    _getBoolFlag(flag) {
        // Fetching latest state of local storage value in case it was modified by another tab since
        // we loaded the page.
        const userProfile = this._getUserProfile(this.userStorageKey);

        return !!userProfile[flag];
    }

    /**
     * Saves the clicked lead record in the
     * user's client localstorage.
     *
     * @param {String} productId - The clicked lead product id
     * @param {String} reason - The clicked lead reason (e.g. 'feed-product')
     */
    saveClickedLead(productId, reason) {
        let leadLinkProps = { productId, reason };

        if (this._isDuplicateLead(leadLinkProps)) {
            return;
        }

        this.userProfile.clickedLeads.push(leadLinkProps);
        this._saveUserProfile();
    }

    /**
     * Saves the seen product record in the
     * user's client localstorage. If it's a duplicate,
     * move it to the end of the array.
     *
     * @param {Object} product - Object containing all the
     product infos
     */
    saveSeenProduct(product) {
        let productProps = this._getProductProps(product);
        let seenProducts = this.userProfile.seenProducts;

        if (this._isDuplicateProduct(productProps)) {
            let productIndex = _findIndex(seenProducts, (seenProduct) => {
                return seenProduct.productId === productProps.productId;
            });

            seenProducts.splice(productIndex, 1);
        }

        seenProducts.push(productProps);
        this._saveUserProfile();
    }

    /**
     * Saves the id of a product for which a back in stock alert has been set.
     * @param {number} productId
     */
    saveStockAlert(productId) {
        if (this.userProfile.stockAlerts.indexOf(productId) !== -1) {
            return;
        }

        this.userProfile.stockAlerts.push(productId);
        this._saveUserProfile();
    }

    saveSearchTerm(searchTerm) {
        let indexOf = this.userProfile.searchTerms.indexOf(searchTerm);

        if (indexOf >= 0) {
            this.userProfile.searchTerms.splice(indexOf, 1);
        }

        // We unshift so the that they are in saved in chronological order
        this.userProfile.searchTerms.unshift(searchTerm);
        this._saveUserProfile();
    }

    saveSubscribedSeachTerm(searchTerm) {
        if (this.userProfile.subscribedSearchTerms.indexOf(searchTerm) !== -1) {
            return;
        }

        this.userProfile.subscribedSearchTerms.push(searchTerm);
        this._saveUserProfile();
    }

    /**
     * Saves the id of a designer for which a back in stock alert has been set.
     * @param {number} designerId
     */
    saveDesignerUpdate(designerId) {
        if (this.userProfile.designerUpdates.indexOf(designerId) !== -1) {
            return;
        }

        this.userProfile.designerUpdates.push(designerId);
        this._saveUserProfile();
    }

    saveLystedProduct(productId) {
        if (this.userProfile.lystedProducts.indexOf(productId) !== -1) {
            return;
        }

        this.userProfile.lystedProducts.push(productId);
        this._saveUserProfile();
    }

    saveAppBannerDismissed() {
        const currentDate = new Date();
        const expiryDateTimestamp = currentDate.setDate(currentDate.getDate() + 7);

        this.userProfile.appBannerDismissalExpiry = expiryDateTimestamp;
        this._saveUserProfile();
    }

    hasOmnibusBeenClosed() {
        return this.userProfile.omnibusClosed;
    }

    setOmnibusIsClosed(value) {
        this.userProfile.omnibusClosed = value;
        this._saveUserProfile();
    }

    hasOmnibusFashionClipBeenClosed() {
        return this.userProfile.omnibusFashionClipClosed;
    }

    setOmnibusFashionClipIsClosed(value) {
        this.userProfile.omnibusFashionClipClosed = value;
        this._saveUserProfile();
    }

    hasAppBannerBeenDismissed() {
        const currentDate = new Date();
        const appBannerDismissalExpiry = this.userProfile?.appBannerDismissalExpiry;
        const dismissalExpired =
            !appBannerDismissalExpiry || currentDate.getTime() >= appBannerDismissalExpiry;

        if (appBannerDismissalExpiry && dismissalExpired) {
            this.saveAppBannerShown(false);
        }
        return !dismissalExpired;
    }

    saveAppBannerShown(shown = true) {
        this.userProfile.appBannerShown = shown;
        this._saveUserProfile();
    }

    hasAppBannerShown() {
        return this.userProfile.appBannerShown;
    }

    toggleProductSavedForLater(productId) {
        const index = this.userProfile.savedForLaterProducts.indexOf(productId);
        if (index !== -1) {
            this.userProfile.savedForLaterProducts.splice(index, 1);
            this._saveUserProfile();
            return false;
        } else {
            this.userProfile.savedForLaterProducts.push(productId);
            this._saveUserProfile();
            return true;
        }
    }

    isProductSavedForLater(productId) {
        const index = this.userProfile.savedForLaterProducts.indexOf(productId);
        return index !== -1;
    }

    getSavedForLaterProducts() {
        return this.userProfile.savedForLaterProducts;
    }

    clearSavedForLaterProducts() {
        this.userProfile.savedForLaterProducts = [];
        this._saveUserProfile();
    }

    /**
     * Returns true if a stock alert has been set up for `productId`, else false.
     * @param {number} productId
     * @returns {boolean}
     */
    hasStockAlertSet(productId) {
        return this.userProfile.stockAlerts.indexOf(productId) !== -1;
    }

    /**
     * Returns true if a designer update has been set up for `designerId`, else false.
     * @param {number} designerId
     * @returns {boolean}
     */
    hasDesignerUpdateSet(designerId) {
        return this.userProfile.designerUpdates.indexOf(designerId) !== -1;
    }

    hasLystedProduct(productId) {
        return this.userProfile.lystedProducts.indexOf(productId) !== -1;
    }

    hasSubscribedSearchTerm(searchTerm) {
        return this.userProfile.subscribedSearchTerms.indexOf(searchTerm) !== -1;
    }

    /**
     * Given the seen product entity,
     * extracts and returns the interesting properties.
     *
     * @param {Object} product - The seen product properties
     * @returns {Object} - the object containing the seen product useful properties
     */
    _getProductProps(product) {
        return {
            productId: product.product_id,
            productType: product.type,
            category: product.category,
            subcategory: product.subcategory,
            designerSlug: product.designer_slug,
        };
    }

    /**
     * Gets a list of the seen products
     *
     * @returns {Array} - Array containing a list of the seen products
     */
    getSeenProducts() {
        return this.userProfile.seenProducts.slice();
    }

    /**
     * Removes a product from the seenProducts array.
     *
     * @param {Number} productId - The product id of the product you want to remove.
     */
    removeSeenProduct(productId) {
        let seenProducts = this.userProfile.seenProducts;
        let productIndex = _findIndex(seenProducts, (product) => {
            return product.productId === productId;
        });

        if (productIndex === -1) {
            console.error("The specified product is not in the seenProducts array.");
            return;
        }

        this.userProfile.seenProducts.splice(productIndex, 1);

        this._saveUserProfile();
    }

    getSearchTerms() {
        return this.userProfile.searchTerms.slice().filter((x) => x);
    }

    hasSeenOOSNotification(productId) {
        return this.userProfile.OOSNotifications.indexOf(productId) !== -1;
    }

    saveSeenOOSNotification(productId) {
        if (this.hasSeenOOSNotification(productId)) {
            return;
        }

        this.userProfile.OOSNotifications.push(productId);
        this._saveUserProfile();
    }

    /**
     * Resets UserProfiler for testing purposes
     */
    reset() {
        storage.remove(this.userStorageKey);
        this.init();
    }

    /**
     * Checks if the clicked lead is a duplicate.
     *
     * @param {Object} leadLinkProps - The clicked link element properties
     * @returns {boolean}
     */
    _isDuplicateLead(leadLinkProps) {
        return this.userProfile.clickedLeads.some((savedLead) => {
            return savedLead.productId === leadLinkProps.productId;
        });
    }

    /**
     * Checks if the seen product is a duplicate.
     *
     * @param {Object} productProps - The seen product properties
     * @returns {boolean}
     */
    _isDuplicateProduct(productProps) {
        return this.userProfile.seenProducts.some((seenProduct) => {
            return seenProduct.productId === productProps.productId;
        });
    }

    /**
     * Gets the user info with the current user id and gender.
     * If there are any field that are expected to be arrays, this ensures
     * that they are by setting them to an empty array if undefined.
     *
     * @returns {Object} - an object containing user info
     */
    _getUserProfile(storageKey) {
        let userInfo = storage.get(storageKey, {});

        // Check that each field is an array
        [
            "clickedLeads",
            "seenProducts",
            "stockAlerts",
            "designerUpdates",
            "searchTerms",
            "subscribedSearchTerms",
            "lystedProducts",
            "savedForLaterProducts",
            "OOSNotifications",
        ].forEach((key) => {
            if (!userInfo[key]) {
                userInfo[key] = [];
            }
        });

        if (!userInfo["appBannerDismissalExpiry"]) {
            userInfo["appBannerDismissalExpiry"] = null;
        }

        ["appBannerShown", "omnibusClosed", "omnibusFashionClipClosed"].forEach((key) => {
            if (!userInfo[key]) {
                userInfo[key] = false;
            }
        });

        userInfo.user = {
            id: environment.get("userId"),
            gender: environment.get("userGender"),
        };

        return userInfo;
    }

    _mergeDeviceAndUserData() {
        const deviceId = this.getDeviceId();
        const deviceKey = `${STORAGE_PREFIX}-${deviceId}`;

        // don't do anything if we used a device id to generate the user storage key
        if (deviceKey === this.userStorageKey) {
            return;
        }

        const deviceData = this._getUserProfile(deviceKey);

        // merge all device data into the user data
        deviceData.clickedLeads.forEach((x) => this.saveClickedLead(x.productId, x.reason));
        deviceData.seenProducts.forEach((x) =>
            this.saveSeenProduct({
                product_id: x.productId,
                type: x.productType,
                category: x.category,
                subcategory: x.subcategory,
                designer_slug: x.designerSlug,
            })
        );
        deviceData.stockAlerts.forEach((x) => this.saveStockAlert(x));
        deviceData.designerUpdates.forEach((x) => this.saveDesignerUpdate(x));
        deviceData.searchTerms.forEach((x) => this.saveSearchTerm(x));
        deviceData.subscribedSearchTerms.forEach((x) => this.saveSubscribedSeachTerm(x));
        deviceData.lystedProducts.forEach((x) => this.saveLystedProduct(x));
        deviceData.OOSNotifications.forEach((x) => this.saveSeenOOSNotification(x));
        deviceData.savedForLaterProducts.forEach((x) => {
            if (!this.isProductSavedForLater(x)) {
                this.toggleProductSavedForLater(x);
            }
        });

        // remove the device data from local storage now
        storage.remove(deviceKey);
    }

    /**
     * Save the current user profile, you can call this after making
     * changes to save them.
     */
    _saveUserProfile() {
        storage.set(this.userStorageKey, this.userProfile);
    }
}

/* Assign getters and setters for each bool flag. Example:
 * Adding "myUserFlag" to BOOL_FLAGS will result in two class methods:
 *    - getMyUserFlag
 *    - setMyUserFlag
 */
const BOOL_FLAGS = [
    "filterTooltipShown",
    "joinOverlayShown",
    "saveForLaterCalloutShown",
    "surveyShown",
    "crmSurveyShown",
    "subscribedToNewsLetter",
    "heartNotificationShown",
    "savedSearchtNotificationShown",
    "recentlyViewedEmailCaptureShown",
    "homepageOverlayShown",
];

_each(BOOL_FLAGS, (flag) => {
    const capitalisedFlag = flag.charAt(0).toUpperCase() + flag.slice(1);
    _each(["get", "set"], (method) => {
        const functionName = `${method}${capitalisedFlag}`;
        UserProfiler.prototype[functionName] = function () {
            return UserProfiler.prototype[`_${method}BoolFlag`].call(this, flag);
        };
    });
});

const userProfiler = new UserProfiler();

// Exposing the class for testing purposes
userProfiler.UserProfiler = UserProfiler;

export default userProfiler;

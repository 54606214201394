import PropTypes from "prop-types";
import React from "react";
import { gettext } from "web/script/modules/django-i18n";

/**
 * Used for the translations integration test by calling from django management
 * command `hypernova-test`
 */
export default function Example(props) {
    return (
        <>
            {"##"}
            {gettext(props.keyname)}
            {"##"}
        </>
    );
}

Example.propTypes = {
    keyname: PropTypes.string.isRequired,
};

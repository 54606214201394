import FacebookIcon from "img/footer/facebook-black.svg?inline";
import InstagramIcon from "img/footer/instagram-black.svg?inline";
import TiktokIcon from "img/footer/tiktok-black.svg?inline";
import XIcon from "img/footer/x-black.svg?inline";
import React from "react";
import { useDispatch } from "react-redux";
import AppDownloadBadge from "web/react/components/app-download-badge/app-download-badge";
import TrackedButton from "web/react/components/tracked-button/tracked-button";
import { TrustpilotWidget } from "web/react/components/trustpilot-widget";
import { useDomViewport } from "web/react/hooks/use-dom-viewport/use-dom-viewport";
import { openCustomerCaptureOverlay } from "web/redux/ducks/customer-capture-overlay";
import { gettext } from "web/script/modules/django-i18n";
import { languageIsEnglish } from "web/script/modules/language";
import { RawBranchLink } from "web/script/utils/branch-io";
import { useFooterContext } from "./footer";
import styles from "./footer-lead-content.module.css";

function FooterLeadContent(): JSX.Element | null {
    const dispatch = useDispatch();
    const { isDesktopViewport } = useDomViewport();

    const {
        data: { app_callout_link: appCalloutLink, copyright_notice: copyrightNotice },
    } = useFooterContext();

    if (!languageIsEnglish()) {
        return null;
    }

    function scanQrCode(e): void {
        e.preventDefault();
        dispatch(
            openCustomerCaptureOverlay({
                captureType: "qr_code",
                branchLink: RawBranchLink.Footer,
            })
        );
    }

    const mobileFooterText = gettext("footer.mobile.image_alt_text");

    return (
        <div className={styles.leadContent}>
            <div className={styles.trustpilotLogo}>
                <TrustpilotWidget isFooter />
            </div>

            <div className={styles.socialBlock}>
                <TrackedButton
                    className={styles.socialIcon}
                    eventAction={"social_media_link"}
                    eventCategory={"navigation"}
                    eventLabel={"instagram"}
                    href={"https://www.instagram.com/lyst/"}
                    customAttributes={{
                        rel: "nofollow",
                        title: gettext("footer.follow_us.instagram_tooltip"),
                    }}
                >
                    <InstagramIcon />
                </TrackedButton>
                <TrackedButton
                    className={styles.socialIcon}
                    eventAction={"social_media_link"}
                    eventCategory={"navigation"}
                    eventLabel={"tiktok"}
                    href={"https://www.tiktok.com/@lystapp/"}
                    customAttributes={{
                        rel: "nofollow",
                        title: gettext("footer.follow_us.tiktok_tooltip"),
                    }}
                >
                    <TiktokIcon />
                </TrackedButton>
                <TrackedButton
                    className={styles.socialIcon}
                    eventAction={"social_media_link"}
                    eventCategory={"navigation"}
                    eventLabel={"x"}
                    href={"https://x.com/lyst/"}
                    customAttributes={{
                        rel: "nofollow",
                        title: gettext("footer.follow_us.x_tooltip"),
                    }}
                >
                    <XIcon />
                </TrackedButton>
                <TrackedButton
                    eventAction={"social_media_link"}
                    eventCategory={"navigation"}
                    eventLabel={"facebook"}
                    href={"https://www.facebook.com/Lyst/"}
                    customAttributes={{
                        rel: "nofollow",
                        title: gettext("footer.follow_us.facebook_tooltip"),
                    }}
                >
                    <FacebookIcon />
                </TrackedButton>
            </div>
            <div className={styles.appBadge}>
                <div className={styles.badgeContainer}>
                    <AppDownloadBadge
                        small
                        color={"black"}
                        callback={isDesktopViewport ? scanQrCode : undefined}
                        appDownloadLink={"footer"}
                        eventLabel={"footer_download_badge"}
                        buttonText={mobileFooterText}
                    />
                </div>
                <div className={styles.badgeContainer}>
                    <AppDownloadBadge
                        small
                        color={"black"}
                        appDownloadLink={"footer"}
                        callback={isDesktopViewport ? scanQrCode : undefined}
                        eventLabel={"footer_download_badge"}
                        badgeType="android"
                        buttonText={mobileFooterText}
                    />
                </div>
            </div>
            <p className={styles.appCallout}>
                <a href={appCalloutLink} className={styles.appCalloutLink}>
                    {gettext("footer.mobile.text")}
                </a>
            </p>
            <p className={styles.copyright}>{copyrightNotice}</p>
        </div>
    );
}

export default FooterLeadContent;

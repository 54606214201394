import clsx from "clsx";
import React, { useCallback, useEffect, useRef, useState } from "react";
import Slider from "react-slick";
import ProductCard from "web/react/components/product-card/react/product-card";
import { ProductCardDetailsCuratedAd } from "web/react/components/product-card/react/product-card-details-curated-ad";
import { ProductCardImageCuratedAd } from "web/react/components/product-card/react/product-card-image-curated-ad";
import { ProductCardLinkCuratedAd } from "web/react/components/product-card/react/product-card-link-curated-ad";
import ProductSectionHeader from "web/react/components/product-section-header/product-section-header";
import { useAboveFold } from "web/react/hooks/use-above-fold/use-above-fold";
import analytics from "web/script/analytics/analytics";
import { CuratedAdsSerializer } from "web/types/serializers";
import styles from "./curated-ads.module.css";

interface CuratedAdsProps extends CuratedAdsSerializer {
    usesFeedLayout?: boolean;
    onOverlay?: boolean;
}

function CuratedAds({
    title,
    products,
    retailer_network: retailerNetwork,
    context_type: contextType,
    collection_name: collectionName,
    collection_id: collectionId,
    collection_gender: collectionGender,
    collection_country: collectionCountry,
    usesFeedLayout = true,
}: CuratedAdsProps): JSX.Element | null {
    const sliderRef = useRef<any>(null);
    const visibleRef = useRef<HTMLDivElement | null>(null);
    const { isAboveFold, isVisible } = useAboveFold(visibleRef);
    const [isLoaded, setIsLoaded] = useState(false);

    const sendAnalyticsEvent = useCallback(
        (action) => {
            analytics.event(
                "curated_ads", // category
                action,
                "", // label
                action !== "click", // nonInteraction
                {}, // customData
                "", // subType
                {}, // checkoutData
                [
                    // itemData
                    {
                        item_type: "ad_curated",
                        retailer_network: retailerNetwork,
                        label: collectionName,
                        variant: contextType,
                        above_fold: isAboveFold,
                        collection_name: collectionName,
                        collection_id: collectionId,
                        collection_gender: collectionGender,
                        collection_country: collectionCountry,
                    },
                ]
            );
        },
        [
            isAboveFold,
            contextType,
            collectionName,
            retailerNetwork,
            collectionGender,
            collectionCountry,
            collectionId,
        ]
    );

    useEffect(() => {
        if (!isLoaded && products.length) {
            sendAnalyticsEvent("loaded");
            setIsLoaded(true);
        }
    }, [isLoaded, products.length, sendAnalyticsEvent]);

    useEffect(() => {
        if (products.length && isVisible) {
            sendAnalyticsEvent("impression");
        }
    }, [products.length, isVisible, sendAnalyticsEvent]);

    if (!products.length) {
        return null;
    }

    function beforeChange(): void {
        sendAnalyticsEvent("slide");
    }

    const slickSettings = {
        beforeChange,
        arrows: false,
        dots: true,
        draggable: false,
        infinite: false,
        slidesToShow: 5,
        slidesToScroll: 2,
        swipe: false,
        touchMove: false,
        responsive: [
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 2,
                    swipe: true,
                    touchMove: true,
                    draggable: true,
                    arrows: false,
                },
            },
            {
                breakpoint: 576,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    swipe: true,
                    touchMove: true,
                    draggable: true,
                    arrows: false,
                },
            },
        ],
    };

    return (
        <div
            className={clsx(styles.container, {
                [styles.containerFeeds]: usesFeedLayout,
            })}
        >
            {/*
              The CSS required for curated ads is the opposite way round which is
              somewhat misleading
            */}
            <ProductSectionHeader title={title} usesFeedLayout={!usesFeedLayout} />
            <div
                ref={visibleRef}
                className={clsx(styles.carouselWrapper, {
                    [styles.carouselWrapperFeeds]: usesFeedLayout,
                })}
            >
                <Slider ref={sliderRef} className={styles.products} {...slickSettings}>
                    {products?.map((product) => (
                        <div
                            key={product.uid}
                            data-testid="slide"
                            className={styles.productCardWrapper}
                        >
                            <ProductCard product={product}>
                                <ProductCardLinkCuratedAd
                                    collectionId={collectionId}
                                    className={styles.productCardLink}
                                    onClick={() => sendAnalyticsEvent("click")}
                                >
                                    <ProductCardImageCuratedAd />
                                    <ProductCardDetailsCuratedAd />
                                </ProductCardLinkCuratedAd>
                            </ProductCard>
                        </div>
                    ))}
                </Slider>
            </div>
        </div>
    );
}

export default CuratedAds;

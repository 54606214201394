import analytics from "web/script/analytics/analytics";
import storage from "web/script/utils/storage";

const PAGE_COUNTER_EXCLUDED_CATEGORIES = ["lead"];
const PAGE_COUNTER_EXCLUDED_PAGE_TYPES = ["error", "product_overlay"];

export default {
    send(data) {
        data.forEach((event) => {
            if (event.type === "page_view") {
                if (
                    !(
                        PAGE_COUNTER_EXCLUDED_CATEGORIES.includes(event.category) ||
                        PAGE_COUNTER_EXCLUDED_PAGE_TYPES.includes(event.data.page_type)
                    )
                ) {
                    const newPageCount = storage.get(
                        "valid_journey_page_count",
                        analytics.getPageViewsCount() - 1,
                        true
                    );
                    storage.set("valid_journey_page_count", newPageCount + 1, true);
                }
            }
        });
        return Promise.resolve();
    },
};

import clsx from "clsx";
import React from "react";
import SVGIcon from "web/react/components/svg-icon/svg-icon";
import { gettext } from "web/script/modules/django-i18n";
import styles from "./product-gallery-carousel-controls.module.css";

interface ProductGalleryCarouselControlsProps {
    onPrevious(): void;
    onNext(): void;
    totalCount: number;
    currentItem: number;
}

export function ProductGalleryCarouselControls({
    onPrevious,
    onNext,
    totalCount,
    currentItem,
}: ProductGalleryCarouselControlsProps): JSX.Element {
    const normalized = Math.min(Math.max(currentItem, 1), totalCount);

    const isFirstItem = normalized === 1;
    const isLastItem = normalized === totalCount;

    return (
        <div className={styles.container}>
            <button
                className={clsx(styles.button, { [styles.disabled]: isFirstItem })}
                onClick={() => onPrevious()}
                disabled={isFirstItem}
                aria-label={gettext("general.previous_btn")}
                tabIndex={0}
            >
                <SVGIcon name="chevron-thin" rotate={90} />
            </button>
            <div className={styles.text} data-testid="progress">
                {normalized}
                {`/`}
                {totalCount}
            </div>
            <button
                className={clsx(styles.button, { [styles.disabled]: isLastItem })}
                onClick={() => onNext()}
                disabled={isLastItem}
                aria-label={gettext("general.next_btn")}
                tabIndex={0}
            >
                <SVGIcon name="chevron-thin" rotate={-90} />
            </button>
        </div>
    );
}

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { FeedShowMoreSerializer } from "web/types/serializers";

export interface FeedsShowMoreSlice {
    currentPage: number | null;
    visibleProductCount: number;
    totalProductCount: number;
    showMoreNextUrl: string | null;
    retailerSlug: string | null;
    isLoading: boolean;
}

const feedsShowMore = createSlice({
    name: "feedsShowMore",
    initialState: {
        currentPage: null,
        visibleProductCount: 0,
        totalProductCount: 0,
        showMoreNextUrl: "",
        isLoading: false,
    } as FeedsShowMoreSlice,
    reducers: {
        setAll(state, { payload }: PayloadAction<FeedShowMoreSerializer>): void {
            state.currentPage = payload.current_page;
            state.showMoreNextUrl = payload.show_more_next_url;
            state.totalProductCount = payload.total_product_count;
            state.visibleProductCount = payload.visible_product_count;
            state.retailerSlug = payload.retailer_slug?.[0] || null;
            state.isLoading = false;
        },
        setLoading(state, action: PayloadAction<boolean>): void {
            state.isLoading = action.payload;
        },
    },
});

const { actions, reducer } = feedsShowMore;
export const { setAll, setLoading } = actions;
export default reducer;

import React from "react";
import ProductGallery from "web/react/components/product-gallery/product-gallery";
import {
    ProductCardSerializer,
    ProductImageGallerySerializer,
    ProductSerializer,
} from "web/types/serializers";

interface PDPGalleryProps {
    product: ProductSerializer | ProductImageGallerySerializer;
    relatedProducts?: ProductCardSerializer[];
    hasRelatedProductsSidebar: boolean;
    variant?: "oos-carousel";
}

function PDPGallery({
    product,
    relatedProducts,
    hasRelatedProductsSidebar,
    variant,
}: PDPGalleryProps): React.ReactElement {
    return (
        <ProductGallery
            relatedProducts={relatedProducts}
            images={product.images}
            currentLinkId={(product as ProductSerializer)?.link_ids?.[0] || undefined}
            hasRelatedProductsSidebar={hasRelatedProductsSidebar}
            variant={variant}
        />
    );
}

export default PDPGallery;

import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SwitchTransition } from "react-transition-group";
import { Toast, ToastTransition } from "web/react/emo/toast/toast";
import { useToast } from "web/react/emo/toast/useToast";
import { useDomViewport } from "web/react/hooks/use-dom-viewport/use-dom-viewport";
import { removeToast } from "web/redux/ducks/toasts";
import { ReduxStoreState } from "web/redux/store";
import environment from "web/script/modules/environment";
import * as styles from "./toast-provider.css";

export const ToastProvider = (): JSX.Element | null => {
    const dispatch = useDispatch();
    const toast = useSelector((state: ReduxStoreState) => state.toasts?.[0]);
    const { isDomLoaded } = useDomViewport();

    if (!isDomLoaded) {
        return null;
    }

    return (
        <div className={styles.root} aria-live="assertive">
            <SwitchTransition mode="out-in">
                <ToastTransition key={toast?.id ?? "empty"} timeout={300}>
                    {toast?.id ? (
                        <Toast
                            message={toast.message}
                            duration={toast.duration}
                            onDismiss={() => {
                                dispatch(removeToast(toast.id));
                            }}
                        />
                    ) : (
                        <div key="empty" />
                    )}
                </ToastTransition>
            </SwitchTransition>
        </div>
    );
};

export const InitializedToastProvider = (): JSX.Element => {
    const toast = useToast();

    useEffect(() => {
        const messages = environment.get("messages", []);

        messages.forEach((message) => {
            if (message.tags.includes("toast")) {
                toast({ message: message.message });
            }
        });
    });

    return <ToastProvider />;
};

import { ActionCreatorWithPayload } from "@reduxjs/toolkit";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useDomViewport } from "web/react/hooks/use-dom-viewport/use-dom-viewport";

export function useInitialReduxState(actions: [ActionCreatorWithPayload<any>, any][]): void {
    // THIS is a temporary work around until we can render components in a single react root
    const dispatch = useDispatch();
    const { isDomLoaded } = useDomViewport();

    useEffect(() => {
        actions?.map(([action, state]) => {
            dispatch(action(state));
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (!isDomLoaded) {
        actions?.map(([action, state]) => {
            dispatch(action(state));
        });
    }
}

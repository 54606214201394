import clsx from "clsx";
import React from "react";
import { useNavigationContext } from "web/react/components/navigation/navigation.context";
import SVGIcon from "web/react/components/svg-icon/svg-icon";
import analytics from "web/script/analytics/analytics";
import * as styles from "./search-button.css";

interface SearchButtonProps {
    isMobile?: boolean;
}

export function SearchButton({ isMobile }: SearchButtonProps): JSX.Element {
    const { showSearch, setShowSearch, showMenu, setShowMenu } = useNavigationContext();
    function onClick(): void {
        if (!showSearch) {
            if (showMenu) {
                setShowMenu(null);
            }
            setShowSearch(isMobile ? "mobile" : "desktop");
        } else {
            setShowSearch(null);
        }
        analytics.event("header", "clicked", "burger_menu_search_button_open");
    }

    return (
        <button id="searchButton" onClick={onClick} className={styles.iconWrapper}>
            <SVGIcon className={clsx(styles.icon, showMenu && styles.greyIcon)} name="search-emo" />
        </button>
    );
}

import clsx from "clsx";
import React from "react";
import { Divider } from "web/react/emo/archive-device-divider/divider";
import { View } from "web/react/emo/view";
import * as styles from "./archive-device-divider.css";

export interface ArchiveDeviceDividerProps {
    overlap?: boolean;
    children?: (React.ReactElement | null)[] | React.ReactElement | null;
}

/**
 * Design System
 * @name ArchiveDeviceDivider
 * @version 1.0
 * @design https://www.figma.com/file/EJXteyeEMjMgKETDQiZDli/Design-Sprint%3A-FY24-Home?type=design&node-id=3796-21417&mode=design&t=msdlxslZ4KOz53KA-0
 */
export function ArchiveDeviceDivider({
    overlap = false,
    children,
}: ArchiveDeviceDividerProps): React.ReactElement {
    const elements = (Array.isArray(children) ? children : [children]).filter((child) => !!child);

    if (elements.length === 0) {
        return <Divider />;
    }

    return (
        <View width="full">
            {elements.map((child, i) => {
                return overlap ? (
                    <div key={i}>
                        <div
                            className={clsx(styles.overlapDivider, styles.topOverlapDivider)}
                            data-testid="top-divider"
                        >
                            <Divider overlap />
                        </div>
                        <div className={styles.childWrapper}>{child}</div>
                        <div
                            className={clsx(styles.overlapDivider, styles.bottomOverlapDivider)}
                            data-testid="bottom-divider"
                        >
                            <Divider overlap />
                        </div>
                    </div>
                ) : (
                    <div key={i}>
                        <Divider />
                        <div className={styles.childWrapper}>{child}</div>
                        {i === elements.length - 1 && <Divider />}
                    </div>
                );
            })}
        </View>
    );
}

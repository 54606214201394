import "web/react/emo/theme.css.ts.vanilla.css!=!../../../../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"web/react/emo/theme.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA6WUTW/bMAyG7/sVRHzZgHSwPizL3Em2JayH7bDuPjip06b5cJqkSdth/32gMxQzPZ0G+fRQpqiXfIX7rjvCz3cAV1c/xP52JcU5RUjSNP00gAIhsdpY4YdcIiRBhdQXQ64QkjJzOrdDrhGSIs1zlQ15hpAYlynB9hvK40sV5JDnCImvvA3lkFuEpCorGVj+guovrHBhyBuEJAuZy1n9M4REV9oaMeRzhKRW0kumwy3p4L3zbshb4qVVluVZEHc60+xed6RPJmrB7nWPkMhamcDqX1Kzgig5f+ibaF3KdFiRbiY4nw/5mvQvvcpZ3zd9HhdStn/bc21SNeRdRJ9dRJ/HiD77iD6HiD7HPn9h+dw+EQ8h82bIT/3+ShjGzz13tWL3eu7nKnc8/wtCIpwyJeOvpLP1ZWC6CXJXbqypucHIYSKTpa1ZgCyWOmE0K1WQx2QurWSzK3SkKEEuqx0tFjB9G4ILPEA+C1nQ3H+CjFb7uq55VUXsjObSisBHVcxih88vj4sKrBmC3OZzWizQxg4nv1WeFguQ4SpNiwXIcUVJiwXIcnlKiwXIc9rTYoFVrFHry1M7fm03CPu7WfM+ncKf76P+wPZsYz93sQJ3sT8eI6+7IBvm0pQFs6c4RHwujpGHR5ATq1wayYU+RawlzrExfo5N0kss8BppjUwjkkgREVHKyMBIhcD3agS9e2YwQ7AjaBCEHNEcQZgRtQhynLZAUOmINghqnHeGoMd75wh6XNktghmf1iLYcYYFQjGu947uNt58j3DztDwcWrjeHtcsuHwLfum2HQs+IFyfmj3cdIsjC60GSacw+dyuT+1xOW/ga/vUTqbwBqbg9stmPYVDsz1cHdr9csGSrf86ZwqT78tNe4Cv7Rm+dZtmO5lCT6bwr383gwv8ZyFb6hcfrg5BZxzuELLRzkcEM4J7BKEuM/frN5j8aoMHCgAA\"}!../../../../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
import "web/react/pages/product/in-stock/in-stock-product-page/product-price-comparison/price-comparison-table/price-comparison-table.css.ts.vanilla.css!=!../../../../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"web/react/pages/product/in-stock/in-stock-product-page/product-price-comparison/price-comparison-table/price-comparison-table.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA6VW7W6bMBT9n6ewJk1qpBEBCQ0hf/YmkwOGuDW2ZZxBO+XdJwwmxnYS1lVqU/me+3Guz73O5pLCwxsNwZ8VADUUFaYZgBfJjqvrajMYY2U8MVEgkYGId6BhBBfgNxQvQfArEsV7HLURWh+nGMGJScnqzMLEqYkhqJQ62XQocHWenXZBiwt5zsBuG/LOKGuryipwwwn8yABlFPUelcBFkDNyqWlQQe6U8Lo2YuzmMXrfKYZENSdQogAKBJsMrID1840LnKNv7rlAEmKChMfU4E+fR3PGnGNaeUyni5SMzg19jWNXojD8PpUsWOvlnJqcE8VZ4XtmGVA0jtb9g/BodqYkqOsP3i6NxOVHkDMqEZUZaDjMUXBCskWIGlle7Sy6KQsT9Z9BSVibgeEy+8N7/PYmv71fF2O/akx18YZPOvfBFEsMyVO3gy/VZD0ZQ/VoICaH/GG44qEV2f3WkvL3u+czUovD+VyVKpJEnQykgLQpmagzQFiLRA4b5Gjv5phlJRaNDAiSEglvmBxyLCHBn2btlYLmjDBhNyhia3c5WD1MFOSMBmOU8s6sMZ2ROzttUqX8Y4uwHWUYUjvO/Va9qQB+27spG8n80+zoKg4H2s8nNkdUDmMICa5ogCWqm9vxVAdRdXBYFJhW3kJ2qhCNuKPwnVL4zxoVGIImFwhRAGkBXoz+JvtX3q1Vvo35IC1+dTRwKkJP/WQQsMCXRgXqz6+3VNGYar70h6XTO5QCxKXoP48e3OPHYZpC4Fvjs8dCKdELO846oZkZDGKrWTfyj5fPdq1R0yNrbD6np0/uwChoOxbkPKz/EXNnXtO9h0AzWvIlYBY9WRI9sb1Sm6fRPgN2WAaDNsxz1acFmHxZOjzC9MYKh903/XG0MW7BWZD3WZDpWsMB9mzuD4fYmvsFw6h+N4n+70tjeRs6PZ8Pp88j7Xk6wdrmtoPvqdaC15jWsHtJE9796Hnc06T3q9D9L0OOtvfL9OCI2SMtR8kejCNjfzpHpZ5QxdeUrNV7XV3/Agp/RCDgDAAA\"}!../../../../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var buttonArea = 'u8a9jni';
export var comparisonRow = {header:'u8a9jn3 u8a9jn1',option:'u8a9jn4 u8a9jn1'};
export var comparisonRowWrapper = 'u8a9jn2';
export var comparisonWrapper = 'u8a9jn0';
export var priceArea = 'u8a9jn5';
export var promoCta = {mobile:'u8a9jnb',tablet:'u8a9jnc',desktop:'u8a9jnd'};
export var promotionArea = 'u8a9jnj';
export var retailerArea = 'u8a9jn6';
export var retailerName = {noFrom:'u8a9jn7',withFrom:'u8a9jn8'};
export var retailerSizesButton = 'u8a9jnl';
export var seeMoreButton = 'u8a9jnk';
export var shippingInfo = 'u8a9jnf';
export var shippingInfoArea = 'u8a9jne';
export var sizePillsArea = 'u8a9jnh';
export var sticker = {tablet:'u8a9jn9',desktop:'u8a9jna'};
export var tickIcon = 'u8a9jng';
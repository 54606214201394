import { Form, Formik } from "formik";
import mapValues from "lodash/mapValues";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Divider } from "web/react/components/divider";
import { FormikCheckbox } from "web/react/components/forms/formik";
import GenericModal from "web/react/components/generic-modal/generic-modal";
import { Button } from "web/react/emo/button";
import { Heading } from "web/react/emo/heading";
import { Text } from "web/react/emo/text";
import { VStack } from "web/react/emo/v-stack";
import { useDomViewport } from "web/react/hooks/use-dom-viewport/use-dom-viewport";
import { closeCustomerCaptureOverlay } from "web/redux/ducks/customer-capture-overlay";
import analytics from "web/script/analytics/analytics";
import { gettext } from "web/script/modules/django-i18n";
import requester from "web/script/modules/requester";
import * as styles from "./opt-in-modal.css";

interface FormValues {
    marketingEmails: boolean;
}

export default function OptInModal(): JSX.Element {
    const dispatch = useDispatch();
    const { isDesktopViewport } = useDomViewport();
    const [isSubmitting, setIsSubmitting] = useState(false);

    useEffect(() => {
        analytics.event(
            "modal__marketing_preferences",
            "shown",
            "",
            false,
            {},
            "modal__marketing_preferences.shown"
        );
    }, []);

    async function onSubmit(values: FormValues): Promise<void> {
        setIsSubmitting(true);

        const marketingEmails = values.marketingEmails;

        // Push is handled differently, it should always remain true
        const marketingPreferences = {
            newsletters_email: marketingEmails,
            newsletters_push: true,
            notifications_email: marketingEmails,
            notifications_push: true,
            promotions_email: marketingEmails,
            promotions_push: true,
        };

        // URLSearchParams wants Record<string, string> so map values
        const marketingPreferencesStringified = mapValues(marketingPreferences, (value) =>
            value.toString()
        );

        const body = new URLSearchParams(marketingPreferencesStringified).toString();

        await requester.post("/account/", body);

        analytics.event(
            "modal__marketing_preferences", // category
            "submitted", // action
            "", // label
            false, // nonInteraction
            {}, // customData
            "modal__marketing_preferences.submitted", // subType
            {}, // checkoutData
            {
                marketing_preferences: marketingPreferences,
            } // itemData
        );

        dispatch(closeCustomerCaptureOverlay());
    }

    function onClose(): void {
        analytics.event(
            "modal__marketing_preferences",
            "dismissed",
            "",
            false,
            {},
            "modal__marketing_preferences.dismissed"
        );
        dispatch(closeCustomerCaptureOverlay());
    }

    return (
        <GenericModal className={styles.modal} isOpen={true} onClose={onClose}>
            <>
                <Heading as={"h1"} className={styles.heading} textStyle={"title-2"}>
                    {
                        // We send great emails
                        gettext("new_login.email_permission_modal.title")
                    }
                </Heading>
                <VStack spacing={isDesktopViewport ? "lg" : "md"}>
                    <Divider />
                    <Text as={"p"} textStyle={"body-3-small"}>
                        {
                            // Get insider intel on what's new, what's hot and what's about to sell out. We'll keep track of any changes in price or stock levels so you never miss out on your favorite items.
                            gettext("new_login.email_permission_modal.body")
                        }
                    </Text>
                    <Formik initialValues={{ marketingEmails: false }} onSubmit={onSubmit}>
                        <Form noValidate>
                            <FormikCheckbox
                                name="marketingEmails"
                                label={
                                    // I would like to hear about products, services, and sales, including personalized email alerts from Lyst. You can unsubscribe at any time.
                                    gettext(
                                        "account.register.legal_agreement.sign_up_checkbox_label"
                                    )
                                }
                                labelClassName={styles.marketingEmailCheckboxLabel}
                            />
                            <Button
                                className={styles.continueButton}
                                variant={"primary"}
                                disabled={isSubmitting}
                                type="submit"
                                width="full"
                                title={
                                    // Sign me up
                                    gettext("new_login.email_permission_modal.cta")
                                }
                            />
                        </Form>
                    </Formik>
                </VStack>
            </>
        </GenericModal>
    );
}

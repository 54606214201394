import PropTypes from "prop-types";
import React from "react";
import { useClickTracker } from "web/react/hooks/use-click-tracker/use-click-tracker";

function TrackedButton({
    children,
    className,
    eventAction,
    eventCategory,
    eventLabel,
    eventCustomData,
    href,
    openInNewTab,
    customAttributes,
    onClick: customOnClickHandler,
}) {
    let trackedOnClickHandler = useClickTracker(
        eventCategory,
        eventAction,
        eventLabel,
        !openInNewTab,
        eventCustomData
    );

    function onClick(event) {
        if (customOnClickHandler) {
            customOnClickHandler(event);
        }
        return trackedOnClickHandler(event);
    }

    return (
        // This could be considered a security issue. We need to review as we do use window.opener in some cases
        // eslint-disable-next-line react/jsx-no-target-blank
        <a
            className={className}
            href={href}
            onClick={onClick}
            target={openInNewTab ? "_blank" : null}
            {...customAttributes}
        >
            {children}
        </a>
    );
}

export default TrackedButton;

TrackedButton.propTypes = {
    className: PropTypes.string,
    href: PropTypes.string.isRequired,
    openInNewTab: PropTypes.bool,
    eventCategory: PropTypes.string.isRequired,
    eventAction: PropTypes.string.isRequired,
    eventLabel: PropTypes.string.isRequired,
    eventCustomData: PropTypes.object,
    customAttributes: PropTypes.object,
    children: PropTypes.node.isRequired,
    productId: PropTypes.number,
    reason: PropTypes.string,
    onClick: PropTypes.func,
};

TrackedButton.defaultProps = {
    openInNewTab: false,
    eventCustomData: {},
    customAttributes: {},
};

import clsx from "clsx";
import PropTypes from "prop-types";
import React, { lazy, Suspense, useEffect, useState } from "react";
import { Button as LegacyButton } from "web/react/components/__LEGACY__/LEGACY_button/button";
import LoadingSpinner from "web/react/components/loading-spinner/loading-spinner";
import SVGIcon from "web/react/components/svg-icon/svg-icon";
import { Button } from "web/react/emo/button";
import { usePrevious } from "web/react/hooks/use-previous/use-previous";
import { gettext } from "web/script/modules/django-i18n";
import styles from "./save-for-later.module.css";

const basePropTypes = {
    className: PropTypes.string,
    isSaved: PropTypes.bool.isRequired,
    isSaving: PropTypes.bool,
    onClick: PropTypes.func,
    cta: PropTypes.string,
    showExpressCheckout: PropTypes.bool,
    bingTraffic: PropTypes.bool,
    variant: PropTypes.oneOf(["emotional"]),
};

const LEGACYHeartAnimation = lazy(() =>
    import("web/react/components/lottie-animations/LEGACY-heart-animation/LEGACY-heart-animation")
);

const HeartAnimation = lazy(() =>
    import("web/react/components/lottie-animations/heart-animation/heart-animation")
);

export function SaveForLaterIcon({ onClick, variant, isSaved, className, isSaving }) {
    const [isAnimating, setIsAnimating] = useState(false);
    const showNewIconDesign = variant === "emotional";
    const previousIsSaved = usePrevious(isSaving);

    function loadAnimation() {
        // Dynamically import heart animation when user hovers...
        if (showNewIconDesign) {
            import("web/react/components/lottie-animations/heart-animation/heart-animation");
        } else {
            import(
                "web/react/components/lottie-animations/LEGACY-heart-animation/LEGACY-heart-animation"
            );
        }
    }

    useEffect(() => {
        if (!previousIsSaved && isSaving) {
            setIsAnimating(true);
        }
    }, [isAnimating, previousIsSaved, isSaving]);

    return (
        <button
            onClick={() => {
                onClick?.();
                if (isAnimating) {
                    setIsAnimating(false);
                }
            }}
            className={clsx(styles.icon, !showNewIconDesign && styles.oldIcon, className, {
                [styles.iconSaved]: isSaved,
            })}
            onMouseEnter={loadAnimation}
        >
            {showNewIconDesign ? (
                <span className={styles.heartWithAnimation}>
                    {isAnimating ? (
                        <Suspense fallback={<LoadingSpinner />}>
                            <div className={clsx(styles.animatedHeart, styles.animatedEmoHeart)}>
                                <HeartAnimation
                                    onComplete={() => {
                                        setIsAnimating(false);
                                    }}
                                />
                            </div>
                        </Suspense>
                    ) : (
                        <SVGIcon
                            name={isSaved ? "heart-pressed" : "heart-unpressed"}
                            className={styles.svgEmoIcon}
                        />
                    )}
                </span>
            ) : (
                <>
                    <SVGIcon
                        name={isSaved ? "LEGACY-heart-pressed" : "LEGACY-heart-unpressed"}
                        className={styles.svgIcon}
                    />
                    {isAnimating && (
                        <Suspense fallback={<LoadingSpinner />}>
                            <div className={styles.animatedHeart}>
                                <LEGACYHeartAnimation
                                    onComplete={() => {
                                        setIsAnimating(false);
                                    }}
                                    onAnimationStart={() => {
                                        setIsAnimating(true);
                                    }}
                                />
                            </div>
                        </Suspense>
                    )}
                </>
            )}
        </button>
    );
}

SaveForLaterIcon.propTypes = basePropTypes;

export function LEGACYSaveForLaterSmall({ onClick, isSaved, className }) {
    return (
        <button
            onClick={onClick}
            className={clsx(styles.small, className, {
                [styles.smallSaved]: isSaved,
            })}
        >
            <SVGIcon name="heart" className={styles.svgIcon} />
        </button>
    );
}

LEGACYSaveForLaterSmall.propTypes = basePropTypes;

function getCTA(isSaved) {
    if (isSaved) {
        return gettext("in_stock_product.saved");
    } else {
        return gettext("save_for_later.cta.variant_b");
    }
}

export function LEGACYSaveForLaterNormal({
    onClick,
    isSaved,
    isSaving,
    className,
    cta,
    showExpressCheckout,
    bingTraffic,
}) {
    let buttonStyle = "secondary";
    if (showExpressCheckout && bingTraffic === false) {
        buttonStyle = "cta";
    }

    return (
        <LegacyButton
            styleType={isSaved ? "temp-grey" : buttonStyle}
            onClick={onClick}
            className={className}
            fullWidth
            disabled={isSaving}
        >
            <SVGIcon className={styles.svg} name={isSaved ? "heart" : "heart-empty"} />{" "}
            <span>{cta ? cta : getCTA(isSaved)}</span>
        </LegacyButton>
    );
}
LEGACYSaveForLaterNormal.propTypes = basePropTypes;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function SaveForLaterCTA({ onClick, cta, isSaved }) {
    // TODO CW-622: add "isActive" to button props, relies on `isSaved`
    return (
        <Button
            title={cta}
            size="sm"
            variant="secondary"
            onClick={onClick}
            // isActive={isSaved}
        />
    );
}

export function LEGACYSaveForLaterStockAlert({ onClick, isSaved, isSaving, className, cta }) {
    let ctaText;
    if (isSaved) {
        ctaText = getCTA(isSaved);
    } else {
        ctaText = cta ? cta : getCTA(isSaved);
    }
    return (
        <LegacyButton
            styleType={isSaved ? "temp-grey" : "cta"}
            onClick={onClick}
            className={className}
            disabled={isSaving}
        >
            {isSaved ? (
                <SVGIcon className={styles.svgSaved} name={"bell"} />
            ) : (
                <SVGIcon className={styles.svg} name={"bell-empty"} />
            )}
            <span>{ctaText}</span>
        </LegacyButton>
    );
}

LEGACYSaveForLaterStockAlert.propTypes = basePropTypes;

export function LEGACYSaveForLaterTick({ onClick, isSaved, isSaving, className, cta }) {
    let ctaText;
    if (isSaved) {
        ctaText = gettext("oos_product.signup_bis_alert_set.button");
    } else {
        ctaText = cta ? cta : getCTA(isSaved);
    }
    return (
        <LegacyButton
            styleType={isSaved ? "temp-gold" : "cta"}
            onClick={onClick}
            className={className}
            disabled={isSaving}
        >
            {isSaved && <SVGIcon className={styles.svgSavedTick} name="tick" />}
            <span>{ctaText}</span>
        </LegacyButton>
    );
}

LEGACYSaveForLaterTick.propTypes = basePropTypes;

// As a part of the redesign work, we should make an effort to
// only use the "cta" or "icon" variants of the S4L button. Other
// variants have been marked as "LEGACY" with the aim of removing once
// the redesign is complete. If the "cta" variant needs expanding on,
// please add to that component and only create new variants if absolutely
// necessary.
const BUTTON_TYPES = {
    cta: SaveForLaterCTA,
    icon: SaveForLaterIcon,
    LEGACY_normal: LEGACYSaveForLaterNormal,
    LEGACY_small: LEGACYSaveForLaterSmall,
    LEGACY_stockAlert: LEGACYSaveForLaterStockAlert,
    LEGACY_tick: LEGACYSaveForLaterTick,
};

function SaveForLater({ type, ...props }) {
    const Component = BUTTON_TYPES[type];
    if (!Component) {
        throw new Error(`Invalid button type ${type}!`);
    }

    return <Component {...props} />;
}

export default SaveForLater;

SaveForLater.propTypes = {
    ...basePropTypes,
    type: PropTypes.oneOf([
        "cta",
        "icon",
        "LEGACY_normal",
        "LEGACY_small",
        "LEGACY_stockAlert",
        "LEGACY_tick",
    ]).isRequired,
};

SaveForLater.defaultProps = {
    isSaving: false,
};

import _isFunction from "lodash/isFunction";
import {
    CookieConsent,
    CookieConsentLevel,
    setCookieConsent,
} from "web/redux/ducks/cookie-consent";
import store from "web/redux/store";
import analytics from "web/script/analytics/analytics";
import globals from "web/script/modules/globals";
import cookie from "web/script/utils/cookie";

const ONE_TRUST_COOKIES = {
    advertisement: "C0004", // Targeting Cookies
    analytics: "C0002", // Performance Cookies
    customization: "C0003", // Functional Cookies
    personalizedAds: "V2STACK42", // Personalized Ads Cookies
};

const COOKIE_BANNER_SELECTOR = '[aria-describedby="onetrust-policy-text"]';

export function getConsentLevel(accepts: boolean): CookieConsentLevel {
    return accepts ? CookieConsentLevel.Accepts : CookieConsentLevel.Rejects;
}

export interface SelectedState {
    adCookie: boolean;
    analyticalCookie: boolean;
    customizationCookie: boolean;
    personalizedAdsCookie: boolean;
}

function _sendAnalyticsEvents(updatedState: SelectedState, source: string): void {
    if (
        updatedState.customizationCookie &&
        updatedState.adCookie &&
        updatedState.analyticalCookie
    ) {
        // The same event as cookie consent even if it is done via cookie settings -
        // so we know what % of users accept all cookies
        analytics.event("Cookie consent", "Clicked", "Hide");
    } else if (
        !updatedState.customizationCookie &&
        !updatedState.adCookie &&
        !updatedState.analyticalCookie
    ) {
        // Send analytics when only required cookies are accepted + the source
        analytics.event(source, "clicked", "accept_required");
    } else {
        // this event is sent when we are not in the reject all/accept all cookies conditions
        analytics.event(source, "clicked", "save_changes", false, {
            advertising: getConsentLevel(updatedState.adCookie) === CookieConsentLevel.Accepts,
            analytics:
                getConsentLevel(updatedState.analyticalCookie) === CookieConsentLevel.Accepts,
            siteCustomization:
                getConsentLevel(updatedState.customizationCookie) === CookieConsentLevel.Accepts,
        });
    }
}

function getGeoLocationCountry(): string | undefined {
    if (_isFunction(globals.window.OneTrust?.getGeolocationData)) {
        return globals.window.OneTrust?.getGeolocationData().country.toUpperCase();
    }
}

/**
 * Handles logic when OnConsentChanged is triggered, which includes the following:
 *
 * - Creating the different types of lyst cookies depending on the one trust cookie value
 * (customization, advertisement and analytics) using the cookie-consent logic
 * - Send analytic events with the accepted/rejected cookies and the source
 * (from the cookie banner or the preference center).
 */
function _oneTrustCookieConsentListener(
    event: CustomEvent<string[]>,
    country: string | undefined
): void {
    // In the US customization cookie will always be accepted in OneTrust
    // To keep the same logic as we had before, we need to check the country
    // and set the cookieConsent based on the advertisement cookie
    const customizationCookie =
        country === "US"
            ? event.detail.includes(ONE_TRUST_COOKIES.advertisement)
            : event.detail.includes(ONE_TRUST_COOKIES.customization);

    const updatedState: SelectedState = {
        adCookie: event.detail.includes(ONE_TRUST_COOKIES.advertisement),
        analyticalCookie: event.detail.includes(ONE_TRUST_COOKIES.analytics),
        customizationCookie: customizationCookie,
        personalizedAdsCookie: event.detail.includes(ONE_TRUST_COOKIES.personalizedAds),
    };
    const cookieConsent: CookieConsent = {
        adCookie: getConsentLevel(updatedState.adCookie),
        analyticsCookie: getConsentLevel(updatedState.analyticalCookie),
        customizationCookie: getConsentLevel(updatedState.customizationCookie),
        personalizedAdsCookie: getConsentLevel(updatedState.personalizedAdsCookie),
    };
    store.dispatch(setCookieConsent(cookieConsent));

    // This logic is here to detect from where the user is interacting with cookies on OneTrust.
    // Source can be `cookie_settings` or `Cookie consent`.
    let source = "cookie_settings";
    const cookieBanner = globals.document.querySelector(COOKIE_BANNER_SELECTOR);
    if (cookieBanner) {
        // NOTES: the logic here is applied ONLY when the user has interacted with the cookie banner for the first time.
        // Once the oneTrust cookie is set, this should always be skipped.
        source = "Cookie consent";

        // The initial cookie banner has to be removed if we want the source 'cookie_settings' after
        // the user has already interacted with the cookie banner,
        // otherwise the cookieBanner querySelector still pick the banner as it is just hidden in the html code.
        // The cookie banner element only truly disappears after reloading the page once the user has clicked on it.
        cookieBanner.remove();

        // If OneTrust country = US and `OptanonAlertBoxClosed` cookie is present, it means CCPA banner just got closed.
        if (country === "US" && cookie("OptanonAlertBoxClosed")) {
            analytics.event("cookie_banner", "dismissed", "ccpa_banner");

            return;
        }
    }

    if (country !== "US") {
        _sendAnalyticsEvents(updatedState, source);
    } else {
        // eslint-disable-next-line no-lonely-if
        if (event.detail.includes(ONE_TRUST_COOKIES.analytics)) {
            analytics.event("ccpa_toggle_share", "clicked", "accepts_cookies");
        } else {
            analytics.event("ccpa_toggle_share", "clicked", "rejected_cookies");
        }
    }
}

/**
 *  Sends an analytic everytime we display the one trust preference center.
 *
 * The preference center is accessible by either the banner or the footer.
 * We try to get the cookie banner element from the DOM: if it is there, it means the preference center has been
 * opened from the banner, if not, from the footer.
 */
function _sendOneTrustPreferenceCenterAnalytics(country: string | undefined): void {
    const cookieBanner = globals.document.querySelector(COOKIE_BANNER_SELECTOR);
    // Set to "banner" if the cookie settings modal is accessed via the banner
    const eventAction = country === "US" ? "shown_ccpa" : "shown_tcf";
    const eventLabel = cookieBanner !== null ? "banner" : "footer";
    analytics.event("cookie_settings", eventAction, eventLabel);
}

let isOneTrustInitialised = false;

export default {
    init: function () {
        // We apply our own logic on top of OneTrust after listening to the `OneTrustIsLoaded` event, which is
        // triggered from the base_page.jinja file only when the OneTrust script is fully loaded.
        // This allows us to apply our logic on top of OneTrust such as sending our own analytics
        // when the cookies have been updated using the OneTrust interface for example.
        globals.window.addEventListener("OneTrustIsLoaded", function () {
            const country = getGeoLocationCountry();

            // We need to set the OneTrust listeners just once, `isOneTrustInitialised` is used for this purpose.
            if (!isOneTrustInitialised) {
                // Whenever a change is made on the cookie settings via OneTrust, we need to update the lyst cookies.
                globals.window.OneTrust?.OnConsentChanged((event: CustomEvent<string[]>) => {
                    _oneTrustCookieConsentListener(event, country);
                });
                // Whenever we display the cookie preference center modal, we send an event.
                globals.window.addEventListener("OneTrustPCLoaded", () => {
                    _sendOneTrustPreferenceCenterAnalytics(country);
                });

                isOneTrustInitialised = true;
            }

            // if the `OptanonAlertBoxClosed` cookie is not here, we assume that the banner is shown for the first time.
            if (!cookie("OptanonAlertBoxClosed")) {
                const eventAction = country === "US" ? "Shown_ccpa" : "Shown_tcf";
                analytics.event("Cookie consent", eventAction, undefined, true);
            }
        });
    },

    getGeoLocationCountry: getGeoLocationCountry,
};

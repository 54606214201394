import React from "react";
import LazyImage from "web/react/components/lazy-load-image/lazy-load-image";
import { Text } from "web/react/emo/text";
import { VStack } from "web/react/emo/v-stack";
import { View } from "web/react/emo/view";
import { useDomViewport } from "web/react/hooks/use-dom-viewport/use-dom-viewport";
import { gettext } from "web/script/modules/django-i18n";
import * as styles from "./display-banner.css";

export interface DisplayBannerProps {
    desktopImage: string;
    mobileImage: string;
    url: string;
    onClick?: () => void;
    alt?: string;
    lazy?: boolean;
    variant?: "sm" | "lg";
}

export function DisplayBanner({
    desktopImage,
    mobileImage,
    url,
    onClick,
    alt = "",
    lazy = true,
    variant = "lg",
}: DisplayBannerProps): React.ReactElement {
    const { isMobileViewport } = useDomViewport();
    const lazyImgSrc = isMobileViewport ? mobileImage : desktopImage;

    return (
        <VStack spacing="none">
            <View background="placeholder">
                <a
                    data-testid="display-banner"
                    href={url}
                    target="_blank"
                    rel="noreferrer"
                    className={styles.link}
                    onClick={onClick}
                >
                    {lazy ? (
                        <LazyImage
                            width="100%"
                            alt={alt}
                            src={lazyImgSrc as string}
                            placeholder={`data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0"3E%3C/svg%3E`}
                        />
                    ) : (
                        <picture>
                            <source
                                data-testid="mobile-image-source"
                                media={styles.mobileMedia}
                                srcSet={mobileImage}
                            />
                            <source
                                data-testid="desktop-image-source"
                                media={styles.desktopMedia}
                                srcSet={desktopImage}
                            />
                            <img data-test-id="image" src={mobileImage} alt={alt} />
                        </picture>
                    )}
                </a>
            </View>
            <View
                background="neutral1"
                className={variant === "lg" ? styles.sponsoredLg : styles.sponsoredSm}
            >
                <Text upperCase color="secondary" textStyle="caption-3-small">
                    {gettext("general.sponsored_ad.caption")}
                </Text>
            </View>
        </VStack>
    );
}

import React, { RefObject, useEffect } from "react";
import * as styles from "./flyout.css";

interface FlyoutProps {
    children: React.ReactNode;
    parentRef: RefObject<HTMLElement>;
    isOpen: boolean;
    handleClose: () => void;
}

export function Flyout({
    children,
    parentRef,
    isOpen,
    handleClose,
}: FlyoutProps): React.ReactElement {
    useEffect(() => {
        function handleClickOutside(event: MouseEvent): void {
            if (!parentRef?.current?.contains(event.target as Node) && isOpen) {
                handleClose();
            }
        }

        document.addEventListener("mouseup", handleClickOutside);

        return (): void => {
            document.removeEventListener("mouseup", handleClickOutside);
        };
    }, [isOpen, handleClose, parentRef]);

    return (
        <div className={styles.wrapper}>
            <span className={styles.triangle} />
            <div className={styles.content}>{children}</div>
        </div>
    );
}

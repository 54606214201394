/**
 Lyst Alerts - Displays on the fly alerts to the user in their browser window
 @author Devin Hunt
 @uri http://www.ly.st

 @namespace Lyst
 @class alerts
 **/
import factory from "web/script/dom/factory";
import style from "web/script/dom/style";
import globals from "web/script/modules/globals";
import url from "./url";

function Alerts() {
    /** The element used to display alerts */
    var el;

    /** The standard time for the alert to show */
    var duration = 5000;

    /** Flag for current alert state */
    var isAlerting = false;

    /** Timer ID of the current display instance */
    var timerId;

    /** if currently showing a message, queue up other ones **/
    var messageQueue = [];
    this.init = function () {
        el = factory.fromString(`
            <div class="alert-bar">
                <div class="alert-inner">
                    <p><span class="message"></span><a class="alert-close" href="#"></a></p>
                </div>
            </div>`);

        /**
         * Hide the alert of click :tobi:
         */
        el.querySelector(".alert-close").addEventListener(
            "click",
            () => {
                if (timerId) {
                    clearTimeout(timerId);
                    timerId = undefined;
                    this._hideEl();
                }
            },
            false
        );
    };

    this._hideEl = () => {
        style.setStyles(el, { left: "auto", right: "0px", height: "0px" });

        if (el.parentElement) {
            el.parentElement.removeChild(el);
        }

        isAlerting = false;

        var nextItem = messageQueue.shift();

        if (nextItem) {
            this.alert(nextItem.msg, nextItem.ops);
        }
    };

    /**
     Displays an alert message. If currently displaying a msg, will queue up next message to show when box hidden

     @method alert

     @param msg The message you want to display. Html will be escaped
     @param [ops] optional containing these options:
     error : true if error message - will format alert with error colors
     **/
    this.alert = (msg, ops) => {
        if (isAlerting) {
            var queueItem = { msg: msg, ops: ops };
            messageQueue.push(queueItem);
            return;
        }

        isAlerting = true;
        document.body.insertBefore(el, document.body.firstElementChild);
        el.querySelector(".message").innerText = msg;

        style.setStyles(el, { left: "0px", right: "auto" });

        timerId = setTimeout(this._hideEl, duration);
    };

    this.loginRequiredThenRedirect = () => {
        this.alert("You need to be logged in to do that!");

        setTimeout(function () {
            var current = url.parse(globals.window.location.href);
            var next = url.unparse({
                path: current.path,
                searchParams: current.searchParams,
            });

            globals.window.location = url.unparse({
                path: "/account/login/",
                searchParams: {
                    next: next,
                },
            });
        }, 2000);
    };
}

export default new Alerts();

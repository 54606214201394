import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { FeedCountSerializer } from "web/types/serializers";

const feedCount = createSlice({
    name: "feedCount",
    initialState: {
        product_count: undefined,
        retailer_count: undefined,
    } as Partial<FeedCountSerializer>,
    reducers: {
        setFeedCount(state, action: PayloadAction<FeedCountSerializer>): void {
            state.product_count = action.payload.product_count;
            state.retailer_count = action.payload.retailer_count;
        },
    },
});

export const { setFeedCount } = feedCount.actions;
export default feedCount.reducer;

import loadable from "@loadable/component";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
    CampaignDetails,
    useKevelCampaignControl,
} from "web/react/hooks/use-kevel-campaign-control/use-kevel-campaign-control";
import { useTopBannerClass } from "web/react/hooks/use-top-banner-class/use-top-banner-class";
import withReduxProvider from "web/react/redux-provider";
import {
    bannerHidden,
    bannerVisible,
    setBannerSize,
    setBannerType,
} from "web/redux/ducks/top-banner";
import store from "web/redux/store";
import analytics from "web/script/analytics/analytics";
import environment from "web/script/modules/environment";
import userProfiler from "web/script/modules/userprofiler";
import {
    BannerSize,
    BranchBannerCampaign,
    getBannerCampaign,
} from "web/script/utils/app-banner-campaign";
import { CampaignRawBranchLink } from "web/script/utils/branch-io";
import browser from "web/script/utils/browser";

const SmallAppDownloadBanner = loadable(
    () =>
        import(
            /* webpackChunkName: "small-app-download-banner" */ "web/react/components/top-banner/small-app-download-banner"
        )
);

const BRANCH_LINKS = {
    feed: CampaignRawBranchLink.Feed,
    homepage: CampaignRawBranchLink.Homepage,
    is_pdp: CampaignRawBranchLink.ISPDP,
    oos_pdp: CampaignRawBranchLink.OOSPDP,
    lead_return: CampaignRawBranchLink.LeadReturn,
};

function getBranchBannerCampaign(): BranchBannerCampaign | undefined {
    const branchBannerCampaign = getBannerCampaign(
        analytics.getInitialPageType(),
        analytics.getInitialSubPageType()
    ) as BranchBannerCampaign;

    if (!branchBannerCampaign) {
        return;
    } else {
        branchBannerCampaign.branchLink = BRANCH_LINKS[branchBannerCampaign.flag];
        return branchBannerCampaign;
    }
}

// whilst we're migrating to using Kevel use this function as a fallback to retrieve campaign data the old way
function getCampaignDetails(): CampaignDetails {
    let campaignDetails;
    const bannerCampaignData = getBranchBannerCampaign();

    if (
        environment.get("isPlaCampaign") &&
        environment.get("pageType") === "product" &&
        environment.get("pageSubType") === "in_stock"
    ) {
        campaignDetails = {};
    } else if (
        browser.isSupportedMobileDevice &&
        bannerCampaignData &&
        !userProfiler.hasAppBannerBeenDismissed() &&
        environment.get("domainDefaultLanguageIsEnglish")
    ) {
        campaignDetails = {
            branchLink: bannerCampaignData.branchLink,
            blockType: "",
            componentType: "app_banner",
            name: bannerCampaignData.name,
        };
    }

    return campaignDetails;
}

interface TopBannerReduxStateProps {
    fixTopBanner: boolean;
}

function mapStateToProps(state): TopBannerReduxStateProps {
    const { show: fixTopBanner } = state.followDesignerBannerReducer;

    return { fixTopBanner };
}

export function TopBanner({ fixTopBanner }: TopBannerReduxStateProps): null | JSX.Element {
    const [isHidden, setIsHidden] = useState<boolean>(false);
    let { onLoad, onClick } = useKevelCampaignControl(
        "top_banner",
        {
            hasBeenDismissed: userProfiler.hasAppBannerBeenDismissed(),
        },
        false
    );

    let campaignDetails = getCampaignDetails();

    const analyticsEventCategory = campaignDetails?.componentType || "";

    useTopBannerClass(() => document.body, "has-top-banner");

    function showBanner(): void {
        store.dispatch(bannerVisible());

        if (campaignDetails?.componentType === "email_banner") {
            store.dispatch(setBannerType(campaignDetails.componentType));
        } else if (campaignDetails?.componentType === "app_banner") {
            store.dispatch(setBannerSize(BannerSize.Small));
        }
    }

    useEffect(() => {
        if (!isHidden && campaignDetails) {
            showBanner();

            if (!userProfiler.hasAppBannerShown()) {
                userProfiler.saveAppBannerShown();
                analytics.event(campaignDetails.componentType, "shown", campaignDetails.name, true);

                // trigger Kevel event once the banner is visible
                onLoad();
            }
        }
    }, [campaignDetails, onLoad, isHidden]);

    function onClose(): void {
        analytics.event(analyticsEventCategory, "dismissed", campaignDetails?.name || "");

        setIsHidden(true);
        store.dispatch(bannerHidden());

        userProfiler.saveAppBannerDismissed();
    }

    if (!isHidden && campaignDetails?.componentType === "app_banner") {
        return (
            <SmallAppDownloadBanner
                campaignDetails={campaignDetails}
                onCtaClick={onClick}
                onClose={onClose}
                fixed={fixTopBanner}
            />
        );
    } else {
        return null;
    }
}

export default withReduxProvider(connect(mapStateToProps)(TopBanner));

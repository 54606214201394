import React, { useEffect, useRef, useState } from "react";

function checkIfInViewport(boundingRect: DOMRect): boolean {
    const { top, left } = boundingRect;

    return (
        top < (window.innerHeight || document.documentElement.clientHeight) &&
        left < (window.innerWidth || document.documentElement.clientWidth)
    );
}

type UseIntersectionObserver = [
    boolean,
    React.Dispatch<
        React.SetStateAction<React.MutableRefObject<HTMLElement | undefined | null> | null>
    >,
    boolean
];

interface Params {
    root?: HTMLElement | null;
    rootMargin?: string;
    threshold?: number;
    once?: boolean;
}

export function useIntersectionObserver(
    { root = null, rootMargin, threshold = 0, once = false }: Params = {},
    targetRef: React.MutableRefObject<HTMLElement | null | undefined> | null = null
): UseIntersectionObserver {
    const [isVisible, setIsVisible] = useState(false);
    const [isReady, setIsReady] = useState(false);
    const [intersectionRef, setIntersectionRef] = useState(targetRef);
    let observer = useRef<IntersectionObserver>();

    useEffect(() => {
        const targetNode = intersectionRef?.current;
        if (targetNode) {
            observer.current = new window.IntersectionObserver(
                ([entry], thisObserver) => {
                    let visible = entry.isIntersecting;

                    if (!visible && entry.boundingClientRect.top === 0) {
                        // edge case when IntersectionObserver does not see on load an element in viewport
                        // that has been mounted using renderReactComponentInDocument()
                        visible = checkIfInViewport(targetNode?.getBoundingClientRect());
                    }

                    setIsVisible(visible);
                    setIsReady(true);

                    if (visible && once) {
                        thisObserver.disconnect();
                    }
                },
                { root, rootMargin, threshold }
            );
            observer.current?.observe?.(targetNode);
        }

        return () => {
            observer.current?.disconnect?.();
        };
    }, [intersectionRef, once, root, rootMargin, threshold]);

    return [isVisible, setIntersectionRef, isReady];
}

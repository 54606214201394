/* eslint-disable css-modules/no-undef-class */
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import clsx from "clsx";
import React, { useEffect, useRef, useState } from "react";
import Slider, { Settings as SlickSettings } from "react-slick";
import { MoreProducts } from "web/react/components/product-gallery/product-gallery-more-products/product-gallery-more-products";
import SVGIcon from "web/react/components/svg-icon/svg-icon";
import { useDomViewport } from "web/react/hooks/use-dom-viewport/use-dom-viewport";
import {
    ProductPurchaseType,
    useInStockProductPageContext,
} from "web/react/pages/product/in-stock/in-stock-product-page/in-stock-product-page.context";
import styles from "web/react/pages/product/in-stock/product-image-gallery/product-image-carousel-emo.module.css";
import { Image } from "web/react/pages/product/in-stock/product-image-gallery/product-image-gallery";
import analytics from "web/script/analytics/analytics";

export interface ProductImageGalleryProps {
    images: Image[];
    isFullscreen?: boolean;
    settings?: SlickSettings;
    handleImageClick?: (event: React.SyntheticEvent, index: number) => void;
    onClose: () => void;
    currentSlideIndex: number;
    productId: string;
}

export function ProductImageGalleryCarouselEmo({
    images,
    isFullscreen,
    settings,
    handleImageClick,
    onClose,
    currentSlideIndex,
    productId,
}: ProductImageGalleryProps): React.ReactElement {
    const [seenAll, setSeenAll] = useState(false);
    const [showExpand, setShowExpand] = useState(true);

    const { isMobileViewport, isTabletViewport, isDesktopViewport } = useDomViewport();
    const { relatedProducts: moreLikeThisProducts, productPurchaseType } =
        useInStockProductPageContext();

    const isExpressCheckout = productPurchaseType === ProductPurchaseType.EXPRESS_CHECKOUT;
    const carouselImages = images.map((image, index) => (
        <Image
            lazy={isDesktopViewport}
            key={`${image.id}-${index}`}
            src={image.url}
            alt={image.altText ?? undefined}
            onClick={(event) => {
                onImageClick(event, index);
            }}
            isFullscreen={isFullscreen}
            width={isMobileViewport && isExpressCheckout ? 750 : 520}
            height={isMobileViewport && isExpressCheckout ? 488 : 650}
        />
    ));

    if (isMobileViewport && moreLikeThisProducts && moreLikeThisProducts.length > 0) {
        carouselImages.push(
            <MoreProducts
                key="related-slide"
                products={moreLikeThisProducts.slice(0, 4)}
                fullscreen={isFullscreen}
            />
        );
    }

    const lastImageIndex = carouselImages.length - 1;
    const total = carouselImages.length;
    settings = { ...settings, dots: total > 1 };

    // Component for the gallery thumbnail image so we can lazy-load it client-side instead
    function ThumbnailImage({ image }: { image: string }): React.ReactElement | null {
        const [src, setSrc] = React.useState(
            "data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"
        );

        React.useEffect(() => {
            if ((isTabletViewport || isDesktopViewport) && isFullscreen) {
                setSrc(image);
            }
        }, [image]);

        if ((isTabletViewport || isDesktopViewport) && isFullscreen) {
            return <img src={src} />;
        } else {
            return null;
        }
    }

    const sliderRef = useRef<any>(null);

    function onImageClick(event, index): void {
        if (isFullscreen) {
            return;
        }
        handleImageClick && handleImageClick(event, index);
    }

    function getDots(dots): React.ReactElement {
        const thumbnails = dots.map((dot, index) => {
            const image: Image | undefined = images[index];

            return (
                <li key={`${dot.key}-${index}`} className={dot.props.className}>
                    <button
                        data-testid={`dots-${index}`}
                        onClick={(e) => {
                            dot.props.children.props.onClick(e);
                        }}
                    >
                        {image && <ThumbnailImage image={image.url} />}
                    </button>
                </li>
            );
        });

        const noImageDots = dots.map((dot, index) => {
            return (
                isFullscreen && (
                    <li
                        key={`${dot.key}-${index}`}
                        className={clsx(dot.props.className, {
                            [styles.noImageDots]: isFullscreen,
                        })}
                    >
                        <button
                            data-testid={`dot-${index}`}
                            onClick={(e) => {
                                dot.props.children.props.onClick(e);
                            }}
                        ></button>
                    </li>
                )
            );
        });

        return (
            <div>
                <ul
                    className={clsx(styles.imageDotsUl, {
                        [styles.longerSlides]: total >= 6,
                    })}
                >
                    {thumbnails}
                </ul>
                <ul className={styles.noImageDotsUl}>{noImageDots}</ul>
            </div>
        );
    }

    const onKeyDown = (e: KeyboardEvent): void => {
        e.preventDefault();

        if (e.key === "Left" || e.key === "ArrowLeft" || e.key === "Up" || e.key === "ArrowUp") {
            sliderRef.current.slickPrev();
        }

        if (
            e.key === "Right" ||
            e.key === "ArrowRight" ||
            e.key === "Down" ||
            e.key === "ArrowDown"
        ) {
            sliderRef.current.slickNext();
        }

        if (e.key === "Escape") {
            onClose();
        }
    };

    function afterSlideChange(currentSlide): void {
        if (seenAll) {
            // return so we only track "seen_all" once
            return;
        } else if (currentSlide === lastImageIndex) {
            setSeenAll(true);
            analytics.event("product_gallery", "engagement", "seen_all", false, {
                product_id: productId,
            });
        }
    }

    useEffect(() => {
        const sliderElement = sliderRef.current;

        if (isFullscreen) {
            document.addEventListener("keydown", onKeyDown);
            disableBodyScroll(sliderElement, {
                // disables vertical scrolling on mobile (including iOS) and desktop when fullscreen
                allowTouchMove: (el) => {
                    while (el && el !== document.body) {
                        if (el.getAttribute("data-allowTouch") !== null) {
                            return true;
                        }
                        el = el.parentNode;
                    }
                },
            });

            return () => {
                document.removeEventListener("keydown", onKeyDown);
                enableBodyScroll(sliderElement);
            };
        }
    });

    const sliderClasses = clsx({
        [styles.fullscreen]: isFullscreen,
    });

    return (
        // TODO CW-353 this style can be removed when we rewrite the CSS to not use :global
        <div data-testid="image-gallery" className={styles.emo}>
            {total && (
                <div
                    className={clsx(styles.arrowLeft, {
                        [styles.fullscreenArrowLeft]: isFullscreen,
                        [styles.active]: currentSlideIndex !== 0 && total !== 0,
                    })}
                    onClick={() => {
                        currentSlideIndex !== 0 && sliderRef.current.slickPrev();
                    }}
                    data-testid="chevron-thin-left"
                >
                    {total > 1 && <SVGIcon name={"chevron-thin"} rotate={90} />}
                </div>
            )}
            <Slider
                ref={sliderRef}
                className={sliderClasses}
                afterChange={afterSlideChange}
                beforeChange={(_, nextSlide) => {
                    setShowExpand(
                        isTabletViewport || (isMobileViewport && !(nextSlide === lastImageIndex))
                    );
                }}
                {...settings}
                adaptiveHeight={isMobileViewport && isExpressCheckout}
                appendDots={getDots}
            >
                {carouselImages}
            </Slider>
            <span className={styles.expandIconContainer}>
                <button onClick={(event) => handleImageClick?.(event, currentSlideIndex)}>
                    <SVGIcon
                        name="expand"
                        className={clsx(styles.expandIcon, {
                            hidden: isFullscreen || !showExpand,
                        })}
                    />
                </button>
            </span>
            {total && (
                <div
                    className={clsx(styles.arrowRight, {
                        [styles.active]: currentSlideIndex !== lastImageIndex && total !== 0,
                        [styles.fullscreenArrowRight]: isFullscreen,
                    })}
                    onClick={() => {
                        currentSlideIndex !== lastImageIndex && sliderRef.current.slickNext();
                    }}
                    data-testid="chevron-thin-right"
                >
                    {total > 1 && <SVGIcon name={"chevron-thin"} rotate={-90} />}
                </div>
            )}
        </div>
    );
}

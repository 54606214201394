import { gettext } from "web/script/modules/django-i18n";
import environment from "web/script/modules/environment";

function _capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

/**
 * Get translated "for gender" label - E.g. "Men's" or "Pour homme"
 *
 * @param {String} gender - E.g. "M" or "F"
 * @return {String} translated "for gender" label
 */
export function getForGenderLabel(gender) {
    if (gender === "M") {
        return gettext("general.mens");
    } else {
        return gettext("general.womens");
    }
}

/**
 * Get translated color label
 *
 * @param {String} color - E.g. "blue"
 * @return {String} translated color label
 */
export function getColorLabel(color) {
    const i18nKey = `color.${color}`;
    let colorLabel = gettext(i18nKey);
    if (colorLabel === i18nKey) {
        colorLabel = _capitalizeFirstLetter(color);
    }
    return colorLabel;
}

/**
 * Get translated material label
 *
 * @param {String} material - e.g. "fur"
 * @return {String} translated material label
 */
export function getMaterialLabel(material) {
    const i18nKey = `material.${material}`;
    let materialLabel = gettext(i18nKey);
    if (materialLabel === i18nKey) {
        materialLabel = _capitalizeFirstLetter(material);
    }
    return materialLabel;
}

function _taxonomyLookup(tag, gender, productTaxonomy) {
    const taxonomyKeyPart = productTaxonomy.toLowerCase().replace(" ", "-");
    const i18nKey = `taxonomy.${gender.toLowerCase()}.${tag.toLowerCase()}.${taxonomyKeyPart}`;
    const taxonomyLabel = gettext(i18nKey);
    if (taxonomyLabel === i18nKey) {
        return null;
    } else {
        return taxonomyLabel;
    }
}

function _getTaxonomyLabel(gender, productTaxonomy, firstTag, fallbackTag) {
    let taxonomyLabel = _taxonomyLookup(firstTag, gender, productTaxonomy);
    if (taxonomyLabel !== null) {
        return taxonomyLabel;
    }
    taxonomyLabel = _taxonomyLookup(fallbackTag, gender, productTaxonomy);
    if (taxonomyLabel !== null) {
        return taxonomyLabel;
    }
    taxonomyLabel = getProductTypeLabel(gender, productTaxonomy);
    if (taxonomyLabel !== null) {
        return taxonomyLabel;
    }
    return _capitalizeFirstLetter(productTaxonomy);
}

/**
 * Get translated product type label
 *
 * @param {String} product type - e.g. "Bags"
 * @return {String} translated product type label
 */
export function getProductTypeLabel(gender, productType) {
    const PRODUCT_NAMES_TO_TYPES = environment.get("productNamesToTypes");
    const productTypeValue =
        PRODUCT_NAMES_TO_TYPES[_capitalizeFirstLetter(productType)].toLowerCase();
    return _taxonomyLookup("product_type", gender, productTypeValue);
}

/**
 * Get translated product category label
 *
 * @param {String} product category - e.g. "shirts"
 * @return {String} translated category label
 */
export function getCategoryLabel(gender, category) {
    // Try category first, fallback to subcategory
    return _getTaxonomyLabel(gender, category, "category", "subcategory");
}

/**
 * Get translated product subcategory label
 *
 * @param {String} product subcategory - e.g. "gowns"
 * @return {String} translated subcategory label
 */
export function getSubcategoryLabel(gender, subcategory) {
    // Try subcategory first, fallback to category
    return _getTaxonomyLabel(gender, subcategory, "subcategory", "category");
}

/**
 * Get translated label for either a product type, category or subcategory.
 * Use this when we're not sure which taxonomy type it is.
 *
 * @param {String} product taxonomy - e.g. "Bags" or "shirts" or "gowns"
 * @return {String} translated taxonomy label
 */
export function getTaxonomyLabel(gender, productTaxonomy) {
    // If not sure, try category first, fallback to subcategory
    return _getTaxonomyLabel(gender, productTaxonomy, "category", "subcategory");
}

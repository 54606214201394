export default class Stack {
    constructor() {
        this.stack = [];
    }

    /**
     * Pushes an element to the stack.
     *
     * @param {*} element - Whatever you like
     */
    push(element) {
        this.stack.push(element);
    }

    /**
     * Pops the last element from the stack.
     *
     * @return {*} The last element on the stack
     */
    pop() {
        return this.stack.pop();
    }

    /**
     * Returns the top element from the stack (without removing it).
     *
     * @return {*} The last element on the stack
     */
    peek() {
        return this.stack[this.stack.length - 1];
    }

    /**
     * Utility method to see if the stack is empty or not.
     *
     * @return {Boolean} Whether the stack is empty or not
     */
    isEmpty() {
        return !this.stack.length;
    }
}

import { getFormat } from "web/script/modules/django-i18n";
/**
 * Convert an integer to a string containing commas every three digits.
 * For example, 3000 becomes "3,000" and 45000 becomes "45,000".
 *
 * See: https://github.com/django/django/blob/master/django/contrib/humanize/templatetags/humanize.py
 *
 * @param {Integer|Float|String} value - the number to be formatted
 * @param {Integer|Null} numberDecimalPlaces - optional
 * @return {String}
 */
export function numberFormat(value, numberDecimalPlaces = null) {
    try {
        const decimalSep = getFormat("DECIMAL_SEPARATOR");
        const grouping = getFormat("NUMBER_GROUPING");
        const thousandSep = getFormat("THOUSAND_SEPARATOR");
        return _numberFormat(value, decimalSep, numberDecimalPlaces, grouping, thousandSep);
    } catch (e) {
        return "0";
    }
}

/**
 *  Get a number (as a number or string), and return it as a string,
 *  using formats defined as arguments:
 *
 * See: https://github.com/django/django/blob/master/django/utils/numberformat.py
 *
 * @param {Number|String} value
 * @param {String} decimal_sep - decimal separator symbol (for example ".")
 * @param {Number} decimal_pos - number of decimal positions
 * @param {Number} grouping - number of digits in every group limited by thousand separator.
    For non-uniform digit grouping, it can be a sequence with the number
    of digit group sizes following the format used by the Python locale
    module in locale.localeconv() LC_NUMERIC grouping (e.g. (3, 2, 0)).
 * @param {String} thousand_sep - thousand separator symbol (for example ",")
 * @return {String}
 */
function _numberFormat(value, decimalSep, decimalPos = null, grouping = 0, thousandSep = "") {
    const useGrouping = grouping != 0;
    // Make the common case fast
    if (typeof value == "number" && value % 1 == 0 && grouping == 0 && decimalPos == 0) {
        return value.toString();
    }

    // sign
    let sign = "";
    let strValue = "";
    if (typeof value == "number") {
        strValue = value.toLocaleString("fullwide", {
            minimumFractionDigits: decimalPos,
            useGrouping: false,
        });
    } else {
        strValue = value.toString();
    }

    if (strValue.charAt(0) == "-") {
        sign = "-";
        strValue = strValue.slice(1);
    }

    // decimal part
    let [intPart, decPart] = [false, false];
    if (strValue.includes(".")) {
        [intPart, decPart] = strValue.split(".");
        if (decimalPos != null) {
            decPart = decPart.slice(0, decimalPos);
        }
    } else {
        [intPart, decPart] = [strValue, ""];
    }

    if (decimalPos != null) {
        decPart = decPart + "0".repeat(decimalPos - decPart.length);
    }

    if (decPart.length > 0) {
        decPart = decimalSep + decPart;
    }

    // grouping
    if (useGrouping) {
        let intervals = [];
        if (Array.isArray(grouping)) {
            // if grouping is a sequence
            intervals = grouping;
        } else {
            // grouping is a single value
            intervals = [grouping, 0];
        }

        let activeInterval = intervals.shift();
        let intPartGd = "";
        let cnt = 0;

        intPart
            .split("")
            .reverse()
            .forEach(function (digit) {
                if (cnt > 0 && cnt == activeInterval) {
                    if (intervals.length > 0) {
                        activeInterval = intervals.shift() || activeInterval;
                    }
                    intPartGd += thousandSep.split("").reverse().join("");
                    cnt = 0;
                }

                intPartGd += digit;
                cnt += 1;
            });

        intPart = intPartGd.split("").reverse().join("");
    }

    return sign + intPart + decPart;
}

import format from "string-format";

/**
 * These methods wrap Django i18n methods in an es6 module,
 * while adding you support for python style formatting of i18n strings that have placeholders
 * and handling the case where the django methods are not available - for example during JS testing.
 * Documentation is copied from https://docs.djangoproject.com/en/1.11/topics/i18n/translation/#gettext
 */
let translationsCatalog = {
    catalog: {},
    formats: {},
    plural: (n) => n != 1,
};

if (window.django && window.django.translationsCatalog) {
    let catalog = window.django.translationsCatalog;
    ensurePluralFunction(catalog);
    setTranslationsCatalog(catalog);
}

export function ensurePluralFunction(catalog) {
    if (!catalog.plural) {
        catalog.plural = (n) => n != 1;
        return;
    }
    catalog.plural = new Function("n", `return ${catalog.plural}`);
}

export function setTranslationsCatalog(catalog) {
    translationsCatalog = catalog;
}

/**
 * The gettext function behaves similarly to the standard gettext interface within your Python code
 * with an additional optional argument to format the string with placeholder values.
 *
 * @example
 *  document.write(gettext('this is to be translated'));
 *
 * @param {String} msgid
 * @param {Object} formatPlaceholders names and values to format into the string
 * @returns {String} a locally translated string
 */
export function gettext(msgid, formatPlaceholders = null) {
    let value = translationsCatalog.catalog[msgid];
    if (typeof value == "undefined") {
        return msgid;
    }

    let content = typeof value == "string" ? value : value[0];
    if (formatPlaceholders) {
        return format(content, formatPlaceholders);
    }
    return content;
}
/**
 * The ngettext function provides an interface to pluralize words and phrases
 * with an additional optional argument to format the string with placeholder values.
 *
 * @example
 *  var objectCount = 1 // or 0, or 2, or 3, ...
 *  s = ngettext('literal for the singular case', 'literal for the plural case', objectCount);
 *
 * @param {String} singular
 * @param {String} plural
 * @param {Number} count
 * @param {Object} formatPlaceholders names and values to format into the string
 * @returns {String} a locally translated string
 */
export function ngettext(singular, plural, count, formatPlaceholders = null) {
    let value = translationsCatalog.catalog[singular];
    let content = "";
    if (typeof value == "undefined") {
        content = count == 1 ? singular : plural;
    } else {
        content = value[pluralidx(count)];
    }
    return formatPlaceholders ? format(content, formatPlaceholders) : content;
}

/**
 * The interpolate function supports dynamically populating a format string.
 * The interpolation syntax is borrowed from Python, so the interpolate function supports both positional
 * and named interpolation
 *
 * Positional interpolation: obj contains a JavaScript Array object whose elements values are then sequentially
 * interpolated in their corresponding fmt placeholders in the same order they appear. For example:
 *
 * @example
 *  fmts = ngettext('There is %s object. Remaining: %s',
 *          'There are %s objects. Remaining: %s', 11);
 *  s = interpolate(fmts, [11, 20]);
 *  // s is 'There are 11 objects. Remaining: 20'
 *
 * Named interpolation: This mode is selected by passing the optional boolean named parameter as true.
 * obj contains a JavaScript object or associative array. For example:
 *
 * @example
 *  d = {
 *      count: 10,
 *      total: 50
 *  };
 *
 *  fmts = ngettext('Total: %(total)s, there is %(count)s object',
 *  'there are %(count)s of a total of %(total)s objects', d.count);
 *  s = interpolate(fmts, d, true);
 *
 * @param {String} fmt - string to format
 * @param {Object|Array<String>} obj - values to format with
 * @param {Boolean} named - is obj an Object? If not it's an array.
 * @returns {String} an interpolated string
 */
export function interpolate(fmt, obj, named) {
    if (named) {
        return fmt.replace(/%\(\w+\)s/g, (match) => obj[match.slice(2, -2)]);
    } else {
        return fmt.replace(/%s/g, () => obj.shift());
    }
}

/**
 * The pgettext function behaves like the Python variant (pgettext()), providing a contextually translated word
 *
 * @example
 *  document.write(pgettext('month name', 'May'));
 *
 * @param {String} context
 * @param {String} msgid
 * @returns {String} a locally translated string
 */
export function pgettext(context, msgid) {
    let value = gettext(context + "\x04" + msgid);
    if (value.includes("\x04")) {
        value = msgid;
    }
    return value;
}

/**
 * The gettextFake function is used to extract dynamic translation key values at build time. Behaves similarly to gettext_noop
 *
 * @example
 *  document.write(gettextNoop('key'));
 *
 * @param {String} msgid
 * @returns {String} msgid
 */
export function gettextNoop(msgid) {
    return msgid;
}

/**
 * The npgettext function also behaves like the Python variant (npgettext()), providing a pluralized contextually
 * translated word
 *
 * @example
 *   document.write(npgettext('group', 'party', 1));
 *   // party
 *   document.write(npgettext('group', 'party', 2));
 *   // parties
 *
 * @param {String} context
 * @param {String} singular
 * @param {String} plural
 * @param {Number} count
 * @returns {String} a locally translated string
 */
export function npgettext(context, singular, plural, count) {
    let value = ngettext(context + "\x04" + singular, context + "\x04" + plural, count);
    if (value.includes("\x04")) {
        value = ngettext(singular, plural, count);
    }
    return value;
}

/**
 * The pluralidx function works in a similar way to the pluralize template filter, determining if a given count should
 * use a plural form of a word or not:
 *
 * @example
 *  document.write(pluralidx(0));
 *  // 1
 *  document.write(pluralidx(1));
 *  // 0
 *  document.write(pluralidx(2));
 *  // 1
 *
 * It has access to the following settings:
 *
 * In the simplest case, if no custom pluralization is needed, this returns 0 for the integer 1 and 1
 * for all other numbers.
 *
 * However, pluralization is not this simple in all languages. If the language does not support pluralization,
 * an empty value is provided.
 *
 * Additionally, if there are complex rules around pluralization, the catalog view will render a conditional expression.
 *
 * @param {Number} n
 * @returns {Number} index of correct plural string
 */
export function pluralidx(n) {
    let v = translationsCatalog.plural(n);
    if (typeof v == "boolean") {
        return v ? 1 : 0;
    } else {
        return v;
    }
}

/**
 * The get_format function has access to the configured i18n formatting settings and can retrieve the format string
 * for a given setting name
 *
 * @example
 *  document.write(get_format('DATE_FORMAT'));
 *  // 'N j, Y'
 *
 * It has access to the following settings:
 *
 * DATE_FORMAT
 * DATE_INPUT_FORMATS
 * DATETIME_FORMAT
 * DATETIME_INPUT_FORMATS
 * DECIMAL_SEPARATOR
 * FIRST_DAY_OF_WEEK
 * MONTH_DAY_FORMAT
 * NUMBER_GROUPING
 * SHORT_DATE_FORMAT
 * SHORT_DATETIME_FORMAT
 * THOUSAND_SEPARATOR
 * TIME_FORMAT
 *
 * @param {String} formatType
 * @returns {String} format for this locale
 */
export function getFormat(formatType) {
    let value = translationsCatalog.formats[formatType];
    if (typeof value == "undefined") {
        return formatType;
    } else {
        return value;
    }
}

import React from "react";
import { createRoot } from "react-dom/client";

export default function renderReactComponentInDocument(
    component: React.FunctionComponent<any> | React.ComponentClass<any, any> | string,
    props: any,
    element: Element
): void {
    if (props) {
        createRoot(element).render(React.createElement(component, props));
    }
}

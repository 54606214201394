import baustein from "baustein";
import BuyArea from "web/react/components/buybuybuy-area/buy-area/buy-area";
import { RelatedProductsSidebar } from "web/react/components/related-products-sidebar/related-products-sidebar";
import analytics from "web/script/analytics/analytics";
import "web/script/components/save-for-later-button";
import "web/script/components/save-for-later-signup-prompt";
import "web/script/components/tracked-link";
import renderReactComponentInDocument from "web/script/utils/render-react";

export default baustein.register("product-overview", {
    defaultOptions: {
        relatedProductsHorizontal: null,
        relatedProductsSidebar: null,
    },

    setupEvents(add) {
        add("readMoreText", this._onReadMoreText);
    },

    onInsert() {
        this._renderBuyArea();
        this._renderRelatedProductsHorizontal();
        this._renderRelatedProductsSidebar();
    },

    _onReadMoreText() {
        analytics.event("product_overview", "read_more_details");
    },

    _renderBuyArea() {
        if (document.querySelector(".buybuybuy-area__slot")) {
            renderReactComponentInDocument(
                BuyArea,
                {
                    header: this.options.productHeader,
                    buyAreaOptions: this.options.buyAreaOptions,
                    productDescription: this.options.productDescription,
                    csrfToken: this.options.csrfToken,
                    isOverlay: true,
                },
                document.querySelector(".buybuybuy-area__slot")
            );
        }
    },

    _renderRelatedProductsHorizontal() {
        if (document.querySelector(".horizontal-related-products__slot")) {
            renderReactComponentInDocument(
                RelatedProductsSidebar,
                {
                    relatedProductsSidebar: this.options.relatedProductsHorizontal,
                    variant: "horizontal",
                },
                document.querySelector('.horizontal-related-products__slot"')
            );
        }
    },

    _renderRelatedProductsSidebar() {
        if (document.querySelector(".related-products-sidebar__slot")) {
            renderReactComponentInDocument(
                RelatedProductsSidebar,
                {
                    relatedProductsSidebar: this.options.relatedProductsSidebar,
                    variant: "vertical",
                },
                document.querySelector(".related-products-sidebar__slot")
            );
        }
    },
});

import clsx from "clsx";
import React, { ReactNode, useEffect, useRef } from "react";
import styles from "./keyline-grid.module.css";

interface KeylineGridProps {
    children?: ReactNode;
    className?: string;
    gridClassName?: string;
    fullWidth?: boolean;
    isEmo?: boolean;
}

export function KeylineGrid({
    children,
    className,
    gridClassName,
    fullWidth = false,
    isEmo = false,
}: KeylineGridProps): JSX.Element {
    return (
        <div className={clsx(styles.wrapper, className)}>
            <div
                className={clsx(styles.grid, gridClassName, {
                    [styles.emo]: isEmo,
                    [styles.fullWidth]: fullWidth,
                })}
            >
                {children}
            </div>
        </div>
    );
}

interface KeylineGridItemProps {
    children: ReactNode;
    scrollIntoView?: boolean;
    className?: string;
    colspan?: 1 | 2;
    doubleColumn?: boolean;
    isEmo?: boolean;
}

export function KeylineGridItem({
    children,
    scrollIntoView = false,
    className,
    colspan = 1,
    doubleColumn = false,
    isEmo = false,
}: KeylineGridItemProps): JSX.Element {
    const ref = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (scrollIntoView) {
            ref.current?.scrollIntoView();
        }
    }, [scrollIntoView]);

    return (
        <div
            ref={ref}
            className={clsx(
                { [styles.item]: !isEmo },
                { [styles.colspan2]: colspan === 2 },
                { [styles.doubleColumn]: doubleColumn },
                className
            )}
        >
            {children}
        </div>
    );
}

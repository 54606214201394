/** @module components/lyst-app */
import baustein from "baustein";
import _debounce from "lodash/debounce";
import { watchState } from "web/redux/state-watcher";
import analytics from "web/script/analytics/analytics";
import "web/script/components/background-banner";
import ProductOverlay from "web/script/components/overlays/product-overlay";
import ShippingAndReturnsOverlay from "web/script/components/overlays/shipping-and-returns-overlay";
// Footer
import "web/script/components/tracked-link";
import environment from "web/script/modules/environment";
import globals from "web/script/modules/globals";
import requester from "web/script/modules/requester";
import Stack from "web/script/modules/stack";
import { getProductPageRegex } from "web/script/routing/routes";
import alerts from "web/script/utils/alerts";
import browser from "web/script/utils/browser";
import globalEvents from "web/script/utils/global-events";
import history from "web/script/utils/history";
import logging from "web/script/utils/logging";
import storage from "web/script/utils/storage";
import url from "web/script/utils/url";

const LYST_APP_CALLOUT_SHOWN_MODIFIER = "lyst-app--callout-shown";
const APP_HEADER_COMPONENT = "app-header-main";

/**
 * This component encapsulates the entire lyst web app. It handles page load set up, any
 * subsequent client side navigation, and ideally also will manage the lifecycle of overlays.
 * @constructor
 * @alias module:components/lyst-app
 * @extends module:baustein
 */
export default baustein.register(
    "lyst-app",
    /** @lends module:components/lyst-app.prototype */
    {
        defaultOptions: {
            surveyEnabled: false,
            exitOverlaySource: "",
            exitOverlayUrl: "",
        },

        overlays: ["base-overlay", "country-overlay", "editing-overlay", "product-overlay"],

        membershipPages: ["sign_in", "new_signup", "signup", "signup form"],

        setupEvents(add) {
            add("overlayOpen", this._onOverlayOpen);
            add("overlayCloseIntent", this._onOverlayCloseIntent);
            add("overlayClose", this._onOverlayClose);
            add("oosItemFound", "cart-summary", this.onCartCalloutOpened);
            add("saveForLaterButtonInserted", this._onSaveForLaterButtonInserted);
            add("cartSummaryShown", this.closeSaveForLaterCallout);
            add("fixPageContent", this.fixPageContent);
            add("unFixPageContent", this.unFixPageContent);
            add("showInstantFeedback", this.showInstantFeedback);

            this._boundOpenCartCallout = this.onCartCalloutOpened.bind(this);
            globalEvents.on("added-to-bag", this._boundOpenCartCallout);
        },

        init() {
            // we need to override any logic that attempts to open the overlay when routing
            this.useNewOverlay = true;
            this.attachShippingOverlayWatcher();
            this.crmSurveyOverlay;
            this.overlayStack = new Stack();
            this.sflProductIdsQueue = [];
            this.lastScrollTop = window.pageYOffset;
            this._trackPWAAppInstallEvent();
            this._trackPWAHomescreenLaunch();
            this._trackPWAMode();
            this._performFrontEndRouting();
            this._redirectProxySites();
        },

        onInsert() {
            this._addSupportClasses();

            // these are messages created using the Django messages framework
            // See https://docs.djangoproject.com/en/1.8/ref/contrib/messages/ for more info
            const messages = environment.get("messages", []);
            messages.forEach((message) => {
                if (!message.tags.includes("toast")) {
                    alerts.alert(message.message);
                }
            });

            const parsedURL = url.parse(globals.window.location);

            // show instant notification if registered = "true" and area are set
            const notificationArea = parsedURL.searchParams.get("area");
            if (
                (parsedURL.searchParams.get("loggedin") === "true" ||
                    parsedURL.searchParams.get("registered") === "true") &&
                notificationArea
            ) {
                this.showInstantFeedback({ area: notificationArea });
            }

            // fire stock check on page load
            const header = this.findComponent(APP_HEADER_COMPONENT);
            if (header) {
                let stickyBuyButton = this.findComponent("buybuybuy-sticky-buy-button");

                if (stickyBuyButton) {
                    stickyBuyButton.setHeaderHeightReference(
                        header.el.getBoundingClientRect().height
                    );
                }
            }
        },

        onRemove() {
            this.releaseGlobalHandler("mouseleave", this.onBodyMouseLeave);
            globalEvents.off("added-to-bag", this._boundOpenCartCallout);
        },

        /**
         * Block the scrolling of the body.
         */
        blockScrolling() {
            this.el.classList.add("no-scroll");
        },

        /**
         * Un-block the scrolling of the body.
         */
        unblockScrolling() {
            this.el.classList.remove("no-scroll");
        },

        /**
         * A function to reference when we want to prevent a default browser behaviour
         * @param {Event} event
         */
        preventDefault(event) {
            event.preventDefault();
            return false;
        },
        fixPageContent() {
            this.el.classList.add("fix-element");
        },

        unFixPageContent() {
            this.el.classList.remove("fix-element");
        },

        /**
         * Tracks PWA appinstalled event.
         * @private
         */
        _trackPWAAppInstallEvent() {
            window.addEventListener("appinstalled", function () {
                analytics.event("pwa", "installed");
            });
        },

        /**
         * Tracks if user launched PWA from homescreen
         * @private
         */
        _trackPWAHomescreenLaunch() {
            var params = this._getParamsFromRequest();
            if (params.get("utm_medium") === "pwa") {
                analytics.event("pwa", "homescreen_launch");
            }
        },

        /**
         * Tracks if user is currently in PWA
         * @private
         */
        _trackPWAMode() {
            var isPWAinBrowser = true;

            if (matchMedia("(display-mode: standalone)").matches) {
                // Android and iOS 11.3+
                isPWAinBrowser = false;
            } else if ("standalone" in navigator) {
                // useful for iOS < 11.3
                isPWAinBrowser = !navigator.standalone;
            }

            if (!isPWAinBrowser) {
                analytics.event("pwa", "is_pwa");
            }
        },

        /**
         * If the page is being loaded on a non-lyst domain, redirect back to Lyst.
         * @private
         */
        _redirectProxySites() {
            // Attempt to guess if this is an allowed domain.
            const hostname = document.location.hostname;
            const href = document.location.href;
            const hostWhitelist = /(lyst|127\.0\.0\.1|localhost|nip\.io|archive\.org|google)/;
            const hrefWhitelist = /translate.*\/.*\?.*=.*lyst/;

            if (hostname.match(hostWhitelist) || href.match(hrefWhitelist)) {
                return;
            } else {
                document.location.href = `https://www.lyst.com${document.location.pathname}?nonlystdomainredirect=1`;
            }
        },

        _getParamsFromRequest() {
            var parsed = url.parse(globals.window.location.href);
            return parsed.searchParams;
        },

        /**
         * Adds classes to this component that indicate whether certain
         * features are supported or not.
         * @private
         */
        _addSupportClasses() {
            var classList = this.el.classList;

            // add class to the body to indicate whether this device has touch support
            classList.add(browser.hasTouch ? "has-touch" : "no-touch");

            // if device supports css transforms add class to body for CSS to hook into
            classList.toggle("has-css-transforms", browser.supportsCSSTransforms);

            // if device has css transitions
            classList.toggle("no-css-transitions", !browser.supportsCSSTransitions);

            // If scrollbars are stylable, add a class to help.
            classList.toggle("css-scrollbars", browser.supportsCSSScrollbars);
        },

        onCartCalloutOpened() {
            // show desktop header, add matte, prevent scrolling
            this.invoke(this.findComponent("desktop-header"), "show");
            this.el.classList.add(LYST_APP_CALLOUT_SHOWN_MODIFIER);
        },

        closeCartSummary() {
            const header = this.findComponent(APP_HEADER_COMPONENT);

            if (header) {
                const cartSummary = header.findComponent("cart-summary");
                this.invoke(cartSummary, "close");
            }
        },

        _onOverlayOpen(event) {
            if (this.useNewOverlay) {
                return;
            } // prevent this baustein model from doing anything if we're in the new overlay experiment
            this.overlayStack.push(event.target);
            this.blockScrolling();
        },

        _onOverlayCloseIntent(event) {
            const activeOverlay = this.overlayStack.peek();

            // If the event is not coming from the active
            // overlay, preventDefault and do nothing.
            if (activeOverlay !== event.target) {
                event.preventDefault();
                return;
            }
        },

        _onOverlayClose() {
            const header = this.findComponent("app-header");

            this.overlayStack.pop();

            // If there is no active overlay,
            // unblock the scrolling
            if (this.overlayStack.isEmpty()) {
                if (header && header.openCallouts) {
                    return;
                }

                this.unblockScrolling();
                this.unFixPageContent();
            }
        },

        /**
         * On every save for later button insert event, pushes the product ID
         * into a list. Then fetches the pins and re-render the SFL button
         * if it has to.
         *
         * @param {Event} event
         */
        _onSaveForLaterButtonInserted(event) {
            this.sflProductIdsQueue.push(event.productId);
            this._fetchSaveForLaterPins();
        },

        /**
         * Requests the pins API endpoint passing the list of product ids in the
         * page. That will return an object with a map of the ids to their pin
         * status, i.e.
         *      {
         *          00000: True,  # The user has saved/pinned this product...
         *          00101: False, # ... But not this one.
         *          01010: True   # etc.
         *      }
         * Runs every 50ms – debounced.
         */
        _fetchSaveForLaterPins: _debounce(function () {
            const endpoint = "/services/pins/";
            const query = url.toURLSearchParams({
                product_id: [...this.sflProductIdsQueue],
            });

            this.sflProductIdsQueue = [];

            requester
                .get(endpoint, query)
                .then((data) => {
                    this._setPinnedProducts(data.pin_mapping);
                })
                .catch((error) => logging.error(error));
        }, 50),

        /**
         * Loops through the save for later buttons in the page and re-renders them
         * based on the user-saved product pins.
         *
         * @param {Object} pinnedProductsMapping - The product id mapping
         */
        _setPinnedProducts(pinnedProductsMapping) {
            const sflButtons = this.findComponents("save-for-later-button");
            sflButtons.forEach((saveForLaterButton) => {
                const isPinned = pinnedProductsMapping[saveForLaterButton.options.productId];
                if (isPinned) {
                    saveForLaterButton._render(true);
                }
            });
        },

        showInstantFeedback({ area }) {
            setTimeout(() => {
                /* skip a tick to allow the React component to initialize */
                globalEvents.trigger("instant-feedback-show", area);
            });
            this.findComponents("instant-feedback").forEach((component) => {
                if (component.options.area === area) {
                    component.show();
                }
            });
        },

        attachShippingOverlayWatcher() {
            watchState(
                (state) => {
                    return state.shippingAndReturnsOverlayReducer;
                },
                (overlayState) => {
                    if (!overlayState.open) {
                        return;
                    }
                    const linkId = overlayState.linkId;
                    const shippingAndReturnsOverlay = new ShippingAndReturnsOverlay({ linkId });

                    // append overlay to the document body, because this component will typically
                    // be shown in an accordion, which is position relative and overflow hidden
                    // so would not display correctly
                    shippingAndReturnsOverlay.appendTo(document.body);
                    shippingAndReturnsOverlay.open();
                    analytics.event("buy_area", "show_shipping_and_returns");
                }
            );
        },

        _performFrontEndRouting() {
            // Attach change event listener for history
            history.on("change", this._onHistoryChange.bind(this));

            // don't open the old overlay if we're in the new experiment
            if (this.useNewOverlay) {
                return;
            }
            // Try to open product overlay initially
            this._initialProductOverlayOpen();
        },

        // ORG-3465: More details in https://confluence.lystit.com/display/OA/FY23+Q2+-+Hash+URL+Crawl+Optimisation
        // ORG-3619: More details in https://confluence.lystit.com/pages/viewpage.action?pageId=80321368
        // Check if we are on feed
        // and we have hash URL so that we open product overlay
        async _initialProductOverlayOpen() {
            const feedLayout = this.findComponent("product-feed-layout");
            const url = new URL(window.location.href);

            if (!(url.hash && url.hash.startsWith("#slug="))) {
                return;
            }

            // We know that we are on feed since we are opening
            // overlay from hash URL, so we get the pathname
            // as the origin feed so we can use in the overlay module
            if (feedLayout) {
                url.searchParams.set("origin_feed", url.pathname);
            }

            // We want to have those in the lifetime
            // of the session, so we will keep them
            // in the session storage
            storage.set("overlayOnInitialPageLoad", true, true);
            storage.set("initialPageURL", window.location.href, true);

            await this._openProductOverlay({
                newURL: url,
            });

            analytics.pageView();
        },

        /**
         * @param {import("web/script/utils/history").BrowserHistoryEvent} data
         */
        async _openProductOverlay({ newURL }) {
            // Get the product slug and put it in the data
            let productSlug;
            // ORG-3465: More details in https://confluence.lystit.com/display/OA/FY23+Q2+-+Hash+URL+Crawl+Optimisation
            // ORG-3619: More details in https://confluence.lystit.com/pages/viewpage.action?pageId=80321368
            // Use the slug from the hash
            const withHashURL = newURL.hash && newURL.hash.startsWith("#slug=");

            if (withHashURL) {
                productSlug = newURL.hash.replace("#slug=", "");
            } else {
                // Extract the slug from the path
                productSlug = newURL.pathname.match(getProductPageRegex())[2];
            }

            let { searchParams } = newURL;

            let args = {
                productSlug,
                // ORG-3465: More details in https://confluence.lystit.com/display/OA/FY23+Q2+-+Hash+URL+Crawl+Optimisation
                originFeed: searchParams.get("origin_feed"),
                // We need those so we can close it programatically when it is coming from hash URL and initial page load
                withHashURL,
                // TODO: These were always undefined before, is fixing them correct?
                productUid: searchParams.get("product_overlay_uid"),
                linkId: searchParams.get("link_id"),
                previousPageType: searchParams.get("previous_page_type"),
                previousPageSubType: searchParams.get("previous_page_sub_type"),
            };

            let existingOverlay = this.findComponent("product-overlay");
            if (existingOverlay) {
                // Wait for the overlay to update
                // and then move on
                await existingOverlay.updateOptions(args);
                return;
            }

            // NOTE: Change this to an `import()` statement to bundle split the overlay
            let overlay = new ProductOverlay(args);
            overlay.appendTo(this);
            return overlay.open();
        },

        /**
         * @param {string} path
         */
        async _closeProductOverlay(path) {
            // This should only ever happen when you close an overlay.
            let overlay = this.findComponent("product-overlay");
            if (!overlay) {
                // "this should never happen"
                logging.error(`Unexpected product transition to ${path}?!`);
                // The router's default "halp" reaction is to reload the page, so
                globals.window.location.reload();
                return;
            }
            await overlay.close();
        },

        /**
         * Handle the remnants of our frontend routing system.
         * Currently it is used for 2 things:
         *  - Opening the product overlay
         *  - Updating feeds (pagination & filters)
         * All other routing is done via full page changes.
         * @param {never} _
         * @param {import("web/script/utils/history").BrowserHistoryEvent} data
         */
        async _onHistoryChange(_, data) {
            // If we're not changing any page parameters (eg opening a
            // non-product modal) then don't do any routing
            if (
                data.newURL.pathname === data.oldURL?.pathname &&
                data.queryChanges.length === 0 &&
                // if the hash doesn't change then nothing to do as well
                data.newURL.hash === data.oldURL?.hash
            ) {
                return;
            }

            // ORG-3465: More details in https://confluence.lystit.com/display/OA/FY23+Q2+-+Hash+URL+Crawl+Optimisation
            // ORG-3619: More details in https://confluence.lystit.com/pages/viewpage.action?pageId=80321368
            const isGoingToProductCardHashURL =
                !!data.newURL.hash && data.newURL.hash.startsWith("#slug=");
            const isComingFromProductCardHashURL =
                !!data.oldURL?.hash && data.oldURL?.hash.startsWith("#slug=");
            const initialPageURL = storage.get("initialPageURL", null, true)
                ? new URL(storage.get("initialPageURL", "", true))
                : null;
            const productPageRoute = getProductPageRegex();
            const path = data.newURL.pathname;
            const isOpeningProductOnFeed =
                productPageRoute.test(path) || isGoingToProductCardHashURL;
            const isClosingProductOnFeed =
                productPageRoute.test(data.oldURL?.pathname) || isComingFromProductCardHashURL;

            const feedLayout = this.findComponent("product-feed-layout");
            const isOnPDP =
                this.find('[data-hypernova-key="InStockAffiliateProductPage"]').length ||
                this.find('[data-hypernova-key="InStockCheckoutProductPage"]').length ||
                this.find('[data-hypernova-key="InStockExpressCheckoutProductPage"]').length ||
                this.find('[data-hypernova-key="OutOfStockProductPage"]').length ||
                // New PDP redesign
                this.find('[data-hypernova-key="InStockProductPage"]').length;
            const isOnHomepage = this.find('[data-hypernova-key="Stories"]').length;
            const isOnEditorial = this.find('[data-hypernova-key="Editorial"]').length;
            const isOnTheEdit = this.find('[data-hypernova-key="TheEdit"]').length;
            const isOnCuratedCollection = this.find(
                '[data-hypernova-key="CuratedCollection"]'
            ).length;
            // Don't send pageView events if you are going backwards or forwards.
            // This makes our analytics more consistent but also happens to work around
            // a problem in the reffer tracking in the analytics module that results
            // in pages having themselves as a referrer currently.
            function sendPageView() {
                if (data.eventType !== "popstate") {
                    analytics.pageView();
                }
            }

            // we don't want baustein taking over the routing related
            // to the new overlay, check url-overlay.tsx for new component
            const feedOverlay = feedLayout && (isOpeningProductOnFeed || isClosingProductOnFeed);
            if (
                this.useNewOverlay &&
                (isOnPDP || feedOverlay || isOnHomepage || isOnEditorial || isOnCuratedCollection)
            ) {
                return;
            }

            // ORG-3557: Open overlays on all page types
            // We want to be able to open product overlays out of hash URLs
            // regardless of the context where the product card is rendered
            // (mainly in Feeds and PDPs)
            // We need to handle several cases:

            // 1. In PDP page context
            // If the product page component exists, we're already on a
            // product page and are moving around it (eg gallery or colour
            // variant picker) so we won't do anything
            if (isOnPDP) {
                // 1a. Trying to open Hash URL (can happen from within any of the modules on the PDP)
                // It can be either when going to hash URL
                // or changing to regular URL of a product (we still want to have overlay)
                if (
                    isGoingToProductCardHashURL ||
                    (isComingFromProductCardHashURL &&
                        data.eventType === "pushstate" &&
                        path !== initialPageURL?.pathname)
                ) {
                    // Open or update the product overlay
                    await this._openProductOverlay(data);
                    analytics.pageView();

                    return;
                }

                // 1b. Closing overlay
                // It can happen when coming back from hash URL
                // but only when the history event is `popstate`
                // which means going back in history
                // otherwise we still want to open overlay
                if (
                    isComingFromProductCardHashURL &&
                    (data.eventType === "popstate" || path === initialPageURL?.pathname)
                ) {
                    // Close the product overlay
                    await this._closeProductOverlay(path);

                    // ORG-3557: Send product page view when coming back from overlay
                    // but only if the page view hasn't been sent
                    const sessionHasPDPPageView = storage.get("hasPDPPageView", false, true);
                    const overlayOnInitialPageLoad = storage.get(
                        "overlayOnInitialPageLoad",
                        false,
                        true
                    );

                    // eslint-disable-next-line max-depth
                    if (!sessionHasPDPPageView && overlayOnInitialPageLoad) {
                        analytics.pageView();
                        storage.set("hasPDPPageView", true, true);
                    }

                    return;
                }

                // 1c. Navigating between products on PDP
                // with overlay opened
                // This case catches when we are navigating between products
                // with regular URLs on already opened overlay
                if (this.findComponent("product-overlay")) {
                    // Open or update the product overlay
                    await this._openProductOverlay(data);
                    sendPageView();

                    return;
                }

                // 1d. Fallback to reload the page
                // if we are navigating away from PDP
                // since `popstate` event always fires
                // when the user navigates to a history
                // entry that was added via `pushState`
                // which in our case won't change anything
                // except the URL.
                if (data.eventType === "popstate") {
                    globals.window.location.reload();

                    return;
                }

                return;
            } else if (
                feedLayout ||
                isOnHomepage ||
                isOnEditorial ||
                isOnTheEdit ||
                isOnCuratedCollection
            ) {
                // paginating on The Edit page
                if (isOnTheEdit) {
                    return;
                }

                // 2. In feed page context
                // 2a. Trying to navigate to PDP URL or Hash URL
                if (isOpeningProductOnFeed) {
                    // Open or update the product overlay
                    await this._openProductOverlay(data);
                    sendPageView();

                    return;
                }

                // 2b. Closing overlay (going back from PDP URL or Hash URL)
                if (isClosingProductOnFeed) {
                    // Close the product overlay
                    await this._closeProductOverlay(path);

                    // ORG-3513: Send feed page view when coming back from overlay
                    // but only if the page view hasn't been sent
                    const sessionHasFeedPageView = storage.get("hasFeedPageView", false, true);
                    const overlayOnInitialPageLoad = storage.get(
                        "overlayOnInitialPageLoad",
                        false,
                        true
                    );

                    // eslint-disable-next-line max-depth
                    if (!sessionHasFeedPageView && overlayOnInitialPageLoad) {
                        analytics.pageView();
                        storage.set("hasFeedPageView", true, true);
                    }

                    return;
                }

                // 2c. Updating filters on feed
                // only if we are not opening the related products overlay (CW-1980)
                if (data.state.overlay === "related-products-overlay") {
                    return;
                }

                await feedLayout.onFilterUpdate(_, data);

                // EAC-295: Don't send page view events on `Show more` click on Feeds
                if (
                    data.state &&
                    data.oldState &&
                    data.state.pageViewId === data.oldState.pageViewId
                ) {
                    return;
                }

                sendPageView();

                return;
            } else if (
                !feedLayout &&
                !isOnHomepage &&
                !isOnEditorial &&
                !isOnTheEdit &&
                !isOnCuratedCollection
            ) {
                // "this really should never happen"
                logging.error(`Unexpected page transition to ${path}?!`);
                // fallback
                globals.window.location.reload();

                return;
            }
        },
    }
);

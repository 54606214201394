import _extend from "lodash/extend";
import _isString from "lodash/isString";
import analytics from "web/script/analytics/analytics";
import eventEmitter from "web/script/mixins/event-emitter";
import { RequesterError, RothkoError } from "web/script/modules/errors";
import { getRothkoEndpoint } from "web/script/routing/routes";
import logging from "web/script/utils/logging";
import url, { URLQuery } from "web/script/utils/url";
import requester from "./requester";

let cache = new Map<string, any>();

let csrfToken: string | null = null;

/**
 * Creates a cache key from a template name and a query object.
 */
function getCacheKey(name: string, query: URLQuery = {}): string {
    query = url.toURLSearchParams(query);
    query.sort();

    let cacheKey = name + ":";
    for (let [name, value] of query.entries()) {
        cacheKey += `${name}=${value}`;
    }

    return cacheKey;
}

export interface RothkoOptions {
    /** Whether or not to cache the response. */
    cache: boolean;
    signal?: AbortSignal;
}

interface RothkoResponse<T> {
    data: T;
    csrf_token: string;
}

const DEFAULT_OPTIONS: RothkoOptions = {
    cache: true,
};

export class Rothko<T = any> {
    private _templateName: string;
    private _options: RothkoOptions;
    private _requestId = 0;

    /**
     * @param name The name of the template to fetch data for.
     * @param options
     */
    constructor(name: string, options?: RothkoOptions) {
        this._templateName = name;
        this._options = _extend({}, DEFAULT_OPTIONS, options);
    }
    on = eventEmitter.on;
    off = eventEmitter.off;
    trigger = eventEmitter.trigger;

    /**
     * Fetches the data for the template with the given params. Returns a Promise that will
     * resolve with the data returned from the API.
     *
     * If by the time the response has been received no other requests have been started on this
     * instance a 'data' event will be also be fired and if options.cache is true the response
     * will be cached.
     *
     * @param query The query params for this request.
     */
    public fetch(query?: URLQuery, signal?: AbortSignal): Promise<T> {
        // increment the request id so this request will have a new id
        this._requestId++;

        // generate a cache key for this request
        let cacheKey = getCacheKey(this._templateName, query);

        // if this instance is using the cache and we have an entry in the cache
        //  for this cacheKey then resolve without making a network request.
        // Using Promise.resolve() as we still want to guarantee async behaviour.
        if (this._options.cache && cache.has(cacheKey)) {
            return Promise.resolve(cache.get(cacheKey)).then(
                this.handleData.bind(this, this._requestId)
            );
        }

        // otherwise make the API call
        let request = requester(getRothkoEndpoint() + this._templateName + "/", {
            query: query,
            signal,
        });

        return request
            .then(requester.toJSON)
            .then(this.successHandler.bind(this, this._requestId, cacheKey))
            .catch(this.failureHandler.bind(this, query));
    }

    /**
     * Generic success handler for requests to the Rothko API.
     */
    private successHandler(
        requestId: number,
        cacheKey: string,
        responseJSON: RothkoResponse<T>
    ): T {
        let data = responseJSON.data;
        csrfToken = responseJSON.csrf_token;

        if (this._options.cache) {
            cache.set(cacheKey, data);
        }

        return this.handleData(requestId, data);
    }

    private handleData(requestId: number, data: T): T {
        if (requestId === this._requestId) {
            this.trigger("data", data);
        }

        analytics.updatePageViewInAnalyticsCookie();

        return data;
    }

    /**
     * Provides more explicit errors for failed fetches
     */
    private failureHandler(
        query: URLQuery | undefined,
        result: RequesterError | Error
    ): Promise<never> {
        let error = result;
        this.trigger("error", error);

        if (query) {
            if (!_isString(query)) {
                query = url.toQueryString(query);
            }
            query = "?" + query;
        }
        // Data to log in newrelic to help identify the problem
        let data = {
            template: this._templateName,
            query,
            pageViewId: analytics.getPageViewId(),
            domain: window.location.host,
        };

        if (result instanceof RequesterError) {
            let error = new RothkoError(result, this._templateName);
            console.error(
                "Rothko call to %s%s responded with %s!",
                this._templateName,
                query || "",
                error.response.status
            );
        }

        logging.reportError(error, data);
        return Promise.reject(error);
    }
}

/**
 * @param name The name of the template to fetch data for.
 * @param query The query params for this request.
 * @param options Options to pass to the Rothko instance.
 */
function fetch<T = any>(name: string, query?: URLQuery, options?: RothkoOptions): Promise<T> {
    let rothko = new Rothko<T>(name, options);
    return rothko.fetch(query);
}

/**
 * Clears the cache for either a specific template name or all templates.
 */
export function clearCache(name?: string): void {
    if (!name) {
        cache.clear();
        return;
    }

    for (let key of cache.keys()) {
        if (key.startsWith(name + ":")) {
            cache.delete(key);
        }
    }
}

/**
 * Adds an entry to the cache
 */
function addCacheEntry(templateName: string, query: URLQuery | undefined, data: any): void {
    cache.set(getCacheKey(templateName, query), data);
}

function getCSRF(): string | null {
    return csrfToken;
}

export default {
    Rothko,
    fetch,
    clearCache,
    addCacheEntry,
    getCSRF,
};

import clsx from "clsx";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { useClickTracker } from "web/react/hooks/use-click-tracker/use-click-tracker";
import { gettext } from "web/script/modules/django-i18n";
import { BuyOptionPropType } from "web/types/buy-option";
import { ProductPropType } from "web/types/product";
import styles from "./color-picker.module.css";

function Variant({ allSortedBuyOptions, buyOptions, current, onClick, product }) {
    let sortedBuyOptions = allSortedBuyOptions.filter((linkId) =>
        product.link_ids.includes(linkId)
    );
    let buyOption = buyOptions[sortedBuyOptions[0]];
    let inStock = buyOption.in_stock;

    let variantClass = clsx(styles.variant, {
        [styles.oos]: !inStock,
        [styles.current]: current === product.product_id,
    });

    let useLink = inStock && !onClick;
    let trackOnClick = useClickTracker("color_picker", "clicked", "", useLink);

    function changeColor(event) {
        if (inStock) {
            trackOnClick(event);

            if (onClick) {
                event.preventDefault();
                onClick(product.product_id);
            }
        } else {
            event.preventDefault();
        }
    }

    return (
        <li className={variantClass}>
            <a
                onClick={changeColor}
                href={product.canonical_url}
                aria-disabled={!inStock}
                className={clsx({ [styles.oosLink]: !inStock })}
            >
                <img
                    className={styles.img}
                    src={product.images[0].thumbnail_url}
                    alt={product.color}
                />
            </a>
        </li>
    );
}

Variant.propTypes = {
    allSortedBuyOptions: PropTypes.arrayOf(PropTypes.string).isRequired,
    buyOptions: PropTypes.objectOf(BuyOptionPropType).isRequired,
    current: PropTypes.string,
    onClick: PropTypes.func,
    product: ProductPropType.isRequired,
};

function ColorPicker({
    allSortedBuyOptions,
    buyOptions,
    className,
    current,
    onClick,
    products,
    variantLimit,
    variants,
}) {
    let [open, setOpen] = useState(false);
    let variantsToShow = variantLimit;
    if (open) {
        variantsToShow = variants.length;
    }

    return (
        <div className={clsx(className, styles.wrapper)}>
            <ul className={styles.variants}>
                {variants.slice(0, variantsToShow).map((variant) => {
                    let product = products[variant];
                    return (
                        <Variant
                            key={product.product_id}
                            allSortedBuyOptions={allSortedBuyOptions}
                            buyOptions={buyOptions}
                            current={current}
                            onClick={onClick}
                            product={product}
                        />
                    );
                })}
                {variants.length > variantLimit && (
                    <li className={styles.more}>
                        <button className={styles.button} onClick={() => setOpen(!open)}>
                            <span>
                                {open ? gettext("general.see_less") : gettext("general.see_more")}
                            </span>
                        </button>
                    </li>
                )}
            </ul>
        </div>
    );
}

ColorPicker.defaultProps = {
    variantLimit: 10,
};

ColorPicker.propTypes = {
    allSortedBuyOptions: PropTypes.arrayOf(PropTypes.string).isRequired,
    buyOptions: PropTypes.objectOf(BuyOptionPropType).isRequired,
    className: PropTypes.string,
    current: PropTypes.string,
    onClick: PropTypes.func,
    products: PropTypes.objectOf(ProductPropType).isRequired,
    variantLimit: PropTypes.number,
    variants: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default ColorPicker;

import clsx from "clsx";
import React, { MouseEvent } from "react";
import SVGIcon from "web/react/components/svg-icon/svg-icon";
import environment from "web/script/modules/environment";
import styles from "./footer-block.module.css";

interface FooterBlockProps extends ReactComponentProps {
    isOpen: boolean;
    onClick: (event: MouseEvent<HTMLButtonElement>) => void;
    title: string;
    isInteractive?: boolean;
}

export default function FooterBlock({
    title,
    isOpen,
    isInteractive = true,
    onClick,
    children,
    className,
}: FooterBlockProps): JSX.Element {
    return (
        <div
            className={clsx({
                [styles.footerBlock]: isInteractive,
                [styles.plainFooterBlock]: !isInteractive,
                [styles.footerBlockSingle]: environment.get("pageType") === "product",
                className,
            })}
        >
            {isInteractive && (
                <h4 className={styles.footerBlockTitle}>
                    <button type="button" className={styles.footerBlockButton} onClick={onClick}>
                        <span>{title}</span>
                        <SVGIcon name={"plus"} className={styles.plusIcon} />
                    </button>
                </h4>
            )}
            <div
                className={clsx(styles.footerBlockContent, {
                    [styles.open]: isOpen,
                })}
            >
                {children}
            </div>
        </div>
    );
}

FooterBlock.defaultProps = {
    isInteractive: true,
    title: "",
    onClick: (): void => {},
};

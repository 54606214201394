import _reduce from "lodash/reduce";
import ga from "web/script/embedded/universal-analytics";
import environment from "web/script/modules/environment";

export default {
    objectTypes: {
        page_view: "pageview",
        event: "event",
        social: "social",
    },

    init: function () {
        var options = {};
        var userId = environment.get("gaVariables.&uid");

        // giving the user id the GA allows them to track users over multiple devices
        // https://developers.google.com/analytics/devguides/collection/analyticsjs/user-id
        if (userId) {
            options.userId = userId;
        }

        ga("create", environment.get("gaUaAccount"), options);
        ga("require", "displayfeatures");
        ga("require", "ec");
        ga("set", "&cu", environment.get("currencyProps.currencyCode"));
        ga("set", "transport", "beacon");
    },

    send: function (data) {
        return Promise.all(
            _reduce(
                data,
                function (promises, obj) {
                    let type;
                    let options;

                    let gaEcommerceType;
                    let gaEcommerceAction;
                    let gaEcommerceActionField;
                    let gaEcommerceProducts;

                    switch (obj.type) {
                        case "page_view":
                            type = this.objectTypes.page_view;
                            options = {
                                location: obj.data.url,
                            };

                            if (obj.data.gaVariables) {
                                ga("set", obj.data.gaVariables);
                            }

                            if (obj.data.product_ga_ecommerce_data) {
                                gaEcommerceType = obj.data.product_ga_ecommerce_data.type;
                                gaEcommerceAction = obj.data.product_ga_ecommerce_data.action;
                                gaEcommerceProducts = obj.data.product_ga_ecommerce_data.products;
                            }

                            break;

                        case "ecommerce":
                            // pass through all the arguments
                            ga(...obj.data);
                            break;

                        case "event":
                            type = this.objectTypes.event;
                            options = {
                                eventCategory: obj.data.category,
                                eventAction: obj.data.action,
                                eventLabel: obj.data.label,
                                eventValue: obj.data.value,
                                nonInteraction: obj.data.nonInteraction,
                            };
                            if (obj.data.customData) {
                                gaEcommerceType = obj.data.customData.type;
                                gaEcommerceAction = obj.data.customData.action;
                                gaEcommerceActionField = obj.data.customData.actionField;
                                gaEcommerceProducts = obj.data.customData.products;
                            }
                            break;

                        case "social":
                            type = this.objectTypes.social;
                            options = {
                                socialNetwork: obj.data.network,
                                socialAction: obj.data.action,
                                socialTarget: obj.data.target,
                            };
                            break;

                        default:
                            break;
                    }

                    if (type) {
                        promises.push(
                            new Promise(function (resolve) {
                                options.hitCallback = resolve;

                                // we have to resolve the promise when the GA script loading fails
                                // or the script is blocked by extension
                                if (!window.gaGlobal) {
                                    resolve();
                                }

                                if (gaEcommerceType) {
                                    for (let i = 0; i < gaEcommerceProducts.length; i++) {
                                        ga(gaEcommerceType, gaEcommerceProducts[i]);
                                    }

                                    if (gaEcommerceAction && gaEcommerceActionField) {
                                        ga(
                                            "ec:setAction",
                                            gaEcommerceAction,
                                            gaEcommerceActionField
                                        );
                                    } else if (gaEcommerceAction) {
                                        ga("ec:setAction", gaEcommerceAction);
                                    }
                                }

                                ga("send", type, options);
                            })
                        );
                    }

                    return promises;
                }.bind(this),
                []
            )
        );
    },

    getClientId: function () {
        return new Promise(function (resolve) {
            // we have to resolve the promise when the GA script isn't loaded
            if (!window.gaGlobal) {
                resolve();
            }

            ga(function (tracker) {
                resolve(tracker.get("clientId"));
            });
        });
    },
};

import React from "react";
import { HStack } from "web/react/emo/h-stack";
import { View } from "web/react/emo/view";
import * as styles from "./divider.css";

interface DividerProps {
    overlap?: boolean;
}

export function Divider({ overlap = false }: DividerProps): React.ReactElement {
    return (
        <View width="full" marginY={overlap ? "none" : { sm: "md", lg: "lg" }}>
            <HStack dataTestId="divider" justify={"space-between"}>
                <div className={styles.deviceDividerLeft}>
                    <div className={styles.deviceDivider} />
                </div>
                <div className={styles.deviceDividerRight}>
                    <div className={styles.deviceDivider} />
                </div>
            </HStack>
        </View>
    );
}

import loader from "./loader";

window.dataLayer = window.dataLayer || [];

const load = loader(() => {
    let d = document;
    let g = d.createElement("script");
    let s = d.getElementsByTagName("script")[0];
    g.type = "text/javascript";
    g.src = "//www.googletagmanager.com/gtag/js?id=AW-982977399";
    s.parentNode.insertBefore(g, s);
    // gtag expects the object pushed to be an [object Arguments] - 2020/05/14
    (function () {
        window.dataLayer.push(arguments);
    })("js", new Date());
});

export default function () {
    load();
    window.dataLayer.push(arguments);
}

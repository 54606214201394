import { createSlice } from "@reduxjs/toolkit";
import { FeedHeaderProps } from "web/react/components/feed-header/feed-header";

export interface FollowDesignerBannerSlice {
    show: boolean;
    profile: FeedHeaderProps;
    bannerDisable: boolean;
}

const followDesignerBanner = createSlice({
    name: "followDesignerBanner",
    initialState: {
        show: false,
        profile: {},
        bannerDisable: false,
    } as FollowDesignerBannerSlice,
    reducers: {
        triggerBanner(state, action): void {
            state.show = action.payload;
        },
        setDesignerProfile(state, action): void {
            state.profile = action.payload;
        },
        bannerDisabledByUser(state, action): void {
            state.bannerDisable = action.payload;
        },
    },
});

export const { triggerBanner, setDesignerProfile, bannerDisabledByUser } =
    followDesignerBanner.actions;
export default followDesignerBanner.reducer;

import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { PageSource } from "web/react/components/save-for-later-button/save-for-later-button";
import {
    CampaignOptions,
    useKevelCampaignControl,
} from "web/react/hooks/use-kevel-campaign-control/use-kevel-campaign-control";
import withReduxProvider from "web/react/redux-provider";
import { CaptureType, openCustomerCaptureOverlay } from "web/redux/ducks/customer-capture-overlay";
import globals from "web/script/modules/globals";
import { setCampaignHasBeenDismissed } from "web/script/utils/block-modal-campaigns";
import history from "web/script/utils/history";
import navigate from "web/script/utils/navigate";
import storage from "web/script/utils/storage";

interface KevelCampaignControlProps {
    captureType?: CaptureType;
    contextType: string;
    children: React.ReactNode;
    options?: CampaignOptions;
    beforeOnClick?: (event?) => void;
    afterOnClick?: (any?) => void;
    defaultAction: (event) => void;
    analyticsEventLabel: string;
    productImageUrl?: string;
    continueOptionHref?: string;
    appDeepLinkPath?: string;
    productId?: number | string;
    productUrl?: string;
    pageSource?: PageSource;
}

function _KevelCampaignControl({
    captureType,
    contextType,
    children,
    options = {},
    beforeOnClick,
    afterOnClick,
    defaultAction,
    analyticsEventLabel,
    productImageUrl,
    continueOptionHref = "",
    appDeepLinkPath,
    productId,
    productUrl,
    pageSource,
}: KevelCampaignControlProps): JSX.Element {
    const dispatch = useDispatch();
    const [hasBeenBlocked, setHasBeenBlocked] = useState<boolean>(false);
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const {
        campaignDetails,
        onLoad,
        onClick: onCtaClick,
        fetchCampaign,
    } = useKevelCampaignControl(
        "block_modal",
        {
            hasBeenBlocked,
            ...options,
        },
        false,
        contextType
    );

    const {
        componentType,
        captureType: kevelCaptureType,
        titleKey,
        ctaKey,
    } = campaignDetails || {};

    function closeModal(): void {
        setIsOpen(false);

        setCampaignHasBeenDismissed(contextType);
        if (componentType === "sign_up" && continueOptionHref) {
            const url = new URL(continueOptionHref, window.location.href);
            if (url.hash.startsWith("#slug=")) {
                history.pushOverlay("product", { newURL: url });
            } else {
                navigate(url.href);
            }
        }
    }

    function openModal(): void {
        setIsOpen(true);
    }

    function onClick(event): void {
        // we must persist the event so it can be used after the handler has run
        event.preventDefault();
        event.persist?.();

        // run anything, regardless of the campaign decision - useful for sending
        // analytics data related to the wrapped component
        beforeOnClick?.(event);
        fetchCampaign()
            .then((campaignDetailsData) => {
                if (campaignDetailsData) {
                    if (campaignDetailsData?.blockType === "hard_block_after_action") {
                        defaultAction(event);
                        return {
                            blockBeforeAction: false,
                            extraContent: { blockType: "hard_block_after_action" },
                        };
                    } else {
                        openModal();
                        return {
                            blockBeforeAction: true,
                        };
                    }
                }
                defaultAction(event);
                return {};
            })
            .then((data) => {
                if (!data.blockBeforeAction) {
                    afterOnClick?.(data.extraContent || {});
                }
            });
    }

    if (campaignDetails && !hasBeenBlocked) {
        setHasBeenBlocked(true);
        onLoad();
    }

    if (isOpen) {
        const redirectURL =
            storage.get("new_user_to_sign_up", null, true) === "active"
                ? globals.window.location.pathname
                : continueOptionHref || productUrl;

        const touchpointCaptureType: CaptureType | undefined =
            captureType ||
            (pageSource &&
                ((options.isIcon
                    ? "signup_checkout_save"
                    : `signup_${pageSource}_save`) as CaptureType));

        const captureTypeToUse = touchpointCaptureType
            ? touchpointCaptureType
            : (kevelCaptureType as CaptureType);

        dispatch(
            openCustomerCaptureOverlay({
                productId,
                productImageUrl,
                analyticsEventCategory: "membership",
                analyticsEventLabel,
                componentType,
                captureType: captureTypeToUse || "feeds_product_card",
                next: redirectURL,
                appDeeplinkPath: appDeepLinkPath,
                onClose: closeModal,
                onCtaClick: onCtaClick,
                titleKey: titleKey || "",
                ctaKey: ctaKey || "",
            })
        );
    }

    return (
        <div data-testid="campaign-children" onClick={onClick}>
            {children}
        </div>
    );
}

export const KevelCampaignControl = withReduxProvider(_KevelCampaignControl);

import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface SignupSlice {
    open: boolean;
    options: _.Dictionary<any>;
}

const initialState: SignupSlice = { open: false, options: {} };
const signupOverlay = createSlice({
    name: "signupOverlay",
    initialState,
    reducers: {
        openSignupOverlay(state, { payload }: PayloadAction<SignupSlice["options"]>): void {
            state.open = true;
            state.options = payload;
        },
        closeSignupOverlay(state): void {
            state.open = false;
            state.options = {};
        },
    },
});

// Extract the action creators object and the reducer
const { actions, reducer } = signupOverlay;
// Extract and export each action creator by name
export const { openSignupOverlay, closeSignupOverlay } = actions;
// Export the reducer, either as a default or named export
export default reducer;

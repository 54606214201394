import React from "react";
import { connect } from "react-redux";
import FollowStickyBanner from "web/react/components/follow-sticky-banner/follow-sticky-banner";
import withReduxProvider from "web/react/redux-provider";
import { gettext } from "web/script/modules/django-i18n";
import environment from "web/script/modules/environment";
import { languageIsEnglish } from "web/script/modules/language";
import { FeedHeaderSerializer, ProfileHeaderSerializer } from "web/types/serializers";
import * as styles from "./banner-container.css";

interface BannerContainerReduxStateProps {
    showDUBanner: boolean;
    disableDUBanner: boolean;
    feedProfile?: ProfileHeaderSerializer & FeedHeaderSerializer;
}

function mapStateToProps(state): BannerContainerReduxStateProps {
    const {
        profile: feedProfile,
        show,
        bannerDisable: disableDUBanner,
    } = state.followDesignerBannerReducer;

    const showDUBanner = show;

    return { feedProfile, showDUBanner, disableDUBanner };
}

export function BannerContainer({
    feedProfile,
    showDUBanner,
    disableDUBanner,
}: BannerContainerReduxStateProps): JSX.Element {
    const pageSubType = environment.get("pageSubType");

    const isOnDesignerFeed = pageSubType && pageSubType.startsWith("designer");
    const notFollowedText = isOnDesignerFeed
        ? gettext("designer_updates.get_designer_updates_btn_text")
        : gettext("feed.store_updates.cta");

    const bannerEnabled = environment.getFeature("oat_designer_update_banner_enabled");

    return bannerEnabled ? (
        <div className={styles.container}>
            {languageIsEnglish() &&
                !disableDUBanner &&
                showDUBanner &&
                feedProfile &&
                feedProfile.follow_button &&
                feedProfile.follow_button.is_authenticated !== "true" && (
                    <FollowStickyBanner
                        forceDisplay={showDUBanner}
                        lystId={feedProfile.follow_button.lyst_id}
                        isAuthenticated={feedProfile.follow_button.is_authenticated}
                        isFollowing={feedProfile.follow_button.is_following}
                        notFollowedText={notFollowedText}
                        profileImage={feedProfile.small_photo_url}
                        followedText={feedProfile.follow_button.followed_text}
                        designerName={feedProfile.follow_button.designer_name}
                        feedTitle={
                            feedProfile.title ||
                            feedProfile.name ||
                            feedProfile.follow_button.designer_name
                        }
                        productTaxonomy={feedProfile.follow_button.product_taxonomy}
                    />
                )}
        </div>
    ) : (
        <></>
    );
}

export default withReduxProvider(connect(mapStateToProps)(BannerContainer));

import _isUndefined from "lodash/isUndefined";
import _omitBy from "lodash/omitBy";
import React, { useEffect, useRef, useState } from "react";
import analytics from "web/script/analytics/analytics";

import ProductAreaRail from "web/react/components/product-area-rail/product-area-rail";
import { ProductDetails } from "web/react/components/product-card/react/emo";
import ProductCard from "web/react/components/product-card/react/product-card";
import { Rail } from "web/react/components/rail/rail";
import { HStack } from "web/react/emo/h-stack";
import { ProductCardPrice, ProductCardWishlist, ProductImage } from "web/react/emo/product-card";
import { VStack } from "web/react/emo/v-stack";
import { useDomViewport } from "web/react/hooks/use-dom-viewport/use-dom-viewport";
import { useIntersectionObserver } from "web/react/hooks/use-intersection-observer/use-intersection-observer";
import { useRothko } from "web/react/hooks/use-rothko/use-rothko";
import { useFeedContext } from "web/react/pages/feed/feed.context";
import { FeedPage } from "web/react/pages/feed/utils";
import userProfiler from "web/script/modules/userprofiler";
import { canUseMembership } from "web/script/modules/utils";
import { RecentlyViewedSerializer } from "web/types/serializers";
import * as styles from "./recently-viewed.css";

const RECENTLY_VIEWED_ENDPOINT = "modules/recently_viewed";

function getRecentlySeenProductIds(number: number, currentProductId: number | null): number[] {
    // If we're on a product page we're going to exclude
    // the current product from the recently viewed module.
    if (currentProductId) {
        number++;
    }

    const seenProducts = userProfiler.getSeenProducts().slice(-number).reverse();
    const productIds = seenProducts.reduce((memo, product) => {
        if (product.productId !== currentProductId) {
            memo.push(product.productId);
        }

        return memo;
    }, []);

    return productIds;
}

function makeApiParams(recentlyViewedProducts, inOverlay, originFeed): any {
    return _omitBy(
        {
            product_id: recentlyViewedProducts,
            in_overlay: inOverlay,
            origin_feed: originFeed,
        },
        _isUndefined
    );
}

interface RecentlyViewedProps extends RecentlyViewedSerializer {
    feedType?: string;
    isRedesign?: boolean;
}

const analyticsEventLabelType = {
    [FeedPage.WISH_LIST]: "wishlist_recently_viewed",
    default: "recently-viewed-product",
};

export function RecentlyViewed({
    title,
    current_product_id: currentProductId,
    in_overlay: inOverlay,
    origin_feed: originFeed,
    feedType = FeedPage.DEFAULT,
    isRedesign = true,
}: RecentlyViewedProps): React.ReactElement | null {
    const [recentlySeenProductIds, setRecentlySeenProductIds] = useState<number[] | null>(null);
    const { setFeedFetched } = useFeedContext();
    const params = makeApiParams(recentlySeenProductIds, inOverlay, originFeed);

    const analyticsEventLabel =
        analyticsEventLabelType[feedType] || analyticsEventLabelType.default;

    const { data, isFetching, run } = useRothko<RecentlyViewedSerializer>(
        RECENTLY_VIEWED_ENDPOINT,
        params,
        {
            enabled: false,
        }
    );
    const [sentAnalytics, setSentAnalytics] = useState(false);

    const recentlyViewedRef = useRef<HTMLDivElement | null>(null);

    const [isVisible] = useIntersectionObserver(
        {
            rootMargin: "0px 0px 250px 0px",
            once: true,
        },
        recentlyViewedRef as any
    );

    const { isMobileViewport } = useDomViewport();

    // Don't render if we didn't store recently seen products
    const avoidRender =
        (recentlySeenProductIds !== null && !recentlySeenProductIds.length) ||
        !data?.products?.length;

    useEffect(() => {
        if (isVisible) {
            const productLimit = isMobileViewport ? 5 : 10;
            let recentlySeen = getRecentlySeenProductIds(productLimit, currentProductId);
            setRecentlySeenProductIds(recentlySeen);

            if (recentlySeen && recentlySeen.length) {
                setFeedFetched(false);
                run();
            }
            if (!sentAnalytics && !avoidRender) {
                setSentAnalytics(true);
                analytics.event("recently_viewed", "impression", analyticsEventLabel);
            }
        }
    }, [
        isVisible,
        currentProductId,
        avoidRender,
        run,
        data,
        isMobileViewport,
        analyticsEventLabel,
        sentAnalytics,
        setFeedFetched,
    ]);

    const RecentlyViewedRail = isRedesign ? (
        <Rail title={title} analyticsLabel={analyticsEventLabel}>
            {data?.products?.map((product) => {
                return (
                    <ProductCard
                        key={product.uid}
                        className={styles.item}
                        product={product}
                        dataTestId="product-card"
                    >
                        <VStack spacing="xxs">
                            <ProductImage
                                feedType={feedType}
                                analyticsEventLabel={analyticsEventLabel}
                                shouldRenderLeadLinkInImage
                            />
                            <VStack spacing="xxxs">
                                <HStack spacing="none" justify="space-between">
                                    <ProductCardPrice size="sm" />
                                    {canUseMembership() && (
                                        <ProductCardWishlist
                                            isSavedForLater={product.is_saved_for_later}
                                        />
                                    )}
                                </HStack>
                                <ProductDetails
                                    feedType={feedType}
                                    analyticsEventLabel={analyticsEventLabel}
                                />
                            </VStack>
                        </VStack>
                    </ProductCard>
                );
            })}
        </Rail>
    ) : (
        <ProductAreaRail
            analyticsEventLabel={analyticsEventLabel}
            title={title}
            isLoading={isFetching}
            products={data?.products}
        />
    );

    return <div ref={recentlyViewedRef}>{avoidRender ? null : RecentlyViewedRail}</div>;
}

import logging from "web/script/utils/logging";

/**
 * Utility function that translates base64 strings into JSON objects.
 */
export function base64Decode(base64: string): any {
    const jsonString = atob(base64);

    try {
        return JSON.parse(jsonString);
    } catch (e: any) {
        logging.error(e);

        return null;
    }
}

/**
 * Jinja filter that converts an object to a JSON representation,
 * then converts it again into a base64 string.
 * Returns a 'null' string if something goes wrong during the conversion.
 */
export function base64Encode(obj: any): string {
    if (obj === undefined) {
        return "null";
    }

    try {
        const jsonString = JSON.stringify(obj);

        // We need to encode unicode characters as btoa can't handle them
        // TODO: Should we be using a non-broken base64 library instead?
        const encodedString = jsonString
            .split("")
            .map((x: string) => {
                let cp = x.codePointAt(0);
                if (!cp || cp < 256) {
                    return x;
                }
                return "\\u" + ("000" + cp.toString(16)).slice(-4);
            })
            .join("");

        return btoa(encodedString);
    } catch (e: any) {
        logging.error(e);

        return "null";
    }
}

import React, { useEffect } from "react";
import { FeedProductCard } from "web/react/components/feed-grid/feed-grid";
import { KeylineGrid, KeylineGridItem } from "web/react/components/keyline-grid";
import analytics from "web/script/analytics/analytics";
import { getInitialFeedProductIds } from "web/script/utils/factories";
import { ProductFeedSerializer } from "web/types/serializers";
import styles from "./discovery-feed-grid.module.css";

interface FeedImageCardProps {
    title: string;
    imageUrl: string;
}

function FeedImageCard({ title, imageUrl }: FeedImageCardProps): JSX.Element {
    return (
        <div className={styles.imageCardContainer}>
            <img src={imageUrl} alt={title} />
        </div>
    );
}

interface DiscoveryFeedGridProps {
    feedItems: ProductFeedSerializer["feed_items"];
    extendedFeedItems:
        | ProductFeedSerializer["feed_items"]
        | { item_type: string; image_card_url: string }[];
    title: string;
    feedType: string;
}

export function DiscoveryFeedGrid({
    feedItems,
    extendedFeedItems,
    title,
    feedType,
}: DiscoveryFeedGridProps): JSX.Element {
    const productIds = getInitialFeedProductIds(feedItems);
    const products: ProductFeedSerializer["feed_items"] = [];
    extendedFeedItems.map((item) => {
        if (item.item_type === "productCard") {
            products.push(item);
        }
    });
    useEffect(() => {
        analytics.event("feed", "view", "feed_product_ids", true, {
            ids: productIds,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(productIds)]);

    return (
        <KeylineGrid className={styles.discovery}>
            {extendedFeedItems.map((feedItem, i) => {
                if (feedItem.item_type === "productCard") {
                    return (
                        <KeylineGridItem key={i}>
                            <FeedProductCard product={feedItem.product_card} feedType={feedType} />
                        </KeylineGridItem>
                    );
                } else if (feedItem.image_card_url) {
                    return (
                        <KeylineGridItem key={i} colspan={2}>
                            <FeedImageCard
                                title={`${title}-${i}`}
                                imageUrl={feedItem.image_card_url}
                            />
                        </KeylineGridItem>
                    );
                }
            })}
        </KeylineGrid>
    );
}

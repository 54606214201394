/**
 * @file use-feed-fetch
 * @description a hook to refetch product feeds without reloading the DOM.
 */

import pick from "lodash/pick";
import { useRothkoFetcher } from "web/react/hooks/use-rothko-fetcher";
import { useFeedContext } from "web/react/pages/feed/feed.context";
import { setFeedData, setFeedIsFetching } from "web/redux/ducks/feed";
import { setFeedBreadcrumbs } from "web/redux/ducks/feed-breadcrumbs";
import { setFeedCount } from "web/redux/ducks/feed-count";
import { setFeedHeader } from "web/redux/ducks/feed-header";
import { setAll as showMoreSetAll } from "web/redux/ducks/feed-show-more";
import { setLoading as sideBarSetLoading } from "web/redux/ducks/feed-sidebar";
import store from "web/redux/store";
import analytics from "web/script/analytics/analytics";
import { URLQuery, buildLanguageAwareUrlPath } from "web/script/utils/url";
/**
 * @function onGetFeedData
 * @description a function which dispatches state required by feeds.
 * @param {ProductFeedSerializer} data - an object containing the new Product Feed.
 * @returns {void}
 */

function onGetFeedData(data): void {
    if (data.feed_count) {
        store.dispatch(setFeedCount(data.feed_count));
    }

    if (data.feed_navigation) {
        store.dispatch(setFeedBreadcrumbs(data.feed_navigation.shop_breadcrumbs));
    }

    if (data.show_more_button) {
        store.dispatch(showMoreSetAll(data.show_more_button));
    }

    if (data.header) {
        store.dispatch(setFeedHeader(data.header));
    }

    // TODO: we will need this block in the future when we move away from baustein in media/web/script/components/layouts/product-feed-layout.js
    // if (data.more_like_this_rail && data.more_like_this_rail.feed_cards.length > 0) {
    //     store.dispatch(setMoreLikeThis(data.more_like_this_rail));
    // }
    // logging how many rows the user could see, normalised based on desktop
    analytics.event(
        "FEED",
        "POST_FILTER_ROWS",
        Math.min(Math.ceil(data.feed_items.length / 3), 4).toString()
    );

    store.dispatch(
        setFeedData(
            pick(data, "feed_items", "pre_filters", "type", "empty_message", "empty_feed_link")
        )
    );
    store.dispatch(setFeedIsFetching(false));
    store.dispatch(sideBarSetLoading(false));
}

export function useFeedFetch(url = "/account/wishlist/"): {
    fetchFeed: (query?: URLQuery) => void;
} {
    // As wishlisting has been expanded to international users, include the path language in the URL
    // such that the /account/wishlist/ endpoint can be correctly resolved.
    //
    // See ssaw/portal/views/profile.py for where the /account/wishlist/ route is defined.
    let languageAwareUrlPath = url;
    languageAwareUrlPath = buildLanguageAwareUrlPath(url);

    const [fetch] = useRothkoFetcher("modules/product_feed", undefined, false);
    const { setFeedFetched } = useFeedContext();
    const fetchFeed = async (): Promise<void> => {
        store.dispatch(setFeedIsFetching(true));
        store.dispatch(sideBarSetLoading(true));
        setFeedFetched(true);
        const data = await fetch({ url: languageAwareUrlPath });
        if (data) {
            onGetFeedData(data);
        }
    };
    return { fetchFeed };
}

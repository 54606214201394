import _each from "lodash/each";
import googleConversion from "web/script/embedded/google-conversion";
import environment from "web/script/modules/environment";

export default {
    // Google Analytics 4 (GA4) conversion actions
    gA4ConversionMeasurementID: "RCC31DCE8N",
    gA4GroupName: "GA4",

    init: function () {
        googleConversion("config", "G-" + this.gA4ConversionMeasurementID, {
            send_page_view: false,
            groups: this.gA4GroupName,
        });
    },

    send: function (data) {
        // this will contain any promises we generate
        var promises = [];

        let userId = environment.get("userId") || undefined;

        _each(data, (event) => {
            // Send Page View events on all pages apart from Lead page
            if (event.data.page_type !== "lead" && event.type === "page_view") {
                const queryParams = Object.fromEntries(new URLSearchParams(window.location.search));
                let gA4Payload = {
                    send_to: this.gA4GroupName,
                    page_location: window.location.href,
                    page_referrer: document.referrer,
                    page_title: document.title,
                    user_id: userId,
                    content_group: environment.get("pageType"),
                    active_filter: JSON.stringify(queryParams),
                };
                // Not sending to default group but to GA4 specifically
                promises.push(createGtagPromise("page_view", gA4Payload));
            }
            // Send sign up via email event
            if (
                event.data.category === "signup" &&
                event.data.action === "click" &&
                event.data.label === "signup_button"
            ) {
                promises.push(
                    createGtagPromise("sign_up", {
                        send_to: this.gA4GroupName,
                        method: "email",
                        user_id: userId,
                    })
                );
            }
            // Send sign up via social event
            if (event.data.action === "click_social_sign_up") {
                promises.push(
                    createGtagPromise("sign_up", {
                        send_to: this.gA4GroupName,
                        method: event.data.label.split("_")[1],
                        user_id: userId,
                    })
                );
            }
            // Send add_to_cart event on "Buy now" button for checkout products
            if (
                event.data.category === "cart" &&
                event.data.action === "add_item" &&
                event.data.label === "add_to_cart_button"
            ) {
                promises.push(
                    createGtagPromise("add_to_cart", {
                        send_to: this.gA4GroupName,
                        user_id: userId,
                        currency: event.data.customData.currency,
                        value: event.data.customData.price,
                        items: [
                            {
                                item_id: event.data.customData.product_id,
                                item_name: event.data.customData.product_name,
                            },
                        ],
                    })
                );
            }
            // Send add_to_wishlist event
            if (event.data.category === "save_for_later" && event.data.action === "add_item") {
                promises.push(
                    createGtagPromise("add_to_wishlist", {
                        send_to: this.gA4GroupName,
                        user_id: userId,
                        page_source: event.data.label,
                        currency: event.data.customData.currency,
                        value: undefined,
                        items: [
                            {
                                item_id: event.data.customData.product_id,
                                item_name: undefined,
                            },
                        ],
                    })
                );
            }
            // Send select_item event when clicking on a product card
            if (
                event.data.category === "product_card" &&
                ["image", "product", "short description"].includes(event.data.action)
            ) {
                promises.push(
                    createGtagPromise("select_item", {
                        send_to: this.gA4GroupName,
                        user_id: userId,
                        clicked_from: event.data.action,
                        items: [
                            {
                                item_id: undefined,
                                item_name: undefined,
                                designer_name: event.data.customData.designer_name,
                                retailer_name: event.data.customData.retailer_name,
                                in_stock: event.data.customData.in_stock,
                            },
                        ],
                    })
                );
            }
            // Send apply_filter event only when the filters are updated
            if (event.data.category === "filter" && event.data.action === "apply") {
                promises.push(
                    createGtagPromise("filter_apply", {
                        send_to: "GA4",
                        user_id: userId,
                        filter_apply: window.location.search,
                    })
                );
            }
            // Send redirect_to_vendor event
            if (
                ["navigation", "affiliate"].includes(event.data.category) &&
                ["lead", "retailer_bounce", "lead_sold_out"].includes(event.data.action)
            ) {
                promises.push(
                    createGtagPromise("redirect_to_vendor", {
                        send_to: "GA4",
                        user_id: userId,
                        event_category: event.data.category,
                        event_action: event.data.action,
                        affiliate_product_info: event.data.customData,
                    })
                );
            }
            // Send show more button event
            if (
                event.data.category === "feeds_show_more" &&
                event.data.action === "click_show_more_button" &&
                event.data.label === "click"
            ) {
                promises.push(
                    createGtagPromise("feed_show_more_button", {
                        send_to: "GA4",
                        user_id: userId,
                    })
                );
            }
            // Send search bar events
            if (
                event.data.category === "top_nav" &&
                ["search_focused", "search_input"].includes(event.data.action) &&
                event.data.label === "search_bar"
            ) {
                promises.push(
                    createGtagPromise("search_bar", {
                        send_to: "GA4",
                        user_id: userId,
                        action: event.data.action,
                    })
                );
            }
        });

        // wait for any promises what we were asked to wait for
        return Promise.all(promises);
    },
};

function createGtagPromise(event, data) {
    return new Promise((resolve) => {
        // create gtag
        data.event_callback = resolve;
        googleConversion("event", event, data);
    });
}

import _isString from "lodash/isString";
import analytics from "web/script/analytics/analytics";
import globals from "web/script/modules/globals";
import browser from "web/script/utils/browser";

/**
 * Checks if an event is the user trying to open something in a new tab
 */
export function isOpenInNewTab(event: React.MouseEvent): boolean {
    return (browser.mac && event.metaKey) || (!browser.mac && event.ctrlKey);
}

/**
 * A custom hook for tracking analytics events when a link is clicked
 * @param eventCategory The event category
 * @param eventAction The event action
 * @param eventLabel Optional free text label for the event.
 * @param delayPageChange Optional If the click should be delayed until the analytics completes
 * @param eventCustomData Optional information if you need to send customised data.
 * @returns A callback for onClick
 */

export function useClickTracker(
    eventCategory: string | undefined,
    eventAction: string | undefined,
    eventLabel = "",
    delayPageChange = true,
    eventCustomData = {}
): (event: React.MouseEvent<HTMLAnchorElement>) => Promise<void> {
    // TODO: a) shouldn't this be an exception? b) analytics.event already checks this for us
    if (!(_isString(eventCategory) && _isString(eventAction))) {
        return (): Promise<void> => Promise.resolve();
    }

    return function onClick(event): Promise<void> {
        let action = analytics.event(
            eventCategory,
            eventAction,
            eventLabel,
            false,
            eventCustomData
        );
        let openingInNewTab = isOpenInNewTab(event);
        if (delayPageChange && !openingInNewTab) {
            event.preventDefault();
            let href = event.currentTarget.href;
            action = action.then(() => {
                globals.window.location.href = href;
            });
        }
        return action;
    };
}

import React, { Children } from "react";
import styles from "./slide-container.module.css";

interface SlideContainerProps {
    index: number;
    children: React.ReactNode;
}

export function SlideContainer({ index, children }: SlideContainerProps): React.ReactElement {
    return (
        <div
            className={styles.slideContainer}
            style={{ transform: `translateX(${index * 100 * -1}%)` }}
        >
            {Children.map(children, (child) => (
                <div className={styles.slide}>{child}</div>
            ))}
        </div>
    );
}

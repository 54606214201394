import React, { useCallback } from "react";
import { CurvedPill } from "web/react/components/filters-emo/curved-pill";
import * as styles from "web/react/components/rail/rail.css";
import analytics from "web/script/analytics/analytics";
import { gettext } from "web/script/modules/django-i18n";

interface ShowMoreButtonProps {
    showMore: boolean;
    setShowMore: React.Dispatch<React.SetStateAction<boolean>>;
    onClick: () => void;
    analyticsLabel: string;
}

export function ShowMoreButton({
    showMore,
    setShowMore,
    onClick,
    analyticsLabel,
}: ShowMoreButtonProps): JSX.Element {
    const handleShowMoreClick = useCallback((): void => {
        onClick();
        // only send event when we click to more (false -> true)
        !showMore && analytics.event("rail", "show more", analyticsLabel);
        setShowMore(!showMore);
    }, [showMore, setShowMore, onClick, analyticsLabel]);

    return (
        <div className={styles.showMoreButton}>
            <CurvedPill
                onClick={handleShowMoreClick}
                variant={"tertiary"}
                active={false}
                activeHighlighted={false}
                label={showMore ? gettext("general.show_less") : gettext("general.show_more")}
            />
        </div>
    );
}

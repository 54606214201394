import baustein from "baustein";
import template from "templates/modules/email_capture_prompt.jinja";
import { openCustomerCaptureOverlay } from "web/redux/ducks/customer-capture-overlay";
import store from "web/redux/store";

export const selectors = {
    EMAIL_CAPTURE_FORM: ".email-capture-prompt__cta-form",
};

/**
 * @summary this component is in serious need of refactor
 *     @todo make it markup agnostic (so we can use it in any type of HTML element)
 *     @todo prevent the template from spitting any markup (so it can be markup agnostic)
 *     @todo just needs to read clicks no need at all to use a form
 *     @todo abstract `methodMap` so that others component can read it
 *
 * @description Since most types of email capture will be very similar we are using an adapter
 * pattern here instead of having to use if statements in every component method.
 */
export default baustein.register("email-capture-prompt", {
    template,

    setupEvents(add) {
        add("click", ".email-capture-trigger", this.onClick);
    },

    defaultOptions: {
        productId: null,
        captureType: "",
        text: "",
    },

    _render() {
        this.setRenderContext({
            email_capture_prompt: {
                product_id: this.options.productId,
                capture_type: this.options.captureType,
                text: this.options.text,
            },
        });
    },

    onInsert() {
        this._render();
    },

    onClick(event) {
        event.preventDefault();
        store.dispatch(
            openCustomerCaptureOverlay({
                productId: this.options.productId.toString(),
                captureType: this.options.captureType,
            })
        );
    },
});

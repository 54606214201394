import _get from "lodash/get";
import _has from "lodash/has";
import _isPlainObject from "lodash/isPlainObject";
import _merge from "lodash/merge";
import _set from "lodash/set";
import _unset from "lodash/unset";

type KeyPath = string | string[];

export class Environment {
    _currState = {};

    /**
     * Deeply merges the passed object into the environment
     */
    set(data: Record<string, any>): void;
    /**
     * Updates a value
     */
    set(keyPath: KeyPath, value: any): void;
    set(key: KeyPath | Record<string, any>, value?: any): void {
        if (_isPlainObject(key)) {
            _merge(this._currState, key);
        } else {
            _set(this._currState, key as KeyPath, value);
        }
    }

    /**
     * Gets a value.
     */
    get(keyPath: KeyPath, defaultValue: any = null): any {
        return _get(this._currState, keyPath, defaultValue);
    }

    has(keyPath: KeyPath): boolean {
        return _has(this._currState, keyPath);
    }

    /**
     * Deletes a value
     */
    delete(keyPath: KeyPath): void {
        _unset(this._currState, keyPath);
    }

    /**
     * Destroys the environment
     */
    clear(): void {
        this._currState = {};
    }

    /**
     * Gets the value of a feature or variant
     * Note that this does not actually make a request to Heimdall; the server
     * must have set this value in the view's get_frontend_features function.
     * @param string name The name of the feature to check (eg my_feature)
     */
    getFeature(name: string): boolean | string {
        let feature = this.get(["features", name]);
        // If the feature is undefined or null, return false
        if (feature == null) {
            return false;
        }
        return feature;
    }

    /**
     * Checks the current page type against a passed string/string[].
     * @param pageType string|string[] The pageType(s) to check.
     */
    pageTypeIs(pageType: string | string[]): boolean {
        if (typeof pageType === "string") {
            return pageType === this.get("pageType");
        } else {
            return pageType.includes(this.get("pageType"));
        }
    }
}

const environment = new Environment();
export default environment;

declare global {
    interface Window {
        Lyst?: {
            data: {
                features: Record<string, boolean | string> | null;
                pageSubType: string;
                pageType: string;
            } | null;
            environment: {
                publicUrl: string;
                [prop: string]: any;
                sentryDsn: string;
                sentryEnvironment: string;
                country: string;
                release_sha: string;
                currencyProps: {
                    currencyCode: string;
                    currencyDecimalPlaces: number;
                    currencyIndicator: string;
                    currencySymbol: string;
                    priceWithCurrencySymbolFormat: string;
                    priceWithCurrencySymbolFormatInItempropSpans: string;
                };
                language: string;
                userGender: string | null;
                userId: number | null;
                userLoggedIn: boolean;
                userName: string | null;
                userSignedInStatus: string;
                userSlug: string | null;
                pageViewId: string;
                ipAddress: string;
                paidSessionId: string | null;
                isSignUp: boolean;
            };
        };
        // OneTrust globals
        OneTrust?: {
            [prop: string]: any;
        };
    }
}
// seed environment with data passed from server
if (window.Lyst?.data) {
    environment.set(window.Lyst.data);
}
if (window.Lyst?.environment) {
    environment.set(window.Lyst.environment);
}

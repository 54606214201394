import clsx from "clsx";
import PropTypes from "prop-types";
import React, { useCallback, useEffect, useRef, useState } from "react";
import SVGIcon from "web/react/components/svg-icon/svg-icon";
import TrackedButton from "web/react/components/tracked-button/tracked-button";
import analytics from "web/script/analytics/analytics";
import { gettext } from "web/script/modules/django-i18n";
import globals from "web/script/modules/globals";
import browser from "web/script/utils/browser";
import styles from "./rail.module.css";

function Rail({
    analyticsLabel,
    children,
    className,
    peekingCard,
    railClassName,
    seeAllLink,
    sendAnalytics,
    smallRail,
    title = "",
    titlePosition,
    leftButtonStyle,
    rightButtonStyle,
    buttonIcon,
    id,
}) {
    let railRef = useRef(null);
    let [shouldShowArrows, setShouldShowArrows] = useState(false);
    let [firstInteraction, setFirstInteraction] = useState(false);
    let [hideLeftArrow, setHideLeftArrow] = useState(true);
    let [hideRightArrow, setHideRightArrow] = useState(true);
    let headerClasses = clsx({
        [styles.smallHeader]: smallRail,
        [styles.defaultHeader]: !smallRail,
        [styles.center]: titlePosition == "center",
    });

    let moreLabel = gettext("general.see_all");
    const showTitle = title == "" ? false : true;

    const setArrowVisibility = useCallback(() => {
        let currentRef = railRef.current;
        setHideLeftArrow(currentRef.scrollLeft === 0);
        if (
            currentRef.scrollLeft + currentRef.offsetWidth === currentRef.scrollWidth ||
            currentRef.scrollWidth === currentRef.offsetWidth
        ) {
            setHideRightArrow(true);
        } else {
            setHideRightArrow(false);
        }
    }, []);

    function scrollInteraction() {
        if (sendAnalytics && !firstInteraction) {
            setFirstInteraction(true);
            analytics.event("rail", "scroll", analyticsLabel);
        }
        setArrowVisibility();
    }

    useEffect(() => {
        if (shouldShowArrows) {
            // Only do this on desktop ...
            globals.window.addEventListener("resize", setArrowVisibility);
            return () => {
                globals.window.removeEventListener("resize", setArrowVisibility);
            };
        }
    }, [setArrowVisibility, shouldShowArrows]);

    useEffect(() => {
        setArrowVisibility();
    });

    useEffect(() => {
        setShouldShowArrows(!browser.hasTouch);
    }, []);

    function move(direction) {
        let currentRef = railRef.current;
        let width = currentRef.offsetWidth;

        if (sendAnalytics && !firstInteraction) {
            setFirstInteraction(true);
            analytics.event("rail", `click ${direction}`, analyticsLabel);
        }

        let scrollAmount;
        if (direction === "left") {
            scrollAmount = -(width / 1.5);
        } else if (direction === "right") {
            scrollAmount = width / 1.5;
        }
        browser.animate(currentRef, "scrollLeft", scrollAmount, 300);
    }

    return (
        <div className={className}>
            {showTitle && (
                <div className={headerClasses}>
                    <h2 className={smallRail ? styles.smallTitle : styles.defaultTitle}>{title}</h2>
                    {seeAllLink && (
                        <TrackedButton
                            href={seeAllLink}
                            eventCategory="rail"
                            eventAction="click see all"
                            eventLabel={analyticsLabel}
                            className={styles.seeAll}
                        >
                            <span className={styles.seeAllText}>{moreLabel}</span>
                            <SVGIcon className={styles.seeAllChevron} name="round-chevron" />
                        </TrackedButton>
                    )}
                </div>
            )}
            <div className={styles.content}>
                <ol
                    ref={railRef}
                    className={clsx(styles.rail, railClassName)}
                    onScroll={scrollInteraction}
                    id={id}
                >
                    {React.Children.map(children, (child) => {
                        return <li className={styles.listItem}>{child}</li>;
                    })}
                    {seeAllLink && (
                        <li className={clsx(styles.lastCardWrapper, styles.listItem)}>
                            <TrackedButton
                                href={seeAllLink}
                                eventCategory="rail"
                                eventAction="click see all"
                                eventLabel={analyticsLabel}
                                className={clsx(styles.lastCard)}
                            >
                                <span className={styles.seeAll}>
                                    {moreLabel}
                                    <SVGIcon
                                        className={styles.seeAllChevron}
                                        name="round-chevron"
                                    />
                                </span>
                            </TrackedButton>
                        </li>
                    )}
                </ol>

                {shouldShowArrows && !hideLeftArrow && (
                    <div className={peekingCard ? styles.leftButtonWrapper : null}>
                        <button
                            type="button"
                            className={clsx(styles.arrow, styles.leftArrow, leftButtonStyle, {
                                [styles.smallArrow]: smallRail,
                                [styles.largeArrow]: !smallRail,
                                [styles.peeking]: peekingCard,
                            })}
                            onClick={() => move("left")}
                        >
                            <SVGIcon name={buttonIcon} rotate={180} />
                        </button>
                    </div>
                )}
                {shouldShowArrows && !hideRightArrow && (
                    <div className={peekingCard ? styles.rightButtonWrapper : null}>
                        <button
                            type="button"
                            className={clsx(styles.arrow, styles.rightArrow, rightButtonStyle, {
                                [styles.smallArrow]: smallRail,
                                [styles.largeArrow]: !smallRail,
                                [styles.peeking]: peekingCard,
                            })}
                            onClick={() => move("right")}
                        >
                            <SVGIcon name={buttonIcon} />
                        </button>
                    </div>
                )}
            </div>
        </div>
    );
}

Rail.defaultProps = {
    peekingCard: false,
    sendAnalytics: true,
    smallRail: false,
    buttonIcon: "square-chevron-thin",
};

Rail.propTypes = {
    analyticsLabel: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
    peekingCard: PropTypes.bool,
    railClassName: PropTypes.string,
    seeAllLink: PropTypes.string,
    sendAnalytics: PropTypes.bool,
    smallRail: PropTypes.bool,
    title: PropTypes.string,
    titlePosition: PropTypes.string,
    leftButtonStyle: PropTypes.string,
    rightButtonStyle: PropTypes.string,
    buttonIcon: PropTypes.string,
    id: PropTypes.string,
};

export default Rail;

import React from "react";
import { ProductRetailersSerializer } from "web/types/serializers";
import styles from "./product-retailers.module.css";

function ProductRetailers({
    previously_sold_at_retailers_html: previouslySoldAtRetailersHtml,
}: ProductRetailersSerializer): JSX.Element | null {
    if (!previouslySoldAtRetailersHtml) {
        return null;
    }

    return (
        <div
            className={styles.productRetailers}
            dangerouslySetInnerHTML={{ __html: previouslySoldAtRetailersHtml }}
        />
    );
}

export default ProductRetailers;

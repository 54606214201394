import PropTypes from "prop-types";
import React from "react";
import SVGIcon from "web/react/components/svg-icon/svg-icon";
import style from "./burger-menu-item.module.css";

function BurgerMenuItem({ renderText, renderChevron, href, onClickEvent }) {
    return (
        <li className={style.menuItem}>
            <a className={style.menuItemContent} href={href} onClick={onClickEvent}>
                {renderText}
                {renderChevron && <SVGIcon className={style.menuItemChevron} name="arrow-icon" />}
            </a>
        </li>
    );
}

export default BurgerMenuItem;

BurgerMenuItem.propTypes = {
    renderText: PropTypes.string.isRequired,
    renderChevron: PropTypes.bool,
    href: PropTypes.string,
    onClickEvent: PropTypes.func,
};

BurgerMenuItem.defaultProps = {
    renderText: "",
    renderChevron: false,
    href: null,
};

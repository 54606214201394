import { useEffect } from "react";
import { useDomViewport } from "web/react/hooks/use-dom-viewport/use-dom-viewport";
import analytics from "web/script/analytics/analytics";
import environment from "web/script/modules/environment";

type CookieType = "essential" | "analytics" | "customization" | "advertisement";

export interface UseThirdPartyScriptArgs {
    cookieType: CookieType;
    sourceUrl: string;
}

// Load third party scripts on the client side
export function useThirdPartyScript({ cookieType, sourceUrl }: UseThirdPartyScriptArgs): boolean {
    const { isDomLoaded } = useDomViewport();
    const cookies = analytics.cookieConsent;

    const isThirdPartyJsDisabled = environment.get("disable_third_party_js") === true;
    const shouldLoadScript =
        !isThirdPartyJsDisabled && (cookieType === "essential" || cookies[cookieType]);

    // Load the script if it is not already loaded
    useEffect(() => {
        if (shouldLoadScript && !isScriptAlreadyLoaded(sourceUrl)) {
            const script = document.createElement("script");
            script.async = true;
            script.src = sourceUrl;

            document.head.appendChild(script);

            return () => {
                document.head.removeChild(script);
            };
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isDomLoaded]);

    return !isThirdPartyJsDisabled && isDomLoaded && isScriptAlreadyLoaded(sourceUrl);
}

// Function to check if the script is already loaded
function isScriptAlreadyLoaded(sourceUrl: string): boolean {
    const scripts = document.getElementsByTagName("script");
    for (let i = scripts.length; i--; ) {
        if (scripts[i].src.includes(sourceUrl)) {
            return true;
        }
    }
    return false;
}

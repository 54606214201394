import loadable from "@loadable/component";
import React, { useRef } from "react";
import { FocusOn } from "react-focus-on";
import ReactModal from "react-modal";

export const Modal = loadable(
    () =>
        import(
            /* webpackChunkName: "react-modal" */
            "react-modal"
        )
);

interface LazyModalProps extends ReactModal.Props {
    children: React.ReactNode;
    noScroll?: boolean;
}

function LazyModal({
    isOpen,
    noScroll = true,
    children,
    ...props
}: LazyModalProps): React.ReactElement | null {
    const contentRef = useRef<HTMLDivElement>(null);

    return (
        <Modal {...props} isOpen={isOpen}>
            <FocusOn enabled={noScroll}>
                <div
                    ref={contentRef}
                    onTouchMove={(event) => {
                        // Avoid the propagation of the scrolling event to the body
                        event.stopPropagation();
                    }}
                >
                    {children}
                </div>
            </FocusOn>
        </Modal>
    );
}

export default LazyModal;

import { useEffect, useState } from "react";
import { BREAKPOINTS } from "web/react/constants";

function getClientWidth(): number {
    // Note that Hypernova will ensure `window` is always `{}`, so we can
    //  procede assuming it exists but none of its properties will.
    if (window.hasOwnProperty("document")) {
        return window.document.body.clientWidth || BREAKPOINTS.ExtraSmall;
    }

    return BREAKPOINTS.ExtraSmall;
}

/**
 * Returns how wide the user's screen is.
 * On Hypernova, it will always return a mobile screensize
 */
export function useClientWidth(): number {
    let [clientWidth, setClientWidth] = useState(getClientWidth());

    useEffect(() => {
        function onResize(): void {
            setClientWidth(getClientWidth());
        }

        window.addEventListener("resize", onResize);
        return (): void => {
            window.removeEventListener("resize", onResize);
        };
    }, []);

    return clientWidth;
}

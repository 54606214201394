import React from "react";
import { Heading } from "web/react/emo/heading";
import { Text } from "web/react/emo/text/text";
import { VStack } from "web/react/emo/v-stack";
import { View } from "web/react/emo/view";
import styles from "web/react/pages/account/reset-password-pages/reset-password-pages.module.css";
import { gettext } from "web/script/modules/django-i18n";

export default function PasswordResetLinkFailedPage(): JSX.Element {
    return (
        <>
            <View
                className={styles.wrapper}
                paddingTop={{ sm: "lg", xl: "xxl" }}
                paddingBottom={{ sm: "lg", xl: "xxl" }}
                paddingRight={{ sm: "sm" }}
                paddingLeft={{ sm: "sm" }}
            >
                <VStack spacing="md">
                    <Heading textStyle={{ lg: "large-title", sm: "large-title-2" }} as="h1">
                        {gettext("account.password_reset_confirmation_failed.title")}
                    </Heading>
                    <Text textStyle={"body-1"}>
                        {gettext("account.password_reset_confirmation_failed.desc")}
                    </Text>
                </VStack>
            </View>
        </>
    );
}

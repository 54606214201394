import { getLanguage } from "web/script/modules/language";
import { getUrl } from "web/script/routing/getUrl";

function urlFetcher<T>(name: string, transform: (url: string) => T): () => T {
    let savedLanguage: undefined | string;
    let transformed: T | undefined;
    return (): T => {
        let currentLanguage = getLanguage();
        if (savedLanguage != currentLanguage || !transformed) {
            let url = getUrl(name);
            if (!url) {
                throw new Error(`URL ${name} does not exist in ${currentLanguage}!`);
            }
            savedLanguage = currentLanguage;
            transformed = transform(url);
        }
        return transformed;
    };
}
// Tests
export { urlFetcher as _urlFetcher };

export const getProductPageRegex = urlFetcher("productPageRegex", (url: string) => new RegExp(url));
export const getSearchPageRegex = urlFetcher(
    "searchPage",
    (url: string): RegExp => new RegExp(`^/?${url}/$`)
);
export const getSignupPageRegex = urlFetcher(
    "signupPageRegex",
    (url: string): RegExp => new RegExp(url)
);

export function getSearchPageURL(): string {
    return `/${getUrl("searchPage")}/`;
}

export function getRothkoEndpoint(): string {
    // This code assumes we will never not have a rothko endpoint.
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return getUrl("rothkoEndpoint")!;
}

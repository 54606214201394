import loadable from "@loadable/component";
import React, { useState } from "react";
import { Settings as SlickSettings } from "react-slick";
import { ProductGalleryCarousel as Carousel } from "web/react/components/product-gallery/product-gallery-carousel/product-gallery-carousel";
import { BREAKPOINTS } from "web/react/constants";
import { useDomViewport } from "web/react/hooks/use-dom-viewport/use-dom-viewport";
import { ProductCardSerializer } from "web/types/serializers";

const FullscreenGallery = loadable(
    () =>
        import(
            /* webpackChunkName: "react-fullscreen-gallery" */ "web/react/components/product-gallery/product-gallery-fullscreen/product-gallery-fullscreen"
        )
);

interface ProductGalleryProps {
    images: ProductImage[];
    relatedProducts?: ProductCardSerializer[];
    currentLinkId?: string;
    hasRelatedProductsSidebar: boolean;
    variant?: "oos-carousel";
}

function ProductGallery({
    images,
    relatedProducts,
    currentLinkId,
    hasRelatedProductsSidebar,
    variant,
}: ProductGalleryProps): React.ReactElement {
    const { isTabletViewport } = useDomViewport();
    const [fullscreen, setFullscreen] = useState(false);
    const [currentSlideIndex, setCurrentSlideIndex] = useState(0);
    const oosCarouselVariant = variant === "oos-carousel";

    function openFullscreenGallery(index): void {
        setCurrentSlideIndex(index);
        setFullscreen(true);
    }

    function handleImageClick(index): void {
        openFullscreenGallery(index);
    }

    // Slick settings and props for the gallery
    const baseGallerySettings: SlickSettings = {
        speed: 0,
        initialSlide: 0,
        arrows: false,
        dots: images.length > 1,
        // The progressive option loads the first image on page load and the other images are loaded afterwards
        lazyLoad: "progressive",
        centerMode: false,
        slidesToShow: 1,
        infinite: false,
        variableWidth: false,
        swipe: false,
        touchMove: false,
        draggable: false,
        responsive: [
            {
                breakpoint: BREAKPOINTS.Medium,
                settings: {
                    speed: 200,
                    lazyLoad: "ondemand",
                    slidesToShow: images.length > 1 ? 1.3 : 1,
                    infinite: true,
                    variableWidth: true,
                    swipe: true,
                    touchMove: true,
                    touchThreshold: 20,
                    draggable: true,
                },
            },
        ],
    };

    const oosCarouselSettings: SlickSettings = {
        speed: 200,
        arrows: false,
        slidesToShow: images.length > 1 ? 2 : 1,
        infinite: false,
        initialSlide: 0,
        dots: images.length > 1,
        // The progressive option loads the first image on page load and the other images are loaded afterwards
        lazyLoad: "progressive",
        centerMode: false,
        variableWidth: false,
        swipe: false,
        touchMove: false,
        draggable: false,
        responsive: [
            {
                breakpoint: BREAKPOINTS.Medium,
                settings: {
                    speed: 200,
                    lazyLoad: "ondemand",
                    slidesToShow: images.length > 1 ? 1.3 : 1,
                    arrows: false,
                    infinite: true,
                    variableWidth: true,
                    swipe: true,
                    touchMove: true,
                    touchThreshold: 20,
                    draggable: true,
                },
            },
        ],
    };

    const gallerySettings =
        oosCarouselVariant && isTabletViewport
            ? { ...baseGallerySettings, ...oosCarouselSettings }
            : baseGallerySettings;

    const galleryProps = {
        images: images,
        relatedProducts: relatedProducts,
        handleImageClick: (index): void => handleImageClick(index),
        openFullscreenGallery: (index): void => openFullscreenGallery(index),
        settings: gallerySettings,
        currentLinkId: currentLinkId,
        hasRelatedProductsSidebar: hasRelatedProductsSidebar,
        variant: variant,
    };

    // Slick settings and props for the fullscreen gallery
    const fullscreenGallerySettings = {
        speed: 400,
        initialSlide: currentSlideIndex,
        slidesToShow: 1,
        dots: images.length > 1,
        // The progressive option loads the first image on page load and the other images are loaded afterwards
        lazyLoad: false,
        centerMode: false,
        touchThreshold: 20,
        infinite: false,
        swipeToSlide: false,
        arrows: true,
        swipe: false,
        touchMove: false,
        draggable: false,
        responsive: [
            {
                breakpoint: BREAKPOINTS.Medium,
                settings: {
                    speed: 200,
                    lazyLoad: "progressive",
                    arrows: false,
                    swipeToSlide: true,
                    swipe: true,
                    touchMove: true,
                    draggable: true,
                },
            },
        ],
    };

    const fullscreenGalleryProps = {
        isOpen: fullscreen,
        onClose: (): void => setFullscreen(false),
        images: images,
        relatedProducts: relatedProducts,
        currentLinkId: currentLinkId,
        settings: fullscreenGallerySettings,
    };

    return (
        <>
            <Carousel {...galleryProps} />
            {fullscreen ? <FullscreenGallery {...fullscreenGalleryProps} /> : null}
        </>
    );
}

export default ProductGallery;

ProductGallery.displayName = "ProductGallery";

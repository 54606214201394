import PropTypes from "prop-types";
import { MoneyPropType } from "./money";

export const ShippingOptionPropType = PropTypes.shape({
    id: PropTypes.string.isRequired,
    html_message: PropTypes.string.isRequired,
    formatted_price: PropTypes.string.isRequired,
    is_free: PropTypes.bool.isRequired,
    is_guaranteed: PropTypes.bool.isRequired,
    has_promo_code: PropTypes.bool.isRequired,
    terms_url: PropTypes.string,
});

export const ReturnsPropType = PropTypes.shape({
    is_free: PropTypes.bool.isRequired,
});

export const PromotionPropType = PropTypes.shape({
    html_message: PropTypes.string.isRequired,
    url: PropTypes.string,
    terms_url: PropTypes.string,
});

export const SizePropType = PropTypes.shape({
    size_key: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    stock_status: PropTypes.number,
    sale_status: PropTypes.number,
    price: MoneyPropType.isRequired,
    sale_price: MoneyPropType.isRequired,
    formatted_price: PropTypes.string.isRequired,
    formatted_sale_price: PropTypes.string.isRequired,
    is_on_sale: PropTypes.bool.isRequired,
    shipping_info: PropTypes.shape({
        cheapest: ShippingOptionPropType.isRequired,
        cheapest_guaranteed: ShippingOptionPropType,
    }),
    promotion: PromotionPropType,
    lead_url: PropTypes.string.isRequired,
    returns: ReturnsPropType,
});

export const BuyOptionPropType = PropTypes.shape({
    link_id: PropTypes.string.isRequired,
    product_id: PropTypes.string.isRequired,
    in_stock: PropTypes.bool.isRequired,

    date_updated: PropTypes.string.isRequired,
    date_updated_human: PropTypes.string.isRequired,

    oos_since: PropTypes.string,
    oos_since_human: PropTypes.string,

    is_amazon: PropTypes.bool.isRequired,

    sizes: PropTypes.objectOf(SizePropType).isRequired,
    default_size: PropTypes.string.isRequired,

    retailer_name: PropTypes.string.isRequired,
    retailer_slug: PropTypes.string.isRequired,
});

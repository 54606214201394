import clsx from "clsx";
import React from "react";
import LazyImage from "web/react/components/lazy-load-image/lazy-load-image";
import analytics from "web/script/analytics/analytics";
import { useProductCardContext } from "./product-card";
import styles from "./product-card-image.module.css";
import { ProductCardLink } from "./product-card-link";

interface ProductCardImageProps {
    width: number;
    height: number;
    children?: React.ReactNode;
    feedType?: string;
    lazyLoad?: boolean;
    analyticsCategory?: string;
    analyticsEventLabel?: string;
}

function ProductCardImageWrapper({
    width,
    height,
    children,
    feedType,
    lazyLoad = true,
    analyticsCategory = "product_card",
    analyticsEventLabel,
}: ProductCardImageProps): React.ReactElement {
    return (
        <div className={styles.imageWrapper}>
            {children}
            <ProductCardImage
                width={width}
                height={height}
                lazyLoad={lazyLoad}
                feedType={feedType}
                analyticsCategory={analyticsCategory}
                analyticsEventLabel={analyticsEventLabel}
            />
        </div>
    );
}

export function ProductCardImage({
    width,
    height,
    lazyLoad = true,
    feedType,
    analyticsCategory = "product_card",
    analyticsEventLabel,
}: ProductCardImageProps): React.ReactElement {
    const {
        use_lazy_images: useLazyImages,
        image_url: imageURL,
        image_alt_text: imageAltText,
        context_type: contextType,
        id: productId,
    } = useProductCardContext();

    const displayedImage = imageURL || "";
    const shouldRenderLeadLinkInImage = ![
        "homepage",
        "trend-homepage",
        "sales-landing-page",
    ].includes(contextType || "");

    function handleClick(): void {
        analyticsEventLabel
            ? analytics.event(analyticsCategory, "image", analyticsEventLabel, false, {
                  product_id: productId,
              })
            : analytics.event(analyticsCategory, "image", "", false, {
                  product_id: productId,
              });
    }

    if (!imageURL) {
        return (
            <div style={{ position: "relative", width, height }}>
                <ProductCardLink
                    className={styles.imageLink}
                    reason="image"
                    predicate={shouldRenderLeadLinkInImage}
                    onClick={handleClick}
                    feedType={feedType}
                />
            </div>
        );
    }

    const productCardImageComponent = (
        <>
            {useLazyImages && lazyLoad ? (
                <LazyImage
                    src={displayedImage}
                    alt={imageAltText}
                    width={width}
                    height={height}
                    placeholder={`data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 ${width} ${height}"3E%3C/svg%3E`}
                    className={clsx(styles.image)}
                    data-pin-no-hover="true"
                />
            ) : (
                <img
                    width={width}
                    height={height}
                    src={displayedImage}
                    alt={imageAltText}
                    className={clsx(styles.image)}
                    data-pin-no-hover="true"
                />
            )}
        </>
    );

    return (
        <ProductCardLink
            reason="image"
            predicate={shouldRenderLeadLinkInImage}
            onClick={handleClick}
            feedType={feedType}
        >
            {productCardImageComponent}
        </ProductCardLink>
    );
}

export default ProductCardImageWrapper;

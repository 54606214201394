import clsx from "clsx";
import React, { LiHTMLAttributes, ReactNode } from "react";
import { Conditional } from "web/react/components/conditional";
import SVGIcon from "web/react/components/svg-icon/svg-icon";
import { alignToFlex } from "web/react/emo/shared/components/stack";
import {
    ResponsiveTextStyle,
    ResponsiveValue,
    mapResponsiveValue,
    sprinkles,
} from "web/react/emo/sprinkles.css";
import { Text } from "web/react/emo/text";
import { textColors } from "web/react/emo/theme.css";
import * as styles from "./list-item.css";

type ListChildItem = LiHTMLAttributes<HTMLLIElement> &
    ({ as: "a"; href: string } | { as?: "span"; href?: never } | { as: "button"; href?: string });

type ListItemWishLabel = {
    label: string;
    note?: string;
    children?: never;
};

type ListItemWishChildren = {
    label?: never;
    note?: never;
    children?: ReactNode;
};

export type ListItemProps = (ListItemWishLabel | ListItemWishChildren) &
    ListChildItem & {
        size?: keyof typeof styles.wrapper;
        variant?: keyof typeof styles.listItem;
        showChevron?: boolean;
        flexWrapperHeight?: boolean;
        textStyle?: ResponsiveTextStyle;
        textColor?: keyof typeof textColors;
        align?: ResponsiveValue<keyof typeof alignToFlex>;
        labelClassName?: string;
    };

/**
 * Design System
 * @name ListItem
 * @version 1.0
 * @design https://www.figma.com/file/VhOHx4tv9uYXZHXAAKPVYf/Design-System?type=design&node-id=3481-30566&t=sJmKH2VN0OACQRby-4
 */
export function ListItem({
    as: Component = "span",
    size = "sm",
    variant = "secondary",
    label,
    note,
    showChevron,
    children,
    href,
    className,
    labelClassName,
    flexWrapperHeight = false,
    textStyle = "body-1",
    textColor = "primary",
    align = "center",
    ...props
}: ListItemProps): JSX.Element {
    return (
        <li {...props} className={clsx(styles.listItem[variant], className)}>
            <Component
                className={clsx(
                    sprinkles({
                        alignItems:
                            align && mapResponsiveValue(align, (value) => alignToFlex[value]),
                    }),
                    styles.wrapper[size],
                    {
                        [styles.underline]: size === "lg" && !note,
                        [styles.flexibleHeight]: flexWrapperHeight,
                    }
                )}
                {...(href && { href })}
            >
                {children}
                <Text
                    as={"span"}
                    textStyle={textStyle}
                    color={textColor}
                    className={labelClassName ? labelClassName : styles.title}
                    truncate
                >
                    {label}
                </Text>
                <Conditional check={Boolean(note)}>
                    <Text as={"span"} textStyle={textStyle} color={"secondary"} truncate>
                        {`(${note})`}
                    </Text>
                </Conditional>
                <Conditional check={Boolean(showChevron)}>
                    <Text as={"span"} textStyle={textStyle} className={styles.iconWrapper}>
                        <SVGIcon name={"chevron-medium"} />
                    </Text>
                </Conditional>
            </Component>
        </li>
    );
}

import baustein from "baustein";
import { ReactTooltip } from "web/react/components/tooltip/tooltip";
import renderReactComponentInDocument from "web/script/utils/render-react";

export default baustein.register("react-tooltip", {
    defaultOptions: {
        direction: "bottom",
        showArrow: true,
        autoShow: false,
        autoHide: false,
    },

    onInsert() {
        renderReactComponentInDocument(
            ReactTooltip,
            {
                ...this.options,
                children: this.el.innerHTML,
            },
            this.el
        );
    },
});

import clsx from "clsx";
import React, { Children, useState } from "react";
import TabSwitcherButton from "./tab-switcher-button";
import TabSwitcherHeader from "./tab-switcher-header";
import styles from "./tab-switcher.module.css";

interface TabSwitcherContextType {
    currentTabIndex: number;
    setCurrentTabIndex: React.Dispatch<React.SetStateAction<number>>;
}

const TabSwitcherContext = React.createContext<TabSwitcherContextType | undefined>(undefined);

export function useTabSwitcherContext(): TabSwitcherContextType {
    const context = React.useContext(TabSwitcherContext);

    if (!context) {
        throw new Error(`useTabSwitcherContext must be used within a TabSwitcherContext.Provider`);
    }

    return context;
}

interface TabSwitcherProps {
    children: React.ReactNode;
    initialTabIndex?: number;
    className?: string;
    // By default, we are rendering the content of all the Tabs at once, because of SEO reasons (crawlers need content).
    // If you want to lazy load the content (render the content of the active Tab only),
    // then you can just pass `lazyLoad` prop.
    lazyLoad?: boolean;
}

function TabSwitcher({
    children,
    initialTabIndex,
    className,
    lazyLoad,
}: TabSwitcherProps): React.ReactElement {
    const [currentTabIndex, setCurrentTabIndex] = useState(initialTabIndex || 0);
    const childrenArr = Children.toArray(children);

    const tabs = children ? children[0] : null;
    const [, ...sections] = childrenArr;

    if (!tabs || tabs.type !== TabSwitcherHeader) {
        throw "You must provide TabSwitcherHeader as the first child";
    }

    if (tabs.props.children.length !== childrenArr.length - 1) {
        throw "You must provide equal number of tabs and child components";
    }

    return (
        <TabSwitcherContext.Provider value={{ currentTabIndex, setCurrentTabIndex }}>
            {tabs}

            {sections.map((section, index) => (
                <div
                    key={index}
                    className={clsx(styles.tabContent, className, {
                        [styles.tabContentActive]: currentTabIndex === index,
                    })}
                    id={`tabpanel-${index}`}
                    aria-labelledby={`tabpanel-${index}`}
                    tabIndex={index}
                    role="tabpanel"
                >
                    {(!lazyLoad || currentTabIndex === index) && section}
                </div>
            ))}
        </TabSwitcherContext.Provider>
    );
}

TabSwitcher.Header = TabSwitcherHeader;
TabSwitcher.Tab = TabSwitcherButton;

export default TabSwitcher;

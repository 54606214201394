import clsx from "clsx";
import React, { useEffect } from "react";
import { Button } from "web/react/emo/button";
import { Heading } from "web/react/emo/heading";
import { Text } from "web/react/emo/text";
import { VStack } from "web/react/emo/v-stack";
import { View } from "web/react/emo/view";
import { FeedPage } from "web/react/pages/feed/utils";
import analytics from "web/script/analytics/analytics";
import { gettext } from "web/script/modules/django-i18n";
import { ProductFeedSerializer } from "web/types/serializers";
import styles from "./feed-no-results.module.css";

interface FeedNoResultsProps {
    feedType?: FeedPage;
    data?: Pick<ProductFeedSerializer, "empty_feed_link" | "empty_message">;
}

export function FeedNoResults({ feedType, data }: FeedNoResultsProps): JSX.Element {
    useEffect(() => {
        analytics.event("ENGAGEMENT", "FEED_END", "EMPTY_FEED_LANDING", true);
    }, []);

    return feedType === FeedPage.WISH_LIST ? (
        <View marginBottom="lg" className={clsx(styles.wrapper)}>
            <VStack spacing={"sm"} justify="center" align="center">
                {data?.empty_message && (
                    <Heading textStyle="title-2" as="h2">
                        {data.empty_message}
                    </Heading>
                )}

                <Text textStyle={"caption-1"} color={"secondary"}>
                    {/* Add your favourite products to your wishlist and they'll appear here */}
                    {gettext("wishlist_items.current_user_not_saved_items_desc1")}
                </Text>
                {data?.empty_feed_link && (
                    <Button
                        title={gettext("suggestion_card.shop_sale_btn_label")} // Shop Sale
                        variant={"secondary"}
                        width={"fit-content"}
                        href={data.empty_feed_link}
                    />
                )}
            </VStack>
        </View>
    ) : (
        <p className={styles.wrapper}>
            <em>{gettext("feeds.empty_message")}</em>
        </p>
    );
}

import baustein from "baustein";
import _isString from "lodash/isString";
import { openCustomerCaptureOverlay } from "web/redux/ducks/customer-capture-overlay";
import { openOOSModal } from "web/redux/ducks/send-to-app-modal";
import store from "web/redux/store";
import analytics from "web/script/analytics/analytics";
import environment from "web/script/modules/environment";
import globals from "web/script/modules/globals";
import userProfiler from "web/script/modules/userprofiler";
import { canUseMembership, isMobile } from "web/script/modules/utils";
import browser from "web/script/utils/browser";
import url from "web/script/utils/url";

const SHOW_PROMO_CODE_ENV_KEY = "showPromoCode";

export default baustein.register("lead-link", {
    defaultOptions: {
        eventCategory: null,
        eventAction: null,
        eventLabel: null,
        productHref: null,
        isSavedForLater: false,
        affiliatePromoUrl: null,
        triggersReactEmailCapture: null,
    },

    setupEvents(add) {
        add("click", this.onClick);
    },

    init() {
        this.updateUrl = this.updateUrl.bind(this);
    },

    onInsert() {
        analytics.on("pageViewIdChanged", this.updateUrl);
        this.updateUrl();
    },

    onRemove() {
        analytics.off("pageViewIdChanged", this.updateUrl);
    },

    updateUrl() {
        var parsed = url.parse(this.el.href);

        parsed.searchParams.set("pageViewId", analytics.getPageViewId());

        ["reason", "type", "fromUrl"].forEach(
            function (key) {
                if (this.options[key]) {
                    parsed.searchParams.set(key, this.options[key]);
                }
            }.bind(this)
        );

        this.el.href = url.unparse(parsed);
    },

    onClick(event) {
        let pendingRequests = [];
        // sometimes we need to track the link as well as being a lead
        const isMobileViewport = isMobile();
        const { href, target } = this.el;
        const { eventCategory, eventAction, eventLabel } = this.options;
        const showPromoCode =
            this.options.affiliatePromoUrl && environment.get(SHOW_PROMO_CODE_ENV_KEY);

        // Save the clicked lead in the user localstorage profile only if a product id has
        // been provided
        if (this.options.productId) {
            userProfiler.saveClickedLead(this.options.productId, this.options.reason);
        }

        if (this.options.isSavedForLater) {
            pendingRequests.push(
                analytics.event("affiliate", "lead", "save_for_later_buy_on_store")
            );
        }

        // only track if we have all the information we need
        if (_isString(eventCategory) && _isString(eventAction) && href) {
            pendingRequests.push(analytics.event(eventCategory, eventAction, eventLabel));
        }

        // Open new tab with promo code url if one has been supplied
        if (showPromoCode) {
            globals.window.open(this.options.affiliatePromoUrl, "_blank");
        }
        // If necessary, open track link in current tab once analytics have resolved
        event.preventDefault();
        if (showPromoCode || target !== "_blank") {
            Promise.all(pendingRequests).then(() => {
                globals.window.location = href;
            });
        } else {
            if (
                this.options.reason === "affiliate-buy-on-store-button" &&
                isMobileViewport &&
                browser.ios &&
                !userProfiler.isLoggedIn()
            ) {
                store.dispatch(openOOSModal());
                return;
            }

            globals.window.open(href, "_blank");
            this.emit("show-bis-overlay");
            if (
                !userProfiler.isLoggedIn() &&
                this.options.triggersReactEmailCapture &&
                canUseMembership()
            ) {
                store.dispatch(
                    openCustomerCaptureOverlay({
                        productId: this.options.productId.toString(),
                        captureType: "signup_lead_save",
                    })
                );
            }
        }
    },
});

import globals from "web/script/modules/globals";
import loader from "./loader";

// extracted from GA snippet
globals.window["GoogleAnalyticsObject"] = "ga";
globals.window["ga"] =
    globals.window["ga"] ||
    function () {
        (globals.window["ga"].q = globals.window["ga"].q || []).push(arguments);
    };
globals.window["ga"].l = 1 * new Date();

const load = loader(() => {
    (function (i, s, o, g, r, a, m) {
        a = s.createElement(o);
        m = s.getElementsByTagName(o)[0];
        a.async = 1;
        a.src = g;
        m.parentNode.insertBefore(a, m);
    })(globals.window, globals.document, "script", "//www.google-analytics.com/analytics.js", "ga");
});

export default function () {
    load();
    globals.window.ga.apply(null, arguments);
}

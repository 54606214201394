/**
 * This is a very thin wrapper around DOM Storage that mainly adds the functionality to save objects and arrays.
 * The use of try / catch in this module is to suppress exceptions that could be caused by any of the following:
 *
 *  1. The browser may not support localStorage (TypeError)
 *  2. Private browsing on iOS throws an exception if you try to use localStorage (QUOTA_EXCEEDED_ERR)
 *  3. Storage quota may have been reached (QUOTA_EXCEEDED_ERR)
 *  4. JSON.parse() throws exceptions for invalid JSON strings (SyntaxError)
 *  5. Chrome throws a SecurityError if a 3rd party iframe accesses localStorage in private browsing
 *
 *  As all operations will fail silently the functionality provided by this module should always be
 *  treated as a 'nice-to-have' and not a requirement.
 */
import _isNumber from "lodash/isNumber";
import _isString from "lodash/isString";
import _once from "lodash/once";

const storage = {
    _getStore(session: boolean): Storage | undefined {
        // Uses window explicitly to ensure returning `undefined` on hypernova
        try {
            return session ? window.sessionStorage : window.localStorage;
        } catch (e) {
            return undefined;
        }
    },

    /**
     * Returns a previously saved value
     * @param key Key of the item to retrieve.
     * @param defaultValue Default value to return if key doesn't exist.
     *      If not provided null will be returned.
     * @param fromSession get the value from session storage
     * @returns The value stored under the given key or null.
     */
    get(key: string, defaultValue: any = null, fromSession = false): any {
        let store = this._getStore(fromSession);
        if (!store) {
            return defaultValue;
        }

        let value: string | null;
        try {
            value = store.getItem(key);
        } catch (e) {
            return defaultValue;
        }

        if (value === null) {
            return defaultValue;
        }

        try {
            return JSON.parse(value);
        } catch (e) {
            return value;
        }
    },

    /**
     * Sets a new item.
     * @param key Key to save the item under.
     * @param value Value to save, if it is an object or array it will be stringified.
     * @param toSession set the value to session storage
     */
    set(key: string, value: any, toSession = false): void {
        let store = this._getStore(toSession);
        if (!store) {
            return;
        }
        // this is not wrapped in try catch as provided an object or array which cannot be JSON serialized is an error.
        if (!_isString(value)) {
            value = JSON.stringify(value);
        }

        try {
            store.setItem(key, value as string);
        } catch (e) {}
    },

    /**
     * Removes a previously saved item.
     * @param key
     * @param fromSession remove from the session store
     */
    remove(key: string, fromSession = false): void {
        let store = this._getStore(fromSession);
        if (!store) {
            return;
        }

        try {
            store.removeItem(key);
        } catch (e) {}
    },

    /**
     * Increments the value of `key` by 1. If key doesn't exist it will be set to 1.
     * If key exists and the value is not a number it will be set to 1.
     * @param key
     */
    increment(key: string): number {
        let curr = this.get(key, 0);
        if (!_isNumber(curr) || isNaN(curr)) {
            curr = 0;
        }
        curr += 1;
        this.set(key, curr);
        return curr;
    },

    /**
     * @returns true if localStorage is supported, otherwise false.
     */
    isSupported(): boolean {
        let key = "test-key" + Math.random() + Math.random();
        this.set(key, key);
        let result = this.get(key) === key;
        this.remove(key);

        return result;
    },
};

storage.isSupported = _once(storage.isSupported);

export default storage;

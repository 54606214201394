import React from "react";
import { ProductRail } from "web/react/components/home/stories/product-rail";
import { ArchiveDeviceDivider } from "web/react/emo/archive-device-divider";
import { ProductRailSerializer } from "web/types/serializers";

interface ProductRailContainerProps {
    productRail: ProductRailSerializer;
    className?: string;
    position: number;
}

export function ProductRailContainer({
    productRail,
    className,
    position,
}: ProductRailContainerProps): React.ReactElement {
    return (
        <>
            <div className={className}>
                <ProductRail {...productRail} position={position} />
            </div>
            <ArchiveDeviceDivider />
        </>
    );
}

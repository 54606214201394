import globals from "web/script/modules/globals";

// We have to put these in there own file - as have an issue with circular dependencies
export class RothkoError extends Error {
    public readonly name: string = "RothkoError";
    public readonly response: Response;
    public url: URL;

    constructor(public readonly reason: RequesterError, public readonly templateName: string) {
        super();

        this.response = reason.response;
        this.url = reason.url;
        this.message = `Rothko call to ${templateName} responded with ${this.response.status} ${this.response.statusText}`;
    }
}

export class RequesterError extends Error {
    public readonly name: string = "RequesterError";
    public url: URL;

    constructor(
        public readonly response: Response,
        public readonly request: RequestInfo,
        public readonly options?: RequestInit
    ) {
        super();

        this.url = new URL(response.url, globals.window.location.href);
        const method = options?.method?.toUpperCase?.() || "GET";
        this.message = `Request for ${method} ${this.url.pathname} responded with ${response.status} ${response.statusText}`;
    }
}

import clsx from "clsx";
import React, { useCallback, useRef, useState } from "react";
import Slider, { Settings as SlickSettings } from "react-slick";
import { ProductGalleryCarouselControls } from "web/react/components/product-gallery/product-gallery-carousel-controls/product-gallery-carousel-controls";
import Image from "web/react/components/product-gallery/product-gallery-image/product-gallery-image";
import { MoreProducts } from "web/react/components/product-gallery/product-gallery-more-products/product-gallery-more-products";
import "web/react/components/product-gallery/slick-theme.less";
import "web/react/components/product-gallery/slick.less";
import SVGIcon from "web/react/components/svg-icon/svg-icon";
import { useDomViewport } from "web/react/hooks/use-dom-viewport/use-dom-viewport";
import analytics from "web/script/analytics/analytics";
import { ProductCardSerializer } from "web/types/serializers";
import styles from "./product-gallery-carousel.module.css";

interface ProductGalleryCarouselProps {
    settings: SlickSettings;
    images: ProductImage[];
    hasRelatedProductsSidebar: boolean;
    relatedProducts?: ProductCardSerializer[];
    handleImageClick?: (index: number) => void;
    openFullscreenGallery?: (index: number) => void;
    dotsInsideGallery?: boolean;
    isFullscreen?: boolean;
    setCurrentSlideIndex?: () => void;
    imageType?: string;
    currentLinkId?: string;
    variant?: "oos-carousel";
    productId?: string;
}

let firstClientX;

// Component for the gallery thumbnail image so we can lazy-load it client-side instead
function ThumbnailImage({ image }: { image: string }): React.ReactElement | null {
    const { isTabletViewport, isDesktopViewport } = useDomViewport();
    const [src, setSrc] = React.useState(
        "data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"
    );

    React.useEffect(() => {
        if (isTabletViewport || isDesktopViewport) {
            setSrc(image);
        }
    }, [image, isDesktopViewport, isTabletViewport]);

    if (isTabletViewport || isDesktopViewport) {
        return <img src={src} width="48" height="48" />;
    } else {
        return null;
    }
}

interface ArrowProps {
    className?: string; // react-slick passed
    onClick?: () => void; // react-slick passed
}

function Arrow({ className, onClick }: ArrowProps): JSX.Element {
    return (
        <button type="button" className={clsx(className, styles.arrow)} onClick={onClick}>
            <SVGIcon name="chevron" />
        </button>
    );
}

export function ProductGalleryCarousel({
    images,
    isFullscreen = false,
    relatedProducts,
    handleImageClick,
    openFullscreenGallery,
    settings,
    hasRelatedProductsSidebar,
    variant,
    productId,
}: ProductGalleryCarouselProps): React.ReactElement {
    const lastImageIndex = images.length - 1;
    const { isMobileViewport, isTabletViewport, isDesktopViewport } = useDomViewport();
    const oosCarouselVariant =
        variant === "oos-carousel" && (isTabletViewport || isDesktopViewport);

    function onImageClick(index): void {
        if (isFullscreen) {
            return;
        }
        handleImageClick && handleImageClick(index);
    }

    const galleryImages = images.map((image, index) => {
        return (
            /* hack to get slick-slider ssr to set slick-track width to NaN (ignored by the
             * browser) instead of 0, which causes the slides to start in the wrong place until
             * the client version is initialized*/
            <div key={image.large_url} style={{}}>
                <Image
                    className={clsx({
                        [styles.stretchImage]: oosCarouselVariant && !isFullscreen,
                    })}
                    isFullscreen={isFullscreen}
                    onClick={(): void => onImageClick(index)}
                    image={image}
                    isSingle={images.length === 1}
                    hasRelatedProductsSidebar={hasRelatedProductsSidebar}
                    width={oosCarouselVariant ? 200 : 520}
                    height={oosCarouselVariant ? 250 : 650}
                />
            </div>
        );
    });

    const [currentSlide, setCurrentSlide] = useState<number>(0);
    const [seenAll, setSeenAll] = useState<boolean>(false);
    const [relatedSeen, setRelatedSeen] = useState<boolean>(false);
    const [imagesSeen, setImagesSeen] = useState<number>(0);
    const sliderRef = useRef<any>(null);

    const slides = [
        ...galleryImages,
        isMobileViewport && relatedProducts && relatedProducts.length > 0 && (
            <MoreProducts
                key="related-slide"
                products={relatedProducts}
                fullscreen={isFullscreen}
            />
        ),
    ].filter(Boolean);
    const totalSlides = slides.length;

    const handleTouchStart = useCallback((event) => {
        if (event && event.touches.length > 0) {
            firstClientX = event.touches[0].clientX;
        }
    }, []);

    let handleTouchMoved = useCallback((event) => {
        if (event.touches.length > 0) {
            const minValue = 20;
            const clientX = event.touches[0].clientX - firstClientX;

            // Disable vertical scrolling when user swipes horizontally (Safari fix).
            if (Math.abs(clientX) > minValue) {
                event.preventDefault();
                event.returnValue = false;
                return false;
            }
        }
    }, []);

    const sliderElement = useCallback(
        (node) => {
            if (node === null) {
                return;
            }
            node.addEventListener("touchmove", handleTouchMoved, {
                passive: false,
            });
            node.addEventListener("gesturestart", (event) => event.preventDefault());
        },
        [handleTouchMoved]
    );

    function getDots(dots): React.ReactElement {
        const thumbnails = dots.map((dot, index) => {
            const image: ProductImage | undefined = images[index];

            if (!image) {
                return null;
            }

            return (
                <li key={dot.key} className={dot.props.className}>
                    <button onClick={dot.props.children.props.onClick}>
                        <ThumbnailImage image={image.thumbnail_url} />
                    </button>
                </li>
            );
        });

        return <ul>{thumbnails}</ul>;
    }

    function onSlideChange(currentSlide, nextSlide): void {
        analytics.event("product_gallery", "engagement", "changed_image", false, {
            product_id: productId,
        });

        if (!seenAll) {
            let tempImagesSeen = imagesSeen;
            if (nextSlide === currentSlide + 1) {
                // User scrolls right
                tempImagesSeen += 1;
            } else if (nextSlide === currentSlide - 1 || nextSlide === lastImageIndex) {
                // User scrolls left
                tempImagesSeen -= 1;
            }

            if (Math.abs(tempImagesSeen) === lastImageIndex) {
                setSeenAll(true);
                analytics.event("product_gallery", "engagement", "seen_all", false, {
                    product_id: productId,
                });
            } else {
                setImagesSeen(tempImagesSeen);
            }
        }

        if (relatedProducts && !relatedSeen && nextSlide === lastImageIndex + 1) {
            setRelatedSeen(true);
            analytics.event("product_gallery", "engagement", "seen_related_products_slide", false, {
                product_id: productId,
            });
        }

        setCurrentSlide(nextSlide);
    }

    function handleOnSwipe(): void {
        if (sliderRef) {
            sliderRef.current.innerSlider.clickable = true;
        }
    }

    const sliderClasses = clsx(
        { [styles.carouselImage]: !isFullscreen },
        // eslint-disable-next-line css-modules/no-undef-class
        { [styles.fullscreen]: isFullscreen },
        // eslint-disable-next-line css-modules/no-undef-class
        { [styles.singleImageGallery]: images.length === 1 }
    );

    const sliderWrapperClasses = clsx(
        { [styles.carousel]: !isFullscreen && !oosCarouselVariant },
        { [styles.oosCarouselVariant]: !isFullscreen && oosCarouselVariant }
    );

    const oosCarouselArrows =
        oosCarouselVariant && !isFullscreen
            ? {
                  prevArrow: <React.Fragment />, // we must use a Fragment because slick-slider uses React.cloneElement() unsafely
                  nextArrow: <Arrow />,
              }
            : {};

    return (
        <div
            ref={sliderElement}
            onTouchStart={handleTouchStart}
            className={clsx(sliderWrapperClasses, "gallery")}
        >
            <Slider
                ref={sliderRef}
                className={sliderClasses}
                beforeChange={onSlideChange}
                appendDots={getDots}
                {...oosCarouselArrows}
                {...settings}
                dots={
                    !oosCarouselVariant && (isTabletViewport || isDesktopViewport) && settings?.dots
                }
                // Workaround for click bug
                // https://github.com/akiran/react-slick/issues/604#issuecomment-429166338
                onSwipe={handleOnSwipe}
            >
                {slides}
            </Slider>
            {isMobileViewport ? (
                <div
                    className={clsx(styles.controls, {
                        [styles.fullscreenControls]: isFullscreen,
                    })}
                >
                    <ProductGalleryCarouselControls
                        onNext={() => sliderRef.current.slickNext()}
                        onPrevious={() => sliderRef.current.slickPrev()}
                        totalCount={totalSlides}
                        currentItem={currentSlide + 1}
                    />
                </div>
            ) : null}
            {!isFullscreen && !oosCarouselVariant && (
                <button
                    onClick={(): void =>
                        openFullscreenGallery &&
                        openFullscreenGallery(sliderRef.current.innerSlider.state.currentSlide)
                    }
                    className={styles.expandButton}
                >
                    <SVGIcon name="expand-legacy" />
                </button>
            )}
        </div>
    );
}

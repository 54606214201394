import { FieldValidator, useField } from "formik";
import React from "react";
import { Checkbox, CheckboxProps } from "web/react/emo/checkbox/checkbox";

interface CheckboxInputFieldProps extends CheckboxProps {
    name: string;
    validate?: FieldValidator;
}

export function FormikCheckbox({
    name,
    validate,
    label,
    labelClassName,
    value,
    onChange,
    ...props
}: CheckboxInputFieldProps): JSX.Element {
    const [field, { error }, { setValue }] = useField({ type: "checkbox", name, validate, value });

    return (
        <Checkbox
            {...props}
            {...field}
            id={field.name}
            error={error}
            onChange={(e) => {
                onChange?.(e);
                setValue(e.currentTarget.checked);
            }}
            label={label}
            labelClassName={labelClassName}
        >
            {label}
        </Checkbox>
    );
}

import "web/react/emo/theme.css.ts.vanilla.css!=!../../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"web/react/emo/theme.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA6WUTW/bMAyG7/sVRHzZgHSwPizL3Em2JayH7bDuPjip06b5cJqkSdth/32gMxQzPZ0G+fRQpqiXfIX7rjvCz3cAV1c/xP52JcU5RUjSNP00gAIhsdpY4YdcIiRBhdQXQ64QkjJzOrdDrhGSIs1zlQ15hpAYlynB9hvK40sV5JDnCImvvA3lkFuEpCorGVj+guovrHBhyBuEJAuZy1n9M4REV9oaMeRzhKRW0kumwy3p4L3zbshb4qVVluVZEHc60+xed6RPJmrB7nWPkMhamcDqX1Kzgig5f+ibaF3KdFiRbiY4nw/5mvQvvcpZ3zd9HhdStn/bc21SNeRdRJ9dRJ/HiD77iD6HiD7HPn9h+dw+EQ8h82bIT/3+ShjGzz13tWL3eu7nKnc8/wtCIpwyJeOvpLP1ZWC6CXJXbqypucHIYSKTpa1ZgCyWOmE0K1WQx2QurWSzK3SkKEEuqx0tFjB9G4ILPEA+C1nQ3H+CjFb7uq55VUXsjObSisBHVcxih88vj4sKrBmC3OZzWizQxg4nv1WeFguQ4SpNiwXIcUVJiwXIcnlKiwXIc9rTYoFVrFHry1M7fm03CPu7WfM+ncKf76P+wPZsYz93sQJ3sT8eI6+7IBvm0pQFs6c4RHwujpGHR5ATq1wayYU+RawlzrExfo5N0kss8BppjUwjkkgREVHKyMBIhcD3agS9e2YwQ7AjaBCEHNEcQZgRtQhynLZAUOmINghqnHeGoMd75wh6XNktghmf1iLYcYYFQjGu947uNt58j3DztDwcWrjeHtcsuHwLfum2HQs+IFyfmj3cdIsjC60GSacw+dyuT+1xOW/ga/vUTqbwBqbg9stmPYVDsz1cHdr9csGSrf86ZwqT78tNe4Cv7Rm+dZtmO5lCT6bwr383gwv8ZyFb6hcfrg5BZxzuELLRzkcEM4J7BKEuM/frN5j8aoMHCgAA\"}!../../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
import "web/react/components/navigation/search-bar/auto-suggest/auto-suggest.css.ts.vanilla.css!=!../../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"web/react/components/navigation/search-bar/auto-suggest/auto-suggest.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA81TTW+jMBC98yt8WakcHGGShi257D+pHHtCvAXbsk2hW/HfK1woBBOlqlppT5bm482b98abx/2p4bJK0GuEkFZWOKFkjujRqrJ2cIgQ+oeF5NDmiCRJ0geOlD0VRtWS5+iZmjuMH4nhTylpCI37gkZwd/b1z41vUIaDwUflnKpyRHSLrCoFX3ZDfIi6aDNwIl/jhJkqlQmYZd/ILPXMJqxfPRQXVpf0JUenEto+0L+YCwPsnT9TZV3JPlNRU4h+o9qpOe72h3B3HvcmkFFeE1qKQmLhoLI5YiAdmCnMlHQg3ZSYptz7KTeNAG9EJSQeF9XtGDmDKM4uR2kyxIaFEt0uYNLswpF9qFwX/amAC4osMwASUcnRXUXbcezDQ6rb2PdtLn7BOBY7pZfsU+7Zd7Mm8vmmdUqTEsmSUDpg/62tE6eXUP0+ecWvOcftN+HsruFYTRngI7gGQC679h8KfYi/ze7fHZ7XZWPd7DaGT4CQppwLWeASTi4QOAtc+T1gBTjrJG45s3IsozcDsYATjcMzWEZYfFjX039N66hxK94sk7OVyG6XhMJu/0eqKdmvUL16YZdIa2dzeSHJtYuYqzVcRRd1b1YpjZMJBwAA\"}!../../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var heading = '_6fwdnm7';
export var headingDivider = '_6fwdnm5';
export var headingWrapper = '_6fwdnm6';
export var recentHeadingWrapper = '_6fwdnm4';
export var suggestionList = '_6fwdnm8';
export var suggestionsContainer = '_6fwdnm0';
export var suggestionsContainerRebrand = '_6fwdnm1';
export var suggestionsContent = '_6fwdnm2';
export var suggestionsContentRebrand = '_6fwdnm3';
import React from "react";
import DragRail from "web/react/components/drag-rail/drag-rail";
import LazyImage from "web/react/components/lazy-load-image/lazy-load-image";
import analytics from "web/script/analytics/analytics";
import styles from "./top-lysts.module.css";

interface TopLystItemProps {
    image_url: string;
    url: string;
    title: string;
}

function TopLystItem({ image_url: imgUrl, url, title }: TopLystItemProps): JSX.Element {
    return (
        <a href={url} className={styles.item}>
            <div className={styles.itemTitle}>
                <h3 className={styles.vertical}>{title}</h3>
            </div>
            <LazyImage src={imgUrl} alt={title} className={styles.itemImage} />
        </a>
    );
}

export interface TopLystsProps {
    banner_rows: any[];
}

function TopLysts({ banner_rows: bannerRows }: TopLystsProps): JSX.Element {
    const [topRow, bottomRow] = bannerRows;
    function handleDrag(): void {
        analytics.event("rail", "drag", "top-lysts");
    }
    return (
        <div className={styles.topLystsContainer}>
            <DragRail onDragStart={handleDrag} className={styles.rail}>
                <div className={styles.items}>
                    {topRow && topRow.map((item) => <TopLystItem key={item.url} {...item} />)}
                </div>
            </DragRail>
            <DragRail onDragStart={handleDrag}>
                <div className={styles.items}>
                    {bottomRow && bottomRow.map((item) => <TopLystItem key={item.url} {...item} />)}
                </div>
            </DragRail>
        </div>
    );
}

export default TopLysts;

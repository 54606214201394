import React from "react";
import { connect } from "react-redux";
import AppDownloadPromo from "web/react/components/app-download-promo/app-download-promo";
import { AppFooter } from "web/react/components/app-footer/app-footer";
import { FooterProps } from "web/react/components/footer/footer";
import withReduxProvider from "web/react/redux-provider";
import { RawBranchLink } from "web/script/utils/branch-io";

interface ToAppPageProps {
    footer: FooterProps;
}

function ToAppPage({ footer }: ToAppPageProps): JSX.Element {
    return (
        <>
            <AppDownloadPromo branchLink={RawBranchLink.AppTeaser} />
            <AppFooter {...footer} />
        </>
    );
}

export default withReduxProvider(connect(null)(ToAppPage));

import clsx from "clsx";
import React from "react";
import { connect } from "react-redux";
import { DiscoveryFeedGrid } from "web/react/components/discovery-feed-grid/discovery-feed-grid";
import SVGIcon from "web/react/components/svg-icon/svg-icon";
import withReduxProvider from "web/react/redux-provider";
import { gettext, ngettext } from "web/script/modules/django-i18n";
import { numberFormat } from "web/script/modules/formats";
import { DiscoveryContentSerializer } from "web/types/serializers";
import styles from "./discovery-content.module.css";

export function DiscoveryContentPage({
    feed_items: feedItems,
    hero_image_set: { desktop_image_url: desktopImage, mobile_image_url: mobileImage },
    subtitle,
    title,
    feed_type: feedType,
}: DiscoveryContentSerializer): JSX.Element {
    let productCount = 0;
    let normalisedFeedItems: any[] = [];
    for (let i = 0; i < feedItems.length; ++i) {
        const feedItem = feedItems[i];
        if (feedItems[i].item_type === "lyst" && feedItem.product_feed_items) {
            productCount += feedItem.product_feed_items.length;
            normalisedFeedItems.push(
                ...feedItem.product_feed_items.map((obj) => {
                    return { item_type: "productCard", ...obj };
                })
            );
        } else {
            normalisedFeedItems.push({
                ...feedItem,
            });
        }
    }
    const productCountLabel = ngettext(
        "feed.product_count.label",
        "feed.product_count.label",
        productCount,
        {
            count: numberFormat(productCount),
        }
    );

    return (
        <div className={styles.discoveryFeedContainer}>
            <div className={styles.topImageContainer}>
                <picture>
                    <source media="(min-width: 576px)" srcSet={desktopImage} />
                    <source media="(max-width: 575px)" srcSet={mobileImage} />
                    <img src={desktopImage} alt={title} />
                </picture>
            </div>
            <div className={styles.textArea}>
                <h1>{title}</h1>
                <div className={styles.contentDetails}>
                    <SVGIcon className={styles.svg} name={"user-icon"} />
                    <div className={styles.detailText}>{gettext("feed.created_by_lyst.label")}</div>
                </div>
                <div className={styles.contentDetails}>
                    <SVGIcon className={styles.svg} name={"basket-icon"} />
                    <div className={styles.detailText}>{productCountLabel}</div>
                </div>
                <div className={styles.subTitle}>{subtitle}</div>
            </div>
            <div className={clsx(styles.gridHolder)}>
                <DiscoveryFeedGrid
                    feedItems={normalisedFeedItems}
                    extendedFeedItems={normalisedFeedItems}
                    title={title}
                    feedType={feedType}
                />
            </div>
        </div>
    );
}

export default withReduxProvider(connect(null)(DiscoveryContentPage));

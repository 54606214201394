import loader from "./loader";
/*
Testing the new Criteo tag that, starting with the French market.
If successful, will generalise to all markets at once.
The Partner ID is required in the new tag, unfortunately it is stored in
the criteo-transport.js file, and the load() function does not accept
parameters so creating a separate file for the French market with a
hardcoded tag source URL.
*/
window.criteo_q = window.criteo_q || [];

const CRITEO_FR_PARTNER_ID = 36542;

const load = loader(() => {
    let d = document;
    let g = d.createElement("script");
    let s = d.getElementsByTagName("script")[0];
    g.type = "text/javascript";
    g.async = true;
    g.src = `//dynamic.criteo.com/js/ld/ld.js?a=${CRITEO_FR_PARTNER_ID}`;
    s.parentNode.insertBefore(g, s);
});

export default function (...args) {
    load();
    return window.criteo_q.push(...args);
}

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { EU_COUNTRIES } from "web/react/constants";
import googleConsentMode from "web/script/analytics/google-consent-mode";
import microsoftConsentMode from "web/script/analytics/microsoft-consent-mode";
import environment from "web/script/modules/environment";
import globals from "web/script/modules/globals";
import cookie from "web/script/utils/cookie";

const legacyConsentCookieName = "cookieconsent";

export enum CookieConsentLevel {
    DoesNotExist = 0,
    Accepts = 1,
    Rejects = 2,
}

const cookieTypes = {
    Advertisement: "advertisement",
    Analytics: "analytics",
    Customization: "customization",
    PersonalizedAds: "personalizedAds",
};

const cookieVersions = environment.get("cookieVersions", {});

const cookieNamePrefixes = {
    advertisement: "consent_ads_",
    analytics: "consent_analytics_",
    customization: "consent_customization_",
    personalizedAds: "consent_personalized_ads_",
};

function _getCurrentCookieVersion(cookieName): number | null {
    for (let cookieType in cookieNamePrefixes) {
        if (cookieName.startsWith(cookieNamePrefixes[cookieType])) {
            return cookieVersions[cookieType];
        }
    }

    return null;
}

function _cookieIsCurrentVersion(cookieName): boolean {
    const cookieVersion = cookieName.split("_").pop();
    return cookieVersion == _getCurrentCookieVersion(cookieName);
}

function _createConsentCookie(cookieType, consentLevel): void {
    const cookieName = cookieNamePrefixes[cookieType] + cookieVersions[cookieType];

    let hostnameParts = globals.window.location.hostname.split(".");
    let cookieDomain;
    if (hostnameParts.includes("www") || hostnameParts.includes("checkout")) {
        cookieDomain = hostnameParts.slice(1).join(".");
    }

    cookie(cookieName, consentLevel, {
        expires: 365,
        path: "/",
        withEncoding: false,
        domain: cookieDomain,
    });
}

function _deleteCookie(cookieName): void {
    cookie(cookieName, "", {
        expires: 0,
        path: "/",
    });
}

function _getCurrentConsent(cookieType): CookieConsentLevel {
    let consentLevel = CookieConsentLevel.DoesNotExist;

    // loop through all possible versions to see if the current cookie is the
    // correct version. If the cookie is an old version then delete it.
    for (let version = 1; version <= cookieVersions[cookieType]; version++) {
        const cookieName = cookieNamePrefixes[cookieType] + version;
        const consentCookie = cookie(cookieName);

        if (consentCookie && _cookieIsCurrentVersion(cookieName)) {
            consentLevel = Number(consentCookie) as CookieConsentLevel;
        } else if (consentCookie) {
            _deleteCookie(cookieName);
        }
    }

    return consentLevel;
}

function _acceptAllCookies(): void {
    for (let cookieType in cookieTypes) {
        _createConsentCookie(cookieTypes[cookieType], CookieConsentLevel.Accepts);
    }
}

function _consentRequired(): boolean {
    if (!environment.get("eu_geoip_country")) {
        return false;
    }

    // If the user has the legacy cookieconsent then we can just accept all cookies
    if (cookie(legacyConsentCookieName)) {
        _deleteCookie(legacyConsentCookieName);
        _acceptAllCookies();
        return false;
    }

    let consentRequired = false;
    // check if user has accepted/rejected most recent cookies
    for (const cookieType in cookieTypes) {
        const currentConsentValue = _getCurrentConsent(cookieTypes[cookieType]);

        // if a user doesn't have the most recent cookie version then we require their consent again
        if (currentConsentValue === CookieConsentLevel.DoesNotExist) {
            consentRequired = true;
        }
    }

    return consentRequired;
}

function _hasConsentedToCookie(cookieType): boolean {
    const country = environment.get("country", "");
    if (!EU_COUNTRIES.has(country) && country !== "US") {
        return true;
    }

    const consentLevel = _getCurrentConsent(cookieType);

    // Return the default value true for US if the cookies are not set
    // this ensures the CCPA toggle will be set as consenting to cookies by default
    if (country === "US" && consentLevel === CookieConsentLevel.DoesNotExist) {
        return true;
    }

    return consentLevel === CookieConsentLevel.Accepts;
}

function _acceptsCustomizationCookies(): boolean {
    return _hasConsentedToCookie(cookieTypes.Customization);
}

function _acceptsAdCookies(): boolean {
    return _hasConsentedToCookie(cookieTypes.Advertisement);
}

function _acceptsAnalyticalCookies(): boolean {
    return _hasConsentedToCookie(cookieTypes.Analytics);
}

function _acceptsPersonalizedAdsCookies(): boolean {
    return _hasConsentedToCookie(cookieTypes.PersonalizedAds);
}

interface CookieConsentSlice {
    consentConfirmed: boolean;
    acceptsAds: boolean;
    acceptsAnalytics: boolean;
    acceptsCustomization: boolean;
    acceptsPersonalizedAds: boolean;
}

export interface CookieConsent {
    adCookie: CookieConsentLevel;
    analyticsCookie: CookieConsentLevel;
    customizationCookie: CookieConsentLevel;
    personalizedAdsCookie: CookieConsentLevel;
}

const cookieConsent = createSlice({
    name: "cookieConsent",
    initialState: {
        acceptsAds: _acceptsAdCookies(),
        acceptsAnalytics: _acceptsAnalyticalCookies(),
        acceptsCustomization: _acceptsCustomizationCookies(),
        acceptsPersonalizedAds: _acceptsPersonalizedAdsCookies(),
        consentConfirmed: !_consentRequired(),
    } as CookieConsentSlice,
    reducers: {
        setCookieConsent(state, action: PayloadAction<CookieConsent>): void {
            _createConsentCookie(cookieTypes.Advertisement, action.payload.adCookie);
            _createConsentCookie(cookieTypes.Analytics, action.payload.analyticsCookie);
            _createConsentCookie(cookieTypes.Customization, action.payload.customizationCookie);
            _createConsentCookie(cookieTypes.PersonalizedAds, action.payload.personalizedAdsCookie);

            state.acceptsAds = action.payload.adCookie === CookieConsentLevel.Accepts;
            state.acceptsAnalytics = action.payload.analyticsCookie === CookieConsentLevel.Accepts;
            state.acceptsCustomization =
                action.payload.customizationCookie === CookieConsentLevel.Accepts;
            state.acceptsPersonalizedAds =
                action.payload.personalizedAdsCookie === CookieConsentLevel.Accepts;
            state.consentConfirmed = true;
            // update consent mode
            googleConsentMode.updateConsentMode(state);
            microsoftConsentMode.updateConsentMode(state);
        },
    },
});

export const { setCookieConsent } = cookieConsent.actions;
export default cookieConsent.reducer;

// Refer to https://github.com/whatwg/html/issues/3518 for passwordrules
import { gettext } from "web/script/modules/django-i18n";

export const DEFAULT_PASSWORD_REQUIREMENTS = [
    {
        description: gettext("account.set_password_contain_8_characters.label"), // "8 characters"
        pattern: /.{8}/,
        passwordRule: "minlength: 8",
    },

    {
        description: gettext("account.set_password_contain_1_lower.label"), // "1 lowercase letter"
        pattern: /[a-z]/,
        passwordRule: "required: lower",
    },

    {
        description: gettext("account.set_password_contain_1_upper.label"), // "1 uppercase letter"
        pattern: /[A-Z]/,
        passwordRule: "required: upper",
    },

    {
        description: gettext("account.set_password_contain_1_number.label"), // "1 number"
        pattern: /[0-9]/,
        passwordRule: "required: digit",
    },

    {
        description: gettext("account.set_password_contain_1_special.label"), // "1 special character"
        pattern: /[!@#$%^&*)(+=._-]/,
        passwordRule: "required: [-().&@?'#,/&quot;+]",
    },
];

export const PASSWORD_RULES = DEFAULT_PASSWORD_REQUIREMENTS.map(
    (requirement) => requirement.passwordRule
).join("; ");

export function validatePassword(password: string): boolean {
    return DEFAULT_PASSWORD_REQUIREMENTS.every((requirement) => requirement.pattern.test(password));
}

export function validateEmail(email: string): boolean {
    return /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email);
}

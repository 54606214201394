import { useState } from "react";
import { BREAKPOINTS, VIEWPORTS } from "web/react/constants";
import useIsomorphicLayoutEffect from "web/react/hooks/use-isomorphic-layout-effect/use-isomorphic-layout-effect";
import { useWindowWidth } from "web/react/hooks/use-window-width/use-window-width";
import environment from "web/script/modules/environment";

interface DomViewport {
    isDomLoaded: boolean;
    isMobileViewport: boolean;
    isTabletViewport: boolean;
    isDesktopViewport: boolean;
}

/**
 * Hook that keeps track of the browser viewport once the DOM has loaded
 *
 * Each viewport check requires that the DOM has been loaded, therefore we should always
 * check these values in a truthy way, not falsey. This way we can be sure that the
 * check is false because the viewport does not match, not because the DOM is yet
 * to load (i.e. we are on the server)
 *
 * ❌ Incorrect:
 *     if (!isMobileViewport) {}
 *
 * ✅ Correct:
 *     if (isDesktopViewport || isTabletViewport) {}
 *
 * Note: The hook relies on client-side code execution.
 * Hooks are React-specific and run in the client browser after the initial render.
 * They don't execute on the server during server-side rendering (SSR).
 * When server-side rendering occurs, there is no real browser window to work with.
 * Avoid conditionally rendering components with this hook in order to enable SSR
 * See https://lyst.atlassian.net/browse/CW-1368
 *
 */
export function useDomViewport(): DomViewport {
    const [isDomLoaded, setIsDomLoaded] = useState(false);
    const windowWidth = useWindowWidth();

    useIsomorphicLayoutEffect(() => {
        setIsDomLoaded(true);
    }, []);

    function getClientViewport(): number {
        if (windowWidth >= BREAKPOINTS.Large) {
            return VIEWPORTS.Desktop;
        } else if (windowWidth >= BREAKPOINTS.Small) {
            return VIEWPORTS.Tablet;
        }
        return VIEWPORTS.Mobile;
    }

    function getServerViewport(): number {
        const deviceFamily = environment.get("deviceFamily");

        if (deviceFamily === "desktop") {
            return VIEWPORTS.Desktop;
        } else if (deviceFamily === "tablet") {
            return VIEWPORTS.Tablet;
        }
        return VIEWPORTS.Mobile;
    }

    const viewport = isDomLoaded ? getClientViewport() : getServerViewport();
    const isMobileViewport = viewport === VIEWPORTS.Mobile;
    const isTabletViewport = viewport === VIEWPORTS.Tablet;
    const isDesktopViewport = viewport === VIEWPORTS.Desktop;

    return { isDomLoaded, isMobileViewport, isTabletViewport, isDesktopViewport };
}

import clsx from "clsx";
import React from "react";
import ProductHeader from "web/react/components/buybuybuy-area/product-header/product-header";
import SaveForLaterButton from "web/react/components/save-for-later-button/save-for-later-button";
import userProfiler from "web/script/modules/userprofiler";
import { canUseMembership } from "web/script/modules/utils";
import style from "./product-description-header.module.css";

export interface ProductDescriptionHeaderProps {
    absoluteUrl?: string;
    className?: string;
    designerName: string;
    designerUrl: string;
    isDesignerActive?: boolean;
    productId: string;
    shortDescription: string;
    isAffiliate: boolean;
}

export default function ProductDescriptionHeader({
    className,
    designerName,
    designerUrl,
    isDesignerActive = false,
    shortDescription,
    productId,
    isAffiliate = false,
}: ProductDescriptionHeaderProps): JSX.Element {
    return (
        <div className={clsx(className, style.wrapper)}>
            <ProductHeader
                variant="affiliate"
                isOwnerActive={isDesignerActive}
                isOwnerFeedLinkable={isDesignerActive}
                designerUrl={designerUrl}
                ownerName={designerName}
                shortDescription={shortDescription}
            />
            <div className={style.s4lwrapper}>
                {canUseMembership() && (
                    <SaveForLaterButton
                        type="icon"
                        isLoggedIn={userProfiler.isLoggedIn()}
                        pageSource="pdp"
                        productId={productId}
                        isAffiliate={isAffiliate}
                    />
                )}
            </div>
        </div>
    );
}

import clsx from "clsx";
import PropTypes from "prop-types";
import React from "react";
import RetailerLink from "web/react/components/retailer-link/retailer-link";
import SVGIcon from "web/react/components/svg-icon/svg-icon";
import { gettext } from "web/script/modules/django-i18n";
import environment from "web/script/modules/environment";
import { ShippingOptionPropType } from "web/types/buy-option";
import styles from "./price-info.module.css";

export function PriceInfo({
    currentPriceHtml,
    // note: formattedPrice is the previous price if eg on sale. the "real" price is currentPrice
    formattedPrice,
    isInStock,
    isOnSale,
    leadUrl,
    retailerName,
    selectedShipping,
    retailAreaText,
}) {
    // If it's out of stock, it's not on sale
    if (!isInStock) {
        isOnSale = false;
    }
    const taxIncRetailers = { "Slam Jam": ["US"], Atterley: ["US", "GB"], Italist: ["GB"] };

    const showTaxIncludedLabel =
        taxIncRetailers.hasOwnProperty(retailerName) &&
        taxIncRetailers[retailerName].includes(environment.get("country")) &&
        environment.getFeature("mg_show_tax_included_label");

    // "Taxes and duties included"
    const taxIncludedLabel = gettext("product.buy_area.taxes_duties_included.label");

    let shippingCostLabel = "";
    let guaranteedFreeShipping = false;
    if (selectedShipping) {
        if (selectedShipping.is_free) {
            guaranteedFreeShipping = true;
            shippingCostLabel = gettext("general.free_shipping");
        } else {
            shippingCostLabel = gettext("in_stock_product.shipping_cost", {
                shipping_cost: selectedShipping.formatted_price,
            });
        }
    }

    return (
        <div className={styles.priceArea}>
            <span className={styles.bothPrices}>
                {isOnSale ? (
                    <>
                        <span
                            className={clsx(styles.price, styles.reducedPrice, {
                                [styles.oos]: !isInStock,
                            })}
                            dangerouslySetInnerHTML={{ __html: currentPriceHtml }}
                        />
                        <span className={styles.originalPrice}>
                            {" "}
                            <del>{formattedPrice}</del>
                        </span>
                    </>
                ) : (
                    <span
                        className={clsx(styles.price, {
                            [styles.oos]: !isInStock,
                        })}
                    >
                        {formattedPrice}
                    </span>
                )}
            </span>
            {shippingCostLabel && (
                <span className={clsx(styles.shippingCost)}>
                    {guaranteedFreeShipping && (
                        <SVGIcon name="truck" className={styles.freeShippingIcon} />
                    )}
                    <span className={styles.shippingCostLabel}>{shippingCostLabel}</span>
                </span>
            )}
            {showTaxIncludedLabel && (
                <span className={styles.taxIncludedLabel}>{taxIncludedLabel}</span>
            )}

            <div className={styles.retailArea}>
                {retailAreaText ? (
                    <span dangerouslySetInnerHTML={{ __html: retailAreaText }} />
                ) : (
                    <RetailerLink retailerName={retailerName} leadUrl={leadUrl} />
                )}
            </div>
        </div>
    );
}

PriceInfo.propTypes = {
    currentPriceHtml: PropTypes.string.isRequired,
    formattedPrice: PropTypes.string.isRequired,
    isInStock: PropTypes.bool,
    isOnSale: PropTypes.bool.isRequired,
    leadUrl: PropTypes.string.isRequired,
    saleCountLabel: PropTypes.string,
    retailerName: PropTypes.string,
    selectedShipping: ShippingOptionPropType,
    retailAreaText: PropTypes.string,
};

PriceInfo.defaultProps = {
    isInStock: true,
    saleCountLabel: null,
};

export default PriceInfo;

import clsx from "clsx";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import AppDownloadBadge from "web/react/components/app-download-badge/app-download-badge";
import BurgerMenuCountrySelector from "web/react/components/burger-menu/burger-menu-country-selector-menu/burger-menu-country-selector-menu";
import LanguageSelector from "web/react/components/burger-menu/burger-menu-language-selector/burger-menu-language-selector";
import { useNavigationContext } from "web/react/components/navigation/navigation.context";
import { Button } from "web/react/emo/button";
import { HStack } from "web/react/emo/h-stack";
import { Text } from "web/react/emo/text";
import { VStack } from "web/react/emo/v-stack";
import { View } from "web/react/emo/view";
import { useKevelCampaignControl } from "web/react/hooks/use-kevel-campaign-control/use-kevel-campaign-control";
import withReduxProvider from "web/react/redux-provider";
import { setSelectedGender } from "web/redux/ducks/main-navigation";
import analytics from "web/script/analytics/analytics";
import { gettext } from "web/script/modules/django-i18n";
import requester from "web/script/modules/requester";
import { canUseMembership } from "web/script/modules/utils";
import { LanguageChoicePropInterface } from "web/types/language";
import {
    BasicMenuSerializer,
    HeaderGenderedMenuSerializer,
    MyListMenuSerializer,
} from "web/types/serializers";
import { GenderMenu } from "./gender-menu";
import * as styles from "./navigation-menu.css";
import { SlideContainer } from "./slide-container";
import { SubCategory } from "./subcategory";

interface GenderButtonProps {
    title: string;
    isActive: boolean;
    onGenderClick: () => void;
}

function GenderButton({ title, isActive, onGenderClick }: GenderButtonProps): React.ReactElement {
    const { isRebrand } = useNavigationContext();

    return (
        <button
            data-testid={`gender-button-${title}-active-${isActive}`}
            type="button"
            onClick={(): void => onGenderClick()}
            className={clsx({
                [styles.genderOptionRebrand]: isRebrand,
                [styles.genderOption]: !isRebrand,
                [styles.genderActive]: isActive,
            })}
        >
            <Text textStyle="footnote">{gettext(`header.gender.${title}`)}</Text>
        </button>
    );
}

export interface NavigationMenuProps {
    data: HeaderGenderedMenuSerializer;
    myListMenu: MyListMenuSerializer;
    isAuthenticated: boolean;
    currentGender: string;
    showCountryPicker: boolean;
    currentCountry: string;
    currentCurrency: string;
    languageSelector: {
        is_shown: boolean;
        callout_text: string;
        choices: LanguageChoicePropInterface[];
        num_columns: number;
    };
    blockBotsNavigation?: boolean;
}

export function _NavigationMenu({
    data,
    myListMenu,
    isAuthenticated,
    currentGender,
    showCountryPicker = false,
    currentCountry = "",
    currentCurrency = "",
    languageSelector,
    blockBotsNavigation = false,
}: NavigationMenuProps): React.ReactElement | null {
    const dispatch = useDispatch();

    const [hasRendered, setHasRendered] = useState<boolean>(false);
    const [categories, setCategories] = useState<any[]>([]);
    const [menuIndex, setMenuIndex] = useState(0);
    const [renderCountries, setRenderCountries] = useState(false);
    const [renderLanguages, setRenderLanguages] = useState(false);
    const [animateIn, setAnimateIn] = useState<boolean>(false);
    const [allCountries, setAllCountries] = useState([]);

    const {
        showMenu,
        setShowMenu,
        hideGenderControl,
        setHideGenderControl,
        handleNavigationAnalytics,
        handleMenuOpenedAnalytics,
        isRebrand,
    } = useNavigationContext();

    const genderMenuArray = [data.F, data.M];
    const COUNTRY_SLIDE = 2;
    const LANGUAGE_SLIDE = 3;
    let currentLanguage = languageSelector.choices.find(
        (language) => language.is_selected === true
    );

    const { campaignDetails: branchLinkDetails, onLoad } = useKevelCampaignControl("branch_link");
    useEffect(() => {
        if (menuIndex === COUNTRY_SLIDE) {
            setHideGenderControl(true);
        } else {
            setHideGenderControl(false);
        }
    }, [menuIndex, setHideGenderControl]);

    function scrollToTop(): void {
        const menuContent = document.getElementById("burgerMenuContent");
        if (menuContent !== null) {
            menuContent.scrollTop = 0;
        }
    }

    function showNextCategory(nextCategory: BasicMenuSerializer): void {
        if (nextCategory.url) {
            const newCategories = [...categories, nextCategory];
            setCategories(newCategories);
            setMenuIndex(newCategories.length);
            scrollToTop();
        }
    }

    function goBackCategory(): void {
        categories.pop();
        setMenuIndex(categories.length);
        setCategories([...categories]);
        scrollToTop();
    }

    async function showCountrySlide(): Promise<void> {
        if (!allCountries.length) {
            const data = await requester.get("/account/get_countries/");
            setAllCountries(data.all_countries);
        }

        analytics.event("SETTINGS", "SHOW_COUNTRY_OVERLAY");
        setRenderCountries(true);
        setMenuIndex(COUNTRY_SLIDE);
        scrollToTop();
    }

    function hideCountrySlide(): void {
        setRenderCountries(false);
        setMenuIndex(0);
        scrollToTop();
    }

    function showLanguageSlide(): void {
        setRenderLanguages(true);
        setMenuIndex(LANGUAGE_SLIDE);
        scrollToTop();
    }

    function hideLanguageSlide(): void {
        setRenderLanguages(false);
        setMenuIndex(0);
        scrollToTop();
    }

    function onGenderClick(gender: string): void {
        goBackCategory();
        dispatch(setSelectedGender(gender));
        handleMenuOpenedAnalytics("opened", gender);
    }

    const isWomenMenu = currentGender === "women";

    if (showMenu) {
        scrollToTop();
    }

    // Track first render of the navigation
    useEffect(() => {
        const onKeyDown = (event: KeyboardEvent): void => {
            if (event.key === "Escape") {
                setShowMenu(null);
            }
        };
        window.addEventListener("keydown", onKeyDown);

        if (showMenu) {
            setAnimateIn(true);
            setHasRendered(true);
        }

        return () => {
            setAnimateIn(false);
            window.removeEventListener("keydown", onKeyDown);
        };
    }, [showMenu, setShowMenu]);

    useEffect(() => {
        if (showMenu === "mobile") {
            // fire kevel event when menu is visible
            onLoad();
            document.body.classList.add("no-scroll");
        } else {
            document.body.classList.remove("no-scroll");
        }
    });

    // We only prevent rendering of the navigation on initial page load and if the user-agent is not a bot
    // hasRendered -> boolean to indicate if we have already openened the navigation once so we don't remove the elements from the DOM when we close it
    // visible -> coming from our Redux store, controlling the visibility
    // blockBotsNavigation -> boolean indicating if the page is visited by a bot
    // ORG-3123 changes this logic - we don't fullRender if a bot
    if (!hasRendered && !showMenu && !blockBotsNavigation) {
        return null;
    }

    function onOverlayInteract(event): void {
        const navContainer = document.getElementById("burgerMenuContent");
        if (!navContainer?.contains(event.target)) {
            setShowMenu(null);
        }
    }

    return (
        <div className={styles.overlay} onClick={onOverlayInteract}>
            <nav
                id="burgerMenuContent"
                className={clsx({
                    [styles.navRebrand]: isRebrand,
                    [styles.nav]: !isRebrand,
                    [styles.navOpen]: showMenu === "mobile",
                    [styles.animateIn]: animateIn,
                })}
                aria-label="Lyst navigation menu"
            >
                {!isAuthenticated && canUseMembership() && (
                    <>
                        <div className={styles.signInButtonContainer}>
                            <Button
                                href={myListMenu.register_url}
                                onClick={() => {
                                    handleNavigationAnalytics("signup_or_login_link");
                                }}
                                title={
                                    /* "Sign up or log in" */
                                    gettext("header.navigation.sign_in_log_in.cta")
                                }
                                width="full"
                            />
                        </div>
                        <View className={styles.divider} marginX="sm" />
                    </>
                )}
                <div>
                    <div
                        className={clsx(styles.navTopContainer, {
                            [styles.hideItem]: hideGenderControl,
                        })}
                    >
                        <div
                            className={
                                isRebrand ? styles.genderSelectorRebrand : styles.genderSelector
                            }
                        >
                            <HStack align={"center"} justify={"center"}>
                                <GenderButton
                                    title={"women"}
                                    isActive={isWomenMenu}
                                    onGenderClick={(): void => {
                                        onGenderClick("women");
                                    }}
                                />
                                <GenderButton
                                    title={"men"}
                                    isActive={!isWomenMenu}
                                    onGenderClick={(): void => {
                                        onGenderClick("men");
                                    }}
                                />
                            </HStack>
                        </div>
                    </div>
                    <SlideContainer index={menuIndex}>
                        <div>
                            <div
                                className={clsx(styles.genderMenuContainer, {
                                    [styles.genderMenuSwitch]: !isWomenMenu,
                                })}
                            >
                                {genderMenuArray.map((genderMenu) => (
                                    <GenderMenu
                                        key={genderMenu.display_name}
                                        menu={genderMenu}
                                        isAuthenticated={isAuthenticated}
                                        myListMenu={myListMenu}
                                        fullRender={blockBotsNavigation}
                                        showCountryPicker={showCountryPicker}
                                        onCategoryClick={showNextCategory}
                                        onCountrySelectorClick={showCountrySlide}
                                        currentCountry={currentCountry}
                                        currentCurrency={currentCurrency}
                                        currentLanguage={currentLanguage}
                                        onLanguageSelectorClick={showLanguageSlide}
                                        showLanguageSelector={languageSelector.is_shown}
                                    />
                                ))}
                            </div>
                        </div>

                        {categories && (
                            <div>
                                {categories.map((category) => {
                                    return (
                                        <SubCategory
                                            key={category.display_name}
                                            title={category.display_name}
                                            submenu={category}
                                            show={true}
                                            onCategoryClick={showNextCategory}
                                            onClose={goBackCategory}
                                            fullRender={blockBotsNavigation}
                                        />
                                    );
                                })}
                            </div>
                        )}

                        {renderCountries && (
                            <BurgerMenuCountrySelector
                                allCountries={allCountries}
                                currentCountry={currentCountry}
                                currentCurrency={currentCurrency}
                                onBackToMainClick={hideCountrySlide}
                            />
                        )}

                        {renderLanguages && (
                            <LanguageSelector
                                allLanguages={languageSelector.choices}
                                onBackToMainClick={hideLanguageSlide}
                            />
                        )}
                    </SlideContainer>
                </div>
                <View
                    className={styles.appPromoContainer}
                    paddingX="lg"
                    paddingTop="sm"
                    paddingBottom="xxs"
                >
                    <VStack spacing="sm">
                        <Text textStyle="body-2">{gettext("general.navigation_app_promo")}</Text>
                        <HStack spacing="sm">
                            <AppDownloadBadge
                                small
                                color="black"
                                eventLabel="navigation_download_badge"
                                branchLink={branchLinkDetails?.branchLink}
                            />
                            <AppDownloadBadge
                                small
                                color="black"
                                eventLabel="navigation_download_badge"
                                branchLink={branchLinkDetails?.branchLink}
                                badgeType="android"
                            />
                        </HStack>
                    </VStack>
                </View>
            </nav>
        </div>
    );
}

export const NavigationMenu = withReduxProvider(_NavigationMenu);

import clsx from "clsx";
import React, { useEffect, useRef, useState } from "react";
import SVGIcon from "web/react/components/svg-icon/svg-icon";
import analytics from "web/script/analytics/analytics";
import { gettext } from "web/script/modules/django-i18n";
import environment from "web/script/modules/environment";
import { getHtmlProps } from "web/script/modules/html-tag-props";
import userProfiler from "web/script/modules/userprofiler";
import * as styles from "./omnibus-fashionclip-banner.css";

function OmnibusFashionClipBannerCopy(): JSX.Element {
    const ref = useRef<HTMLSpanElement>(null);

    useEffect(() => {
        const spanRef = ref.current;

        const handleClick = (e): void => {
            // only track clicks on the anchor tag
            if (spanRef && spanRef.contains(e.target) && e.target.tagName === "A") {
                analytics.event("feedback_banner", "cta_click");
            }
        };

        if (spanRef) {
            spanRef.addEventListener("click", handleClick);
        }

        return () => {
            if (spanRef) {
                spanRef.removeEventListener("click", handleClick);
            }
        };
    }, []);

    const omnibusLinkProps = getHtmlProps({
        href: environment.get("contactUsForm"),
        target: "_blank",
        rel: "noreferrer noopener",
        class: styles.copyAnchor,
    });

    return (
        <span
            ref={ref}
            className={styles.copy}
            dangerouslySetInnerHTML={{
                __html: gettext("feed.omnibus_fashionclip_disclaimer.label", {
                    omnibus_link_props: omnibusLinkProps,
                }),
            }}
        />
    );
}

export function OmnibusFashionClipBanner(): JSX.Element | null {
    const [isClosed, setIsClosed] = useState(true);

    function handleClick(): void {
        analytics.event("feedback_banner", "close");
        userProfiler.setOmnibusFashionClipIsClosed(true);
        setIsClosed(!isClosed);
    }

    useEffect(() => {
        setIsClosed(userProfiler.hasOmnibusFashionClipBeenClosed());
    }, []);

    if (isClosed) {
        return null;
    }

    return (
        <div className={styles.container}>
            <SVGIcon name={"info-icon"} className={styles.icons}></SVGIcon>
            <OmnibusFashionClipBannerCopy />
            <button onClick={handleClick} className={clsx(styles.icons, styles.closeIcon)}>
                <SVGIcon name={"close"}></SVGIcon>
            </button>
        </div>
    );
}

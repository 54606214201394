import loader from "./loader";

// defined outside of the loader,
// so that the window.snaptr function works even if we do not load the script
var a = (window.snaptr = function () {
    a.handleRequest ? a.handleRequest.apply(a, arguments) : a.queue.push(arguments);
});

if (!window._snaptr) {
    window._snaptr = a;
}
a.push = a;
a.loaded = true;
a.queue = [];

const load = loader(() => {
    (function (e, t, n) {
        var s = "script";
        var r = t.createElement(s);
        r.async = !0;
        r.src = n;
        var u = t.getElementsByTagName(s)[0];
        u.parentNode.insertBefore(r, u);
    })(window, document, "https://sc-static.net/scevent.min.js");
});

export default function (...args) {
    load();
    return window.snaptr(...args);
}

import React from "react";
import { useNavigate } from "react-router-dom";
import { resetPassword } from "web/apis";
import { UserEmailCard } from "web/react/components/user-email-card";
import { Button } from "web/react/emo/button";
import { Heading } from "web/react/emo/heading";
import { ResponsiveHeadingStyle } from "web/react/emo/sprinkles.css";
import { Text } from "web/react/emo/text/text";
import { useToast } from "web/react/emo/toast/useToast";
import { VStack } from "web/react/emo/v-stack";
import { View } from "web/react/emo/view";
import analytics from "web/script/analytics/analytics";
import { gettext } from "web/script/modules/django-i18n";
import styles from "./forgot-password-email-sent-screen.module.css";

export interface ForgotPasswordEmailSentScreenProps {
    email: string;
    headingStyle?: ResponsiveHeadingStyle;
}

export const ForgotPasswordEmailSentScreen = ({
    email,
    headingStyle = { sm: "large-title-2", lg: "large-title" },
}: ForgotPasswordEmailSentScreenProps): JSX.Element => {
    const navigate = useNavigate();
    const toast = useToast();

    async function onClick(): Promise<void> {
        analytics.event("forgot_password_sent", "click", "resend_email_link");

        try {
            await resetPassword(email);
            toast({ message: gettext("account.email_sent_check_inbox.label") });
        } catch (e) {
            analytics.event("forgot_password_sent", "error", "resend_email_link");
        }
    }

    return (
        <>
            <View className={styles.wrapper}>
                <VStack spacing="md">
                    <Heading textStyle={headingStyle} as="h1">
                        {/* Email link sent */}
                        {gettext("account.resend_reset_link.title")}
                    </Heading>
                    <Text textStyle={"body-1"}>
                        {/* We have just sent an email to {user_email_address} to reset your password. Check your inbox. */}
                        {gettext("account.resend_reset_link.desc", {
                            user_email_address: email,
                        })}
                    </Text>
                    <UserEmailCard email={email} onClick={() => navigate("/forgot-password")} />
                    <Text textStyle="body-2" color="secondary">
                        {/* Didnt receive the email? */}
                        {gettext("account.no_email_received_reset_link.label")}
                    </Text>
                    <div>
                        <Button
                            width="full"
                            type="submit"
                            variant={"secondary"}
                            onClick={onClick}
                            title={
                                /* Resend email link */
                                gettext("account.resend_reset_link.cta")
                            }
                        />
                    </div>
                </VStack>
            </View>
        </>
    );
};

import noop from "lodash/noop";
import { useEffect, useState } from "react";
import { CheckoutLayoutSerializer, CheckoutShippingOptionSerializer } from "web/types/serializers";

interface UseDeliveryFormProps {
    initialData: CheckoutLayoutSerializer;
}

interface BaseDeliveryFormDetails {
    isDeliveryFormComplete: boolean;
    hasDeliveryFormErrored: boolean;
    onDeliverySelect: (deliveryOption: CheckoutShippingOptionSerializer | null) => void;
    selectedShipping: CheckoutShippingOptionSerializer | null;
    shippingMethods: CheckoutShippingOptionSerializer[];
    showDeliveryLoadingSpinner: boolean;
}

interface UseDeliveryForm extends BaseDeliveryFormDetails {
    onShippingMethodsChange: (shippingMethods: CheckoutShippingOptionSerializer[]) => void;
    savedShipping: CheckoutShippingOptionSerializer | null;
    setSavedShipping: (deliveryOption: CheckoutShippingOptionSerializer | null) => void;
    setDeliveryFormComplete: (isComplete: boolean) => void;
    setDeliveryFormErrored: (errored: boolean) => void;
    setDeliveryLoadingSpinner: (loading: boolean) => void;
}

export interface CheckoutContextDeliveryForm extends BaseDeliveryFormDetails {
    isDeliveryFormDisabled: boolean;
    onClickDeliveryFormCTA: () => void;
}

export const CHECKOUT_CONTEXT_DELIVERY_FORM_DEFAULT_DATA: CheckoutContextDeliveryForm = {
    isDeliveryFormComplete: false,
    isDeliveryFormDisabled: false,
    hasDeliveryFormErrored: false,
    onClickDeliveryFormCTA: noop,
    onDeliverySelect: noop,
    selectedShipping: null,
    shippingMethods: [],
    showDeliveryLoadingSpinner: false,
};

function useDeliveryForm({ initialData }: UseDeliveryFormProps): UseDeliveryForm {
    const [shippingMethods, updateShippingMethods] = useState<CheckoutShippingOptionSerializer[]>(
        initialData.products[0].shipping_methods
    );
    const [selectedShipping, updateSelectedShipping] =
        useState<CheckoutShippingOptionSerializer | null>(
            initialData.products[0].shipping_methods.find((shipping) => shipping.is_selected) ||
                null
        );
    const [savedShipping, setSavedShipping] = useState<CheckoutShippingOptionSerializer | null>(
        initialData.products[0].shipping_methods.find((shipping) => shipping.is_selected) || null
    );
    const [isDeliveryFormComplete, setDeliveryFormComplete] = useState<boolean>(
        initialData.is_delivery_complete
    );
    const [hasDeliveryFormErrored, setDeliveryFormErrored] = useState<boolean>(false);
    const [showDeliveryLoadingSpinner, setDeliveryLoadingSpinner] = useState<boolean>(false);

    function onShippingMethodsChange(shippingMethods: CheckoutShippingOptionSerializer[]): void {
        updateShippingMethods(shippingMethods);
    }

    function onDeliverySelect(deliveryOption: CheckoutShippingOptionSerializer | null): void {
        updateSelectedShipping(deliveryOption);
    }

    useEffect(() => {
        updateSelectedShipping(savedShipping);
    }, [savedShipping]);

    return {
        isDeliveryFormComplete,
        hasDeliveryFormErrored,
        onDeliverySelect,
        onShippingMethodsChange,
        savedShipping,
        selectedShipping,
        setDeliveryFormComplete,
        setDeliveryLoadingSpinner,
        setDeliveryFormErrored,
        setSavedShipping,
        shippingMethods,
        showDeliveryLoadingSpinner,
    };
}

export default useDeliveryForm;

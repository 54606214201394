/**
 * Defines snapchat event types.
 * See https://businesshelp.snapchat.com/s/article/pixel-website-install?language=en_US for a list of events
 */
export default {
    EVENT_NAMES: ["ADD_CART", "PAGE_VIEW", "PURCHASE", "VIEW_CONTENT"],

    cart: {
        action: "add_item",
        label: "add_to_cart_button",
        name: "ADD_CART",
    },
    page_view: {
        name: "PAGE_VIEW",
        data: {
            item_ids: "",
        },
    },
    affiliate: {
        action: "lead",
        name: "PURCHASE",
    },
    view_content: {
        name: "VIEW_CONTENT",
    },
};

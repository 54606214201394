/* eslint-disable @typescript-eslint/no-non-null-assertion */
import * as Sentry from "@sentry/browser"; // eslint-disable-line import/no-namespace
import analytics from "web/script/analytics/analytics";
import { RequesterError, RothkoError } from "web/script/modules/errors";

declare global {
    interface Window {
        NREUM: typeof newrelic | undefined;
    }
}

let lastException: any;

function getRequestError(error: Error): undefined | RequesterError {
    if (error instanceof RequesterError) {
        return error;
    }
    if (error instanceof RothkoError) {
        return error.reason;
    }
}

/**
 * Report an error to NewRelic (if available)
 */
function reportError(error: Error, data?: Record<string, any>): void {
    if (window.NREUM) {
        (window.NREUM.noticeError as any)(error, data);
    }

    Sentry.withScope((scope) => {
        Sentry.captureException(error, {
            extra: {
                data,
            },
            contexts: {
                analytics: {
                    page_view_id: analytics.getPageViewId(),
                    device_id: analytics.getDeviceUid(),
                },
            },
        });

        const requestError = getRequestError(error);

        if (requestError) {
            scope.setFingerprint(
                [
                    requestError.options?.method || "GET",
                    requestError.url.pathname,
                    String(requestError?.response.status),
                ].filter(Boolean)
            );
        }
    });
}

/**
 * Handle an error by sending data to New Relic (if available).
 */
function handleError(errorOrMessage: Error | string, ...args: string[]): void {
    const error = typeof errorOrMessage === "string" ? new Error(errorOrMessage) : errorOrMessage;

    // checking for duplicate exceptions so we don't spam New Relic
    if (lastException === error) {
        return;
    }

    lastException = error;
    console.error(...args, error);
    reportError(error);
}

if (window.NREUM) {
    const SCRIPT_ERROR = /^Script error\.?$/i;
    const SHOPTAGR_QUERY = /\.oos-product-hero__alert-body span/;
    const SAVED_SEARCH = /^Request for .+saved-search.+ responded with 404/;
    const FETCH_ERROR = /^Failed to make request to \/.+!$/;

    // Pick which errors we want to discard
    window.NREUM.setErrorHandler((err) => {
        // Try to deal with things that aren't Errors
        let message: string;
        if (typeof err == "string") {
            message = err;
        } else if (err && typeof err["message"] != "undefined") {
            message = err.message + "";
        } else {
            // I have no idea what this is so just let it through
            return false;
        }

        // Just in case there's stray whitespace somehow
        message = message.trim();

        if (SCRIPT_ERROR.test(message)) {
            // Useless unsolvable error caused by one of our third party scripts
            // See also: https://trackjs.com/blog/script-error-javascript-forensics/
            return true;
        } else if (
            err.message == "Cannot read property 'textContent' of undefined" ||
            SHOPTAGR_QUERY.test(message)
        ) {
            // Shoptagr is a very poorly coded extension that spews errors
            //  all over the place clogging up NewRelic.
            // Possibly we could work out some way to intentionally break
            //  it, but for now just block its errors.
            return true;
        } else if (SAVED_SEARCH.test(message)) {
            // We log an error for every failed request, even when the
            // "failure" is an expected 404 response
            return true;
        } else if (FETCH_ERROR.test(message)) {
            // While nominally we'd like to know about all these requests
            //  disappearing into the ether, they just completely swamp newrelic
            //  and none of them are actionable.
            // Ideally this should be increasing a metric somewhere but we don't
            //  have the ability to do that right now.
            return true;
        }
        return false;
    });
}

// public API
export default {
    error: handleError,
    reportError,
};

import "web/react/emo/theme.css.ts.vanilla.css!=!../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"web/react/emo/theme.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA6WUTW/bMAyG7/sVRHzZgHSwPizL3Em2JayH7bDuPjip06b5cJqkSdth/32gMxQzPZ0G+fRQpqiXfIX7rjvCz3cAV1c/xP52JcU5RUjSNP00gAIhsdpY4YdcIiRBhdQXQ64QkjJzOrdDrhGSIs1zlQ15hpAYlynB9hvK40sV5JDnCImvvA3lkFuEpCorGVj+guovrHBhyBuEJAuZy1n9M4REV9oaMeRzhKRW0kumwy3p4L3zbshb4qVVluVZEHc60+xed6RPJmrB7nWPkMhamcDqX1Kzgig5f+ibaF3KdFiRbiY4nw/5mvQvvcpZ3zd9HhdStn/bc21SNeRdRJ9dRJ/HiD77iD6HiD7HPn9h+dw+EQ8h82bIT/3+ShjGzz13tWL3eu7nKnc8/wtCIpwyJeOvpLP1ZWC6CXJXbqypucHIYSKTpa1ZgCyWOmE0K1WQx2QurWSzK3SkKEEuqx0tFjB9G4ILPEA+C1nQ3H+CjFb7uq55VUXsjObSisBHVcxih88vj4sKrBmC3OZzWizQxg4nv1WeFguQ4SpNiwXIcUVJiwXIcnlKiwXIc9rTYoFVrFHry1M7fm03CPu7WfM+ncKf76P+wPZsYz93sQJ3sT8eI6+7IBvm0pQFs6c4RHwujpGHR5ATq1wayYU+RawlzrExfo5N0kss8BppjUwjkkgREVHKyMBIhcD3agS9e2YwQ7AjaBCEHNEcQZgRtQhynLZAUOmINghqnHeGoMd75wh6XNktghmf1iLYcYYFQjGu947uNt58j3DztDwcWrjeHtcsuHwLfum2HQs+IFyfmj3cdIsjC60GSacw+dyuT+1xOW/ga/vUTqbwBqbg9stmPYVDsz1cHdr9csGSrf86ZwqT78tNe4Cv7Rm+dZtmO5lCT6bwr383gwv8ZyFb6hcfrg5BZxzuELLRzkcEM4J7BKEuM/frN5j8aoMHCgAA\"}!../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
import "web/react/emo/expandable-text/expandable-text.css.ts.vanilla.css!=!../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"web/react/emo/expandable-text/expandable-text.css.ts.vanilla.css\",\"source\":\"Lm5ibnk5ZzEgewogIG92ZXJmbG93OiBoaWRkZW47CiAgdHJhbnNpdGlvbjogaGVpZ2h0IDAuM3MgZWFzZS1vdXQ7CiAgaGVpZ2h0OiB2YXIoLS1uYm55OWcwKTsKfQoubmJueTlnMiB7CiAgYm9yZGVyOiAxcHggc29saWQgdmFyKC0tXzFyZGsyMXcxZSk7CiAgYm9yZGVyLXJhZGl1czogOHB4Owp9Ci5uYm55OWczIHsKICBwYWRkaW5nOiAxMnB4Owp9Ci5uYm55OWc0IHsKICB0ZXh0LWRlY29yYXRpb246IHVuZGVybGluZTsKICB0ZXh0LWFsaWduOiBzdGFydDsKfQoubmJueTlnNSB7CiAgaGVpZ2h0OiBhdXRvOwp9Ci5uYm55OWc2IHsKICBsaW5lLWhlaWdodDogMjRweDsKfQoubmJueTlnNyB7CiAgd2lkdGg6IC1tb3otYXZhaWxhYmxlOwogIHdpZHRoOiAtd2Via2l0LWZpbGwtYXZhaWxhYmxlOwogIHdpZHRoOiBzdHJldGNoOwp9Ci5uYm55OWc4IHsKICBiYWNrZ3JvdW5kLWNvbG9yOiB2YXIoLS1fMXJkazIxdzFhKTsKICBwYWRkaW5nOiB2YXIoLS1fMXJkazIxdzI1KTsKfQoubmJueTlnOSB7CiAgZGlzcGxheTogZmxleDsKICBqdXN0aWZ5LWNvbnRlbnQ6IGZsZXgtZW5kOwogIGJhY2tncm91bmQ6IHRyYW5zcGFyZW50OwogIHBvc2l0aW9uOiByZWxhdGl2ZTsKICBoZWlnaHQ6IDA7CiAgYm90dG9tOiB2YXIoLS1fMXJkazIxdzI3KTsKICByaWdodDogdmFyKC0tXzFyZGsyMXcyNSk7Cn0KLm5ibnk5Z2EgewogIGRpc3BsYXk6IGZsZXg7CiAgYWxpZ24taXRlbXM6IGNlbnRlcjsKICBjb2x1bW4tZ2FwOiA4cHg7Cn0KLm5ibnk5Z2IgewogIHdpZHRoOiAxMDAlOwogIGhlaWdodDogMXB4OwogIGJhY2tncm91bmQ6IHZhcigtLV8xcmRrMjF3MWUpOwp9Ci5uYm55OWdjIHsKICB0cmFuc2l0aW9uOiB0cmFuc2Zvcm0gMC4zcyBlYXNlLW91dDsKICB0cmFuc2Zvcm06IHJvdGF0ZVgoMTgwZGVnKTsKfQoubmJueTlnZCB7CiAgd2lkdGg6IHZhcigtLV8xcmRrMjF3MjUpOwogIGhlaWdodDogdmFyKC0tXzFyZGsyMXcyNSk7Cn0=\"}!../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var boxContainer = 'nbny9g2';
export var boxTextContainer = 'nbny9g3';
export var buttonOpaque = 'nbny9g8';
export var controlButtonText = 'nbny9g4';
export var expandableBoxButton = 'nbny9g9';
export var expandableButton = 'nbny9g7';
export var expandableDividerContainer = 'nbny9ga';
export var expandableDividerLine = 'nbny9gb';
export var expandableIconOpenIcon = 'nbny9gc';
export var expandableTextContainer = 'nbny9g5';
export var smallIcon = 'nbny9gd';
export var text = 'nbny9g6';
export var textHeight = 'var(--nbny9g0)';
export var wrapper = 'nbny9g1';
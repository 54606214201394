import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { FeedHeaderSerializer } from "web/types/serializers";

const initialState: FeedHeaderSerializer = {
    title: null,
    description: null,
    short_description: null,
    designer_name: null,
    gender: "",
    search_next: null,
    product_link: null,
    photo_url: null,
    small_photo_url: null,
    photo_alt_text: null,
    follow_button: null,
    brand_ad: null,
    promotion: null,
    retailer_hub_link: null,
};

const feedHeader = createSlice({
    name: "feedHeader",
    initialState: initialState,
    reducers: {
        setFeedHeader(state, action: PayloadAction<FeedHeaderSerializer>): void {
            state.title = action.payload.title;
            state.description = action.payload.description;
            state.short_description = action.payload.short_description;
            state.designer_name = action.payload.designer_name;
            state.gender = action.payload.gender;
            state.search_next = action.payload.search_next;
            state.product_link = action.payload.product_link;
            state.photo_url = action.payload.photo_url;
            state.small_photo_url = action.payload.small_photo_url;
            state.photo_alt_text = action.payload.photo_alt_text;
            state.follow_button = action.payload.follow_button;
            state.brand_ad = action.payload.brand_ad;
            state.promotion = action.payload.promotion;
            state.retailer_hub_link = action.payload.retailer_hub_link;
        },
    },
});

export const { setFeedHeader } = feedHeader.actions;
export default feedHeader.reducer;

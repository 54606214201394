import React, { createContext, useCallback, useContext, useEffect, useState } from "react";
import { useDomViewport } from "web/react/hooks/use-dom-viewport/use-dom-viewport";
import analytics from "web/script/analytics/analytics";

export const NAV_ANALYTICS_CATEGORY = "top_nav";

type MenuCategoryType = "product_type" | "product_category" | "featured_category";

type ViewType = "desktop" | "mobile" | null;

type ActionType = "clicked" | "impression" | "opened" | "closed";
interface NavigationContextProps {
    showMenu: ViewType;
    setShowMenu: React.Dispatch<React.SetStateAction<ViewType>>;
    showSearch: ViewType;
    setShowSearch: React.Dispatch<React.SetStateAction<ViewType>>;
    handleMenuOpenedAnalytics: (
        menuState: Omit<ActionType, "clicked" | "impression">,
        targetGender: string
    ) => void;
    handleCategoryAnalytics: (categoryType: MenuCategoryType, action?: ActionType) => void;
    handleHeaderAnalytics: (buttonName: string, action?: ActionType) => void;
    handleNavigationAnalytics: (navButtonName: string) => void;
    hideGenderControl: boolean;
    setHideGenderControl: React.Dispatch<React.SetStateAction<boolean>>;
    isRebrand: boolean;
}
interface NavigationContextProviderProps {
    isRebrand: boolean;
    children: React.ReactNode;
}

const NavigationContext = createContext<NavigationContextProps>({
    showMenu: null,
    setShowMenu: () => {},
    showSearch: null,
    setShowSearch: () => {},
    handleMenuOpenedAnalytics: () => {},
    handleNavigationAnalytics: () => {},
    handleHeaderAnalytics: () => {},
    handleCategoryAnalytics: () => {},
    hideGenderControl: false,
    setHideGenderControl: () => {},
    isRebrand: false,
});

export function NavigationContextProvider({
    isRebrand = false,
    children,
}: NavigationContextProviderProps): React.ReactElement {
    const [showMenu, setShowMenu] = useState<ViewType>(null);
    const [showSearch, setShowSearch] = useState<ViewType>(null);
    const [hideGenderControl, setHideGenderControl] = useState(false);

    const { isDesktopViewport } = useDomViewport();
    const handleMenuOpenedAnalytics = useCallback(function (menuState, targetGender): void {
        analytics.event("top_nav", menuState, `nav_${targetGender.toLowerCase()}_menu`);
    }, []);

    const handleCategoryAnalytics = useCallback(function (categoryType, action = "clicked"): void {
        analytics.event("top_nav", action, categoryType);
    }, []);

    const handleNavigationAnalytics = useCallback(function (navButtonName): void {
        analytics.event("top_nav", "clicked", navButtonName);
    }, []);

    const handleHeaderAnalytics = useCallback(function (buttonName, action = "clicked"): void {
        analytics.event("top_nav", action, buttonName);
    }, []);
    /** Auto hides mobile search if viewport changes to desktop*/
    useEffect(() => {
        if (isDesktopViewport) {
            setShowSearch(null);
        }
    }, [isDesktopViewport]);

    const value = {
        showMenu,
        setShowMenu,
        showSearch,
        setShowSearch,
        handleMenuOpenedAnalytics,
        handleCategoryAnalytics,
        handleNavigationAnalytics,
        handleHeaderAnalytics,
        hideGenderControl,
        setHideGenderControl,
        isRebrand,
    };
    return <NavigationContext.Provider value={value}>{children}</NavigationContext.Provider>;
}

export function useNavigationContext(): NavigationContextProps {
    return useContext(NavigationContext);
}

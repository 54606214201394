import React, { useState } from "react";
import { useDispatch } from "react-redux";
import {
    follow,
    getCaptureType,
    unFollow,
} from "web/react/components/follow-designer-button/utils";
import { Button } from "web/react/emo/button";
import { openCustomerCaptureOverlay } from "web/redux/ducks/customer-capture-overlay";
import analytics from "web/script/analytics/analytics";

export interface FollowDesignerButtonProps {
    lystId: number;
    designerName?: string;
    followedText: string;
    notFollowedText: string;
    isAuthenticated: string;
    isFollowing: string;
    analyticsParameters: [string, string, string];
    downloadApp?: boolean;
}

export function FollowDesignerButton({
    lystId,
    designerName,
    followedText,
    notFollowedText,
    isAuthenticated,
    isFollowing,
    analyticsParameters,
}: FollowDesignerButtonProps): JSX.Element {
    const [following, setFollowing] = useState<boolean>(isFollowing !== "false");
    const dispatch = useDispatch();

    const buttonLabel = following ? followedText : notFollowedText;

    const [category, action, label] = analyticsParameters;

    function onClick(): void {
        analytics.event(category, action, label);
        if (isAuthenticated === "false") {
            dispatch(
                openCustomerCaptureOverlay({
                    designerId: lystId.toString(),
                    captureType: getCaptureType(analyticsParameters),
                    analyticsEventLabel: "follow_designer_button",
                    designerName,
                })
            );
        } else if (following) {
            unFollow(lystId, setFollowing);
        } else {
            follow(lystId, setFollowing);
        }
    }

    return (
        <div data-testid="follow-designer-button">
            <Button title={buttonLabel} onClick={onClick} size={"sm"} variant={"secondary"} />
        </div>
    );
}

export default FollowDesignerButton;

/* eslint-disable max-depth */
import { useMemo } from "react";
import { PendingFilters } from "web/react/components/feeds-filters/pending-filters";
import { formatPrice } from "web/script/modules/price";
import { AllSelectedFilterInfo, SelectedFilterInfo } from "web/types/feed-filters";
import {
    OptionFilterOption,
    PairOfSelectsFilterSection,
    UniversalFilterOption,
    UniversalFiltersData,
} from "web/types/universal-filters";

/**
 * This is a fun one because the server marks it as always selected regardless
 *  of what the user's done, so we need to figure out if anything's not on the
 *  default value.
 * The assumption is that this is the price selector and the defaults are the
 *  first and last options.
 */
function findPOSSelected(
    section: PairOfSelectsFilterSection,
    pending: PendingFilters
): [SelectedFilterInfo, number] {
    let firstGroup = section.option_groups[0];
    let secondGroup = section.option_groups[1];

    let firstOptions = Array.from(firstGroup.options);
    let secondOptions = Array.from(secondGroup.options);

    // On mobile, we need to append any pending values which aren't part
    // of the section list, so we can display a count before we apply the filters.
    // Ugly, but the walls set by these legacy filter data structures necessitates this.
    Array.from(pending.entries()).map((entry) => {
        // A pending entry key looks like e.g.:
        // "final_price_from=0"
        // Here we're splitting it up to get some meaning from the keyname.
        const separator = entry[0].indexOf("=");
        const name = entry[0].substring(0, separator);
        const value = +entry[0].substring(separator + 1);

        // A filter option has an ID e.g.
        // final_price_from-0
        const id = name + "-" + value;

        if (value === 0 || value === 1000 || value === 1000000) {
            return;
        }

        const option = {
            id,
            name,
            value: value,
            label: formatPrice(value).round().withCurrency(),
            checked: true,
            disabled: false,
            type: "option" as OptionFilterOption["type"],
            url: window.location.pathname,
        };

        if (name.includes("_from")) {
            firstOptions = [...firstOptions, option];
        }

        if (name.includes("_to")) {
            secondOptions = [option, ...firstOptions];
        }
    });

    const defaultFirst = firstOptions[0];
    const defaultSecond = secondOptions[secondOptions.length - 1];

    const first = firstOptions.find((o) => pending.getChecked(o)) || defaultFirst;
    const second = secondOptions.find((o) => pending.getChecked(o)) || defaultSecond;

    const isFirstNotDefault = first !== defaultFirst;
    const isSecondNotDefault = second !== defaultSecond;

    const numSelected = isFirstNotDefault || isSecondNotDefault ? 1 : 0;

    const breadcrumbs: UniversalFilterOption[] = [];

    // We want to have any changed price filters to show up in the pills
    // area, but they're kinda funky
    if (isFirstNotDefault) {
        breadcrumbs.push({
            ...first,
            // TODO: i18n
            label: `${firstGroup.title}: ${first.label}`,
        });
    }
    if (isSecondNotDefault) {
        breadcrumbs.push({
            ...second,
            // TODO: i18n
            label: `${secondGroup.title}: ${second.label}`,
        });
    }
    return [
        {
            breadcrumbs,
            counts: {},
        },
        numSelected,
    ];
}

function findNestedSelections(
    filters: UniversalFilterOption[],
    pending: PendingFilters,
    _data?: SelectedFilterInfo
): [SelectedFilterInfo, number] {
    if (!_data) {
        _data = {
            breadcrumbs: [],
            counts: {},
        };
    }
    let numAdded = 0;

    for (let filter of filters) {
        if (pending.getChecked(filter)) {
            if (filter.is_default) {
                continue;
            }
            _data.breadcrumbs.push(filter);
            numAdded++;
            if (filter.type == "category") {
                let [, num] = findNestedSelections(filter.children, pending, _data);
                numAdded += num;
                if (num > 0) {
                    _data.counts[`${filter.name}=${filter.value}`] = num;
                }
            }
        }
    }

    return [_data, numAdded];
}

export function useSelectedFilterInfo(
    displayedFilters: UniversalFiltersData,
    pendingFilterInfo: PendingFilters
): [number, AllSelectedFilterInfo] {
    return useMemo(() => {
        let numSelected = 0;
        let filterInfo: AllSelectedFilterInfo = {};
        let selected: SelectedFilterInfo;
        let count = 0;
        for (let section of displayedFilters.filter_section_list) {
            if (section.type === "pair_of_selects") {
                [selected, count] = findPOSSelected(section, pendingFilterInfo);
                // ORG-3733: Added an additional option group to the colors section
                // which contains the color_variants for a color
            } else if (section.type === "colors") {
                let options = section.option_groups.reduce(
                    (opts, group) => [...opts, ...group.options],
                    [] as any
                );

                [selected, count] = findNestedSelections(options, pendingFilterInfo);
            } else {
                [selected, count] = findNestedSelections(
                    section.option_groups[0].options,
                    pendingFilterInfo
                );
            }
            numSelected += count;
            filterInfo[section.id] = selected;
        }

        return [numSelected, filterInfo];
    }, [displayedFilters, pendingFilterInfo]);
}

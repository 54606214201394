import React, { useEffect, useState } from "react";
import SVGIcon from "web/react/components/svg-icon/svg-icon";
import { VStack } from "web/react/emo/v-stack";
import styles from "./validation-requirement.module.css";

export type ValidationRequirement = {
    description: string;
    pattern: RegExp;
};

interface RequirementProps extends ValidationRequirement {
    value: string;
}

function Requirement({ description, pattern, value }: RequirementProps): JSX.Element {
    const [isFulfilled, setIsFulfilled] = useState(pattern.test(value));

    useEffect(() => {
        setIsFulfilled(pattern.test(value));
    }, [value, pattern]);

    return (
        <li className={styles.requirement}>
            <SVGIcon
                name={isFulfilled ? "tick" : "close"}
                className={isFulfilled ? styles.fulfilled : styles.notFulfilled}
            />
            <p className={styles.description}>{description}</p>
        </li>
    );
}

export interface ValidationRequirementsProps {
    requirements: ValidationRequirement[];
    title: string;
    value: string;
}

export function ValidationRequirements({
    requirements,
    title,
    value,
}: ValidationRequirementsProps): JSX.Element {
    return (
        <div className={styles.root}>
            <VStack spacing={"none"}>
                <p>{title}</p>
                <ul>
                    {requirements.map(({ description, pattern }, index) => (
                        <Requirement
                            description={description}
                            pattern={pattern}
                            value={value}
                            key={index}
                        />
                    ))}
                </ul>
            </VStack>
        </div>
    );
}

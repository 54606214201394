/**
 * Creates a DOM element from an HTML string. The HTML string should represent a
 * single root node.
 * @param {string} html
 * @returns {HTMLElement}
 */
export function fromString(html) {
    var el = document.createElement("div");
    el.innerHTML = html.trim();
    return el.firstElementChild;
}

export default {
    fromString,
};

// Extracted from React docs: https://reactjs.org/docs/hooks-faq.html#how-to-get-the-previous-props-or-state
import { useEffect, useRef } from "react";

export function usePrevious<T>(value: T): T {
    const ref = useRef<T>();
    useEffect(() => {
        if (ref.current != value) {
            ref.current = value;
        }
    });
    return ref.current as T;
}

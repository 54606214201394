import clsx from "clsx";
import React, { useEffect, useState } from "react";
import SizeGuide from "web/react/components/buybuybuy-area/size-guide/size-guide";
import { Option } from "web/react/components/select/types";
import { SizePicker } from "web/react/components/size-picker/size-picker";
import analytics from "web/script/analytics/analytics";
import { Schemas } from "web/types/examples/schemas";
import { ProductImageSerializer, BuyBuyBuyAreaSize as Size } from "web/types/serializers";
import styles from "./size-options-form.module.css";

export interface SizeOptionsFormProps {
    action: string;
    csrfToken: string;
    country: string;
    returnUrl: string;
    availableSizes: number;
    sizes: Size[];
    sizeGuideLink: string;
    onSizeChange: (Size) => void;
    userDefaultSchema: string;
    schemas: Schemas;
    productImages?: ProductImageSerializer[];
}

export function SizeOptionsForm({
    action,
    csrfToken,
    country,
    returnUrl,
    availableSizes,
    sizes,
    sizeGuideLink,
    onSizeChange,
    userDefaultSchema,
    schemas,
}: SizeOptionsFormProps): React.ReactElement {
    const [sizePickerExpanded, setSizePickerExpanded] = useState(false);
    const [sizePickerError, setSizePickerError] = useState(false);
    const [searchingStores, setSearchingStores] = useState(false);
    const [renderStoresOptions, setRenderStoresOptions] = useState(false);

    useEffect(() => {
        if (sizePickerExpanded) {
            document.body.classList.add("no-scroll");
        }
        return (): void => {
            document.body.classList.remove("no-scroll");
        };
    }, [sizePickerExpanded]);

    function handleSubmit(e: React.FormEvent<HTMLFormElement>): void {
        e.preventDefault();

        setSizePickerExpanded(true);
    }

    function handleSizeChange(size: Size): void {
        setSizePickerError(false);
        setSearchingStores(true);

        onSizeChange(size);

        if (!renderStoresOptions) {
            setTimeout(() => {
                setRenderStoresOptions(true);
            }, 1400);
        }
    }

    function handleSelect(option: Option): void {
        if (!option.disabled) {
            handleSizeChange(option.value);
        } else {
            const category = "size_picker";
            const action = "select_oos_alert";
            analytics.event(
                category,
                action,
                option.selectedText,
                false,
                null,
                `checkout.${category}.${action}`,
                {
                    size_picker: {
                        size_change: {
                            to_size: option.value.raw_size,
                        },
                    },
                }
            );
        }
    }

    React.useEffect(() => {
        if (availableSizes === 1) {
            onSizeChange(sizes[0]);
            setSearchingStores(true);

            setTimeout(() => {
                setRenderStoresOptions(true);
            }, 600);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [availableSizes]);

    return (
        <div>
            <form
                className={clsx(styles.form, "mt10")}
                action={action}
                method="post"
                autoComplete="off"
                onSubmit={handleSubmit}
            >
                <input type="hidden" name="csrfmiddlewaretoken" value={csrfToken} />
                <input type="hidden" name="country" value={country} />
                <input type="hidden" name="return_url" value={returnUrl} />

                <SizePicker
                    onSelect={handleSelect}
                    isExpanded={sizePickerExpanded}
                    schemas={schemas}
                    setExpanded={setSizePickerExpanded}
                    sizes={sizes}
                    userDefaultSchema={userDefaultSchema}
                    productType="checkout"
                />

                <SizeGuide sizeGuideLink={sizeGuideLink} />

                <div className={styles.buttonWrapper}>
                    {!renderStoresOptions && (
                        <button
                            type="submit"
                            className={`${
                                sizePickerError ? styles.buttonErrorState : ""
                            } btn btn-cta btn--multiply btn--full-width buybuybuy-area__size-options__select-size-button`} // keeping buybuybuy-area className to register sticky button, safe to remove afterwards
                            disabled={searchingStores}
                        >
                            <div className="btn--multiply-label btn--multiply-label--top">
                                <span>
                                    {"Buy now"}
                                    {/* todo I18N-291 */}
                                </span>
                            </div>
                            <div className="btn--multiply-label btn--multiply-label--bottom">
                                <span>
                                    {"Buy now"}
                                    {/* todo I18N-291 */}
                                </span>
                            </div>
                            <div className="btn--multiply-label btn--multiply-label--center">
                                <span>
                                    {"Buy now"}
                                    {/* todo I18N-291 */}
                                </span>
                            </div>
                        </button>
                    )}
                </div>
            </form>
        </div>
    );
}

export default SizeOptionsForm;

import requester from "web/script/modules/requester";
import { NotQuiteAURL } from "./url";

export const SSO_PROVIDERS = {
    google: "google-oauth2",
    facebook: "facebook",
    apple: "apple-id",
};

export async function SocialLogin(
    parsedURL: NotQuiteAURL,
    authProvider: keyof typeof SSO_PROVIDERS
): Promise<string> {
    parsedURL.path = `/social/login/${authProvider}/`;

    const response = await requester(parsedURL.path, {
        body: parsedURL.searchParams,
    }).then((res) => res.json());

    return response.url;
}

import React from "react";
import { HStack } from "web/react/emo/h-stack";
import { Text } from "web/react/emo/text";
import styles from "./divider.module.css";

interface DividerProps {
    children?: JSX.Element;
}

export function Divider({ children }: DividerProps): JSX.Element {
    return (
        <>
            {children ? (
                <HStack spacing="xs" align="center">
                    <div className={styles.line}></div>
                    <Text textStyle="caption-2" align="center" color="secondary">
                        {children}
                    </Text>
                    <div className={styles.line}></div>
                </HStack>
            ) : (
                <div className={styles.line}></div>
            )}
        </>
    );
}

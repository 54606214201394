import React from "react";
import { useNavigate } from "react-router-dom";
import { magicLinkLogin } from "web/apis";
import { UserEmailCard } from "web/react/components/user-email-card";
import { Button } from "web/react/emo/button";
import { Heading } from "web/react/emo/heading";
import { ResponsiveHeadingStyle } from "web/react/emo/sprinkles.css";
import { Text } from "web/react/emo/text/text";
import { useToast } from "web/react/emo/toast/useToast";
import { VStack } from "web/react/emo/v-stack";
import { View } from "web/react/emo/view";
import analytics from "web/script/analytics/analytics";
import { gettext } from "web/script/modules/django-i18n";
import styles from "./login-email-sent-screen.module.css";

export interface LoginEmailSentScreenProps {
    email: string;
    headingStyle?: ResponsiveHeadingStyle;
}

export const LoginEmailSentScreen = ({
    email,
    headingStyle = { sm: "large-title-2", lg: "large-title" },
}: LoginEmailSentScreenProps): JSX.Element => {
    const navigate = useNavigate();
    const toast = useToast();

    async function onClick(): Promise<void> {
        analytics.event("login_email_sent", "click", "resend_email_link");

        try {
            await magicLinkLogin(email);
            toast({ message: gettext("account.email_sent_check_inbox.label") });
        } catch (e) {
            analytics.event("login_email_sent", "error", "resend_email_link");
        }
    }

    return (
        <View className={styles.wrapper}>
            <VStack spacing="md">
                <Heading textStyle={headingStyle} as="h1">
                    {gettext("account.resend_reset_link.title")}
                </Heading>
                <Text textStyle={"body-1"}>
                    {gettext("account.resend_login_link.desc", {
                        user_email_address: email,
                    })}
                </Text>
                <UserEmailCard email={email} onClick={() => navigate("/")} />
                <Text textStyle="body-2" color="secondary">
                    {gettext("account.no_email_received_reset_link.label")}
                </Text>
                <div>
                    <Button
                        variant={"secondary"}
                        type="submit"
                        width="full"
                        onClick={onClick}
                        title={gettext("account.resend_reset_link.cta")}
                    />
                </div>
            </VStack>
        </View>
    );
};

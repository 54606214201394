import analytics from "web/script/analytics/analytics";
import globals from "web/script/modules/globals";
import rothko from "web/script/modules/rothko";

const ROTHKO_ENDPOINT = "modules/brand_ads";

export function trackKevelEvent(url: string, clicked: string): void {
    rothko.fetch(ROTHKO_ENDPOINT, { url: url, clicked: clicked }, { cache: false });
}

export default function (
    container: any,
    eventCategory: string,
    eventLabel: string,
    eventCustomData: Record<string, any> = {},
    kevelClickUrl = ""
): void {
    let iframeChildren = container.children;
    let mouseClick = false;
    let touchClick = false;
    let category = eventCategory;
    let label = eventLabel;

    function mouseEnter(): void {
        mouseClick = true;
    }

    function mouseLeave(): void {
        mouseClick = false;
    }

    function touchStart(): void {
        touchClick = true;
    }

    function touchMove(): void {
        touchClick = false;
    }

    function estimatedClick(): void {
        if (mouseClick || touchClick) {
            // remove all listeners once guesstimate click event occurs
            let iframe;
            for (let index = 0; index < iframeChildren.length; index++) {
                iframe = iframeChildren[index];
                iframe.removeEventListener("mouseenter", mouseEnter);
                iframe.removeEventListener("mouseleave", mouseLeave);
                iframe.removeEventListener("touchstart", touchStart);
                iframe.removeEventListener("touchmove", touchMove);
            }

            globals.window.removeEventListener("blur", estimatedClick);

            // send click events
            if (kevelClickUrl.length > 0) {
                trackKevelEvent(kevelClickUrl, "true");
            }
            analytics.event(category, "click", label, false, eventCustomData);
        }
    }

    // add guesstimate click listeners
    let iframe;
    for (let index = 0; index < iframeChildren.length; index++) {
        iframe = iframeChildren[index];
        iframe.addEventListener("mouseenter", mouseEnter);
        iframe.addEventListener("mouseleave", mouseLeave);
        iframe.addEventListener("touchstart", touchStart);
        iframe.addEventListener("touchmove", touchMove);
    }

    globals.window.addEventListener("blur", estimatedClick);
}

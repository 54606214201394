import { assignInlineVars } from "@vanilla-extract/dynamic";
import clsx from "clsx";
import React, { useEffect, useRef, useState } from "react";
import { useWindowWidth } from "web/react/hooks/use-window-width/use-window-width";
import { gettext } from "web/script/modules/django-i18n";
import * as styles from "./value-proposition-animation.css";

// Image sizes 871 × 5686 px
enum ImageSize {
    height = 5686,
    width = 871,
}

export function ValuePropositionAnimation({ className }: { className?: string }): JSX.Element {
    const [bgImageHeight, setBgImageHeight] = useState("");
    const [reStartAnimation, setReStarAnimation] = useState(false); // Fix for safari animation don't start even if is declared
    const divRef = useRef<HTMLDivElement>(null);
    const windowWidth = useWindowWidth();

    useEffect(() => {
        const divWith = (divRef.current && divRef.current.offsetWidth) || 0;
        const bgImageHeight = (ImageSize.height * divWith) / ImageSize.width;
        setBgImageHeight(`-${bgImageHeight}px`);
        setReStarAnimation(true);
    }, [windowWidth]);

    return (
        <div className={clsx(styles.wrapper, className)}>
            <div
                className={clsx(styles.hero, { [styles.scrollUpAnimation]: reStartAnimation })}
                ref={divRef}
                style={assignInlineVars({
                    [styles.imageHeight]: bgImageHeight,
                })}
                aria-label={
                    // "Get alerts on items, Save your searches, Create a wishlist"
                    gettext("account.all_benefits.value_prop.label")
                }
            ></div>
        </div>
    );
}

import analytics from "web/script/analytics/analytics";
import environment from "web/script/modules/environment";
import { getProductPageRegex } from "web/script/routing/routes";
import { BrowserHistoryEvent } from "web/script/utils/history";
import storage from "web/script/utils/storage";
import { PageType } from "./product-overlay";

type OverlayDecision = "open" | "close";

export const getOverlayDecision =
    (pageType: PageType) =>
    (urlData: BrowserHistoryEvent | null, isOverlayOpen: boolean): OverlayDecision | void => {
        if (!urlData) {
            return;
        }

        // if nothing has changed in the URL, do nothing
        if (
            urlData?.newURL.pathname === urlData?.oldURL?.pathname &&
            urlData?.queryChanges?.length === 0 &&
            urlData.newURL.hash === urlData.oldURL?.hash
        ) {
            return;
        }

        const initialPageURL = storage.get("initialPageURL", null, true)
            ? new URL(storage.get("initialPageURL", "", true))
            : null;

        // handle landing on a hash URL
        if (
            !isOverlayOpen &&
            urlData?.newURL.hash.startsWith("#slug=") &&
            urlData?.newURL.href === initialPageURL?.href
        ) {
            return "open";
        }

        const productPageRoute = getProductPageRegex();

        // ORG-3465: More details in https://confluence.lystit.com/display/OA/FY23+Q2+-+Hash+URL+Crawl+Optimisation
        // ORG-3619: More details in https://confluence.lystit.com/pages/viewpage.action?pageId=80321368
        const isGoingToProductCardHashURL = urlData.newURL.hash?.startsWith("#slug=");
        const isComingFromProductCardHashURL = urlData.oldURL?.hash?.startsWith("#slug=");

        const isOpeningProduct =
            productPageRoute.test(urlData.newURL.pathname) || isGoingToProductCardHashURL;
        const isClosingProduct =
            productPageRoute.test(urlData.oldURL?.pathname || "") || isComingFromProductCardHashURL;

        if (pageType === "pdp") {
            if (
                isGoingToProductCardHashURL ||
                (isComingFromProductCardHashURL &&
                    urlData.eventType === "pushstate" &&
                    urlData.newURL.pathname !== initialPageURL?.pathname)
            ) {
                return "open";
            }

            if (
                isComingFromProductCardHashURL &&
                (urlData.eventType === "popstate" ||
                    urlData.newURL.pathname === initialPageURL?.pathname)
            ) {
                return "close";
            }

            if (isOverlayOpen) {
                return "open";
            }
        } else if (["home", "feed", "editorial"].includes(pageType)) {
            if (isOpeningProduct && urlData?.state?.overlay === "product") {
                return "open";
            }

            if (isClosingProduct) {
                return "close";
            }
        }

        // This will happen if nothing matches an overlay.
        // So we don't want to trigger anything.
        return;
    };

export function handlePageAnalytics(productData): void {
    const overlayOnInitialPageLoad = storage.get("overlayOnInitialPageLoad", false, true);
    const initialPageURL = storage.get("initialPageURL", "", true);

    // ORG-3513: Correctly set up analytics data
    // if the initial page load opens product overlay
    // see https://confluence.lystit.com/display/OA/FY23+Q2+-+Hash+URL+Crawl+Optimisation for more details
    if (overlayOnInitialPageLoad && initialPageURL === window.location.href) {
        analytics.setInitialPageType("product_overlay");
        analytics.setInitialSubPageType(`${productData?.in_stock ? "in_stock" : "out_of_stock"}`);
        analytics.generatePageViewId();
    }

    const {
        ga_variables: gaVariables,
        product_analytics_data: analyticsProduct,
        product_page_view_analytics: pageViewAnalytics,
        product_url: productURL,
    } = productData;

    // update environment variables
    environment.set({
        pageType: "product_overlay",
        pageSubType: productData.in_stock ? "in_stock" : "out_of_stock",
        gaVariables,
        analyticsProduct,
        pageViewAnalytics,
        productURL,
        productHasSingleGallery: productData.in_stock
            ? productData.in_stock.product_area.product.images.length < 2
            : productData.out_of_stock.product_layout.product_image_gallery.images.length < 2,
        // Update the environment with the features from the product overlay response
        features: {
            ...environment.get("features"),
            ...productData.features,
        },
    });

    analytics.pageView();
}

export function getProductSlugFromPath(url?: URL): string | undefined {
    if (!url) {
        return;
    }

    return url.hash.startsWith("#slug=")
        ? url.hash.replace("#slug=", "")
        : url.pathname.match(getProductPageRegex())?.[2]; // extract slug from path
}
